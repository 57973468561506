import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export const RFQ_URLS = {
  CREATE_RFQ: "/rfq",
};

export interface IRfqRequestBody {
  customerId: number;
  productCategoryId: number;
  manufacturerId: number;
  gradeId: number;
  standardId: number;
  shape: string;
  attributes: string,
  requiredQuantity: number;
  expectedDeliveryDate: string;
  specificRequirement: string | string[];
  paymentTerm: string;
}

export const useRfqService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const createRfq = (data: IRfqRequestBody) => {
    return request(API_METHOD.POST, RFQ_URLS.CREATE_RFQ, authenticatedUser, data);
  };

  return {
    createRfq,
  };
};
