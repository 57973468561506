import React, { useEffect, useState } from 'react';
import { useCategoryService } from '../../../services/useCategoryService';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import Popover from '@mui/material/Popover';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface ICategoryFilter {
    id: number;
    name: string;
    subCategories: ICategoryFilter[];
}

const useStyles = createUseStyles((theme: any) => ({
    secondaryDarkText: {
        color: "black",
    },
    secondaryLightBorder: {
        color: theme.palette.border.secondaryLight
    },
    subMenu: {
        transition: 'background-color 0.3s',
        color: theme.palette.v3.text.secondaryLight,
        '&:hover': {
            background: theme.palette.v3.background.secondaryLight,
            color: theme.palette.v2.text._primaryDark,
        },
    },
    container: {
        "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)"
        }
    },
    secondaryContainer: {},
    "@media (max-width: 480px)": {
        secondaryDarkText: {
            width: 'max-content',
            whiteSpace: 'nowrap',
            fontSize: '14px',
            fontWeight: '400',
            color: theme.palette.v3.text._secondaryLight,
            paddingRight: '0px',
        },
        secondaryContainer: {
            display: 'flex',
            gap: '24px',
        },
    },
}));

const HeaderCategory: React.FC = () => {

    const navigate = useNavigate();
    const catagoryService = useCategoryService();
    const styles = useStyles();

    const [categories, setCategories] = useState<ICategoryFilter[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<ICategoryFilter | null>(null);

    const handleClickCategory = (event: any, category: ICategoryFilter) => {
        setAnchorEl(event.currentTarget);
        setSelectedCategory(category);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setSelectedCategory(null);
    };

    const handleProductCategoryClick = (productCategory: ICategoryFilter) => {
        navigate(`${CUSTOMER_ROUTES.CATALOGUE_LISTING}?categoryId=${productCategory.id}`);
        handleClosePopover();
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await catagoryService.getCategoriesFilters();
                setCategories(response?.data?.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
                setCategories([]);
            }
        };

        fetchCategories();
    }, []);

    return (
        <div className={`${styles.secondaryContainer} flex w-full`}>
            {categories?.map((category) => (
                <div key={category.id} onClick={(event) => handleClickCategory(event, category)}
                    className={`${styles.secondaryDarkText} overflow-x flex pr-6 text-base items-center cursor-pointer`}>
                    {category.name} {selectedCategory === category ? <ArrowDropUp /> : <ArrowDropDown />}
                </div>
            ))}
            {selectedCategory && selectedCategory.subCategories?.length > 0 && (
                <Popover
                    className={styles.container}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <div className='grid gap-y-1 p-2.5'>
                        {selectedCategory.subCategories.map((productCategory) => (
                            <div key={productCategory.id} onClick={() => handleProductCategoryClick(productCategory)}
                                className={`cursor-pointer flex justify-between p-4 rounded-lg ${styles.subMenu}`} >
                                <span className='flex min-w-20'>{productCategory.name}</span>
                                {/* <ArrowRightIcon className='' /> */}
                            </div>
                        ))}
                    </div>
                </Popover>
            )}
        </div>
    );
};

export default HeaderCategory;