import { createUseStyles } from "react-jss";
import { SCRAP_PRODUCTS, SCRAP_PRODUCT_ROUTES } from "../../../../utils/constant";
import Button from "../../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import ScrapProductListingTemplate from "./ScrapProductListing.template";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { useEffect, useState } from "react";
import { UploadedImageData } from "../../../pages/DashBoard/FinishedProductCreation.page";
import { useScrapProductService } from "../../../../services/useScrapProductService";
import { HTTP_STATUS, IPagination } from "../../../../utils/types";
import NoDataFrameTemplate from "../NoDataFrame.template";

const useStyles = createUseStyles((theme: any) => ({
    scrapProduct: {
        color: theme.palette.textV2.tertiary.tertiary700,
    }
}))

export interface ScrapProductsProps {
    id: number,
    scrapCategory: string,
    type: string,
    monthlyProduction: number,
    saleMode: string,
    images: UploadedImageData[]
}

const ScrapProductTemplate = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [scrapProducts, setScrapProducts] = useState<ScrapProductsProps[]>([]);
    const scrapProductService = useScrapProductService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [pagination, setPaginationTo] = useState<IPagination>({
        size: 10,
        totalRecords: 0,
        page: 0,
    });
    const handleAddNewProduct = () => {
        navigate(SCRAP_PRODUCT_ROUTES.ADD_SCRAP_PRODUCT);
    }

    const loadScrapProduct = async () => {
        try {
            const scrapProduct = await scrapProductService.getAllScrapProducts({ size: pagination.size, page: pagination.page });
            if (scrapProduct.status === HTTP_STATUS.OK) {
                setScrapProducts(scrapProduct.data.data.content);
                setPaginationTo({
                    ...pagination,
                    totalPages: scrapProduct.data.data.totalPages,
                    totalRecords: scrapProduct.data.data.totalElements
                });
            }
        }
        catch (error) {
            showSnackbar("error", "Error Fetching Data");
        }
    }

    useEffect(() => {
        loadScrapProduct();
    }, [pagination.page, pagination.size])

    return (
        <div className="grid gap-4">
            {SnackBarComponent}
            <div className="flex justify-between pb-4 border-b">
                <span className={`text-2xl font-medium ${classes.scrapProduct}`}>{SCRAP_PRODUCTS.SCRAP_PRODUCTS_LISTING}</span>
                <Button variant="contained" label="+Add New Product" onClick={handleAddNewProduct} />
            </div>
            <div >
                {!!scrapProducts.length ?
                    <ScrapProductListingTemplate scrapProducts={scrapProducts} loadScrapProduct={loadScrapProduct} setPaginationTo={setPaginationTo} pagination={pagination} />
                    : <div className="mt-20">
                        <NoDataFrameTemplate title="No Result Found" message="You haven't provided Scrap for your business. " />
                    </div>}
            </div>
        </div>
    )
}

export default ScrapProductTemplate;