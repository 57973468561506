import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useUserService } from '../../../services/useUserService';
import { useBusinessService } from '../../../services/useBusinessService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { HTTP_STATUS } from '../../../utils/types';
import BussinessLocationTemplate from '../../templates/OnBoarding/BussinessLocation.template';
import Shimmer from '../../molecules/ShimmerComponent/Shimmer';

export interface IGstnState {
  gstin:string;
  state:string;
}
export interface ILocationGstinForm {
  locations: IGstnState[];
  noOfRows: number|null;
  newLocations: IGstnState[];
}
const initialValues = {
  locations:[],
  noOfRows:null,
  newLocations: []
}
const BussinessLocationPage: React.FC = () => {
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const { user: authenticatedUser } = useAuthenticatedUser();
  const bussinessProfileService = useBusinessProfileService();
  const bussinessService = useBusinessService();
  const [businessProfile, setBusinessProfileTo]= useState<any>();
  const [bussinessLocation, setBussinessLocationTo] = useState<ILocationGstinForm>(initialValues)
  const [isLoading, setIsLoading]= useState<boolean>(true);

  const validationSchema = Yup.object().shape({
    locations: Yup.array().of(
      Yup.object().shape({
        gstin: Yup.string().required('GSTIN is required'),
        state: Yup.string().required('State is required')
      })
    ),
    noOfRows: Yup.number()
        .required('please enter atleast one number')
        .transform(value => (!isNaN(value) ? value : undefined)) 
        .test(
            'isValidNumber',
            'Must be a positive number between 1 and 30',
            value => value === undefined || (value > 0 && value < 31)
        ),
    newLocations:Yup.array().of(
      Yup.object().shape({
        gstin: Yup.string()
        .required('GST Number is required')
        .matches(/^[0-9]{2}[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]{1}[0-9]{1}[A-Z,a-z]{1}[0-9,A-Z,a-z]{1}?$/, 'This GSTIN is not valid'),
        state: Yup.string().required('state is required')
      })
    )
  });
  
  const getBusinessProfile = async () => {
    try {
      const businessProfileResponse = await  bussinessProfileService.getBusinessProfile();
      if (businessProfileResponse.status === HTTP_STATUS.OK) {
        const profileData = businessProfileResponse?.data?.data;
        setBusinessProfileTo(profileData);
        let locations = profileData?.locations?.filter((item: { gstin: any; }) => item.gstin !== profileData?.gstin);
        setBussinessLocationTo({ locations: locations || [], noOfRows: null, newLocations: [] });
      }
    } catch (error) {
      showSnackbar('error', 'Business Profile fetch failed');
    }
    finally {
      setIsLoading(false);
    }
};
  const formik = useFormik<ILocationGstinForm>({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      let body = [
        ...formik.values.locations,
        ...formik.values.newLocations.map(location => ({ ...location, gstin: location.gstin.toUpperCase() })),
      ];
      body = [
        ...body,
        {
          gstin: businessProfile?.gstin,
          state: businessProfile?.address?.state
        }
      ]
      body = body.filter((item, index, self) =>
        index === self.findIndex((t) => t.gstin === item.gstin && t.state === item.state)
      );
      try {
        const businessProfileResponse = await bussinessProfileService.updateBusinessProfile(body, 'LOCATIONS');
        if (businessProfileResponse.status === HTTP_STATUS.OK) {
          showSnackbar('success', 'Business Profile updated successfully');
        } else {
          showSnackbar('error', 'Business Profile update failed');
        }
        formik.resetForm();
      } catch (error) {
        showSnackbar("error", `Business profile update  failed ${error}`)
      }
    },
  });
  
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      ...bussinessLocation
    })
  }, [bussinessLocation])
 
  useEffect(() => {
    getBusinessProfile();
  }, [formik.isSubmitting]);
  


return (
 isLoading? <Shimmer/> : <BussinessLocationTemplate formik={formik}  /> 
)
}
export default BussinessLocationPage