import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss';
import avighnaSteelLogo from "../../../assets/images/avighanaLogo.svg"
import tin from "../../../assets/images/onBoardingTin.svg"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import { ONBOARDING_STATES } from '../../../utils/types';
import checkActive from '../../../assets/images/checkActive.svg'
import checkInactive from '../../../assets/images/checkInactive.svg'
import checkCompleted from '../../../assets/images/checkCompleted.svg'
import { useNavigate } from 'react-router-dom';

interface OnBoardingInformationTemplateProps {
  children: ReactNode;
  formState: ONBOARDING_STATES;
  setFormState: (value: ONBOARDING_STATES) => void;
};

const useStyles = createUseStyles((theme: any) => ({
  background: {
    background: theme.palette.miscellaneous.onBoardingBackgroundCard
  },
  sectionText: {
    color: theme.palette.text.primaryDark
  },
  black: {
    color: theme.palette.text.primaryLightAccent
  },
  card: {
    background: theme.palette.background.secondaryLightAccent,
    height: "538px",
    borderRadius: "32px"
  }, dullText: {
    color: theme.palette.text.secondaryLightAccent
  }
}))

const OnBoardingInformationTemplate: React.FC<OnBoardingInformationTemplateProps> = ({ children, formState, setFormState }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const getStateIcon = (state: ONBOARDING_STATES, subState?: ONBOARDING_STATES) => {
    let checkStatus = (state === formState || subState === formState) ? checkActive : (state < formState) ? checkCompleted : (state > formState) ? checkInactive : checkActive;

    return <img src={checkStatus} alt={`${checkStatus}`} />
  }

  const getCurrentSectionName = (state: ONBOARDING_STATES) => {
    return [ 'Business Profile', 'Business Profile', 'Entity Type', 'Product', 'Requirements' ][ state-1 ]
  }

  const getCurrentStep = (state:ONBOARDING_STATES) => state === 1 ? state : state - 1;
  
  const getSectionProgress = (state: ONBOARDING_STATES) => ((getCurrentStep(state) / 4) * 100) - 25;

  const onBack = () => {
    setFormState(formState - 1)
  }

 const handleSkip = () =>{
  navigate('/');
 } 

  return (
    <div className='w-full h-full flex'>
      <div className={`w-2/5 h-full relative ${classes.background}`}>
        <div className='fixed w-2/5 h-full'>
          <div className='relative w-full h-full pl-10'>
            <div className={`h-1/4 md:w-3/6 sm:3/6 xs: 4/6`}>
              <div className='pt-10 relative'><img src={avighnaSteelLogo} alt="Avighna Logo" className='w-full absolute -left-4' /></div>
            </div>
            <div className={`h-3/4 w-full`}>
              <div className={`w-full `}>
                <div className='flex flex-col w-7/12'>
                  <span className={`${classes.sectionText} font-semibold text-4xl`}>Complete</span>
                  <span className={`${classes.black} font-bold text-4xl`}>Onboarding</span>
                  <p className='text-xl mt-4'>Your Ultimate Destination for Steel Raw Material Excellence</p>
                </div>
                <div className='w-full relative'>
                  <div className={`${classes.card} z-0 absolute transform origin-top-left w-10/12 dvh-5/12 h-[50] rotate-[23deg] `}></div>
                  <img src={tin} alt="TIN KA DABBA" className='relative z-10' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`w-3/5 flex justify-center overflow-y-scroll`} >
        <div className='w-8/12 flex flex-col gap-8'>
          <div className='flex justify-between mt-2'>
            <div>
              {formState !== 1 && <span className={`flex items-center cursor-pointer text-xl ${classes.sectionText}`} onClick={onBack}><ArrowBackIosIcon fontSize='small' />Back</span>}
            </div>
            {formState !== 1 && <span className={`${classes.sectionText} text-xl cursor-pointer`} onClick={handleSkip}>Skip for now</span>}
          </div>
          <div className='flex flex-col gap-y-2 w-full text-lg '>
            <div className={`flex gap-1`}>
              <span className={`font-light ${classes.dullText}`} >Steps</span>
              <span>{getCurrentStep(formState)}/4</span>
            </div>
            <div className={` ${classes.sectionText} flex justify-between text-xl`}>
              <span className='flex gap-1 items-center capitalize'><BusinessCenterOutlinedIcon fontSize='medium' />{getCurrentSectionName(formState)}</span>
              <span >{getSectionProgress(formState)}%</span>
            </div>
            <div className='mt-3 flex items-center'>
              <div>{getStateIcon(ONBOARDING_STATES.GST_INFORMATION, ONBOARDING_STATES.BUSINESS_DETAILS)}</div>
              <span className={`flex-1 border-t ${classes.sectionText}`}></span>
              <div>{getStateIcon(ONBOARDING_STATES.ENTITY_TYPE)}</div>
              <span className={`flex-1 border-t ${classes.sectionText}`}></span>
              <div>{getStateIcon(ONBOARDING_STATES.PRODUCT)}</div>
              <span className={`flex-1 border-t ${classes.sectionText}`}></span>
              <div>{getStateIcon(ONBOARDING_STATES.REQUIREMENTS)}</div>
            </div>
          </div>
          <div className='pb-16'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnBoardingInformationTemplate