import React from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import { Lead } from "./LeadList.template";
import { getFormattedDate, REQUEST_ORDER_STATUS } from "../../../utils/helper";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { STATUS } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
  title: {
    color: theme.palette.text.primaryLight,
  },
  webContainer: {
    display: "flex",
    background: theme.palette.background.primaryDarkAccent,
  },
  completed:{
    color: theme.palette.status.primaryLight,
  },
  ongoing:{
    color: theme.palette.status.secondaryLight,
  },
  cancelled: {
    color: theme.palette.status.primaryDark,
  },
  mobileContainer:{
    display: "none",
  },
  "@media (max-width: 476px)": {
    mobileContainer:{
      display: "flex",
      background: theme.palette.background.primaryDarkAccent,
    },
    webContainer: {
      display : "none"
    }
  },
}));

interface LeadDetailsTemplateProps {
  data: Lead;
  onClick?: any;
  show?: boolean;
  setShow?: any;
}

const LeadDetailsTemplate: React.FC<LeadDetailsTemplateProps> = ({ data , onClick, show, setShow }) => {

  const classes = useStyles();

  let statusTextColor;
  if (data.status === STATUS.ONGOING) {
    statusTextColor = classes.ongoing;
  } else if (data.status === STATUS.ACCEPTED) {
    statusTextColor = classes.completed;
  } else if (data.status === STATUS.REJECTED) {
    statusTextColor = classes.cancelled;
  }

  return (
    <>
      <div className={`${classes.webContainer} justify-between p-3 rounded`}>
        <div className="flex gap-x-6">
          <div className="grid">
            <div className={`${classes.title} text-sm font-normal`}>Status</div>
            <div className={`${classes.title} ${statusTextColor} text-sm font-medium`}>{REQUEST_ORDER_STATUS(data.status)}</div>
          </div>
          <div className="grid">
            <div className={`${classes.title} text-sm font-normal`}>Request Placed</div>
            <div className={`${classes.title} text-sm font-medium`}>{getFormattedDate(data?.createdAt)}</div>
          </div>
        </div>
        <div className="">
          <div className={`${classes.title} text-sm font-normal`}>Request ID # {data?.id}</div>
          <Button className="!p-2 !h-6 !font-semibold !text-indigo-900" variant="outlined" label="Quotations" onClick={onClick} />
        </div>
      </div>

      <div className={`${classes.mobileContainer} justify-between p-2 rounded`}>
        <div className="grid h-fit">
          <div className={`${classes.title} text-xs font-normal`}>Status</div>
          <div className={`${classes.title} ${statusTextColor} text-xs font-medium`}>{REQUEST_ORDER_STATUS(data.status)}</div>
        </div> 

        <div className="grid gap-y-2">
          <div className="flex justify-between gap-x-2">
              <div className="grid">
                <div className={`${classes.title} text-xs font-normal`}>Request Placed</div>
                <div className={`${classes.title} text-xs font-medium`}>{getFormattedDate(data?.createdAt)}</div>
              </div>
              <div className="flex gap-x-2 cursor-pointer">
                    {show ? <KeyboardArrowUpRoundedIcon sx={ { fontSize: 30 } } onClick={() => setShow(false)} /> : <KeyboardArrowDownRoundedIcon sx={ { fontSize: 30 }} onClick={() => setShow(true)} />}
              </div>
          </div>
          <div className="">
            <div className={`${classes.title} text-xs font-normal`}>Request ID # {data?.id}</div>
            <Button className="!p-2 !h-6 !font-semibold !text-indigo-900" variant="outlined" label="Quotations" onClick={onClick} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadDetailsTemplate;
