import React, { useEffect, useRef, useState } from "react";
import Button from "../../atoms/Button/Button";
import { createUseStyles } from "react-jss";
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import CatalogueGridItemSkeleton from "../Catalogue/CatalogueGridItemSkeleton.template";
import { useNewsService } from "../../../services/useNewsService";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export interface News {
    title: string;
    content: string;
    banner: string;
    slug: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        height: "450px"
    },
    title: {
        color: theme.palette.v2.text.primaryDark,
    },
    mobiletitle: {
        color: theme.palette.text.primaryDark,
    },
    catalogueCard: {
        width: "304px",
        minWidth: "180px",
        paddingBottom: "4px",
    },
    newsCard: {
        width: "305px",
        border: `1px solid ${theme.palette.v2.border.primaryLight}`
    },
    newsImage: {
        height: "168px",
        overflow: "hidden",
    },
    cardContent: {
        color: theme.palette.text.secondaryLightAccent,
    },
    article: {
        backgroundColor: theme.palette.v3.background.primaryLight,
        color: theme.palette.v2.text.primaryDark,
    },
    newsTitle: {
        color: theme.palette.v3.text.secondaryDark,
    },
    newsDesc: {
        color: theme.palette.v3.text.secondaryLight,
    },
    mobileViewall: {},
    carouselContainerMobile: {},
    mainContainer: {},
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important",
            zIndex: "10 "
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important",
            zIndex: "10 "
        },
        "& .react-multi-carousel-list": {
            width: "1296px",
            margin: "0 auto",
            overflowx: "auto",
        },
        "& .react-multi-carousel-item": {
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
        marginTop: '32px',
    },
    tabMobileContainer: {
        display: "none"
    },
    tabContainer: {
        display: "flex",
        padding: "0px 72px"
    },
    tab: {
        marginRight: "35px"
    },
    "@media (max-width: 480px)": {
        tabMobileContainer: {
            display: "grid",
        },
        mainContainer: {
            margin: '0px !important',
        },
        tabContainer: {
            display: "none"
        },
        newsCard: {
            width: "264px",
            borderRadius: '16px',
        },
        carouselContainer: {
            width: '100%',
            "& .react-multi-carousel-list  ": {
                width: '100vw',
                paddingLeft: '16px',
            },
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-item": {
                width: "264px !important ",
                marginRight: '16px',
            },
            marginTop: '16px !important ',
            ' & .react-multi-carousel-list ': {
                overflowX: 'auto ',
            }
        },
        catalogueCard: {
            width: "264px !important",
            minWidth: "100px",
            paddingBottom: "4px",
        },
        container: {
            width: '100% !important',
            height: '100%',
        },
        carouselContainerMobile: {
            overflow: 'auto',
            height: '356px',
            "& .react-multi-carousel-list  ": {
            }
        },
        customArrow: {
            visibility: 'hidden',
        },
        mobiletitle: {
            color: theme.palette.v3.text._secondaryLightAccent,
            fontSize: '18px',
            paddingLeft: '16px',
        },
        mobileViewall: {
            fontSize: '14px',
            color: theme.palette.v4.text.secondaryLightAccent,
        },
        btn: {
            color: theme.palette.v3.text._secondaryLightAccent,
            fontSize: '14px',
            fontWeight: '500',
            padding: '10px 4px',
        },
    },
    btn: {
        color: theme.palette.v3.text._secondaryLightAccent,
        fontSize: '14px',
        fontWeight: '500',
        padding: '10px 4px',
    },
    customArrow: {
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        cursor: "pointer",
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.secondaryDark}`,
        "&:hover": {
            boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.primaryDark}`
        }
    },
    viewAll: {
        color: theme.palette.v2.text._primaryDarkAccent,
    },
    customButtonContainer: {
        marginTop: "-13%"
    },
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 480, min: 0 },
        items: 2,
        partialVisibilityGutter: 10
    }
};

const LatestNewsTemplate: React.FC<{}> = () => {
    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const newsService = useNewsService();
    const [news, setNewsTo] = useState<News[]>([]);

    const loadNews = async () => {
        setLoading(true);
        try {
            const params = {
                limit: 10,
                page: 1,
            };
            const newsResponse = await newsService.getNews(params);
            if (newsResponse?.data?.data?.length) {
                setNewsTo(newsResponse.data.data);
            } else {
                throw new Error("News fetch failed");
            }
        } catch (error) {
            setNewsTo([]);
        }
        setLoading(false);

    };

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
        loadNews();
    }, []);

    const ButtonGroup = ({ next, previous, carouselState }: any) => {
        const { currentSlide, totalItems, slidesToShow } = carouselState;
        const isFirstSlide = currentSlide === 0;
        const isLastSlide = currentSlide === totalItems - slidesToShow;

        return (
            <div className={`${classes.customButtonContainer} flex justify-between items-center w-full px-3`}>
                {!isFirstSlide && (
                    <button className={`${classes.customArrow} bg-white flex items-center`} onClick={previous}>
                        <KeyboardArrowLeftIcon className="mx-auto" sx={{ fontSize: 30 }} />
                    </button>
                )}
                {!isLastSlide && (
                    <button className={`${classes.customArrow} bg-white flex items-center ml-auto`} onClick={next}>
                        <KeyboardArrowRightIcon className="mx-auto" sx={{ fontSize: 30 }} />
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className={`${classes.container} pb-2`}>
            <div className={`justify-between ${classes.tabContainer} w-full`}>
                <div className={`${classes.title} flex text-2xl font-semibold items-center`}>{"Latest Industry Updates"}</div>
                <div className="flex gap-x-3">
                    <a href="https://steelbazaar.com/" target="_blank" rel="noopener noreferrer">
                        <Button variant="text" label="View All" className={`${classes.viewAll} !px-0 !text-lg !font-medium`} />
                    </a>
                </div>
            </div>


            <div className={`${classes.tabMobileContainer}`}>
                <div className="flex justify-between"   >
                    <div className={`${classes.mobiletitle} flex text-base font-semibold`}>{"Latest Industry Updates"}</div>
                    <div className={classes.viewAll}>
                        <a href="https://steelbazaar.com/" target="_blank" rel="noopener noreferrer">
                            <Button variant="text" label="View All" className={`${classes.mobileViewall} !border !py-0 !px-4 !font-medium`} />
                        </a>
                    </div>
                </div>
            </div>
            {
                loading ? (
                    <div className={`${classes.carouselContainer} ${classes.carouselContainerMobile} flex gap-x-8`}>
                        {[null, null, null, null].map((item: any, index: any) => (
                            <div className={classes.catalogueCard} key={index}>
                                <CatalogueGridItemSkeleton />
                            </div>
                        ))}
                    </div>
                ) :
                    <div className={`${classes.carouselContainer} ${classes.carouselContainerMobile} relative`}>
                        <Carousel
                            responsive={responsive}
                            showDots={false}
                            infinite={false}
                            partialVisible={true}
                            ref={carouselRef}
                            arrows={false}
                            removeArrowOnDeviceType={["sm-mobile"]}
                            renderButtonGroupOutside={true}
                            customButtonGroup={<ButtonGroup />}
                        >
                            {news.map((item: any) => (
                                <div className={`${classes.mainContainer} mr-6`}>
                                    <div className={`${classes.newsCard}  rounded-2xl overflow-hidden`}>
                                        <div>
                                            <img className={`${classes.newsImage} h-fit w-full`} src={item.banner} alt="" />
                                        </div>
                                        <div className='grid gap-y-1.5 pt-6 px-4 pb-2'>
                                            <div className={`${classes.article} px-2 py-1 rounded-2xl text-xs w-fit`}>Article</div>
                                            <div className="text-base font-semibold ">{item.title.substring(0, 23)}...</div>
                                            <div className={`${classes.newsDesc} text-sm h-10`}>{item.content.replace(/<[^>]+>/g, '').substring(0, 60)}...</div>
                                            <div className="mb-1">
                                                <a href={`https://steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer">
                                                    <Button variant="text" label="Read More" className={classes.btn} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Carousel>
                    </div>
            }
        </div>
    );
};

export default LatestNewsTemplate;