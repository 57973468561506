import { createUseStyles } from "react-jss";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Button from "../../../atoms/Button/Button";
import { Radio } from '@mui/material';
import { TableBody, TableCell, TableHead, TableRow, Table } from '@mui/material';
import { ICatalogueUpc } from "../../../pages/Catalogue/MultipleUpcCatalogueDetails.page";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ICustomization } from "./SelectCustomization.template";
import { toInr } from "../../../../utils/helper";
import editIcon from "../../../../assets/images/editIcon.svg"
import deleteIcon from "../../../../assets/images/deleteOutlinedIcon.svg"
import SuccessFailurePopup from "../../../molecules/SuccessFailurePopup/SuccessFailurePopup";
import { InputAdornment } from '@mui/material';
import { SHEARING_HEADING } from "../../../../utils/constant";
import TextFieldV2 from "../../../atoms/Input/TextFieldV2";

interface ShearingCustomizationPopupProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    selectedUpc?: ICatalogueUpc | null;
    selectedWeight: number;
    customization: ICustomization;
    valueAddedService: any;
    setValueAddedServiceTo: any;
    balanceWeight: any;
    setBalanceWeight: any;
}

export interface IShearing {
    id: number,
    length: number;
    pieces: number;
    weightPerPiece: number;
    weight: number;
    chargesPerMT: number;
    totalCharges: number;
}

export interface IShearingForm {
    id: number | null,
    length: number | null | string;
    pieces: number | null | string;
    weightPerPiece: number | null;
    weight: number | null;
    chargesPerMT: number | null;
    totalCharges: number | null;
    requestWeight: number | null;
    minBalanceWeight: boolean;
}

const requestOptions = [
    {
        id: 1,
        name: "Enter the number of pieces you want",
    },
    {
        id: 2,
        name: "Enter the desired Wt. (MT) for CTL",
    }
]

const useStyles = createUseStyles((theme: any) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "983px !important",
            maxWidth: "983px !important",
            borderRadius: "12px"
        },
    },
    webContainer: {
        display: "grid"

    },
    mobileContainer: {
        display: "none"
    },
    mainHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
    closeIcon: {
        color: theme.palette.textV2.secondary.secondary500
    },
    container: {
        borderTop: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
    },
    balancedWeight: {
        color: theme.palette.textV2.primary.primary500,
        backgroundColor: theme.palette.backgroundV2.primary.primary50,
    },
    selectedWeight: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    selectedWidth: {
        color: theme.palette.textV2.success.success500,
        backgroundColor: theme.palette.textV2.success.success50,
    },
    subContainer: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
    },
    inputHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    checkBox: {
        borderTop: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
    },
    checkBoxValue: {
        color:  theme.palette.textV2.primary.primary950,
    },
    uom: {
        "& .MuiTypography-root": {
            color: theme.palette.textV2.tertiary.tertiary400
        }
    },
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.v4.background._primaryLightAccent,
    },
    tableHeadCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 0px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    tableCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 16px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    details: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    detailBalancedWeight: {
        color: "#CA4F02"
    },
    tableContaier: {
    },
    "@media (max-width: 480px)": {
        dialogContainer: {
            "& .MuiDialog-paper": {
                width: "448px !important",
                maxWidth: "448px !important",
                borderRadius: "12px"
            },
        },
        webContainer: {
            display: "none"

        },
        mobileContainer: {
            display: "grid"
        },
        tableContaier: {
            width: "840px"
        },

    },
}))



const ShearingCustomizationPopup: React.FC<ShearingCustomizationPopupProps> = ({ dialogOpen, setDialogOpen, selectedUpc, selectedWeight, customization, valueAddedService, setValueAddedServiceTo, balanceWeight, setBalanceWeight }) => {

    const classes = useStyles();
    const [shearing, setShearing] = useState<IShearing[]>(valueAddedService.shearing)
    const [selectedOption, setSelectedOption] = useState<number | null>(null);

    const currentCumulativeWeight = parseFloat(shearing?.reduce((totalWeight, item) => totalWeight + item.weight, 0).toFixed(3));
    const grandTotal = shearing?.reduce((totalCharges, item) => totalCharges + item.totalCharges, 0);

    const width: string = String(selectedUpc?.attributes?.SPECIFICATION?.find(attr => attr.name.trim() === "Width")?.attributeOptions || "0");
    const thickness: string = String(selectedUpc?.attributes?.SPECIFICATION?.find(attr => attr.name.trim() === "Thickness")?.attributeOptions || "0");
    const chargesPerMT = customization?.price || 0;
    const density = 7.850

    const [editState, setEditState] = useState<boolean>(false)
    const [editId, setEditId] = useState<number | null>(null)

    const [weightPerPiece, setWeigthPerPiece] = useState<number>(0)

    const handleDialogClose: (event: any, reason: "backdropClick" | "escapeKeyDown" | "closeButtonClick") => void = (event, reason) => {
        if (reason !== "backdropClick") {
            setDialogOpen(false);
            setBalanceWeight(balanceWeight + (currentCumulativeWeight - parseFloat(valueAddedService.shearing?.reduce((totalWeight: any, item: any) => totalWeight + item.weight, 0).toFixed(3))))
        }
    }

    const handleRadioChange = (option: number) => {
        setSelectedOption(option);
    };

    const validationSchema = Yup.object().shape({
        length: Yup.number().min(customization?.minLength, `Length should be equal to or greater than ${customization?.minLength} mm`).max(customization?.maxLength, `Length should be equal to or less than ${customization?.maxLength} mm`).required('Length is required'),
        pieces: Yup.number().integer('Pieces must be a whole number').min(1, "Pieces should be greater than zero").required('Number of pieces is required'),
        requestWeight: Yup.number().moreThan(weightPerPiece, `Weight should be greater than ${weightPerPiece}`).max(balanceWeight, "Should be less then Selected or Balanced Weight").required('Weight is required'),
        minBalanceWeight: Yup.boolean()
            .test('is-balance-weight-enough', `Selected Weight Should be more or number of pieces should be less`, function (value) {
                const requestWeight: number = this.parent.requestWeight;
                return balanceWeight >= requestWeight;
            })
    });

    const initialFormValues: IShearingForm = {
        id: null,
        length: "",
        pieces: "",
        weightPerPiece: null,
        weight: null,
        chargesPerMT: null,
        totalCharges: null,
        requestWeight: null,
        minBalanceWeight: false,
    };

    const handleInputChange = (key: string, value: number) => {
        if (key === "pieces") {
            formik.setFieldValue("pieces", Number(value))
            formik.setFieldValue("requestWeight", parseFloat((value * weightPerPiece).toFixed(3)));
        } else if (key === "requestWeight") {
            const pieces = Math.floor(value / weightPerPiece);
            formik.setFieldValue("requestWeight", value);
            formik.setFieldValue("pieces", pieces);
        }
    }

    const formik = useFormik<IShearingForm>({
        enableReinitialize: true,
        initialValues: initialFormValues,
        validationSchema: validationSchema,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const pieces = values.pieces || 0;
            const weightPerPiece = parseFloat(((Number(values.length) ?? 0 / 1000) * (parseFloat(width) / 1000) * (parseFloat(thickness) / 1000) * density).toFixed(3));
            const weight = parseFloat((Number(pieces) * weightPerPiece).toFixed(3));

            if (editState) {
                const updatedShearing: any[] = shearing.map((item: IShearing) =>
                    item.id === values.id
                        ? {
                            ...item,
                            id: values.id,
                            length: values.length,
                            pieces: values.pieces,
                            weightPerPiece: weightPerPiece,
                            weight: weight,
                            chargesPerMT: chargesPerMT,
                            totalCharges: weight * chargesPerMT,
                        }
                        : item
                );
                setShearing(updatedShearing);
                // setValueAddedServiceTo({ ...valueAddedService, shearing: updatedShearing });
                setBalanceWeight(parseFloat((balanceWeight - weight).toFixed(3)))
                setEditState(false);
                resetForm();
                setEditId(null)
            } else {
                const payload: any = {
                    id: shearing.length + 1,
                    length: values.length,
                    pieces: values.pieces,
                    weightPerPiece: weightPerPiece,
                    weight: weight,
                    chargesPerMT: chargesPerMT,
                    totalCharges: weight * chargesPerMT,
                }
                setShearing([...shearing, payload]);
                setBalanceWeight(parseFloat((balanceWeight - weight).toFixed(3)))
                resetForm();
            }

        },
    });


    useEffect(() => {
        setWeigthPerPiece(parseFloat(((Number(formik.values.length) ?? 0 / 1000) * (parseFloat(width) / 1000) * (parseFloat(thickness) / 1000) * density).toFixed(3)));
    }, [formik.values.length])

    const handleSave = () => {
        setValueAddedServiceTo({ ...valueAddedService, shearing: shearing });
        setDialogOpen(false);
    };

    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<any>({ id: null, weight: null });

    const handleDelete = (id: number, weight: number) => {
        setItemToDelete({ id, weight });
        setShowDeletePopUp(true);
    };

    const handleDeleteConfirmed = () => {
        const updatedShearing = shearing.filter((item) => item.id !== itemToDelete.id);
        setShearing(updatedShearing);
        setValueAddedServiceTo({ ...valueAddedService, shearing: valueAddedService.shearing.filter((item: any) => item.id !== itemToDelete.id) });
        setBalanceWeight(parseFloat((balanceWeight + itemToDelete.weight).toFixed(3)))
        setShowDeletePopUp(false);
    };

    const handleCancelDelete = () => {
        setShowDeletePopUp(false);
    };

    const [showDeleteAllPopUp, setShowDeleteAllPopUp] = useState(false);

    const handleDeleteAll = () => {
        setShowDeleteAllPopUp(true);
    };

    const handleDeleteAllConfirmed = () => {
        setShearing([]);
        setValueAddedServiceTo({ ...valueAddedService, shearing: [] });
        setBalanceWeight(parseFloat((balanceWeight + currentCumulativeWeight).toFixed(3)));
        setShowDeleteAllPopUp(false);
    };

    const handleCancelDeleteAll = () => {
        setShowDeleteAllPopUp(false);
    };

    const handleEdit = (data: IShearing) => {
        setEditId(data.id)
        setEditState(true)
        setBalanceWeight(balanceWeight + data.weight)
        setSelectedOption(1)
        formik.setValues({
            ...formik.values,
            id: data?.id,
            length: data.length,
            pieces: data.pieces,
            weightPerPiece: data.weightPerPiece,
            weight: data.weight,
            chargesPerMT: data.chargesPerMT,
            totalCharges: data.totalCharges,
            requestWeight: data.pieces * data.weightPerPiece,
            minBalanceWeight: false,
        })
    }

    const handleCancelEdit = (shearing: any) => {
        const editWeight = shearing.find((s: any) => s.id === editId)?.weight
        setBalanceWeight(balanceWeight - editWeight)
        formik.resetForm();
        setEditState(false)
        setEditId(null)
    }


    return (
        <Dialog fullWidth className={classes.dialogContainer} PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} onClose={handleDialogClose}>
            <div className={`${classes.webContainer} gap-y-8 p-6`}>
                <div className="flex justify-between">
                    <div className={`${classes.mainHeading} text-2xl font-medium `}>Shearing (CTL)</div>
                    {!editState &&
                        <CloseIcon className={`${classes.closeIcon} cursor-pointer`} onClick={(event) => handleDialogClose(event, "closeButtonClick")} />}
                </div>

                <div className={`${classes.container} grid gap-y-6 pt-3`}>
                    {balanceWeight === selectedWeight &&
                        <div className="flex justify-between">
                            <div className="flex gap-x-3">
                                <div className={`${classes.balancedWeight} py-2 px-4 rounded-3xl`}>{`Selected Weight: ${selectedWeight} MT`}</div>
                                <div className={`${classes.selectedWidth} py-2 px-4 rounded-3xl`}>{`Selected Width: ${width} mm`}</div>
                            </div>
                        </div>
                    }

                    {balanceWeight !== selectedWeight &&
                        <div className="flex justify-between">
                            <div className="flex gap-x-3">
                                <div className={`${classes.balancedWeight} py-2 px-4 rounded-3xl`}>{`Balanced Weight: ${balanceWeight.toFixed(3)} MT`}</div>
                                <div className={`${classes.selectedWidth} py-2 px-4 rounded-3xl`}>{`Selected Width: ${width} mm`}</div>
                            </div>
                            <div className={` ${classes.selectedWeight} my-auto text-xs`}>{`Selected Weight: ${selectedWeight} MT`}</div>
                        </div>
                    }

                    <form className="grid gap-y-6" onSubmit={formik.handleSubmit}>
                        <div className={`${classes.subContainer} grid gap-y-6 p-4 rounded-xl`}>
                            <div className={`${classes.mainHeading} text-base font-medium`}>Please Share the value of length required along with either No. of Pieces or Weight (MT)</div>
                            <div className="grid gap-y-2">
                                <div className="grid gap-y-1 w-1/2">
                                    <TextFieldV2
                                        label="Enter the length you want"
                                        fullWidth
                                        type="number"
                                        placeholder="Enter here"
                                        {...formik.getFieldProps("length")}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" className={`${classes.uom} pr-4 text-base font-normal`}>
                                                    MM
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formik.touched && formik.touched?.length && formik.errors?.length && (
                                        <div className={classes.errorMessage}>
                                            <small>{formik.errors?.length}</small>
                                        </div>
                                    )}
                                    {balanceWeight < weightPerPiece && (
                                        <div className={classes.errorMessage}>
                                            <small> Balance Weight should be greater than {weightPerPiece.toFixed(3)} MT or length should be less than {formik.values.length} mm.</small>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={` ${classes.checkBox} grid gap-y-1.5 pt-6`}>
                                <div className="flex gap-x-8">
                                    {requestOptions.map((item: any) => (
                                        <div className="flex gap-x-0.5" key={item.id}>
                                            <div>
                                                <Radio
                                                    sx={{ padding: "6px", color: "#B3B3B3" }}
                                                    checked={selectedOption === item.id}
                                                    onChange={() => handleRadioChange(item.id)}
                                                />
                                            </div>
                                            <div className={`${classes.checkBoxValue} my-auto text-base font-normal`}>{item.name}</div>
                                        </div>
                                    ))}
                                </div>

                                <div>
                                    <div className="grid gap-y-2 ml-10 w-72">
                                        {selectedOption === 1 && (
                                            <>
                                                <TextFieldV2
                                                    label="Enter Number of Pieces"
                                                    type="number"
                                                    placeholder="Enter here"
                                                    onChange={(e: any) => handleInputChange("pieces", e.target.value)}
                                                    value={formik.values.pieces}
                                                />
                                                {formik.touched.pieces && !!formik.errors.pieces && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{formik?.errors?.pieces}</small>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {selectedOption === 2 && (
                                            <>
                                                <TextFieldV2
                                                    label="Enter Requested Weight (MT)"
                                                    type="number"
                                                    placeholder="Enter here"
                                                    onChange={(e: any) => handleInputChange("requestWeight", e.target.value)}
                                                    value={formik.values.requestWeight}
                                                />
                                                {formik.touched && formik.touched?.requestWeight && formik.errors?.requestWeight && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{formik.errors?.requestWeight}</small>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {formik.touched && formik.touched?.pieces && formik.errors?.pieces && selectedOption === null && (
                                        <div className={classes.errorMessage}>
                                            <small>Select Either one feild</small>
                                        </div>
                                    )}

                                    {formik.touched && formik.touched?.minBalanceWeight && formik.errors?.minBalanceWeight && (
                                        <div className={classes.errorMessage}>
                                            <small>{formik.errors?.minBalanceWeight}</small>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {!editState &&
                            <div className="flex justify-end">
                                <div className="flex gap-x-4 items-center">
                                    {
                                        shearing?.length === 0 && <Button label="Cancel" variant="tertiaryContained" size="medium" onClick={(event) => handleDialogClose(event, "closeButtonClick")} />
                                    }
                                    <Button label={shearing?.length >= 1 ? "+Add Another" : "+Add"} variant="secondaryContained" size="medium" type="submit" />
                                </div>
                            </div>
                        }

                        {shearing?.length > 0 && editState &&
                            <div className="flex justify-end">
                                <div className="flex gap-x-4 items-center">
                                    <Button label="Cancel" variant="tertiaryContained" size="medium" onClick={() => handleCancelEdit(shearing)} />
                                    <Button label="Save" variant="secondaryContained" size="medium" type="submit" />
                                </div>
                            </div>
                        }
                    </form>

                    {shearing?.length > 0 &&
                        <div className="grid gap-y-6">
                            <div className="border rounded-lg">
                                <Table className="w-full" size="small">
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            {SHEARING_HEADING.map((item, index) => (
                                                <TableCell key={index} className={`${classes.tableHeadCell} first:pl-4 first:text-left last:pr-4 last:text-right`}>{item}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {shearing.map((data: any, index: number) => (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                                <TableCell className={classes.tableCell}>{data?.length}</TableCell>
                                                <TableCell className={classes.tableCell}>{data?.pieces}</TableCell>
                                                <TableCell className={classes.tableCell}>{data?.weightPerPiece}</TableCell>
                                                <TableCell className={classes.tableCell}>{data?.weight}</TableCell>
                                                <TableCell className={classes.tableCell}>{toInr(data?.chargesPerMT)}</TableCell>
                                                <TableCell className={classes.tableCell}>{toInr(data?.totalCharges)}</TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <div className="flex justify-end">
                                                        <div className='m-1 cursor-pointer'>
                                                            <img src={editIcon} alt="editIcon" onClick={() => handleEdit(data)} />
                                                        </div>

                                                        {!editState &&
                                                            <div className='m-1 cursor-pointer' onClick={() => handleDelete(data?.id, data?.weight)}>
                                                                <img src={deleteIcon} alt="deleteIcon" />
                                                            </div>
                                                        }
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>

                            <div className="flex justify-between">
                                <div className="grid gap-y-2 w-1/3">
                                    <div className={`${classes.details} text-sm font-medium`}>Weight Details</div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                        <div >Balance Weight</div>
                                        <div className={`${classes.detailBalancedWeight}`}>{balanceWeight.toFixed(3)} MT</div>
                                    </div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-sm font-medium`}>
                                        <div>Current Cumulative Wt.</div>
                                        <div>{currentCumulativeWeight} MT</div>
                                    </div>
                                </div>

                                <div className="grid gap-y-2 h-fit w-1/3">
                                    <div className={`${classes.details} text-sm font-medium`}>Price Details</div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                        <div>Grand Total</div>
                                        <div>{toInr(grandTotal)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {shearing?.length > 0 && !editState &&
                        <div className="flex justify-between">
                            <div>
                                <Button className="!px-0" label="Delete All Service" variant="primaryText" size="medium" onClick={handleDeleteAll} />
                            </div>

                            <div className="flex gap-x-4 items-center">
                                <Button label="Cancel" variant="tertiaryContained" size="medium" onClick={(event) => handleDialogClose(event, "closeButtonClick")} />
                                <Button label="Save" variant="secondaryContained" size="medium" onClick={handleSave} />
                            </div>
                        </div>
                    }
                </div>
            </div>


            <div className={`${classes.mobileContainer} gap-y-4 p-4`}>
                <div className="flex justify-between">
                    <div className={`${classes.mainHeading} text-2xl font-medium `}>Shearing (CTL)</div>
                    {!editState &&
                        <CloseIcon className={`${classes.closeIcon} cursor-pointer`} onClick={(event) => handleDialogClose(event, "closeButtonClick")} />}
                </div>

                <div className={`${classes.container} grid gap-y-6 pt-4`}>
                    {balanceWeight === selectedWeight &&
                        <div className="grid gap-y-2">
                            <div className={`${classes.balancedWeight} py-2 px-4 rounded-3xl`}>{`Selected Weight: ${selectedWeight} MT`}</div>
                            <div className={`${classes.selectedWidth} py-2 px-4 rounded-3xl`}>{`Selected Width: ${width} mm`}</div>
                        </div>
                    }

                    {balanceWeight !== selectedWeight &&
                        <div className="grid gap-y-2">
                            <div className={`${classes.balancedWeight} py-2 px-4 rounded-3xl`}>{`Balanced Weight: ${balanceWeight.toFixed(3)} MT`}</div>
                            <div className={`${classes.selectedWidth} py-2 px-4 rounded-3xl`}>{`Selected Width: ${width} mm`}</div>
                            <div className={` ${classes.selectedWeight} py-2 px-4 text-xs`}>{`Selected Weight: ${selectedWeight} MT`}</div>
                        </div>
                    }

                    <form className="grid gap-y-6" onSubmit={formik.handleSubmit}>
                        <div className={`${classes.subContainer} grid gap-y-4 p-3 rounded-xl`}>
                            <div className={`${classes.mainHeading} text-base font-medium`}>Please Share the value of length required along with either No. of Pieces or Weight (MT)</div>
                            <div className="grid gap-y-2">
                                <div className="grid gap-y-1 w-full">
                                    <TextFieldV2
                                        label="Enter the length you want"
                                        fullWidth
                                        type="number"
                                        placeholder="Enter here"
                                        {...formik.getFieldProps("length")}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" className={`${classes.uom} pr-4 text-base font-normal`}>
                                                    MM
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formik.touched && formik.touched?.length && formik.errors?.length && (
                                        <div className={classes.errorMessage}>
                                            <small>{formik.errors?.length}</small>
                                        </div>
                                    )}
                                    {balanceWeight < weightPerPiece && (
                                        <div className={classes.errorMessage}>
                                            <small> Balance Weight should be greater than {weightPerPiece.toFixed(3)} MT or length should be less than {formik.values.length} mm.</small>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={` ${classes.checkBox} grid gap-y-1.5 pt-4`}>
                                <div className="grid gap-y-4">
                                    {requestOptions.map((item: any) => (
                                        <div className="flex flex-col gap-y-1.5" key={item.id}>
                                            <div className="flex gap-x-0.5">
                                                <div>
                                                    <Radio
                                                        sx={{ padding: "6px", color: "#B3B3B3" }}
                                                        checked={selectedOption === item.id}
                                                        onChange={() => handleRadioChange(item.id)}
                                                    />
                                                </div>
                                                <div className={`${classes.checkBoxValue} my-auto text-base font-normal`}>{item.name}</div>
                                            </div>
                                            {selectedOption === item.id && (
                                                <div className="mt-2">
                                                    {item.id === 1 && (
                                                        <>
                                                            <TextFieldV2
                                                                label="Enter Number of Pieces"
                                                                type="number"
                                                                placeholder="Enter here"
                                                                onChange={(e: any) => handleInputChange("pieces", e.target.value)}
                                                                value={formik.values.pieces}
                                                            />
                                                            {formik.touched.pieces && !!formik.errors.pieces && (
                                                                <div className={classes.errorMessage}>
                                                                    <small>{formik?.errors?.pieces}</small>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    {item.id === 2 && (
                                                        <>
                                                            <TextFieldV2
                                                                label="Enter Requested Weight (MT)"
                                                                type="number"
                                                                placeholder="Enter here"
                                                                onChange={(e: any) => handleInputChange("requestWeight", e.target.value)}
                                                                value={formik.values.requestWeight}
                                                            />
                                                            {formik.touched.requestWeight && formik.errors.requestWeight && (
                                                                <div className={classes.errorMessage}>
                                                                    <small>{formik.errors?.requestWeight}</small>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                <div>
                                    {formik.touched.pieces && formik.errors.pieces && selectedOption === null && (
                                        <div className={classes.errorMessage}>
                                            <small>Select Either one field</small>
                                        </div>
                                    )}
                                    {formik.touched.minBalanceWeight && formik.errors.minBalanceWeight && (
                                        <div className={classes.errorMessage}>
                                            <small>{formik.errors?.minBalanceWeight}</small>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {!editState &&
                            <div className="flex justify-end">
                                <div className="flex gap-x-4 items-center">
                                    {
                                        shearing?.length === 0 && <Button label="Cancel" variant="tertiaryContained" size="medium" onClick={(event) => handleDialogClose(event, "closeButtonClick")} />
                                    }
                                    <Button label={shearing?.length >= 1 ? "+Add Another" : "+Add"} variant="secondaryContained" size="medium" type="submit" />
                                </div>
                            </div>
                        }

                        {shearing?.length > 0 && editState &&
                            <div className="flex justify-end">
                                <div className="flex gap-x-4 items-center">
                                    <Button label="Cancel" variant="tertiaryContained" size="medium" onClick={() => handleCancelEdit(shearing)} />
                                    <Button label="Save" variant="secondaryContained" size="medium" type="submit" />
                                </div>
                            </div>
                        }
                    </form>

                    {shearing?.length > 0 &&
                        <div className="grid gap-y-6">
                            <div className="grid gap-y-2">

                                <div className={`overflow-x-auto overflow-hidden w-full`}>
                                    <div className={`${classes.tableContaier} border rounded-lg`}>
                                        <Table className="w-full" size="small">
                                            <TableHead className={classes.tableHead}>
                                                <TableRow>
                                                    {SHEARING_HEADING.map((item, index) => (
                                                        <TableCell key={index} className={`${classes.tableHeadCell} first:pl-4 first:text-left last:pr-4 last:text-right`}>{item}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {shearing.map((data: any, index: number) => (
                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                                        <TableCell className={classes.tableCell}>{data?.length}</TableCell>
                                                        <TableCell className={classes.tableCell}>{data?.pieces}</TableCell>
                                                        <TableCell className={classes.tableCell}>{data?.weightPerPiece}</TableCell>
                                                        <TableCell className={classes.tableCell}>{data?.weight}</TableCell>
                                                        <TableCell className={classes.tableCell}>{toInr(data?.chargesPerMT)}</TableCell>
                                                        <TableCell className={classes.tableCell}>{toInr(data?.totalCharges)}</TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <div className="flex justify-end">
                                                                <div className='m-1 cursor-pointer'>
                                                                    <img src={editIcon} alt="editIcon" onClick={() => handleEdit(data)} />
                                                                </div>

                                                                {!editState &&
                                                                    <div className='m-1 cursor-pointer' onClick={() => handleDelete(data?.id, data?.weight)}>
                                                                        <img src={deleteIcon} alt="deleteIcon" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>

                                <div className="flex justify-end">
                                    <Button className="!px-0" label="Delete All Service" variant="primaryText" size="medium" onClick={handleDeleteAll} />
                                </div>
                            </div>

                            <div className="grid gap-y-6">
                                <div className="grid gap-y-2 w-full">
                                    <div className={`${classes.details} text-sm font-medium`}>Weight Details</div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                        <div >Balance Weight</div>
                                        <div className={`${classes.detailBalancedWeight}`}>{balanceWeight.toFixed(3)} MT</div>
                                    </div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-sm font-medium`}>
                                        <div>Current Cumulative Wt.</div>
                                        <div>{currentCumulativeWeight} MT</div>
                                    </div>
                                </div>

                                <div className="grid gap-y-2 h-fit w-full">
                                    <div className={`${classes.details} text-sm font-medium`}>Price Details</div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                        <div>Grand Total</div>
                                        <div>{toInr(grandTotal)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {shearing?.length > 0 && !editState &&
                        <div className="flex justify-end">


                            <div className="flex gap-x-4 items-center">
                                <Button label="Cancel" variant="tertiaryContained" size="medium" onClick={(event) => handleDialogClose(event, "closeButtonClick")} />
                                <Button label="Save" variant="secondaryContained" size="medium" onClick={handleSave} />
                            </div>
                        </div>
                    }
                </div>
            </div>


            {showDeletePopUp &&
                <SuccessFailurePopup
                    setShowPopup={setShowDeletePopUp}
                    status={false}
                    heading="Delete Service?"
                    description="Are you sure you want to delete this?"
                    button1={{
                        text: "Yes",
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: handleDeleteConfirmed,
                    }}
                    button2={{
                        text: "No",
                        variant: "secondaryContained",
                        size: "large",
                        onClick: handleCancelDelete,
                    }}
                />
            }

            {showDeleteAllPopUp &&
                <SuccessFailurePopup
                    setShowPopup={setShowDeleteAllPopUp}
                    status={false}
                    heading="Delete All Service?"
                    description="Are you sure you want to delete this?"
                    button1={{
                        text: "Yes",
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: handleDeleteAllConfirmed,
                    }}
                    button2={{
                        text: "No",
                        variant: "secondaryContained",
                        size: "large",
                        onClick: handleCancelDeleteAll,
                    }}
                />
            }

        </Dialog>
    )
}

export default ShearingCustomizationPopup;