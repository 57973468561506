import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { CART_LIFE_CYCLE, CLASS_TYPES, CUSTOMER_ROUTES, PRODUCTS_TYPES } from "../../../utils/constant";
import noImage from "../../../assets/images/noImage.jpg"
import { formatUOM, makeRoute, sortAttributes } from "../../../utils/helper";
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS } from "../../../utils/types";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { WishlistContext } from "../../../contexts/WishlistContext";
import { useWishlistService } from "../../../services/useWishlistService";

interface GridItemProps {
    data: any;
    favoriteItemIds?: number[];
    setFavoriteItemIds?: (prevIds: (prevIds: number[]) => number[]) => void;
    isImageVisible?: boolean;
    styles?: string;
    label?: string
}
export interface IStyle {
    [key: string]: string
}
const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.borderV2.secondary.secondary100}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            // boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.primaryDark}`,
            // boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.06)"
        },
        height: "390px"
    },
    productWrapper: {
        // height: "66px",
    },
    iconContainer: {
        top: "16px",
        right: "12px",
        backgroundColor: "#FCE9EA",
        borderRadius: "100px",
        color: theme.palette.v3.text.secondaryDarkAccent,
    },
    iconStyle: {
        // color: theme.palette.v3.background.secondaryDarkAccent,
    },
    productTitle: {
        color: theme.palette.textV2.secondary.secondary500,
        // marginBottom: "8px",
    },
    attributeTitle: {
        color: theme.palette.textV2.tertiary.tertiary600,
        letterSpacing: "0.12px"
    },
    attributeValue: {
        color: theme.palette.textV2.secondary.secondary500,
        letterSpacing: "0.12px",
    },
    cardButton: { },
    cardPrimaryBlue: {
        color: theme.palette.textV2.secondary.secondary500,
        borderBottom: `1px solid ${theme.palette.borderV2.secondary.secondary100}`,
    },
    catalogueName: {
        color: theme.palette.textV2.primary.primary900,
        height: "44px",
        lineHeight: "22.4px"
    },
    classType: {
        backgroundColor: theme.palette.textV2.secondary.secondary50,
        color: theme.palette.textV2.primary.primary500,
    },
    "@media (max-width: 480px)": {
        cardButton: {
            fontSize: '14px !important',
            padding: '10px 16px',
        },
        catalogueName: {
            color: theme.palette.textV2.primary.primary900,
            fontSize: '14px',
            fontWeight: '600',
        },
    },
}));
const CatalogueGridItem: React.FC<GridItemProps> = ({ data, favoriteItemIds, setFavoriteItemIds, isImageVisible = true, styles, label }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { metaData: filterData } = useMetaDataService();
    const onViewCatalogue = (event: any) => {
        if (event.target.closest("#child")) return;
        // event.stopPropagation();
        if (data.classType === CLASS_STATES.STANDARD || data.classType === CLASS_STATES.NONSTANDARD) {
            if (data.catalogueType === CATALOGUE_TYPE_STATES.CURRENT_INVENTORY) {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION, { query: { "catalogueId": data.id } }))
            }
            else if (data.catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_MTO_VIEW, { query: { "catalogueId": data.id } }));
            }
        }
        else if (data.classType === CLASS_STATES.SECONDARY) {
            if (data.catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                navigate(makeRoute(CUSTOMER_ROUTES.SECONDARY_CATALOGUE_LOT_VIEW, { query: { "secondaryCatalogueId": data.id } }));
            }
            else {
                navigate(makeRoute(CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION, { query: { "secondaryCatalogueId": data.id } }));
            }
        }
    }
    const sortedAttributes = sortAttributes(data.attributes, filterData.attributes);
    const { user } = useAuthenticatedUser();
    const wishlistContextData = useContext(WishlistContext);
    const wishlistService = useWishlistService();
    const handleFavoriteClick = () => (event: any) => {
        if (event.target.closest("#child")) {
            updateWishlist();
        }
    };
    const updateWishlist = async () => {
        try {
            const payload: any = {
                userId: user?.id,
                favourites: {
                    catalogueIds: [...wishlistContextData.favourites.catalogueIds],
                    secondaryCatalogueIds: [...wishlistContextData.favourites.secondaryCatalogueIds]
                }
            };
            if (data.classType === CLASS_STATES.STANDARD || data.classType === CLASS_STATES.NONSTANDARD) {
                payload.favourites.catalogueIds = payload.favourites.catalogueIds.filter((cId: number) => cId !== data.id);
            } else if (data.classType === CLASS_STATES.SECONDARY) {
                payload.favourites.secondaryCatalogueIds = payload.favourites.secondaryCatalogueIds.filter((cId: number) => cId !== data.id);
            }
            const wishlistResponse = await wishlistService.updateWishlist(payload);
            if (wishlistResponse?.status === HTTP_STATUS.OK) {
                await wishlistContextData.syncWishlist(CART_LIFE_CYCLE.REMOVE_PRODUCT);
                return true;
            } else {
                throw new Error("Wishlist Details fetch failed");
            }
        } catch (error) {
            return false;
        }
    };
    const [hovered, setHovered] = useState<boolean>(false)
    const getClassType = (id: string) => {
        const classType = CLASS_TYPES.find(type => type.id === id);
        return classType ? classType.name : id;
    }
    return (
        <div className={`grid gap-y-3 pb-4 ${classes.container} ${styles} bg-white h-full  cursor-pointer mt-0 rounded-2xl`} onClick={onViewCatalogue} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            {isImageVisible && (
                <div className="relative mt-0 my-auto z-0">
                    <img className="w-full rounded-t-2xl h-40 object-cover" src={data.catalogueImagePath[0] || noImage} alt="CatalogueImage"/>
                    {label === "wishlist" && hovered &&
                        <div className={`${classes.iconContainer} flex gap-x-2 px-4 py-2 absolute z-20 cursor-pointer text-sm font-medium`} id="child" onClick={handleFavoriteClick()} >Remove</div>
                    }
                </div>
            )}
            <div className="grid gap-y-3 px-4  mt-0 my-auto">
                <div className="grid gap-y-3 mt-0 ">
                    <div className={`grid gap-y-2 pb-3 ${classes.cardPrimaryBlue}`}>
                        <div className="flex justify-between">
                            <div className={`${classes.productTitle} flex justify-center items-center text-xs font-normal `}>{data.brand}</div>
                            {label === "wishlist" &&
                                <div className={`${classes.productTitle} ${classes.classType}  rounded-3xl py-1.5 px-4 text-xs font-medium `}>{getClassType(data.classType)}</div>
                            }
                        </div>
                        <div className={`text-base font-semibold text-wrap truncate ${classes.catalogueName}`}>{data.name.length > 55 ? data.name.substring(0, 55) + "..." : data.name}</div>
                    </div>
                    <div className="flex gap-x-3">
                        {sortedAttributes.splice(0, 3).map(({ key, value }) => {
                            let [min, max, uom] = value as string[];
                            min = min.replace(/\.0*$/, '');
                            max = max.replace(/\.0*$/, '');
                            const isRangeZero = min === max || max === "";
                            return (
                                <div className="grid gap-y-1 first:text-left last:text-right text-center flex-1 first:flex-none last:flex-none" key={key}>
                                    <div className={`${classes.attributeTitle} text-xs font-normal`}>{key}</div>
                                    <div className={`${classes.attributeValue} text-xs font-semibold h-8`}>{formatUOM(min, uom, isRangeZero ? (min) : `${min}-${max}`)}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="flex">
                    <Button className={`w-full ${classes.cardButton}`} label="View More" variant="tertiaryContained"/>
                </div>
            </div>
        </div>
    );
};

export default CatalogueGridItem;
