import React, { useState } from 'react';
import { IImage } from '../../templates/Catalogue/CatalogueImage.template';
import { createUseStyles } from 'react-jss';
import { Height } from '@mui/icons-material';

export interface IImageGroupProps {
    images?: IImage[];
    activeIndex?: number;
    onClick: (index: number) => void;
    height?: any;
    width?: any;
}

const useStyles = createUseStyles((theme: any) => ({
    selectedImage: {
        border: `1px solid ${ theme.palette.v2.border.primaryDark }`,
        width: "74px",
        height: "74px"
    },
    image: {
        width: "74px",
        height: "74px"
    },
    "@media (max-width: 480px)": {
        selectedImage: {
            width: "60px",
            height: "60px"
        },
        image: {
            width: "60px",
            height: "60px"
        },
    }
}))

const ImageGroup: React.FC<IImageGroupProps> = ({ images, activeIndex = 0, onClick, height, width }) => {

    const classes = useStyles();
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(activeIndex)

    const handleOnClick = (index: number) => () => {
        onClick(index);
        setSelectedImageIndex(index);
    }

    const divStyle = { height, width };

    return (
        <div className='grid grid-cols-4 gap-x-4 w-full'>
            {images?.slice(0, Math.min(4, images.length)).map((image: IImage, index: number) => (
                <div key={image.id}>
                    <img className={`cursor-pointer rounded-lg ${divStyle} ${selectedImageIndex === index ? classes.selectedImage : classes.image}`} src={image.path} onClick={handleOnClick(index)} alt="index" />
                </div>
            ))}
        </div>
    )
}

export default ImageGroup;
