import React from "react";
import Box from '@mui/material/Box';
import { Tab, Typography } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import { ProductTabs, tabprops } from './ProductTabs.Template';
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";

const AutoMobilesTemplate: React.FC = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <section> 
                <div className={classes.banner_main}>
                    <div className={`${classes.banner_wrapper} ${classes.container}`}>
                        <div className={classes.banner_content}>
                            <span>Flat Rolled Steel</span>
                            <h1>Automobiles</h1>
                            <p>Cold-rolled steel is essential in the automotive industry for its strength, formability, and
                                consistency. It's used to make body panels, structural components, and chassis parts, providing
                                a uniform surface finish and precise thickness. It plays a crucial role in enhancing the
                                performance and aesthetics of vehicles.</p>
                            {/* <!-- <div className="btn">
                        <a href="#">Learn More</a>
                    </div> --> */}
                        </div>
                        <div className={classes.banner_preview}>
                            <div>
                                <img src="../assets/images/Automobiles/bannerImage1.webp" alt="" />
                            </div>
                            <div className={classes.banner_preview_content_secondary}>
                                <img src="../assets/images/Automobiles/bannerImage2.webp" alt="" />
                            </div>
                        </div>
                        {/* <div className="banner_mobile">
                    <img src="../assets/images/Automobiles/bannerMobile.png" alt="" />
                </div> */}
                    </div>
                </div>
            </section>

            <section className={`${classes.products_about_us} ${classes.section_top_spacing}`}>
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>Flat Rolled Steel in Automobiles</h2>
                            <p className={classes.gal_card_des} >Cold-rolled steel is a crucial material in the automotive industry,
                                engineered to meet the strict demands of vehicle construction. It is renowned for its high
                                strength and formability, making it an ideal choice for critical components such as body panels,
                                structural elements, and chassis parts. Its uniform thickness and smooth surface finish make it
                                perfect for achieving precision in shaping intricate designs while ensuring durability. The use
                                of cold-rolled steel in the automotive industry is particularly valued for its ability to
                                enhance safety by contributing to the structural integrity of vehicles. Whether forming the
                                frame of a car or providing the foundation for safety features, cold-rolled steel plays a
                                multifaceted role in elevating the performance, safety, and overall quality of automobiles.
                            </p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/Automobiles/About.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        
            <div className={classes.container}>
                <Box>
                    <Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Body Panels" {...tabprops(0)} />
                            <Tab label="Structural Components" {...tabprops(1)} />
                            <Tab label="Safety Features" {...tabprops(2)} />
                            <Tab label="Suspension Systems" {...tabprops(3)} />
                            <Tab label="Interior Components" {...tabprops(4)} />
                        </Tabs>
                    </Box>
                    <ProductTabs value={value} index={0}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Automobiles/bodyPanels.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Body Panels</h2>
                                <p className={classes.gal_card_des} >Automobile body panels benefit from the strength and formability of
                                    cold-rolled steel. This type of steel enhances the structural integrity and safety features
                                    of vehicles, including cars and trucks.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={1}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Automobiles/structuralComponents.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Structural Components</h2>
                                <p className={classes.gal_card_des} >Cold-rolled steel is ideal for structural components in construction and
                                    manufacturing due to its uniformity, strength, and precise dimensions.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={2}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Automobiles/safetyFeatures.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Safety Features</h2>
                                <p className={classes.gal_card_des} >Cold-rolled steel is often used in appliances and vehicles for its
                                    strength and reliability. It enhances the safety of products, providing longevity and
                                    protection with impact-resistant panels and structural reinforcements.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={3}>

                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Automobiles/Suspension.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Suspension Systems</h2>
                                <p className={classes.gal_card_des} >Cold-rolled steel is commonly used in the auto industry for suspension
                                    components due to its strength and fatigue resistance, ensuring durability and performance.
                                </p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={4}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Automobiles/Interior.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Interior Components</h2>
                                <p className={classes.gal_card_des} >Cold-rolled steel forms diverse components, from appliances to auto
                                    interiors. Its flexibility enables intricate designs, and its durability ensures
                                    long-lasting functionality and aesthetics.</p>
                            </div>
                        </div>
                    </ProductTabs>
                </Box>
            </div>

            <NewsLetterTemplate />
        </>
    );
};

export default AutoMobilesTemplate;