import React from 'react'
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { AUTH_STATES } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import { IncognitoUser } from './Authentication.template';
import Emial from "../../../assets/images/Email.svg"
import line from "../../../assets/images/Line 2.svg"
import { createUseStyles } from 'react-jss';
import AuthDialogFooter from '../../molecules/AuthDialogFooter/AuthDialogFooter';

interface EmailConfirmationTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    sentEmailType: AUTH_STATES;
    userDetails: IncognitoUser;
    authState: AUTH_STATES;
}

const useStyles = createUseStyles((theme: any) => ({
    content: {
        color: theme.palette.button.primaryLight
    },
    orange: {
        color: theme.palette.miscellaneous.emailConfirmationOrange
    }
}));

const EmailConfirmationTemplate: React.FC<EmailConfirmationTemplateProps> = ({ setAuthState, userDetails, sentEmailType }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user, syncAuthDialogActive } = useAuthenticatedUser();
    const handleDialogClose = () => {
        syncAuthDialogActive(false);
        setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE)
        user && !user.businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
    };

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }

    return (
        <div className='flex flex-col text-center items-center gap-4 w-full'>
            <div>
                <img src={Emial} alt="EMAIL ENVELOP" />
            </div>
            <div className={`text-lg font-medium `}>Please check your Email</div>
            <span><img src={line} alt="__" /></span>
            <div className='flex flex-col'>
                <span className={`font-base ${classes.content}`}>We have sent a confirmation email to</span>
                <span className={`text-lg font-medium ${classes.orange}`}>{userDetails.email || "your email id"}</span>
            </div>
            <div className={`w-2/4 ${classes.content}`}>
                Check your email and click on the link to {sentEmailType === AUTH_STATES.FORGOT_PASSWORD ? "change your password" : "confirm"}.
            </div>
            {
                sentEmailType === AUTH_STATES.EMAIL_REGISTRATION && (
                        <div className='w-full'>
                            <Button variant="containedLight" label="Continue Browsing" type="submit" className="!h-12 w-full" onClick={handleDialogClose}/>
                        </div>
                )
            }
                { sentEmailType !== AUTH_STATES.FORGOT_PASSWORD && <AuthDialogFooter setAuthStateTo={setAuthStateTo} section={AUTH_STATES.LOGIN_WITH_EMAIL} loginType={AUTH_STATES.LOGIN_WITH_EMAIL} isLogin={false}/>  }
        </div>
    )
}

export default EmailConfirmationTemplate