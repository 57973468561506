import React, { useEffect, useState } from 'react';
import { useCatalogueService } from '../../../services/useCatalogueService';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from "react-router-dom";
import { CUSTOMER_ROUTES } from "../../../utils/constant";
import { createUseStyles } from 'react-jss';
import Search from "@mui/icons-material/Search";
import SearchIcon from "../../../assets/images/headerSearch.svg";
import { CATALOGUE_TYPE_STATES, CLASS_STATES } from '../../../utils/types';

interface ICatalogueSearch {
    id: number | string;
    name: string;
}

const useStyles = createUseStyles((theme: any) => ({

    quantityInput: {
        "& .MuiOutlinedInput-root": {
            padding: "5px",
            border: 'none'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 'none'
        },
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        fontSize: '1.25vw',
        paddingLeft: '10px',
        border: `1px solid ${theme.palette.v3.border.searchBarBorder}`,
        width: '100%',
    },
    tabContainer: {
        display: "flex",
    },
    tabMobileContainer: {
        display: "none"
    },
    "@media (max-width: 476px)": {
        tabContainer: {
            display: "none",
        },
        tabMobileContainer: {
            display: "flex",
            flex: "1",
        },
        quantityInput: {
            "& .MuiAutocomplete-popupIndicator": {
                display: 'none'
            },
            "& .MuiOutlinedInput-root": {
                border: 'none !important',
            },
        },
    },
}));

const HeaderSearch: React.FC = () => {

    const classes = useStyles()

    const [suggestions, setSuggestionsTo] = useState<ICatalogueSearch[]>([]);
    const [selectedCatalogue, setSelectedCatalogueTo] = useState<ICatalogueSearch | null>(null);

    const catalogueService = useCatalogueService();

    const navigate = useNavigate();
    const location = useLocation();

    const fetchCatalogues = async (searchText: string) => {
        try {
            // *********************************TODO- fix api and remove the interation************************************ */
            const payload = {
                name: searchText
            };
            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 20,
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse.data.data;
                setSuggestionsTo(content);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setSuggestionsTo([]);
            setSelectedCatalogueTo(null);
        }
    };

    const handleInputChange = (event: React.SyntheticEvent, value: string) => {
        fetchCatalogues(value);
    };

    const handleSuggestionSelect = (event: React.ChangeEvent<{}>, value: any | null) => {
        if (value) {
            const { classType, catalogueType } = value;
            setSelectedCatalogueTo(value);
            if (classType === CLASS_STATES.STANDARD || classType === CLASS_STATES.NONSTANDARD) {
                if (catalogueType === CATALOGUE_TYPE_STATES.CURRENT_INVENTORY) {
                    navigate(`${CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION}?catalogueId=${value.id}`);
                }
                else if (catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                    navigate(`${CUSTOMER_ROUTES.CATALOGUE_MTO_VIEW}?catalogueId=${value.id}`);
                }
            }
            else if (classType === CLASS_STATES.SECONDARY) {
                if (catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                    navigate(`${CUSTOMER_ROUTES.SECONDARY_CATALOGUE_LOT_VIEW}?secondaryCatalogueId=${value.id}`);
                }
                else {
                    navigate(`${CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION}?secondaryCatalogueId=${value.id}`);
                }
            }
        }
    };

    useEffect(() => {
        if (!location.pathname.startsWith("/catalogue")) {
            setSelectedCatalogueTo(null);
        }
    }, [location.pathname]);

    useEffect(() => {
        fetchCatalogues('');
    }, []);

    return (
        <>
            <div className={`${classes.tabContainer} w-full`}>
                <Autocomplete
                    options={suggestions}
                    getOptionLabel={(option) => option?.name}
                    onInputChange={handleInputChange}
                    onChange={handleSuggestionSelect}
                    value={selectedCatalogue}
                    renderInput={(params) => (
                        <div className={`${classes.searchContainer} ${classes.quantityInput} !w-full`}>
                            <img src={SearchIcon} alt="searchLogo" className="w-5" />
                            <TextField
                                {...params}
                                placeholder='Search...'
                                variant="outlined"
                                InputLabelProps={{
                                    children: 'Search',
                                }}
                            />
                        </div>
                    )}
                /> 
            </div>
            <div className={classes.tabMobileContainer}>
                <Autocomplete
                    options={suggestions}
                    getOptionLabel={(option: ICatalogueSearch) => option?.name}
                    onInputChange={handleInputChange}
                    onChange={handleSuggestionSelect}
                    renderInput={(params) => (
                        <div className={`${classes.searchContainer}  ${classes.quantityInput}`}>
                            <img src={SearchIcon} alt="searchLogo" className="w-5" />
                            <TextField
                                {...params}
                                placeholder='Search...'
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    children: 'Search',
                                }}
                            />
                        </div>
                    )}
                />
            </div>
        </>
    );
};

export default HeaderSearch;