import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from '.';
import { IMaterialRequirement } from '../components/pages/DashBoard/MyMaterialProduct.page';
import { replaceUrlParams } from '../utils/helper';

interface MaterialGetRequestBody {
    classType: string | null,
    categoryId: number | null
}

interface IMaterialRequirementParams{
    page: number;
    size: number;
    sort?: string;
}

const MATERIAL_REQUIREMENT_URLS = {
    GET_ALL_MATERIAL_REQUIREMENTS: "/material-requirements",
    POST_MATERIAL_REQUIREMENTS: "/material-requirements/create",
    PUT_MATERIAL_REQUIREMENTS: "/material-requirements/:id",
    GET_MATERIAL_REQUIREMENT_BY_ID: "/material-requirements/:id",
    DELETE_MATERIAL_REQUIREMENT_BY_ID: "/material-requirements/:id",
}

export const useMaterialRequirementService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();
  
    const getAllMaterialRequirements = (data: MaterialGetRequestBody, params: IMaterialRequirementParams) => {
        return request(API_METHOD.POST, MATERIAL_REQUIREMENT_URLS.GET_ALL_MATERIAL_REQUIREMENTS, authenticatedUser, data, { params  });
    };

    const getMaterialRequirementById = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(MATERIAL_REQUIREMENT_URLS.GET_MATERIAL_REQUIREMENT_BY_ID, { id }), authenticatedUser, null);
    };

    const deleteMaterialRequirementById = (id: number) => {
        return request(API_METHOD.DELETE, replaceUrlParams(MATERIAL_REQUIREMENT_URLS.DELETE_MATERIAL_REQUIREMENT_BY_ID, { id }), authenticatedUser, null);
    };

    const createMaterialRequirements = (data: IMaterialRequirement[]) => {
        return request(API_METHOD.POST, MATERIAL_REQUIREMENT_URLS.POST_MATERIAL_REQUIREMENTS, authenticatedUser, data);
    };

    const updateMaterialRequirements = (data: IMaterialRequirement, id: number) => {
        return request(API_METHOD.PUT, replaceUrlParams(MATERIAL_REQUIREMENT_URLS.PUT_MATERIAL_REQUIREMENTS, { id }), authenticatedUser, data);
    };
    return {
        getAllMaterialRequirements, createMaterialRequirements, updateMaterialRequirements, getMaterialRequirementById, deleteMaterialRequirementById
    };
  };
  