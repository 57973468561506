import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import successBackground from "../../../assets/images/rfqSuccessBackground.png"
import Button from "../../atoms/Button/Button";
import CloseIcon from '@mui/icons-material/Close';

interface ThanksModalTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    onContinue?: ()=> void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "694px !important",
            maxWidth: "694px !important",
            borderRadius: "16px"
        },
    },
    dialogContainer: {
        backgroundImage: `url(${successBackground})`,
        padding: "240px 16px 44px 16px"
    },
    mainHeading:{
        color: theme.palette.textV2.success.success500,
        fontSize: "36px"
    },
    content:{
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "14px"
    },
    closeIcon:{
        color: theme.palette.textV2.primary.primary500,
    },
    "@media (max-width: 480px)": {
        container: {
            "& .MuiDialog-paper": {
                width: "448px !important",
                maxWidth: "448px !important",
                height: "826px",
                margin: "16px",
            }
        },
        mainHeading:{
            fontSize: "24px"
        },
        content:{
            fontSize: "12px"
        },
        dialogContainer: {
            backgroundImage: `url(${successBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 65%",
            padding: "140px 16px 31px 16px"
        },
    },
}));

const ThanksModalTemplate: React.FC<ThanksModalTemplateProps> = ({ dialogOpen, setDialogOpen, onContinue }) => {
    const classes = useStyles();

    const handleDialogClose = () => {
        setDialogOpen(false);
        onContinue && onContinue();
    };

    return (
        <Dialog fullWidth className={classes.container} PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} onClose={handleDialogClose}>
                 <div className={`absolute top-0 right-0 p-4 cursor-pointer`} onClick={handleDialogClose}>
          <CloseIcon className={`${classes.closeIcon}`}/>
        </div>
            <div className={`${classes.dialogContainer} flex gap-x-6`}>
                <div className="grid gap-y-8">
                    <div className="grid gap-y-6">
                        <div className={`${classes.mainHeading} text-center font-semibold mx-auto`}>Thank you for reaching out to us! 🌟</div>
                        <div className={`${classes.content} grid gap-y-4 text-center`}>
                            <div>Your inquiry means a lot to us, and we're excited to assist you. Our team is dedicated to providing top-notch service and will respond to your query promptly.</div>
                            <div>In the meantime, feel free to explore more of our website to discover our products/services and learn about what makes us stand out. If you have any further questions or need assistance with anything else, don't hesitate to reach out.</div>
                            <div>Once again, thank you for choosing us. We look forward to serving you!</div>
                            <div>
                                <div>Best regards,</div>
                                <div> Avighna Team</div>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto">
                        <Button variant="tertiaryContained" label="Continue" onClick={handleDialogClose} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ThanksModalTemplate;