import React, { useState } from 'react'
import { PocSectionProps } from './KeyPersonDetails.template'
import Button from '../../../atoms/Button/Button'
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import { createUseStyles } from 'react-jss';
import { ViewKeyPersonDetailsTemplate } from './ViewKeyPersonDetails.template';
import AddKeyPersonDetailsTemplate from './AddKeyPersonDetails.template';
import { useBusinessProfileService } from '../../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS, KEY_PERSON_DETAIL_STATES } from '../../../../utils/types';
import Checkbox from '@mui/material/Checkbox';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.secondaryLight}`
    },
    authorizedText: {
        color: theme.palette.v4.text._primaryDark
    },
    text: {
        color: theme.palette.text.secondaryDark
    },
    checkedInputColor: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    }
}));

export const HeaderKeyPersonDetailsTemplate: React.FC<PocSectionProps> = ({ formik, setCurrentSectionTo }) => {

    const classes = useStyles()
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [isEditing, setIsEditing] = useState<number | null>(null);

    const handleEditClick = (index: number) => {
        if (isEditing === index) {
            if (Object.keys(formik.errors).length === 0) {
                formik.setFieldValue("noOfRows", 1);
                formik.handleSubmit();
                setIsEditing(null);
            }
            else {
                showSnackbar('error', 'Errors in fields');
            }
        } else {
            setIsEditing(index);
        }
    };

    const keyDetailProps: PocSectionProps = {
        setCurrentSectionTo: setCurrentSectionTo,
        formik: formik
    };

    const deletePoc = async (index: number) => {
        const pocs = [...formik.values.pocs];
        pocs.splice(index, 1);
        formik.setFieldValue('locations', pocs);
        try {
            const response = await bussinessProfileService.updateBusinessProfile(pocs, 'POCS');
            if (response?.status === HTTP_STATUS.OK) {
                showSnackbar("success", "Business profile updated successfully");
                window.location.reload();
            } else if (response?.status === HTTP_STATUS.BAD_REQUEST) {
                showSnackbar('error', response?.data?.statusMessage);
            } else {
                showSnackbar('error', "Error while updating business profile");
            }
        } catch (error) {
            showSnackbar("error", `Business profile update  failed ${error}`);
        }
    };

    const handleBackToViewPage = (index: number) => {
        if (isEditing === index) {
            setIsEditing(null);
        } else {
            setIsEditing(index);
        }    
    };

    return (
        <>
            {formik?.values?.pocs?.map((poc: any, index: number) => {
                return <div className={`${classes.container} mt-6 p-6  rounded-lg`} key={index}>
                    <div className='border-b-2 font-medium mb-6  pb-6 flex items-center justify-between text-2xl'>
                        <div>
                            <span className={` ${classes.text}`}>{poc.name}</span>
                            <span style={{ marginLeft: '10px' }}>
                                {poc.isAuthorized ? (
                                    <span className={classes.authorizedText}>(Authorized Account)</span>
                                ) : (
                                    <span className={classes.authorizedText}>(Unauthorized Account)</span>
                                )}
                            </span>
                        </div>
                        <div className='flex items-center gap-x-4 '>
                        {isEditing === index ? (
                            <div className="flex items-center ">
                                <Checkbox
                                    id={`isAuthorized-${index}`}
                                    name={`poc[${index}].isAuthorized`}
                                    checked={poc.isAuthorized}
                                    className={` ${classes.checkedInputColor}`}
                                    onChange={(e) => formik.setFieldValue(`poc[${index}].isAuthorized`, e.target.checked)} />
                                <label className='text-base'>Authorized Person</label>
                            </div>
                        ) : null}
                            {isEditing === index ? (
                                <Button
                                    variant={'tertiaryContained'}
                                    className='flex items-center'
                                    label={'Cancel'} onClick={() => handleBackToViewPage(index)}
                                    size='small'
                                />
                            )
                                : (
                                    null
                                )
                            }
                            <Button
                                variant={isEditing === index ? 'primaryContained' : 'tertiaryContained'}
                                className='flex items-center'
                                label={isEditing === index ? 'Save' : 'Edit'} onClick={() => handleEditClick(index)}
                                size='small'
                            />
                            {!poc.isAuthorized ? (
                                <img src={deleteIcon} alt="deleteicon" className="blink-on-hover blink-on-click" onClick={() => deletePoc(index)} />
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                    {isEditing === index ? (<AddKeyPersonDetailsTemplate  {...keyDetailProps} poc={poc} index={index} />)
                        :
                        (<ViewKeyPersonDetailsTemplate  {...keyDetailProps} poc={poc} index={index} />)
                    }
                </div>
            })}
        </>
    )
}
