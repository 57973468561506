import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { Grid } from "@mui/material";
import { IContext } from "../../pages/Cart/CartDetails.page-Deprecated";

import { formatUOM, formatUOMv2, sortAttributes, sortSecondarySpecificationAttributes, sortSpecificationAttributes, toInr, useDebounce } from "../../../utils/helper";
import { ATTRIBUTE_LABEL, COLOR_DATA, CUSTOMER_ROUTES } from "../../../utils/constant";
import { Attribute, CATALOGUE_TYPE_STATES } from "../../../utils/types";
import useMetaDataService from "../../../hooks/useMetaDataService";
import editPenIcon from "./../../../assets/images/editPenIcon.svg";
import cartDeleteIcon from "./../../../assets/images/cartDeleteIcon.svg";
import warehouseLocation from "./../../../assets/images/warehouseLocation.svg";
import { useNavigate } from "react-router-dom";
import SuccessFailurePopup from "../../molecules/SuccessFailurePopup/SuccessFailurePopup";
import { ICartItem, IValueAddedServices } from "../../pages/Cart/CartDetails.page";
import ValueAddedServicesDetailsPage from "./ValueAddedServices/ValueAddedServicesDetailsPage";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IColorPalette } from "../Catalogue/AttributeSelection.template";


interface CartItemProps {
    data: ICartItem;
    handleDeleteCartItem: (cartId: number) => void;
    handleCartItemUpdate: (cartId: number, key: string, value: number | string) => void;
    configuration: IContext;
    index?: any;
    isEditable?: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid",
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        padding: "16px 18px 16px 18px",
    },
    mobileContainer: {
        display: "none"
    },
    cartHeader: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    catalogueName: {
        color: theme.palette.textV2.primary.primary900,
    },
    productHeader: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    attributesBox: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
    },
    attributesName: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    attributesValue: {
        color: theme.palette.textV2.primary.primary800,
    },
    tableContainer: {
        color: theme.palette.textV2.tertiary.tertiary700,
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
    },
    subTotal: {
        color: theme.palette.textV2.tertiary.tertiary700,
        fontSize: '16px'
    },
    totalPrice: {
        color: theme.palette.textV2.primary.primary900,
        fontSize: '18px'
    },
    warehouseText: {
        color: theme.palette.textV2.primary.primary500,
        backgroundColor: theme.palette.backgroundV2.primary.primary50,
    },
    pricePerMT: {
        fontSize: '14px',
    },
    priceAndVas: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    upcCodeAndVasTitle: {
        color: theme.palette.textV2.primary.primary900,
        fontSize: '14px'
    },
    mobileAttributeContainer: {

    },
    attributeMiddleBorder: {

    },
    borderBottom:{

    },
    "@media (max-width: 480px)": {
        webContainer: {
            display: "none",
            border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            padding: "16px 18px 16px 18px",
        },
        mobileContainer: {
            border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            padding: "16px 0px",
            display: "grid"
        },
        subTotal: {
            fontSize: '14px'
        },
        mobileAttributeContainer: {
            borderTop: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            borderBottom: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        },
        attributeMiddleBorder: {
            borderTop: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        },
        borderBottom:{
            borderBottom: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        },
    },
}));

const CardItem: React.FC<CartItemProps> = ({ data, handleDeleteCartItem, handleCartItemUpdate, configuration, index, isEditable = true }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { metaData: filterData } = useMetaDataService();
    const [isDeletePopupVisible, setDeletePopVisibleTo] = useState(false);
    const [itemToDelete, setItemToDeleteTo] = useState<number>(-1);
    const [cancelIcon, setCancelIconTo] = useState(true);
    const [valueAddedServices, setValueAddedServicesTo] = useState<IValueAddedServices | null>(data?.valueAddedServices);
    const shearingTotalWeight = parseFloat(valueAddedServices?.shearing?.reduce((totalWeight, item) => totalWeight + item.weight, 0).toFixed(3) ?? '0');
    const slittingTotalWeight = parseFloat(valueAddedServices?.slitting?.reduce((totalWeight, item) => totalWeight + item.weightOfCoil, 0).toFixed(3) ?? '0');
    const shearingTotalCharges = valueAddedServices?.shearing?.reduce((total, item) => total + item.totalCharges, 0) ?? 0;
    const slittingTotalCharges = valueAddedServices?.slitting?.reduce((total, item) => total + item.totalCharges, 0) ?? 0;
    const [isVasPopup, setVasPopupTo] = useState<boolean>(false);

    const [showAll, setShowAll] = useState(false);
    const handleClick = () => {
        setShowAll(!showAll);
    };

    const getAttributeValue = (attributeName?: string, attributeValue?: string) => {
        try {
          if (attributeValue === undefined || attributeValue === '') {
            return '';
          }
          if (attributeName && attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
            return attributeValue;
          }
            const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-4 h-3 mr-1 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
    }

    const displayAttributeValue = (attributeName?: string, minValue?: string, maxValue?: string) => {

        if (minValue === maxValue || maxValue === "") {
            return (
                <div className="flex justify-center items-center">
                    {getAttributeValue(attributeName, minValue)}
                </div>
            );
        } else {
            return (
                <div className="flex justify-center items-center">
                    {getAttributeValue(attributeName, minValue)} - {getAttributeValue(attributeName, maxValue)}
                </div>
            );
        }
    };

    const displayAttributes = useMemo(() => {
        if (data?.upcId == null && data?.secondarySkuId == null) {
            return sortAttributes(data?.secondaryLotAttributes, filterData.attributes)?.slice(0, 6).map(({ key, value }) => {
                const [min, max, uom] = value as string[];
                const isRangeZero = min === max;
                return (
                    <div className="grid gap-y-1" key={key}>
                        <div className={`${classes.attributesName} text-xs text-center font-normal`}>
                            {key}
                        </div>
                        <div className={`${classes.attributesValue} text-sm text-center font-normal`}>
                            {!isRangeZero ? getAttributeValue(key, min ) : `${getAttributeValue(key, min)} - ${getAttributeValue(key, max)}`} {formatUOMv2(min, uom, isRangeZero ? (min) : `${min}-${max}`)}
                        </div>
                    </div>
                );
            });
        }

        if (data?.upcId != null) {
            return sortSpecificationAttributes(data?.attributes?.SPECIFICATION, filterData.attributes)?.slice(0, 6).map((attribute: Attribute) => (
                <div className="grid gap-y-1 mt-0" key={attribute.id}>
                    <div className={`${classes.attributesName} text-xs text-center font-normal`}>
                        {attribute?.name}
                    </div>
                    <div className={`${classes.attributesValue} text-sm text-center font-normal`}>
                        {getAttributeValue(attribute?.name, attribute?.attributeOptions)} {attribute?.attributeOptions && attribute.uom && formatUOM(attribute.attributeOptions, attribute.uom)}
                    </div>
                </div>
            ));
        } else {
            return sortSecondarySpecificationAttributes(data?.attributes?.productSpecification, filterData.attributes)
                ?.slice(0, 6)
                .map((attribute: Attribute) => {
                    const isRangeZero = attribute.minValue === attribute.maxValue;
        
                    return (
                        <div className="grid gap-y-1" key={attribute.id}>
                            <div className={`${classes.attributesName} text-xs font-normal`}>
                                {attribute?.name}
                            </div>
                            <div className={`${classes.attributesValue} text-sm text-center font-normal`}>
                                {isRangeZero 
                                    ? (
                                        <>
                                            {displayAttributeValue(attribute?.name, attribute?.minValue, attribute?.minValue)} {attribute.minValue && attribute.uom && formatUOM(attribute.minValue, attribute.uom)}
                                        </>
                                    ) : (
                                        <>
                                            {displayAttributeValue(attribute?.name, attribute?.minValue, attribute?.maxValue)} {attribute.minValue && attribute.uom && formatUOM(attribute.minValue, attribute.uom)}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    );
                });
        }
        
    }, [data]);


    const displayMobileAttributes = useMemo(() => {
        if (data?.upcId == null && data?.secondarySkuId == null) {
            return (
                <div className={`${classes.mobileAttributeContainer} p-4 grid `}>
                    <div className="flex justify-between">
                        {sortAttributes(data?.secondaryLotAttributes, filterData.attributes)?.slice(0, 3).map(({ key, value }) => {
                            const [min, max, uom] = value as string[];
                            const isRangeZero = min === max;
                            return (
                                <div className="grid gap-y-1 border" key={key}>
                                    <div className={`${classes.attributesName} text-xs font-normal`}>
                                        {key}
                                    </div>
                                    <div className={`${classes.attributesValue} text-sm font-normal flex gap-x-1`}>
                                        {!isRangeZero ? getAttributeValue(key, min ) : `${getAttributeValue(key, min)} - ${getAttributeValue(key, max)}`} {formatUOMv2(min, uom, isRangeZero ? (min) : `${min}-${max}`)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {showAll &&
                        <div className={`${classes.attributeMiddleBorder} flex justify-between pt-4 mt-4`}>
                            {sortAttributes(data?.secondaryLotAttributes, filterData.attributes)?.slice(3, 6).map(({ key, value }) => {
                                const [min, max, uom] = value as string[];
                                const isRangeZero = min === max;
                                return (
                                    <div className="grid gap-y-1" key={key}>
                                        <div className={`${classes.attributesName} text-xs font-normal`}>
                                            {key}
                                        </div>
                                        <div className={`${classes.attributesValue} text-sm font-normal flex gap-x-1`}>
                                            {!isRangeZero ? getAttributeValue(key, min ) : `${getAttributeValue(key, min)} - ${getAttributeValue(key, max)}`} {formatUOMv2(min, uom, isRangeZero ? (min) : `${min}-${max}`)}

                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            );
        }

        if (data?.upcId != null) {
            return (
                <div className={`${classes.mobileAttributeContainer} p-4 grid `}>
                    <div className="flex justify-between">
                        {sortSpecificationAttributes(data?.attributes?.SPECIFICATION, filterData.attributes)?.slice(0, 3).map((attribute: Attribute) => (
                            <div className="grid gap-y-1 mt-0 " key={attribute.id}>
                                <div className={`${classes.attributesName} text-xs font-normal`}>
                                    {attribute?.name}
                                </div>
                                <div className={`${classes.attributesValue} text-sm font-normal flex gap-x-1`}>
                                    {getAttributeValue(attribute?.name, attribute?.attributeOptions)} {attribute?.attributeOptions && attribute.uom && formatUOM(attribute.attributeOptions, attribute.uom)}

                                </div>
                            </div>
                        ))}
                    </div>
                    {showAll &&
                        <div className={`${classes.attributeMiddleBorder} flex justify-between pt-4 mt-4`}>
                            {sortSpecificationAttributes(data?.attributes?.SPECIFICATION, filterData.attributes)?.slice(3, 6).map((attribute: Attribute) => (
                                <div className="grid gap-y-1 mt-0" key={attribute.id}>
                                    <div className={`${classes.attributesName} text-xs font-normal`}>
                                        {attribute?.name}
                                    </div>
                                    <div className={`${classes.attributesValue} text-sm font-normal flex gap-x-1`}>
                                        {getAttributeValue(attribute?.name, attribute?.attributeOptions)} {attribute?.attributeOptions && attribute.uom && formatUOM(attribute.attributeOptions, attribute.uom)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <div className={`${classes.mobileAttributeContainer} p-4 grid `}>
                    <div className="flex justify-between">
                        {sortSecondarySpecificationAttributes(data?.attributes?.productSpecification, filterData.attributes)?.slice(0, 3).map((attribute: Attribute) => {
                        const isRangeZero = attribute.minValue === attribute.maxValue;

                        return (
                            <div className="grid gap-y-1 " key={attribute.id}>
                                <div className={`${classes.attributesName} text-xs font-normal`}>
                                    {attribute?.name}
                                </div>
                                <div className={`${classes.attributesValue} text-sm font-normal flex gap-x-1`}>
                                    {isRangeZero 
                                        ? (
                                            <>
                                                {displayAttributeValue(attribute?.name, attribute?.minValue, attribute?.minValue)} {attribute.minValue && attribute.uom && formatUOM(attribute.minValue, attribute.uom)}
                                            </>
                                        ) : (
                                            <>
                                                {displayAttributeValue(attribute?.name, attribute?.minValue, attribute?.maxValue)} {attribute.minValue && attribute.uom && formatUOM(attribute.minValue, attribute.uom)}
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        )
                            
                        })}
                    </div>

                    {showAll &&
                        <div className={`${classes.attributeMiddleBorder} flex justify-between pt-4 mt-4`}>
                            {sortSecondarySpecificationAttributes(data?.attributes?.productSpecification, filterData.attributes)?.slice(3, 6).map((attribute: Attribute) => {
                                const isRangeZero = attribute.minValue === attribute.maxValue;
                                return (
                                    <div className="grid gap-y-1" key={attribute.id}>
                                        <div className={`${classes.attributesName} text-xs font-normal`}>
                                            {attribute?.name}
                                        </div>
                                        <div className={`${classes.attributesValue} text-sm font-normal flex gap-x-1`}>
                                            {isRangeZero 
                                                ? (
                                                    <>
                                                        {displayAttributeValue(attribute?.name, attribute?.minValue, attribute?.minValue)} {attribute.minValue && attribute.uom && formatUOM(attribute.minValue, attribute.uom)}
                                                    </>
                                                ) : (
                                                    <>
                                                        {displayAttributeValue(attribute?.name, attribute?.minValue, attribute?.maxValue)} {attribute.minValue && attribute.uom && formatUOM(attribute.minValue, attribute.uom)}
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    }
                </div>
            )
        }
    }, [data, showAll]);

    const handleEdit = () => {
        if (!data || !data.catalogueId || !data.catalogueType) {
            console.error("Invalid data: ", data);
            return;
        }
        const isMakeToOrder = data?.catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER;
        const isSecondarySku = Boolean(data?.secondarySkuId);
        const catalogueParam = isSecondarySku ? 'secondaryCatalogueId' : 'catalogueId';
        const PDPRoute = isSecondarySku
            ? (isMakeToOrder ? CUSTOMER_ROUTES.SECONDARY_CATALOGUE_LOT_VIEW : CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION)
            : (isMakeToOrder ? CUSTOMER_ROUTES.CATALOGUE_MTO_VIEW : CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION);

        try {
            navigate(`${PDPRoute}?${catalogueParam}=${data.catalogueId}`);
        } catch (error) {
            console.error("Navigation error: ", error);
        }
    };

    const handleDelete = (id: number) => {
        setItemToDeleteTo(id);
        setCancelIconTo(true);
        setDeletePopVisibleTo(true);
    }
    const confirmDelete = () => {
        if (itemToDelete !== -1) {
            handleDeleteCartItem(itemToDelete);
            setDeletePopVisibleTo(false);
            setItemToDeleteTo(-1);
        }
    };

    const cancelDelete = () => {
        setDeletePopVisibleTo(false);
        setItemToDeleteTo(-1);
    };

    const addValueAddedServices = () => {
        setVasPopupTo(true);
    }

    useEffect(() => {
    }, [isVasPopup]);

    return (
        <>
            <div className={`${classes.webContainer} rounded-xl bg-white gap-y-4`} key={data?.id} >
                <div className={` flex justify-between rounded`}>
                    <div className="">
                        <div className={`${classes.productHeader} text-xs font-medium`} >
                            {`Product ${index + 1}`}
                        </div>
                        <div className={`${classes.catalogueName} text-base font-semibold`} >
                            {data?.catalogueName}
                        </div>
                    </div>
                    <div className="flex gap-x-8 items-center">
                        <div className={`${classes.warehouseText} flex gap-x-1 px-3 py-1.5 text-xs font-medium rounded-3xl`}>
                            <img className="ml-1 mr-1" src={warehouseLocation} alt="pic" />
                            <div className="text-xs font-bold">
                                <span className="font-semibold">{data?.warehouseName},</span>
                                <span className="font-semibold"> {data?.warehouseAddress}</span>
                            </div>
                        </div>
                        <div className="flex gap-x-2">
                            {isEditable && <img className="max-w-xs m-auto cursor-pointer" src={editPenIcon} alt="pic" onClick={() => handleEdit()} />}
                            {isEditable && <img className="max-w-xs m-auto cursor-pointer" src={cartDeleteIcon} alt="pic" onClick={() => handleDelete(data?.id)} />}
                        </div>
                        {cancelIcon && isDeletePopupVisible && (
                            <SuccessFailurePopup
                                width="458px"
                                height="400px"
                                status={false}
                                heading="Delete Product?"
                                description="Are you sure you want to delete this?"
                                setShowPopup={setCancelIconTo}
                                button1={{
                                    text: 'Yes',
                                    variant: "tertiaryContained",
                                    size: "large",
                                    onClick: confirmDelete,
                                }}
                                button2={{
                                    text: 'No',
                                    variant: "secondaryContained",
                                    size: "large",
                                    onClick: cancelDelete
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className={`${classes.attributesBox} py-4 px-2 flex justify-between rounded-lg w-full`}>
                    {displayAttributes}
                </div>

                <div className={`rounded-lg w-full ${classes.tableContainer}`}>
                    {isVasPopup &&
                        <ValueAddedServicesDetailsPage
                            valueAddedServices={valueAddedServices}
                            shearingTotalWeight={shearingTotalWeight}
                            slittingTotalWeight={slittingTotalWeight}
                            shearingTotalCharges={shearingTotalCharges}
                            slittingTotalCharges={slittingTotalCharges}
                            setVasPopupTo={setVasPopupTo}
                            isVasPopup={isVasPopup}
                            cartData={data}
                            setValueAddedServicesTo={setValueAddedServicesTo}
                        />}

                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="px-2 py-3 text-sm font-medium border-b border-r border-gray-200">S.No.</th>
                                <th className="px-2 py-3 text-sm font-medium w-1/2 text-left border-b border-r border-gray-200">Product/Service</th>
                                <th className="px-2 py-3 text-sm font-medium border-b border-r border-gray-200">Quantity (MT)</th>
                                <th className="px-2 py-3 text-sm font-medium border-b border-r border-gray-200">Price/MT*</th>
                                <th className="px-2 py-3 text-sm font-medium border-b border-gray-200">Total Price*</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="px-2 py-3 text-center  border-r border-gray-200 text-xs font-normal">1</td>
                                <td className={`${classes.upcCodeAndVasTitle} px-2 py-3 font-medium border-r border-gray-200`}>
                                    {data?.upcCode != null ? (
                                        data.upcCode
                                    ) : (
                                        data?.skuCode != null ? (
                                            data.skuCode
                                        ) : (
                                            data?.catalogueName
                                        )
                                    )}                           <br />
                                    <span className={`${classes.priceAndVas} text-xs`}>Catalog Price-{toInr(data?.upcPrice)}/MT</span></td>
                                <td className={`${classes.pricePerMT} px-2 py-3 text-center  border-r border-gray-200`}>{data?.quantity}</td>
                                <td className={`${classes.pricePerMT} px-2 py-3 text-center  border-r border-gray-200`}>{toInr(data?.upcPrice)}</td>
                                <td className={`${classes.pricePerMT} px-2 py-3 text-center border-gray-200`}>{toInr((data?.upcPrice) * (!isNaN(data?.quantity) ? data?.quantity : 0))}</td>
                            </tr>
                        </tbody>
                        {valueAddedServices && (valueAddedServices.shearing?.length || valueAddedServices.slitting?.length) ? (
                            <tbody>
                                <tr className='border-t'>
                                    <td className="px-2 py-3 text-center  border-r border-gray-200 text-xs font-normal">2</td>
                                    <td className="px-2 py-3  border-r border-gray-200" colSpan={3} >
                                        <span className={`${classes.upcCodeAndVasTitle} underline hover:text-orange-500 hover:underline hover:decoration-orange-500`} onClick={addValueAddedServices} style={{ cursor: 'pointer' }}>
                                            Value Added Services
                                        </span>
                                        <br />
                                        <span className={`${classes.priceAndVas} text-xs font-normal`}>
                                            {shearingTotalWeight > 0 ? `Shearing - ${shearingTotalWeight} MT  ` : ''}
                                            {shearingTotalWeight && slittingTotalWeight ? ' | ' : ''}
                                            {slittingTotalWeight > 0 ? `Slitting - ${slittingTotalWeight} MT` : ''}
                                        </span>
                                    </td>
                                    <td className="px-2 py-3 text-center text-sm font-normal">{toInr(shearingTotalCharges + slittingTotalCharges)}</td>
                                </tr>
                            </tbody>
                        ) : null}
                    </table>
                </div>

                <div className="flex justify-between">
                    <div className={`${classes.subTotal} font-medium`}>Total Price</div>
                    <div className={`flex flex-col`}>
                        <span className={`${classes.totalPrice} text-lg font-semibold`}>{toInr((data?.upcPrice) * (!isNaN(data?.quantity) ? data?.quantity : 0) + (shearingTotalCharges + slittingTotalCharges))}*</span>
                        <span className={`${classes.cartHeader} text-xs font-normal text-right`}>*Excl. of GST</span>
                    </div>
                </div>
            </div>

            <div className={`${classes.mobileContainer} rounded-xl bg-white gap-y-4`} key={data?.id} >
                <div className={` flex justify-between px-4`}>
                    <div className={`${classes.productHeader} text-xs font-medium`} >
                        {`Product ${index + 1}`}
                    </div>

                    <div className="flex gap-x-2">
                        {isEditable && <img className="max-w-xs m-auto cursor-pointer" src={editPenIcon} alt="pic" onClick={() => handleEdit()} />}
                        {isEditable && <img className="max-w-xs m-auto cursor-pointer" src={cartDeleteIcon} alt="pic" onClick={() => handleDelete(data?.id)} />}
                    </div>
                    {cancelIcon && isDeletePopupVisible && (
                        <SuccessFailurePopup
                            width="458px"
                            height="400px"
                            status={false}
                            heading="Delete Product?"
                            description="Are you sure you want to delete this?"
                            setShowPopup={setCancelIconTo}
                            button1={{
                                text: 'Yes',
                                variant: "tertiaryContained",
                                size: "large",
                                onClick: confirmDelete,
                            }}
                            button2={{
                                text: 'No',
                                variant: "secondaryContained",
                                size: "large",
                                onClick: cancelDelete
                            }}
                        />
                    )}
                </div>

                <div className="grid gap-y-3 px-4">
                    <div className="flex justify-between">
                        <div className={`${classes.catalogueName} text-sm font-semibold`} >
                            {data?.catalogueName}
                        </div>
                        <div className={`cursor-pointer h-fit`} onClick={handleClick} >
                            {showAll ? <ArrowDropUp /> : <ArrowDropDown />}
                        </div>
                    </div>
                    <div className={`${classes.warehouseText} flex gap-x-1 px-3 py-1.5 text-xs font-medium rounded-3xl w-fit`}>
                        <img className="ml-1 mr-1" src={warehouseLocation} alt="pic" />
                        <div className="text-xs font-bold">
                            <span className="font-semibold">{data?.warehouseName},</span>
                            <span className="font-semibold"> {data?.warehouseAddress}</span>
                        </div>
                    </div>
                </div>

                {displayMobileAttributes}

                {
                    showAll &&
                    <>
                    <div className={`px-4 ${classes.borderBottom} pb-4`}>
                    <div className="grid gap-y-3">
                        <div>
                            <div className={`${classes.upcCodeAndVasTitle} font-medium`}>
                                {data?.upcCode != null ? (
                                    data.upcCode
                                ) : (
                                    data?.skuCode != null ? (
                                        data.skuCode
                                    ) : (
                                        data?.catalogueName
                                    )
                                )}
                            </div>
                            <div className={`${classes.priceAndVas} text-xs`}>Catalog Price-{toInr(data?.upcPrice)}/MT</div>
                        </div>

                        <table className={` ${classes.tableContainer}`}>
                            <thead>
                                <tr>
                                    <th className="px-2 py-2 text-xs font-medium border-b border-r border-gray-200">Quantity (MT)</th>
                                    <th className="px-2 py-2 text-xs font-medium border-b border-r border-gray-200">Price/MT*</th>
                                    <th className="px-2 py-2 text-xs font-medium border-b border-gray-200">Total Price*</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={`${classes.pricePerMT} px-2 py-3 text-center  border-r border-gray-200`}>{data?.quantity}</td>
                                    <td className={`${classes.pricePerMT} px-2 py-3 text-center  border-r border-gray-200`}>{toInr(data?.upcPrice)}</td>
                                    <td className={`${classes.pricePerMT} px-2 py-3 text-center border-gray-200`}>{toInr((data?.upcPrice) * (!isNaN(data?.quantity) ? data?.quantity : 0))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {valueAddedServices && (valueAddedServices.shearing?.length || valueAddedServices.slitting?.length) ? (
                    <div className={`grid gap-y-3 px-4 ${classes.borderBottom} pb-4`}>
                        {isVasPopup &&
                            <ValueAddedServicesDetailsPage
                                valueAddedServices={valueAddedServices}
                                shearingTotalWeight={shearingTotalWeight}
                                slittingTotalWeight={slittingTotalWeight}
                                shearingTotalCharges={shearingTotalCharges}
                                slittingTotalCharges={slittingTotalCharges}
                                setVasPopupTo={setVasPopupTo}
                                isVasPopup={isVasPopup}
                                cartData={data}
                                setValueAddedServicesTo={setValueAddedServicesTo}
                            />}
                        <div>
                            <div className={`${classes.upcCodeAndVasTitle} underline hover:text-orange-500 hover:underline hover:decoration-orange-500`} onClick={addValueAddedServices} style={{ cursor: 'pointer' }}>
                                Value Added Services
                            </div>
                            <div className={`${classes.priceAndVas} text-xs`}>{shearingTotalWeight > 0 ? `Shearing - ${shearingTotalWeight} MT  ` : ''}
                                {shearingTotalWeight && slittingTotalWeight ? ' | ' : ''}
                                {slittingTotalWeight > 0 ? `Slitting - ${slittingTotalWeight} MT` : ''}</div>
                        </div>
                        <table className={` ${classes.tableContainer}`}>
                            <thead>
                                <tr>
                                    <th className="px-2 py-2 text-xs text-left font-medium border-b border-gray-200">Total Price*</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='border-t'>
                                    <td className="px-2 py-3 text-sm font-normal">{toInr(shearingTotalCharges + slittingTotalCharges)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : null}
                    </>
                }

                <div className="flex justify-between px-4">
                    <div className={`${classes.subTotal} font-medium`}>Total Price</div>
                    <div className={`flex flex-col`}>
                        <span className={`${classes.totalPrice} text-base font-semibold`}>{toInr((data?.upcPrice) * (!isNaN(data?.quantity) ? data?.quantity : 0) + (shearingTotalCharges + slittingTotalCharges))}*</span>
                        <span className={`${classes.cartHeader} text-xs font-normal text-right`}>*Excl. of GST</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardItem;
