import React from 'react';
import { createUseStyles } from 'react-jss';
import AddLocation from '../../../assets/images/AddLocation.svg';
import Button from '../../atoms/Button/Button';
import { LOCATION_STATES } from '../../../utils/types';
import { FormikProps } from 'formik';
import { ILocationGstinForm } from '../../pages/DashBoard/BussinessLocation.page';

export interface LocationSectionProps {
    setCurrentSectionTo: (section: LOCATION_STATES) => void;
    formik: FormikProps<ILocationGstinForm>;
}
const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.secondaryLight}`
    },
    heading:{
        color: theme.palette.v2.text.primaryDark,
    }
}));

const AddBusinessLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.container} py-14 px-6  rounded-lg`}>
            <div className='grid gap-y-4'>
                <div className='grid gap-y-8 justify-items-stretch'>
                    <div className='justify-self-center'>
                        <img src={AddLocation} alt="locationImage" className="" />
                    </div>
                    <div className={`justify-self-center font-normal text-lg ${classes.heading}`}>
                        You haven't provide a location for your business.
                    </div>
                </div>
                <div className='grid justify-items-stretch'>
                    <Button
                        className={` justify-self-center w-auto`}
                        variant="contained"
                        label="Add Location"
                        onClick={() => { setCurrentSectionTo(LOCATION_STATES.ADD_LOCATION_NO) }}
                    />
                </div>
            </div>
        </div>
    )
};
export default AddBusinessLocationTemplate;
