import { POLICY_SECTION } from "../../../../utils/constant";

interface SecurityOfYouInformationProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
}

const SecurityOfYouInformation: React.FC<SecurityOfYouInformationProps> = ({ contentStyle, boxStyle, subHeading }) => {
    return (
        <>
            <div className={contentStyle}>
                <div className={boxStyle}>
                    <span className={subHeading}>{POLICY_SECTION.SECURITY_OF_YOUR_INFORMATION}</span>
                </div>
                5.1 Avighna strives to maintain physical, technical, and procedural safeguards appropriate to protect Your Information and User Information against loss, misuse, copying, damage, modification, unauthorized access, or disclosure. Some security measures adopted by Avighna include:
                <br />
                <br />
                (i) Regular review of information collection, usage, storage, processing, and disclosure practices, including physical security measures, to prevent unauthorized access to systems.
                <br />
                <br />
                (ii) Restriction of access to Your Information to Avighna employees, agents, and partners who need it for processing and are subject to strict contractual confidentiality obligations.
                <br />
                <br />
                5.2 All Avighna employees and data processors with access to and involvement in processing Personal Information or Financial Information from Users are obligated to respect the confidentiality of every User’s Personal Information or Financial Information.
                <br />
                <br />
                5.3 Information collected from Users by Avighna is maintained in electronic form on servers and/or cloud systems, accessible only to specific employees of Avighna. The User information may also be converted to physical form from time to time. Regardless of the storage method, Avighna makes commercially reasonable efforts to keep User information confidential and discloses it only in accordance with the terms of this Privacy Policy.
                <br />
                <br />
                5.4 To cancel Your account or request that Avighna no longer use Your information to provide You Services, contact Avighna at [contact email]. After a certain period, Your data may be anonymized and aggregated, held by Avighna for effective service provision, and used solely for analytic purposes. Withdrawal of consent or account cancellation may result in Avighna being unable to provide You with Services.
                <br />
                <br />
                5.5 The Avighna Platform uses temporary cookies to store certain data, excluding personally identifiable information. Information collected that does not personally identify the User (such as utilization patterns) is owned by Avighna and may be used for technical administration, user administration, research, development, and other purposes. Avighna may sell or transfer such aggregated or non-personally identifiable data to other parties.
                <br />
                <br />
                5.6 Users have the option to set or modify web browsers to delete or disable cookies. Disabling cookies may restrict access to certain areas of the Platform, and Users are encouraged to use the browser's "clear cookies" functionality for deletion, as Avighna cannot guarantee or predict the behavior of all Platform users' equipment.
                <br />
                <br />
            </div>
        </>
    )
}
export default SecurityOfYouInformation