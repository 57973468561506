import { TableBody, TableContainer, TableCell, TableHead, TableRow, Table } from '@mui/material';
import { createUseStyles } from 'react-jss';
import editIcon from "../../../../assets/images/editIcon.svg"
import { IShearing } from './ShearingCustomizationPopup';

interface ShearingCustomizationTableTemplateProps {
    valueAddedService: IShearing[];
    setDialogOpen: (open: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.v4.background._primaryLightAccent,
        fontFamily: "none"
    },
    tableHeadCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 0px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    tableCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 16px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    tableContaier: {

    },
    "@media (max-width: 480px)": {
        tableContaier: {
            width: "840px"
        },

    },
}))

const SHEARING_HEADING = [
    "Sno.",
    "Length (MM)",
    "No. of Pieces",
    "Weight/Pc (MT)",
    "Weight (MT)",
    "CTL Charges/MT",
    "Total Charges",
]

const ShearingCustomizationTableTemplate: React.FC<ShearingCustomizationTableTemplateProps> = ({ valueAddedService, setDialogOpen }) => {

    const classes = useStyles();

    return (
        <>
            <TableContainer className="w-full">
                <div className='grid gap-y-2'>
                    <div className='flex justify-between'>
                        <div className="text-base font-semibold my-auto">Shearing (CTL)</div>
                        <div className='m-2.5'>
                            <img src={editIcon} alt="" onClick={(e) => setDialogOpen(true)} />
                        </div>
                    </div>
                    <div className={`overflow-x-auto overflow-hidden w-full`}>
                        <div className={`${classes.tableContaier} border rounded-lg`}>
                            <Table className="w-full" size="small">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        {SHEARING_HEADING.map((item, index) => (
                                            <TableCell key={index} className={`${classes.tableHeadCell} first:pl-4 first:text-left last:pr-4 last:text-right`}>{item}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {valueAddedService.map((data: any, index: number) => (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.length}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.pieces}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.weightPerPiece}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.weight}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.chargesPerMT}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.totalCharges}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </TableContainer>
        </>
    )
}

export default ShearingCustomizationTableTemplate