interface UseOfThePlatformProps {
    contentStyle: string;
    header: string;
    boxStyle: string;
    subHeading: string;
}

const UseOfThePlatformTemplate: React.FC<UseOfThePlatformProps> = ({ contentStyle, boxStyle, subHeading, header }) => {
    return (
        <div className={contentStyle}>
            <div className={boxStyle}>
                <span className={subHeading}>{header}</span>
            </div>
            <p>
                4.1 The Platform is utilized by Users to interact with Avighna Steel for their Transactions. Registration and membership on the Platform are free for Users. Avighna Steel does not charge any fee for browsing on the Platform. Avighna Steel reserves the right to change its fee policy from time to time without any prior intimation.
            </p><br />
            <p> 4.2 You agree not to host, display, upload, modify, publish, transmit, update, share, or otherwise make available on the Platform any information that:
            </p><br />
            <p> (a) belongs to another person and over which you have no right;
            </p><br />
            <p> (b) contains content or other material protected by intellectual property laws unless You own or control the rights thereto or have received all necessary consents;
            </p><br />
            <p>(c) defames, abuses, harasses, stalks, hurts religious or ethnic sentiments, threatens, or otherwise violates the legal rights of others;
            </p><br />
            <p>(d) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner;
            </p><br />
            <p>(e) is false, inaccurate, or misleading in any way; infringes any patent, trademark, copyright, or other proprietary rights;
            </p><br />
            <p>(f) violates any law for the time being in force; deceives or misleads the addressee about the origin of such messages or communicates any information that is grossly offensive or menacing in nature;
            </p><br />
            <p>(g) abets or assists with impersonating another person; contains software viruses or any other computer codes, files, or programs designed to interrupt, destroy, or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs, or other computer programming routines that may damage, detrimentally interfere with, diminish the value of, surreptitiously intercept, or expropriate any system, data, or personal information
            </p><br />
            <p>(h) conducts or forwards surveys, contests, pyramid schemes, or chain letters;
            </p><br />
            <p> (i) solicits gambling or engages in any gambling activity which we, at our sole discretion, believe is or could be construed as being illegal;
            </p><br />
            <p> (j) interferes with another’s use and enjoyment of the Platform;
            </p><br />
            <p> (k) creates profiles or provides content that promotes escort services or prostitution;
            </p><br />
            <p>   (l) threatens the unity, integrity, defense, security, or sovereignty of India, friendly relations with foreign states, or disrupts public order or causes incitement to the commission of any cognizable offense or prevents the investigation of any offense or is insulting to any other nation;
            </p><br />
            <p>(m) uses any other internet service to send or post spam to drive visitors to any other site hosted on or through Our systems, whether or not the messages were originated by You, under Your direction, or by or under the direction of a related or unrelated third party; or
            </p><br />
            <p> (n) shall create liability for us or cause us to lose (in whole or part) the services of our Internet Service Provider (“ISPs”) or other Users.
            </p><br />
            <p> (o) contains any content that is non-compliant with the Information Technology Act, 2000, Rules and regulations, guidelines made thereunder, including Rule 3 of The Information Technology (Intermediaries Guidelines) Rules, 2011, the Indian Penal Code, 1860, Terms of Use, or Privacy Policy, as amended/re-enacted from time to time; or
            </p><br />
            <p>(p) violates any law for the time being in force.
            </p><br />
            <p> 4.3 You further agree to use the Platform in a bona fide manner. In particular, You agree not to:
            </p><br />
            <p> (a) print, distribute, share, download, duplicate or otherwise copy, delete, vary or amend or use any data or information posted by any User other than You;
            </p><br />
            <p> (b) use or attempt to use another person’s Profile;
            </p><br />
            <p> (c) attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the Platform Services by hacking, password “mining” or any other illegitimate means;
            </p><br />
            <p> (d) probe, scan or test the vulnerability of the Platform or any network connected to the Platform or breach the security or authentication measures on the Platform or any network connected to the Platform;
            </p><br />
            <p> (e) use any automated systems to extract data from the Platform;
            </p><br />
            <p> (f) make any inaccurate, false, unfair, or defamatory statement(s) or comment(s) about Us or the brand name or domain name used by Us or any User on the Platform;
            </p><br />
            <p> (g) make any negative, denigrating, or defamatory statement(s) or comment(s) about Us or the brand name or domain name used by Us, or otherwise engage in any conduct or action that might tarnish the image or reputation of Avighna Steel, its affiliates, or Avighna Steel on the Platform or otherwise tarnish or dilute any Avighna Steel Group Company or Avighna Steel’s trade or service marks, trade name, and/or goodwill associated with such trade or service marks, trade name as may be owned or used by Us;
            </p><br />
            <p> (h) take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or Our systems or networks, or any systems or networks connected to Us;
            </p><br />
            <p> (i) circumvent or manipulate our Platform, Platform Services, registration process, billing system, or attempt to do so;
            </p><br />
            <p> (j) engage in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of “virtual” products related to the Platform.
            </p><br />
            <p> (k) engage in deceptive, fraudulent, anti-competitive practices or other invalid means to meet the other User’s requirements; or
            </p><br />
            <p> (l) engage in any form of fraudulent traffic generating methods including the use of robots, spiders, auto-spawning browsers, auto-reloading, meta refreshes, or any other form of fraudulent and artificial traffic.
            </p><br />
            <p> 4.4 You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules thereunder as applicable and as amended from time to time and also all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange control laws or regulations in force) and international laws, foreign exchange laws, Statutes, Ordinances, and Regulations (including, but not limited to GST, income tax, octroi, customs duty, local levies) regarding Your use of the Platform. You shall not engage in any transaction in an item or service, which is prohibited by applicable law.
            </p><br />
            <p> 4.5 Unless otherwise specified, the material on the Platform is presented solely for the purpose of sale in India. Avighna Steel makes no representation that the Products on the Platform are appropriate or available for use in other locations other than India. Those who choose to access the Platform from other locations/countries other than India, do so on their own initiative and Avighna Steel is not responsible for such actions.
            </p><br />
            <p> 4.6 The Buyer understands that the Platform is a B2B e-commerce Platform and accordingly represents, warrants and undertakes that the Products purchased on the Platform are for business, distribution, and resale (B2B) purposes.
            </p><br />
        </div>
    );
}

export default UseOfThePlatformTemplate;
