import { DASHBOARD_RESOURCES } from "../../../utils/constant";
import tuneSmall from '../../../assets/images/tune_small.svg'
import lock_password_outline from '../../../assets/images/lock_password-outline.svg'
import metalRoll from '../../../assets/images/metal_roll.svg'
import userProfile from '../../../assets/images/user_profile.svg'
import building from '../../../assets/images/building.svg'
import location from '../../../assets/images/location.svg'
import bag from '../../../assets/images/bag.svg'
import user from '../../../assets/images/user.svg'
import { USER_PROFILE_TYPE } from '../../../utils/types';
import dashboard from '../../../assets/icons/dashboardOutlined.svg';
import cart from '../../../assets/icons/cartOutlined.svg';
import document from '../../../assets/icons/documentOutlined.svg';
import offer from '../../../assets/icons/offerOutlined.svg';
import setting from '../../../assets/icons/settingOutlined.svg';
import warehouse from '../../../assets/icons/warehouseOutlined.svg';
import inventoryManagementIcon from '../../../assets/icons/inventoryManagementIcon.svg';
import addVendorProduct from "../../../assets/icons/addVendorProduct.svg";
export interface MenuItem {
    key: string;
    icon?: string;
    route?: string;
    disabled?: boolean;
    subList?: MenuItem[];
}

const buyerDashboardMenuBarSchema: MenuItem[] = [
    {
        key: DASHBOARD_RESOURCES.MY_ACCOUNT,
        subList: [
            {
                key: DASHBOARD_RESOURCES.MY_PROFILE,
                route: "/dashboard/my-profile",
                icon: user,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.BUSINESS_ENTITY_PROFILE,
                route: "/dashboard/business-profile",
                icon: bag,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.BUSINESS_LOCATION,
                route: "/dashboard/business-location",
                icon: location,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.KEY_PERSON_DETAILS,
                route: "/dashboard/key-person-details",
                icon: userProfile,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.MY_MATERIAL,
                route: "/dashboard/my-material",
                icon: metalRoll,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.STATUTORY_AND_LEGAL,
                route: "/dashboard/statutory-documents",
                icon: building,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.PREFERENCES,
                route: "/dashboard/preferences",
                icon: tuneSmall,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.CHANGE_PASSWORD,
                route: "/dashboard/change-password",
                icon: lock_password_outline,
                disabled: false
            }
        ]
    },
    {
        key: DASHBOARD_RESOURCES.MY_AVIGHNA,
        subList: [
            {
                key: DASHBOARD_RESOURCES.INVENTORY_MANAGEMENT,
                route: "/dashboard/inventory-management",
                icon: inventoryManagementIcon,
                disabled: true
            },
            {
                key: DASHBOARD_RESOURCES.ADD_PRODUCT,
                route: "/dashboard/vendor/product",
                icon: addVendorProduct,
                disabled: true
            },
            {
                key: DASHBOARD_RESOURCES.REQUEST,
                subList: [
                    {
                        key: DASHBOARD_RESOURCES.STANDARD,
                        route: "/dashboard/lead",
                    },
                    {
                        key: DASHBOARD_RESOURCES.SECONDARY,
                        route: "/dashboard/lead-secondary",
                    },
                    {
                        key: DASHBOARD_RESOURCES.SECONDARY_LOT,
                        route: "/dashboard/lead-secondary-lot",
                    }
                ]
            },
            {
                key: DASHBOARD_RESOURCES.ORDER,
                subList: [
                    {
                        key: DASHBOARD_RESOURCES.STANDARD,
                        route: "/dashboard/order",
                    },
                    {
                        key: DASHBOARD_RESOURCES.SECONDARY,
                        route: "/dashboard/order-secondary",
                    },
                    {
                        key: DASHBOARD_RESOURCES.SECONDARY_LOT,
                        route: "/dashboard/order-secondary-lot",
                    }
                ]
            },

        ]
    },
]

const sellerDashboardMenuBarSchema: MenuItem[] = [
    {
        key: DASHBOARD_RESOURCES.MY_ACCOUNT,
        subList: [
            {
                key: DASHBOARD_RESOURCES.MY_PROFILE,
                route: "/dashboard/my-profile",
                icon: user,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.BUSINESS_ENTITY_PROFILE,
                route: "/dashboard/business-profile",
                icon: bag,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.BUSINESS_LOCATION,
                route: "/dashboard/business-location",
                icon: location,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.KEY_PERSON_DETAILS,
                route: "/dashboard/key-person-details",
                icon: userProfile,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.MY_MATERIAL,
                route: "/dashboard/my-material",
                icon: metalRoll,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.STATUTORY_AND_LEGAL,
                route: "/dashboard/statutory-documents",
                icon: building,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.PREFERENCES,
                route: "/dashboard/preferences",
                icon: tuneSmall,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.CHANGE_PASSWORD,
                route: "/dashboard/change-password",
                icon: lock_password_outline,
                disabled: false
            }
        ]
    },
    {
        key: DASHBOARD_RESOURCES.MY_AVIGHNA,
        subList: [
            {
                key: DASHBOARD_RESOURCES.DASHBOARD,
                route: "/dashboard",
                icon: dashboard,
                disabled: true
            },
            {
                key: DASHBOARD_RESOURCES.ADD_PRODUCT,
                route: "/dashboard/vendor/product",
                icon: addVendorProduct,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.ADD_INVENTORY,
                route: "/dashboard/inventory-management",
                icon: inventoryManagementIcon,
                disabled: false
            },
            {
                key: DASHBOARD_RESOURCES.PRICE_MANAGEMENT,
                route: "/dashboard",
                icon: offer,
                disabled: true
            },
            {
                key: DASHBOARD_RESOURCES.VALUE_ADDED_SERVICES,
                route: "/dashboard",
                icon: setting,
                disabled: true
            },
            {
                key: DASHBOARD_RESOURCES.MAKE_CATALOG,
                route: "/dashboard",
                icon: document,
                disabled: true
            },
            {
                key: DASHBOARD_RESOURCES.ENQUIRIES,
                route: "/dashboard",
                icon: cart,
                disabled: true
            },
            {
                key: DASHBOARD_RESOURCES.ORDERS,
                route: "/dashboard",
                icon: cart,
                disabled: true
            },
            {
                key: DASHBOARD_RESOURCES.AUCTION,
                disabled: true,
                icon: lock_password_outline,
                subList: [
                    {
                        key: DASHBOARD_RESOURCES.CREATE_SALE_AUCTION,
                        route: "/dashboard",
                        disabled: true,
                        subList: [
                            {
                                key: DASHBOARD_RESOURCES.CREATE_SALE_AUCTION,
                                route: "/dashboard",
                                disabled: true
                            },
                            {
                                key: DASHBOARD_RESOURCES.MANAGE_AND_VIEW_AUCTION,
                                route: "/dashboard",
                                disabled: true
                            }

                        ]
                    },
                    {
                        key: DASHBOARD_RESOURCES.REVERSE_BUY_AUCTION,
                        route: "/dashboard",
                        disabled: true,
                        subList: [
                            {
                                key: DASHBOARD_RESOURCES.PARTICIPATE_IN_AUCTION,
                                route: "/dashboard",
                                disabled: true
                            }
                        ]
                    }
                ]
            },
            {
                key: DASHBOARD_RESOURCES.BUY_LEADS,
                route: "/dashboard",
                icon: lock_password_outline,
                disabled: true
            },
            {
                key: DASHBOARD_RESOURCES.BRAND_CENTRE,
                route: "/dashboard",
                icon: lock_password_outline,
                disabled: true
            }

        ]
    },
]

export const getDashboardMenuSchema = (profileType: USER_PROFILE_TYPE ) => {
    return profileType === USER_PROFILE_TYPE.BUYER ? buyerDashboardMenuBarSchema : sellerDashboardMenuBarSchema;
}