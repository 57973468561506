import React, { useRef, ChangeEvent, KeyboardEvent } from 'react';
import { FormHelperText, OutlinedInput } from '@mui/material';
import { createUseStyles } from 'react-jss';

interface OtpInputGroupProps {
    otp: string[];
    setOtp: (otp: string[]) => void;
    otpErrorMessage: string | undefined;
    setOtpErrorMessage: (error: string) => void;
}

const useStyles = createUseStyles({
    otpInputContainer: {
        textAlign: 'center !important',
    }
});

const OtpInputGroup: React.FC<OtpInputGroupProps> = ({
    otp,
    setOtp,
    otpErrorMessage,
    setOtpErrorMessage
}) => {
    const classes = useStyles();
    const otpInputRefs = useRef<HTMLInputElement[]>([]);

    const handleOtpChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        // Check if the entered value is a number
        if (!isNaN(Number(value))) {
            // Update the OTP value at the specified index
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);
            setOtpErrorMessage("")

            // Focus on the next input field
            if (value !== '' && index < otp.length - 1) {
                otpInputRefs.current[index + 1].focus();
            }
        }
    };

    const handleOtpPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const pasteData = event.clipboardData.getData('text/plain');
        const otpArray = pasteData.slice(0, 4).split('');

        // Update the OTP values with the pasted data
        const updatedOtp = [...otp];
        otpArray.forEach((value, index) => {
            if (!isNaN(Number(value))) {
                updatedOtp[index] = value;
            }
        });
        setOtp(updatedOtp);
        setOtpErrorMessage("")

        // Focus on the next input field
        otpInputRefs.current[otpArray.length].focus();
    };

    const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && index > 0 && !otp[index]) {
            // Handle backspace key press and focus on the previous input field
            otpInputRefs.current[index - 1].focus();
        }
    };

    return (
        <div className={`${classes.otpInputContainer} flex justify-start gap-x-4 relative`}>
            {otp.map((value, index) => (
                <OutlinedInput
                    key={index}
                    inputRef={(ref) => (otpInputRefs.current[index] = ref)}
                    type="text"
                    inputProps={{ maxLength: 1 }}
                    value={value}
                    onChange={(event) => handleOtpChange(index, event as ChangeEvent<HTMLInputElement>)}
                    onKeyDown={(event) => handleKeyDown(index, event as KeyboardEvent<HTMLInputElement>)}
                    onPaste={handleOtpPaste}
                    className='w-12 h-12'
                    placeholder='-'
                    error={!!otpErrorMessage}
                />
            ))}
            {!!otpErrorMessage && (
                <FormHelperText error className='absolute top-full'>{otpErrorMessage}</FormHelperText>
            )}
        </div>
    );
};

export default OtpInputGroup;