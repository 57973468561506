import { POLICY_SECTION } from "../../../utils/constant";
import ContentPreviewer, { IContentComponent } from "../../organisms/ContentPreviewer/ContentPreviewer";
import { createUseStyles } from "react-jss";
import React from "react";
import UseOfThePlatformTemplate from "../../templates/Policies/PrivacyPolicy/UseOfThePlatform.template";
import PaymentsTemplate from "../../templates/Policies/PrivacyPolicy/Payments.template";
import LogisticAndFulfillmentServicesTemplate from "../../templates/Policies/PrivacyPolicy/LogisticsAndFulfillmentServicesPolicy.template";
import TermsAndConditionsIntroduction from "../../templates/Policies/PrivacyPolicy/TermsAndConditionsIntoduction.template";
import General from "../../templates/Policies/PrivacyPolicy/General.template";
import AccessToThePlatform from "../../templates/Policies/PrivacyPolicy/AccessToThePlatform.template";
import YourProfileAndYourInformation from "../../templates/Policies/PrivacyPolicy/YourProfileAndYourInformation.template";

const useStyles = createUseStyles((theme: any) => ({
    contentStyle: {
        color: theme.palette.text._primaryDarkAccent,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
    },
    boxStyle: {
        padding: '14px 10px',
        borderRadius: '8px',
        background: theme.palette.v2.background.secondaryLightAccent,
        marginTop: '16px',
        marginBottom: '24px',
    },
    subHeading: {
        color: theme.palette.text.primaryDark,
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '120%',
    },
}));

const TermsAndConditions: React.FC = () => {
    const classes = useStyles();
    const TermsAndConditionsSchem: IContentComponent[] = [
        {
            Key: POLICY_SECTION.TERMS_INTRODUCTION,
            component: <TermsAndConditionsIntroduction {...classes}/>
        },
        {
            Key: POLICY_SECTION.GENERAL,
            component: <General  header={POLICY_SECTION.GENERAL} {...classes} />
        },
        {
            Key: POLICY_SECTION.ACCESS_TO_THE_PLATFORM,
            component: <AccessToThePlatform header={POLICY_SECTION.ACCESS_TO_THE_PLATFORM} {...classes} />
        },
        {
            Key: POLICY_SECTION.YOUR_PROFILE_AND_YOUR_INFORMATION,
            component: <YourProfileAndYourInformation {...classes} header={POLICY_SECTION.YOUR_PROFILE_AND_YOUR_INFORMATION} />
        },
        {
            Key: POLICY_SECTION.USE_OF_THE_PLATFORM,
            component: <UseOfThePlatformTemplate {...classes} header={POLICY_SECTION.USE_OF_THE_PLATFORM} />
        },
        {
            Key: POLICY_SECTION.PAYMENTS,
            component: <PaymentsTemplate {...classes} header={POLICY_SECTION.PAYMENTS} />
        },
        {
            Key: POLICY_SECTION.LOGISTICS_AND_FULFILLMENT_SERVICES,
            component: <LogisticAndFulfillmentServicesTemplate {...classes} header={POLICY_SECTION.LOGISTICS_AND_FULFILLMENT_SERVICES}/>
        }
       
    ]

    return <ContentPreviewer policySchema={TermsAndConditionsSchem}/>;
}

export default TermsAndConditions;