import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { HTTP_STATUS } from '../../../utils/types';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import BussinessProfileCINTemplate from './BussinessProfileCIN.template';
import OtherInformationTemplate from './Otherinformation.template';
import BussinessUdhyamDetailsTemplate from './BussinessUdhyamDetails.template';
import TurnOverTemplate from './TurnOver.template';
import { IFinance } from './TurnOverEdit.template';
import BussinessProfileAboutUsTemplate from './BussinessProfileAboutUs.templete';
import { udhyam } from './BussinessUdhyamEdit.template';
import { description } from './BussinessProfileAboutUsEditTemplate';
import BussinessProfileTemplate from './BussinessProfileGST.template';
import Shimmer from '../../molecules/ShimmerComponent/Shimmer';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.v2.text.primaryDark,
    },
}));

export interface GstDetails {
    gstin: string;
    pan: string;
}

export interface BusinessProfile { 
    name: string,
    yearOfEstablishment: number;
    dateOfEstablishment: number;
    numberOfEmployees: number;
    websiteUrl: string;
    industryType: string;
    businessType: string;
    brand: string;
    finance: IFinance;
    udhyam: udhyam;
    description: description;
    industry: string;
}

const BussinessProfile: React.FC<any> = () => {
    const classes = useStyles();
    const [section, setSection] = useState<string>("OTHER_INFORMATION");
    const bussinessProfileService = useBusinessProfileService();
    const [businessProfile, setBusinessProfileTo] = useState<any>();
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true); 



    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }

        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
        finally{
            setLoading(false);
        }
    };

    const updateBusinessProfileSection = async (type: string, data: any) => {
        let payload: any = {};
        switch (section) {
            case "UDHYAM":
                payload = {
                    udhyamNumber: data?.udhyam?.udhyamNumber,
                    businessType: data?.udhyam?.businessType,
                };
                break;
            case "CIN":
                payload = data?.cin;
                break;
            case "TURN_OVERS":
                payload = data?.finance?.turnOvers ?? [];
                break;
            case "OTHER_INFORMATION":
                payload = {
                    yearOfEstablishment: data?.dateOfEstablishment != null ? data?.dateOfEstablishment : businessProfile?.dateOfEstablishment,
                    numberOfEmployees: data?.totalEmployees != null ? data?.totalEmployees : businessProfile?.totalEmployees,
                    websiteUrl: data?.websiteUrl != null ? data?.websiteUrl : businessProfile?.websiteUrl,
                    industryType: data?.industryType != null ? data?.industryType : businessProfile?.industry,
                    businessType: data?.businessType != null ? data?.businessType : businessProfile?.businessType,
                    brand: data?.brand != null ? data?.brand : businessProfile?.brand,
                    description: data?.description != null ? data?.description : businessProfile?.description,
                };
                break;
        }
        const businessProfileResponse = await bussinessProfileService.updateBusinessProfile(payload, section);
        if (businessProfileResponse.status === HTTP_STATUS.OK) {
            getBusinessProfile();
        }
    };

    const handleSection = (section: string) => {
        setSection(section)
    }

    useEffect(() => {
        getBusinessProfile();
    }, []);

    const handleUpdateBusinessProfile = async () => {
        await getBusinessProfile();
    }

    if (loading) {
        return <Shimmer />;
    }
    return (
        <div className='grid gap-y-6 w-full'>
            <h1 className={`${classes.heading} text-lg font-semibold`}>Business Profile</h1>
            <div className='grid gap-y-3'>
                {businessProfile && (
                    <>
                        <BussinessProfileTemplate businessProfile={businessProfile} handleSection={handleSection} updateBusinessProfileSection={updateBusinessProfileSection} handleUpdateBusinessProfile={handleUpdateBusinessProfile} />
                        <BussinessProfileCINTemplate businessProfile={businessProfile} handleSection={handleSection} updateBusinessProfileSection={updateBusinessProfileSection} handleUpdateBusinessProfile={handleUpdateBusinessProfile} />
                        <BussinessUdhyamDetailsTemplate businessProfile={businessProfile} handleSection={handleSection} updateBusinessProfileSection={updateBusinessProfileSection} />
                        <TurnOverTemplate businessProfile={businessProfile} handleSection={handleSection} updateBusinessProfileSection={updateBusinessProfileSection} handleUpdateBusinessProfile={handleUpdateBusinessProfile} />
                        <OtherInformationTemplate businessProfile={businessProfile} handleSection={handleSection} updateBusinessProfileSection={updateBusinessProfileSection} handleUpdateBusinessProfile={handleUpdateBusinessProfile} />
                        <BussinessProfileAboutUsTemplate businessProfile={businessProfile} handleSection={handleSection} updateBusinessProfileSection={updateBusinessProfileSection} handleUpdateBusinessProfile={handleUpdateBusinessProfile} />
                    </>
                )}
                </div>
        </div>
    );
};

export default BussinessProfile;