import React from "react";
import {
  default as MuiTextField,
  TextFieldProps,
} from "@mui/material/TextField";

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return <MuiTextField {...props} />;
};

export default TextField;
