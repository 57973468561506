import React from "react";
import { createUseStyles } from "react-jss";
import CatalogueListItemSkeletonTemplate from "../Catalogue/CatalogueListItemSkeleton.template";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primaryLight}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.primaryDark}`,
        },
    },
    background: {
        background: theme.palette.background.primaryLight,
    },
}));

const LeadListSkeletonTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div className="grid gap-y-6 p-6 border-2 rounded">
            <div className={`h-14 w-full animate-pulse ${classes.background}`}></div>
            <CatalogueListItemSkeletonTemplate />
        </div>
    );
};

export default LeadListSkeletonTemplate;
