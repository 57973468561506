import React, { useEffect, useState } from "react";
import { IProductSpecification } from "../../pages/Catalogue/SecondaryCatalogueDetails.page";
import { IProductClassification } from "../../pages/Catalogue/SecondaryLotCatalouge.page";
import { createUseStyles } from "react-jss";
import { CART_SECTIONS } from "../../../utils/types";
import ListView from "../../organism/ListView/ListView.organism";
import CardItem from "./CartItem.template";
import MobileCardItem from "./MobileCartItem.template";
import CartPriceDetailsTemplate from "./CartPriceDetails.template";
import NoProduct from "../../molecules/NoProduct/NoProduct";
import Button from "../../atoms/Button/Button";
import phoneCall from "./../../../assets/images/phoneCall.svg";
import CartContactUsPop from "../../molecules/CartContactUs/CartContactUsPop";
import CartPriceDetailsSkeletonTemplate from "./CartPriceDetailsSkeleton.template";
import LeadListSkeletonTemplate from "../Lead/LeadListSkeleton.template";
import { IValueAddedServices } from "../../pages/Cart/CartDetails.page";

export interface IConfiguration {
	name: string;
	uom?: string | null;
	price: number;
	value: string;
}

export interface IContext {
	[key: string]: { [key: string]: IConfiguration };
}

export interface IProductAttribute {
	id: number;
	name: string;
	attributeType: string;
	uom?: string | null;
	fieldType?: string;
	attributeOptions: number | string | string[];
}

interface CartSectionProps {
	setCurrentSectionTo: (section: CART_SECTIONS) => void;
	cartItemDetails: ICartItem[];
	handleDeleteCartItem: (cartId: number) => void;
	handleCartItemUpdate: (cartId: number, key: string, value: number | string) => void;
	configuration: IContext;
	handleRfq: () => void;
	paymentMethod: string;
}

export interface ICartItem {
	id: number;
	catalogueName: string;
	catalogueId: number;
	upcId: number | null;
	warehouseId: number;
	warehouseName: string;
	warehouseAddress: string;
	quantity: number;
	uom: string;
	packagingType: string;
	upcPrice: number;
	attributes: {
		SPECIFICATION: IProductAttribute[];
		CLASSIFICATION: IProductAttribute[];
		GENERALIZATION: IProductAttribute[];
		productSpecification: IProductSpecification[];
		productClassification: IProductClassification[];
	};
	catalogueImages: string;
	cataloguePackagingTypes: string[];
	secondarySkuId: number | null;
	minimumOrderQuantity: number;
	secondaryLotAttributes: any;
	upcCode: string | null;
	valueAddedServices: IValueAddedServices | null;
	catalogueType: string;
	paymentType: string;
	skuCode: string;
}

const useStyles = createUseStyles((theme: any) => ({
	mainContainer: {
		paddingLeft: "70px",
		paddingRight: "70px",
	},
	leftContainer: {
		width: "67.31%",
	},
	subtitle: {
		color: theme.palette.textV2.primary.primary950,
	},
	rightContainer: {
		width: "30.92%",
		display: "flex",
	},
	rightChildContainer: {
		border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
		boxShadow: "0px 7px 18px 0px rgba(29, 54, 121, 0.12)",
	},
	rightMobileContainer: {
		display: "none"
	},
	phoneButton: {
		padding: "14px",
		backgroundColor: theme.palette.backgroundV2.primary.primary100,
	},
	"@media (max-width: 480px)": {
		mainContainer: {
			paddingLeft: "16px",
			paddingRight: "16px",
		},
		leftContainer: {
			width: "100%",
		},
		rightContainer: {
			display: "none",
		},
		rightMobileContainer: {
			display: "grid",
			border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
		},
		phoneButton: {
			padding: "10px",
		},
	},
}));

const CartItemDetail: React.FC<CartSectionProps> = ({ setCurrentSectionTo, cartItemDetails, handleDeleteCartItem, handleCartItemUpdate, configuration, handleRfq }) => {

	const [loading, setLoadingTo] = useState<boolean>(true);
	const classes = useStyles();
	const [contactIconStatus, setContactIconStatusTo] = useState<boolean>(false);

	const handleContactIconClick = () => {
		setContactIconStatusTo(!contactIconStatus);
	};

	useEffect(() => {
		if (cartItemDetails) {
			setLoadingTo(false);
		}
	}, []);

	return (
		<>
			<div className={`${classes.mainContainer} grid gap-x-6`}>
				{loading ? (
					<div className="flex gap-x-8 ">
						<div className="w-2/3 grid gap-y-8">
							{[null, null, null].map(() => (
								<div className="grid w-full gap-x-8">
									<div className="">
										<LeadListSkeletonTemplate />
									</div>
								</div>
							))}
						</div>
						<div className="w-1/3">
							<CartPriceDetailsSkeletonTemplate />
						</div>
					</div>
				) : (cartItemDetails && cartItemDetails.length) > 0 ? (
					<div className="flex gap-x-6">
						<div className={`${classes.leftContainer} grid gap-y-6 h-fit`}>
							<div className={`${classes.subtitle} text-2xl font-medium`}>{`My Cart (${cartItemDetails.length} Items)`}</div>
							<div>
								<ListView
									items={cartItemDetails}
									itemComponent={(item, index) => (
										<CardItem
											index={index}
											data={item}
											handleDeleteCartItem={handleDeleteCartItem}
											handleCartItemUpdate={handleCartItemUpdate}
											configuration={configuration}
										/>
									)}
								/>
							</div>
						</div>
						<div className={classes.rightContainer}>
							<div className={`z-50 rounded-2xl sticky top-0 ${classes.rightChildContainer} h-fit`}>
								{cartItemDetails && cartItemDetails.length > 0 && (
									<CartPriceDetailsTemplate
										data={cartItemDetails}
										configuration={configuration}
										handleRfq={handleRfq}
									/>
								)}
								<div className="flex justify-between gap-x-2 mx-4 mb-4 bg-white">
									<Button className="flex-1 !h-12" variant="primaryContained" size="large"
										// label="Choose your Payment Terms"
										label="Review"
										onClick={() =>
											// setCurrentSectionTo(CART_SECTIONS.PAYMENT_SECTION)
											setCurrentSectionTo(CART_SECTIONS.CART_REVIEW)
										}
									/>
									<div className={`rounded-lg cursor-pointer  ${classes.phoneButton}`} onClick={handleContactIconClick}>
										<img src={phoneCall} alt="call" />
									</div>
								</div>
								{contactIconStatus && <CartContactUsPop />}
							</div>
						</div>
					</div>
				) : (
					<NoProduct text="Your Cart is Empty." subText="Looks like you have not added anything to your cart. Go ahead and explore our categories." label="Shop our Products" />
				)}
			</div>

			{cartItemDetails && cartItemDetails.length > 0 && (
				<div className={` fixed bottom-0 z-50 bg-white !pl-0 ${classes.rightMobileContainer} w-full`}>
					{cartItemDetails && cartItemDetails.length > 0 && (
						<CartPriceDetailsTemplate
							data={cartItemDetails}
							configuration={configuration}
							handleRfq={handleRfq}
						/>
					)}
					<div className="flex justify-between gap-x-2 mx-4 mb-4 bg-white">
						<Button className='flex-1' variant="primaryContained" size="small" label="Review" onClick={() => setCurrentSectionTo(CART_SECTIONS.CART_REVIEW)} />
						<div className={`rounded-lg cursor-pointer  ${classes.phoneButton}`} onClick={handleContactIconClick}>
							<img className='w-3.5' src={phoneCall} alt="call" />
						</div>
					</div>
					{contactIconStatus && <CartContactUsPop />}
				</div>
			)}
		</>
	);
};

export default CartItemDetail;