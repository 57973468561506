import React from "react";
import { useStyles } from "./Style";
import Header from "../../organisms/Home/Header";
import NewsLetterTemplate from "./NewsLetter.Template";
import Footer from "../../atoms/Footer/Footer";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { ProductTabs, tabprops } from './ProductTabs.Template';

const GalvanizedSteelTemplate: React.FC = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <section className={classes.produts_banner}>
                <div className={`${classes.products_bannner_wrapper} ${classes.galvanised_banner}`}>
                    <div className={classes.products_banner_content}>
                        <h4>Products</h4>
                        <h3>Galvanized Steel</h3>
                        <h1>Built to Last, Shine to Impress</h1>
                        {/* <!-- <div className="banner_btn">
                    <a href="#">Learn More</a>
                </div> --> */}
                    </div>
                </div>
            </section>


            {/* <!-- about us sections starts here  --> */}

            <section className={`${classes.products_about_us} ${classes.spacing_strip} ${classes.section_space}`}>
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={classes.business_card}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={`${classes.section_heading_main} ${classes.gal_card_heading}`}>About Galvanized Products</h2>
                            <p className={classes.gal_card_des} >Galvanization is a manufacturing process that involves applying a layer of
                                zinc coating to steel
                                to prevent rust. This protective layer of zinc acts as a barrier against various corrosive
                                elements, ultimately enhancing the quality and longevity of the steel product. Galvanized sheet
                                metal is a highly favoured type of steel due to its increased strength, prolonged durability,
                                and improved formability. The fundamental principle behind galvanization is to create a
                                permanent bond between the steel and zinc, resulting in a more rust-resistant base metal.</p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/Galvanized/aboutGalvanized.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>


            {/* <!-- specification range section starts here  --> */}

            <section className={`${classes.specification_section} ${classes.section_strip}`}>
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading_main}>Specifications range</h3>
                    <div className={classes.card_parent_wrap}>
                        <div className={classes.p_card_wrapper}>
                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg6.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Thickness Ranges</span>
                                        <span className={classes.p_card_s_text}>0.25~2.00 mm (±0.003mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg2.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Width Range</span>
                                        <span className={classes.p_card_s_text}>50~1250mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg3.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Length Range</span>
                                        <span className={classes.p_card_s_text}>600-3000 mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg4.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Outer Diameter</span>
                                        <span className={classes.p_card_s_text}>1500-2200 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg5.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Inner Diameter</span>
                                        <span className={classes.p_card_s_text}>510, 600, 610 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg1.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Coil Weight</span>
                                        <span className={classes.p_card_s_text}>3-20 tonnes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!-- BIS Standards in Cold Rolled Steel section starts here --> */}

            <section className={classes.bis_section}>
                <div className={`${classes.bis_section_wrapper} ${classes.container}`}>
                    <h3>Galvanized as per BIS Standards</h3>
                    <div className={classes.bis_content_wrapper}>
                        <div className={classes.bis_content}>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 277</span>
                                    <span className={classes.table_subheading}>Year : 2019</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Galvanized Steel Strips and Sheets (Plain & Corrugated)- Specification (Seventh Revision)
                                    </p>
                                </div>
                            </div>

                            <div className={`${classes.bis_content_main} ${classes.last_row}`}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 2552</span>
                                    <span className={classes.table_subheading}>Year : 1989</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Steel Drums (galvanized And Ungalvanized)
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.bis_content}>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 16732</span>
                                    <span className={classes.table_subheading}>Year : 2019</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Galvanized Structural Steel â€” Specification
                                    </p>
                                </div>
                            </div>

                            <div className={`${classes.bis_content_main} ${classes.last_row}`}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- cards starts here  --> */}
            {/* 
    <section className="galvanised_cards section_strip" >
        <div className="g_card_wrapper container">
            <div className={classes.g_card_main}>
                <div className={classes.g_card}>
                    <div className={classes.g_card_preview}>
                        <img src="../assets/images/Galvanized/hotDip.webp" alt="" />
                    </div>
                    <div className="g_card_content">
                        <h4>Hot-Dip Galvanization</h4>
                        <p>Hot-dip galvanization is the process of coating steel with zinc by dipping it into a bath of
                            molten zinc. The steel is cleaned and fluxed before immersion to ensure a strong bond forms
                            between the zinc and the steel. Once removed from the bath, the zinc reacts with oxygen and
                            carbon dioxide to create a robust protective layer.</p>
                    </div>
                </div>
            </div>

            <div className={classes.g_card_main}>
                <div className={classes.g_card}>
                    <div className={classes.g_card_preview}>
                        <img src="../assets/images/Galvanized/electroGalv.webp" alt="" />
                    </div>
                    <div className="g_card_content">
                        <h4>Electro Galvanization</h4>
                        <p>This alternative process for galvanization avoids a molten zinc bath and instead uses an
                            electric current and an electrolyte solution to deposit zinc ions onto the metal substrate.
                            This results in a consistently precise and uniformly thick zinc coating, making it a
                            preferred choice for applications where accurate coating thickness is crucial.</p>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

            {/* <!-- grades section starts here  --> */}

            <section className={`${classes.section_space} ${classes.grades_section}`}>
                <div className={`${classes.grades_section_wrapper} ${classes.container}`}>
                    <h3>Grades as per BIS Standards</h3>
                    <div className={classes.grades_table}>
                        <div className={classes.grades_table_wrapper}>
                            <div className={classes.details_table}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>JSW GRADES</th>
                                            <th>TATA GRADES</th>
                                            <th>SAIL GRADES</th>
                                            <th>AMNS GRADE</th>
                                            <th>BIS GRADES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>CQ</td>
                                            <td>CQ</td>
                                            <td>CR1</td>
                                            <td>CR1</td>
                                            <td>CR1</td>
                                        </tr>
                                        <tr>
                                            <td>02</td>
                                            <td>DQ</td>
                                            <td>DQ</td>
                                            <td>CR2</td>
                                            <td>CR2</td>
                                            <td>CR2</td>
                                        </tr>
                                        <tr>
                                            <td>03</td>
                                            <td>DDQ</td>
                                            <td>DDQ</td>
                                            <td>CR3</td>
                                            <td>CR3</td>
                                            <td>CR3</td>
                                        </tr>
                                        <tr>
                                            <td>04</td>
                                            <td>EDDQ</td>
                                            <td>EDDQ</td>
                                            <td>CR4</td>
                                            <td>CR4</td>
                                            <td>CR4</td>
                                        </tr>
                                        <tr className={classes.last_row}>
                                            <td>05</td>
                                            <td>IF</td>
                                            <td>IF</td>
                                            <td>CR5</td>
                                            <td>CR5</td>
                                            <td>CR5</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Benefits of Galvanized Sheet Metal section  --> */}

            <section className={`${classes.specification_section} ${classes.section_strip} ${classes.seconday_cards_wrap}`} >
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading_secondary}>Benefits of Galvanized Sheet Metal</h3>
                    <div className={`${classes.card_wrapper_mobile} ${classes.p_card_wrapper}`}>
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/Galvanized/Protect.webp" alt="" />
                                <span>Reliable Coating</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/Galvanized/manHoleCover.webp" alt="" />
                                <span>Durable</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/Galvanized/coinInHand.webp" alt="" />
                                <span>Cost Effective</span>
                            </div>
                        </div>

                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/Galvanized/Ok.webp" alt="" />
                                <span>Complete Coverage</span>
                            </div>
                        </div>

                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/Galvanized/refreshShield.webp" alt="" />
                                <span>Fast Turnaround</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- tabs section starts here  --> */}

            <div className={`${classes.container} ${classes.section_strip}`}>
                <Box>
                    <Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Packaging" {...tabprops(0)} />
                            <Tab label="Coated Sheets" {...tabprops(1)} />
                            <Tab label="Railway Coach" {...tabprops(2)} />
                            <Tab label="Electrical Panels" {...tabprops(3)} />
                            <Tab label="Electrical Panels" {...tabprops(4)} />
                        </Tabs>
                    </Box>
                    <ProductTabs value={value} index={0}>

                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Galvanized/automotiveIndustry.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Automotive Industry</h2>
                                <p>Thanks to its superior finish and mechanical properties, galvanized steel is vital in making
                                    solid and durable automotive body parts and components.</p>
                            </div>
                        </div>

                    </ProductTabs>
                    <ProductTabs value={value} index={1}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Galvanized/buildingConstruction.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Building Construction Industry</h2>
                                <p>Galvanized steel is commonly used in construction for roofing, cladding, and framing due to
                                    its accuracy, smooth surface, and strength.</p>
                            </div>
                        </div>

                    </ProductTabs>
                    <ProductTabs value={value} index={2}>

                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Galvanized/Telecomm.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Telecommunication Industry</h2>
                                <p>Galvanized steel is vital in telecommunications for enclosures, cabinets, and components. Its
                                    precise finish and formability meet customization needs and industry standards.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={3}>

                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Galvanized/electricEquipment.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Electric Equipment</h2>
                                <p>Crafting reliable and visually appealing electric equipment that adheres to stringent
                                    industry standards requires galvanized steel due to its protective zinc coating and
                                    malleability. Galvanized steel is essential for reliable electric equipment that meets
                                    industry standards.</p>
                            </div>
                        </div>

                    </ProductTabs>

                    <ProductTabs value={value} index={4}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Galvanized/whiteGoods.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>White goods</h2>
                                <p>Galvanized steel is the preferred material for fabricating outer panels, doors, and
                                    structural components of white goods. Galvanised steel has durability, aesthetic appeal,
                                    refined surface finish, and enhanced strength.</p>
                            </div>
                        </div>
                    </ProductTabs>
                </Box>

            </div>


            {/* <!-- cards for mobile view  --> */}
            <section className={`${classes.section_strip} ${classes.mobile_section_cards}`}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Applications </h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Galvanized/automotiveIndustry.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Automotive Industry</span>
                                    <p>Thanks to its superior finish and mechanical properties, galvanized steel is vital in
                                        making solid and durable automotive body parts and components.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Galvanized/buildingConstruction.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Building Construction Industry</span>
                                    <p>Galvanized steel is commonly used in construction for roofing, cladding, and framing due
                                        to its accuracy, smooth surface, and strength.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Galvanized/Telecomm.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Telecommunication Industry</span>
                                    <p>Galvanized steel is vital in telecommunications for enclosures, cabinets, and components.
                                        Its precise finish and formability meet customization needs and industry standards.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Galvanized/electricEquipment.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Electric Equipment</span>
                                    <p>Crafting reliable and visually appealing electric equipment that adheres to stringent
                                        industry standards requires galvanized steel due to its protective zinc coating and
                                        malleability. Galvanized steel is essential for reliable electric equipment that meets
                                        industry standards.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Galvanized/whiteGoods.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>White goods</span>
                                    <p>Galvanized steel is the preferred material for fabricating outer panels, doors, and
                                        structural components of white goods. Galvanised steel has durability, aesthetic appeal,
                                        refined surface finish, and enhanced strength.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default GalvanizedSteelTemplate;
