import { FormHelperText } from '@mui/material';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { FormikProps } from 'formik';
import { ENTITY_TYPES } from '../../../utils/constant';

interface EntityTypeTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
};

const useStyles = createUseStyles((theme: any) => ({
    sectionText: {
        color: theme.palette.text.primaryDark
    },
    black: {
        color: theme.palette.text.primaryLightAccent
    }, dullText: {
        color: theme.palette.text.secondaryLightAccent
    },
    selected: {
        border: `1px solid ${theme.palette.button.primaryDark}`,
        background: theme.palette.background.secondaryLightAccent,
    }
}))

export interface IEntityType {
    business: {
        entityType: string
    }
}

const EntityTypeTemplate: React.FC<EntityTypeTemplateProps> = ({ formik, setFormState }) => {
    const classes = useStyles();
    const businessService = useBusinessService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [error, setError] = useState(false);
    const handleSelected = (option: string) => {
        setError(false)
        formik.setFieldValue('entityType', option)
    }

    const handleSubmit = () => {
        const entityTypeFieldValue = formik.getFieldProps("entityType").value;
        if (!entityTypeFieldValue.length) {
            setError(true);
            return;
        }
        user && businessService.updateBusiness({ business: { entityType: entityTypeFieldValue } }, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    formik.setFieldValue('entityType', entityTypeFieldValue);
                    setFormState(ONBOARDING_STATES.PRODUCT)
                }
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally();
    }

    return (
        <div>
            {SnackBarComponent}
            <span className={`text-xl ${classes.black} font-semibold`}>Please Enter Your Entity Type</span>
            <div className='mt-3 mb-8'>
                <div className='flex gap-2 flex-wrap'>
                    {
                        ENTITY_TYPES.map((option,index) => (
                            <span className={`py-2 px-4 text-lg border rounded border-accent-gray-950 accent-gray-800 w-fit cursor-pointer select-none ${formik.getFieldProps("entityType").value === option?.name && classes.selected}`}
                                onClick={() => handleSelected(option?.name)} key={index}>{option?.name}</span>
                        ))
                    }
                </div>
                {error &&
                    <FormHelperText error className='top-half '>Entity Type is required</FormHelperText>
                }
            </div>
            <Button variant="containedLight" label="Next" className="!h-12 mt-8" fullWidth onClick={handleSubmit} />
        </div>
    )
}

export default EntityTypeTemplate