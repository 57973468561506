import React, { useEffect } from "react";

interface ListViewProps {
  items: any[];
  itemComponent: (props: any, index:number) => React.ReactNode;
}

const ListView: React.FC<ListViewProps> = ({ items, itemComponent }) => {
  return (
    <div className="grid gap-y-4">
      {items.map((item: any, index:number) => (
        <>
         { itemComponent(item,index) }
        </>
      ))}
    </div>
  );
};
export default ListView;
