import { Grid } from "@mui/material";
import CatalogueImageTemplate, { IImage } from "../Catalogue/CatalogueImage.template";
import CatalogueDetailsTemplate from "../Catalogue/CatalogueDetails.template";
import { useLeadService } from "../../../services/useLeadService";
import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Attribute, HTTP_STATUS } from "../../../utils/types";
import SecondaryProductUpcItemTemplate from "../ProductUpc/SecondaryProductUpcItem";
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';
import classType from "../../../assets/icons/classType.svg";
import grade from "../../../assets/icons/grade.svg";
import shape from "../../../assets/icons/shape.svg";
import { snakeCaseToTitleCase } from "../../../utils/helper";
import { ISecondaryCatalogue } from "../../pages/Catalogue/SecondaryLotCatalouge.page";
import { useCatalogueService } from "../../../services/useCatalogueService";
import { SecondarySKUPackingListTemplate } from "../Catalogue/SecondarySKUPackingList.template";
import { CUSTOMER_ROUTES } from "../../../utils/constant";
import Button from "../../atoms/Button/Button";

export interface SecondaryLotLeadCatalogueDetails {
    shape: string;
    classType: string;
    grade: string;
    name: string;
}


const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.secondaryLight}`,
        padding: "24px"
    },
    title: {
        color: theme.palette.text.primaryDark,
    },
    property: {
        color: theme.palette.text.primaryLight,
    },
    value: {
        color: theme.palette.text.primaryLight,
    },
    detailContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    detailSubContainer: {
        rowGap: "8px"
    },
    catalogueName: {
        color: theme.palette.text.primaryDark,
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        }
    },
    webContainer: {
        display: "flex"
    },
    mobileContainer: {
        display: "none"
    },
    "@media (max-width: 476px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        container: {
            border: "none",
            padding: "0px",
            marginTop: "56px"
        },
        detailContainer: {
            display: "grid",
            rowGap: "16px"
        },
        detailSubContainer: {
            rowGap: "4px"
        },
        carouselContainer: {
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-dot-list": {
                top: "225px"
            },
            maxWidth: "287px",
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 20px`,
        },
    },
}));

export interface LeadUpc {
    secondaryCatalogueId: number;
    catalogueName: string;
    attributes: any;
    catalogueImages: IImage[];
    secondaryProductResponse: any;
    cataloguePackagingTypes: any;
    totalQuantity: number;
}

export interface UpcAttribute {
    SPECIFICATION?: Attribute[];
    productSpecification?: Attribute[];
}

const gridTemplate = {
    xs: 4,
    sm: 4,
    md: 3,
    lg: 2,
    xl: 2,
};

const catalogueDetailsGridTemplate = {
    xs: 6,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6,
};

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 10
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 670, min: 0 },
        items: 1
    }
};

const SecondaryLotLeadView: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const leadService = useLeadService();
    const catalogueService = useCatalogueService();
    const params = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const leadId = searchParams.get('leadId');
    const [leadUpc, setLeadUpcTo] = useState<LeadUpc | null>(null);
    const [secondaryProducts, setsecondaryProductsTo] = useState<ISecondaryCatalogue[] | null>([]);
    const [catalouge, setCatalouge] = useState<SecondaryLotLeadCatalogueDetails>({ shape: '', classType: '', grade: '', name: '' });
    const [quotationUpc, setQuotationUpcTo] = useState<any[]>([]);

    const loadLeadUpc = async () => {
        try {
            const leadUpcResponse = await leadService.getSecondaryLotLeadByCatalogueName(Number(params.leadUpcId));
            if (leadUpcResponse?.data?.data) {
                setLeadUpcTo(leadUpcResponse.data.data);
            } else {
                throw new Error("Rfqs fetch failed");
            }
        } catch (error) {
            setLeadUpcTo(null);
        }
    };

    const loadQuotations = async () => {
        try {
            const quotationResponse = await leadService.getQuotationByLeadId(Number(leadId));
            if (quotationResponse?.data?.data?.content?.length) {
                try {
                    const quotationUpcResponse = await leadService.getSecondaryLotQuotationById(quotationResponse.data.data.content[0].id);
                    if (quotationUpcResponse?.data?.data) {
                        setQuotationUpcTo(quotationUpcResponse.data.data.secondaryProducts);
                    } else {
                        throw new Error("Quotations fetch failed");
                    }
                } catch (error) {
                    setQuotationUpcTo([]);
                }
            } else {
                throw new Error("Quotations fetch failed");
            }
        } catch (error) {

        }
    };

    useEffect(() => {
        loadQuotations();
    }, [leadId]);

    const carouselRef = useRef<CarouselRef>(null);
    const CATALOGUE_DETAILS_SCHEMA = [{ label: "grade", icon: grade }, { label: "classType", icon: classType }, { label: "shape", icon: shape }];
    useEffect(() => {
        loadLeadUpc();
    }, [params.leadUpcId]);

    useEffect(() => {
        if (params.leadUpcId) {
            catalogueService.getSecondaryProductsByCatalogueId(Number(params.leadUpcId))
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK) {
                        setsecondaryProductsTo(response?.data?.data?.content ? response?.data?.data?.content : null)
                    }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setsecondaryProductsTo(null);
                });
        }
    }, [params.leadUpcId]);

    useEffect(() => {
        if (secondaryProducts && secondaryProducts.length > 0) {
            const updatedCatalouge: SecondaryLotLeadCatalogueDetails = {
                shape: secondaryProducts[0].shape || "",
                classType: "Secondary",
                grade: secondaryProducts[0].gradeName || "",
                name: ''
            };
            setCatalouge(updatedCatalouge);
        } else {
            setCatalouge({ shape: '', classType: '', grade: '', name: '' });
        }
    }, [secondaryProducts]);

    const handleBack = () => {
        navigate(`${CUSTOMER_ROUTES.DASHBOARD}${CUSTOMER_ROUTES.LEAD_SECONDARY_LOT}`)
    }

    return (
        <div className="grid gap-y-5">
            <div className={`${classes.container} w-full rounded-lg`}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className={classes.mobileContainer}>
                            <div>
                                <div className={`${classes.catalogueName} mb-4 text-base font-semibold`}>{leadUpc?.catalogueName}</div>
                                {leadUpc && leadUpc?.catalogueImages && leadUpc?.catalogueImages.length > 0 && <div className={`${classes.carouselContainer} relative rounded-2xl mb-6 mx-auto`}>
                                    <Carousel
                                        responsive={responsive}
                                        showDots={true}
                                        infinite={true}
                                        partialVisible={true}
                                        ref={carouselRef}
                                        removeArrowOnDeviceType={["sm-mobile"]}
                                        renderDotsOutside={true}
                                    >
                                        {leadUpc?.catalogueImages.map((item: any) => (
                                            <img className='rounded-2xl h-56 w-full object-cover' src={item?.path} alt="catalogueImages" />
                                        ))}
                                    </Carousel>
                                </div>}
                            </div>
                        </div>
                        <div className={classes.webContainer}>
                            <CatalogueImageTemplate isSharable={false} catalogueImages={leadUpc?.catalogueImages} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                        <div className="grid gap-y-6 mt-0 m-auto">
                            <div>
                                <div className={`${classes.catalogueName} text-base font-semibold`}>{leadUpc?.catalogueName}</div>
                                <CatalogueDetailsTemplate catalogue={catalouge} CATALOGUE_DETAILS_SCHEMA={CATALOGUE_DETAILS_SCHEMA} gridTemplate={catalogueDetailsGridTemplate} />
                            </div>
                            <SecondaryProductUpcItemTemplate data={leadUpc?.attributes?.productSpecification || null} gridTemplate={gridTemplate} />
                            <div className={`${classes.detailContainer}`}>
                                <div className={`${classes.detailSubContainer} grid `}>
                                    <div className={`${classes.property} text-sm font-semibold`}>Warehouse Locationn</div>
                                    <div className={`${classes.property} text-sm font-normal`}>{leadUpc?.secondaryProductResponse?.[0]?.warehouseName}</div>
                                </div>
                                <div className={`${classes.detailSubContainer} grid `}>
                                    <div className={`${classes.property} text-sm font-semibold`}>Packaging Types</div>
                                    <div className={`${classes.property} text-sm font-normal`}>{leadUpc?.cataloguePackagingTypes[0] && snakeCaseToTitleCase(leadUpc?.cataloguePackagingTypes[0])}</div>
                                </div>
                                <div className={`${classes.detailSubContainer} grid `}>
                                    <div className={`${classes.property} text-sm font-semibold`}>Quantity</div>
                                    <div className={`${classes.property} text-sm font-normal`}>{quotationUpc?.[0]?.quantity ?? leadUpc?.totalQuantity}</div>
                                </div>
                            </div>
                            <div>
                                <SecondarySKUPackingListTemplate secondaryProducts={quotationUpc.length > 0 ? quotationUpc?.[0]?.secondaryProductDetails : secondaryProducts} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="flex justify-end">
                <Button variant="tertiaryContained" label="Back" onClick={handleBack} />
            </div>
        </div>
    );
};

export default SecondaryLotLeadView;
