import { request } from "."
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const BUSINESS_DOCUMENT_URL = {
    CREATE: "/business-document",
    DELETE: "/business-document/:id"
};

export interface BusinessDocumentBody {
    type: string;
    businessId: number;
    content: any; 
}
interface IDeletePathRequest {
    path: string;
  }

export const useBusinessDocumentService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();
    
    const createBusinessDocument = (data: BusinessDocumentBody) => {
        return request(API_METHOD.POST, BUSINESS_DOCUMENT_URL.CREATE, null, data);
    };

    const deleteBusinessDocument = (id: number, params:IDeletePathRequest) => {
        return request(API_METHOD.DELETE, replaceUrlParams(BUSINESS_DOCUMENT_URL.DELETE, { id } ), authenticatedUser, null, {params} );
      };
    return {
        createBusinessDocument,
        deleteBusinessDocument
    };
};