import React, { ReactNode } from 'react';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createUseStyles } from 'react-jss';
import styled from '@emotion/styled';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import deleteOutlinedIcon from "../../../assets/icons/deleteOutlinedMedium.svg"
import { defaultTheme } from '../../../utils/theme';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


type StatusType = 'success' | 'error';

export interface IStatus {
    message: string,
    type: StatusType
}
interface CustomAccordionProps {
    index: number,
    expanded: number,
    component: ReactNode,
    heading: string,
    status?: IStatus,
    onChange: (index: number) => void;
    onDelete: (index: number) => void;
    isDeletable?: boolean
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.textV2.primary.primary500,
    },
    successStatus: {
        color: theme.palette.textV2.success.success500,
        background: theme.palette.backgroundV2.success.success50,
        padding: '6px 12px'
    },
    errorStatus: {
        color: theme.palette.textV2.failure.failure500,
        background: theme.palette.backgroundV2.failure.failure50,
        padding: '6px 12px'
    },
    accordionSummarySuccessBackground: {
        background: `${theme.palette.backgroundV2.secondary.secondary50}`
    },
    headingColor: {
        color: theme.palette.textV2.primary.primary900
    }
}));

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    border: 'none'
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    marginTop: '24px',
}));

export const CustomAccordion: React.FC<CustomAccordionProps> = ({ index, expanded, component, heading, status = null, onChange, onDelete, isDeletable = false }) => {
    const classes = useStyles();

    const AccordionSummary = styled((props: AccordionSummaryProps & { status: IStatus | null }) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))<AccordionSummaryProps & { status: IStatus | null }>(({ status }) => ({
        border: `1px solid ${defaultTheme.palette.backgroundV2.secondary.secondary50}`,
        borderRadius: '8px',
        zIndex: 1,
        background: status?.type === 'success' ? defaultTheme.palette.backgroundV2.primary.primary50 : 'inherit',
    }));

    const handleChange =
        (id: number) => (event: React.SyntheticEvent, isExpanded: boolean) => onChange(id);

    const handleDelete =
        (id: number) => (event: React.MouseEvent) => {
            event.stopPropagation();
            onDelete(id);
        };

    return (
        <div>
            <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1bh-content"
                    id={`accordion-${index}`}
                    status={status}
                >
                    <div className='flex justify-between w-full'>
                        <div className={`flex items-center gap-3`}>
                            <span className={`text-lg font-semibold ${classes.headingColor}`}>{heading}</span>
                            {(status && !!status.message) && (
                                <div className={`${classes[`${status.type}Status`]} rounded-3xl text-xs font-medium`}>
                                    <span>{status.message}</span>
                                </div>
                            )}
                        </div>
                        {isDeletable && <span onClick={handleDelete(index)} className='z-10 h-full '>
                            <img src={deleteOutlinedIcon} className='mr-3' alt='' />
                        </span>}
                    </div>

                </AccordionSummary>
                <AccordionDetails>
                    {component}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}


