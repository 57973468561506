import { FC, createContext, useEffect, useState } from "react";
import { CART_LIFE_CYCLE } from "../utils/constant";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { useWishlistService } from "../services/useWishlistService";

interface WishlistProviderType {
  children: React.ReactNode;
}

export interface WishlistContextType {
  wishlistItemsCount: number;
  syncWishlist: (message:string) => Promise<void>;
  tooltip: any;
  clearWishlistState: () => void;
  favourites: any
}

const getInitialWishlistState = () => {
  const storedState = localStorage.getItem("wishlistState");
  return storedState ? JSON.parse(storedState) : { wishlistItemsCount: 0, tooltip: { visible: false, message: "" } };
};

const WishlistContext = createContext<WishlistContextType>({
  wishlistItemsCount: 0,
  syncWishlist: async () => {},
  tooltip: { visible: false, message: "" },
  clearWishlistState: () => {},
  favourites: {}
});

const WishlistProvider: FC<WishlistProviderType> = ({ children }) => {

  const wishlistService = useWishlistService();
  const [wishlistItemsCount, setWishlistItemsCountTo] = useState<number>(getInitialWishlistState().wishlistItemsCount);
  const [favourites, setFavouritesTo] = useState<any>({});
  const [tooltip, setTooltip] = useState(getInitialWishlistState().tooltip);
  const { user } = useAuthenticatedUser();

  const showTooltip = (message: string) => {
    setTooltip({ visible: true, message });
    setTimeout(() => {
      setTooltip({ visible: false, message: '' });
    }, 2000);
  };
  
  const syncWishlist = async (message:string) => {
    try {
      if (!user?.id) return;
      const wishlistResponse = await wishlistService.getWishlist();
      const wishListItemCount = (wishlistResponse?.data?.data?.favourites?.catalogueIds?.length + wishlistResponse?.data?.data?.favourites?.secondaryCatalogueIds?.length) || 0;
      if(message === CART_LIFE_CYCLE.LOGIN){
        setWishlistItemsCountTo(wishListItemCount);
        setFavouritesTo(wishlistResponse?.data?.data?.favourites)
        return;
      }

      if (wishlistItemsCount < wishListItemCount) {
        showTooltip('Added to Wishlist');
      } else if (wishlistItemsCount > wishListItemCount && message === CART_LIFE_CYCLE.REMOVE_PRODUCT) {
        showTooltip('Deleted from Wishlist');
      } 
      setFavouritesTo(wishlistResponse?.data?.data?.favourites)
      setWishlistItemsCountTo(wishListItemCount);
    } catch (error) {
        setWishlistItemsCountTo(0);
        setFavouritesTo({})
      console.error("Error fetching data:", error);
    }
  };

  const clearWishlistState = () => {
    localStorage.removeItem("wishlistState");
    setWishlistItemsCountTo(0);
    setFavouritesTo({})
  };

  useEffect(() => {
    syncWishlist(CART_LIFE_CYCLE.LOGIN);
  }, []);

  useEffect(() => {
    localStorage.setItem("wishlistState", JSON.stringify({ wishlistItemsCount, tooltip }));
  }, [wishlistItemsCount, tooltip]);

  return (
    <WishlistContext.Provider value={{ wishlistItemsCount, syncWishlist, tooltip, clearWishlistState, favourites }}>
      { children }
    </WishlistContext.Provider>
  );
};

export { WishlistContext, WishlistProvider };
