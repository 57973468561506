import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { useState, useEffect } from 'react';
import BussinessUdhyamEditTemplate from './BussinessUdhyamEdit.template';
import noData from '../../../assets/images/noData.png';
import { getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
    },
    container_heading: {
        color: theme.palette.text.secondaryDark,
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
        background: theme.palette.v4.background._secondaryDarkAccent,
    },
    card_content: {
        "& span": {
            color: theme.palette.text.secondaryDark,
        },
        "& p": {
            color: theme.palette.text.secondaryDark,
        }   
    },
    secondaryText: {
        color: theme.palette.v3.text._secondaryLight,
    },
}));

interface BussinessUdhyamDetailsTemplateProps {
    businessProfile: any;
    handleSection: any;
    updateBusinessProfileSection: (section: string, values: any) => void;
}

const BussinessUdhyamDetailsTemplate: React.FC<BussinessUdhyamDetailsTemplateProps> = ({ businessProfile, handleSection, updateBusinessProfileSection }) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    const [profile, setProfile] = useState(businessProfile);

    useEffect(() => {
        setProfile(businessProfile);
    }, [businessProfile]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSuccess = (updatedProfile: any) => {
        setProfile(updatedProfile);
        setIsEditing(false);
    };

    const handleBack = () => {
        setIsEditing(false);
    };

    return (
        <>
            {isEditing ? (
                <BussinessUdhyamEditTemplate
                    businessProfile={profile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    onSuccess={handleSuccess}
                    onBack={handleBack}
                />
            ) : (
                <>
                    {profile?.udhyam ? (
                        <div className={`${classes.container} p-6 rounded-lg w-full`}>
                            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                                <h2 className={`${classes.container_heading} text-2xl font-medium`}>Udhyam Details</h2>
                                <Button variant={'tertiaryContained'} label={'Edit'} onClick={handleEditClick} />
                            </div>
                            <div className='flex gap-x-9 pt-6'>
                                <div className={classes.card_content}>
                                    <span>Business Classification</span>
                                    <p className='font-medium'>{getProfileDisplayData(profile?.udhyam?.businessType)}</p>
                                </div>
                                <div className={classes.card_content}>
                                    <span>Udhyam No.</span>
                                    <p className='font-medium'>{getProfileDisplayData(profile?.udhyam?.udhyamNumber)}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={`${classes.primaryContainer} py-14 px-6 flex justify-center w-full rounded-lg`}>
                            <div className='flex flex-col items-center'>
                                <img src={noData} alt="" className='mb-8' />
                                <p className={`${classes.secondaryText} mb-4 text-lg`}>You haven't provided Udhyam Details for your business.</p>
                                <Button className='rounded-lg' variant={'primaryContained'} onClick={handleEditClick} label={"Add Udhyam Details"} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default BussinessUdhyamDetailsTemplate;
