import React from 'react';
import { createUseStyles } from 'react-jss';
import { LOCATION_STATES } from '../../../utils/types';
import { LocationSectionProps } from './AddBusinessLocation.template';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../atoms/Button/Button';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.secondaryLight}`
    },
    helperText: {
        position: 'absolute',
        bottom: '-20px',
    },
    titleText: {
        color: theme.palette.v2.text.secondaryDark
    },
    text: {
        letterSpacing: "-0.1px",
       color: theme.palette.text.secondaryDarkLight,
    },
    icon: {
        backgroundColor: "#FD6202",
        color: "white",
        width: "48px",
        height: "48px",
        borderRadius: "10px",
    }
}));

const GetNumberOfBusinessLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const handleNext = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["noOfRows"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        if (fieldError === true) return;
        const numberOfRows = formik.values.noOfRows ?? 0;
        const emptyLocation = { gstin: '', state: '' };
        const newLocationsValues = Array.from({ length: numberOfRows }, () => ({ ...emptyLocation }));
        formik.setFieldValue('newLocations', newLocationsValues)
        setCurrentSectionTo(LOCATION_STATES.ADD_LOCATIONS);
    };
    const handleBack = () => {
        setCurrentSectionTo(LOCATION_STATES.VIEW_LOCATIONS) ;
        formik.setTouched({});
    };
    return (
        <>
        <div className={`${classes.container} py-14 px-6 rounded-lg`}>
            <div className='flex gap-x-4 items-end'>
                <div className='grid gap-y-2 w-1/3'>
                    <p className={classes.text}>Number of states you are registered with GST</p>
                    <TextFieldV2
                        variant="outlined"
                        placeholder="Enter data"
                        fullWidth
                     {...formik.getFieldProps("noOfRows")}
                     type="number"
                     error={
                         formik.touched.noOfRows &&
                         Boolean(formik.errors.noOfRows)
                     }
                     helperText={
                         Boolean(formik.touched?.noOfRows) &&
                         formik.errors?.noOfRows
                     }
                     FormHelperTextProps={{ className: `${classes.helperText}` }}
                    />
                </div>
                <div>
                    <AddIcon className={`${classes.icon} w-full cursor-pointer`} onClick={handleNext} />
                </div>
            </div>
            
        </div>
        <div className='flex items-end gap-6 justify-self-end'>
        {formik.values?.locations?.length == 0 &&< Button
            variant="tertiaryContained"
            label="Back"
            onClick={handleBack}
        />}
    </div>
    </>
    )
};
export default GetNumberOfBusinessLocationTemplate;
