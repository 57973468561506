import React from 'react';
import CompanyFacilities from '../../templates/Home/CompanyFacilities.template';
import WebsiteBanner from '../../templates/Home/WebsiteBanner.template';
import CatalogueList from '../../templates/Home/CatalogueList.template';
import FilterCatalogueList from '../../templates/Home/FilterCatalogueList.template';
import { createUseStyles } from 'react-jss';
import { HOMEPAGE_CATALOUGE_SECTION, HOMEPAGE_CATALOUGE_SECTION_HEADINGS } from '../../../utils/types';
import OurServicesTemplate from '../../templates/Home/OurServices.template';
import SliderTemplate from '../../templates/Home/SliderTemplate';
import JoinOurCommunity from '../../templates/Home/JoinOurCommunity.template';
import ProcessedMaterialTemplate from '../../templates/Home/ProcessedMaterial.template';
import { RequestForQuotationTemplate } from '../../templates/Home/RequestForQuotation.template';
import LatestNewsTemplate from '../../templates/Home/LatestNews.template';
import BussinessProfileCINTemplate from '../../templates/BussinessProfile/BussinessProfileCIN.template';

const useStyles = createUseStyles((theme: any) => ({
	recentCataloguecontainer: {
		// paddingTop: "96px",
		// paddingBottom: "96px"
	},
	hotCataloguecontainer: {
		paddingTop: "90px",
	},
	mobileContainer: {},
	"@media (max-width: 480px)": {
		recentCataloguecontainer: {
			paddingTop: "16px",
			paddingBottom: "16px"
		},
		hotCataloguecontainer: {
			paddingTop: "16px",
		},
		mobileContainer: {
			display: 'grid',
			gap: '32px',
			},
	}
}));

const Home: React.FC = () => {
	const classes = useStyles()
	return (
		<div>
			<WebsiteBanner />
			<div className={`${classes.mobileContainer} grid gap-y-24 max-container mx-auto`}>
				<FilterCatalogueList title={HOMEPAGE_CATALOUGE_SECTION_HEADINGS.CHOOSE_YOUR_SPECIFICATIONS} status={HOMEPAGE_CATALOUGE_SECTION.CHOOSE_YOUR_SPECIFICATIONS} />

				<ProcessedMaterialTemplate title={HOMEPAGE_CATALOUGE_SECTION_HEADINGS.PROCESSED_MATERIAL} />
				
				<FilterCatalogueList title={HOMEPAGE_CATALOUGE_SECTION_HEADINGS.TODAYS_HOT_OFFERS} status={HOMEPAGE_CATALOUGE_SECTION.TODAYS_HOT_OFFERS} />
				
          		<RequestForQuotationTemplate />
        		
				<OurServicesTemplate />
				
				<LatestNewsTemplate />
				
				<SliderTemplate />

				<JoinOurCommunity />
			</div>
		</div>
	);
}
export default Home;