import React from 'react'
import { createUseStyles } from "react-jss";
import { sortAttributes } from '../../../utils/helper';
import useMetaDataService from '../../../hooks/useMetaDataService';
import { ATTRIBUTE_LABEL, COLOR_DATA } from '../../../utils/constant';
import { IColorPalette } from './AttributeSelection.template';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border._primaryLight}`,
        background: 'white',
        boxShadow: `0px 18px 48px 0px ${theme.palette.boxShadow.secondaryDark}`,
        rowGap: '16px'
    },
    activeCategory: {
        color: theme.palette.text.primaryLightAccent,
    },
    tableHeader: {
        background: theme.palette.button.secondaryLight
    },
    value: {
        color: theme.palette.text._primaryDarkAccent
    },
    tableContainer:{
        width: "100%"
    },
    "@media (max-width: 480px)": {
        tableContainer: {
            width: "800px"
        },
    },
}));

export interface ISecondaryLotsAttributes {
    secondaryProducts: any;
}

export const SecondarySKUPackingListTemplate: React.FC<ISecondaryLotsAttributes> = ({ secondaryProducts }) => {

    const classes = useStyles();
    const { metaData: filterData } = useMetaDataService();
    const packingList: string[] = [];
    const sortedList = sortAttributes(secondaryProducts, filterData.attributes);
    // Todo need to change after the ranking part is done
    filterData?.attributes?.sort((a: any, b: any) => a?.id - b?.id).map(attribute => attribute?.name)
        .forEach((orderValue: string) => {
            sortedList.forEach(({ value: secondaryProductValue }: { value: any }) => {
                secondaryProductValue.attributes.productSpecification.forEach((spec: any) => {
                    if (spec.name.trim() === orderValue && !packingList.includes(spec.name.trim())) {
                        packingList.push(spec.name.trim());
                    }
                });
            });
        });
    sortedList.forEach(({ value: secondaryProductValue }: { value: any }) => {
        secondaryProductValue.attributes.productSpecification.forEach((spec: any) => {
            if (!packingList.includes(spec.name.trim())) {
                packingList.push(spec.name.trim());
            }
        });
    });

    const getAttributeValue = (attributeName: string, attributeValue: string) => {
        try {
            if(attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return attributeValue;
            }
              const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-4 h-3 mr-1 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
	}
    return (

        <div className={`grid rounded-3xl !p-6 ${classes.container}`}>
            <div className={`${classes.activeCategory} text-lg font-semibold`}>
                Packing List
            </div>
            <div className='w-full border rounded-2xl overflow-x-auto'>
               <div className={`${classes.tableContainer}`}>
               <table className={`w-full`}>
                    <thead>
                        <tr className={classes.tableHeader}>
                            <td className={`py-2 px-4 text-center font-medium ${classes.value}`}>
                                Sr No.
                            </td>
                            {packingList.map((item: any, index: number) => (
                                <td className={`py-2 px-4 text-center font-medium ${classes.value}`} key={item}>
                                    {item}
                                </td>
                            ))}
                            <td className={`py-2 px-4 text-center font-medium ${classes.value}`}>
                                Qty
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedList && sortedList?.map(({ value: secondaryProductValue }: { value: any }, index: number) => {
                            return (
                                <tr key={index} className={`${index === secondaryProducts.length - 1 ? '' : 'border-b'}`}>
                                    <td className={`py-2 px-4 text-center font-medium ${classes.activeCategory}`}>{index + 1}</td>
                                    {packingList.map((attribute: string, attributeIndex: number) => {
                                        const spec = secondaryProductValue.attributes.productSpecification.find((spec: any) => spec.name.trim() === attribute);
                                        return (
                                            <td className={`py-2 px-4 border-l border-r text-center ${classes.value}`} key={attributeIndex}>
                                                {spec && (spec.minValue === spec.maxValue ? getAttributeValue(spec.name, spec.minValue) : `${getAttributeValue(spec.name, spec.minValue)}-${getAttributeValue(spec.name, spec.maxValue)}`)}
                                            </td>
                                        );
                                    })}
                                    <td className={`py-2 px-4 border-l border-r text-center ${classes.value}`}>{secondaryProductValue?.netWeight}</td>
                                </tr>
                            )
                        })}
                    </tbody>

                </table>
               </div>
            </div>
        </div>
    )
}
