import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";

const AvighnaAdvantageTemplate: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <section className={classes.produts_banner}>
                <div className={classes.avighna_advantage_banner}>
                    <div className={classes.products_banner_content}>
                        <h1 className={classes.main_heading}>Avighna Advantage</h1>
                        <h3 className={classes.banner_heading_space}>Prime steel quality at secondary steel prices</h3>
                        {/* <!-- <div className="banner_btn news_btn">
                    <a href="#">Learn More</a>
                </div> --> */}
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className={`${classes.products_about_us} ${classes.card_sp}`}>
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>About Secondary Steel Processing</h2>
                            <p className={classes.gal_card_des} >Avighna processes secondary steel including surplus, over-rolled, line
                                rejections, quality defects, and scraps from various manufacturing processes. We reclaim 50-80%
                                of standard-quality prime materials, which are valuable in commercial endeavours, and benefit
                                steel manufacturers with competitive pricing. Reprocessing efforts reduce environmental
                                pollution, reflecting our commitment to sustainability, and foster collaboration and progress
                                within the steel industry.</p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/AvighnaAdvantage/aboutSSP.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- secondary steel section starts here  --> */}

            <section className={classes.section_strip}>
                <div className={`${classes.steel_process_wrapper} ${classes.container}`}>
                    <h3>Secondary Steel Processing</h3>
                    <div className={classes.process_content_wrap} >
                        <div className={classes.process_content}>
                            <div className={classes.process_content_main}>
                                <span>01</span>
                                <div className={classes.process_description}>
                                    <p>Procurement of Secondary Steel from Steel Mills</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.process_content}>
                            <div className={classes.process_content_main}>
                                <span>02</span>
                                <div className={classes.process_description}>
                                    <p>Shearing, Slitting, Blanking, and Printing occur</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.process_content}>
                            <div className={classes.process_content_main}>
                                <span>03</span>
                                <div className={classes.process_description}>
                                    <p>Segregation of Usable Prime Materials</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.process_content}>
                            <div className={classes.process_content_main}>
                                <span>04</span>
                                <div className={classes.process_description}>
                                    <p>Customization</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.process_content}>
                            <div className={classes.process_content_main}>
                                <span>05</span>
                                <div className={classes.process_description}>
                                    <p>Packing</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.process_content}>
                            <div className={classes.process_content_main}>
                                <span>06</span>
                                <div className={classes.process_description}>
                                    <p>Dispatch</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- cards section starts here  --> */}
            <section className={`${classes.section_strip} ${classes.section_margin} ${classes.seconday_cards_wrap} ${classes.specification_section}`}>
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={`${classes.cards_heading} ${classes.section_heading_main}`}>Benefits From Secondary Steel Processing</h3>
                    <div className={classes.p_card_wrapper}>
                        <div className={`${classes.card_main} ${classes.cards_secondary}`}>
                            <div className={classes.p_card_preview}>
                                <img src="../assets/images/AvighnaAdvantage/naturalFood.webp" alt="" />
                                <span>Green Steel</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary}`}>
                            <div className={classes.p_card_preview}>
                                <img src="../assets/images/AvighnaAdvantage/bankNotes.webp" alt="" />
                                <span>Commercial Valuation of Scraps</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary}`}>
                            <div className={classes.p_card_preview}>
                                <img src="../assets/images/AvighnaAdvantage/shipped.webp" alt="" />
                                <span>Exports Potential</span>
                            </div>
                        </div>

                        <div className={`${classes.card_main} ${classes.cards_secondary}`}>
                            <div className={classes.p_card_preview}>
                                <img src="../assets/images/AvighnaAdvantage/feedPaper.webp" alt="" />
                                <span>Customized Raw Materials</span>
                            </div>
                        </div>

                        <div className={`${classes.card_main} ${classes.cards_secondary}`}>
                            <div className={classes.p_card_preview}>
                                <img src="../assets/images/AvighnaAdvantage/coins.webp" alt="" />
                                <span>Huge Cost Advantage</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary}`}>
                            <div className={classes.p_card_preview}>
                                <img src="../assets/images/AvighnaAdvantage/greenEarth.webp" alt="" />
                                <span>Environmental Friendly</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={classes.section_strip}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Our Services</h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesShearing.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Shearing</span>
                                    <p>Steel Shearing is a precise process that cuts large sheets of steel into smaller,
                                        manageable sizes.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesSlitting.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Slitting</span>
                                    <p>Steel coils are longitudinally cut into narrower strips with uniform width and edge
                                        quality for industrial use.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesBlanking.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Blanking</span>
                                    <p>Steel Blanking is the method of punching out geometric shapes from flat steel sheets to
                                        create components used in manufacturing industries.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesMetalOffsetPrinting.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Metal Offset printing</span>
                                    <p>Metal offset printing transfers ink from a metal plate to a rubber blanket, producing
                                        high-quality prints on steel substrates.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesWarehousing.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Warehousing and Handling Services</span>
                                    <p>Avighna Steel Warehousing provides material handling services and supply chain management
                                        solutions to industries through advanced facilities and expertise.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesSteelLog.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Steel Logistics</span>
                                    <p>Efficient transportation of steel from manufacturers to end-users is crucial.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default AvighnaAdvantageTemplate;