import { Formik, FormikProps } from 'formik'
import React from 'react'
import { IMaterialRequirement } from '../../../pages/DashBoard/MyMaterialProduct.page'
import { createUseStyles } from 'react-jss';
import { capitalizeFirstLetter } from '../../../../utils/helper';

interface MaterialViewTemplateProps {
    materialRequirement:IMaterialRequirement
}

const useStyles = createUseStyles((theme: any) => ({
    section: {
        color: theme.palette.v2.text.primaryDark,
    },
    highlightedText: {
        color: theme.palette.textV2.primary.primary500
    },
    divider: {
        height: '1px',
        background: theme.palette.textV2.tertiary.tertiary100
    },
    tertiary100Border: {
        border: `1px solid ${theme.palette.textV2.tertiary.tertiary100}`
    },
    darkHeading: {
        color: theme.palette.textV2.primary.primary900
    },
    tertiary700: {
        color: theme.palette.textV2.tertiary.tertiary700
    }
}));

const MaterialViewTemplate: React.FC<MaterialViewTemplateProps> = ({ materialRequirement }) => {
    const classes = useStyles();
    return (
        <div className='flex flex-col gap-6'>
            <div>
                <div className={`pb-4 text-base font-medium ${classes.darkHeading}`}>Standard</div>
                <div className='grid grid-cols-3 gap-6'>
                    <div>
                        <div className={`${classes.tertiary700} font-normal`}>Standard</div>
                        <div className={`${classes.tertiary700} font-medium`}>{materialRequirement?.standardName}</div>
                    </div>
                    <div>
                        <div className={`${classes.tertiary700} font-normal`}>Grade</div>
                        <div className={`${classes.tertiary700} font-medium`}>{materialRequirement?.gradeName}</div>
                    </div>
                    <div>
                        <div className={`${classes.tertiary700} font-normal`}>Shape</div>
                        <div className={`${classes.tertiary700} font-medium`}>{materialRequirement?.shape?.split("_").map(el => capitalizeFirstLetter(el)).join(" ")}</div>
                    </div>
                </div>
            </div>
            <div>
                <div className={`pb-4 text-base font-medium ${classes.darkHeading}`}>Product Specification</div>
                <div className='grid grid-cols-3 gap-6'>
                    {
                        materialRequirement?.attributes?.map((attribute) => {
                            return (
                                <div>
                                    <div className={`${classes.tertiary700} font-normal`}>{`${attribute.name} ${attribute.uom ? `(${attribute.uom.toLowerCase()})` : ""}`}</div>
                                    <div className={`${classes.tertiary700} font-medium`}>{attribute.attributeOptions} </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div>
                <div className={`pb-4 text-base font-medium ${classes.darkHeading}`}>Other Information</div>
                <div className='grid gap-6'>
                    <div className='grid grid-cols-2 gap-6'>
                        <div>
                            <div className={`${classes.tertiary700} font-normal`}>Application Type</div>
                            <div className={`${classes.tertiary700} font-medium`}>{materialRequirement?.applicationType?.split("_").map(el => capitalizeFirstLetter(el)).join(" ")}</div>
                        </div>
                        <div>
                            <div className={`${classes.tertiary700} font-normal`}>Primary Sourcing Purpose</div>
                            <div className={`${classes.tertiary700} font-medium`}>{materialRequirement?.primarySourcingPurpose}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className={`pb-4 text-base font-medium ${classes.darkHeading}`}>Any Other Specific Requirement</div>
                <div className='grid grid-cols-2 gap-6'>
                    <div>
                        <div className={`${classes.tertiary700} font-medium`}>{materialRequirement?.otherSpecificRequirement}</div>
                    </div>
                </div>
            </div>
            <div>
                <div className={`pb-4 text-base font-medium ${classes.darkHeading}`}>Estimated Product Requirement</div>
                <div className='grid grid-cols-2 gap-6'>
                    <div>
                        <div className={`${classes.tertiary700} font-normal`}>Frequency</div>
                        <div className={`${classes.tertiary700} font-medium`}>{materialRequirement?.frequency}</div>
                    </div>
                    <div>
                        <div className={`${classes.tertiary700} font-normal`}>Quantity (MT)</div>
                        <div className={`${classes.tertiary700} font-medium`}>{materialRequirement?.quantity}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaterialViewTemplate