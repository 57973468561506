import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { Checkbox } from "@mui/material";
import { CATEGORY_TYPE, HTTP_STATUS, RFQ_SECTIONS } from '../../../utils/types';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useAdminMasterService } from '../../../services/useAdminMasterService';
import { Radio } from '@mui/material';
import { SHAPE_TYPES } from '../../../utils/constant';
import { InputAdornment } from '@mui/material';
import AutoCompleteInputV2, { AutoCompleteOption } from '../../atoms/AutoCompleteInput/AutoCompleteInputV2';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import SelectV2 from '../../atoms/Select/SelectV2';
import RadioActive from '../../../assets/images/radioActive.svg';
import RadioInActive from '../../../assets/images/radioInactive.svg'

interface ProductRequirementFormTemplateProps {
    setCurrentSectionTo: (section: RFQ_SECTIONS) => void;
    formik: any;
    setAttributeValues?: any
    attributeValues?: Attributes[]
    productSpecification?: any,
    setProductSpecificationOptions?: any
}
export interface IManufacturer {
    id: number;
    name: string;
}
export interface ICategory {
    id: number;
    name: string;
    parentCategory: ICategory;
    superParent: ICategory;
    standardDetails: IStandards[];
    attributeDetails: {
        SPECIFICATION: AttributeSpecification[];
    }
    images?: any[]
}

export interface IStandards {
    id: number;
    name: string;
}

export interface IGrade {
    id: number;
    name: string;
}

export interface Attributes {
    name: string;
    value: string;
}

export interface AttributeSpecification {
    id: number;
    name: string;
    uom: string | null;
}


const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    subHeading: {
        color: theme.palette.textV2.primary.primary900
    },
    form: {
        color: theme.palette.textV2.primary.primary800
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary300
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary200
    },
    value: {
        color: theme.palette.textV2.secondary.secondary900
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure500,
    },
    uom: {
        "& .MuiTypography-root": {
            color: theme.palette.textV2.secondary.secondary900
        }
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    mobileContainer: {
        display: "none"
    },
    productBar: {

    },
    customerBar: {

    },
    checkBox: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "16px",
        lineHeight: "20px"
    },
    "@media (max-width: 480px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        productBar: {
            width: "83.98%"
        },
        customerBar: {
            width: "12.89%"
        },
        checkBox: {
            color: theme.palette.textV2.tertiary.tertiary300,
            '&.Mui-checked': {
                color: theme.palette.textV2.primary.primary400,
            },
            "&.MuiSvgIcon-root": {
                width: "18px",
                height: "18px"
            }
        },
        label: {
            color: theme.palette.textV2.tertiary.tertiary600,
            fontSize: "14px",
            lineHeight: "20px"
        },
    }
}));

const paymentMode = [
    {
        id: 1,
        name: "Cash",
    },
    {
        id: 2,
        name: "Credit",
    }
]

const specificRequirement = [
    {
        id: 1,
        name: "Shearing",
    },
    {
        id: 2,
        name: "Slitting",
    },
    {
        id: 3,
        name: "Blanking",
    },
    {
        id: 4,
        name: "Metal Printing",
    }
]

const ProductRequirementFormTemplate: React.FC<ProductRequirementFormTemplateProps> = ({ setCurrentSectionTo, formik, setAttributeValues, attributeValues, productSpecification, setProductSpecificationOptions }) => {

    const classes = useStyles();
    const adminService = useAdminMasterService();
    const [superCategoryData, setSuperCategoryData] = useState<ICategory[] | null>(null);
    const [mainCategoryData, setMainCategoryData] = useState<ICategory[] | null>(null);
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
    const [standardData, setStandardData] = useState<IStandards[] | null>([]);
    const [gradeData, setGradeData] = useState<IGrade[] | null>([]);
    const [manufacturers, setManufactuerersTo] = useState<IManufacturer[]>([])



    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["customerId", "productCategoryId", "superCategory", "mainCategory", "productCategory", "standard", "grade", "manufacturerId", "gradeId", "standardId", "shape", "attributes", "requiredQuantity", "expectedDeliveryDate", "specificRequirement", "paymentTerm"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        if (fieldError === true) return;
        formik.setValues((prevValues: any) => ({
            ...prevValues,
            customerId: formik.values.customerId,
            productCategoryId: formik.values.productCategory.id,
            manufacturerId: formik.values.manufacturer.id,
            gradeId: formik.values.grade.id,
            standardId: formik.values.standard.id,
            shape: formik.values.shape,
            attributes: formik.values.attributes,
            requiredQuantity: formik.values.requiredQuantity,
            expectedDeliveryDate: formik.values.expectedDeliveryDate,
            specificRequirement: selectedRequirements,
            paymentTerm: formik.values.paymentTerm,
        }));

        setCurrentSectionTo(RFQ_SECTIONS.PERSONAL_DETAILS);
    };

    const loadSuperCategory = (inputValue?: string) => {
        adminService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setSuperCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadMainCategory = (inputValue?: string) => {
        if (formik?.values?.superCategory?.id) {
            adminService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.superCategory?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK)
                        setMainCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadProductCategory = (inputValue?: string) => {
        if (formik?.values?.mainCategory?.id) {
            adminService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.mainCategory?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK)
                        setProductCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadStandard = (inputValue?: string) => {
        adminService.getAllStandards({ search: inputValue ?? '', page: 0, size: 10, sort: 'createdAt,desc' })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setStandardData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Standards: ", error);
            })
    }

    const loadGrade = (inputValue?: string) => {
        if (formik?.values?.standard?.id) {
            adminService.getAllQualityGrades({ search: inputValue ?? '', type: 'GRADE', page: 0, size: 10, sort: 'createdAt,desc', standardId: formik?.values?.standard?.id ?? '' })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK)
                        setGradeData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadManufacturers = (inputValue?: string) => {
        adminService.getAllManufacturers({ page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? '' })
            .then(response => {
                if (response.status === HTTP_STATUS.OK)
                    setManufactuerersTo(response?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const updateSelectedCategory = (key: string) => (option: AutoCompleteOption | null) => {
        formik.setFieldValue(key, option);
        if (key === "superCategory") {
            formik.setFieldValue("mainCategory", { label: "", id: null });
            formik.setFieldValue("productCategory", { label: "", id: null });
        }
        if (key === "mainCategory") {
            formik.setFieldValue("productCategory", { label: "", id: null });
        }
        if (key === "standard") {
            formik.setFieldValue("grade", { label: "", id: null });
        }
        if (key === "productCategory") {
            const product = productCategoryData?.find((item: any) => item.id === option?.id);
            setProductSpecificationOptions(product?.attributeDetails?.SPECIFICATION);

            const initialAttributeValues = product?.attributeDetails?.SPECIFICATION?.map(attribute => ({
                name: attribute.name,
                value: ""
            }));
            setAttributeValues(initialAttributeValues);
        }
    };

    const superCategoryOptions = useMemo(() => {
        return superCategoryData?.map((superCategory: ICategory) => ({ label: superCategory.name, id: superCategory.id })) ?? []
    }, [superCategoryData])

    const mainCategoryOptions = useMemo(() => {
        return mainCategoryData?.map((mainCategory: ICategory) => ({ label: mainCategory.name, id: mainCategory.id })) ?? []
    }, [mainCategoryData])

    const productCategoryOptions = useMemo(() => {
        return productCategoryData?.map((productCategory: ICategory) => ({ label: productCategory.name, id: productCategory.id })) ?? []
    }, [productCategoryData])

    const standardOptions = useMemo(() => {
        return standardData?.map((standard: IStandards) => ({ label: standard.name, id: standard.id })) ?? []
    }, [standardData])

    const gradeOptions = useMemo(() => {
        return gradeData?.map((grade: IGrade) => ({ label: grade.name, id: grade.id })) ?? []
    }, [gradeData])

    const manufacturerOptions = useMemo(() => {
        return manufacturers?.map((manufacturer: IManufacturer) => ({ label: manufacturer.name, id: manufacturer.id })) ?? []
    }, [manufacturers])


    useEffect(() => {
        loadMainCategory();
    }, [formik?.values?.superCategory?.id]);

    useEffect(() => {
        loadProductCategory();
    }, [formik?.values?.mainCategory?.id]);

    useEffect(() => {
        loadGrade();
    }, [formik?.values?.standard?.id]);

    useEffect(() => {
        loadSuperCategory();
        loadStandard();
        loadManufacturers();
    }, []);

    useEffect(() => {
        setSelectedRequirements(formik.values.specificRequirement)
    }, [formik]);

    const [selectedRequirements, setSelectedRequirements] = useState<Array<string>>([]);

    const handleRequirementChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let local = [];
        if (event.target.checked) {
            setSelectedRequirements([...selectedRequirements, name]);
            local = [...selectedRequirements, name]
        } else {
            setSelectedRequirements(selectedRequirements.filter(item => item !== name));
            local = selectedRequirements.filter(item => item !== name)
        }
        formik.setFieldValue("specificRequirement", local);
    };

    const handleAttributeChange = (name: string, value: string) => {
        if (attributeValues) {
            const index = attributeValues.findIndex(attribute => attribute.name === name);
            if (index !== -1) {
                const updatedAttributeValues = [...(attributeValues as Attributes[])];
                updatedAttributeValues[index] = {
                    ...updatedAttributeValues[index],
                    value: value
                };
                setAttributeValues(updatedAttributeValues);
            }
        }
    };

    useEffect(() => {
        formik.setFieldValue('attributes', attributeValues);
    }, [attributeValues]);

    

    return (
        <>
            <div className={`${classes.webContainer} grid gap-y-6`}>
                <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto font-semibold`}>Product Requirements</div>
                        </div>
                        <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto`}>Personal Details</div>
                        </div>
                        <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                    </div>
                </div>
                <div className='grid gap-y-8'>
                    <div className='grid gap-y-3 '>
                        <div className='flex justify-between text-lg font-medium'>
                            <div className={classes.subHeading} >Tell us about your product requirements </div>
                            <div className={classes.form}>1/2</div>
                        </div>
                        <div className="grid grid-cols-3 gap-x-4">

                            <div className='w-full grid'>
                                <AutoCompleteInputV2
                                    placeholder='Super Category'
                                    options={superCategoryOptions}
                                    label="Super Category*"
                                    onChange={updateSelectedCategory('superCategory')}
                                    onSearch={loadSuperCategory}
                                    value={formik.values.superCategory}
                                    error={!!formik.errors.superCategory && formik.touched.superCategory}
                                />
                                {formik.errors.superCategory?.id && formik.touched.superCategory && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.superCategory?.id}</small>
                                    </div>
                                )}
                            </div>
                            <div className='w-full grid'>
                                <AutoCompleteInputV2
                                    placeholder='Main Category'
                                    options={mainCategoryOptions}
                                    label="Main Category*"
                                    onChange={updateSelectedCategory('mainCategory')}
                                    onSearch={loadMainCategory}
                                    value={formik.values.mainCategory}
                                    error={!!formik.errors.mainCategory && formik.touched.mainCategory}
                                    isDisabled={!formik?.values?.superCategory?.id}
                                />
                                {formik.errors.mainCategory?.id && formik.touched.mainCategory && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.mainCategory?.id}</small>
                                    </div>
                                )}
                            </div>

                            <div className='w-full grid'>
                                <AutoCompleteInputV2
                                    placeholder='Product Category'
                                    options={productCategoryOptions}
                                    label="Product Category*"
                                    onChange={updateSelectedCategory('productCategory')}
                                    onSearch={loadProductCategory}
                                    value={formik.values.productCategory}
                                    error={!!formik.errors.productCategory && formik.touched.productCategory}
                                    isDisabled={!formik?.values?.mainCategory?.id}
                                />
                                {formik.errors.productCategory?.id && formik.touched.productCategory && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.productCategory?.id}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-x-4">
                            <div className='grid'>
                                <AutoCompleteInputV2
                                    placeholder='Standard'
                                    options={standardOptions}
                                    label="Standard*"
                                    onChange={updateSelectedCategory('standard')}
                                    onSearch={loadStandard}
                                    value={formik?.values?.standard}
                                    error={!!formik.errors.standard && formik.touched.standard}
                                />
                                {formik?.errors?.standard?.id && formik?.touched?.standard && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.standard?.id}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid'>
                                <AutoCompleteInputV2
                                    placeholder='Grade'
                                    options={gradeOptions}
                                    label="Grade*"
                                    onChange={updateSelectedCategory('grade')}
                                    onSearch={loadGrade}
                                    value={formik?.values?.grade}
                                    error={!!formik.errors.grade && formik.touched.grade}
                                    isDisabled={!formik?.values?.standard?.id}
                                />
                                {formik?.errors?.grade?.id && formik?.touched?.grade && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.grade?.id}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid'>
                                <SelectV2
                                    placeholder='Shape'
                                    label="Shape*"
                                    fullWidth
                                    {...formik.getFieldProps("shape")}
                                    error={
                                        formik.touched.superCategory &&
                                        Boolean(formik.errors.superCategory)
                                    }
                                    options={
                                        SHAPE_TYPES?.map((option: any) => ({
                                            value: (option.id),
                                            label: option.name,
                                        })) || []
                                    }
                                />
                                {formik.touched.shape && formik.errors && formik.errors.shape && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.shape}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        {productSpecification &&
                            <div className="w-full grid">
                                <div className='w-full grid grid-cols-3 gap-x-4 gap-y-3'>
                                    {productSpecification?.map((specification: any, index: any) => (
                                        <div className='grid'>
                                            <TextFieldV2
                                                fullWidth
                                                placeholder={`${specification.name}`}
                                                id={specification.name}
                                                name={specification.name}
                                                label={`${specification.name}*`}
                                                type="text"
                                                value={attributeValues && attributeValues[index] ? attributeValues[index].value : ''}
                                                onChange={(e) => handleAttributeChange(specification.name, e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" className={`${classes.uom} pr-4 text-base font-normal`}>
                                                            {specification?.uom}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {formik?.touched && formik?.touched?.attributes && formik?.errors?.attributes && (
                                                <div className={`${classes.errorMessage}`}>
                                                    <small>{formik.errors.attributes[index]?.value}</small>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                            </div>
                        }
                        <div className="grid grid-cols-3 gap-x-4">
                            <div className='grid'>
                                <TextFieldV2
                                    fullWidth
                                    placeholder='Required Quantity'
                                    id="requiredQuantity"
                                    name='requiredQuantity'
                                    label="Required Quantity*"
                                    type="number"
                                    {...formik.getFieldProps("requiredQuantity")}
                                />
                                {formik?.touched && formik?.touched?.requiredQuantity && formik?.errors?.requiredQuantity && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik.errors.requiredQuantity}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid'>
                                <div className='grid gap-y-2'>
                                    <div className={classes.label}>
                                        Expected Delivery Date*
                                    </div>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            className={classes.input}
                                            name="expectedDeliveryDate"
                                            value={formik?.values?.expectedDeliveryDate ? moment(formik?.values?.expectedDeliveryDate) : null}
                                            format="DD-MM-YYYY"
                                            onChange={(newValue) => { formik.setFieldValue("expectedDeliveryDate", newValue) }}
                                            disablePast
                                        />
                                    </LocalizationProvider>
                                </div>
                                {formik?.errors?.expectedDeliveryDate && formik?.touched?.expectedDeliveryDate && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik.errors.expectedDeliveryDate}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid'>
                                <AutoCompleteInputV2
                                    placeholder='Manufacturer'
                                    options={manufacturerOptions}
                                    label="Select Manufacturer"
                                    onChange={updateSelectedCategory('manufacturer')}
                                    onSearch={loadManufacturers}
                                    value={formik?.values?.manufacturer}
                                    error={!!formik.errors.manufacturer && formik.touched.manufacturer}
                                />
                            </div>
                        </div>
                        <div className={`${classes.subHeading} text-base font-medium`}>Any Customisation or Specific Requirements*:</div>
                        <div className='grid'>
                            <div className='flex gap-x-8'>
                                {specificRequirement.map((item: any) => (
                                    <div className="flex gap-x-2" key={item.id}>
                                        <div>
                                            <Checkbox
                                                sx={{ padding: 0, width: "18px", height: "18px", }}
                                                className={classes.checkBox}
                                                checked={selectedRequirements.includes(item.name)}
                                                onChange={handleRequirementChange(item.name)}
                                            />
                                        </div>
                                        <div className={`${classes.value} my-auto text-base font-normal`}>{item.name}</div>
                                    </div>
                                ))}

                            </div>
                            {formik.touched && formik.touched?.specificRequirement && formik.errors?.specificRequirement && (
                                <div className={`${classes.errorMessage}`}>
                                    <small>{formik.errors.specificRequirement}</small>
                                </div>
                            )}
                        </div>
                        <div className='grid gap-y-3'>
                            <div className={`${classes.subHeading} text-base font-medium`}>Payment Terms*:</div>
                            <div className='grid'>
                                <div className='flex gap-x-4'>
                                    {paymentMode.map((item: any) => (
                                        <div className="flex gap-x-0.5" key={item.id}>
                                            <div>
                                                <Radio
                                                    icon={<img src={RadioInActive} alt="icon" />}
                                                    checkedIcon={<img src={RadioActive} alt="icon" />}
                                                    checked={formik.values.paymentTerm === item.name}
                                                    onChange={() => formik.setFieldValue('paymentTerm', item.name)}
                                                    value={item.name}
                                                />
                                            </div>
                                            <div className={`${classes.value} my-auto text-base font-normal`}>{item.name}</div>
                                        </div>
                                    ))}
                                </div>
                                {formik.touched && formik.touched?.paymentTerm && formik.errors?.paymentTerm && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors.paymentTerm}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button variant='primaryContained' label='Next' onClick={handleSubmit} className="!px-20" />
                    </div>
                </div>
            </div>


            <div className={`${classes.mobileContainer} grid gap-y-10`}>
                <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                    <div className={`${classes.productBar} grid gap-y-3`}>
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto font-semibold`}>Product Requirements</div>
                        </div>
                        <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className={`${classes.customerBar} grid gap-y-3 content-end`}>
                        <div className={`${classes.inActiveProgressBar} h-2.5 rounded mb-0 `}></div>
                    </div>
                </div>
                <div className='grid gap-y-8'>
                    <div className='grid gap-y-3 '>
                        <div className='flex justify-between text-lg font-medium'>
                            <div className={classes.subHeading} >Tell us about your product requirements </div>
                        </div>
                        <div className="grid gap-y-3">
                            <div className='w-full grid'>
                                <AutoCompleteInputV2
                                    placeholder='Super Category'
                                    options={superCategoryOptions}
                                    label="Super Category*"
                                    onChange={updateSelectedCategory('superCategory')}
                                    onSearch={loadSuperCategory}
                                    value={formik.values.superCategory}
                                    error={!!formik.errors.superCategory && formik.touched.superCategory}
                                />
                                {formik.errors.superCategory?.id && formik.touched.superCategory && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.superCategory?.id}</small>
                                    </div>
                                )}
                            </div>
                            <div className='w-full grid'>
                                <AutoCompleteInputV2
                                    placeholder='Main Category'
                                    options={mainCategoryOptions}
                                    label="Main Category*"
                                    onChange={updateSelectedCategory('mainCategory')}
                                    onSearch={loadMainCategory}
                                    value={formik.values.mainCategory}
                                    error={!!formik.errors.mainCategory && formik.touched.mainCategory}
                                    isDisabled={!formik?.values?.superCategory?.id}
                                />
                                {formik.errors.mainCategory?.id && formik.touched.mainCategory && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.mainCategory?.id}</small>
                                    </div>
                                )}
                            </div>
                            <div className='w-full grid'>
                                <AutoCompleteInputV2
                                    placeholder='Product Category'
                                    options={productCategoryOptions}
                                    label="Product Category*"
                                    onChange={updateSelectedCategory('productCategory')}
                                    onSearch={loadProductCategory}
                                    value={formik.values.productCategory}
                                    error={!!formik.errors.productCategory && formik.touched.productCategory}
                                    isDisabled={!formik?.values?.mainCategory?.id}
                                />
                                {formik.errors.productCategory?.id && formik.touched.productCategory && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.productCategory?.id}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="grid gap-y-3">
                            <div className='grid'>
                                <AutoCompleteInputV2
                                    placeholder='Standard'
                                    options={standardOptions}
                                    label="Standard*"
                                    onChange={updateSelectedCategory('standard')}
                                    onSearch={loadStandard}
                                    value={formik?.values?.standard}
                                    error={!!formik.errors.standard && formik.touched.standard}
                                />
                                {formik?.errors?.standard?.id && formik?.touched?.standard && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.standard?.id}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid'>
                                <AutoCompleteInputV2
                                    placeholder='Grade'
                                    options={gradeOptions}
                                    label="Grade*"
                                    onChange={updateSelectedCategory('grade')}
                                    onSearch={loadGrade}
                                    value={formik?.values?.grade}
                                    error={!!formik.errors.grade && formik.touched.grade}
                                    isDisabled={!formik?.values?.standard?.id}
                                />
                                {formik?.errors?.grade?.id && formik?.touched?.grade && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.grade?.id}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid'>
                                <SelectV2
                                    placeholder='Shape'
                                    label="Shape*"
                                    fullWidth
                                    {...formik.getFieldProps("shape")}
                                    error={
                                        formik.touched.superCategory &&
                                        Boolean(formik.errors.superCategory)
                                    }
                                    options={
                                        SHAPE_TYPES?.map((option: any) => ({
                                            value: (option.id),
                                            label: option.name,
                                        })) || []
                                    }
                                />
                                {formik.touched.shape && formik.errors && formik.errors.shape && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors.shape}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        {productSpecification &&
                            <div className="w-full grid">
                                <div className='w-full grid gap-x-3 gap-y-3'>
                                    {productSpecification?.map((specification: any, index: any) => (
                                        <div className='grid'>
                                            <TextFieldV2
                                                placeholder={`${specification.name}`}
                                                fullWidth
                                                id={specification.name}
                                                name={specification.name}
                                                label={`${specification.name}*`}
                                                type="text"
                                                value={attributeValues && attributeValues[index] ? attributeValues[index].value : ''}
                                                onChange={(e) => handleAttributeChange(specification.name, e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" className={`${classes.uom} pr-4 text-base font-normal`}>
                                                            {specification?.uom}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {formik?.touched && formik?.touched?.attributes && formik?.errors?.attributes && (
                                                <div className={`${classes.errorMessage}`}>
                                                    <small>{formik.errors.attributes[index]?.value}</small>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        <div className="grid gap-y-3">
                            <div>
                                <TextFieldV2
                                    fullWidth
                                    placeholder='Required Quantity'
                                    id="requiredQuantity"
                                    name='requiredQuantity'
                                    label="Required Quantity*"
                                    type="number"
                                    {...formik.getFieldProps("requiredQuantity")}
                                />
                                {formik?.touched && formik?.touched?.requiredQuantity && formik?.errors?.requiredQuantity && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik.errors.requiredQuantity}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid'>
                                <div className='grid gap-y-2'>
                                    <div className={classes.label}>
                                        Expected Delivery Date*
                                    </div>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            className={classes.input}
                                            name="expectedDeliveryDate"
                                            value={formik?.values?.expectedDeliveryDate ? moment(formik?.values?.expectedDeliveryDate) : null}
                                            format="DD-MM-YYYY"
                                            onChange={(newValue) => { formik.setFieldValue("expectedDeliveryDate", newValue) }}
                                            disablePast
                                        />
                                    </LocalizationProvider>
                                </div>
                                {formik?.errors?.expectedDeliveryDate && formik?.touched?.expectedDeliveryDate && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik?.errors?.expectedDeliveryDate}
                                    </div>
                                )}
                            </div>
                            <div className='grid'>
                                <AutoCompleteInputV2
                                    placeholder='Manufacturer'
                                    options={manufacturerOptions}
                                    label="Select Manufacturer"
                                    onChange={updateSelectedCategory('manufacturer')}
                                    onSearch={loadManufacturers}
                                    value={formik?.values?.manufacturer}
                                    error={!!formik.errors.manufacturer && formik.touched.manufacturer}
                                />
                            </div>
                        </div>
                        <div className='grid gap-y-2'>
                            <div className={`${classes.subHeading} text-base font-medium`}>Any Customisation or Specific Requirements*:</div>
                            <div className='grid'>
                                <div className='grid grid-cols-2 gap-x-2 gap-y-3'>
                                    {specificRequirement.map((item: any) => (
                                        <div className="flex gap-x-1.5" key={item.id}>
                                            <div className=''>
                                                <Checkbox
                                                    sx={{ padding: 0, width: "18px", height: "18px", }}
                                                    className={classes.checkBox}
                                                    checked={selectedRequirements.includes(item.name)}
                                                    onChange={handleRequirementChange(item.name)}
                                                />
                                            </div>
                                            <div className={`${classes.value} my-auto text-base font-normal`}>{item.name}</div>
                                        </div>
                                    ))}
                                </div>
                                {formik.touched && formik.touched?.specificRequirement && formik.errors?.specificRequirement && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik.errors.specificRequirement}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='grid gap-y-2'>
                            <div className={`${classes.subHeading} text-base font-medium`}>Payment Terms*:</div>
                            <div className='grid'>
                                <div className='flex gap-x-4'>
                                    {paymentMode.map((item: any) => (
                                        <div className="flex gap-x-0.5" key={item.id}>
                                            <div>
                                                <Radio
                                                    icon={<img src={RadioInActive} alt="icon" />}
                                                    checkedIcon={<img src={RadioActive} alt="icon" />}
                                                    checked={formik.values.paymentTerm === item.name}
                                                    onChange={() => formik.setFieldValue('paymentTerm', item.name)}
                                                    value={item.name}
                                                />
                                            </div>
                                            <div className={`${classes.value} my-auto text-base font-normal`}>{item.name}</div>
                                        </div>
                                    ))}
                                </div>
                                {formik.touched && formik.touched?.paymentTerm && formik.errors?.paymentTerm && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors.paymentTerm}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button variant='secondaryContained' size='small' label='Next' fullWidth onClick={handleSubmit} className="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductRequirementFormTemplate