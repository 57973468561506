import React, { useEffect, useState } from 'react'
import Button from '../../atoms/Button/Button'
import { createUseStyles } from 'react-jss';
import { IStatutoryDetailProps } from './StatutoryDetail.template';
import Select from '../../atoms/Select/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DOCUMENT_RESOURCE_TYPE, certificateTypeOptions, initialPaginationValues } from '../../../utils/constant';
import ImageUploadModalTemplate from './ImageUploadModal.template';
import { useFileService } from '../../../services/useFileService';
import { useBusinessDocumentService } from '../../../services/useBusinessDocumentService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import viewOutlinedIcon from '../../../assets/icons/viewOutlinedIcon.svg';
import { getFormattedDate } from '../../../utils/helper';
import { useSnackbar } from '../../../hooks/useSnackBar';
import DocumentViewTemplate from './DocumentView.template';
import  Table, { ColumnType } from '../../organisms/table';
import { IPagination } from '../../../utils/types';
import { IBusinessProfileStatutoryDocs } from '../../pages/DashBoard/StatutoryDetail.page';
import AlertPopupModelTemplate from './AlertPopupModel.template';
import EmptyBussinessDocumentTemplate from './EmptyBussinessDocument.template';

interface Column {
    label: string;
    key: string;
    type: ColumnType;
    props: { className: string };
  }
 

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.v2.text.primaryDark
    },
    headingText: {
        color: theme.palette.text.secondaryDark
    },
    paginationTable: {
        paddingLeft: '0px',
        overflowX: "hidden",
        "& .MuiTablePagination-root": {

        },
        "& .MuiTablePagination-selectLabel": {
            color: theme.palette.pagination.text.secondaryLight,
            fontWeight: 500,
            fontSize: "14px",
            paddingLeft: 0,
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #D6DEF5',
            width: '13%',
            paddingRight: '10px'
        },
        "& .MuiTablePagination-displayedRows": {
            color: theme.palette.pagination.text.secondaryLight,
            fontWeight: 500,
            fontSize: "14px",
        },
    },
    paginationComponent: {
        color: theme.palette.pagination.text.secondaryLight,
        fontWeight: 500,
        fontSize: "14px",
        marginLeft: '64%'
    },
    icon: {
        marginRight: '4px'
    },
    inputLabelText: {
        color: theme.palette.text.secondaryDark
    },
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.v4.background._primaryLightAccent,
    },
    tableHeadCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 0px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    tableCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 16px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    input: {
        width: '60px',
        borderRadius: '8px',
        border: '1px solid #D6DEF5',
        paddingLeft: '20px'
    },
}));

const UploadCertificatesDocumentTemplate: React.FC<IStatutoryDetailProps> = ({ formik, refreshBusinessProfile, showEditForm, showEditFormBank }) => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const classes = useStyles();
    const [certificateType, setCertificateType] = useState<string>('');
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileService = useFileService();
    const businessDocumentService = useBusinessDocumentService();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [isImageViewOpen, setIsImageViewOpen] = useState<boolean>(false);
    const [url, setUrl] = useState<string>('');
    const [documentType, setDocumentType] = useState<string>('');
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);
    const [pendingDelete, setPendingDelete] = useState<{ index: number; id: number; path: string } | null>(null);

    const handleCertificateTypeChange = (e: any) => {
        setCertificateType(e.target.value);
    };
    const isFormValid = (): boolean => {
        if (!certificateType || certificateType === '') {
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        const businessId = Number(authenticatedUser?.businessId);
        if (selectedFile) {
            try {
                formik.setSubmitting(true); 
                const presignedUrl = await fileService.generatePresignedUrl(
                    selectedFile.name,
                    DOCUMENT_RESOURCE_TYPE.BUSINESS_PROFILE_STATUTORY,
                    businessId
                );
                if (presignedUrl) {
                    const fileType = selectedFile.type;
                    const isPdf = fileType === 'application/pdf';
                    const imageUploadResponse = isPdf ? await fileService.uploadPdfUsingPreSignedUrls(
                        selectedFile,
                        presignedUrl?.data?.data?.presignedUrl
                    ) : await fileService.uploadImageUsingPreSignedUrls(
                        selectedFile,
                        presignedUrl?.data?.data?.presignedUrl
                    );
                    const requestBody = {
                        type: 'BUSINESS_PROFILE_STATUTORY',
                        businessId: businessId,
                        content: {
                            location: presignedUrl?.data?.data?.objectKey,
                            certificateType: certificateType,
                        },
                    };
                    const businessDocumentResponse = await businessDocumentService.createBusinessDocument(requestBody);
                    refreshBusinessProfile();
                    setSelectedFile(null); 
                }
            } catch (error) {
                console.error("Error uploading image or creating document", error);
            } finally {
                formik.setSubmitting(false); 
            }
            setIsImageModalOpen(false);
        }
    };
    
    const handleDelete = async (index: number, id: number, path: string) => {
        setPendingDelete({ index, id, path });
        setIsAlertModalOpen(true);
    };

    const handleDialogClose = async (event: boolean) => {
        setIsAlertModalOpen(false);
        if (event && pendingDelete) {
            formik.setSubmitting(true); 
            const { index, id, path } = pendingDelete;
            const newDocs = [...formik.values.docs];
            newDocs.splice(index, 1);
            formik.setFieldValue('docs', newDocs);

            try {
                await businessDocumentService.deleteBusinessDocument(id, { path });
                showSnackbar("success", "Document deleted successfully");
                refreshBusinessProfile();
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                setPendingDelete(null);
                formik.setSubmitting(false);
            }
        }
    };

    const handleView = async (path: string) => {
        const fileResponse = await fileService.getImagePreSignedUrl(path);
        setUrl(fileResponse?.data?.data);
        setDocumentType(fileResponse?.config?.url?.split('.')[1]);
        setIsImageViewOpen(true);
    };
   
    const displayedDocDetails = formik.values.docs
    .slice() 
    .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())  
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };
    
    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };
    

    const Action = (index:number, id: number, path:string) => (
        <div className='flex items-center justify-center gap-x-3'>
            {onEdit && <button onClick={() => handleDelete(index, id, path)}><img src={deleteIcon} alt={deleteIcon} /></button>}
            <button onClick={() => handleView(path)}><img src={viewOutlinedIcon} alt={viewOutlinedIcon} /></button>
        </div>
    )

    const getTableColumns = (edit: boolean): Column[] => {
        const columns: Column[] = [
          { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
          { label: "Certificate type", key: "accountName", type: "custom" as ColumnType, props: { className: '' } },
          { label: "Upload Date", key: "accountNumber", type: "customm" as ColumnType, props: { className: '' } },
          { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
        ];
      
        return columns;
      };

    const getRecords = () => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const paginatedDetails = formik.values.docs
            .slice() 
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())  
            .slice(startIndex, endIndex);
        return paginatedDetails.map((displayedDocDetail: IBusinessProfileStatutoryDocs, index) => [
            startIndex + index + 1,
            displayedDocDetail.content.certificateType.charAt(0).toUpperCase() + displayedDocDetail.content.certificateType.slice(1),
            getFormattedDate(displayedDocDetail.updatedAt),
            Action(index, displayedDocDetail.id, displayedDocDetail.content.location)
        ]);
    }

    const getSchema = () => ({
        id: "1",
        pagination: {
            total: formik.values.docs.length,
            currentPage: page,
            isVisible: true,
            limit: rowsPerPage,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: getTableColumns(!onEdit) ?? []
    });

    useEffect(() => {
        getRecords();
    }, [page, rowsPerPage]);
    
    return (
         (formik.values.docs.length != 0 || onEdit)  ? 
        <div className='p-6 grid gap-6 border rounded-lg'>
            <div className=' flex justify-between'>
                <text className={`${classes.headingText} self-center text-2xl font-medium`}>Upload certificate/documents</text>
                <Button
                    variant={onEdit ? 'primaryContained' : 'tertiaryContained'}
                    label={onEdit ? 'Save' : 'Edit'}
                    size='small'
                    onClick={() => setOnEdit(!onEdit)}
                />
            </div>
            <div className="border-t border-gray-300 my-2"></div>
            {
                onEdit &&
                <div className=' grid gap-1'>
                    <label className={`${classes.headingText} self-center text-base font-medium`}>Certificate Type</label>
                    <div className='flex justify-start gap-4 '>
                        <div className='w-1/3 '>
                            <Select
                                label=""
                                variant='standard'
                                disableUnderline={true}
                                value={certificateType}
                                onChange={(e: any) => handleCertificateTypeChange(e)}
                                className={` !m-0 !px-2 p-2 border`}
                                fullWidth
                                name="certificateType"
                                IconComponent={ArrowDropDownIcon}
                                classes={{ icon: classes.icon }}
                                options={certificateTypeOptions}
                            />
                        </div>
                        {isImageModalOpen &&
                            <ImageUploadModalTemplate
                                dialogOpen={isImageModalOpen}
                                setDialogOpen={setIsImageModalOpen}
                                onSubmit={handleSubmit}
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                            />}
                        <Button
                            variant='primaryContained'
                            label={'Upload'}
                            size='medium'
                            disabled={!isFormValid()}
                            onClick={() => setIsImageModalOpen(true)}
                            className='self-center'
                        />
                    </div>
                    {
                    isAlertModalOpen &&
                    <AlertPopupModelTemplate
                        dialogOpen={isAlertModalOpen}
                        heading={"Delete Document"}
                        text={"Are you sure want to delete this Document file"}
                        handleDialogClose={handleDialogClose}
                    />
                }
                </div>
            }
          {formik.values.docs.length != 0 &&            
          <div className='w-full'>
                <Table schema={getSchema()} records={getRecords()} />
            </div>
           }
            {
                isImageViewOpen && 
                <DocumentViewTemplate 
                dialogOpen={isImageViewOpen} 
                setDialogOpen={setIsImageViewOpen} 
                url={url}
                documentType={documentType}
                />
            }
        </div> :
           showEditForm && <EmptyBussinessDocumentTemplate  setEdit={setOnEdit}  />      
    )
}

export default UploadCertificatesDocumentTemplate