import React, { useState } from 'react'
import cardBoardFilled from '../../../assets/images/filledCardBoard.svg'
import { createUseStyles } from 'react-jss'
interface NoDataFrameTemplate {
    title: string,
    message: string
}

const useStyles = createUseStyles((theme: any) => ({
title: {
    color: theme.palette.textV2.primary.primary500
},
message: {
    color: theme.palette.textV2.tertiary.tertiary600
}
}))

const NoDataFrameTemplate: React.FC<NoDataFrameTemplate> = ({title, message}) => {
    const classes = useStyles();
  return (
    <div className='flex flex-col justify-center items-center'>
        <div className='mb-6'>
            <img src={cardBoardFilled} alt="" />
        </div>
        <div className={`${classes.title} text-[32px] font-bold mb-2`}>{title}</div>
        <div className={`${classes.message}`}>{message}</div>
    </div>
  )
}

export default NoDataFrameTemplate