import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FormikErrors } from 'formik';
import Button from '../../atoms/Button/Button';
import { useGstinService } from '../../../services/useGstService';
import { LOCATION_STATES } from '../../../utils/types';
import { IGstnState } from '../../pages/DashBoard/BussinessLocation.page';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import GntnDetailModalTemplate from './GstnDetailModal.Template';
import { LocationSectionProps } from './AddBusinessLocation.template';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.secondaryLight}`
    },
    button: {
        backgroundColor: theme.palette.border.primaryDarkAccent,
        color: 'white',
    },
    helperText: {
        position: 'absolute',
        bottom: '-20px',
    },
    titleText: {
        color: theme.palette.v4.text._secondaryDark
    },
    textFeild: {
        width: "260px"
    },
    text:{
        color: theme.palette.v4.text._primaryDark
    }
    
}));

const AddNewBusinessLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const gstinService = useGstinService();
    const [gstinModalDialogOpen, setGstinModalDialogOpen] = useState<boolean>(false);
    const [index, setIndexTo] = useState<number | null>(null);
    const [isFormValidate, setIsFormValidate]= useState<boolean>(true);

    const validateGstin = (gstin: string) => (index: number) => async () => {
        const gstinInput = gstin;
    
        if (gstin.length === 0) {
            formik.setFieldValue(`newLocations.${index}.gstin`, gstinInput, false);
            formik.setFieldValue(`newLocations.${index}.state`, '', false);
            formik.setFieldTouched(`newLocations.${index}.gstin`, false, false);
            return;
        }
    
        let error = '';
        let state = '';

        if(gstin.length >0 && gstin.length < 15){ error = 'This GSTIN is not valid'}
        
        if (gstin.length === 15) {
            error = '';
            const isDuplicateGstExistsInLocations = formik.values.newLocations.some(
                (location: any, idx: number) => location.gstin?.toLowerCase() === gstinInput?.toLowerCase() && idx !== index
            );
            const isDuplicateGstExistsInExistingLocations = formik.values.locations?.some(
                (location) => location.gstin?.toLowerCase() === gstinInput?.toLowerCase()
            );
    
            if (isDuplicateGstExistsInExistingLocations || isDuplicateGstExistsInLocations) {
                error = 'duplicate gst number';
            } else {
                const response = await gstinService.searchGstin({ gstin });
                if (response?.data?.data?.valid) {
                    state = response.data.data.state?.join(', ');
                    isFormValid()
                } else {
                    error = response?.data?.data?.message || 'This GSTIN is not valid';
                }
            }
        }
        if (gstin.length > 15) {error = 'This GSTIN is not valid'}
        formik.setFieldValue(`newLocations.${index}.gstin`, gstinInput, false);
        formik.setFieldValue(`newLocations.${index}.state`, state, false);
        formik.setFieldError(`newLocations.${index}.gstin`, error);
        formik.setFieldTouched(`newLocations.${index}.gstin`, true, false);
    };

    const onSubmit = () => {
        if (isFormValid()) {
            formik.handleSubmit();
            setCurrentSectionTo(LOCATION_STATES.VIEW_LOCATIONS);
        }
    };
    const isFormValid = () => {
        const { newLocations } = formik.values;
        const {errors} = formik;
        if (!Array.isArray(newLocations) || newLocations.length === 0) {
            return false;
        }
        return newLocations?.every((location, index) => {
            const hasValidGstin = Boolean(location.gstin);
            const hasValidState = Boolean(location.state);
            const locationErrors = (errors?.newLocations as FormikErrors<IGstnState>[] | undefined)?.[index];
            const hasErrors = locationErrors ? Boolean(locationErrors.gstin) : false;
            return hasValidGstin && hasValidState && !hasErrors;
        });
    };
    const deleteLocation = (index: number) => {
        const newLocations = [...formik.values.newLocations];
        newLocations.splice(index, 1);
        formik.setFieldValue('newLocations', newLocations);
        if (newLocations.length === 0) {
            setCurrentSectionTo(LOCATION_STATES.VIEW_LOCATIONS);
            formik.setTouched({});
        }

    };

    const handleBack = () => {
        formik.values.locations?.length > 0 ? setCurrentSectionTo(LOCATION_STATES.VIEW_LOCATIONS) : setCurrentSectionTo(LOCATION_STATES.ADD_LOCATION_NO) ;
        formik.setTouched({});
    };

    const handleDiaglogBox = (index: number) => {
        formik.values.newLocations?.[index].state && setGstinModalDialogOpen(true);
        setIndexTo(index);
    }
    useEffect(()=>{
        if(formik.errors.newLocations && formik.errors.newLocations){
           setIsFormValidate(!isFormValid())
        }
    },[formik.values.newLocations])
    return (
        <>
        {formik.values.locations.length > 0 && <div className={`${classes.text} justify-self-end mt-8 `}>No. of Business Location selected previously : {formik.values.locations.length.toString().padStart(2, '0')}</div>}
            <div className={`${classes.container} p-6 grid gap-6 rounded-lg`}>
                {formik.values.newLocations?.map((location: any, index: number) => (
                    <div key={index} className='flex gap-x-6 items-end'>
                        <div className={`boder rounded-lg ${classes.button} w-12 h-12 text-lg flex justify-center text-center items-center font-semibold`}> {index+1} </div>
                        <div className={`${classes.textFeild}`}>
                            <TextFieldV2
                                label="GST"
                                placeholder="Enter GST"
                                fullWidth
                                {...formik.getFieldProps(`newLocations.${index}.gstin`)}
                                error={
                                    formik.touched.newLocations?.[index]?.gstin &&
                                    Boolean(
                                        formik.errors.newLocations &&
                                        Array.isArray(formik.errors.newLocations) &&
                                        (formik.errors.newLocations as FormikErrors<IGstnState>[])[index]?.gstin
                                    )
                                }
                                helperText={
                                    formik.touched.newLocations?.[index]?.gstin &&
                                    Array.isArray(formik.errors.newLocations) &&
                                    (formik.errors.newLocations as FormikErrors<IGstnState>[])[index]?.gstin
                                }
                                FormHelperTextProps={{ className: `${classes.helperText}` }}
                                onChange={(event) => validateGstin(event.target.value)(index)()}
                            />
                        </div>
                        <div className={`${classes.textFeild}`}>
                            <TextFieldV2
                                label="Location"
                                placeholder="State will be added"
                                {...formik.getFieldProps(`newLocations.${index}.state`)}
                                disabled={true}
                            />
                        </div>
                        <div className=''>
                            <Button
                                variant="tertiaryContained"
                                label="View Details"
                                onClick={() => handleDiaglogBox(index)}
                                disabled={!formik.values.newLocations?.[index]?.state}
                            />
                        </div>
                        <div className='mb-2.5'>
                            <img src={deleteIcon} alt="deleteicon" onClick={() => { deleteLocation(index) }} />
                        </div>
                    </div>
                ))}
                { index != null && formik.values.newLocations?.[index]?.state && gstinModalDialogOpen && <GntnDetailModalTemplate dialogOpen={gstinModalDialogOpen} setDialogOpen={setGstinModalDialogOpen} index={index} locations={formik.values.newLocations}  />}
            </div>
            <div className='flex items-end gap-6 justify-self-end'>
                <Button
                    variant="tertiaryContained"
                    label="Back"
                    onClick={handleBack}
                />
                <Button
                    variant="primaryContained"
                    label="Save"
                    onClick={onSubmit}
                    disabled={isFormValidate}
                />
            </div>
        </>
    )
};
export default AddNewBusinessLocationTemplate;
