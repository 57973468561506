import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { PRICE_DETAILS, STATUS } from "../../../utils/constant";
import PricingInfo from "../../organisms/PricingInfo";
import { toInr } from "../../../utils/helper";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

export interface IChargesProps {
  title: string;
  subTitle?: string | null;
  price: number;
  icon?: React.ReactNode;
  iconInfo?: string | null;
  showFullQuantity: boolean;
}

export interface IPriceDetails {
  subTotal: IChargesProps;
  warehouseCharges: IChargesProps;
  handlingCharges: IChargesProps;
  GST: IChargesProps;
  TCS: IChargesProps;
}

export interface IPaymentInfoProps {
  label: string;
  status?: string;
  showStatus?: boolean;
  priceDetails: IPriceDetails;
  quantity: number;
  uom: string;
  totalPrice: number;

}

const useStyles = createUseStyles((theme: any) => ({
  priceDetailsHeading: {
    boxShadow: `0px 4px 45px 0px ${theme.palette.boxShadow.primaryLight}`,
  },
  priceInformation: {
    color: theme.palette.text.secondaryDark,
    borderBottom: `1px solid ${theme.palette.border.secondaryLight}`,
  },
  lessTcs: {
    borderBottom: `1px solid ${theme.palette.border.secondaryLight}`,
  },
  grandTotal: {
    color: theme.palette.text.secondaryDark,
    fontSize: "20px"
  },
  property: {
    color: theme.palette.text.primaryLight,
    borderBottom: `1px solid ${theme.palette.border.secondaryLight}`,
    paddingBottom: "16px"
  },
  completed:{
    background: theme.palette.status.primaryLight,
  },
  ongoing:{
    background: theme.palette.status.secondaryLight,
  },
  cancelled: {
    background: theme.palette.status.primaryDark,
  },
  container: {
    borderRadius: "8px",
    padding: "24px"
  },
  mobileContainer: {
    display: "none"
  },
  iconContainer: {
    display: "none"
  },
  webContainer: {
    display: "grid",

  },
  heading: {
    fontSize: "20px"
  },
  status: {
    fontSize: "16px",
    padding: "4px 12px"
  },
  "@media (max-width: 476px)": {
    container: {
      borderRadius: "16px 16px 0px 0px",
      padding: "16px"
    },
    mobileContainer: {
      display: "grid"
    },
    iconContainer: {
      display: "flex"
    },
    webContainer: {
      display: "none"
    },
    grandTotal: {
      fontSize: "16px"
    },
    heading: {
      fontSize: "12px"
    },
    property: {
      paddingBottom: "8px"
    },
    status: {
      fontSize: "12px",
      padding: "0px 12px"
    },
  },
}));

const PaymentInfoTemplate: React.FC<IPaymentInfoProps> = ({ label, status, showStatus, priceDetails, quantity, uom, totalPrice }) => {

  const classes = useStyles();
  const [totalQuantity, setTotalQuantityTo] = useState<number>(quantity || 0);
  const [grandTotal, setGrandTotalTo] = useState<number>(0);
  const [UOM, setUOMTo] = useState<string>(uom || "MT");
  const [paymentInfoDetails, setPaymentInfoDetailsTo] = useState<IPriceDetails>(() => PRICE_DETAILS({}));
  const [showAll, setShowAll] = useState(false);

  const handleClick = () => {
    setShowAll(!showAll);
  };


  useEffect(() => {
    setUOMTo(uom)
    setTotalQuantityTo(quantity)
    setGrandTotalTo(totalPrice);
    setPaymentInfoDetailsTo(priceDetails);
  }, [priceDetails]);

  let statusBackgroundColor;
  if (status === STATUS.ONGOING || status === STATUS.PENDING) {
    statusBackgroundColor = classes.ongoing;
  } else if (status === STATUS.COMPLETED) {
    statusBackgroundColor = classes.completed;
  } else if (status === STATUS.CANCELLED) {
    statusBackgroundColor = classes.cancelled;
  }

  return (
    <div className={`${classes.container} grid border-2 bg-white gap-y-3`} >
      <div className={`${classes.property} flex justify-between`}>
        <div className="flex gap-x-6">
          <div className={`${classes.heading} text-lg font-semibold`}>{label}</div>
          {showStatus && (<div className={`${classes.status} font-medium text-white ${statusBackgroundColor} rounded-2xl h-fit my-auto`}>{status}</div>)}
        </div>
        <div className={`${classes.iconContainer} gap-x-2 cursor-pointer`} onClick={handleClick} >
          {showAll ? <ArrowDropDown /> : <ArrowDropUp />}
        </div>
      </div>

      {showAll && (
        <div className={`${classes.lessTcs} ${classes.mobileContainer} gap-y-4 pb-3`}>
          {Object.entries(paymentInfoDetails).map(([key, value]) => (
            <PricingInfo
              key={key}
              data={{
                ...value,
                // icon: <InfoOutlinedIcon sx={{ fontSize: 20, marginX: "5px" }} />
                icon: null
              }}
              totalQuantity={totalQuantity}
              uom={UOM}
            />
          ))}
        </div>
      )}

        <div className={`${classes.lessTcs} ${classes.webContainer} gap-y-4 pb-3`}>
          {Object.entries(paymentInfoDetails).map(([key, value]) => (
            <PricingInfo
              key={key}
              data={{
                ...value,
                // icon: <InfoOutlinedIcon sx={{ fontSize: 20, marginX: "5px" }} />
                icon: null
              }}
              totalQuantity={totalQuantity}
              uom={UOM}
            />
          ))}
        </div>

      <div className="pb-4 flex justify-between">
        <div className={`${classes.grandTotal} font-medium`}>
          Grand Total
        </div>
        <div className={`${classes.grandTotal} font-medium`}>
          {toInr(grandTotal)}
        </div>
      </div>
    </div>
  );
};

export default PaymentInfoTemplate;
