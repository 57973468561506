import { POLICY_SECTION } from "../../../../utils/constant";

interface OptOutProps {
  contentStyle?: string;
  boxStyle?: string;
  subHeading?: string;
}
const OptOut: React.FC<OptOutProps> = ({ contentStyle, boxStyle, subHeading }) => {
  return (
    <>
      <div className={boxStyle}>
        <span className={subHeading}>{POLICY_SECTION.OPT_OUT_POLICY}</span>
      </div>
      <div className={contentStyle}>
        <p>7.1 Users agree and consent to receive all communications, including text messaging services or over-the-top
          communication services (e.gWhatsApp, Google ), at the provided mobile number, even if registered under the
          DND/NCPR list. Users express interest and consent to receive communications, including commercial ones, related
          to Avighna's services. Users authorize Avighna to share/disclose information with third-party service providers,
          affiliates, group companies, authorized agents, or third-party service providers.
          <br />
          <br />
          7.2 All Users may opt-out of receiving non-essential, promotional, or marketing-related communication from
          Avighna. These settings are accessible on the Website, or Users can address opt-out requests to the Grievance
          Officer. If Users wish to remove their contact information from Avighna’s call/email lists and newsletters, they can
          contact the Grievance Officer. Avighna reserves the right to limit accessibility to the Platform and related services
          based on User information availability.
        </p>
        <br />
      </div>
    </>
  )
}
export default OptOut;