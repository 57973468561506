import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";

const ConsumerAppliancesTemplate: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <section>
                <div className={classes.banner_main}>
                    <div className={`${classes.banner_wrapper} ${classes.container}`}>
                        <div className={classes.banner_content}>
                            <span>Flat Rolled Steel</span>
                            <h1>Consumer Appliances</h1>
                            <p>Cold-rolled steel is vital in making durable and visually appealing consumer appliances. It is
                                commonly used for outer panels, doors, and structural components, providing a smooth finish and
                                resistance against wear. Its formability allows for intricate designs, making it key in ensuring
                                functionality and longevity.</p>
                            {/* <!-- <div className="btn">
                        <a href="#">Learn More</a>
                    </div> --> */}
                        </div>
                        <div className={classes.banner_preview}>
                            <div >
                                <img src="../assets/images/ConsumerAppliances/bannerImage1.webp" alt="banner-img" />
                            </div>
                            <div className={classes.banner_preview_content_secondary}>
                                <img src="../assets/images/ConsumerAppliances/bannerImage2.webp" alt="banner-img" />
                            </div>
                        </div>
                        <div className={classes.banner_mobile}>
                            <img src="../assets/images/ConsumerAppliances/bannerMobile.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className="products_about_us section-top-spacing">
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>Flat Rolled Steel in Appliances </h2>
                            <p className={classes.gal_card_des} >Flat-rolled steel is widely used in the production of household appliances,
                                playing a crucial role in improving the structural integrity and appearance of various devices.
                                It is the preferred material for outer panels, doors, and internal components of appliances such
                                as refrigerators, dishwashers, ovens, and washing machines. The material's versatility allows
                                precise shaping, ensuring a seamless fit and a sleek appliance look. Its durability and
                                corrosion resistance contribute to the longevity of household appliances, making flat-rolled
                                steel an essential element in manufacturing reliable and visually pleasing products for the
                                home.
                            </p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/ConsumerAppliances/about.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- slider section starts here  --> */}
            {/* 
    <div className="parent p_secondary">
        <h3 className="slider_heading">Our Product Range For Appliances Industry</h3>
        <div className="slick">
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsPrePainted.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsElectrolyticTinplate.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsTinFreeSteel.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsPrePainted.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsColdRolled.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsGalvanized.webp" alt="" />
            </div>
        </div>
    </div> */}

            {/* <!-- tabs section starts here  --> */}

            {/* <section className="section_strip">
        <div className="tabs_wrapper container">
            <h3>Applications</h3>
            <div className="tab">
                <button className="tablinks" onclick="openCity(event, 'Packaging')">Refrigerators</button>
                <button className="tablinks" onclick="openCity(event, 'Coated Sheets')">Dishwashers</button>
                <button className="tablinks" onclick="openCity(event, 'Railway Coach')">Ovens</button>
                <button className="tablinks" onclick="openCity(event, 'Electrical Panels')">Washing Machines</button>
                <button className="tablinks" onclick="openCity(event, 'Automobiles')">Air Conditioners</button>
            </div>

            <div id="Packaging" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/ConsumerAppliances/fridge.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Refrigerators</h2>
                        <p className={classes.gal_card_des} >Cold-rolled steel is essential for constructing durable refrigerator
                            components that resist corrosion and maintain precise dimensions, ensuring efficient and
                            long-lasting food preservation.</p>
                    </div>
                </div>
            </div>

            <div id="Coated Sheets" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/ConsumerAppliances/dishwashers.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Dishwashers</h2>
                        <p className={classes.gal_card_des} >In the manufacturing of dishwashers, cold-rolled steel is used for
                            structural components and interior features. Its formability and resistance to heat and
                            moisture make it a suitable material for constructing durable dishwasher interiors and
                            exteriors.</p>
                    </div>
                </div>
            </div>

            <div id="Railway Coach" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/ConsumerAppliances/ovens.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Ovens</h2>
                        <p className={classes.gal_card_des} >Cold-rolled steel is ideal for oven manufacturing due to its heat
                            resistance and dimensional stability. Using it for oven body panels and structural
                            components ensures reliable performance and high-temperature endurance.</p>
                    </div>
                </div>
            </div>

            <div id="Electrical Panels" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/ConsumerAppliances/washingMachines.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Washing Machines</h2>
                        <p className={classes.gal_card_des} >Cold-rolled steel is commonly used in washing machine construction for
                            its strength and corrosion resistance, contributing to durability and efficiency.</p>
                    </div>
                </div>
            </div>

            <div id="Automobiles" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/ConsumerAppliances/airConditioners.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Air Conditioners</h2>
                        <p className={classes.gal_card_des} >Cold-rolled steel is vital in making air conditioners. It is solid and
                            flexible, contributing to AC units' robustness and optimal performance.</p>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

            {/* <!-- cards for mobile view  --> */}

            <section className={`${classes.section_strip}`}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Applications </h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ConsumerAppliances/fridge.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Refrigerators</span>
                                    <p>Cold-rolled steel is essential for constructing durable refrigerator components that
                                        resist corrosion and maintain precise dimensions, ensuring efficient and long-lasting
                                        food preservation.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ConsumerAppliances/dishwashers.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Dishwashers</span>
                                    <p>In the manufacturing of dishwashers, cold-rolled steel is used for structural components
                                        and interior features. Its formability and resistance to heat and moisture make it a
                                        suitable material for constructing durable dishwasher interiors and exteriors.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ConsumerAppliances/ovens.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Ovens</span>
                                    <p>Cold-rolled steel is ideal for oven manufacturing due to its heat resistance and
                                        dimensional stability. Using it for oven body panels and structural components ensures
                                        reliable performance and high-temperature endurance.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ConsumerAppliances/washingMachines.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Washing Machines</span>
                                    <p>Cold-rolled steel is commonly used in washing machine construction for its strength and
                                        corrosion resistance, contributing to durability and efficiency.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ConsumerAppliances/airConditioners.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Air Conditioners</span>
                                    <p>Cold-rolled steel is vital in making air conditioners. It is solid and flexible,
                                        contributing to AC units' robustness and optimal performance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default ConsumerAppliancesTemplate;
