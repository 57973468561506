import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { CLASS_STATES, HTTP_STATUS, MY_MATERIAL } from '../../../utils/types';
import CategorySelectionTemplate from '../../templates/DashBoard/MyMaterials/CategorySelection.template';
import ProductRequirementTemplate from '../../templates/DashBoard/MyMaterials/ProductRequirement.template';
import { createUseStyles } from 'react-jss';
import * as yup from "yup";
import { useFormik } from 'formik';
import AddMaterialProductTemplate from '../../templates/DashBoard/MyMaterials/AddMaterialProduct.template';
import { AutoCompleteOption } from '../../atoms/AutoCompleteInput/AutoCompleteInputV2';
import { useMaterialRequirementService } from '../../../services/useMaterialRequirementService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { CUSTOMER_ROUTES } from '../../../utils/constant';


const useStyles = createUseStyles((theme: any) => ({
    section: {
        color: theme.palette.v2.text.primaryDark,
    }
}));

export interface IMaterialAttribute {
    id: number,
    uom: string,
    name: string,
    attributeOptions: string | null | string[],
    prefix: string,
    isMpc: boolean,
    isUpc: boolean
}

export interface IMaterialRequirement {
    id: number | null;
    productCode: string;
    categoryId: number | null;
    category?: string;
    classType: string;
    standardId: number | null;
    standardName?: string;
    gradeId: number | null;
    gradeName?: string;
    shape: string;
    attributes: IMaterialAttribute[];
    applicationType: string;
    otherSpecificRequirement: string;
    primarySourcingPurpose: string;
    frequency: string;
    quantity: number | null;
    preferredDeliveryDate?: string;
    preferredDeliveryLocation?: string;
    customization?: string[];
    tradeType?: string;
    standard: AutoCompleteOption | null,
    grade: AutoCompleteOption | null,
    isDuplicate?: boolean;
}

export interface IMaterialRequirementFormik {
    materialRequirement: IMaterialRequirement[] | [],
    noOfRows?: number | string,
    categoryName?: string,
    categoryId?: number | string,
    deletedRowIndex?: number[]
}

const initialValues = {
    materialRequirement: [],
    noOfRows: "",
    categoryName: "",
    categoryId: "",
    deletedRowIndex: []
}

const validationSchema = yup.object().shape({
    materialRequirement: yup.array().of(
        yup.object().shape({
            standardId: yup.number().required('Standard is required'),
            gradeId: yup.number().required('Grade is required'),
            shape: yup.string().required('Shape is required'),
            applicationType: yup.string().required('Application type is required'),
            primarySourcingPurpose: yup.string().required('Primary sourcing purpose is required'),
            otherSpecificRequirement: yup.string().required('Other specific requirement is required'),
            frequency: yup.string().required('Frequency is required'),
            quantity: yup.number().typeError('Quantity must be a number').required('Quantity is required').moreThan(0, 'Quantity must be greater than zero').positive('Quantity must be a positive number'),
            attributes: yup.array().of(yup.object().shape({
                attributeOptions: yup.string().required('Attribute option is required'),
            })).required('Attributes are required').test(
                'all-attribute-options-filled',
                'All attribute options must be filled',
                (attributes) => {
                    return attributes.every(attr => attr.attributeOptions !== null);
                }
            ),
        })
    ),
    // noOfRows: yup.number().min(0, "Quantity should be greater than 0").max(10, 'Quantity cannot exceed 10').required("Quantity is required"),
    noOfRows: yup.number()
        .min(1, "Quantity should be greater than 0")
        .max(10, `Add maximum upto 10 products`)
        .required("Quantity is required")
        .test(
            'is-integer',
            'Enter Valid Quantity',
            value => Number.isInteger(value)
        ),
})

const MyMaterialAddProductPage = () => {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const materialRequirementService = useMaterialRequirementService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [currentSection, setCurrentState] = useState<MY_MATERIAL>(() => {
        return new URLSearchParams(location.search).get('category')?.length ?
            MY_MATERIAL.PRODUCT_REQUIREMENT : MY_MATERIAL.CATEGORY_SELECTION;
    });

    const handleOnBack = () => {
        if (currentSection === MY_MATERIAL.CATEGORY_SELECTION)
            navigate(CUSTOMER_ROUTES.MY_MATERIAL_LISTING);
        setCurrentState((prev) => {
            switch (prev) {
                case MY_MATERIAL.PRODUCT_REQUIREMENT: return MY_MATERIAL.CATEGORY_SELECTION;
                case MY_MATERIAL.MY_MATERIAL_FORM: default: return MY_MATERIAL.PRODUCT_REQUIREMENT;
            }
        })
        formik.setFieldValue('deletedRowIndex', []);
    }

    const handleOnNext = () => {
        setCurrentState((prev) => {
            switch (prev) {
                case MY_MATERIAL.CATEGORY_SELECTION: default: return MY_MATERIAL.PRODUCT_REQUIREMENT;
                case MY_MATERIAL.PRODUCT_REQUIREMENT: return MY_MATERIAL.MY_MATERIAL_FORM;
            }
        })
    }

    const checkClassType = (attributes: IMaterialAttribute[]) => {
        for (let attribute of attributes) {
            if (attribute.isMpc) {
                return false;
            }
        }
        return true;
    }

    const formik = useFormik<IMaterialRequirementFormik>({
        initialValues,
        validationSchema,
        validateOnMount: true,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (value) => {
            const payload: IMaterialRequirement[] = value.materialRequirement.map((item: IMaterialRequirement) => {
                const materialRequirement = { ...item };
                materialRequirement.classType = checkClassType(item.attributes) ? CLASS_STATES.STANDARD : CLASS_STATES.NONSTANDARD;
                materialRequirement.categoryId = value.categoryId as number;
                return materialRequirement;
            });
            materialRequirementService.createMaterialRequirements(payload)
                .then((res: any) => {
                    if (res?.status === HTTP_STATUS.OK) {
                        const response = res.data.data
                        const materialRequirements = [];
                        for (let materialRequirement of formik.values.materialRequirement) {
                            for (let res of response) {
                                if (res.index === materialRequirement.id && !res?.status) {
                                    materialRequirements.push(materialRequirement)
                                }
                            }
                        }
                        materialRequirements.forEach(materialRequirement => {
                            materialRequirement.isDuplicate = true;
                        })
                        formik.setFieldValue('materialRequirement', materialRequirements);
                        if (!response.filter((el: any) => el.status === false)?.length) {
                            showSnackbar('success', "Created Material Requirements")
                            navigate(CUSTOMER_ROUTES.MY_MATERIAL_LISTING)
                        } else
                            if (response.includes(true))
                                showSnackbar('success', "Created Material Requirements")
                            else
                                showSnackbar('error', "Failed to create material requirements")
                    } else {
                        showSnackbar('error', "Failed to create material requirements");
                    }
                }).catch((error: any) => {
                    showSnackbar('error', "Failed to create material requirements")
                    console.error('ERROR CREATING MATERIAL PRODUCT: ', error)
                })
        }
    })

    useEffect(() => {
        if (formik.values.categoryName) return;
        const category = new URLSearchParams(location.search).get('category')?.split(":")
        formik.setFieldValue('categoryName', category?.[0].split("-").join(" "));
        formik.setFieldValue('categoryId', category?.[1]);
    }, [currentSection])

    const sectionStateView = useMemo(() => {
        const props = { handleOnBack, handleOnNext, formik }
        switch (currentSection) {
            case MY_MATERIAL.CATEGORY_SELECTION: return <CategorySelectionTemplate {...props} />;
            case MY_MATERIAL.PRODUCT_REQUIREMENT: return <ProductRequirementTemplate {...props} />;
            default: return <AddMaterialProductTemplate {...props} />;
        }
    }, [currentSection, formik])

    return (
        <div className={"grid gap-y-6"}>
            {SnackBarComponent}
            <div className={`${classes.section} text-lg font-semibold`}>My Materials</div>
            <div>{sectionStateView}</div>
        </div>
    )

}

export default MyMaterialAddProductPage