import React from "react";
import { useStyles } from "./Style";

const NewsLetterTemplate: React.FC = () => {
    const classes = useStyles()
    return (
        <section className={classes.news_letter_main}>
            <div className={classes.contact_us_wrapper}>
                <div className={`${classes.contact_us} ${classes.container}`}>
                    <img src="../assets/images/Call.webp" alt="" />
                    <span>contact us</span>
                    <h3 className={classes.section_heading_secondary}>Reach Us for your exact customised requirements.</h3>
                </div>
                <div>
                    <div className={`${classes.message_wrapper} ${classes.container}`}>
                        <h3>Send us a Message!</h3>
                        <div className={classes.inputs_wrapper}>
                            <div className={classes.message_content}>
                                <input type="text" name="fullname" placeholder="Full Name*" className={classes.newsleter_input} />
                                <input type="email" name="Email" placeholder="Email*" className={classes.newsleter_input} />
                                <input type="number" name="phonenumber" placeholder="Phone*" className={classes.newsleter_input} />
                            </div>
                            <div className={classes.message_content}>
                                <input type="text" name="fullname" placeholder="Product Category*" className={classes.newsleter_input} />
                                <input type="email" name="Email" placeholder="Product Description*" className={`${classes.newsleter_input}`} />
                                <input type="number" name="phonenumber" placeholder="Quantity (MT)*" className={classes.newsleter_input} />
                            </div>
                        </div>
                        <div className={`${classes.btn} ${classes.btn_main} ${classes.news_btn}`}>
                            <a href="#">Submit</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.subscribe}>
                <div className={`${classes.subscribe_wrapper} ${classes.container}`}>
                    <div className={classes.subscribe_main}>
                        <h3 className={classes.section_heading}>Subscribe to our Newsletter</h3>
                        <p>And because your company is unique, you will need an extensible identity solution.</p>
                        <div className={classes.subscribe_content}>
                            <input type="email" name="email" placeholder="Your work mail" className={classes.subscribe_input} />
                            <div className={`${classes.btn} ${classes.btn_primary}`}>
                                <a href="#">Submit</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default NewsLetterTemplate;

