import { createUseStyles } from "react-jss";
import { CONNECT_WITH_US, CONNECT_WITH_US_POPUP } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    contactUsWrapper: {
        display: "flex",
        width: "321px",
        flexDirection: "column",
        border: `1px solid ${theme.palette.v4.border.primaryLightAccent}90`,
        background: theme.palette.v4.background.secondaryLightAccent,
        boxShadow: `2px 2px 12px 0px ${theme.palette.v4.border.secondaryLight}60`,
        position: "fixed",
        zIndex: 14,
        marginTop: "264px",
        cursor: "default",
    },
    detailsWrapper: {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.v4.text.secondaryDarkAccent,
    },
    subText: {
        color: theme.palette.v3.text.primaryLight,
    },
    supportWrapper: {
        display: "flex",
        height: "48px",
        padding: "12px 24px",
        background: theme.palette.v4.background.primaryDarkAccent,
        color: theme.palette.v4.text.primaryLight,
        cursor: "pointer",
    },
}))

const ContactUsPopup: React.FC = () => {

    const classes = useStyles();
    
    return (
        <div className={`${classes.contactUsWrapper} p-4 gap-4 rounded-2xl items-start`}>
            {CONNECT_WITH_US_POPUP.map(({ icon, text, country, mobile, email, description }) => (
                <div className="flex gap-x-6">
                    <img src={icon} alt="icon" className="w-14 h-14" />
                    <div className={`${classes.detailsWrapper} items-start my-auto`}>
                        <span className="text-sm font-medium">{text}</span>
                        <span className={`text-sm font-medium ${classes.subText}`}>{`${mobile ? country + " " + mobile : email}`}</span>
                        <p className={`text-xs font-normal ${classes.subText}`}>{description}</p>
                    </div>
                </div>
            ))}
            {/* <div className={`${classes.supportWrapper} w-full gap-2 rounded-lg justify-center items-center`}>
                <span className="text-lg font-medium">{CONNECT_WITH_US.HELP_SUPPORT}</span>
            </div> */}
        </div>
    )
}

export default ContactUsPopup;