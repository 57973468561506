import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { AUTH_STATES, HTTP_STATUS } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import CircularProgress from "@mui/material/CircularProgress";

import {
    TextField,
    Checkbox,
    FormHelperText,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { REGEX } from '../../../utils/constant';
import { useUserService } from '../../../services/useUserService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useLocation } from 'react-router-dom';
import { AuthenticatedUserType } from '../../../contexts/AuthenticatedUserContext';

interface PasswordTemplateprops {
    setAuthState: (state: AUTH_STATES) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    sectionText: {
        color: theme.palette.text.primaryDarkAccent
    },
    successGreenColor: {
        color: theme.palette.success.secondaryDark
    },
    content: {
        color: theme.palette.button.primaryLight
    }
}));

export interface VerifyTokenParams{
    token: string | null
}


const PasswordTemplate: React.FC<PasswordTemplateprops> = ({ setAuthState }) => {
    const classes = useStyles();
    const userService = useUserService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user, syncAuthDialogActive, setAuthenticatedUser} = useAuthenticatedUser();
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>();
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>();
    const [passwordChecks, setPasswordChecks] = useState({
        uppercase: false,
        minLength: false,
        lowercase: false,
        symbol: false,
        number: false,
    });

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [token, setToken] = useState<string|null>(null);
    const location = useLocation();
    const verifyToken = async () => {
        if(new URLSearchParams(location.search).get('token')){
            const params:VerifyTokenParams = {
                token: new URLSearchParams(location.search).get('token')
            } 
            await userService.verifyToken(params)
            .then(res => {
                if(res?.status === HTTP_STATUS.OK){
                    setToken(res.data.data)
                }
            }).catch(error => {
                    console.error(error)
            }).finally(()=>setIsLoading(false))
        }
    }

    useEffect(() => {
        verifyToken()
    },[])

    const handlePasswordChange = (value: string) => {
        setConfirmPassword("")
        setPassword(value);
        setPasswordError("");
        setPasswordChecks({
            uppercase: REGEX.UPPERCASE.test(value),
            minLength: value.length >= 8,
            lowercase: REGEX.LOWERCASE.test(value),
            symbol: REGEX.SYMBOL.test(value),
            number: REGEX.NUMBER.test(value),
        });
    };

    const handleConfirmPasswordChange = (value: string) => {
        setConfirmPassword(value);
        if (value !== password)
            setConfirmPasswordError(true);
        else setConfirmPasswordError(false);
    };

    const handleDialogClose = () => {
        syncAuthDialogActive(false);
        setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE)
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if(!!passwordError || confirmPasswordError) return;

        if(!Object.values(passwordChecks).every(check => check) ){
            setPasswordError("Enter valid password");
            return;
        }
        if(password !== confirmPassword) {
            setConfirmPasswordError(true);
            return;
        };

        setIsLoading(true)
        userService.setPassword({token},{newPassword: password})
        .then(res => {
            if(res?.status === HTTP_STATUS.OK){
                const editedUser = { ...user };
                editedUser.password = true;
                setAuthenticatedUser(editedUser as AuthenticatedUserType)
                setAuthState(AUTH_STATES.EMAIL_CONFIRMAITON);
                window.history.replaceState(null, '', `${window.location.pathname}`);
                if(user) handleDialogClose();
                else setAuthState(AUTH_STATES.LOGIN_WITH_EMAIL);
            }else if(res?.status === HTTP_STATUS.BAD_REQUEST && res?.data?.statusMessage === "DUPLICATE_ENTRY"){
                setPasswordError("Please choose a new password that's different from the previous one")
            }else {
                showSnackbar('error', "Something went wrong")
                setToken(null);
            }
        }).catch(error => {
            showSnackbar('error', "Something went wrong")
            console.error("Update Password", error)
        }).finally(()=>setIsLoading(false))
    };
   
    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }
    
    return (
        <div className="auth-right-child-container flex flex-col w-full" >
            {SnackBarComponent}
            <div className="form-container flex flex-col grow w-full">
                {(!isLoading && token) ? <form className="flex flex-col gap-y-3 items-center mb-3 w-full text-lg" onSubmit={handleSubmit}>
                    <div className='w-full'>
                        <div>
                        <TextField
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => handlePasswordChange(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(show => !show)}
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                            error={!!passwordError}
                            />
                        {!!passwordError &&
                        <div className='flex w-full'>
                            <FormHelperText error className=''>{passwordError}</FormHelperText>
                        </div>
                    }
                    </div>
                        <div className='grid w-full grid-cols-2 py-4 px-5 bg-gray-200 rounded select-none ponter-none text-sm'>
                            <div>
                                <Checkbox size='small' checked={passwordChecks.uppercase} icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon className={`${classes.successGreenColor}`} />} disabled />
                                <span className={`${passwordChecks.uppercase && classes.successGreenColor}`}>Includes a Uppercase Letter</span>
                            </div>
                            <div className={`${passwordChecks.minLength && classes.successGreenColor}`}>
                                <Checkbox size='small' checked={passwordChecks.minLength} icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon className={`${classes.successGreenColor}`} />} disabled />
                                <span className={`${passwordChecks.uppercase && classes.successGreenColor}`}>Minimum 8 Characters</span>
                            </div>
                            <div className={`${passwordChecks.lowercase && classes.successGreenColor}`}>
                                <Checkbox size='small' checked={passwordChecks.lowercase} icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon className={`${classes.successGreenColor}`} />} disabled />
                                <span className={`${passwordChecks.uppercase && classes.successGreenColor}`}>Includes a Lowercase Letter</span>
                            </div>
                            <div className={`${passwordChecks.symbol && classes.successGreenColor}`}>
                                <Checkbox size='small' checked={passwordChecks.symbol} icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon className={`${classes.successGreenColor}`} />} disabled />
                                <span className={`${passwordChecks.uppercase && classes.successGreenColor}`}>Includes a Symbol</span>
                            </div>
                            <div className={`${passwordChecks.number && classes.successGreenColor}`}>
                                <Checkbox size='small' checked={passwordChecks.number} icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon className={`${classes.successGreenColor}`} />} disabled />
                                <span className={`${passwordChecks.uppercase && classes.successGreenColor}`}>Includes a Number</span>
                            </div>
                        </div>
                    </div>
                    <TextField
                        label="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        className='!m-0'
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowConfirmPassword(show => !show)}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                        error={confirmPasswordError}
                    />
                    {confirmPasswordError &&
                        <div className='flex w-full'>
                            <FormHelperText error className=''>Password is not matching</FormHelperText>
                        </div>
                    }
                    <Button variant="containedLight" label="Submit" type="submit" className="!h-12 w-full" isLoading={isLoading}
                     />
                </form>
                    : isLoading
                        ? (<div className='flex justify-center'><CircularProgress /> </div>)
                        : (<div className='text-center flex flex-col gap-1'>
                            <h4 className='text-xl text-red-600'>Oops! Seems like your link is not working!</h4>
                            <div className='flex gap-2 justify-center items-center border-t'>
                                <span onClick={setAuthStateTo(AUTH_STATES.FORGOT_PASSWORD)} className={`cursor-pointer font-normal text-lg text-center my-4 ${classes.sectionText}`}>
                                    Request new link</span>
                            </div>
                        </div>)}
            </div>
        </div>
    )
}

export default PasswordTemplate