import { createUseStyles } from "react-jss";
import ContentPreviewer, { IContentComponent } from "../../organisms/ContentPreviewer/ContentPreviewer";
import { POLICY_SECTION } from "../../../utils/constant";
import PrivacyIntroduction from "../../templates/Policies/PrivacyPolicy/PrivacyIntroduction.template";
import YourInformation from "../../templates/Policies/PrivacyPolicy/YourInformation.template";
import UseOfInformation from "../../templates/Policies/PrivacyPolicy/UseOfInformation.template";
import DisclosureOfInformation from "../../templates/Policies/PrivacyPolicy/DisclosureOfInformation.template";
import SecurityOfYouInformation from "../../templates/Policies/PrivacyPolicy/SecurityOfYourInformation.template";
import ExternalLinks from "../../templates/Policies/PrivacyPolicy/ExternalLinksOnThePlatform.template";
import ChangesToYourInformation from "../../templates/Policies/PrivacyPolicy/ChangesToYourInformation.template";
import React from 'react';
import OptOut from "../../templates/Policies/PrivacyPolicy/OptOut.template";
import GrievanceOfficerAndContactInformation from "../../templates/Policies/PrivacyPolicy/GrievanceOfficerAndContactInformation.template";
import ApplicableLawAndDisputeResolution from "../../templates/Policies/PrivacyPolicy/ApplicableLawAndDisputeResolution.template";

const useStyles = createUseStyles((theme: any) => ({
    contentHeader: {
        color: theme.palette.text._secondaryDark,
        fontSize: '32px',
        fontWeight: 600,
        marginBottom: '24px',
    },
    contentStyle: {
        color: theme.palette.v2.text._secondaryLight,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'justify',
    },
    boxStyle: {
        padding: '14px 10px',
        borderRadius: '8px',
        background: theme.palette.v2.background.secondaryLightAccent,
        marginBottom: '24px',
    },
    subHeading: {
        color: theme.palette.text.primaryDark,
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '120%',
    },
}));


const PrivacyPolicy: React.FC = () => {
    const classes = useStyles();
    const PrivacyPolicySchem: IContentComponent[] = [
        {
            Key: POLICY_SECTION.PRIVACY_POLICY_INTRODUCTION,
            component: <PrivacyIntroduction {...classes} />
        },
        {
            Key: POLICY_SECTION.YOUR_INFORMATION,
            component: <YourInformation {...classes} />
        },
        {
            Key: POLICY_SECTION.USE_OF_INFORMATION,
            component: <UseOfInformation {...classes} />
        },
        {
            Key: POLICY_SECTION.DISCLOSURE_OF_INFORMATION,
            component: <DisclosureOfInformation {...classes} />
        },
        {
            Key: POLICY_SECTION.CHANGES_TO_YOUR_INFORMATION,
            component: <ChangesToYourInformation {...classes} />
        },
        {
            Key: POLICY_SECTION.SECURITY_OF_YOUR_INFORMATION,
            component: <SecurityOfYouInformation {...classes} />
        },
        {
            Key: POLICY_SECTION.EXTERNAL_LINKS_ON_THE_PLATFORM,
            component: <ExternalLinks {...classes} />
        },
        {
            Key: POLICY_SECTION.OPT_OUT_POLICY,
            component: <OptOut {...classes} />
        },
        {
            Key: POLICY_SECTION.GRIEVANCE_OFFICER_AND_CONTACT_INFORMATION,
            component: <GrievanceOfficerAndContactInformation {...classes} />
        },
        {
            Key: POLICY_SECTION.APPLICABLE_LAW_AND_DISPUTE_RESOLUTION,
            component: <ApplicableLawAndDisputeResolution {...classes} />
        }
    ]

    return <ContentPreviewer policySchema={PrivacyPolicySchem} />;
};

export default PrivacyPolicy;
