import React, { useEffect, useState } from 'react'
import Button from '../../../atoms/Button/Button';
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { CITY_OPTIONS } from '../../../../utils/constant';
import MultiSelectInput2, { IMultiSelectOption } from '../../../atoms/MultiSelectInput/MultiSelectInput2';
import { IBrand, IMaterialBrandManufacturer } from './MaterialBrandManufacturer.template';
import { ICategory, IManufacturer } from '../../Home/ProductRequirementForm.template';
import { IPreferredProductCategory } from './ProductCategoy.template';
import { useAdminMasterService } from '../../../../services/useAdminMasterService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../../utils/types';
import radioActive from '../../../../assets/images/radioActive.svg'
import radioInActive from '../../../../assets/images/radioInactive.svg'

interface ProductCategoryFormTemplateProps {
    handleSelectChange: (key: string) => (option: IMultiSelectOption[]) => void;
    formik: FormikProps<IPreferredProductCategory>;
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.textV2.primary.primary500,
    },
    borderColor: {
        border: `1px solid ${theme.palette.v3.background.primaryLight}`
    },
    heading: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    tertiary100BorderColor: {
        borderColor: theme.palette.textV2.tertiary.tertiary100
    },
    sectionBackground: {
        background: theme.palette.backgroundV2.tertiary.tertiary500
    },
    divider: {
        height: '1px',
        background: theme.palette.textV2.tertiary.tertiary100
    },
    tertiary100Border: {
        border: `1px solid ${theme.palette.textV2.tertiary.tertiary100}`
    },
    imageBackground: {
        background: theme.palette.backgroundV2.primary.primary50
    },
    title: {
        color: theme.palette.textV2.tertiary.tertiary700
    }
}));

const ProductCategoryFormTemplate: React.FC<ProductCategoryFormTemplateProps> = ({ formik, handleSelectChange }) => {
    const classes = useStyles();
    const [categories, setCategories] = useState<ICategory[]>([]);
    const adminMasterService = useAdminMasterService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleCategorySelect = (category: { id: number }) => () => {
        let categoryData = [...formik.values.productCategories];
        categoryData = categoryData.includes(category.id) ?
            categoryData.filter(el => el !== category.id)
            : [...categoryData, category.id];
        formik.setFieldValue('productCategories', categoryData)
    }

    const loadProductCategories = () => {
        adminMasterService.getAllCategories({ level: 2, page: 0, size: 10 })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setCategories(res?.data?.data?.content)
            }).catch((error) => {
                showSnackbar('error', "Error Fetching Category");
            })
    }

    useEffect(() => {
        loadProductCategories();
    }, [])

    function sortCategoriesBySpaces(categories: ICategory[]) {
        return categories.sort((a: ICategory, b: ICategory) => {
            const countSpaces = (str: string) => (str.match(/ /g) || []).length;
            return countSpaces(b.name) - countSpaces(a.name);
        });
    }

    return (
        <form className={` p-6 grid gap-6 rounded-lg ${classes.sectionBackground}`} onSubmit={formik.handleSubmit}>
            <div className={`grid gap-6`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-2xl`}>Preferred Product Category</span>
                    <Button variant='tertiaryContained' type="submit" label="Save" className='!font-normal' disabled={!formik.values?.productCategories?.length} />
                </div>
                <div className={`${classes.tertiary100BorderColor} border-b`}></div>
                <div className=' flex flex-wrap gap-4'>
                    {sortCategoriesBySpaces(categories).map(category => (
                        <div onClick={handleCategorySelect(category)} className={`flex gap-2 items-center p-4 rounded-lg break-keep cursor-pointer ${classes.tertiary100Border}`}>
                            <span><img src={((formik.values.productCategories).includes(category?.id)) ? radioActive : radioInActive} alt="" /></span>
                            <span className={`!w-[47px] !h-[47px] rounded-full p-2 border-box overflow-hidden ${classes.imageBackground}`}><img src={category.images?.[0]?.path} alt="" className='w-full h-auto rounded-full' /></span>
                            <span className={`${classes.title}`}>{category.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </form>
    )
}

export default ProductCategoryFormTemplate