import { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useCatalogueService } from "../../../services/useCatalogueService";
import { ICartRequestBody, useCartService } from "../../../services/useCartService";
import { useLocation } from "react-router-dom";
import { CartContext } from "../../../contexts/CartContext";
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS } from "../../../utils/types";
import CatalogueImageTemplate, { IImage } from "../../templates/Catalogue/CatalogueImage.template";
import SecondaryProductAttributesTemplate from "../../templates/Catalogue/SecondaryProductAttributes.template";
import Breadcrumbs from "../../atoms/BreadCrumbs/BreadCrumbs";
import { Grid } from "@mui/material";
import Select from "../../atoms/Select/Select";
import { ICurrentCartItem } from "./CatalogueDetails.page";
import { convertObjectToOptions, makeRoute, snakeCaseToTitleCase } from "../../../utils/helper";
import TextField from "../../atoms/Input/TextField";
import TermsAndConditionsTemplate from "../../templates/Catalogue/TermsAndConditions.template";
import ProductDetailsTemplate from "../../templates/Catalogue/ProductDetails.template";
import SecondaryProducProperties from "../../templates/Catalogue/SecondaryProducProperties.template";
import CatalogueDetailsTemplate from "../../templates/Catalogue/CatalogueDetails.template";
import classType from "../../../assets/icons/classType.svg";
import shape from "../../../assets/icons/shape.svg";
import productCategory from "../../../assets/icons/productCategory.svg";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import Carousel from "react-multi-carousel";
import CarouselRef from "react-multi-carousel";
import SecondaryPriceDetailsTemplate from "../../templates/Catalogue/SecondaryPriceDetails.template";
import SecondaryMobilePricDetailsTemplate from "../../templates/Catalogue/SecondaryMobilePricDetails.template";
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES, DEALS_OF_THE_DAY_DATA, PAYMENT_METHODS, REGEX } from "../../../utils/constant";
import TermsAndConditionsCarousel from "../../templates/Catalogue/TermsAndConditionsCarousel.template";
import Disclaimer from '../../templates/Catalogue/Disclaimer.template';
import FAQTemplate from '../../templates/Catalogue/FAQ.template';
import ContactUs from "../ContactUs/ContactUs.page";
import AddToFavouriteTemplate from "../../templates/Catalogue/AddToFavourite.template";
import DealsOfTheDayTemplate from "../../templates/Catalogue/DealsOfTheDay.template";
import SelectCustomizationTemplate, { IValueAddedService } from "../../templates/Catalogue/Customization/SelectCustomization.template";
import TermsConditionsTemplate from "../../templates/Catalogue/TermsConditions.template";
import DeliveryDetailsTemplate from "../../templates/Catalogue/DeliveryDetails.template";
import PaymentDetailsTemplate from "../../templates/Catalogue/PaymentDetails.template";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";

export interface IProductSpecification {
    name: string;
    maxValue: string;
    minValue: string;
    uom: string;
}

export interface IProductClassification {
    name: string;
    value: string;
}

export interface ISecondaryAttributes {
    productSpecification: IProductSpecification[];
    productClassification: IProductClassification[];
}

export interface ISecondaryCatalogue {
    id: number;
    secondarySkuId: number;
    catalogueName: string;
    minimumQuantity: number;
    availableQuantity: number;
    application: string;
    defectDetails: string;
    additionalProductDetails: string;
    keywords: string;
    price: any;
    packagingType: String[];
    termsConditions: string;
    status: string;
    attributes: any;
    warehouseName: string;
    grade: string;
    catalogueImages: IImage[];
    name: string;
    warehouseId: number;
    uom: string;
    netWeight: number;
    grossWeight: number;
    isRepresentationImage: boolean;
    customization: String[];
    skuCode: string;
}

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.secondaryDark
    },
    leftContainer: {
        width: "66.54%",
    },
    middleChild: {
        width: "60%"
    },
    rightChild: {
        width: "31.85%",
    },
    catalogueName: {
        color: theme.palette.textV2.secondary.secondary900,
    },
    value: {
        color: theme.palette.v4.text.secondaryLight,
        fontSize: "16px",
    },
    quantityTerms: {
        color: theme.palette.action.danger
    },
    quantityInput: {
        borderRadius: "0px",
        border: `1px solid ${theme.palette.border._primaryDark}`,
        background: "white",
        borderRight: "none"
    },
    description: {
        color: theme.palette.text._primaryDarkAccent
    },
    productDetails: {
        color: theme.palette.text._secondaryDark
    },
    termsAndConditions: {
        background: theme.palette.background._primaryLight
    },
    priceDetails: {
        position: "sticky",
        bottom: "50.5%"
    },
    select: {
        "& .MuiInputBase-input": {
            color: theme.palette.v4.text.secondaryLightAccent,
            fontSize: "14px",
            background: "none",
        },
        "& .MuiSvgIcon-root": {
            width: "16px",
            height: "16px",
            color: `${theme.palette.v4.text.secondaryLight}60`,
        },
        background: theme.palette.v3.background._primaryLight,
    },
    selectContainer: {
        marginTop: "-31px"
    },
    uom: {
        background: theme.palette.background._primaryLghtAccent,
        border: `1px solid ${theme.palette.border._primaryDark}`,
        borderLeft: "none"
    },
    webContainer: {
        display: "grid",
    },
    mobileContainer: {
        display: "none",
    },
    termsAndConditionsWidth: {
        width: "90%"
    },
    inputContainer: {
        display: "flex",
        columnGap: "24px"
    },
    catalogueDetails: {
        rowGap: "24px"
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-dot-list": {
            top: "225px"
        },
        maxWidth: "287px",
    },
    warehouse: {
        "& .MuiInputBase-input": {
            color: theme.palette.text.primaryLight,
        },
        border: `1px solid ${theme.palette.border.secondaryLight}`,
        background: "white"
    },
    productRemarksWrapper: {
        borderRadius: "16px",
        border: `1px solid ${theme.palette.border._primaryLight}`,
    },
    productDefects: {
        color: theme.palette.text.primaryLightAccent,
        fontSize: "18px"
    },
    productDefectsDescription: {
        color: theme.palette.text._primaryDarkAccent,
        fontSize: "16px"
    },
    activeCategory: {
        color: theme.palette.v3.text._secondaryLight,
    },
    inputField: {
        marginTop: "-10px"
    },
    location: {
        border: `1px solid ${theme.palette.v4.border.primaryLightAccent}`,
        background: "none !important",
    },
    inStock: {
        height: "40px",
        borderRadius: "24px",
        background: theme.palette.v4.background.primaryDark,
        padding: "8px 16px",
        justifyContent: "center",
        alignItems: "center",
    },
    inStockValue: {
        color: theme.palette.v4.text.primaryDarkAccent,
    },
    "@media (max-width: 476px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        catalogueName: {
            color: theme.palette.textV2.primary.primary900,
        },
        termsAndConditionsWidth: {
            width: "auto"
        },
        inputContainer: {
            display: "grid",
            rowGap: "16px"
        },
        catalogueDetails: {
            rowGap: "16px"
        },
        carouselContainer: {
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-dot-list": {
                top: "225px"
            },
            maxWidth: "287px",
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 20px`,
        },
        value: {
            fontSize: "14px",
        },
        productDefects: {
            fontSize: "16px"
        },
        productDefectsDescription: {
            fontSize: "12px"
        },
        selectContainer: {
            marginTop: "-16px"
        },
    }
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 10
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 670, min: 0 },
        items: 1
    }
};

const SecondaryCatalogueDetails: React.FC = () => {

    const classes = useStyles();

    const { user, syncAuthDialogActive } = useAuthenticatedUser();

    const cartContextData = useContext(CartContext);

    const catalogueService = useCatalogueService();
    const cartService = useCartService();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [secondaryCatalogueId, setSecondaryCatalogueIdTo] = useState<number | null>(null);
    const [secondaryCatalogue, setsecondaryCatalogueTo] = useState<ISecondaryCatalogue | null>(null);
    const [packagingTypes, setPackagingTypesTo] = useState<Record<string, string>>({});
    const [currentCartItem, setCurrentCartItemTo] = useState<ICurrentCartItem>({
        attributes: {},
        upc: null,
        warehouse: null,
        packaging: "WITHOUT_PACKAGING",
        quantity: "",
        secondarySkuId: null,
        paymentType: PAYMENT_METHODS.CASH_PAYMENT
    });

    const carouselRef = useRef<CarouselRef>(null);

    const CATALOGUE_DETAILS_SCHEMA = [{ label: "classType", icon: classType }, { label: "productCategory", icon: productCategory }, { label: "shape", icon: shape }];

    const gridTemplate = { xs: 12, sm: 6, md: 4, lg: 4, xl: 4 };

    const [valueAddedService, setValueAddedServiceTo] = useState<IValueAddedService>({
        shearing: [],
        slitting: [],
    })
    const [paymentType, setPaymentType] = useState<string | null>(PAYMENT_METHODS.CASH_PAYMENT);
    const [productCombination, setProductCombination] = useState<boolean | null>(null);

    const handlePaymentTypeChange = (paymentTypeMethod: string) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            paymentType: paymentTypeMethod
        });
        setPaymentType(paymentTypeMethod);
    };

    const handleQuantityChange = (quantity: string) => {
        if (REGEX.QUANTITY.test(quantity) || quantity === '') {
            setCurrentCartItemTo({
                ...currentCartItem,
                quantity
            });
        }
    };

    const handleWarehouseChange = (e: any) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            warehouse: e.target.value,
        });
    };

    const convertWarehousesToOptions = (warehouses: any): any => {
        return warehouses.map((warehouse: any) => ({
            value: warehouse,
            label: warehouse.name,
        }));
    };

    const handleAddToCart = async () => {
        if (!user) {
            syncAuthDialogActive()
            return;
        }
        const requiredQuantity = parseFloat(currentCartItem.quantity);
        if (isNaN(requiredQuantity)) {
            return;
        }
        if (secondaryCatalogue && currentCartItem && currentCartItem.warehouse && currentCartItem.paymentType) {
            let cartRequestBody: ICartRequestBody = {
                catalogueName: secondaryCatalogue.catalogueName,
                upcId: null,
                warehouseId: secondaryCatalogue.warehouseId,
                quantity: requiredQuantity,
                uom: secondaryCatalogue.uom || "MT",
                packagingType: currentCartItem.packaging,
                secondarySkuId: secondaryCatalogue.secondarySkuId,
                valueAddedServices: valueAddedService,
                paymentType: currentCartItem.paymentType
            }
            await cartService.saveToCart(cartRequestBody);
            await cartContextData.syncCart(CART_LIFE_CYCLE.ADD_PRODUCT);
        }
    }

    useEffect(() => {
        const catalogueIdString = queryParams.get('secondaryCatalogueId');
        if (catalogueIdString !== null) {
            const parsedCatalogueId = parseInt(catalogueIdString, 10);
            if (!isNaN(parsedCatalogueId)) {
                setSecondaryCatalogueIdTo(parsedCatalogueId);
            }
        }
    }, [location.search])

    useEffect(() => {
        if (secondaryCatalogueId) {
            catalogueService.getSecondaryCatalogueById(secondaryCatalogueId)
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK) {
                        setsecondaryCatalogueTo(response.data.data ? response.data.data : null)
                        setsecondaryCatalogueTo((prevSecondaryCatalogue: ISecondaryCatalogue | null) => ({
                            ...(prevSecondaryCatalogue as ISecondaryCatalogue),
                            name: prevSecondaryCatalogue?.catalogueName || "",
                        }))
                        setPackagingTypesTo(Object.fromEntries(response.data.data?.packagingType?.map((key: string) => [key, key])) ?? {});
                        setCurrentCartItemTo(prevCartItem => ({ ...prevCartItem, packaging: response.data.data?.packagingType[0] || null, secondarySkuId: response.data.data.secondarySkuId, warehouse: response.data.data.warehouseName, quantity: String(response.data.data?.minimumQuantity || "") }));

                    }
                })


                .catch((error) => {
                    console.error('Error', error);
                    setsecondaryCatalogueTo(null);
                });
        }
    }, [secondaryCatalogueId])

    return (
        <div>
            <div className={`${classes.webContainer} max-container px-16 m-auto`}>
                <div className="flex justify-between mt-6">
                    <Breadcrumbs crumbs={[
                        {
                            link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: CLASS_STATES.SECONDARY, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                            label: "secondary"

                        },
                        {
                            link: "",
                            label: "View"
                        }
                    ]} />
                    <ContactUs />
                </div>
                <div className="flex gap-x-6">
                    <div className={`grid gap-y-6 mt-6 ${classes.leftContainer}`}>
                        <div className="w-full flex gap-x-6 ">
                            <div className="grid gap-y-5 h-fit">
                                <CatalogueImageTemplate
                                    isSharable={false}
                                    catalogueImages={secondaryCatalogue?.catalogueImages}
                                    isRepresentationImage={secondaryCatalogue?.isRepresentationImage}
                                />
                                <div><TermsAndConditionsCarousel /></div>
                                <AddToFavouriteTemplate id={secondaryCatalogue?.id} catalogueType={CLASS_STATES.SECONDARY} />
                            </div>
                            <div className={`grid gap-y-6 h-fit mt-0 m-auto ${classes.middleChild}`}>
                                <div>
                                    <CatalogueDetailsTemplate
                                        catalogue={secondaryCatalogue}
                                        CATALOGUE_DETAILS_SCHEMA={CATALOGUE_DETAILS_SCHEMA}
                                        gridTemplate={gridTemplate}
                                    />
                                </div>
                                {/* TODO: Will uncomment after getting data from backend */}
                                {/* {DEALS_OF_THE_DAY_DATA.length && <DealsOfTheDayTemplate />} */}
                                <div>
                                    <SecondaryProductAttributesTemplate
                                        attributes={
                                            secondaryCatalogue?.attributes?.productSpecification
                                        }
                                        netWeight={secondaryCatalogue?.netWeight || 0}
                                        grossWeight={secondaryCatalogue?.grossWeight || 0}
                                    />
                                    <div className="flex pt-6 gap-x-3">
                                        <div className="grid">
                                            <div
                                                className={`${classes.value} pb-10 text-base font-medium`}
                                            >
                                                Packaging
                                            </div>
                                            <div className={`${classes.selectContainer} w-36`}>
                                                <TextField
                                                    label=""
                                                    variant="standard"
                                                    value={snakeCaseToTitleCase(currentCartItem.packaging)}
                                                    onChange={(event) => {
                                                        setCurrentCartItemTo({
                                                            ...currentCartItem,
                                                            packaging: event.target.value,
                                                        });
                                                    }}
                                                    fullWidth
                                                    className={`justify-center h-12 !py-0 !pl-3 rounded-lg  ${classes.select}`}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid">
                                            <div
                                                className={`${classes.value} pb-4 text-base font-medium`}
                                            >
                                                Location
                                            </div>
                                            <div className={`flex ${classes.inputField}`}>
                                                <div className="w-30 rounded-2xl">
                                                    <TextField
                                                        type="text"
                                                        variant="standard"
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            readOnly: true,
                                                        }}
                                                        className={`!m-0 h-12 !px-3 !py-2 rounded-lg ${classes.location} ${classes.warehouse}`}
                                                        value={secondaryCatalogue?.warehouseName}
                                                        fullWidth
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid">
                                            <div className={`${classes.value} pb-4 text-base font-medium`}>Delivery Terms</div>
                                            <div className={`flex ${classes.inputField} h-12 rounded`}>
                                                <TextField
                                                    label=""
                                                    variant="standard"
                                                    value={"Within 3 Days"}
                                                    className={`${classes.select} justify-center align-center !m-0 !px-3 rounded-lg capitalize h-full`}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {secondaryCatalogue?.netWeight && secondaryCatalogue.netWeight > 0 ? (
                                            <div className={`${classes.inStock} mt-10 whitespace-nowrap`}>
                                                <span className={`${classes.inStockValue} font-medium text-sm`}>In Stock</span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className={`border-b py-6`}>
                            <SelectCustomizationTemplate
                                catalogue={null}
                                secondaryCatalogue={secondaryCatalogue}
                                quantity={parseFloat(currentCartItem.quantity)}
                                selectedUpc={currentCartItem?.upc}
                                valueAddedService={valueAddedService}
                                setValueAddedServiceTo={setValueAddedServiceTo} 
                            />
                        </div> */}
                        <div className={`border-b pt-4 pb-10`}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                        <div className={`border-b pt-4 pb-10`}><DeliveryDetailsTemplate /></div>
                        <div className="pb-10 pt-4 border-b grid gap-y-6">
                            <div className="flex justify-between border-b">
                                <div className={`${classes.activeCategory} text-lg font-bold  pb-1.5`}>
                                    Detailed Specifications (As per selection)
                                </div>
                                <span className={`${classes.activeCategory} text-lg font-bold `}>SKUID:{secondaryCatalogue?.skuCode}</span>
                            </div>
                            <SecondaryProducProperties
                                productSpecification={secondaryCatalogue?.attributes?.productSpecification}
                                productClassification={
                                    secondaryCatalogue?.attributes?.productClassification
                                }
                            />
                        </div>
                        <div className="border-b pb-10 pt-4"><FAQTemplate /></div>
                        <div className={`border-b pb-10 pt-4`}><TermsConditionsTemplate /></div>
                        <div className=" pt-4 pb-10" id="scrollToDisclaimer"><Disclaimer /></div>
                    </div>
                    <div className={classes.rightChild}>
                        <div className={`pt-6 sticky top-0 grid gap-y-4`}>
                            {secondaryCatalogue && (
                                <SecondaryPriceDetailsTemplate
                                    handleAddToCart={handleAddToCart}
                                    paymentType={currentCartItem.paymentType}
                                    minimumPrice={parseFloat(secondaryCatalogue?.price?.default)}
                                    maximumPrice={parseFloat(secondaryCatalogue?.price?.default)}
                                    quantity={secondaryCatalogue?.netWeight?.toString() ?? ''}
                                    warehousename={secondaryCatalogue?.warehouseName}
                                    gst={18}
                                    uom={currentCartItem?.warehouse?.uom?.toString() || ""}
                                    moq={secondaryCatalogue?.minimumQuantity}
                                    handleQuantityChange={handleQuantityChange}
                                    showAlteredQuantity={false}
                                    secondaryCatalogue={secondaryCatalogue}
                                    productCombination={productCombination}

                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`gap-y-6 ${classes.mobileContainer}`}>
                <div className={`grid gap-y-6 px-4`}>
                    <div className="">
                        <Breadcrumbs crumbs={[
                            {
                                link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: CLASS_STATES.SECONDARY, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                                label: "secondary"

                            },
                            {
                                link: "",
                                label: "View"
                            }
                        ]} />
                    </div>
                    <div className="w-full grid gap-y-2 mb-2">
                        <div className={`${classes.catalogueName} text-lg font-semibold`}>{secondaryCatalogue?.name}</div>
                        <CatalogueImageTemplate isSharable={false} catalogueImages={secondaryCatalogue?.catalogueImages} isRepresentationImage={secondaryCatalogue?.isRepresentationImage} />
                    </div>
                    {/* <AttributeSelectionTemplate
                        catalogue={catalogue}
                        catalogueUpc={catalogueUpc}
                        currentCartItem={currentCartItem}
                        currentAttributes={currentCartItem.attributes}
                        setCurrentCartItemTo={setCurrentCartItemTo}
                    /> */}
                    <SecondaryProductAttributesTemplate
                        attributes={
                            secondaryCatalogue?.attributes?.productSpecification
                        }
                        netWeight={secondaryCatalogue?.netWeight || 0}
                        grossWeight={secondaryCatalogue?.grossWeight || 0}
                    />

                    <div className='border-t'> </div>
                    <div className={`${classes.inputContainer}`}>
                        <TextFieldV2
                            label="Packaging"
                            value={snakeCaseToTitleCase(currentCartItem.packaging)}
                            onChange={(event) => {
                                setCurrentCartItemTo({
                                    ...currentCartItem,
                                    packaging: event.target.value,
                                });
                            }}
                            fullWidth
                            disabled={true}
                        />
                        <TextFieldV2
                            label="Location"
                            disabled={true}
                            value={secondaryCatalogue?.warehouseName}
                            fullWidth
                            required
                        />
                        <TextFieldV2
                            fullWidth
                            label="Delivery Terms"
                            value={"Within 3 Days"}
                            disabled={true}
                        />
                        {secondaryCatalogue?.netWeight && secondaryCatalogue.netWeight > 0 ? (
                                    <div className={`${classes.inStock} whitespace-nowrap w-fit`}>
                                        <span className={`${classes.inStockValue} font-medium text-sm`}>In Stock</span>
                                    </div>
                        ) : null}

                    </div>
                    <div className='border-t'> </div>
                    <div className={``}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                    <div className='border-t'> </div>
                    <div className={``}><DeliveryDetailsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className="grid gap-y-3">
                        <div className="flex justify-between border-b">
                            <div className={`${classes.activeCategory} text-lg font-bold  pb-1.5`}>
                                Detailed Specifications (As per selection)
                            </div>
                            <span className={`${classes.activeCategory} text-lg font-bold `}>SKUID:{secondaryCatalogue?.skuCode}</span>
                        </div>
                        <SecondaryProducProperties
                            productSpecification={secondaryCatalogue?.attributes?.productSpecification}
                            productClassification={
                                secondaryCatalogue?.attributes?.productClassification
                            }
                        />
                    </div>
                    <div className='border-t'> </div>
                    <div className={``}><FAQTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}><TermsConditionsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``} id="scrollToDisclaimer"><Disclaimer /></div>
                </div>

                <div className="w-full sticky bottom-24 z-50">
                    {secondaryCatalogue && (
                        <SecondaryPriceDetailsTemplate
                            handleAddToCart={handleAddToCart}
                            paymentType={currentCartItem.paymentType}
                            minimumPrice={parseFloat(secondaryCatalogue?.price?.default)}
                            maximumPrice={parseFloat(secondaryCatalogue?.price?.default)}
                            quantity={secondaryCatalogue?.netWeight?.toString() ?? ''}
                            warehousename={secondaryCatalogue?.warehouseName}
                            gst={18}
                            uom={currentCartItem?.warehouse?.uom?.toString() || ""}
                            moq={secondaryCatalogue?.minimumQuantity}
                            handleQuantityChange={handleQuantityChange}
                            showAlteredQuantity={false}
                            secondaryCatalogue={secondaryCatalogue}
                            productCombination={productCombination}
                        />
                    )}
                </div>

            </div>
        </div>
    );
}

export default SecondaryCatalogueDetails;
