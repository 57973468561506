import React from 'react'
import { createUseStyles } from 'react-jss'
import Dialog from '@mui/material/Dialog';
import successBackground from "../../../assets/images/rfqSuccessBackground.png"
import Button from "../../atoms/Button/Button";
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/joy/Radio';
import RadioActive from '../../../assets/images/radioActive.svg';
import RadioInActive from '../../../assets/images/radioInactive.svg'
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface ConfirmDialogProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    message: string;
    question: string;
    onSubmit: (response: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "694px !important",
            maxWidth: "694px !important",
            borderRadius: "16px",
            padding: "24px"
        },
    },
    headingColor: {
        color: theme.palette.textV2.primary.primary900,
    },
    "@media (max-width: 480px)": {
        container: {
            "& .MuiDialog-paper": {
                width: "448px !important",
                maxWidth: "448px !important",
                height: "826px",
                margin: "16px",
            }
        }
    },
}))

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ dialogOpen, setDialogOpen, message, question, onSubmit }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value == "true");
    };

    const handleDialogClose = (event: React.MouseEvent | {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            closeDialog();
        }
    }

    const closeDialog = () => {
        setDialogOpen(false)
        onSubmit(false);
    }

    const handleSubmit = () => {
        onSubmit(value);
        setDialogOpen(false);
    }

    return (
        <Dialog fullWidth className={classes.container} PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} onClose={handleDialogClose} >
            <div className={`flex justify-between items-center`} onClick={closeDialog}>
                <span className={`text-2xl font-medium ${classes.headingColor}`}>{message}</span>
                <CloseIcon className={`${classes.headingColor}`} />
            </div>
            <div className={`my-4 border-t`}></div>
            <div className='p-6 rounded-xl border'>
                <div className={`text-lg font-medium ${classes.headingColor}`}>{question}</div>
                <div className='flex gap-9 mt-4'>
                    <Radio value={true} label="Yes" checked={value === true} onChange={handleChange} checkedIcon={<img src={RadioActive} />} uncheckedIcon={<img src={RadioInActive} />} />
                    <Radio value={false} label="No" checked={value === false} onChange={handleChange} checkedIcon={<img src={RadioActive}/>} uncheckedIcon={<img src={RadioInActive} />} />
                </div>
            </div>
            <div className='flex justify-end mt-6'>
                <Button variant='primaryContained' label="Submit Response" onClick={handleSubmit}/>
            </div>
        </Dialog >
    )
}

export default ConfirmDialog