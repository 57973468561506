import { useLeadService } from "../../../services/useLeadService";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import OrderItem from "../Order/OrderItem.template";
import { Product } from "../Order/OrderList.template";
import Button from "../../atoms/Button/Button";
import { useParams } from "react-router-dom";
import completed from "../../../assets/icons/completed.svg"
import ongoing from "../../../assets/icons/ongoing.svg"
import cancelled from "../../../assets/icons/cancelled.svg"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { IPriceDetails } from "../Cart/CartPriceDetails.template";
import { PRICE_DETAILS, STATUS } from "../../../utils/constant";
import PaymentInfoTemplate from "../Order/PaymentInfo.template";
import { getFormattedDate, REQUEST_ORDER_STATUS } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
  container: {
    background: theme.palette.background.primaryLight,
    padding: "12px",
    columnGap: "24px",
    fontSize: "20px",
  },
  title: {
    color: theme.palette.text.primaryDark,
  },
  property: {
    color: theme.palette.text.primaryLight,
  },
  value: {
    color: theme.palette.text.primaryLight,
  },
  priceInformation: {
    color: theme.palette.text.secondaryDark,
    borderBottom: `1px solid ${theme.palette.border.secondaryLight}`,
  },
  lessTcs: {
    borderBottom: `1px solid ${theme.palette.border.secondaryLight}`,
  },
  grandTotal: {
    color: theme.palette.text.secondaryDark,
  },
  price: {
    color: theme.palette.text.secondaryDark,
  },
  completed:{
    background: theme.palette.status.primaryLight,
  },
  ongoing:{
    background: theme.palette.status.secondaryLight,
  },
  cancelled: {
    background: theme.palette.status.primaryDark,
  },
  headingContainer: {
    paddingBottom: "24px",
    borderBottom: `1px solid ${theme.palette.border.secondaryLight}`,
    fontSize: "24px"
  },
  mainContainer: { 
    rowGap: "24px",
  },
  productContainer: {
    rowGap: "24px",
    padding: "24px",
    border: `1px solid ${theme.palette.border.secondaryLight}`,
  },
  verticalBreak: {

  },
  status: {
    backgroundColor: theme.palette.background.primaryActive
  },
  timeLineWebContainer: {
    display: "flex"
  },
  timeLineMobileContainer: {
    display: "none"
  },
  priceContainer: {

  },
  statusHeading: {
    fontSize: "16px",
    padding: "4px 12px"
  },
  "@media (max-width: 476px)": {
    mainContainer: { 
      rowGap: "16px",
      marginTop: "56px"
    },
    headingContainer: {
      paddingBottom: "0px",
      borderBottom: "none",
      fontSize: "16px"
    },
    productContainer: {
      rowGap: "16px",
      padding: "0px",
      border: "none",
    },
    verticalBreak: {
      borderBottom: `1px solid ${theme.palette.border.secondaryLight}`,
    },
    timeLineWebContainer: {
      display: "none"
    },
    timeLineMobileContainer: {
      display: "grid"
    },
    priceContainer: {
      zIndex: 50,
      bottom: 0,
      left: 0,
      right: 0,
      position: "fixed",
    },
    container: {
      padding: "8px",
      columnGap: "none",
      justifyContent: "space-between",
      fontSize: "14px",
    },
    statusHeading: {
      fontSize: "12px",
      padding: "0px 12px"
    },
  },
}));

export interface IPaymentChargesProps {
  gst: number,
  tcs: number,
  otherCharges: number,
  handlingCharges: number,
  packagingCharges: number,
  warehouseCharges: number
}

export interface Quotation {
  id: number;
  status: string;
  products: Product[];
  documents: null;
  timeLine: Quotations[];
  charges: Charges[];
}

export interface Charges {
  gst: number;
  tcs: number;
  otherCharges: number;
  handlingCharges: number;
  packagingCharges: number;
  warehouseCharges: number
}

export interface Quotations {
  id: number;
  createdDate: Date;
  updatedDate: Date;
  status: string;
}

const QuotationViewTemplate: React.FC = () => {
  const classes = useStyles();
  const leadService = useLeadService();
  const params = useParams();
  const [quotationUpc, setQuotationUpcTo] = useState<Quotation | null>(null);
  const [charges, setChargesTo] = useState<Charges | null>(null);

  const [totalPrice, setTotalPriceTo] = useState<number>(0);
  const [totalQuantity, setTotalQuantityTo] = useState<number>(0);
  const [grandTotal, setGrandTotalTo] = useState<number>(0);
  const [UOM, setUOMTo] = useState<string>("MT");
  const [paymentInfoDetails, setPaymentInfoDetailsTo] = useState<IPriceDetails>(() => PRICE_DETAILS({}));

  const calculatePaymentInfoDetails = (data: Quotation) => {
    let grandTotal = 0;
    let totalQuantity = 0
    let UOM = "MT";
    let paymentInfoDetails: IPriceDetails = PRICE_DETAILS({});
    const charges = data?.charges?.[0]
    const warehouseCharges = charges?.warehouseCharges ?? 0;
    const handlingCharges = charges?.handlingCharges ?? 0;
    const packagingCharges = charges?.packagingCharges ?? 0;
    const otherCharges = charges?.otherCharges ?? 0;
    let productSubTotal = otherCharges;
    data?.products?.forEach((item: any) => {
      item?.skuData.forEach((sku: any) => {
        const skuPrice =  (sku.quantity * (sku.price + packagingCharges));
        productSubTotal += skuPrice;
        totalQuantity += sku.quantity;
        UOM = item.uom;
      });
    });
    paymentInfoDetails.subTotal.price = productSubTotal;
    paymentInfoDetails.warehouseCharges.price = (warehouseCharges * totalQuantity);
    paymentInfoDetails.handlingCharges.price = (handlingCharges * totalQuantity);
    let totalProductPrice = (paymentInfoDetails.subTotal.price + ((warehouseCharges + handlingCharges) * totalQuantity)) ;
    paymentInfoDetails.GST.price = ((charges?.gst ?? 0) * totalProductPrice) / 100;
    paymentInfoDetails.TCS.price = ((charges?.tcs ?? 0) * totalProductPrice) / 100;
    grandTotal = totalProductPrice + paymentInfoDetails.GST.price + paymentInfoDetails.TCS.price;
    setGrandTotalTo(grandTotal)
    setUOMTo(UOM)
    setPaymentInfoDetailsTo(paymentInfoDetails);
  };


  const loadQuotationUpc = async () => {
    try {
      const quotationUpcResponse = await leadService.getQuotationById(Number(params.quotationId));
      if (quotationUpcResponse?.data?.data) {
        setQuotationUpcTo(quotationUpcResponse.data.data);
        setTotalQuantityTo(
          quotationUpcResponse.data.data.products.reduce(
            (total:any, item:any) => {
              const skuDataTotal = item.skuData.reduce(
                (skuTotal:any, sku:any) => skuTotal + parseFloat(sku.quantity) || 0,
                0
              );
              return total + skuDataTotal;
            },
            0
          )
        );
        setTotalPriceTo(calculateTotalPrice(quotationUpcResponse.data.data))
        setChargesTo(quotationUpcResponse.data.data.charges[0]);
      } else {
        throw new Error("Categories fetch failed");
      }
    } catch (error) {
      setQuotationUpcTo(null);
    }
  };

  const updateQuotation = async (status: string) => {
    let payload = {
      status: status
    }
    try {
      const quotationUpcResponse = await leadService.updateQuotation(Number(params.quotationId), payload);
      if (quotationUpcResponse?.data) {
        await loadQuotationUpc();
      } else {
        throw new Error("Categories fetch failed");
      }
    } catch (error) {
      setQuotationUpcTo(null);
    }
  };

  const handleUpdateQuoattion = (status: string) => () => {
    updateQuotation(status)
  }

  useEffect(() => {
    loadQuotationUpc();
  }, [params.quotationId]);

  useEffect(() => {
    if (quotationUpc) {
      calculatePaymentInfoDetails(quotationUpc);
    }
  }, [quotationUpc])

  const calculateTotalPrice = (quotationData: Quotation) => {
    let totalPrice = 0;
    quotationData?.products.forEach(item => {
      item.skuData.forEach((sku: any) => {
        const skuPrice = sku.quantity * sku.basePrice + sku.quantity * sku.basePrice * (sku.profit / 100);
        totalPrice += skuPrice;
      });
    });
    return totalPrice;
  }

  let statusBackgroundColor;
  if (quotationUpc?.status === STATUS.ONGOING) {
    statusBackgroundColor = classes.ongoing;
  } else if (quotationUpc?.status === STATUS.ACCEPTED) {
    statusBackgroundColor = classes.completed;
  } else if (quotationUpc?.status === STATUS.REJECTED) {
    statusBackgroundColor = classes.cancelled;
  }

  return (
    <div className={`${classes.mainContainer} w-full grid`}>
      <div className={`${classes.headingContainer} flex items-center justify-between `}>
        <h2 className="font-black">Quotations</h2>
      </div>
      <div className={`${classes.productContainer} grid rounded-lg`}>
        <div className={`flex rounded-lg ${classes.container}`}>
          <div className={`${classes.property} font-semibold my-auto`}>Quotation ID: {quotationUpc?.id}</div>
          <div className={`${classes.statusHeading} text-base font-medium text-white ${statusBackgroundColor} rounded-2xl px-3 py-1`}>{REQUEST_ORDER_STATUS(quotationUpc?.status)}</div>
        </div>
        {quotationUpc?.products && quotationUpc?.products?.map((product: Product) => (
          <OrderItem key={product.id} product={product} isShowSku={true}/>
        ))}
      </div>

      <div className={classes.verticalBreak}></div>


      <div className={`${classes.timeLineWebContainer} justify-center p-6 border rounded-lg`}>
        <div className="flex gap-x-5 justify-center">
          {quotationUpc?.timeLine && quotationUpc?.timeLine.map((quotation: Quotations, index) => (
            <div className={`${classes.property} text-sm font-normal flex gap-x-5 text-center`} key={quotation.id}>
              <div className="grid gap-y-1.5 ">
                {quotation?.status === STATUS.ONGOING ? (
                  <img className="m-auto" src={ongoing} alt="Ongoing" />
                    ) : quotation?.status === STATUS.ACCEPTED ? (
                  <img className="m-auto" src={completed} alt="Completed" />
                    ) : quotation?.status === STATUS.REJECTED ? (
                  <img className="m-auto" src={cancelled} alt="Cancelled" />
                    ) : null}
                <div>{index + 1} Quotation</div>
                <div>{getFormattedDate(quotation?.createdDate)}</div>
              </div>
              {quotationUpc?.timeLine.length - 1 !== index &&
                <div className="m-auto">
                  <ArrowRightAltIcon className="bg-white text-red-400" sx={{ fontSize: 50 }} />
                </div>}
            </div>
          ))}
        </div>
      </div>

  
      <div className={`${classes.timeLineMobileContainer} border rounded-lg`}>
        {quotationUpc?.timeLine && quotationUpc?.timeLine.map((quotation: Quotations, index) => (
          <div className={`${classes.property} text-sm font-normal flex gap-x-2 px-4 ${index % 2 === 1 ? `${classes.status}` : ''}`} key={quotation.id}>
            <div className="grid justify-center">
              <div className={`h-2 border-l border-dashed mx-auto ${index === 0 ? "border-none" : ''}`}></div>
                {quotation?.status === STATUS.ONGOING ? (
                  <img className="m-auto" src={ongoing} alt="Ongoing" />
                    ) : quotation?.status === STATUS.ACCEPTED ? (
                  <img className="m-auto" src={completed} alt="Completed" />
                    ) : quotation?.status === STATUS.REJECTED ? (
                  <img className="m-auto" src={cancelled} alt="Cancelled" />
                    ) : null}
              <div className={`h-2 border-l border-dashed mx-auto ${index === quotationUpc?.timeLine.length - 1 ? "border-none" : ''}`}></div>
             </div>
            <div className="grid gap-y-1 my-auto">
              <div>{index + 1} Quotation</div>
              <div>{getFormattedDate(quotation?.createdDate)}</div>
            </div>
          </div>
        ))}
      </div>
    

      <div className={` ${classes.priceContainer}`}>
      <PaymentInfoTemplate
        label={"Payment Information"}
        status={quotationUpc?.status}
        showStatus={false}
        priceDetails={paymentInfoDetails}
        quantity={totalQuantity}
        uom={UOM}
        totalPrice={grandTotal}
      />
      </div>

      {quotationUpc?.status === "ONGOING" ?
        (
          <div className="flex justify-end gap-x-6">
            <Button className="w-28" label="Reject" variant="outlined" onClick={handleUpdateQuoattion(STATUS.REJECTED)} />
            <Button className="w-28" label="Accept" variant="containedLight" onClick={handleUpdateQuoattion(STATUS.ACCEPTED)} />
          </div>
        ) : null
      }

    </div>
  );
};

export default QuotationViewTemplate;
