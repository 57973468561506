import React from 'react';
import { createUseStyles } from 'react-jss';
import { OTHER_PREFERENCE } from '../../../utils/constant';
import { Checkbox } from '@mui/material';
import { getProfileDisplayData } from '../../../utils/helper';

interface PaymentPreferenceEditProps {
    payments: string;
    creditDays: string;
    itemCheckbox: number[];
}

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.secondaryDark
    },
    values: {
        color: theme.palette.text.secondaryDark
    },
    otherPref: {
        color: theme.palette.text.secondaryDark
    },
    numberedListItem: {
        fontSize: '1rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
    },
    checkBox: {
        "& .MuiSvgIcon-root": {
            color:  theme.palette.buttonV2.secondaryText.text.primaryLight,
        },
        "&.MuiButtonBase-root.MuiCheckbox-root":{
            padding: "0px"
        }
    }
}));

const PaymentPreferenceEdit: React.FC<PaymentPreferenceEditProps> = ({ payments, creditDays, itemCheckbox }) => {
    const classes = useStyles();
    return (
        <>
            <div className='border-b'>
                <div className="flex gap-x-6 mt-6 mb-20">
                    <div className="w-1/3">
                        <div className={`${classes.title} text-sm font-normal`}>Payment Preference</div>
                        <div className={`${classes.values} text-base font-medium`}>{getProfileDisplayData(payments)}</div>
                    </div>
                    <div className="w-full">
                        <div className={`${classes.title} text-sm font-normal`}>Credit Period Days</div>
                        <div className={`${classes.values} text-base font-medium`}>{getProfileDisplayData(creditDays)}</div>
                    </div>
                </div>
            </div>
            <div className="border-b">
                <div className={`${classes.otherPref} text-2xl my-6 font-medium`}>Other Preferences</div>
            </div>
            <div className="w-full">
                <div>
                    {OTHER_PREFERENCE.filter(pref => itemCheckbox.includes(pref.id)).map((pref, index) => (
                        <div key={pref.id}>
                            <div  className='flex items-center my-6'>
                            <Checkbox checked disabled className={`${classes.checkBox} mr-6`} /> <span className={`${classes.otherPref}  text-base font-medium ml-6`}>{pref.value}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PaymentPreferenceEdit;
