import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import successBackground from "../../../assets/images/rfqSuccessBackground.png"
import Button from "../../atoms/Button/Button";
import CloseIcon from '@mui/icons-material/Close';
import { useGstinService } from "../../../services/useGstService";
import { useEffect, useState } from "react";
import { createGstAdditionalAddressLines, createGstAddressLines } from "../../../utils/helper";

interface IGstnDetailModalTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    locations: any;
    index: number;
}

interface PrincipalPlaceSplitAddress {
    addressLine: string;
    city: string[];
    country: string[];
    pincode: string;
    state: string[][];
}

interface AdditionalAddress {
    street: string;
    locality: string;
    state: string;
    city: string;
    pincode: string;
    buildingName:string;
    buildingNo:string;
}

export interface GstinResponseDTO {
    gstin: string;
    legalNameOfBusiness: string;
    tradeNameOfBusiness: string;
    registrationDate: string;
    constitutionOfBusiness: string;
    gstStatus: string;
    city: string[];
    state: string[][];
    country: string[];
    addressLine: string;
    pincode: string;
    additionalAddress: AdditionalAddress[];
    valid: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.v2.text.primaryDark
    },
    container: {
        "& .MuiDialog-paper": {
            width: "894px !important",
            maxWidth: "894px !important",
            borderRadius: "16px"
        },
    },
    dialogContainer: {
        backgroundImage: `url(${successBackground})`,
    },
    mainHeading: {
        color: theme.palette.v3.text.primaryLightAccent
    },
    content: {
        color: theme.palette.v3.text.primaryLight
    },
    button: {
        paddingLeft: "89px !important",
        paddingRight: "89px !important",
        color: theme.palette.button.secondaryDark,
    },
    closeIcon: {
        color: theme.palette.v2.text._primaryDark
    }
}));
const GntnDetailModalTemplate: React.FC<IGstnDetailModalTemplateProps> = ({ dialogOpen, setDialogOpen, locations, index }) => {
    const classes = useStyles();
    const gstinService = useGstinService();
    const [gstinDetail, setGstinDetail] = useState<GstinResponseDTO | null>(null);
    const [principalAddress, setPrincipalAddress] = useState<any | null>(null);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    
    const validateGstin = async () => {
        const response = await gstinService.searchGstin({ gstin: locations?.[index]?.gstin });
        if (response?.data?.data?.valid) {
            setGstinDetail(response.data.data);
            const details = response?.data?.data;
            const gstSplitAddress = createGstAddressLines(details);
            setPrincipalAddress(gstSplitAddress);
        } else {
            setGstinDetail(null);
        }
    }
    useEffect(() => {
        validateGstin()
    }, [locations?.[index]?.gstin]);

    return (
        <Dialog fullWidth className={classes.container} PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} onClose={handleDialogClose}>
            <div className={`absolute top-0 right-0 p-4 cursor-pointer `} onClick={handleDialogClose}>
                <CloseIcon className={`${classes.closeIcon}`} />
            </div>
            <div className={` flex gap-x-6 pt-20 pb-11 px-10 `}>
                <div className="grid gap-y-5 w-full border p-4 rounded-md">
                    <div className="grid gap-y-5">
                        <div className={`${classes.titleText} font-semibold text-sm `}>Principal Place Of Address</div>
                        <div className="border-t border-gray-300 my-2"></div>
                        <div className="grid gap-2">
                            <div className={`${classes.content} text-sm`}>Address Line 1</div>
                            <div className={`${classes.content} text-base font-normal text-ellipsis overflow-hidden`}>{principalAddress?.[0]}</div>
                        </div>
                        <div className="grid gap-2">
                            <div className={`${classes.content} text-sm`}>Address Line 2</div>
                            <div className={`${classes.content} text-base font-normal text-ellipsis overflow-hidden`}>{principalAddress?.[1]}</div>
                        </div>
                        <div className="flex">
                            <div className={`${classes.content}  w-1/5 text-sm grid gap-2`}>
                                <div className="text-sm">Pincode</div>
                                <div className="text-base">{gstinDetail?.pincode}</div>
                            </div>
                            <div className={`${classes.content}  w-1/5 text-sm grid gap-2`}>
                                <div className="text-sm">City</div>
                                <div className="text-base">{gstinDetail?.city}</div>
                            </div>
                            <div className={`${classes.content}  w-1/5 text-sm grid gap-2`}>
                                <div className="text-sm">State</div>
                                <div className="text-base">{gstinDetail?.state}</div>
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-300 my-2"></div>
                    <div className="flex gap-y-5 flex-wrap justify-between">
                        {
                            gstinDetail?.additionalAddress?.map((location: AdditionalAddress, index: number) => {
                                const addressLines = createGstAdditionalAddressLines(location);
                                return (
                                    <div key={index} className="grid gap-y-5 w-1/2">
                                        <div className={`${classes.titleText} font-semibold text-sm`}>Additional business location {index + 2}</div>
                                        <div className="grid gap-2">
                                            <div className={`${classes.content} text-sm`}>Address Line 1</div>
                                            <div className={`${classes.content} text-base font-normal text-ellipsis overflow-hidden`}>{addressLines[0]}</div>
                                        </div>
                                        <div className="grid gap-2">
                                            <div className={`${classes.content} text-sm`}>Address Line 2</div>
                                            <div className={`${classes.content} text-base font-normal text-ellipsis overflow-hidden`}>{addressLines[1]}</div>
                                        </div>
                                        <div className="flex ">
                                            <div className={`${classes.content}  w-1/5 text-sm grid gap-2 `}>
                                                <div className="text-sm">Pincode</div>
                                                <div className="text-base">{location.pincode}</div>
                                            </div>
                                            <div className={`${classes.content}  w-2/5 text-sm grid gap-2 `}>
                                                <div className="text-sm  justify-self-center">City</div>
                                                <div className="text-base justify-self-center">{location.city}</div>
                                            </div>
                                            <div className={`${classes.content}  w-1/5 text-sm grid gap-2 `}>
                                                <div className="text-sm">State</div>
                                                <div className="text-base">{location.state}</div>
                                            </div>

                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="flex justify-end pr-10 pb-5">
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleDialogClose}
                    />
                </div>
        </Dialog>
    );
};

export default GntnDetailModalTemplate;