import React from 'react'
import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import banner from "../../../assets/images/rfqFormBanner.png"
import { IRfqRequestBody, useRfqService } from "../../../services/useRfqService";
import { IUserRequestBody, useUserService } from "../../../services/useUserService";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useState } from "react";
import { HTTP_STATUS } from "../../../utils/types";
import { isArray } from "lodash";
import RequestForQuotationFormTemplate from '../../templates/Home/RequestForQuotationForm.template';
import ThanksModalTemplate from '../../templates/Home/ThanksModal.template';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    mainContainer:{
        marginBottom: "16px",
        padding: "0px 12px"
    },
    container: {
        "& .MuiDialog-paper": {
            width: "1297px !important",
            maxWidth: "1297px !important",
            height: "826px",
            borderRadius: "16px"
        }
    },
    rfqMainContainer:{
        marginTop: "20px",
    },
    image: {
        height: "687px",
        display: "grid"
    },
    heading:{
        color: theme.palette.textV2.primary.primary900,
        fontSize: "36px",
    },
    closeIcon:{
        color: theme.palette.textV2.primary.primary500,
    },
    rfqContainer: {
        marginRight: "64px"
    },
    "@media (max-width: 480px)": {
        image: {
            display: "none"
        },
        container: {
            "& .MuiDialog-paper": {
                width: "448px !important",
                maxWidth: "448px !important",
                height: "826px",
                margin: "16px",
                maxHeight: "calc(100% - 16px)"
            }
        },
        mainContainer:{
            marginBottom: "0px",
            padding: "16px"
        },
        rfqMainContainer:{
            marginTop: "0px",
        },
        heading:{
            fontSize: "18px"
        },
        rfqContainer: {
            marginRight: "0px"
        },
    },
}));


const RequestForQuotationPage = () => {
    const classes = useStyles();
    const rfqService = useRfqService();
    const userService = useUserService();
    const { user } = useAuthenticatedUser();
    const [thanksDialogOpen, setThanksDialogOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const createRfq = async (requestBody: any) => {
        const payload: IRfqRequestBody = {
            customerId: requestBody.customerId,
            productCategoryId: requestBody.productCategoryId,
            manufacturerId: requestBody.manufacturerId,
            gradeId: requestBody.gradeId,
            standardId: requestBody.standardId,
            shape: requestBody.shape,
            attributes: requestBody.attributes,
            requiredQuantity: requestBody.requiredQuantity,
            expectedDeliveryDate: requestBody.expectedDeliveryDate,
            specificRequirement: requestBody.specificRequirement.join(","),
            paymentTerm: requestBody.paymentTerm,
        };
        if (user !== null) {
            try {
                const enquiryResponse = await rfqService.createRfq(payload);
                if (enquiryResponse?.status === HTTP_STATUS.OK) {
                    setThanksDialogOpen(true)
                } else {
                    throw new Error("Rfq creation failed");
                }
            } catch (error) {
                return false;
            }
        } else {
            const userRequestBody: IUserRequestBody = {
                mobileNumber: requestBody.mobileNumber,
                fullName: requestBody.name,
                email: !!requestBody.email ? requestBody.email : null,
                gstin: !!requestBody.gst ? requestBody.gst : null,
                companyProfile: {
                    address: {
                        city: requestBody.city,
                        email: !!requestBody.email ? requestBody.email : null,
                        state: requestBody.state,
                        pincode: requestBody.pinCode,
                        line1: requestBody.addressLine1,
                        line2: requestBody.addressLine2,
                        mobileNumber: requestBody.mobileNumber,
                        resourceType: "BUSINESS"
                    },
                    business: {
                        name: requestBody.companyName,
                        gstin: !!requestBody.gst ? requestBody.gst : null,
                        statutory: {}
                    }
                }
            }
            const userResponse = await userService.createUser(userRequestBody);
            if (userResponse?.status === HTTP_STATUS.OK) {
                payload.customerId = userResponse.data.data.data.userId
                const enquiryResponse = await rfqService.createRfq({...payload, specificRequirement: (isArray(payload.specificRequirement) ? payload.specificRequirement.join(",") : payload.specificRequirement)});
                if (enquiryResponse?.status === HTTP_STATUS.OK) {
                    setThanksDialogOpen(true)
                }
            } else {
                throw new Error("User creation failed");
            }
        }
    };

    const handleContinue = () => {
        navigate("/");
    }

    return (
        <>
                <div className={`flex gap-x-6 ${classes.mainContainer}`} >
                    <img className={classes.image} src={banner} alt="" />
                    <div className={`${classes.rfqMainContainer} grid gap-y-6 m-auto flex-1`}>
                        <div className="flex justify-between">
                            <div className={`${classes.heading} font-semibold`}>Tell us About your Customised Requirements</div>
                        </div>
                        <div className={`${classes.rfqContainer}`}>
                            <RequestForQuotationFormTemplate onSubmit={createRfq} />
                        </div>
                    </div>
                </div>
            {thanksDialogOpen && <ThanksModalTemplate dialogOpen={thanksDialogOpen} setDialogOpen={setThanksDialogOpen} onContinue={handleContinue}/>}
        </>
    );
};


export default RequestForQuotationPage