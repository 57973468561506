import React from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import RawMaterialListingTemplate from './RawMaterialListing.template';
import { useNavigate } from 'react-router-dom';
import MaterialOtherPreferencesTemplate from './MaterialOtherPreferences.template';
import MaterialBrandManufacturerTemplate from './MaterialBrandManufacturer.template';
import ProdcutCategoryTemplate from './ProductCategoy.template';

const useStyles = createUseStyles((theme: any) => ({
  primary500: {
    color: theme.palette.textV2.primary.primary500,
  },
  borderColor: {
    border: `1px solid ${theme.palette.v3.background.primaryLight}`
  },
  heading: {
    color: theme.palette.textV2.tertiary.tertiary700
  },
  tertiary100Border: {
    borderColor: theme.palette.textV2.tertiary.tertiary100
  },
  sectionBackground: {
    background: theme.palette.backgroundV2.alphaDark.alphaDark5
  },

}));

const MainRawMaterialsTemplate = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleAddProduct = () => {
    navigate(`/dashboard/my-material/add`)
  }

  return (
    <div className='grid gap-6'>
      <div className={`p-4 w-full flex justify-between items-center ${classes.borderColor} rounded-lg`}>
        <span className={`${classes.heading} text-2xl`}>Raw Material Listing</span>
        <Button variant='contained' label="+Add New Product" className='!font-medium' onClick={handleAddProduct} />
      </div>
      <div className={`${classes.tertiary100Border} border-b`}></div>
      <div className={`${classes.sectionBackground} p-4 rounded-lg grid gap-y-6`}>
        <div className={`grid gap-y-6 `}>
          < RawMaterialListingTemplate />
        </div>
        <div>
          < ProdcutCategoryTemplate />
        </div>
        <div>
          < MaterialBrandManufacturerTemplate />
        </div>
        <div>
          < MaterialOtherPreferencesTemplate />
        </div>
      </div>
    </div>
  )
}

export default MainRawMaterialsTemplate