import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Checkbox, FormControl, InputAdornment, TextField } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import Search from "@mui/icons-material/Search";
import { INPUT_FIELD_TYPE } from "../../../utils/types";
import { ATTRIBUTE_LABEL, COLOR_DATA, REGEX } from "../../../utils/constant";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import { IColorPalette } from "../../templates/Catalogue/AttributeSelection.template";

interface ResourceFilterProps {
    label: string;
    isSearchable: boolean;
    resourceOptions: any;
    onChange: (id: number, isSelected: boolean, value?: string) => void;
    selectedOptions: (string | number)[];
    isRangeFilter: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.secondaryDark,
    },
    value: {
        color: theme.palette.text.primaryLight,
    },
    search: {
        margin: "0",
        background: theme.palette.background.primaryLight,
    },
    searchIcon: {
        color: theme.palette.icon.primaryLight
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: `${theme.palette.v2.border.primaryLight} !important`,
        borderRadius: '8px',
        fontWeight: 400
    },
    selectedFilterChip: {
        background: theme.palette.v3.background.primaryLight,
        color: theme.palette.v2.text._primaryDark
    },
    error: {
        color: theme.palette.v3.text._error
    }
}));

export const ResourceFilterMobile: React.FC<ResourceFilterProps> = ({ label, isSearchable, resourceOptions, onChange, selectedOptions, isRangeFilter }) => {
    const classes = useStyles();
    const [showAll, setShowAll] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    if (!resourceOptions) return null;

    const displayedData = Array.isArray(resourceOptions) ? resourceOptions : [];

    const searchData = displayedData?.filter((item: any) =>
        item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );


    const handleRangeOnCancel = (e: React.MouseEvent<HTMLSpanElement>) => {
        onChange(0, false, "")
        onChange(1, false, "")
    }

    const handleOnChange = (id: number) => (e: any) => {
        if (e?.target?.type === INPUT_FIELD_TYPE.CHECKBOX) {
            onChange(id, e?.target?.checked ?? false, e?.target?.value)
        } else {
            if (REGEX.PLP_RANGED_ATTRIBUTES.test(e?.target?.value) || e?.target?.value === '') {
                onChange(id, e?.target?.checked ?? false, e?.target?.value)
            }
        }
    }
    const handleCrossClick = (id: number) => (e: any) => {
        onChange(id, false, '');
    };

    const getRangeDetails = (minValue: any, maxValue: any) => {
        if (!!minValue && !!maxValue) return `${minValue} - ${maxValue} mm`
        return `${minValue || maxValue} mm`
    }

    const getAttributeValue = (attributeName: string, attributeValue: string, uom: string) => {
        try {
            if(attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return attributeValue;
            }
            const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-6 h-4 mr-2 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue} {uom}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
	}
    return (
        <div className="grid gap-y-6">
            <div className="grid gap-y-2 pt-6  pb-2 border-b">
                <div className="flex justify-between">
                    <div className={`${classes.title} text-base font-semibold`}>{label}</div>
                </div>
                {isRangeFilter && (
                    <div className="flex flex-col gap-6">
                        <TextFieldV2
                            id={`${label}_max`}
                            size="small"
                            placeholder=" Max"
                            label=""
                            variant="outlined"
                            type="number"
                            InputProps={{
                                classes: { notchedOutline: classes.notchedOutline }
                            }}
                            value={selectedOptions?.[1]}
                            onChange={handleOnChange(1)}
                            error={parseInt(selectedOptions?.[0] as string) > parseInt(selectedOptions?.[1] as string)}
                            helperText={parseInt(selectedOptions?.[0] as string) > parseInt(selectedOptions?.[1] as string) && 'Max must be greater than Min'}
                        />
                        <TextFieldV2
                            id={`${label}_min`}
                            size="small"
                            placeholder=" Min"
                            label=""
                            variant="outlined"
                            type="number"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            value={selectedOptions?.[0]}
                            onChange={handleOnChange(0)}
                        />
                    </div>
                )}
                {isSearchable && (
                    <div className="flex gap-x-2 rounded">
                        <TextField
                            size="small"
                            className={classes.search}
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                className: "px-2 h-8 text-base",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                )}
                {searchData && !isRangeFilter && (
                    searchData.map((item: any, index: number) => (
                        <div className="flex gap-x-2" key={`${item.id}_${index}`}>
                            <div>
                                <Checkbox
                                    sx={{ padding: 0, color: "#C4C4C4" }}
                                    checked={selectedOptions?.includes(item.id)}
                                    onChange={handleOnChange(item.id)}
                                />
                            </div>
                            <div className={`${classes.value} my-auto text-sm font-normal`}>{getAttributeValue(label, item.name, "")}</div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};