import React from 'react';
import { useEffect, useState } from 'react';
import { useLeadService } from '../../../services/useLeadService';
import { Product } from '../Order/OrderList.template';
import LeadDetails from './LeadDetails.template';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { createUseStyles } from 'react-jss';
import { Pagination } from "@mui/material";
import { CUSTOMER_ROUTES, STATUS, dateFilter } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';
import OrderItemTemplate from '../Order/OrderItem.template';
import Select from '../../atoms/Select/Select';
import LeadListSkeletonTemplate from './LeadListSkeleton.template';
import NoProduct from '../../molecules/NoProduct/NoProduct';

export interface Lead {
  id: number;
  status?: string;
  createdAt: Date;
  products: Product[];
}

const useStyles = createUseStyles((theme: any) => ({
  title: {
    color: theme.palette.text.primaryDark,
  },
  tabTitle: {
    color: theme.palette.text.primaryLight,
  },
  activeTabTitle: {
    color: theme.palette.text.primaryDark,
    borderBottom: `2px solid ${theme.palette.text.primaryDark}`
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      color: theme.palette.text.secondaryDark,
      border: `1px solid ${theme.palette.border.primaryLight}`,
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 700,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "white",
      color: theme.palette.pagination.text.primaryLight,
      border: `1px solid ${theme.palette.pagination.border.primaryLight}`,
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 700,
    },
  },
  dateSelect: {
    backgroundColor: theme.palette.background.primaryLight,
    "& .MuiOutlinedInput-input": {
      paddingTop: "7px !important",
      paddingBottom: "7px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0
    }
  },
  searchInput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "12.5px !important",
      paddingBottom: "12.5px !important",
    }
  },
  mobileContainer: {
    display: "none",
  },
  webContainer: {
    display : "grid"
  },
  searchIcon:{
    background: "#D6E1FF"
  },
  tabContainer:{
  },
  "@media (max-width: 476px)": {
    mobileContainer:{
      display: "grid",
    },
    webContainer: {
      display : "none"
    },
    tabContainer:{
      scrollbarWidth: "none",
    }
  },
}));

const requestStatusTabs = [
  {
    label: "All Request",
    status: ""
  },
  {
    label: "Ongoing Request",
    status: STATUS.ONGOING
  },
  {
    label: "Confirmed Request",
    status: STATUS.ACCEPTED
  },
  {
    label: "Cancelled Request",
    status: STATUS.REJECTED
  }
];

interface IPagination {
  size: number;
  totalRecords: number;
  page: number;
}

const LeadListTemplate: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const leadService = useLeadService();
  const [leads, setLeadsTo] = useState<Lead[]>([]);
  const [activeTabIndex, setActiveTabIndexTo] = useState<number>(0);
  const [pagination, setPaginationTo] = useState<IPagination>({
    size: 10,
    totalRecords: 0,
    page: 0
  });
  const [loading, setLoading] = useState<boolean>(true);

  const refreshLeads = async () => {
    try {
      setLoading(true);
      const params = {
        page: pagination.page,
        size: pagination.size,
        sort: "createdAt,desc",
        status: requestStatusTabs[activeTabIndex].status,
        date: lastDate,
        catalogueName: catalogueName
      };
      const leadResponse = await leadService.getAllLeads(params);
      if (leadResponse?.data?.data?.content?.length) {
        const { content, totalElements } =
          leadResponse.data.data;
        setLeadsTo(content);
        setPaginationTo((prevPagination: IPagination) => ({
          ...prevPagination,
          totalRecords: totalElements
        }));
        setLoading(false);
      } else {
        throw new Error("Categories fetch failed");
      }
    } catch (error) {
      setLeadsTo([]);
      setLoading(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPaginationTo((prevPagination: IPagination) => ({
      ...prevPagination,
      page: page - 1,
    }));
  };

  const [dateFilterValue, setDateFilterValueTo] = useState<number>(0);
  const [lastDate, setLastDateTo] = useState<string | null>(null);
  const handleChange = (event: any) => {
    setDateFilterValueTo(event.target.value);
    if (event.target.value === 0) {
      setLastDateTo(null);
    } else {
      const today = new Date();
      const newStartDate = new Date(today);
      newStartDate.setMonth(today.getMonth() - event.target.value);
      setLastDateTo(newStartDate.toISOString().split('T')[0]);
    }
  };

  const [catalogueName, setCatalogueNameTo] = useState("");
  const handleSearch = (event: any) => {
    const { name, value } = event.target;
    if (name === "catalogueName") {
      setCatalogueNameTo(value);
    }
  };

  useEffect(() => {
    refreshLeads();
    document.getElementById('main')?.scroll(0,0)
  }, [pagination.page, pagination.size, activeTabIndex, lastDate, catalogueName]);

  const handleViewLead = (id: number) => () => {
    navigate(`${CUSTOMER_ROUTES.DASHBOARD}${CUSTOMER_ROUTES.LEAD}/${id}`);
  };

  const handleViewQuotation = (id: number) => () => {
    navigate(`${CUSTOMER_ROUTES.DASHBOARD}${CUSTOMER_ROUTES.LEAD}/${id}${CUSTOMER_ROUTES.QUOTATION}`);
  }

  const handleTabChange = (currentTabIndex: number) => () => {
    setActiveTabIndexTo(currentTabIndex);
  };

  const [leadVisibility, setLeadVisibility] = useState<{ [key: number]: boolean }>({});

  return (
    <>
      <div className={`${classes.webContainer} w-full gap-y-6`}>
        <div className="flex items-center justify-between border-b-2 pb-6">
          <h2 className={`${classes.title} font-black text-2xl`}>
            All Request
          </h2>
          <div className="flex items-center gap-5">
            <TextField
              label="Enter a keyword to search a product..."
              variant="outlined"
              type="text"
              name="catalogueName"
              value={catalogueName}
              onChange={handleSearch}
              className={`${classes.searchInput} w-80`}
            />
            <div className={`${classes.searchIcon} py-3 px-3.5 rounded-md`}>
              <SearchIcon className="text-base  text-indigo-900" />
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-x-5 border-b-2 w-fit">
            {requestStatusTabs.map((leadStatus, index) => (
              <span
                key={leadStatus.status}
                onClick={handleTabChange(index)}
                className={`cursor-pointer ${
                  activeTabIndex === index
                    ? `${classes.activeTabTitle} pb-3`
                    : `${classes.tabTitle}`
                }`}
              >
                {leadStatus.label}
              </span>
            ))}
          </div>
          <div className="">
            <Select
              label=""
              value={dateFilterValue}
              onChange={handleChange}
              className={`${classes.dateSelect}`}
              name="dateFilter"
              id="dateFilter"
              options={dateFilter}
              variant={'outlined'}/>
          </div>
        </div>
        {loading ? (
          [null, null, null].map(() => (
            <div className="grid gap-y-6">
              <LeadListSkeletonTemplate />
            </div>
          ))
        ) : leads && leads.length > 0 ? (
          leads.map(
            (lead: Lead) =>
              lead.products.length > 0 && (
                <div
                  key={lead.id}
                  className="grid gap-y-6 p-6 rounded border-2"
                >
                  <LeadDetails
                    data={lead}
                    onClick={handleViewQuotation(lead?.id)}
                  />
                  {lead?.products.map((product: Product) => (
                    <OrderItemTemplate
                      label={"View Details"}
                      key={product.id}
                      product={product}
                      onClick={handleViewLead(product?.id)}
                    />
                  ))}
                </div>
              )
          )
        ) : (
          <NoProduct
            text="No Requests"
            subText="Explore our exclusive products and shop them."
            label="Start Exploring"
          />
        )}
        <Pagination
          className={`${classes.pagination} m-auto mt-6`}
          variant="outlined"
          shape="rounded"
          count={Math.ceil(pagination.totalRecords / pagination.size)}
          page={pagination.page + 1}
          onChange={handlePageChange}
        />
      </div>

      <div className={`${classes.mobileContainer} w-full gap-y-4 mx-auto`}>
        <div className="flex justify-end">
          <div className="">
            <Select
              label=""
              value={dateFilterValue}
              onChange={handleChange}
              className={`${classes.dateSelect}`}
              name="dateFilter"
              id="dateFilter"
              options={dateFilter}
              variant={'outlined'}/>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex-1">
            <TextField
              label="Enter a keyword to search a product..."
              variant="outlined"
              type="text"
              name="catalogueName"
              value={catalogueName}
              onChange={handleSearch}
              className={`${classes.searchInput} w-full`}
            />
          </div>
          <div className={`${classes.searchIcon} py-3 px-3.5 rounded-md`}>
            <SearchIcon className="text-base text-indigo-900" />
          </div>
        </div>

        <div className={`${classes.tabContainer} overflow-x-auto border-b-2`}>
          <div className="flex gap-x-5 ">
            {requestStatusTabs.map((leadStatus, index) => (
              <span
                key={leadStatus.status}
                onClick={handleTabChange(index)}
                className={`cursor-pointer flex-none ${
                  activeTabIndex === index
                    ? `${classes.activeTabTitle} pb-1 text-xs`
                    : `${classes.tabTitle} text-xs`
                }`}
              >
                {leadStatus.label}
              </span>
            ))}
          </div>
        </div>

        {loading ? (
          [null, null, null].map(() => (
            <div className="grid gap-y-4">
              <LeadListSkeletonTemplate />
            </div>
          ))
        ) : leads && leads.length > 0 ? (
          leads.map(
            (lead: Lead) =>
              lead.products.length > 0 && (
                <div
                  key={lead.id}
                  className="grid gap-y-4 rounded "
                >
                  <LeadDetails
                    data={lead}
                    onClick={handleViewQuotation(lead?.id)}
                    show={leadVisibility[lead.id] || false}
                    setShow={() => setLeadVisibility(prevState => ({ ...prevState, [lead.id]: !prevState[lead.id] }))}
                  />
                  {lead?.products.map((product: Product) => (
                     <div className={`${leadVisibility[lead.id] ? "hidden" : ""}`} key={product.id}>
                      <OrderItemTemplate
                      label={"View Details"}
                      key={product.id}
                      product={product}
                      onClick={handleViewLead(product?.id)}
                    />
                    </div>
                  ))}
                </div>
              )
          )
        ) : (
          <NoProduct
            text="No Requests"
            subText="Explore our exclusive products and shop them."
            label="Start Exploring"
          />
        )}


        <Pagination
          className={`${classes.pagination} m-auto mt-6`}
          variant="outlined"
          shape="rounded"
          count={Math.ceil(pagination.totalRecords / pagination.size)}
          page={pagination.page + 1}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default LeadListTemplate;