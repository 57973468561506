import React, { useEffect, useRef } from "react";
import OurProductsColdRolled from "../../../../src/assets/images/OurProductsColdRolled.svg";
import OurProductsElectrolyticTinplate from "../../../../src/assets/images/OurProductsElectrolyticTinplate.svg";
import OurProductsGalvanized from "../../../../src/assets/images/OurProductsGalvanized.svg";
import OurProductsPrePainted from "../../../../src/assets/images/OurProductsPrePainted.svg";
import OurProductsTinFreeSteel from "../../../../src/assets/images/OurProductsTinFreeSteel.svg";
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";


const AboutUsTemplate: React.FC = () => {
    const classes = useStyles();

    const carouselRef = useRef<CarouselRef>(null);
    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 6,
            partialVisibilityGutter: 15
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 3,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 980, min: 670 },
            items: 2,
            partialVisibilityGutter: 10
        },
        "sm-mobile": {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 10
        }
    };

    return (
        <>
            <section>
                <div className={classes.banner_main}>
                    <div className={`${classes.banner_wrapper} ${classes.container}`}>
                        <div className={classes.banner_content}>
                            <span>About Us</span>
                            <h1>Customised and Cost Effective Flat Rolled Steel Raw Material Solutions</h1>
                            <p>Avighna Steel offers customised Flat-rolled steel raw materials for SMEs and MSMEs, providing
                                Cost-effective solutions through in house processed Prime/Secondary materials, including Cold
                                Rolled
                                and
                                Coated products like Galvanized, Tin Plates, Tin-free, Color Coated and others.</p>
                            <div className="btn">
                                <a href="/">Shop Now</a>
                            </div>
                        </div>
                        <div className={classes.banner_preview}>
                            <div>
                                <img src="../assets/images/AboutUs/bannerImage1.webp" alt="" className="banner_img" />
                            </div>
                            <div className="banner_preview_content_secondary ">
                                <img src="../assets/images/AboutUs/bannerImage2.webp" alt="" className="banner_img" />
                            </div>
                            <div>
                                <img src="./" alt="" />
                            </div>
                        </div>
                        {/* <div className={classes.banner_mobile}>
                            <img src="../assets/images/AboutUs/aboutusMobile.png" alt="" />
                        </div> */}
                    </div>
                </div>
            </section>

            <section>
                <div className={classes.suppliers_wrapper}>
                    <h2 className={classes.small_heading}>Our Supplies</h2>
                    <div>
                        <div className={classes.suppliers_wrapper_content}>
                            <div className={classes.suppliers}>
                                <img src="../assets/images/logo1.webp" alt="tata steel" />
                            </div>
                            <div className={classes.suppliers}>
                                <img src="../assets/images/logo2.webp" alt="tata steel" />
                            </div>
                            <div className={classes.suppliers}>\
                                <img src="../assets/images/logo3.webp" alt="tata steel" />
                            </div>
                            <div className={classes.suppliers}>
                                <img src="../assets/images/logo4.webp" alt="tata steel" />
                            </div>
                            <div className={classes.suppliers}>
                                <img src="../assets/images/logo5.webp" alt="tata steel" />
                            </div>
                            <div className={classes.suppliers}>
                                <img src="../assets/images/logo6.webp" alt="tata steel" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className={`${classes.section_strip}`}>
                <div className={classes.business_wrapper}>
                    <h3>Our Main Business</h3>
                    <div>
                        <div className={`${classes.business_card_m} ${classes.section_top_spacing}`}>
                            <div className={`${classes.business_card} ${classes.container}`}>
                                <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                                    <img src="../assets/images/AboutUs/leaf.webp" alt="" />
                                    <h2>Avighna Advantage</h2>
                                    <p className={classes.card_sub_heading}>Customized and Cost Effective Raw Materials from Secondary Steel
                                        Materials</p>
                                    <p className={classes.card_description}>The company processes secondary steel from international and
                                        domestic mills, segregating up
                                        to 80% Prime Customized Raw materials. This saves costs and benefits SMEs, who use
                                        processed
                                        steel as their raw material, reducing production costs by 5-25%.</p>
                                </div>
                                <div className={classes.card_review}>
                                    <img src="../assets/images/AboutUs/avighnaAdvantage.webp" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className={classes.business_card_m}>
                            <div className={`${classes.business_card} ${classes.container}`}>
                                <div className={classes.card_review_secondary}>
                                    <img src="../assets/images/AboutUs/avighnaCustomize.webp" alt="" />
                                </div>
                                <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                                    <img src="../assets/images/AboutUs/delivery.webp" alt="" />
                                    <h2>Avighna Customize</h2>
                                    <p className={classes.card_sub_heading}>Exact specifications, increased production & minimal rejections.
                                    </p>
                                    <p className={classes.card_description}>Avighna offers customized flat-rolled steel raw materials for
                                        the
                                        steel industry, including
                                        cold-rolled and coated products. This minimizes waste and enhances production
                                        efficiency.
                                    </p>
                                    {/* <!-- <div className="btn">
                            <a href="#">Read More</a>
                        </div> --> */}
                                </div>
                            </div>
                        </div>

                        <div className={classes.business_card_m}>
                            <div className={`${classes.business_card} ${classes.container}`}>
                                <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                                    <img src="../assets/images/AboutUs/leaf1.webp" alt="" />
                                    <h2>Avighna MetalPack</h2>
                                    <p className={classes.card_sub_heading}>Metal packaging solutions for safe, green and clean
                                        preservation.
                                    </p>
                                    <p className={classes.card_description}>Avighna Steel offers a comprehensive range of Tin Mill products
                                        and
                                        raw materials, including
                                        Electrolytic tin plates, Tin-free and Black plate steel (printed/non-printed) to ensure
                                        the
                                        best preservation properties, whether it is food, chemicals, pesticides, and any other
                                        precious consumables content.</p>
                                    {/* <!-- <div className="btn">
                            <a href="#">Read More</a>
                        </div> --> */}
                                </div>
                                <div className={classes.card_review}>
                                    <img src="../assets/images/AboutUs/avighnaMetalPack.webp" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className={classes.business_card_m}>
                            <div className={`${classes.business_card} ${classes.container}`}>
                                <div className={classes.card_review_secondary}>
                                    <img src="../assets/images/AboutUs/avighnaLogistics.webp" alt="" />
                                </div>
                                <div className={classes.card_content}>
                                    <img src="../assets/images/AboutUs/delivery1.webp" alt="" />
                                    <h2>Avighna Logistics</h2>
                                    <p className={classes.card_sub_heading}>Your trusted logistics partner, ensuring safe & reliable
                                        deliveries
                                        of your valuables</p>
                                    <p className={classes.card_description}>Avighna offers state-of-the-art steel material handling
                                        solutions at
                                        Taloja district Raigad
                                        Maharashtra (Asia’s largest steel marketplace) and transportation services to ensure
                                        safe
                                        and reliable deliveries at your doorstep.</p>
                                    <div className="btn">
                                        <a href="#">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className={` grid ${classes.container}`}>
                <h3 className={`font-semibold text-2xl ${classes.heading}`}>Our Recommended Suppliers</h3>
                <div className={`${classes.carouselContainer} rounded-3xl overflow-hidden`}>
                    <Carousel
                        responsive={responsive}
                        showDots={false}
                        infinite={true}
                        partialVisible={true}
                        ref={carouselRef}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        removeArrowOnDeviceType={["sm-mobile"]}
                    >
                        <div className={`${classes.slide} w-max`}><img src={OurProductsColdRolled} alt="" /></div>
                        <div className="w-max"><img src={OurProductsElectrolyticTinplate} alt="" /></div>
                        <div className="w-max"><img src={OurProductsGalvanized} alt="" /></div>
                        <div className="w-max"><img src={OurProductsPrePainted} alt="" /></div>
                        <div className="w-max"><img src={OurProductsTinFreeSteel} alt="" /></div>
                        <div className="w-max"><img src={OurProductsColdRolled} alt="" /></div>
                        <div className="w-max"><img src={OurProductsElectrolyticTinplate} alt="" /></div>
                        <div className="w-max"><img src={OurProductsGalvanized} alt="" /></div>

                    </Carousel>
                </div>
            </div>

            <section className={`${classes.section_strip}`}>
                <div className={`${classes.container}`}>
                    <h3 className={classes.section_heading}>Our Services</h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesShearing.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Shearing</span>
                                    <p>Steel Shearing is a precise process that cuts large sheets of steel into smaller,
                                        manageable sizes.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesSlitting.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Slitting</span>
                                    <p>Steel coils are longitudinally cut into narrower strips with uniform width and edge
                                        quality for industrial use.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesBlanking.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Blanking</span>
                                    <p>Steel Blanking is the method of punching out geometric shapes from flat steel sheets to
                                        create components used in manufacturing industries.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesMetalOffsetPrinting.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Metal Offset printing</span>
                                    <p>Metal offset printing transfers ink from a metal plate to a rubber blanket, producing
                                        high-quality prints on steel substrates.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesWarehousing.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Warehousing and Handling Services</span>
                                    <p>Avighna Steel Warehousing provides material handling services and supply chain management
                                        solutions to industries through advanced facilities and expertise.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesSteelLog.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Steel Logistics</span>
                                    <p>Efficient transportation of steel from manufacturers to end-users is crucial.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default AboutUsTemplate;
