import React, { useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import FormSubmit from '../../molecules/FormSubmit/FormSubmit';
import CommunicationPreference from '../../molecules/CommunicationPreference/CommunicationPreference';
import PaymentPreference from '../../molecules/PaymentPreference/PaymentPreference';
import { usePreferenceService, ICommunicationRequestBody, IPaymentRequestBody } from '../../../services/usePreferenceService';
import { HTTP_STATUS } from '../../../utils/types';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import PaymentPreferenceEdit from '../../molecules/PaymentPreference/PaymentPreferenceEdit';
import CommunicationPreferenceEdit from '../../molecules/CommunicationPreference/CommunicationPreferenceEdit';
import Shimmer from '../../molecules/ShimmerComponent/Shimmer';

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.v2.text.primaryDark
    },
}));

interface FormValues {
    communication: string;
    contact: string;
    language: string;
    payments: string;
    creditDays: string;
    itemCheckbox: number[];
}

const communicationValidationSchema = Yup.object().shape({
    communication: Yup.string().required("Communication preference is required"),
    contact: Yup.string().required("Contact preference is required"),
    language: Yup.string().required("Language preference is required"),
});

const paymentValidationSchema = Yup.object().shape({
    payments: Yup.string().required("Payment preference is required"),
    creditDays: Yup.string().required("Credit period days is required"),
});

const Preferences: React.FC = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);  
    const [showEditForm, setShowEditForm] = useState(false);
    const [showEditFormOther, setShowEditFormOther] = useState(false);
    const { editPreference, editPaymentPreference } = usePreferenceService();
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [initialValues, setInitialValues] = useState<FormValues>({
        communication: "",
        contact: "",
        language: "",
        payments: "",
        creditDays: "",
        itemCheckbox: [],
    });

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setInitialValues({
                    communication: profileData.communicationChannel || "",
                    contact: profileData.contactMode || "",
                    language: profileData.language || "",
                    payments: profileData.paymentMode || "",
                    creditDays: profileData.creditDays || "",
                    itemCheckbox: [
                        profileData.whatsappUpdates ? 1 : 0,
                        profileData.whatsappCommunity ? 2 : 0,
                        profileData.emailUpdates ? 3 : 0,
                        profileData.docsAccess ? 4 : 0,
                    ],
                });
                setShowEditForm(profileData.communicationChannel && profileData.contactMode && profileData.language);
                setShowEditFormOther(profileData.paymentMode);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        } 
        finally {
            setLoading(false);  
        }
    };

    useEffect(() => {
        getBusinessProfile();
    }, []);


    const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        try {
            if (showEditForm) {
                setShowEditForm(false);
                setSubmitting(false);
                return;
            }
            setShowEditForm(true);
            const communicationRequestBody: ICommunicationRequestBody = {
                communicationChannel: values.communication,
                language: values.language,
                contactMode: values.contact,
            };

            const communicationResponse = await editPreference(communicationRequestBody);
            if (communicationResponse?.status === HTTP_STATUS.OK) {
                setShowEditForm(true);
                return true;
            } else {
                throw new Error("Updating the Communication preference failed");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleSubmitOther = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        try {
            if (showEditFormOther) {
                setShowEditFormOther(false);
                setSubmitting(false);
                return;
            }
            setShowEditFormOther(true);
            const paymentRequestBody: IPaymentRequestBody = {
                creditDays: values.creditDays,
                paymentMode: values.payments,
                whatsappUpdates: values.itemCheckbox.includes(1),
                whatsappCommunity: values.itemCheckbox.includes(2),
                emailUpdates: values.itemCheckbox.includes(3),
                docsAccess: values.itemCheckbox.includes(4),
            };

            const paymentResponse = await editPaymentPreference(paymentRequestBody);
            if (paymentResponse?.status === HTTP_STATUS.OK) {
                setShowEditFormOther(true);
                return true;
            } else {
                throw new Error("Updating the Payment preference failed");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return <Shimmer />;
    }

    return (
        <div>
            <div className={`${classes.title} text-lg font-semibold mb-6`}>Communication Preference</div>
            <div>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={communicationValidationSchema} enableReinitialize>
                    {(formikProps) => (
                        <Form>
                            <FormSubmit
                                buttonText={showEditForm ? "Edit" : "Save"}
                                header={false}
                                onSubmit={formikProps.submitForm}
                                disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.values.communication}
                            >
                                {showEditForm ? (
                                    <CommunicationPreferenceEdit
                                        communication={formikProps.values.communication}
                                        contact={formikProps.values.contact}
                                        language={formikProps.values.language}
                                    />
                                ) : (
                                    <CommunicationPreference
                                        setFieldValue={formikProps.setFieldValue}
                                        formik={formikProps}
                                    />
                                )}
                            </FormSubmit>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="mt-6">
                <Formik initialValues={initialValues} onSubmit={handleSubmitOther} validationSchema={paymentValidationSchema} enableReinitialize>
                    {(formikProps) => (
                        <Form>
                            <FormSubmit
                                buttonText={showEditFormOther ? "Edit" : "Save"}
                                header={false}
                                onSubmit={formikProps.submitForm}
                                disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.values.payments}
                            >
                                {showEditFormOther ? (
                                    <PaymentPreferenceEdit payments={formikProps.values.payments} creditDays={formikProps.values.creditDays} itemCheckbox={formikProps.values.itemCheckbox} />
                                ) : (
                                    <PaymentPreference setFieldValue={formikProps.setFieldValue} values={formikProps.values} formik={formikProps} />
                                )}
                            </FormSubmit>
                        </Form>
                    )}
                </Formik>
            </div>
            {SnackBarComponent}
        </div>
    );
};

export default Preferences;
