import { createUseStyles } from "react-jss";
import uploadImageIcon from "../../../../assets/images/uploadImage.svg";
import { SCRAP_PRODUCTS, SCRAP_PRODUCTS_CATEGORY, SCRAP_PRODUCTS_SALES, SCRAP_PRODUCTS_TYPE } from "../../../../utils/constant";
import { useState } from "react";
import { FormikProps } from "formik";
import Button from "../../../atoms/Button/Button";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { ScrapProductsFormik } from "../../../pages/DashBoard/ScrapProductCreation.page";
import SelectV2 from "../../../atoms/Select/SelectV2";
import TextFieldV2 from "../../../atoms/Input/TextFieldV2";
import { UploadedImageData } from "../../../pages/DashBoard/FinishedProductCreation.page";
import ImageUploadModalTemplate from "../../OnBoarding/ImageUploadModal.template";
import filledPlusIcon from '../../../../assets/icons/filledPlus.svg'
import filledEditIcon from '../../../../assets/icons/filledEdit.svg'
import DisplayImage from "../../../molecules/DisplayImage/DisplayImage";
import { isNumber } from "lodash";

interface AddScrapProductTemplateProps {
    handleOnBack: () => void;
    formik: FormikProps<ScrapProductsFormik>;
    setSelectedFile: (file: (string | null)[]) => void | undefined;
    cardId: number;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
    },
    scrapProduct: {
        color: theme.palette.textV2.tertiary.tertiary700,
        borderBottom: `1px solid ${theme.palette.v4.border._secondaryDarkAccent}`,
    },
    uploadImageWrapper: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    selectContainer: {
        color: theme.palette.v3.text.primaryLight,
    },
    imagePreview: {
        width: "140px",
        height: "160px",
    },
    select: {
        color: theme.palette.textV2.primary.primary900,
        background: theme.palette.backgroundV2.tertiary.tertiary500,
        border: `1px solid ${theme.palette.v4.border._primaryDarkAccent}`,
        "& .MuiInputBase-input": {
            background: "none",
        },
        "& .MuiSvgIcon-root": {
            cursor: "pointer",
            marginRight: "4px",
        },
    },
    addButton: {
        "&.MuiButton-root": {
            border: "none",
        },
    },
    imageContainer: {
        width: '141px',
        height: '159px',
        borderRadius: '8px'
    },
    alphaBackground: {
        background: 'rgba(0,0,0,0.1)',
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary500}`
    }
}))

const AddScrapProductTemplate: React.FC<AddScrapProductTemplateProps> = ({ handleOnBack, formik, setSelectedFile, cardId }) => {

    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFileTo] = useState<File | null>(null);
    const [hoverId, setHoverIdTo] = useState<string | null>(null)
    const [selectedPreviewImagePath, setSelectedPreviewImagePath] = useState<string>("");
    const [imageDialogOpen, setImageDialogOpenTo] = useState(false);
    const [selectedIndex, setSelectedImageIndex] = useState<number | null>(null)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNumber(selectedIndex) && formik.values.images[selectedIndex] !== null && !(formik.values.images[selectedIndex] instanceof File)) {
            formik.setFieldValue('imageListToDelete', [...formik.values.imageListToDelete as number[], (formik.values.images[selectedIndex] as UploadedImageData)?.id])
        }
        formik.setFieldValue(`images[${selectedIndex}]`, selectedFile as File)
        setSelectedFileTo(null);
        setIsImageModalOpen(false);
    };

    const handleUpload = (index: number) => (e: any) => {
        e.stopPropagation();
        setSelectedFileTo(null);
        setSelectedImageIndex(index);
        setIsImageModalOpen(true);
    }

    const handleImageModalClose = () => {
        setIsImageModalOpen(false);
    };

    const handleImageView = (path: string) => () => {
        !imageDialogOpen && setSelectedPreviewImagePath(path);
        setImageDialogOpenTo(!imageDialogOpen);
    }

    return (
        <div className={`flex flex-col rounded-lg p-6 ${classes.container}`}>
            {SnackBarComponent}
            <span className={`text-2xl font-medium pb-8 ${classes.scrapProduct}`}>{SCRAP_PRODUCTS.ADD_NEW_SCRAP}</span>
            <div className={`pt-8 ${classes.uploadImageWrapper}`}>
                <span className="text-base font-medium">{SCRAP_PRODUCTS.UPLOAD_IMAGE}</span>
                <div className="flex gap-5 mt-3">
                    {Array(formik.values?.images?.length < 5 ? formik.values?.images?.length + 1 : 5).fill(null).map((_, index) => (
                        <div key={index}>
                            {index !== formik.values?.images?.length ?
                                <div className={`overflow-hidden relative ${classes.imageContainer}`} onMouseEnter={() => setHoverIdTo(`EDIT-${index}`)} onMouseLeave={() => setHoverIdTo(null)}>
                                    <img className={`rounded-lg cursor-pointer ${classes.imagePreview}`}
                                        src={formik.values?.images?.[index] instanceof File ?
                                            URL?.createObjectURL(formik.values?.images?.[index] as File)
                                            :
                                            (formik.values?.images?.[index] as UploadedImageData)?.path} alt="icon" onClick={handleUpload(index)} />
                                    {hoverId === `EDIT-${index}` && <span className={`${classes.alphaBackground} rounded-lg absolute top-0 right-0 w-full h-full z-0`}
                                        onClick={handleImageView(formik.values?.images?.[index] instanceof File ? URL?.createObjectURL(formik.values?.images?.[index] as File) :
                                            (formik.values?.images?.[index] as UploadedImageData)?.path)}>
                                        <img src={filledEditIcon} alt="" onClick={handleUpload(index)} className="absolute top-0 right-0 mt-2 mr-2 z-10 cursor-pointer" />
                                    </span>}
                                </div>
                                : (
                                    <div className={`relative `} onMouseEnter={() => setHoverIdTo(`ADD-${index}`)} onMouseLeave={() => setHoverIdTo(null)}>
                                        <img src={uploadImageIcon} alt="" className="z-0 h-full w-full" />
                                        {hoverId === `ADD-${index}` && <span className={`${classes.alphaBackground} rounded-lg absolute top-0 right-0 w-full h-full`}>
                                            <img src={filledPlusIcon} alt="" onClick={handleUpload(index)} className="absolute top-0 right-0 mt-2 mr-2 z-10 cursor-pointer" />
                                        </span>}
                                    </div>
                                )}
                            {
                                <ImageUploadModalTemplate
                                    dialogOpen={isImageModalOpen}
                                    setDialogOpen={handleImageModalClose}
                                    onSubmit={handleFileChange}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFileTo}
                                />}
                        </div>
                    ))}
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-4 mt-8 gap-4">

                    <SelectV2
                        {...formik.getFieldProps("category")}
                        label="Scrap Category"
                        placeholder="Select nature of Scrap"
                        value={formik.values.category}
                        fullWidth
                        options={SCRAP_PRODUCTS_CATEGORY}
                        error={formik.touched.category && Boolean(formik.errors.category)}
                        helperText={formik.errors?.category}
                    />

                    <SelectV2
                        {...formik.getFieldProps("type")}
                        label="Type of Scrap"
                        placeholder="Select scrap type"
                        options={SCRAP_PRODUCTS_TYPE}
                        error={formik.touched.type && Boolean(formik.errors.type)}
                        helperText={formik.errors?.type}
                    />

                    <TextFieldV2
                        {...formik.getFieldProps("quantity")}
                        label="Monthly generated qty (MT)"
                        placeholder="Enter quantity"
                        error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                        helperText={formik.errors?.quantity}
                    />

                    <SelectV2
                        {...formik.getFieldProps("sales")}
                        label="Current Sales Mode"
                        placeholder="Select current sales mode"
                        options={SCRAP_PRODUCTS_SALES}
                        error={formik.touched.sales && Boolean(formik.errors.sales)}
                        helperText={formik.errors?.sales}
                    />
                </div>
                <div className="flex justify-end mt-8 gap-x-4">
                    <Button label="Back" variant="tertiaryContained" onClick={handleOnBack} />
                    <Button type="submit" label="Add" variant="primaryContained" className={classes.addButton} isLoading={formik.isSubmitting}/>
                </div>
            </form>
            <DisplayImage path={selectedPreviewImagePath} imageDialogOpen={imageDialogOpen} setImageDialogOpenTo={setImageDialogOpenTo} />
        </div>
    )
}

export default AddScrapProductTemplate;