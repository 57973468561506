import React from "react";
import { createUseStyles } from "react-jss";
import Grid from '@mui/material/Grid';
import { IGridTemplate } from "../../organism/GridView/GridView.organism";
import { formatUOM, formatUOMv2 } from "../../../utils/helper";
import { ATTRIBUTE_LABEL, COLOR_DATA } from "../../../utils/constant";
import { IColorPalette } from "../Catalogue/AttributeSelection.template";

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.primaryLight,
    },
    subText: {
        color: theme.palette.text.secondaryDarkAccent,
    },
    container: {

    },
    "@media (max-width: 476px)": {
        container: {
            display: "grid",
            padding: "12px",
            background: theme.palette.background.primarylightAccent,
            borderRadius: "6px"
        },
        title: {
            color: theme.palette.text.primaryLight,
            textAlign: "center",
            fontSize: "12px"
        },
        subText: {
            color: theme.palette.text.secondaryDarkAccent,
            textAlign: "center"
        },
    },
}));

interface SecondaryProductItemTemplate {
    data: any;
    gridTemplate: IGridTemplate;
}
const SecondaryLotProductTemplate: React.FC<SecondaryProductItemTemplate> = ({ data, gridTemplate }) => {

    const classes = useStyles();

    const getAttributeValue = (attributeName?: string, attributeValue?: string) => {
        try {
          if (attributeValue === undefined || attributeValue === '') {
            return '';
          }
          if (attributeName && attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
            return attributeValue;
          }
            const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-4 h-3 mr-1 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
    }
    
    return (
        <div className={classes.container}>
            <Grid container spacing={2}>
                {Object.keys(data).map((label, index) => (
                    <Grid item xs={gridTemplate.xs} sm={gridTemplate.sm} md={gridTemplate.md} lg={gridTemplate.lg} xl={gridTemplate.xl} key={label}>
                        <div className="grid gap-y-1">
                            <div className={`${classes.title} text-sm font-normal`}>{label}</div>
                            <div className={`${classes.subText} text-xs font-medium`}>
                            {/* {data[label] && Array.isArray(data[label]) && data[label].length >= 3 ? (
                             data[label][1] && data[label][2] ? 
                             formatUOM(data[label][1], data[label][2], data[label][0] === data[label][1] 
                                                                        ? `${data[label][0]}` : 
                                                                          `${data[label][0]} - ${data[label][1]}`) : 
                                   data[label][0]) :
                                   data[label]} */}

                            {data[label] && Array.isArray(data[label]) && data[label].length >= 3 ? (
                                <div>
                                    {data[label][0] !== data[label][1] ? (
                                        <>
                                            {getAttributeValue(label, data[label][0])} - {getAttributeValue(label, data[label][1])} {formatUOMv2(data[label][0], data[label][2], '')}
                                        </>
                                    ) : (
                                        <>
                                            {getAttributeValue(label, data[label][0])} {formatUOMv2(data[label][0], data[label][2], '')}
                                        </>
                                    )}
                                </div>
                            ) : (
                                getAttributeValue(label, data[label])
                            )}
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default SecondaryLotProductTemplate;