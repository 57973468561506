import React from 'react';
import ProductCatalogueViewTemplate from '../../templates/VendorManagement/ProductCatalogueView.template';


const PostProductViewPage = () => {


    return (
        <div>
            <ProductCatalogueViewTemplate/>
        </div>
    )
}

export default PostProductViewPage;
