import * as React from 'react';
import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { FormikErrors, useFormik } from 'formik';
import { HTTP_STATUS } from '../../../utils/types';
import SelectV2 from '../../atoms/Select/SelectV2';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
    },
    container_heading: {
        color: theme.palette.text.secondaryDark,
    },
}));

export interface ITurnOver {
    year: string;
    amount: number;
}

export interface IFinance {
    turnOvers: ITurnOver[];
}

interface TurnOverEditTemplateProps {
    updateBusinessProfileSection: any;
    businessProfile: any;
    onSuccess: (updatedProfile: any) => void;
    onBack: () => void;
}

const TurnOverEditTemplate: React.FC<TurnOverEditTemplateProps> = ({ updateBusinessProfileSection, businessProfile, onSuccess, onBack }) => {
    const classes = useStyles();
    const businessProfileService = useBusinessProfileService();
    const { showSnackbar } = useSnackbar();

    const turnOverSchema = yup.array().of(
        yup.object().shape({
            year: yup.string().required('Year is required'),
            amount: yup.number().required('Amount is required').positive(),
        })
    );

    const generateYearAmountObjects = (numberOfYears: number): { year: string, amount: number }[] => {
        const currentYear = new Date().getFullYear();
        const yearAmountObjects = [];
        for (let i = 0; i < numberOfYears; i++) {
            yearAmountObjects.push({ year: `${currentYear - i - 1}-${currentYear - i}`, amount: 0 });
        }
        return yearAmountObjects;
    };

    const initialTurnOvers = businessProfile?.finance?.turnOvers || generateYearAmountObjects(3);

    const turnoverformik = useFormik<IFinance>({
        initialValues: {
            turnOvers: initialTurnOvers,
        },
        validationSchema: turnOverSchema,
        onSubmit: async (values: any) => {
            try {
                turnoverformik.setSubmitting(true);
                const requestBody = {
                    turnOvers: values?.turnOvers
                }
                const response = await businessProfileService.updateBusinessProfile(requestBody, "TURN_OVERS");
                if (response?.status === HTTP_STATUS.OK) {
                    showSnackbar("success", "Business profile updated successfully");
                    onBack();
                }
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                turnoverformik.setSubmitting(false);
            }
        },
    });

    const handleSubmit = () => {
        turnoverformik.handleSubmit();
        onBack();
    };

    const handleUpdateDetails = (field: string, value: any) => {
        turnoverformik.setFieldValue(field, value);
    };

    const amountOptions = [
        { value: "Less than 40 Lakhs", label: "Less than 40 Lakhs" },
        { value: "40 L - 1 CR", label: "40 L - 1 CR" },
        { value: "1-5 CR", label: "1-5 CR" },
        { value: "5-10 CR", label: "5-10 CR" },
        { value: "10-20 CR", label: "10-20 CR" },
        { value: "20-50 CR", label: "20-50 CR" },
        { value: "50-100 CR", label: "50-100 CR" },
        { value: "100+ CR", label: "100+ CR" },
    ];

    const handleBack = () => {
        onBack();
    };

    return (
        <form onSubmit={turnoverformik.handleSubmit}>
            <div className={`${classes.container} p-6 w-full rounded-lg`}>
                <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                    <h2 className={`${classes.container_heading} text-2xl font-medium`}>Turn over Details</h2>
                    <div className='flex gap-4'>
                        <Button variant={'tertiaryContained'} label={'Back'} type="button" onClick={handleBack} />
                        <Button variant={'primaryContained'} label={'Save'} type="submit" onClick={handleSubmit} />
                    </div>
                </div>
                <div className='grid grid-cols-3 gap-x-6 pt-6 w-full '>
                    {turnoverformik?.values?.turnOvers?.map((turnOver, index) => (
                            <div className='grid gap-y-1 w-full' key={index}>
                                   <SelectV2
                                        label={`FY ${turnOver.year}`}
                                        fullWidth
                                        {...turnoverformik.getFieldProps(`turnOvers[${index}].amount`)}
                                        error={
                                            turnoverformik.touched?.turnOvers?.[index]?.amount &&
                                            Boolean((turnoverformik?.errors?.turnOvers as FormikErrors<ITurnOver>[])?.[index]?.amount)
                                        }
                                        options={amountOptions.map((option) => ({
                                            label: option.label,
                                            value: option.value
                                        }))}
                                    />
                                {turnoverformik?.touched?.turnOvers && turnoverformik?.touched?.turnOvers[index] && turnoverformik?.errors?.turnOvers && (
                                    <div>
                                        <small>{(turnoverformik?.errors?.turnOvers as { [key: string]: number }[])?.[index]?.amount}</small>
                                    </div>
                                )}
                            </div>
                    ))}
                </div>
            </div>
        </form>
    );
};

export default TurnOverEditTemplate;
