import React, { useEffect, useState } from 'react';
import { CART_SECTIONS } from '../../../utils/types';
import { createUseStyles } from 'react-jss';
import { PAYMENT_METHODS, PAYMENT_TYPES } from '../../../utils/constant';
import CartPriceDetailsTemplate from './CartPriceDetails.template';
import { ICartItem, IContext } from './CartItemDetail.template';
import Button from "../../atoms/Button/Button";
import phoneCall from "./../../../assets/images/phoneCall.svg";
import CartContactUsPop from '../../molecules/CartContactUs/CartContactUsPop';
import { Radio } from "@mui/material";
import RadioActive from '../../../assets/images/radioActive.svg';
import RadioInActive from '../../../assets/images/radioInactive.svg'

interface PaymentTermsProps {
    setCurrentSectionTo: (section: CART_SECTIONS) => void;
    onSelectPaymentTerm: (term: string) => void;
    cartItemDetails: ICartItem[] | [];
    configuration: IContext;
    handleRfq: () => void;
    paymentMethod: string;
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
		paddingLeft: "70px",
		paddingRight: "70px",
	},
    leftContainer: {
        width: "67.31%",
    },
    subtitle: {
        color: theme.palette.textV2.primary.primary950,
    },
    paymentTerms: {
        color: theme.palette.textV2.primary.primary950,
    },
    cardContainer: {
        color: theme.palette.textV2.primary.primary950,
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    rightContainer: {
        width: "30.92%",
        display: "flex",
    },
    rightChildContainer: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        boxShadow: "0px 7px 18px 0px rgba(29, 54, 121, 0.12)",
    },
    phoneButton: {
        padding: "14px",
        backgroundColor: theme.palette.backgroundV2.primary.primary100,
    },
    rightMobileContainer: {
        display: "none"
    },
    "@media (max-width: 480px)": {
        leftContainer: {
            width: "100%",
        },
        rightContainer: {
            display: "none",
        },
        rightMobileContainer: {
            display: "grid",
            border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        },
        mainContainer: {
            paddingLeft: "16px",
            paddingRight: "16px",
        },
        phoneButton: {
            padding: "10px",
        },
    },
}));

const CartPaymentDetailTemplate: React.FC<PaymentTermsProps> = ({ setCurrentSectionTo, onSelectPaymentTerm, cartItemDetails, configuration, handleRfq, paymentMethod }) => {
    const [paymentTerm, selectedPaymentTerm] = useState<string>(PAYMENT_METHODS.CASH_PAYMENT);
    const classes = useStyles();
    const [phoneCallStatus, setPhoneCall] = useState<boolean>(false);

    useEffect(() => {
        selectedPaymentTerm(paymentMethod);
    }, [paymentMethod])

    const handleContactIconClick = () => {
        setPhoneCall(!phoneCallStatus);
    };

    const handleSelectTerm = (term: string) => {
        selectedPaymentTerm(term);
        onSelectPaymentTerm(term);
    };

    return (
        <>
            <div className={`${classes.mainContainer} flex gap-x-6`}>
                <div className={`${classes.leftContainer} flex flex-col gap-3`}>
                    <div className={`${classes.subtitle} text-2xl font-medium mb-2`}>{`My Cart (${cartItemDetails.length} Items)`}</div>
                    <div className={`${classes.paymentTerms} text-lg font-medium mb-1 `}> Payment Terms</div>
                    {PAYMENT_TYPES.map((term) => (
                        <div className={`${classes.cardContainer} text-base font-normal flex p-6 rounded-xl`}>
                            <Radio
                                icon={<img src={RadioInActive} alt="icon" />} 
                                checkedIcon={<img src={RadioActive} alt="icon" />}
                                sx={{ padding: 0, marginRight: 1 }}
                                name="paymentTerms"
                                value={term.value}
                                checked={paymentTerm === term.value}
                                onChange={() => handleSelectTerm(term.value)}
                            />
                            <div key={term.value} >
                                {term.label}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`${classes.rightContainer}`}>
                    <div className={`z-50 rounded-2xl sticky top-0 ${classes.rightChildContainer}`}>
                        {cartItemDetails && cartItemDetails.length > 0 && (
                            <CartPriceDetailsTemplate
                                data={cartItemDetails}
                                configuration={configuration}
                                handleRfq={handleRfq}
                            />
                        )}
                        <div className="flex justify-between gap-x-2 mx-4 mb-4 bg-white">
                            <Button className='flex-1 !h-12' variant="primaryContained" size="large" label="Review" onClick={() => setCurrentSectionTo(CART_SECTIONS.CART_REVIEW)} />
                            <div className={`rounded-lg cursor-pointer  ${classes.phoneButton}`} onClick={handleContactIconClick}>
                                <img src={phoneCall} alt="call" />
                            </div>
                        </div>
                        {phoneCallStatus && <CartContactUsPop />}
                    </div>
                </div>
            </div>
           <div className={` fixed bottom-0 z-50 bg-white !pl-0 ${classes.rightMobileContainer} w-full`}>
                {cartItemDetails && cartItemDetails.length > 0 && (
                    <CartPriceDetailsTemplate
                        data={cartItemDetails}
                        configuration={configuration}
                        handleRfq={handleRfq}
                    />
                )}
                <div className="flex justify-between gap-x-2 mx-4 mb-4 bg-white">
                    <Button className='flex-1' variant="primaryContained" size="small" label="Review" onClick={() => setCurrentSectionTo(CART_SECTIONS.CART_REVIEW)} />
                    <div className={`rounded-lg cursor-pointer  ${classes.phoneButton}`} onClick={handleContactIconClick}>
                        <img className='w-3.5' src={phoneCall} alt="call" />
                    </div>
                </div>
                {phoneCallStatus && <CartContactUsPop />}
            </div>
        </>
    );
};

export default CartPaymentDetailTemplate;
