import React, { useEffect, useState } from 'react'
import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import { IValueAddedServices } from '../../../pages/Cart/CartDetails.page';
import { CUSTOMIZTION_TYPE, VAS_SECTIONS } from '../../../../utils/constant';
import Button from '../../../atoms/Button/Button';
import CloseIcon from '@mui/icons-material/Close';
import ShearingCustomizationPopup from '../../Catalogue/Customization/ShearingCustomizationPopup';
import SlittingCustomizationPopup from '../../Catalogue/Customization/SlittingCustomizationPopup';
import { ICatalogueUpc } from '../../../pages/Catalogue/CatalogueDetails.page';
import { ICustomization } from '../../Catalogue/Customization/SelectCustomization.template';

const useStyles = createUseStyles((theme: any) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "983px !important",
            maxWidth: "983px !important",
            borderRadius: "12px"
        },
    },
    mainContainer: {
        padding: "24px 0px",
        rowGap: "32px"
    },
    headingContainer: {
        padding: "0px 24px",
    },
    mainHeading: {
        color: theme.palette.textV2.primary.primary900, //"#331400"
        fontSize: "24px",
        fontWeight: 600
    },
    closeIcon: {
        color: theme.palette.textV2.secondary.secondary500
    },
    container: {
        padding: "20px 16px",
        border: "1px solid #D2D7E4"
    },
    cardContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        columnGap: "12px",
        padding: "0px 24px"
    },
    cardHeading: {
        color: theme.palette.v2.text.primaryDark,
    },
    cardDescription: {
        color: theme.palette.v4.text.secondaryLightAccent,
        lineHeight: "19px",
        letterSpacing: "0.014px"
    },
    header: {
        color: theme.palette.v3.text._secondaryLight
    },
    button:{
        padding: "0px 24px"
    },
    "@media (max-width: 480px)": {
        dialogContainer: {
            "& .MuiDialog-paper": {
                width: "448px !important",
                maxWidth: "448px !important",
                borderRadius: "12px"
            },
        },
        cardContainer: {
            display: "grid",
            gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
            columnGap: "0px",
            rowGap: "12px",
            padding: "0px 16px"
        },
        mainHeading: {
            fontSize: "18px",
            fontWeight: 500
        },
        mainContainer: {
            padding: "16px 0px",
            rowGap: "24px"
        },
        headingContainer: {
            padding: "0px 16px",
            borderBottom: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            paddingBottom: "16px"
        },
        button:{
            padding: "0px 16px"
        },
    },
}));

interface AddAnotherServiceProps {
    anotherServiceDialogOpen: boolean
    setAnotherServiceDialogOpenTo: (isOpen: boolean) => void;
    valueAddedServices: IValueAddedServices | null;
    setValueAddedServicesTo: any;
    setCurrentSectionTo: (section: string) => void;
    selectedUpc: ICatalogueUpc | null;
    quantity: number;
    balanceWeight: number | null;
    setBalanceWeight: (weight: number) => void;
    customization: ICustomization[];
    availableCustomizations: ICustomization[];
}

interface CustomizationItem {
    text: string;
    description: string;
    label: string;
}

const vasConstants = {
    slittingText: "SLITTING",
    shearingText: "SHEARING",
};


const AddAnotherServiceTemplate: React.FC<AddAnotherServiceProps> = ({ anotherServiceDialogOpen, setAnotherServiceDialogOpenTo, valueAddedServices, setValueAddedServicesTo, setCurrentSectionTo, selectedUpc, quantity, balanceWeight, setBalanceWeight, customization, availableCustomizations }) => {
    const classes = useStyles();
    const [dialogStates, setDialogStatesTo] = useState({ shearingDialog: false, slittingDialog: false });
    const [editState, setEditState] = useState<boolean>(false);


    const handleDialogClose = () => {
        setAnotherServiceDialogOpenTo(false);
        setCurrentSectionTo(VAS_SECTIONS.VAS_DETAILS);
    };

    const handleGoBack = () => {
        setCurrentSectionTo(VAS_SECTIONS.VAS_DETAILS);
    }

    const handleSelectCustomization = (item: string) => {
        if (item == vasConstants.slittingText) {
            setDialogStatesTo(prevState => ({ ...prevState, slittingDialog: true }));
        }
        else if (item == vasConstants.shearingText) {
            setDialogStatesTo(prevState => ({ ...prevState, shearingDialog: true }));
        }
    }

    useEffect(() => {
        if ((valueAddedServices?.shearing?.length ?? 0) > 0 && (valueAddedServices?.slitting?.length ?? 0) > 0) {
            setCurrentSectionTo(VAS_SECTIONS.VAS_DETAILS);
        }
    }, [valueAddedServices]);

    const [shearingCustomization, setShearingCustomization] = useState<ICustomization>(
        {
            uom: "MT",
            name: "Shearing (CTL)",
            price: 0,
            value: "SHEARING",
            discription: "Cutting large sheets of steel into smaller, manageable sizes.",
            minLength: 0,
            maxLength: Infinity,
            minThickness: 0,
            maxThickness: Infinity,
            minWidth: 0,
            maxWidth: Infinity,
        }
    );
    const [slittingCustomization, setSlittingCustomization] = useState<ICustomization>(

        {
            uom: "MT",
            name: "Slitting (CTW)",
            price: 0,
            value: "SLITTING",
            discription: "Longitudinally cutting into narrower strips with uniform width.",
            minLength: 0,
            maxLength: Infinity,
            minThickness: 0,
            maxThickness: Infinity,
            minWidth: 0,
            maxWidth: Infinity,
        }

    );

    useEffect(() => {
        if (customization != null) {
            const newShearingCustomization = customization?.find((attr: any) => attr.value === CUSTOMIZTION_TYPE.SHEARING);
            if (newShearingCustomization) {
                setShearingCustomization(prevState => ({
                    ...prevState,
                    ...newShearingCustomization
                }));
            }
            const newSlittingCustomization = customization?.find((attr: any) => attr.value === CUSTOMIZTION_TYPE.SLITTING);
            if (newShearingCustomization) {
                setSlittingCustomization(prevState => ({
                    ...prevState,
                    ...newSlittingCustomization
                }));
            }
        }
    }, [customization]);



    const isButtonDisabled = (item: any) => {
        if (selectedUpc?.attributes?.SPECIFICATION !== undefined) {
            const thicknessSpec = selectedUpc?.attributes.SPECIFICATION.find(attr => attr.name.trim().toLowerCase() === 'thickness')?.attributeOptions;
            const thickness = thicknessSpec && typeof thicknessSpec === 'string' ? parseFloat(thicknessSpec) : NaN;

            const lengthSpec = selectedUpc?.attributes.SPECIFICATION.find(attr => attr.name.trim().toLowerCase() === 'length')?.attributeOptions;
            const length = lengthSpec && typeof lengthSpec === 'string' ? parseFloat(lengthSpec) : NaN;

            const widthSpec = selectedUpc?.attributes.SPECIFICATION.find(attr => attr.name.trim().toLowerCase() === 'width')?.attributeOptions;
            const width = widthSpec && typeof widthSpec === 'string' ? parseFloat(widthSpec) : NaN;
            if (item.value === CUSTOMIZTION_TYPE.SHEARING && shearingCustomization) {
                if (lengthSpec === "COIL") {
                    return thickness === null || length === null || width === null ||
                        thickness < (shearingCustomization?.minThickness) || thickness > (shearingCustomization?.maxThickness) ||
                        width < (shearingCustomization?.minWidth) || width > (shearingCustomization?.maxWidth) ||
                        isNaN(quantity) || quantity <= 0;
                } else {
                    return thickness === null || length === null || width === null ||
                        thickness < (shearingCustomization?.minThickness) || thickness > (shearingCustomization?.maxThickness) ||
                        length < (shearingCustomization?.minLength) || length > (shearingCustomization?.maxLength) ||
                        width < (shearingCustomization?.minWidth) || width > (shearingCustomization?.maxWidth) ||
                        isNaN(quantity) || quantity <= 0;
                }
            } else if (item.value === CUSTOMIZTION_TYPE.SLITTING && slittingCustomization) {
                return thickness === null || width === null ||
                    thickness < (slittingCustomization?.minThickness) || thickness > (slittingCustomization?.maxThickness) ||
                    width < (slittingCustomization?.minWidth) || width > (slittingCustomization?.maxWidth) ||
                    lengthSpec !== "COIL" ||
                    (isNaN(quantity) || quantity <= 0);
            } else if (item.value === CUSTOMIZTION_TYPE.BLANKING) {
                return true;
            } else if (item.value === CUSTOMIZTION_TYPE.METAL_OFFSET_PRINTING) {
                return true;
            }
        } else if (selectedUpc?.attributes?.SPECIFICATION === undefined) {
            return true
        }
        return false;
    }

    return (
        <Dialog fullWidth className={classes.dialogContainer} PaperProps={{ style: { height: 'auto' } }} open={anotherServiceDialogOpen} onClose={handleDialogClose}>
            <div className={`${classes.mainContainer} grid`}>
                <div className={`${classes.headingContainer} flex justify-between`}>
                    <div className={`${classes.mainHeading} text-2xl font-medium `}>Value Added Services</div>
                    <CloseIcon className={`${classes.closeIcon} cursor-pointer`} onClick={() => handleDialogClose()} />
                </div>

                <div className={`${classes.cardContainer}`}>
                    {availableCustomizations.map((item, index) => {
                        return (
                            <div key={index} className={`${classes.container} flex flex-col gap-y-3 rounded-xl`}>
                                <div>
                                    <span className={`${classes.cardHeading} text-lg font-semibold`}>{item.name}</span>
                                    <p className={`${classes.cardDescription} text-sm font-normal`}>{item.discription}</p>
                                </div>
                                <div>
                                    <Button
                                        className={`!px-3 leading-none`}
                                        variant="tertiaryContained"
                                        label="Select"
                                        size="large"
                                        onClick={() => handleSelectCustomization(item.value)}
                                        disabled={isButtonDisabled(item)}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className={`${classes.button} flex justify-end`}>
                    <Button
                        variant="tertiaryContained"
                        label="Go Back"
                        size="medium"
                        onClick={handleGoBack}
                        disabled={false}
                    />
                </div>
                {dialogStates.shearingDialog && <ShearingCustomizationPopup
                    dialogOpen={dialogStates.shearingDialog}
                    setDialogOpen={(value) => setDialogStatesTo(prevState => ({ ...prevState, shearingDialog: false }))}
                    selectedUpc={selectedUpc}
                    selectedWeight={quantity}
                    customization={shearingCustomization}
                    valueAddedService={valueAddedServices}
                    setValueAddedServiceTo={setValueAddedServicesTo}
                    balanceWeight={balanceWeight}
                    setBalanceWeight={setBalanceWeight}

                />
                }
                {dialogStates.slittingDialog && <SlittingCustomizationPopup
                    dialogOpen={dialogStates.slittingDialog}
                    setDialogOpen={(value) => setDialogStatesTo(prevState => ({ ...prevState, slittingDialog: false }))}
                    selectedUpc={selectedUpc}
                    selectedWeight={quantity}
                    customization={slittingCustomization}
                    valueAddedService={valueAddedServices}
                    setValueAddedServiceTo={setValueAddedServicesTo}
                    balanceWeight={balanceWeight}
                    setBalanceWeight={setBalanceWeight}
                    editState={editState}
                    setEditState={setEditState}
                />}
            </div>
        </Dialog>
    )
}
export default AddAnotherServiceTemplate; 