import { POLICY_SECTION } from "../../../../utils/constant";

interface GrievanceOfficerAndContactInformationProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
}

const GrievanceOfficerAndContactInformation: React.FC<GrievanceOfficerAndContactInformationProps> = ({ contentStyle, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{POLICY_SECTION.GRIEVANCE_OFFICER_AND_CONTACT_INFORMATION}</span>
            </div>
            <div className={contentStyle}>
                <p>Users can access, modify, correct, and eliminate their collected data. For grievances, complaints, or concerns,
                    Users can contact the grievance officer through telephonic or electronic means.
                </p>
                <br />
            </div>
        </>
    )
}
export default GrievanceOfficerAndContactInformation;