import React from "react";
import { createUseStyles } from "react-jss";
import { Grid } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import Button from "../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_ROUTES } from "../../../utils/constant";
import noImage from "../../../assets/images/noImage.jpg"
import { formatUOM } from "../../../utils/helper";

interface ListItemProps {
  data: any;
  favoriteItemIds?: number[];
  setFavoriteItemIds?: (prevIds: (prevIds: number[]) => number[]) => void;
}

const useStyles = createUseStyles((theme: any) => ({
  container: {
    border: `1px solid ${theme.palette.border.primaryLight}`,
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: `0px 0px 24px 0px ${theme.palette.boxShadow.primaryDark}`,
    },
  },
  imageContainer: {
    width: "27%",
  },
  detailsContainer: {
    width: "73%",
  },
  productTitle: {
    color: theme.palette.text.primaryDark,
  },
  companyTitle: {
    color: theme.palette.text.secondaryLight,
  },
  iconContainer: {
    background: theme.palette.border.secondaryLight,
  },
  property: {
    color: theme.palette.text.secondaryLightAccent,
  },
  value: {
    color: theme.palette.text.secondaryDarkAccent,
  },
}));

const CatalogueListItem: React.FC<ListItemProps> = ({
  data,
  favoriteItemIds,
  setFavoriteItemIds,
}) => {
  const classes = useStyles();
  const navigate =useNavigate();
  const onViewCatalogue = (event: any) => {
    event.stopPropagation();
    if(data.classType === "STANDARD" || data.classType === "NONSTANDARD"){
        navigate(`${CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION}?catalogueId=${data.id}`);
    }else if(data.classType === "SECONDARY"){
        navigate(`${CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION}?secondaryCatalogueId=${data.id}`);
    }
}
  // *********************************TODO- To Be Refactored and API will be made************************************ */
  // const isFavorited = favoriteItemIds.includes(data.id);
  // const handleFavoriteClick = (itemId: number) => () => {
  //   setFavoriteItemIds((prevIds: number[]) => {
  //     if (prevIds.includes(itemId)) {
  //       return prevIds.filter((id) => id !== itemId);
  //     } else {
  //       return [...prevIds, itemId];
  //     }
  //   });
  // };
  // *********************************TODO- To Be Refactored and API will be made************************************ */

  return (
    <div
      className={ `${ classes.container } rounded-lg bg-white flex h-60` }
      key={ data } onClick={onViewCatalogue}
    >
      <div className={ `${ classes.imageContainer } relative` }>
        <img
          className="w-full h-full rounded-l-lg object-cover"
          src={ data.catalogueImagePath[0] || noImage }
          alt="CatalogueImage"
        />
        <div
          className={ `flex gap-x-2 pt-1.5 px-1.5 justify-end absolute top-0 right-0` }
        >
          {/* <div
            className={`${classes.iconContainer} rounded flex gap-x-2 p-1.5`}
          >
            <ShareIcon sx={{ fontSize: 30 }} />

            {isFavorited ? (
              <FavoriteIcon
                className=" text-red-600"
                sx={{ fontSize: 30 }}
                onClick={handleFavoriteClick(data.id)}
              />
            ) : (
              <FavoriteBorderIcon
                sx={{ fontSize: 30 }}
                onClick={handleFavoriteClick(data.id)}
              />
            )}
          </div> */}
        </div>
      </div>
      <div className={ `${ classes.detailsContainer }  grid gap-y-3 py-2 px-6` }>
        <div className="grid gap-x-2 pb-4 border-b-2 border-solid">
          <div className={ `${ classes.productTitle } text-base font-semibold` }>
            { data.name }
          </div>
          <div className={ `${ classes.companyTitle } text-xs font-medium` }>
            { data.brand }
          </div>
        </div>
        <div className="grid gap-y-4">
          <div className="flex gap-x-8 w-4/5">
            <Grid container spacing={ 1 }>
              {data?.attributes && Object.entries(data?.attributes).map(([key, value]) => {
                const [min, max, uom] = value as string[];
                const isRangeZero = min === max ? true : false;
                return (
                  <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                    <div className="flex justify-between gap-x-1">
                      <div
                        className={ `${ classes.property } text-xs font-normal w-1/2` }
                      >
                        { key }
                      </div>
                      <div
                        className={ `${ classes.value } text-sm font-medium text-start w-1/2` }
                      >
                       {formatUOM(min, uom, isRangeZero ? (min) : `${min}-${max}`)}     
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <div className="w-60">
            <Button
              fullWidth
              variant="containedLight"
              label="Get a best price"
              onClick={onViewCatalogue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogueListItem;
