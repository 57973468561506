import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ProductTabs, tabprops } from './ProductTabs.Template';

const ColorCoatedTemplate: React.FC = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (

        <>
            <section className={classes.produts_banner}>
                <div className={`${classes.products_bannner_wrapper} ${classes.color_coated_banner}`}>
                    <div className={classes.products_banner_content}>
                        <h4>Products</h4>
                        <h3>Color Coated Steel</h3>
                        <h1>Weather-Resistant Brilliance, Cost-Effective Excellence</h1>
                        {/* <!-- <div className="banner_btn">
                    <a href="#">Learn More</a>
                </div> --> */}
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className={`${classes.products_about_us} ${classes.card_sp}`}>
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>About Color Coated</h2>
                            <p className={classes.gal_card_des}>Coil coating involves applying decorative and protective organic coatings to
                                pre-painted steel substrates. The sheets may have either galvanized or galvalume coatings.
                                Coated steel sheets find applications in diverse industries and offer numerous benefits,
                                including reduced costs, improved quality, shorter processing times, and energy conservation.
                                They play a crucial role in the construction sector, serving as materials for exterior,
                                interior, and structural components.</p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/ColorCoated/about.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- specification range section starts here  --> */}

            <section className={`${classes.specification_section} ${classes.section_strip}`}>
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading_main}>Specifications range</h3>
                    <div className={classes.card_parent_wrap}>
                        <div className={classes.p_card_wrapper}>
                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg6.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Thickness Ranges</span>
                                        <span className={classes.p_card_s_text}>0.25~2.00 mm (±0.003mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg2.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Width Range</span>
                                        <span className={classes.p_card_s_text}>50~1250mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg3.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Length Range</span>
                                        <span className={classes.p_card_s_text}>600-3000 mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg4.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Outer Diameter</span>
                                        <span className={classes.p_card_s_text}>1500-2200 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg5.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Inner Diameter</span>
                                        <span className={classes.p_card_s_text}>510, 600, 610 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg1.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Coil Weight</span>
                                        <span className={classes.p_card_s_text}>3-20 tonnes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="specification_section section_strip seconday_cards_wrap section_strip_main">
                <div className="specification_section_wrapper container">
                    <h3 className="section_heading_main cards_heading">Advantages of color coated</h3>
                    <div className="p_card_wrapper card_wrapper_mobile">
                        <div className="card_main cards_secondary mobile_cards">
                            <div className="p_card_preview mobile_preview">
                                <img src="../assets/images/ColorCoated/water.webp" alt="" />
                                <span>Weather Resistant</span>
                            </div>
                        </div>
                        <div className="card_main cards_secondary mobile_cards">
                            <div className="p_card_preview mobile_preview">
                                <img src="../assets/images/ColorCoated/zFoldLeaflet.webp" alt="" />
                                <span>Flexibility</span>
                            </div>
                        </div>
                        <div className="card_main cards_secondary mobile_cards">
                            <div className="p_card_preview mobile_preview">
                                <img src="../assets/images/ColorCoated/weight.webp" alt="" />
                                <span>Lightweight</span>
                            </div>
                        </div>

                        <div className="card_main cards_secondary mobile_cards">
                            <div className="p_card_preview mobile_preview">
                                <img src="../assets/images/ColorCoated/corrosion.webp" alt="" />
                                <span>Corrosion Resistant</span>
                            </div>
                        </div>

                        <div className="card_main cards_secondary mobile_cards">
                            <div className="p_card_preview mobile_preview">
                                <img src="../assets/images/ColorCoated/motionDetector.webp" alt="" />
                                <span>Thermal Insulation</span>
                            </div>
                        </div>
                        <div className="card_main cards_secondary mobile_cards">
                            <div className="p_card_preview mobile_preview">
                                <img src="../assets/images/ColorCoated/dumbbell.webp" alt="" />
                                <span>Durable</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* <!-- BIS Standards in Cold Rolled Steel section starts here --> */}

            <section className={classes.bis_section}>
                <div className={`${classes.bis_section_wrapper} ${classes.container}`}>
                    <h3>BIS Standards for Tin Mill Black Plate</h3>
                    <div className={classes.bis_content_wrapper}>

                        <div className={classes.bis_content}>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>

                            <div className={`${classes.bis_content_main} ${classes.last_row}`}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.bis_content}>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>
                            <div className={`${classes.bis_content_main} ${classes.last_row}`}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Table section starts here  --> */}
            <section className={`${classes.grades_section} ${classes.section_space}`}>
                <div className={`${classes.container} ${classes.grades_section_wrapper}`}>
                    <h3>Grades as per BIS Standards</h3>
                    <div className={classes.grades_table}>
                        <div className={classes.grades_table_wrapper}>
                            <div className={classes.details_table}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>JSW GRADES</th>
                                            <th>TATA GRADES</th>
                                            <th>SAIL GRADES</th>
                                            <th>AMNS GRADE</th>
                                            <th>BIS GRADES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>CQ</td>
                                            <td>CQ</td>
                                            <td>CR1</td>
                                            <td>CR1</td>
                                            <td>CR1</td>
                                        </tr>
                                        <tr>
                                            <td>02</td>
                                            <td>DQ</td>
                                            <td>DQ</td>
                                            <td>CR2</td>
                                            <td>CR2</td>
                                            <td>CR2</td>
                                        </tr>
                                        <tr>
                                            <td>03</td>
                                            <td>DDQ</td>
                                            <td>DDQ</td>
                                            <td>CR3</td>
                                            <td>CR3</td>
                                            <td>CR3</td>
                                        </tr>
                                        <tr>
                                            <td>04</td>
                                            <td>EDDQ</td>
                                            <td>EDDQ</td>
                                            <td>CR4</td>
                                            <td>CR4</td>
                                            <td>CR4</td>
                                        </tr>
                                        <tr className={classes.last_row}>
                                            <td>05</td>
                                            <td>IF</td>
                                            <td>IF</td>
                                            <td>CR5</td>
                                            <td>CR5</td>
                                            <td>CR5</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- tabs section starts here  --> */}

            <div className={classes.container}>
                <Box>
                    <Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Packaging" {...tabprops(0)} />
                            <Tab label="Coated Sheets" {...tabprops(1)} />
                            <Tab label="Railway Coach" {...tabprops(2)} />
                            <Tab label="Electrical Panels" {...tabprops(3)} />
                            <Tab label="Electrical Panels" {...tabprops(4)} />
                        </Tabs>
                    </Box>
                    <ProductTabs value={value} index={0}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColorCoated/exteriorPanels.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Exterior panels for Buildings</h2>
                                <p className={classes.gal_card_des}>Color-coated steel is a durable, corrosion-resistant material used in
                                    roofing, building exterior panels, and structural elements. It comes in vibrant colors and
                                    offers a protective coating that enhances functionality and visual appeal in any structure.
                                </p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={1}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColorCoated/homeAppliances.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Home Appliances</h2>
                                <p className={classes.gal_card_des}>Color-coated steel is a must-have for washers and microwaves. It adds
                                    durability and visual appeal, protects against environmental factors, and comes in a range
                                    of colors to fit modern kitchens and laundry spaces.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={2}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColorCoated/roofingDuctile.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Roofing Ducting Coolers</h2>
                                <p className={classes.gal_card_des}>Color-coated steel is versatile, durable, and available in vibrant
                                    colors. It is used for roofing, ducting, coolers, furniture, and heat plates.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={3}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColorCoated/acDucts.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Ac Ducts</h2>
                                <p className={classes.gal_card_des}>Color-coated steel is essential in construction, used for AC ducts,
                                    panels, doors, frames, and shutters. Its corrosion resistance and strength make it durable
                                    and attractive for architectural elements.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={4}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColorCoated/confec.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>confectionery</h2>
                                <p className={classes.gal_card_des}>Color-coated steel has unexpected uses in confectionery, like for
                                    display shelves, counters, and structural components. It offers hygiene, durability, and
                                    color options, creating a practical and appealing environment.</p>
                            </div>
                        </div>
                    </ProductTabs>
                </Box>
            </div>

            {/* <!-- cards for mobile view  --> */}
            <section className={`${classes.section_strip} ${classes.mobile_section_cards}`}>
                <div className={`${classes.container} ${classes.service_wrapper}`}>
                    <h3 className={classes.section_heading}>Applications </h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColorCoated/exteriorPanels.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Exterior panels for Buildings</span>
                                    <p>Color-coated steel is a durable, corrosion-resistant material used in roofing, building
                                        exterior panels, and structural elements. It comes in vibrant colors and offers a
                                        protective coating that enhances functionality and visual appeal in any structure.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColorCoated/homeAppliances.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Home Appliances </span>
                                    <p>Color-coated steel is a must-have for washers and microwaves. It adds durability and
                                        visual appeal, protects against environmental factors, and comes in a range of colors to
                                        fit modern kitchens and laundry spaces.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColorCoated/roofingDuctile.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Roofing Ducting Coolers</span>
                                    <p>Color-coated steel is versatile, durable, and available in vibrant colors. It is used for
                                        roofing, ducting, coolers, furniture, and heat plates.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColorCoated/acDucts.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Ac Ducts</span>
                                    <p>Color-coated steel is essential in construction, used for AC ducts, panels, doors,
                                        frames, and shutters. Its corrosion resistance and strength make it durable and
                                        attractive for architectural elements.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColorCoated/confec.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>confectionery</span>
                                    <p>Color-coated steel has unexpected uses in confectionery, like for display shelves,
                                        counters, and structural components. It offers hygiene, durability, and color options,
                                        creating a practical and appealing environment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default ColorCoatedTemplate;