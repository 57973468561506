import { POLICY_SECTION } from "../../../../utils/constant";

interface UseOfInformationProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
}

const UseOfInformation: React.FC<UseOfInformationProps> = ({ contentStyle, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{POLICY_SECTION.USE_OF_INFORMATION}</span>
            </div>
            <div className={contentStyle}>
                2.1 Avighna may utilize Your Information for the following purposes:
                <br /> <br />
                (i) To identify the User, understand their needs, and resolve disputes, if any;
                <br /> <br />
                (ii) To establish, manage, and provide Products, enhancing services to meet User requirements;
                <br /> <br />
                (iii) To deliver ongoing service;
                <br /> <br />
                (iv) To comply with legal and regulatory requirements;
                <br /> <br />
                (v) To address technical issues and troubleshoot problems;
                <br /> <br />
                (vi) To assist Avighna in collecting payments from Users for transactions conducted on the Platform;
                <br /> <br />
                (vii) To facilitate financing for the purchase of Products;
                <br /> <br />
                (viii) To keep Users informed about Avighna’s (or third parties’) promotions and offers;
                <br /> <br />
                (ix) To personalize User experiences;
                <br /> <br />
                (x) To advertise Products and increase their sales;
                <br /> <br />
                (xi) To detect and protect Avighna from errors, fraud, and other criminal activities;
                <br /> <br />
                (xii) To enforce the Terms of Use; and
                <br /> <br />
                (xiii) Other reasons, communicated to Users through an update to this Privacy Policy before implementation (collectively, the “Purposes”).
                <br /> <br />
                2.2 Additionally, We use Demographic Information, Behavioural, and Indirect Information of Users collectively, such as the most frequently visited areas and accessed Services. This data is used anonymously and in the aggregate to determine what is most beneficial for Users.
                <br /> <br />
                2.3 Financial Information collected from Users is processed through secure digital platforms of approved payment gateways/financiers with encryption, complying with reasonably expected technology standards. While Avighna endeavors to ensure the protection of User’s Personal and Financial Information through security measures prescribed by applicable laws, Users are strongly advised to exercise discretion when providing such information, given the susceptibility of the Internet to security breaches.
                <br /> <br />
            </div>
        </>

    )
}
export default UseOfInformation;