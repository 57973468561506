import React from 'react'
import { AUTH_STATES } from '../../../utils/types';
import { createUseStyles } from 'react-jss';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../utils/constant';

export interface AuthDialogFooterProps {
  setAuthStateTo: (state: AUTH_STATES) => () => void;
  section: AUTH_STATES;
  loginType?: AUTH_STATES;
  isLogin: boolean;
  skipForNow?: boolean
}

const useStyles = createUseStyles((theme: any) => ({
  sectionText: {
    color: theme.palette.text.primaryDarkAccent
  },
  content: {
    color: theme.palette.button.primaryLight
  }
}));

const AuthDialogFooter: React.FC<AuthDialogFooterProps> = ({ setAuthStateTo, section, loginType = AUTH_STATES.LOGIN_WITH_EMAIL, isLogin, skipForNow=false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user, syncAuthDialogActive } = useAuthenticatedUser();
    const handleSkip = () => {
        syncAuthDialogActive(false);
        setAuthStateTo(AUTH_STATES.LOGIN_WITH_EMAIL)
        user && !user.businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
    };
  return (
    <>
      {isLogin &&
       <span onClick={setAuthStateTo(loginType)} className={`cursor-pointer font-normal text-lg text-center my-4 ${classes.sectionText}`}>
        Or Login via {loginType === AUTH_STATES.LOGIN_WITH_EMAIL ? "email" : "mobile number"}</span>}
      {skipForNow &&
       <span onClick={handleSkip} className={`cursor-pointer font-normal text-lg text-center my-4 ${classes.sectionText}`}>
        Skip for now</span>}

      <div className={`text-center border-t pt-4 ${classes.content}`}>
        {isLogin ? "Don't have an account yet?" : "Already have an account?"} <span onClick={setAuthStateTo(section)} className={`text-secondary cursor-pointer font-normal text-lg ${classes.sectionText}`}>{isLogin ? "Register Now" : "Log In"}</span>
      </div></>
  )
}

export default AuthDialogFooter