import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Search from "@mui/icons-material/Search";
import Button from "../../atoms/Button/Button";
import { IconButton, Badge } from "@mui/material";
import avighnaSteel from "../../../assets/images/avighnasteelLogoNew.svg";
import HeaderCategoryFilter from "./HeaderCategoryFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES } from "../../../utils/constant";
import { CartContext } from "../../../contexts/CartContext";
import HeaderSearch from "../../templates/Home/HeaderSearch.template";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import MobileMenus from "./MobileMenus";
import AuthenticationTemplate from "../../templates/Authentication/Authentication.template";
import WebMenu from './HeaderAboutUs'
import CartIcon from "../../../assets/images/revampedCartIcon.svg";
import ProfileIcon from "../../../assets/images/revampedProfileIcon.svg";
import HeaderCategory from "./HeaderCategory";
import MenuIcon from "@mui/icons-material/Menu";
import HomeMenuTemplate from "../../templates/Home/HomeMenu.template";
import CloseIcon from '@mui/icons-material/Close';
import RequestForQuotationModalTemplate from "../../templates/Home/RequestForQuotationModal.template";
import { WishlistContext } from "../../../contexts/WishlistContext";
import wishlistIcon from "../../../assets/images/wishlistIcon.svg";
import { addToQueue } from "../../../utils/helper";
import headerIcon from "../../../assets/icons/headerIcon.svg";


const useStyles = createUseStyles((theme: any) => ({
    container: {
        boxShadow: `0px 4px 17px 0px ${theme.palette.boxShadow.primaryLight}`,
        "& #about-overflow > #about-menu": {
            display: "none",
        },
        "& #about-overflow:hover > #about-menu": {
            display: "block",
        },
        "& #category-anchor > #category-filter": {
            display: "none",
        },
        "& #category-anchor:hover > #category-filter": {
            display: "block",
        },
    },
    search: {
        background: theme.palette.background.primaryLight,
    },
    searchIcon: {
        background: theme.palette.background.secondaryLightAccent,
        color: theme.palette.text.primaryDarkAccent,
    },
    input: {
        border: `1px solid ${theme.palette.border.primaryLight}`,
    },
    camera: {
        borderTop: `1px solid ${theme.palette.border.primaryLight}`,
        borderBottom: `1px solid ${theme.palette.border.primaryLight}`,
    },
    badge: {
        backgroundColor: theme.palette.button.primaryDark,
        color: "white",
    },
    toolTipMessage: {
        backgroundColor: theme.palette.button.primaryDark,
    },
    arrow: {
        borderBottom: `10px solid ${theme.palette.button.primaryDark}`,
        borderRight: "10px solid transparent",
        borderLeft: "10px solid transparent",
    },
    productHeader:
    {
        borderTop: `1px solid ${theme.palette.border._secondaryLight}`,
        paddingLeft: "70px",
        paddingRight: "70px"
    },
    categories: {
        borderLeft: `1px solid ${theme.palette.border._secondaryLight}`,
    },
    imageWrapper: {
        // marginBottom: "12px",
    },
    tabMobileContainer: {
        display: "none",
    },
    homeSection: {
        color: theme.palette.v3.text.primaryDark
    },
    tabContainer: {
        display: "flex",
    },
    superNewGradButtonMobile: {},
    mobileBtn: {},
    btn: {
        display: 'block',
    },
    "@media (max-width: 480px)": {
        tabMobileContainer: {
            display: "grid",
        },
        tabContainer: {
            display: "none",
        },
        mobileBtn: {
            display: 'block',
        },
        superNewGradButtonMobile: {
            background: `linear-gradient(90deg, ${theme.palette.v3.gradient.requestQuotationOrange} 0%, ${theme.palette.v3.gradient.requestQuotationBlue} 100%)`,
            border: `1px solid ${theme.palette.v3.border.orange}`,
            borderRadius: '8px',
            color: theme.palette.v3.text.secondaryDark,
            cursor: 'pointer',
            padding: '10px',
            width: '36px',
            height: '36px',
            '&:hover': {
                background: theme.palette.v3.background._avighnaOrange,
                color: 'white',
            },
        },
        btn: {
            display: 'none',
        },
        categories: {
            width: '100%',
            overflow: 'auto',
            paddingLeft: '16px',
        },
    },
    superNewGradButton: {
        background: `linear-gradient(90deg, ${theme.palette.v3.gradient.requestQuotationOrange} 0%, ${theme.palette.v3.gradient.requestQuotationBlue} 100%)`,
        border: `1px solid ${theme.palette.v3.border.orange}`,
        borderRadius: '8px',
        color: theme.palette.v3.text.secondaryDark,
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.v3.background._avighnaOrange,
            color: 'white',
        },
    },
    dropDown: {
        position: "absolute",
        top: "143px",
        background: theme.palette.v2.background.primaryLightAccent,
        width: "100%",
        left: '2px',
        zIndex: "99",
    },
    topContainer: {
        paddingLeft: "70px",
        paddingRight: "70px"
    },
}));



const Header: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const cartContextData = useContext(CartContext);
    const wishlistContextData = useContext(WishlistContext);
    const { user, syncAuthDialogActive } = useAuthenticatedUser();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(true);
    }

    const navigateToHomePage = () => {
        navigate("/");
    };

    const headerRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (headerRef.current && !headerRef.current.contains(event.target as Node)) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (new URLSearchParams(location.search).get('token')?.length) {
            syncAuthDialogActive(true)
        }
        else
            user && !user.businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
    }, [])

    useEffect(() => {
        (async () => {
            await cartContextData.syncCart(CART_LIFE_CYCLE.LOGIN);
        })();
    }, [user]);

    useEffect(() => {
        (async () => {
            await wishlistContextData.syncWishlist(CART_LIFE_CYCLE.LOGIN);
        })();
    }, [user]);



    const Tooltip = useMemo(() => {
        if (cartContextData.tooltip.visible) {
            return (
                <div className="cursor-pointer grid relative">
                    <IconButton
                        onClick={() => {
                            navigate(CUSTOMER_ROUTES.CART);
                        }}
                    >
                        <Badge
                            badgeContent={cartContextData.cartItemsCount}
                            classes={{ badge: classes.badge }}
                        >
                            <img src={CartIcon} alt="cartIcon" className="w-1.5vw" />
                        </Badge>
                    </IconButton>
                    <div className="absolute top-full z-20 left-1/2 transform -translate-x-1/2">
                        <div className={`h-px w-0.5 m-auto ${classes.arrow}`}></div>
                        <div
                            className={`p-2 rounded w-max text-white ${classes.toolTipMessage}`}
                        >
                            {cartContextData.tooltip.message}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <IconButton
                    onClick={() => {
                        navigate(CUSTOMER_ROUTES.CART);
                    }}
                >
                    <Badge
                        badgeContent={cartContextData.cartItemsCount}
                        classes={{ badge: classes.badge }}
                    >
                        <img src={CartIcon} alt="cartIcon" className="w-1.5vw" />
                    </Badge>
                </IconButton>
            );
        }
    }, [
        cartContextData.tooltip.visible,
        cartContextData.tooltip.message,
        cartContextData.cartItemsCount,
    ]);


    const WishlistTooltip = useMemo(() => {
        if (wishlistContextData.tooltip.visible) {
            return (
                <div className="cursor-pointer grid relative">
                    <IconButton
                        onClick={() => {
                            navigate(CUSTOMER_ROUTES.WISHLIST);
                        }}
                    >
                        <Badge
                            badgeContent={wishlistContextData.wishlistItemsCount}
                            classes={{ badge: classes.badge }}
                        >
                            <img src={wishlistIcon} alt="wishlistIcon" className="w-1.5vw" />
                        </Badge>
                    </IconButton>
                    <div className="absolute top-full z-20 left-1/2 transform -translate-x-1/2">
                        <div className={`h-px w-0.5 m-auto ${classes.arrow}`}></div>
                        <div
                            className={`p-2 rounded w-max text-white ${classes.toolTipMessage}`}
                        >
                            {wishlistContextData.tooltip.message}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <IconButton
                    onClick={() => {
                        navigate(CUSTOMER_ROUTES.WISHLIST);
                    }}
                >
                    <Badge
                        badgeContent={wishlistContextData.wishlistItemsCount}
                        classes={{ badge: classes.badge }}
                    >
                        <img src={wishlistIcon} alt="cartIcon" className="w-1.5vw" />
                    </Badge>
                </IconButton>
            );
        }
    }, [
        wishlistContextData.tooltip.visible,
        wishlistContextData.tooltip.message,
        wishlistContextData.wishlistItemsCount,
    ]);

    const handleDashboardActive = () => {
        if (user) {
            addToQueue(JSON.stringify({ "key": "dashboard", "expandedMenu": { "Profile Overview": true, "My Profile": true } }));
            navigate(`${CUSTOMER_ROUTES.DASHBOARD}/my-profile`)
        }
        else syncAuthDialogActive();
    }

    const menuItems = [
        "About Us",
        "Our Products",
        "Industries",
        "Business",
        "Our Services",
        "Steel News",
        "Contact Us",
    ];

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const handleRfqForm = () => {
        setDialogOpen(true);
    }

    return (
        <>
            <AuthenticationTemplate />
            <div className={`${classes.container}  bg-white`}>
                <div className={`m-auto max-container flex flex-col ${classes.tabContainer}`}>
                    <div className={`flex ${classes.topContainer}`}>
                        <div className={`${classes.imageWrapper} cursor-pointer my-4`} onClick={navigateToHomePage}>
                            <img src={avighnaSteel} alt="avighnaLogo" className="" />
                        </div>
                        <div className="flex w-full justify-end items-center">
                            <div className="flex items-center relative w-1/2 mr-20">
                                <HeaderSearch />
                            </div>
                            <div className="cursor-pointer">
                                {WishlistTooltip}
                            </div>
                            <div className="cursor-pointer">
                                {Tooltip}
                            </div>
                            <div className="cursor-pointer mr-5">
                                <IconButton onClick={() => { handleDashboardActive() }} >
                                    <img src={ProfileIcon} alt="profileIcon" className="w-1.5vw" />
                                </IconButton>
                            </div>
                            <div className={`${classes.superNewGradButton} ${classes.btn} flex cursor-pointer py-3 px-6 font-semibold text-base`} onClick={handleRfqForm}>
                                Request for Quotation
                            </div>
                            {/* <div className={`${classes.superNewGradButtonMobile} flex cursor-pointer py-3 px-6 font-semibold text-base`} onClick={handleRfqForm}>
                                Request for Quotation
                            </div> */}
                            <RequestForQuotationModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
                        </div>
                    </div>
                    <div ref={headerRef} className={`flex py-4 ${classes.productHeader}`}>
                        <div className="flex gap-x-2 items-center pr-6">
                            {isOpen ? <CloseIcon className="cursor-pointer" onClick={handleClose} /> : <MenuIcon className="cursor-pointer" onClick={handleClick} />}
                            <div className={`font-medium text-lg ${classes.homeSection} relative cursor-pointer`} onClick={navigateToHomePage}>Home</div>
                            <div className={`${classes.dropDown} pl-12 pr-12`}>
                                <div className="m-auto max-container ">
                                    {isOpen && <HomeMenuTemplate />}
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.categories} pl-6 flex justify-center`}>
                            <HeaderCategory />
                        </div>
                    </div>
                </div>
                <div className={`m-auto ${classes.tabMobileContainer}`}>
                    <div className="flex py-3 px-4 justify-between">
                        <div className="my-auto">
                            <MobileMenus />
                        </div>
                        <div className={`flex gap-x-2`}>
                            <div className="flex my-auto">
                                <div className="cursor-pointer">
                                    {WishlistTooltip}
                                </div>
                                <div className="cursor-pointer">
                                    <IconButton onClick={() => { handleDashboardActive() }} >
                                        <Badge classes={{ badge: classes.badge }} >
                                            <img src={ProfileIcon} alt="profileIcon" className="m-auto" onClick={() => { handleDashboardActive() }} />
                                        </Badge>
                                    </IconButton>
                                </div>
                                <div className="cursor-pointer">
                                    {Tooltip}
                                </div>
                                <RequestForQuotationModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
                            </div>

                            <div className={`${classes.superNewGradButtonMobile} my-auto`} onClick={handleRfqForm}>
                                <img src={headerIcon} alt="icon" />
                            </div >
                        </div>
                    </div>
                    
                    <div className="px-4 pb-3 w-full ">
                        <div className="flex">
                            <HeaderSearch />
                        </div>
                    </div>

                    <div className={`${classes.categories} pl-6 pb-3 flex justify-center`}>
                        <HeaderCategory />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
