import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import OtherInformationEditTemplate from './OtherInformationEdit.template';
import { useState } from 'react';
import noData from '../../../assets/images/noData.png';
import { INDUSTRY_STATES } from '../../../utils/constant';
import { convertDate, getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
        background: theme.palette.v4.background._secondaryDarkAccent,
    },
    container_heading: {
        color: theme.palette.text.secondaryDark,
    },
    card_content: {
        "& span": {
            color: theme.palette.text.secondaryDark,
        },
        "& p": {
            color: theme.palette.text.secondaryDark,
        }
    },
    search_container: {
        background: theme.palette.v4.background._primaryDark,
        color: theme.palette.v3.text._primaryLight,
        padding: "14px",
        borderRadius: "10px",
    },
    secondaryText: {
        color: theme.palette.v3.text._secondaryLight,
    },
}));

interface OtherInformationTemplateProps {
    businessProfile: any,
    handleSection: any,
    updateBusinessProfileSection: (section: string, values: any) => void;
    handleUpdateBusinessProfile: () => void;    
}

const OtherInformationTemplate: React.FC<OtherInformationTemplateProps> = ({ businessProfile, handleSection, updateBusinessProfileSection, handleUpdateBusinessProfile }) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSuccess = () => {
        setIsEditing(false);
    };

    const handleBack = () => {
        setIsEditing(false);
        handleUpdateBusinessProfile();
    };

    const getIndustryType = (value: string): string => {
        const industry = INDUSTRY_STATES[value as keyof typeof INDUSTRY_STATES];
        return industry !== undefined ? industry : value;
    }

    return (
        <>
            {isEditing ? (
                <OtherInformationEditTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    onSuccess={handleSuccess} onBack={handleBack} />
            ) : (
                <>
                    {businessProfile?.totalEmployees !=null ? (
                        <div className={`${classes.container} p-6 rounded-lg w-full`}>
                            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                                <h2 className={`${classes.container_heading} text-2xl font-medium`}>Other Information</h2>
                                <Button variant={'tertiaryContained'} label={'Edit'} onClick={handleEditClick} />
                            </div>
                            <div className='grid grid-cols-3 gap-x-9 pt-6'>
                                <div className={classes.card_content}>
                                    <span>Year of Establishment</span>
                                    <p className='font-medium'>{convertDate(businessProfile?.dateOfEstablishment)}</p>
                                </div>
                                <div className={classes.card_content}>
                                    <span>Number of Employees</span>
                                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.totalEmployees)}</p>
                                </div>
                                    <div className={`${classes.card_content} break-all`}>
                                        <span>Website URL</span>
                                        <p className='font-medium'>
                                            <a href={businessProfile.websiteUrl} target="_blank" rel="noopener noreferrer">
                                                {getProfileDisplayData(businessProfile?.websiteUrl)}
                                            </a>
                                        </p>
                                    </div>
                                <div className={classes.card_content}>
                                    <span>Industry Type</span>
                                    <p className='font-medium'>{getIndustryType(businessProfile?.industry)}</p>
                                </div>
                                <div className={classes.card_content}>
                                    <span>Brand</span>
                                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.brand)}</p>
                                </div>
                                <div className={classes.card_content}>
                                    <span>Business Type</span>
                                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.businessType)}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                    <div className={`${classes.primaryContainer} py-14 px-6 flex justify-center w-full rounded-lg`}>
                            <div className='flex flex-col items-center '>
                                <img src={noData} alt="" className='mb-8' />
                                <p className={`${classes.secondaryText} mb-4 text-lg`}>You haven't provided Other Information for your business.</p>
                                <Button className='rounded-lg' variant={'primaryContained'} onClick={handleEditClick} label={"Add Other Information"} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default OtherInformationTemplate;
