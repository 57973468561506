import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { LOCATION_STATES } from '../../../utils/types';
import { FormikProps } from 'formik';
import { ILocationGstinForm } from '../../pages/DashBoard/BussinessLocation.page';
import AddBusinessLocationTemplate, { LocationSectionProps } from './AddBusinessLocation.template';
import ViewBusinessLocationTemplate from './ViewBusinessLocation.template';
import GetNumberOfBusinessLocationTemplate from './GetNumberOfBusinessLocation.template';
import AddNewBusinessLocationTemplate from './AddNewBusinessLocation.template';
import Shimmer from '../../molecules/ShimmerComponent/Shimmer';

export interface IBusinessLocationProps {
    formik: FormikProps<ILocationGstinForm>;
}
const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.v2.text.primaryDark
    }
}));

const BussinessLocationTemplate: React.FC<IBusinessLocationProps> = ({ formik }) => {
    const classes = useStyles();
    const [currentSection, setCurrentSection] = useState<LOCATION_STATES>(LOCATION_STATES.VIEW_LOCATIONS);


    const locationSectionProps: LocationSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
    };

    const secondaryInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case LOCATION_STATES.VIEW_LOCATIONS:
                return formik.values?.locations?.length > 0 ? (
                    <ViewBusinessLocationTemplate {...locationSectionProps} />
                ) : (
                    <AddBusinessLocationTemplate {...locationSectionProps} />
                );
            case LOCATION_STATES.ADD_LOCATION_NO:
                return <GetNumberOfBusinessLocationTemplate {...locationSectionProps} />;
            case LOCATION_STATES.ADD_LOCATIONS:
                return <AddNewBusinessLocationTemplate {...locationSectionProps} />;
            default:
                return formik.values?.locations?.length > 0 ? (
                    <ViewBusinessLocationTemplate {...locationSectionProps} />
                ) : (
                    <AddBusinessLocationTemplate {...locationSectionProps} />
                );
        }
    }, [currentSection, formik]);

    return (
        <div className='grid gap-6 justify-items-stretch'>
            <div className={`${classes.titleText} font-semibold text-lg`}>Business Location</div>
            {secondaryInventorySectionView}
        </div>
    );
};
export default BussinessLocationTemplate;
