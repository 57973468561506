import React, { useEffect, useRef, useState } from "react";
import { useCatalogueService } from "../../../services/useCatalogueService";
import CatalogueGridItem from "../Catalogue/CatalogueGridItem.template";
import { Tab, Tabs } from "@mui/material";
import { useCategoryService } from "../../../services/useCategoryService";
import Button from "../../atoms/Button/Button";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { CATEGORY_LEVEL, CUSTOMER_ROUTES } from "../../../utils/constant";
import { Catalogue } from "../Catalogue/CatalogueList.template";
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import emptyProducts from "../../../assets/images/emptyCartImage.svg";
import CatalogueGridItemSkeleton from "../Catalogue/CatalogueGridItemSkeleton.template";
import { CATALOGUE_TYPE_STATES, CLASS_STATES } from "../../../utils/types";
import { makeRoute } from "../../../utils/helper";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import processedHeading from '../../../assets/images/processedHeading.svg';

interface ProcessedMaterialTemplateProps {
    title: string;
}

const useStyles = createUseStyles((theme: any) => ({
    primaryContainer: {
        background: "linear-gradient(115deg, #FEF1EA 8.97%, #EEF2FE 104.01%)",
        height: "654px",
        margin: "0px 72px",
        overflow: "hidden",
        borderRadius: "16px",
        
    },
    secondaryContainer: {
        // width: "1290px",
    },
    titleBorder: {
        height: "16px",
        width: "225px",
        background: theme.palette.v2.background.secondaryDark,
        position: "relative",
        top: "75px",
        left: "261px",
    },
    title: {
        color: theme.palette.v2.text.primaryDark,
    },
    titleSecondary: {
        color: theme.palette.v2.text._primaryLightAccent,
    },
    mobiletitle: {
        color: theme.palette.text.primaryDark,
    },
    catalogueCard: {
        width: "304px",
        minWidth: "180px",
        paddingBottom: "4px",
        height: "430px !important"
    },
    viewAllButton: {
        padding: "2px 0px",
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important",
            zIndex: "10 "
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important",
            zIndex: "10 "
        },
        "& .react-multi-carousel-list": {
            width: "1146px",
            margin: "0 auto",
            overflowX: "auto",
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
        marginTop: '24px',
        position: "relative",
    },
    iconTitle: {
        color: theme.palette.text.secondaryDark,
        fontSize: '36px'
    },
    tabMobileContainer: {
        display: "none"
    },
    tabContainer: {
        display: "flex"
    },
    tab: {
        marginRight: "35px"
    },
    tabs: {
    },
    btn: {},
    headingMain: {}, 
    mobiletitleSecondary: {},
    viewbtn: {},
    mobileContainerSecondary: {
        display: 'none',
    },
    "@media (max-width: 480px)": {
        tabMobileContainer: {
            display: "none",
            "& div": {
                display: 'flex',
                justifyContent: 'flex-start',
            },
        },
        tabContainer: {
            display: "none"
        },
        carouselContainer: {
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-item": {
                width: "264px !important ",
                marginRight: '-8px',
            },
            marginTop: '8px !important ',
            ' & .react-multi-carousel-list ': {
                overflowX: 'auto ',
                width: '100% !important',
                "& .react-multi-carousel-track": {
                    width: '100%',
                },
            },
        },
        iconTitle: {
            color: theme.palette.text.secondaryDark,
            fontSize: '16px'
        },
        catalogueCard: {
            width: "264px !important",
            minWidth: "100px",
            paddingBottom: "4px",
        },
        heading: {
            display: 'none',
            fontSize: '14px',
            margin: '0px',
            height: 'auto',
        },
        headingMain: {
            display: 'none',
        },
        tabs: {
            display: 'none',
        },
        mobiletitleSecondary: {
            display: 'none',    
        },
        btn: {
            display: 'none',
        },
        primaryContainer: {
            margin: '0px',
            height: '527px',
            width: '100%',
            borderRadius: "0px",
            paddingLeft: '16px',
            paddingRight: '0px',
        },
        titleBorder: {
            display: 'none',
          
        },
        secondaryContainer: {
           
        },  
        mobileContainerSecondary: {
            display: 'block',
        },
        customArrow: {
            display: 'none',
        },
        viewbtn: {
            padding: '0px 16px',
            fontSize: '14px',
            color: theme.palette.v4.text.secondaryLightAccent,
        },
        tab: {
            marginRight: "16px"
        },
    },
    viewAll: {
        color: theme.palette.v2.text._primaryDarkAccent,
    },
    customButtonContainer: {
        marginTop: "-19.5%"
    },
    customArrow: {
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        cursor: "pointer",
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.secondaryDark}`,
        "&:hover": {
            boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.primaryDark}`
        }
    },
    heading: {
        color: theme.palette.v3.text.primaryDarkAccent,
    },

}));

interface ICategoryItem {
    id: number | string;
    label: string;
}

interface Payload {
    catalogueTypes?: string[];
    classes?: string[];
}

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 3,
        partialVisibilityGutter: 46
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 480, min: 320 },
        items: 2,
        partialVisibilityGutter: 0
    }
};

const ProcessedMaterialTemplate: React.FC<ProcessedMaterialTemplateProps> = ({ title }) => {
    const classes = useStyles();
    const catalogueService = useCatalogueService();
    const [catalogues, setCataloguesTo] = useState<Catalogue[]>([]);
    const navigate = useNavigate();
    const catagoryService = useCategoryService();
    const [categories, setCategoriesTo] = useState<ICategoryItem[]>([]);
    const [activeCategoryTabIndex, setActiveCategoryTabIndex] = useState<number>(0);
    const carouselRef = useRef<CarouselRef>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
    }, [activeCategoryTabIndex, categories]);

    const handleTabChange = (
        event: React.SyntheticEvent,
        currentTabIndex: any
    ) => {
        setActiveCategoryTabIndex(currentTabIndex);
    };

    const loadCategories = async () => {
        try {
            const params = {
                level: CATEGORY_LEVEL.PRODUCT_CATEGORY,
                limit: 7,
            };
            const categoriesResponse = await catagoryService.getAllCategories(params);
            if (categoriesResponse?.data?.data?.length) {
                setCategoriesTo(categoriesResponse.data.data);
                setActiveCategoryTabIndex(0);
            } else {
                throw new Error("Categories fetch failed");
            }
        } catch (error) {
            setCategoriesTo([]);
        }
    };


    const fetchCatalogues = async () => {
        if (!categories?.[activeCategoryTabIndex]?.id) return null;
        try {
            setLoading(true);

            const payload: Payload = {
                classes: [CLASS_STATES.SECONDARY],
                catalogueTypes: [CATALOGUE_TYPE_STATES.CURRENT_INVENTORY]
            };

            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 10,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse.data.data;
                setCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setCataloguesTo([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        fetchCatalogues();
    }, [activeCategoryTabIndex, categories]);

    const handleClick = () => {
        navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { "classes": CLASS_STATES.SECONDARY, "catalogueTypes": CATALOGUE_TYPE_STATES.CURRENT_INVENTORY, "categoryId": categories?.[activeCategoryTabIndex]?.id } }));
    }

    const ButtonGroup = ({ next, previous, carouselState }: any) => {
        const { currentSlide, totalItems, slidesToShow } = carouselState;
        const isFirstSlide = currentSlide === 0;
        const isLastSlide = currentSlide === totalItems - slidesToShow;

        return (
            <div className={`${classes.customButtonContainer} flex justify-between items-center w-full`}>
                {!isFirstSlide && (
                    <button className={`${classes.customArrow} bg-white flex items-center`} onClick={previous}>
                        <KeyboardArrowLeftIcon className="mx-auto" sx={{ fontSize: 30 }} />
                    </button>
                )}
                {!isLastSlide && (
                    <button className={`${classes.customArrow} bg-white flex items-center ml-auto`} onClick={next}>
                        <KeyboardArrowRightIcon className="mx-auto" sx={{ fontSize: 30 }} />
                    </button>
                )}
            </div>
        );
    };
    return (
        <>
            {
                catalogues.length > 0 &&
                <div className={`${classes.primaryContainer} mx-auto py-8 px-5 flex justify-center `}>
                    <div className={`${classes.secondaryContainer} w-full`}>
                        <div className="relative">
                            <div className={classes.titleBorder}></div>
                            <div className={`${classes.headingMain} h-28 flex justify-center`}>
                                <h3 className={`${classes.heading} text-5xl font-bold text-center z-10 my-auto`}>Processed Materials.Big <span className={classes.titleSecondary}>Savings.</span></h3>
                            </div>
                                <div className={`${classes.mobileContainerSecondary} `}>
                                    <div className="flex justify-between items-center">
                                        <img src={processedHeading} alt="" />
                                        <Button variant="text" label="View All" className={classes.viewbtn} onClick={handleClick} />
                                    </div>
                                </div>
                        </div>
                        <div className={` justify-between px-14 ${classes.tabContainer}`}>
                            <div className={`${classes.title} flex text-2xl font-semibold  items-center gap-1.6`}>{title}</div>
                            <div className="flex gap-x-3">
                                {/* <Tabs
                        value={activeCategoryTabIndex}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons={false}
                    >
                        {categories.map((category, index) => (
                            <Tab key={category.id} label={category.label} value={index}
                            />
                        ))}
                    </Tabs> */}
                                <div className={classes.viewAllButton}>
                                    <Button variant="text" label="View All Categories" onClick={handleClick} className={`${classes.viewAll} !px-0 !text-lg !font-medium`} />
                                </div>
                            </div>
                        </div>

                        <div className={`${classes.tabMobileContainer} `}>
                            <div className="flex justify-between">
                                <div className={`${classes.mobiletitle} ${classes.mobiletitleSecondary} flex text-base font-bold  items-center gap-1.6`}>{title}</div>
                                <div className={classes.viewAllButton}>
                                    <Button variant="text" label="View All Categories" onClick={handleClick} className= {`${classes.btn} !py-0`}/>
                                </div>
                            </div>
                            
                        </div>
                        {
                            loading ? (
                                <div className={`${classes.carouselContainer} flex gap-x-8`}>
                                    {[null, null, null, null].map((item: any, index: any) => (
                                        <div className={classes.catalogueCard} key={index}>
                                            <CatalogueGridItemSkeleton />
                                        </div>
                                    ))}
                                </div>
                            ) :
                                catalogues.length > 0 ? (
                                    <div className={classes.carouselContainer}>
                                        <Carousel
                                            responsive={responsive}
                                            showDots={false}
                                            infinite={false}
                                            partialVisible={true}
                                            ref={carouselRef}
                                            removeArrowOnDeviceType={["sm-mobile"]}
                                            arrows={false}
                                            renderButtonGroupOutside={true}
                                            customButtonGroup={<ButtonGroup />}
                                        >
                                            {catalogues.map((item: any, index: any) => (
                                                <div className={` ${classes.catalogueCard} ${index !== catalogues.length - 1 ? classes.tab : ''}`} key={item.id} >
                                                    <CatalogueGridItem data={item} />
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                ) : (
                                    <div className="w-full flex justify-center items-center">
                                        <div className="grid  items-center m-auto ">
                                            <img className="m-auto w-3/4" src={emptyProducts} alt="pic" />
                                            <div className={` ${classes.iconTitle} font-black m-auto`}>
                                                We're adding more products soon!
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default ProcessedMaterialTemplate;
