import { FormikProps } from 'formik';
import { TextField, FormHelperText, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { GstResponse, IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';

interface BusinessDetailsTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
    gstDetails: GstResponse | null;
};

export interface IBusinessDetails {
    business: {
        dateOfEstablishment: string,
        websiteUrl: string,
        lastAnnualTurnover: string,
        totalEmployees: string
    }
}

const useStyles = createUseStyles((theme: any) => ({
    sectionText: {
        color: theme.palette.text.primaryDark
    },
    black: {
        color: theme.palette.text.primaryLightAccent
    }, dullText: {
        color: theme.palette.text.secondaryLightAccent
    }
}))

const annualTurnoverOptions = [
    'Less than 40 Lakhs',
    '40 L - 1 CR',
    '1-5 CR',
    '5-10 CR',
    '10-20 CR',
    '20-50 CR',
    '50-100 CR',
    '100+ CR',
];

const BusinessDetailsTemplate: React.FC<BusinessDetailsTemplateProps> = ({ formik, setFormState }) => {
    const classes = useStyles();
    const businessService = useBusinessService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [loading, setLoading] = useState(false)
    const handleSubmit = async () => {
        formik.setTouched({ dateOfEstablishment: true, lastAnnualTurnover: true, totalEmployees: true }, true)
        const errors = Object.keys(formik.errors).find(key => [ 'dateOfEstablishment', 'lastAnnualTurnover', 'totalEmployees' ].includes(key))
        if (errors) return;
        const { dateOfEstablishment, websiteUrl, lastAnnualTurnover, totalEmployees } = formik.values;
        setLoading(true)
        user && businessService.updateBusiness({ business: { dateOfEstablishment, websiteUrl, lastAnnualTurnover, totalEmployees } }, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setFormState(ONBOARDING_STATES.ENTITY_TYPE)
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally(() => {setLoading(true)});
    }

    return (
        <div>
            {SnackBarComponent}
            <div className={`flex gap-1`}>
                <span className={`font-light ${classes.dullText}`} >Steps</span>
                <span>2/2</span>
            </div>
            <span className={`text-xl ${classes.black} font-semibold`}>Please Enter Your Business Details</span>
            <form onSubmit={formik.handleSubmit} className='mt-3'>
                <div className='flex flex-col gap-y-3'>
                    <div className="">
                        <TextField
                            label="Date of Establishment"
                            type="date"
                            fullWidth
                            autoComplete="false"
                            focused
                            error={
                                formik.touched?.dateOfEstablishment && Boolean(formik.errors?.dateOfEstablishment)
                            }
                            {...formik.getFieldProps("dateOfEstablishment")}
                            disabled
                        />
                        {formik.touched?.dateOfEstablishment && formik.errors?.dateOfEstablishment &&
                            <FormHelperText error className='top-half '>{formik.errors?.dateOfEstablishment}</FormHelperText>
                        }
                    </div>
                    <div className="">
                        <TextField
                            label="Business Website (if Any)"
                            type="text"
                            fullWidth
                            autoComplete="false"
                            error={
                                formik.touched?.websiteUrl && Boolean(formik.errors?.websiteUrl)
                            }
                            {...formik.getFieldProps("websiteUrl")}

                        />
                        {formik.touched?.websiteUrl && formik.errors?.websiteUrl &&
                            <FormHelperText error className='top-half '>{formik.errors?.websiteUrl}</FormHelperText>
                        }
                    </div>
                    <div className="">
                        <TextField
                            label="Last Annual Turnover (Cr)"
                            type="text"
                            fullWidth
                            select
                            autoComplete="false"
                            error={
                                formik.touched?.lastAnnualTurnover && Boolean(formik.errors?.lastAnnualTurnover)
                            }
                            {...formik.getFieldProps("lastAnnualTurnover")}
                        >
                            {annualTurnoverOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        {formik.touched?.lastAnnualTurnover && formik.errors?.lastAnnualTurnover &&
                            <FormHelperText error className='top-half '>{formik.errors?.lastAnnualTurnover}</FormHelperText>
                        }
                    </div>
                    <div className="">
                        <TextField
                            label="Number Of Employees"
                            type="number"
                            fullWidth
                            autoComplete="false"
                            error={
                                formik.touched?.totalEmployees && Boolean(formik.errors?.totalEmployees)
                            }
                            {...formik.getFieldProps("totalEmployees")}
                        />
                        {formik.touched?.totalEmployees && formik.errors?.totalEmployees &&
                            <FormHelperText error className='top-half '>{formik.errors?.totalEmployees}</FormHelperText>
                        }
                    </div>
                </div>
                <div className='w-2/4 mt-8'>
                    <Button variant="containedLight" label="Next" type="submit" className="!h-12" fullWidth onClick={handleSubmit} isLoading={loading}/>
                </div>
            </form>
        </div>
    )
}

export default BusinessDetailsTemplate