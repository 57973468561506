import { request } from ".";
import { IValueAddedService } from "../components/templates/Catalogue/Customization/SelectCustomization.template";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export interface ICartRequestBody {
	catalogueName: string;
	upcId: number | null;
	warehouseId: number;
	quantity: number;
	uom: string;
	packagingType: string;
  secondarySkuId: number | null;
  valueAddedServices?: IValueAddedService;
  paymentType: string
}

export const CART_URLS = {
    CART: "/cart/",
    CART_ITEM: "/cart/:cartId"
  };
  
  export const useCartService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

	  const getCart = () => {
		    return request(API_METHOD.GET, CART_URLS.CART, authenticatedUser);
	  };
    
    const saveToCart = (data: ICartRequestBody) => {
      return request(API_METHOD.POST, CART_URLS.CART, authenticatedUser, data);
    };

    const updateCartItem = (cartId: number, data: ICartRequestBody) => {
      return request(API_METHOD.PUT, replaceUrlParams(CART_URLS.CART_ITEM, { cartId } ), authenticatedUser, data );
    };

    const deleteCartItem = (cartId: number) => {
      return request(API_METHOD.DELETE, replaceUrlParams(CART_URLS.CART_ITEM, { cartId } ), authenticatedUser);
    };

    const emptyCart = () => {
      return request(API_METHOD.DELETE, CART_URLS.CART, authenticatedUser);
    };

    return {
      getCart,
      saveToCart,
      updateCartItem,
      deleteCartItem,
      emptyCart
    };
  };
