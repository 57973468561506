import React from "react";
import { PRICE_TRENDS } from "../../../utils/constant";
import ComingSoonImage from '../../../assets/images/comingSoon.png';
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.v3.text._secondaryLight
    }
}));
const PriceTrendsTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div id="scrollToLabel">
            <span className={`${classes.title} text-lg font-bold`}>{PRICE_TRENDS.KNOW_ABOUT_PAST_PRICE_TRENDS}</span>
            <img src={ComingSoonImage} alt="icon" className={`w-full mt-3`} />
        </div>
    )
};

export default PriceTrendsTemplate;