import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

export interface ITabsSchema {
    label: string,
    component: ReactNode
}

interface TabsProps {
    schema: ITabsSchema[],
    value: number,
    setValue: (value: number) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container:{
        backgroundColor: theme.palette.backgroundV2.primary.primary50,
        color: theme.palette.textV2.primary.primary900,
    },
    tab: {
        '&.selected': {
            backgroundColor: 'white',
            boxShadow: "0px 2px 4.8px 0px rgba(77, 77, 77, 0.25)",
            color: theme.palette.textV2.primary.primary900,
            fontWeight: 600,
            borderRadius: "8px"
        },
    },
}));

const TabsV2: React.FC<TabsProps> = ({ schema, value, setValue }) => {
    const classes = useStyles();
    const handleChange = (index: number) => {
        setValue(index);
    };

    return (
        <div className='grid gap-y-6 w-full'>
            <div className={`${classes.container} flex gap-x-1 rounded-lg p-0.5 text-sm w-full`}>
                {schema.map((tab, index) => (
                    <div
                        className={`px-6 py-4 cursor-pointer ${classes.tab} ${value === index ? 'selected' : ''} flex-1 text-center`}
                        key={index}
                        onClick={() => handleChange(index)}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
            <div className='w-full'>
                {schema.map((tab, index) => (
                    value === index && (
                        <div key={index}>
                            {tab.component}
                        </div>
                    )
                ))}
            </div>
        </div>
    );
}

export default TabsV2;