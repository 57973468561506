import { FormHelperText } from '@mui/material';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';

interface ServiceRequirementsTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
};

export interface IServiceRequirements {
    business: {
        serviceRequirements: string
    }
}

const useStyles = createUseStyles((theme: any) => ({
    sectionText: {
        color: theme.palette.text.primaryDark
    },
    black: {
        color: theme.palette.text.primaryLightAccent
    }, dullText: {
        color: theme.palette.text.secondaryLightAccent
    },
    selected: {
        border: `1px solid ${theme.palette.button.primaryDark}`,
        background: theme.palette.background.secondaryLightAccent,
    }
}))

const requirements = [
    'Slitting',
    'Metal Offset Printing',
    'Shearing',
    'Blanking',
    'Warehousing and Handling',
    'Logistics'
]

const ServiceRequirementsTemplate: React.FC<ServiceRequirementsTemplateProps> = ({ formik }) => {
    const classes = useStyles();
    const businessService = useBusinessService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const navigate = useNavigate();
    const [ error, setError ] = useState(false);

    const handleSelect = (option: any) => {
        const selection = new Set(formik.getFieldProps("serviceRequirements").value);
        if (selection.has(option))
            selection.delete(option)
        else{
            selection.add(option)
            setError(false)
        }
        formik.setFieldValue('serviceRequirements', Array.from(selection));
    }
 
    const handleSubmit = () => {
        const selectedRequirements = formik.getFieldProps("serviceRequirements").value;
        if (!selectedRequirements.length) {
            setError(true)
            return
        }
        formik.setSubmitting(true);
        user && businessService.updateBusiness({business:{serviceRequirements: selectedRequirements.join(",")}}, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    navigate('/');
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally(() =>{formik.setSubmitting(false);});        
    }
    return (
        <div className='select-none'>
            {SnackBarComponent}
            <span className={`text-xl ${classes.black} font-semibold`}>Please Select Your Service Requirements</span>
            <div className='mt-3 mb-8'>
                <div className='flex gap-2 flex-wrap'>
                    {
                        requirements?.map((option) => (
                            <span className={`py-2 px-4 text-lg border rounded border-accent-gray-950 accent-gray-800 w-fit cursor-pointer ${formik.getFieldProps("serviceRequirements").value.includes(option) && classes.selected}`}
                                onClick={() => handleSelect(option)}>{option}</span>
                        ))
                    }
                </div>
                {error &&
                    <FormHelperText error className='top-half '>Service requirement is required</FormHelperText>
                }
            </div>
            <Button variant="containedLight" label="Submit" className="!h-12 mt-8" fullWidth onClick={handleSubmit} isLoading={formik.isSubmitting}/>
        </div>)
}

export default ServiceRequirementsTemplate