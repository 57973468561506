import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useStyles } from "./Style";
import NewsLetterTemplate from './NewsLetter.Template';
import { ProductTabs, tabprops } from './ProductTabs.Template';

const ColdRolledSteelTemplate: React.FC = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <section className={classes.produts_banner}>
                <div className={`${classes.products_bannner_wrapper} ${classes.colled_rolled_banner}`}>
                    <div className={classes.products_banner_content}>
                        <h4>Products</h4>
                        <h3>Cold Rolled Steel</h3>
                        <h1>Strength, Precision, Endless Possibilities</h1>
                        {/* <!-- <div className="banner_btn">
                    <a href="#">Learn More</a>
                </div> --> */}
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className="products_about_us section_space section_strip_space">
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={classes.business_card}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={`${classes.section_heading_main} ${classes.gal_card_heading}`}>About Cold Rolled</h2>
                            <p className={classes.gal_card_des} >Cold-rolled steel is produced by passing hot rolled steel through rollers
                                below its
                                recrystallization temperature. This process is done to enhance its mechanical and dimensional
                                properties. The metal is compressed and squeezed, resulting in increased yield strength and
                                hardness. Cold rolling of metal strips is a specialized segment in the metalworking industry.
                                Cold-rolled steel has several advantages, such as:</p>
                            <div className="gal_card-btns_wrapper">
                                {/* <p href="" className="gal_card_btn">Higher Precision</p>
                        <p href="" className="gal_card_btn">Greater strength</p>
                        <p href="" className="gal_card_btn">Better surface finishes</p> */}
                            </div>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/ColdRolled/aboutColdRolled.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- specification range section starts here  --> */}

            <section className={`${classes.specification_section} ${classes.section_strip}`}>
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading_main}>Specifications range</h3>
                    <div className={classes.card_parent_wrap}>
                        <div className={classes.p_card_wrapper}>
                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg6.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Thickness Ranges</span>
                                        <span className={classes.p_card_s_text}>0.25~2.00 mm (±0.003mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg2.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Width Range</span>
                                        <span className={classes.p_card_s_text}>50~1250mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg3.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Length Range</span>
                                        <span className={classes.p_card_s_text}>600-3000 mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg4.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Outer Diameter</span>
                                        <span className={classes.p_card_s_text}>1500-2200 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg5.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Inner Diameter</span>
                                        <span className={classes.p_card_s_text}>510, 600, 610 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg1.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Coil Weight</span>
                                        <span className={classes.p_card_s_text}>3-20 tonnes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- BIS Standards in Cold Rolled Steel section starts here --> */}

            <section className={classes.bis_section}>
                <div className={`${classes.bis_section_wrapper} ${classes.container}`}>
                    <h3>BIS Standards in Cold Rolled Steel</h3>
                    <div className={classes.bis_content_wrapper}>
                        <div className={classes.bis_content}>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 14491</span>
                                    <span className={classes.table_subheading}>Year : 1999</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Low carbon high strength cold rolled steel sheets and coils for cold forming</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 410</span>
                                    <span className={classes.table_subheading}>Year : 1990</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold Rolled Brass Sheet, Strip And Foil</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 648</span>
                                    <span className={classes.table_subheading}>Year : 1974</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold rolled non-oriented electrical steel sheet and strip - Fully processed type</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 9485</span>
                                    <span className={classes.table_subheading}>Year : 1979</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-reduced and hot-rolled carbon steel sheet for porcelain enameling</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 14491</span>
                                    <span className={classes.table_subheading}>Year : 1980</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Low carbon high strength cold rolled steel sheets and coils for cold forming</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 15391</span>
                                    <span className={classes.table_subheading}>Year : 2016</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold Rolled Non-Oriented Electrical Steel Sheet and Strip - Semi-Processed Type</p>
                                </div>
                            </div>

                            <div className={`${classes.bis_content_main} ${classes.last_row}`}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS/ISO 16162</span>
                                    <span className={classes.table_subheading}>Year : 2016</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-Rolled Steel Sheet Products - Dimensional and Shape Tolerances ( First Revision )
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.bis_content}>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>

                            <div className={`${classes.bis_content_main} ${classes.last_row}`}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* <!-- BIS Standards in Cold Rolled Steel mobile section starts here --> */}
            {/* 
            <section className="bis_section_mobile">
                <div className="bis_section_wrapper container">
                    <h3>BIS Standards in Cold Rolled Steel</h3>
                    <div className={classes.bis_content_wrapper}>
                        <div className={classes.bis_content}>
                            <div className="bis_content_main ">
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 12367</span>
                                    <span className={classes.table_subheading}>Year : 1975</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Cold-rolled Carbon Steel Strips/Coils for Manufacture of Welded Tubes</p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 14491</span>
                                    <span className={classes.table_subheading}>Year : 1999</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Low carbon high strength cold rolled steel sheets and coils for cold forming</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <!-- grades section starts here  --> */}

            <section className={` ${classes.section_strip} ${classes.section_strip_space}`} >
                <div className={`${classes.grades_section_wrapper} ${classes.container}`}>
                    <h3>BIS Standards in Cold Rolled Steel</h3>
                    <div className={classes.grades_table}>
                        <div className={classes.grades_table_wrapper}>
                            <div className={classes.details_table}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>JSW GRADES</th>
                                            <th>TATA GRADES</th>
                                            <th>SAIL GRADES</th>
                                            <th>AMNS GRADE</th>
                                            <th>BIS GRADES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>CQ</td>
                                            <td>CQ</td>
                                            <td>CR1</td>
                                            <td>CR1</td>
                                            <td>CR1</td>
                                        </tr>
                                        <tr>
                                            <td>02</td>
                                            <td>DQ</td>
                                            <td>DQ</td>
                                            <td>CR2</td>
                                            <td>CR2</td>
                                            <td>CR2</td>
                                        </tr>
                                        <tr>
                                            <td>03</td>
                                            <td>DDQ</td>
                                            <td>DDQ</td>
                                            <td>CR3</td>
                                            <td>CR3</td>
                                            <td>CR3</td>
                                        </tr>
                                        <tr>
                                            <td>04</td>
                                            <td>EDDQ</td>
                                            <td>EDDQ</td>
                                            <td>CR4</td>
                                            <td>CR4</td>
                                            <td>CR4</td>
                                        </tr>
                                        <tr className={classes.last_row}>
                                            <td>05</td>
                                            <td>IF</td>
                                            <td>IF</td>
                                            <td>CR5</td>
                                            <td>CR5</td>
                                            <td>CR5</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- tabs section starts here  --> */}

            <div className={`${classes.container} ${classes.section_strip}`}>
                <Box>
                    <Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Packaging" {...tabprops(0)} />
                            <Tab label="Coated Sheets" {...tabprops(1)} />
                            <Tab label="Railway Coach" {...tabprops(2)} />
                            <Tab label="Electrical Panels" {...tabprops(3)} />
                            <Tab label="Electrical Panels" {...tabprops(4)} />
                        </Tabs>
                    </Box>
                    <ProductTabs value={value} index={0}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColdRolled/packaging.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Packaging</h2>
                                <p className={classes.gal_card_des} >Steel and tin packaging are durable, recyclable, and
                                    corrosion-resistant, which makes them an ideal sustainable packaging solution for protecting
                                    goods and preserving product quality.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={1}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColdRolled/coatedSheets.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Coated Sheets</h2>
                                <p className={classes.gal_card_des} >Cold-rolled sheets are vital for coated sheets. The process smoothens
                                    the metal for coating. Adding zinc or aluminium boosts its durability and corrosion
                                    resistance. Coated sheets are used in construction, automotive, and appliance manufacturing,
                                    offering improved properties and resilience.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={2}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColdRolled/railwayCoach.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Railway Coach</h2>
                                <p className={classes.gal_card_des} >Cold-rolled sheets are vital in building railway coaches. They provide
                                    strength and precision, ensuring durability and safety. They create a reliable foundation
                                    for the robust structures needed in railway transportation.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={3}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColdRolled/electrical.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Electrical Panels</h2>
                                <p className={classes.gal_card_des} >Cold-rolled sheets are crucial for crafting electrical panel enclosures
                                    and structural elements. Their inherent properties of precise dimensions and smooth surfaces
                                    ensure optimal performance and longevity.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={4}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ColdRolled/automobiles.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Automobiles</h2>
                                <p className={classes.gal_card_des} >Cold-rolled sheets are crucial for constructing various parts in the
                                    automotive industry. The process enhances mechanical properties, ensuring the strength and
                                    longevity of components, resulting in reliable and safe performance on the road.</p>
                            </div>
                        </div>
                    </ProductTabs>
                </Box>
            </div>

            {/* <!-- cards for mobile view  --> */}

            <section className={`${classes.section_strip} ${classes.mobile_section_cards}`}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Applications </h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColdRolled/packaging.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Packaging</span>
                                    <p>Steel and tin packaging are durable, recyclable, and corrosion-resistant, which makes
                                        them an ideal sustainable packaging solution for protecting goods and preserving product
                                        quality.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColdRolled/coatedSheets.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Coated Sheets</span>
                                    <p>Cold-rolled sheets are vital for coated sheets. The process smoothens the metal for
                                        coating. Adding zinc or aluminium boosts its durability and corrosion resistance. Coated
                                        sheets are used in construction, automotive, and appliance manufacturing, offering
                                        improved properties and resilience.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColdRolled/railwayCoach.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Railway Coach</span>
                                    <p>Cold-rolled sheets are vital in building railway coaches. They provide strength and
                                        precision, ensuring durability and safety. They create a reliable foundation for the
                                        robust structures needed in railway transportation.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColdRolled/electrical.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Electrical Panels</span>
                                    <p>Cold-rolled sheets are crucial for crafting electrical panel enclosures and structural
                                        elements. Their inherent properties of precise dimensions and smooth surfaces ensure
                                        optimal performance and longevity.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ColdRolled/automobiles.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Automobiles</span>
                                    <p>Cold-rolled sheets are crucial for constructing various parts in the automotive industry.
                                        The process enhances mechanical properties, ensuring the strength and longevity of
                                        components, resulting in reliable and safe performance on the road..</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <NewsLetterTemplate />
        </>
    );
};

export default ColdRolledSteelTemplate;
