import React, { useEffect, Fragment, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
    children: ReactNode;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {

    const location = useLocation();

    useEffect(() => {
        document.getElementById('main')?.scroll(0,0)
    }, [location.pathname]);

    return <Fragment>{children}</Fragment>;
}

export default ScrollToTop;