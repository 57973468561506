import React from "react";
import { createUseStyles } from "react-jss";
import SelectProductTypeTemplate from "../../templates/VendorManagement/SelectProductType.template";

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const PostProductAddPage: React.FC = () => {
    const classes = useStyles();

    return (
        <div className="grid gap-y-8">
            <div className={` ${classes.mainHeading} text-lg font-medium`}>Add New Product</div>
            <SelectProductTypeTemplate />
        </div>
    );
};

export default PostProductAddPage;