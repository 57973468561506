import React, { useEffect, useState } from 'react';
import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';

interface DocumentViewTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    url: string;
    documentType: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "1000px !important",
            maxWidth: "1000px !important",
            borderRadius: "16px"
        },
    },
    closeIcon: {
        color: theme.palette.v2.text._primaryDark
    }
}));

const DocumentViewTemplate: React.FC<DocumentViewTemplateProps> = ({ dialogOpen, setDialogOpen, url, documentType }) => {
    const classes = useStyles();
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const renderContent = () => {
        const imageTypes = ['jpeg', 'jpg', 'png', 'gif'];
        if (imageTypes.includes(documentType)) {
            return <img src={url} alt="document" className='w-full' />;
        } else if (documentType === 'pdf') {
            return (
                <div>
                    <embed src={`${url}#navpanes=0`} width="1000px" height="850px" type="application/pdf" />
                </div>
            );
        } else {
            return <p>Unsupported document type</p>;
        }
    };

    return (
        <Dialog
            className={classes.container}
            open={dialogOpen}
            onClose={handleDialogClose}
        >
            <div className="flex justify-center items-center h-full">
                {renderContent()}
            </div>
        </Dialog>
    );
};

export default DocumentViewTemplate;
