import { POLICY_SECTION } from "../../../../utils/constant";

interface DisclosureOfInformationProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
}

const DisclosureOfInformation: React.FC<DisclosureOfInformationProps> = ({ contentStyle, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{POLICY_SECTION.DISCLOSURE_OF_INFORMATION}</span>
            </div>
            <div className={contentStyle}>
                3.1 Avighna discloses Your Information to third parties in the following manner, including but not limited to:
                <br /> <br />
                (i) Sellers listed on the Platform, related service providers, and logistics partners for servicing, delivering, and providing credit periods for orders placed by You on the Platform;
                <br /> <br />
                (ii) Potential financiers for availing financing for Your purchases on the Platform;
                <br /> <br />
                (iii) Disclosure of Your Information to companies, agencies, individuals, and other third parties authorized by Us to perform functions on our behalf. Information or User Information may be transferred to third parties (in India or outside India), such as sellers, affiliates, agents, contractors, and third-party service providers processing Your information or User Information on our behalf, including but not limited to those providing administrative or other services like mailing houses, telecommunication companies, information technology companies, and data centers, to enhance the Platform;
                <br /> <br />
                (iv) Sharing Your Information with partners, affiliates, manufacturers, and traders of the Products sold on the Platform for both commercial and non-commercial purposes. This allows them to understand User and visitor activities on the Platform, product sale-related information, and contribute to creating a better overall experience. Your information may also be shared with partners, affiliates, other Platform visitors, and third parties for understanding visitor profiles and usage patterns to serve advertisements and marketing information on the Platform;
                <br /> <br />
                (v) Disclosure and transfer of Your Information or User Information to a third party acquiring business units, whether through merger, consolidation, or purchase of all or a substantial portion of assets;
                <br /> <br />
                (vi) Disclosure of Your Information if legally required.
                <br /> <br />
                3.2 Subject to applicable law, Avighna may, at its sole discretion, transfer Information to any other body corporate (as defined under the Information Technology Act, 2000) ensuring at least the same level of data protection as provided by Avighna under the terms herein, whether located in India or any other country. By using the Platform, You accept these terms and hereby consent to the storage and processing of information in any of the above locations.<br /> <br />
            </div>
        </>

    )
}
export default DisclosureOfInformation;