import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";

const ShearingTemplate: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <section className={classes.produts_banner}>
                <div className={`${classes.products_bannner_wrapper} ${classes.shearing_banner}`}>
                    <div className={classes.products_banner_content}>
                        <h4>Services</h4>
                        <h3 className={classes.main_heading}>Shearing</h3>
                        <h1 className={classes.banner_heading_space}>From Rolls to Precision Sheets : Our Shearing Mastery</h1>
                        {/* <!-- <div className="banner_btn news_btn">
                    <a href="#">Learn More</a>
                </div> --> */}
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className={`${classes.products_about_us} ${classes.card_sp}`}>
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>About Shearing</h2>
                            <p className={classes.gal_card_des} >Sheet metal needs to be cut into manageable lengths for various industrial
                                purposes. Shearing is a vital process that helps to achieve this. Avighna specializes in
                                shearing materials, including carbon steel, aluminum, and stainless steel. You can contact us to
                                explore our tailored processing capabilities and product lines, ensuring your unique needs are
                                met.</p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/Shearing/aboutShearing.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- cards section starts here  --> */}

            <section className={`${classes.section_strip} ${classes.specification_section}`}>
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading_main}>Coil Shearing Services Capabilities</h3>
                    <div className={classes.card_parent_wrap}>
                        <div className={classes.p_card_wrapper}>
                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg6.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Thickness Ranges</span>
                                        <span className={classes.p_card_s_text}>0.25~2.00 mm (±0.003mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg2.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Width Range</span>
                                        <span className={classes.p_card_s_text}>50~1250mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg3.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Length Range</span>
                                        <span className={classes.p_card_s_text}>600-3000 mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg4.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Outer Diameter</span>
                                        <span className={classes.p_card_s_text}>1500-2200 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg5.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Inner Diameter</span>
                                        <span className={classes.p_card_s_text}>510, 600, 610 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg1.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Coil Weight</span>
                                        <span className={classes.p_card_s_text}>3-20 tonnes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- slider secrion starts here  --> */}

            {/* <div className="parent p_secondary">
        <h3 className="slider_heading">Products We Shear</h3>
        <div className="slick">
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsPrePainted.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsElectrolyticTinplate.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsTinFreeSteel.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsPrePainted.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsColdRolled.webp" alt="" />

            </div>
            <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsGalvanized.webp" alt="" />
            </div>
        </div>
    </div> */}

            {/* <!-- our services section starts here  --> */}

            <section className={classes.section_strip}>
                <div className={`${classes.service_cards_main} ${classes.container}`}>
                    <h3>In-House Coil Slitting Capability</h3>
                    <div className={classes.service_card_wrapper}>
                        <div className={classes.service_card_wrap}>
                            <div className={classes.service_card}>
                                <img src="../assets/images/Slitting/cuttingEdgeTech.webp" alt="cutting" />
                                <span>Cutting-edge technology</span>
                            </div>
                        </div>
                        <div className={classes.service_card_wrap}>
                            <div className={classes.service_card}>
                                <img src="../assets/images/Slitting/Inspecting.webp" alt="cutting" />
                                <span>Inspecting coiled material to prevent processing defects</span>
                            </div>
                        </div>
                        <div className={classes.service_card_wrap}>
                            <div className={classes.service_card}>
                                <img src="../assets/images/Slitting/Minimized.webp" alt="cutting" />
                                <span>Minimized scrap and waste</span>
                            </div>
                        </div>
                        <div className={classes.service_card_wrap}>
                            <div className={classes.service_card}>
                                <img src="../assets/images/Slitting/trackedDelivery.webp" alt="cutting" />
                                <span>Tracked delivery schedules</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default ShearingTemplate;
