import React, { useEffect, useRef } from "react";
import suppliersLogoTata from "../../../../src/assets/images/suppliersLogoTata.svg";
import suppliersLogoJsw from "../../../../src/assets/images/suppliersLogoJsw.svg";
import suppliersLogoAm from "../../../../src/assets/images/suppliersLogoAm.svg";
import suppliersLogoSail from "../../../../src/assets/images/suppliersLogoSail.svg";
import suppliersLogoPosco from "../../../../src/assets/images/suppliersLogoPosco.svg";
import suppliersLogoSteel from "../../../../src/assets/images/suppliersLogoSteel.svg";
import { createUseStyles } from "react-jss";
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        margin: "0px 72px"
    },
    heading: {
        color: theme.palette.v2.text.primaryDark
    },
    carouselContainer: {
        background: "linear-gradient(115deg, #FEF1EA 8.97%, #EEF2FE 104.01%)",
        "& .react-multiple-carousel__arrow": {
            display: "none",
        },
        "& .react-multi-carousel-list": {
            width: "1104px",
        },
        "& .react-multi-carousel-list ul": {
            display: "flex",
            alignItems: "center",
            columnGap: "100px"
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
    },
    "@media (max-width: 480px)": {
        container: {
            margin: "0px 16px",
        },
        carouselContainer: {
            padding: '0px',
            "& .react-multi-carousel-list": {
                width: "auto",
                marginLeft: "24px",
                marginRight: "24px",
            },
            "& .react-multi-carousel-list ul": {
                columnGap: "30px"
            },
        },
        heading: {
            color: theme.palette.v3.text._secondaryLightAccent,
            fontSize: '18px',
        },
    },
}));

const SliderTemplate: React.FC = () => {
    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 6,
            partialVisibilityGutter: 15
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 3,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 480, min: 320 },
            items: 2,
            partialVisibilityGutter: 0
        },
        "sm-mobile": {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 10
        }
    };

    return (
        <div className={` grid gap-y-6 ${classes.container}`}>
            <h3 className={`font-semibold text-2xl ${classes.heading}`}>Our Recommended Suppliers</h3>
            <div className={`${classes.carouselContainer} px-24 rounded-3xl overflow-hidden`}>
                <Carousel
                    className="py-8"
                    responsive={responsive}
                    showDots={false}
                    infinite={true}
                    partialVisible={true}
                    ref={carouselRef}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    removeArrowOnDeviceType={["sm-mobile"]}
                    
                >
                    <div className=""><img src={suppliersLogoTata} alt="" /></div>
                    <div className="w-max"><img src={suppliersLogoJsw} alt="" /></div>
                    <div className="w-max"><img src={suppliersLogoAm} alt="" /></div>
                    <div className="w-max"><img src={suppliersLogoSail} alt="" /></div>
                    <div className="w-max"><img src={suppliersLogoPosco} alt="" /></div>
                    <div className="w-max"><img src={suppliersLogoSteel} alt="" /></div>
                    <div className="w-max"><img src={suppliersLogoTata} alt="" /></div>
                    <div className="w-max"><img src={suppliersLogoJsw} alt="" /></div>
                    <div className="w-max"><img src={suppliersLogoAm} alt="" /></div>
                    <div className="w-max"><img src={suppliersLogoSail} alt="" /></div>

                </Carousel>
            </div>
        </div>
    );
};

export default SliderTemplate;
