import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function tabprops(index: number) {
    return {
        id: `productstab-${index}`,
        'aria-controls': `productstabpanel-${index}`,
    };
}

const ProductTabs: React.FC<TabPanelProps> = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`productstabpanel-${index}`}
            aria-labelledby={`productstab-${index}`}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export { ProductTabs, tabprops };
