import React from 'react';
import ReactDOM from 'react-dom';
import ContentLoader from 'react-content-loader';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  container: {
    border: `1px solid ${theme.palette.v4.border._secondaryDarkAccent}`,
    height: "210px"
  },
}));

const Shimmer: React.FC = () => {
  const classes = useStyles();
  return (
    <>
    <div className={`${classes.container} p-3 rounded-lg`}>
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="20" rx="4" ry="4" width="90%" height="37" />
        <rect x="0" y="70" rx="4" ry="4" width="80%" height="37" />
        <rect x="0" y="120" rx="5" ry="4" width="85%" height="37" />
      </ContentLoader>
    </div>
    <div className={`${classes.container} mt-3 p-3 rounded-lg`}>
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="20" rx="4" ry="4" width="80%" height="37" />
        <rect x="0" y="70" rx="5" ry="4" width="85%" height="37" />
        <rect x="0" y="120" rx="5" ry="4" width="70%" height="37" />
      </ContentLoader>
      </div>
      <div className={`${classes.container} mt-3 p-3 rounded-lg`}>
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="20" rx="4" ry="4" width="80%" height="37" />
        <rect x="0" y="70" rx="5" ry="4" width="85%" height="37" />
        <rect x="0" y="120" rx="5" ry="4" width="70%" height="37" />
      </ContentLoader>
      </div>
    </>
  );
};

export default Shimmer;
