import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import BussinessProfileAboutUsEditTemplate from './BussinessProfileAboutUsEditTemplate';
import noData from '../../../assets/images/noData.png';
import { useState } from 'react';
import { getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
    },
    container_heading: {
        color: theme.palette.text.secondaryDark,
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
        background: theme.palette.v4.background._secondaryDarkAccent,
    },
    secondaryText: {
        color: theme.palette.v3.text._secondaryLight,
    },
}));

interface BussinessProfileAboutUsTemplateProps {
    businessProfile: any;
    handleSection: any;
    updateBusinessProfileSection: (section: string, values: any) => void;
    handleUpdateBusinessProfile: () => void;
}

const BussinessProfileAboutUsTemplate: React.FC<BussinessProfileAboutUsTemplateProps> = ({ businessProfile, handleSection, updateBusinessProfileSection, handleUpdateBusinessProfile }) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSuccess = () => {
        setIsEditing(false);
    };

    const handleBack = () => {
        setIsEditing(false);
        handleUpdateBusinessProfile();
    };

    return (
        <>
            {isEditing ? (
                <BussinessProfileAboutUsEditTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    onSuccess={handleSuccess}
                    onBack={handleBack}
                />
            ) : (
                <>
                    {businessProfile?.description ? (
                        <div className={`${classes.container} p-6 rounded-lg w-full`}>
                            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                                <h2 className={`${classes.container_heading} text-2xl font-medium`}>About Us</h2>
                                <Button variant={'tertiaryContained'} label={'Edit'} onClick={handleEditClick} />
                            </div>
                            <div className='rounded-lg mt-3'>
                                <p className='font-medium text-base'>{getProfileDisplayData(businessProfile?.description)}</p>
                            </div>
                        </div>
                    ) : (
                        <div className={`${classes.primaryContainer} py-14 px-6 flex justify-center w-full rounded-lg`}>
                            <div className='flex flex-col items-center'>
                                <img src={noData} alt="No data" className='mb-8' />
                                <p className={`${classes.secondaryText} mb-4 text-lg`}>You haven't provided Description for your business.</p>
                                <Button className='rounded-lg' variant={'primaryContained'} onClick={handleEditClick} label={"Add Description"} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default BussinessProfileAboutUsTemplate;
