import { POLICY_SECTION } from "../../../../utils/constant";

interface ChangesToYourInformationProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
}

const ChangesToYourInformation: React.FC<ChangesToYourInformationProps> = ({ contentStyle, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{POLICY_SECTION.CHANGES_TO_YOUR_INFORMATION}</span>
            </div>
            <div className={contentStyle}>
                4.1 You have the option to review, correct, update, change, or delete Your Account Information or User Information on the Avighna Platform. You can delete specific parts of the Account Information or User Information or request deletion of the entire Account Information or User Information, unless retention is required for legal purposes.
                <br />
                <br />
                4.2 Avighna reserves the right to verify and authenticate Your identity and Your Account Information to ensure accurate delivery of Services. Access to, correction, updating, or deletion of Your Account Information may be denied or limited by Avighna if it would violate another person’s rights and/or is not otherwise permitted by applicable law.
                <br />
                <br />
            </div>
        </>

    )
}
export default ChangesToYourInformation