import { useNavigate } from "react-router-dom";
import SuccessFailurePopup from "../../molecules/SuccessFailurePopup/SuccessFailurePopup";
import { useState } from "react";

interface CartRfqStatusProps {
    rfqStatus: boolean | null;
    onClose: (status: boolean | null) => void;
}

const CartRfqStatus: React.FC<CartRfqStatusProps> = ({ rfqStatus, onClose }) => {
    const navigate = useNavigate()
    const [showPopup, setShowPopup] = useState(true);

    const downloadPdf = () => { };

    const continueToHome = () => {
        navigate("/");
    };

    return (
        <div>
            {showPopup && (
                <SuccessFailurePopup
                    status={rfqStatus as boolean}
                    heading={rfqStatus == true ? "Success" : "Failed"}
                    description={rfqStatus == true ? "Your quotation has been sent." : "Sorry, your quotation has not been sent."}
                    setShowPopup={setShowPopup}
                    // button1={{
                    //   text: 'Download-PDF',
                    //   variant: "tertiaryContained",
                    //   size: "large",
                    //   onClick: downloadPdf,
                    // }}
                    button1={{
                        text: 'Continue',
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: continueToHome
                    }}
                />
            )}
        </div>
    );
};

export default CartRfqStatus;