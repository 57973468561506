import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import emptyCartImage from "./../../../assets/images/emptyCartImage.svg";
import { useNavigate } from "react-router-dom";

const useStyles = createUseStyles((theme: any) => ({
  boldText: {
    color: theme.palette.text.secondaryDark,
  },
  normalText: {
    color: theme.palette.text.secondaryDark,
  },
}));

interface NoProductProps {
    text: string;
    subText: string;
    label: string;
}

const NoProduct: React.FC<NoProductProps> = ({ text, subText, label }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className="grid">
      <img className="max-w-xs m-auto" src={emptyCartImage} alt="pic" />
      <div className="grid gap-y-2 m-auto text-center max-w-sm">
        <div className={`${classes.boldText} text-xl font-bold`}>
         {text}
        </div>
        <div className={`${classes.normalText} text-lg`}>
		{subText}
        </div>
      </div>
      <div className="m-auto mt-6 w-64">
        <Button
          fullWidth
          variant='primaryContained'
          label={label}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    </div>
  );
};

export default NoProduct;
