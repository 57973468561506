import React, { useCallback, useEffect } from "react";
import { createUseStyles, ThemeProvider } from "react-jss";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { defaultTheme } from "./utils/theme";
import {
  AuthenticatedUserProvider,
} from "./contexts/AuthenticatedUserContext";
import { SnackbarProvider } from "./contexts/SnackBarContext";
import { MetaDataProvider } from "./contexts/MetaDataContext";
import { CartProvider } from "./contexts/CartContext";
import CatalogueList from "./components/pages/CatalogueList/CatalogueList.page";
import CatalogueDetails from "./components/pages/Catalogue/CatalogueDetails.page";
import { CUSTOMER_ROUTES } from "./utils/constant";
import Home from "./components/pages/Home/Home.page";
import Header from "./components/organisms/Home/Header";
import Footer from "./components/atoms/Footer/Footer";
import ScrollToTop from "./components/atoms/ScrollToTop/ScrollToTop";
import DashBoard from "./components/pages/DashBoard/DashBoard.page";
import LeadListTemplate from "./components/templates/Lead/LeadList.template";
import LeadUpcViewTemplate from "./components/templates/Lead/LeadUpcView.template";
import QuotationListTemplate from "./components/templates/Lead/QuotationList.template";
import QuotationViewTemplate from "./components/templates/Lead/QuotationView.template";
import OrderListTemplate from "./components/templates/Order/OrderList.template";
import OrderViewTemplate from "./components/templates/Order/OrderView.template";
import OnBoardingPage from "./components/pages/OnBoarding/OnBoarding.page";
import SecondaryCatalogueDetails from "./components/pages/Catalogue/SecondaryCatalogueDetails.page";
import SecondaryLeadListTemplate from "./components/templates/Lead/SecondaryLeadList.template";
import SecondaryLeadUpcViewTemplate from "./components/templates/Lead/SecondaryLeadUpcView.template";
import SecondaryQuotationViewTemplate from "./components/templates/Lead/SecondaryQuotationView.template";
import SecondaryOrderListTemplate from "./components/templates/Order/SecondaryOrderList.template";
import SecondaryOrderViewTemplate from "./components/templates/Order/SecondaryOrderView.template";
import MultipleUpcCatalogueDetails from "./components/pages/Catalogue/MultipleUpcCatalogueDetails.page";
import SecondaryLotCatalouge from "./components/pages/Catalogue/SecondaryLotCatalouge.page";
import SecondaryLeadLotListTemplate from "./components/templates/Lead/SecondaryLeadLotList.template";
import SecondaryLotLeadView from "./components/templates/Lead/SecondaryLotLeadView.template";
import SecondaryLotQuotationViewTemplate from "./components/templates/Lead/SecondaryLotQuotationView.template";
import SecondaryLotsOrderListTemplate from "./components/templates/Order/SecondaryLotsOrderList.template";
import SecondaryLotOrderViewTemplate from "./components/templates/Order/SecondaryLotOrderView.template";
import PrivacyPolicy from "./components/pages/Policies/PrivacyPolicy.page";
import TermsAndConditions from "./components/pages/Policies/TermsAndConditions.page";
import ReactGA from 'react-ga';
import ChangePasswordPage from "./components/pages/DashBoard/ChangePassword.page";
import Wishlist from "./components/pages/Wishlist/Wishlist.page";
import { WishlistProvider } from "./contexts/WishlistContext";
import BussinessLocationPage from "./components/pages/DashBoard/BussinessLocation.page";
import HomePageRoutes from "./components/templates/StaticPage/HomePageRoutes";
import StatutoryDetailPage from "./components/pages/DashBoard/StatutoryDetail.page";
// import CartDetailsNewpage from "./components/pages/Cart/Cart.page";
import BussinessProfile from "./components/templates/BussinessProfile/BussinessProfileMain";
import ProfileOverviewPage from "./components/pages/DashBoard/ProfileOverview.page";
import KeyPersonDetailsPage from "./components/pages/DashBoard/KeyPersonDetails.page";
import Preferences from "./components/pages/Preferences/Preferences.page";
import CartDetails from "./components/pages/Cart/CartDetails.page";
import { pdfjs } from 'react-pdf';
import MyMaterialListingPage from "./components/pages/DashBoard/MyMaterialListing.page";
import MyMaterialAddProductPage from "./components/pages/DashBoard/MyMaterialProduct.page";
import FinishedProductCreationPage from "./components/pages/DashBoard/FinishedProductCreation.page";
import ScrapProductCreationPage from "./components/pages/DashBoard/ScrapProductCreation.page";
import { popLastEntry } from "./utils/helper";
import InventoryManagementPage from "./components/pages/DashBoard/InventoryManagement.page";
import AddNewVendorInventoryTemplate from "./components/templates/DashBoard/Inventory Management/AddNewVendorInventory.template";
import PostProductListPage from "./components/pages/PostProduct/PostProductList.page";
import PostProductUpdatePage from "./components/pages/PostProduct/PostProductUpdate.page";
import PostProductViewPage from "./components/pages/PostProduct/PostProductView.page";
import PostProductAddPage from "./components/pages/PostProduct/PostProductAdd.page";
import InventoryManagementViewTemplate from "./components/templates/DashBoard/Inventory Management/InventoryManagementView.template";
import InventoryManagementEditTemplate from "./components/templates/DashBoard/Inventory Management/InventoryManagementEdit.template";

const TRACKING_ID = "G-XR6P8KD3X9";
ReactGA.initialize(TRACKING_ID);

const useStyles = createUseStyles({
  rootContainer: {
    height: "calc(100vh - 78px)",
    overflow: "auto",
  },
});

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

const App: React.FunctionComponent = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const handlePopState = useCallback(() => popLastEntry(), []);

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handlePopState]);


  return (
    <ThemeProvider theme={defaultTheme}>
      <AuthenticatedUserProvider>
        <BrowserRouter>
          <MetaDataProvider>
              <SnackbarProvider>
                <CartProvider>
                  <WishlistProvider>
                    <div className="w-full fixed">
                      <Routes>
                        <Route path={CUSTOMER_ROUTES.ONBOARDING} element={<OnBoardingPage />} />
                        <Route path="/" element={<div>
                          <Header />
                          <div className={classes.rootContainer} id="main">
                            <ScrollToTop>
                              <Outlet />
                              <Footer />
                            </ScrollToTop>
                          </div>
                        </div>} >
                          <Route path="/*" element={<HomePageRoutes />} />
                          <Route index element={<Home />} />
                          <Route path={CUSTOMER_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
                          <Route path={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
                          <Route path={CUSTOMER_ROUTES.CATALOGUE_LISTING} element={<CatalogueList />} />
                          <Route path={CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION} element={<CatalogueDetails />} />
                          <Route path={CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION} element={<SecondaryCatalogueDetails />} />
                          <Route path={CUSTOMER_ROUTES.CATALOGUE_MTO_VIEW} element={<MultipleUpcCatalogueDetails />} />
                          <Route path={CUSTOMER_ROUTES.SECONDARY_CATALOGUE_LOT_VIEW} element={<SecondaryLotCatalouge />} />
                          <Route path={CUSTOMER_ROUTES.CART} element={<CartDetails />} />
                          <Route path={`${CUSTOMER_ROUTES.DASHBOARD}`} element={<DashBoard />} >
                            <Route path="lead" element={<Outlet />}>
                              <Route index element={<LeadListTemplate />} />
                              <Route path=":leadId/quotation" element={<Outlet />} >
                                <Route index element={<QuotationListTemplate />} />
                                <Route path=":quotationId" element={<QuotationViewTemplate />} />
                              </Route>
                              <Route path=":leadUpcId" element={<LeadUpcViewTemplate />} ></Route>
                            </Route>
                            <Route path="lead-secondary" element={<Outlet />}>
                              <Route index element={<SecondaryLeadListTemplate />} />
                              <Route path=":leadId/quotation" element={<Outlet />} >
                                <Route index element={<QuotationListTemplate />} />
                                <Route path=":quotationId" element={<SecondaryQuotationViewTemplate />} />
                              </Route>
                              <Route path=":leadUpcId" element={<SecondaryLeadUpcViewTemplate />} ></Route>
                            </Route>
                            <Route path="lead-secondary-lot" element={<Outlet />}>
                              <Route index element={<SecondaryLeadLotListTemplate />} />
                              <Route path=":leadId/quotation" element={<Outlet />} >
                                <Route index element={<QuotationListTemplate />} />
                                <Route path=":quotationId" element={<SecondaryLotQuotationViewTemplate />} />
                              </Route>
                              <Route path=":leadUpcId" element={<SecondaryLotLeadView />} >
                              </Route>
                            </Route>
                            <Route path="order" element={<Outlet />}>
                              <Route index element={<OrderListTemplate />} />
                              <Route path=":orderId" element={<OrderViewTemplate />} ></Route>
                            </Route>
                            <Route path="order-secondary" element={<Outlet />}>
                              <Route index element={<SecondaryOrderListTemplate />} />
                              <Route path=":orderId" element={<SecondaryOrderViewTemplate />} ></Route>
                            </Route>
                            <Route path="order-secondary-lot" element={<Outlet />}>
                              <Route index element={<SecondaryLotsOrderListTemplate />} />
                              <Route path=":orderId" element={<SecondaryLotOrderViewTemplate />} ></Route>
                            </Route>
                            <Route path="change-password" element={<ChangePasswordPage />} />
                            <Route path="key-person-details" element={<KeyPersonDetailsPage />} />
                            <Route path="business-location" element={<BussinessLocationPage />} />   {/*----to do later ---set routing */}
                            <Route path="business-profile" element={<BussinessProfile />} />
                            <Route path="my-profile" element={<ProfileOverviewPage />} />
                            <Route path="statutory-documents" element={<StatutoryDetailPage />} />
                            <Route path="preferences" element={<Preferences />} />
                            <Route path="my-material" element={<MyMaterialListingPage />} />
                            <Route path="my-material/add" element={<MyMaterialAddProductPage />} />
                            {/* <Route path="inventory-management" element={<InventoryManagementPage />} /> */}
                            <Route path="inventory-management" element={<Outlet />}>
                              <Route index element={<InventoryManagementPage />} />
                              <Route path="add" element={<AddNewVendorInventoryTemplate />} ></Route>
                              <Route path=":productId" element={<InventoryManagementViewTemplate />} ></Route>
                              <Route path="edit/:productId" element={<InventoryManagementEditTemplate />} ></Route>
                            </Route>
                            <Route path="vendor/product" element={<Outlet />}>
                              <Route index element={<PostProductListPage />} />
                              <Route path="add" element={<PostProductAddPage />} ></Route>
                              <Route path=":vendorId/view/:vendorProductId" element={<PostProductViewPage />} />
                              <Route path="edit/:vendorProductId" element={<PostProductUpdatePage />} />
                            </Route>
                            <Route path="finished-product" element={<Outlet />}>
                              <Route path="add" element={<FinishedProductCreationPage />} />
                              <Route path="edit" element={<FinishedProductCreationPage />} />
                            </Route>
                            <Route path="scrap-product" element={<Outlet />}>
                              <Route path="add" element={<ScrapProductCreationPage />} />
                              <Route path="edit" element={<ScrapProductCreationPage />} />
                            </Route>
                            
                          </Route>
                          <Route path={CUSTOMER_ROUTES.WISHLIST} element={<Wishlist />} />
                          <Route path="*" element={<Navigate replace={true} to="/" />} />
                        </Route>
                      </Routes>
                    </div>
                  </WishlistProvider>
                </CartProvider>
              </SnackbarProvider>
          </MetaDataProvider>
        </BrowserRouter>
      </AuthenticatedUserProvider>
    </ThemeProvider >
  );
};
export default App;