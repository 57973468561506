import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";

const AvighnaMetalPackTemplate: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <section className={classes.produts_banner}>
                <div className={classes.metal_pack_banner}>
                    <div className={classes.products_banner_content}>
                        <h1 className={classes.main_heading}>Avighna Metal Pack</h1>
                        <h3 className={classes.banner_heading_space}>Clean, Green, and Metal Packaging solutions for a Sustainable Tomorrow
                        </h3>
                        {/* <!-- <div className="banner_btn news_btn">
                    <a href="#">Learn More</a>
                </div> --> */}
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className="products_about_us">
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>About Avighna Metal Pack</h2>
                            <p className={classes.gal_card_des} >Packaging is essential in the food industry, serving multiple purposes,
                                including food handling, preservation, safety, and brand promotion. Tin Mill Products are
                                extensively used in food packaging, creating containers such as cans, trays, aerosol containers,
                                caps, closures, and lids. Avighna Steel Pvt. Ltd. is a premier processor and distributor of Tin
                                Mill Products, providing raw materials for producing packaging solutions for a wide range of
                                products. Tin Mill Products encompass Tin Plates, Tin-Free Sheets, and Black Plate Coils/Sheets
                                and are the optimal choice for preserving the contents of food and other sensitive chemicals.
                            </p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/AvighnaMetalPack/aboutAvighnaMetalPack.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- cards section starts here  --> */}

            <section>
                <div className={classes.vision_wrapper}>
                    <div className={`${classes.vision} ${classes.container}`}>
                        <img src="" alt="" />
                        <h3 className={classes.section_heading}>tin mill products distribution</h3>
                        <div className={classes.vision_card_wrapper}>
                            <div className={classes.vision_card}>
                                <div className={classes.vision_card_wrap}>
                                    <div className={classes.vision_card_preview}><img
                                        src="../assets/images/AvighnaMetalPack/electrolyticTinplates.webp" alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Electrolytic Tinplates</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.vision_card}>
                                <div className={classes.vision_card_wrap}>
                                    <div className={classes.vision_card_preview}><img
                                        src="../assets/images/AvighnaMetalPack/tinFreeSteel.webp" alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Tin Free Steel</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.vision_card}>
                                <div className={classes.vision_card_wrap}>
                                    <div className={classes.vision_card_preview}><img
                                        src="../assets/images/AvighnaMetalPack/blackPlate.webp" alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Black Plate</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- cards slider section starts here  --> */}

            {/* <div className="metal_slider_wrapper container">
                <h3 className={classes.section_heading}>TIn Mill Packaging Solutions</h3>
                <div className="slide_wrapper container slick-slider">
                    <div className="slider_card element element-1">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/foodCans.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Food Can</h4>
                                <p>Tin mill products serve food packaging needs, from fruits to meat. Choose steel plates (T-2.5
                                    to
                                    DR-9) based on contents, usage, and coatings.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-2">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/sprayCans.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Spray Cans</h4>
                                <p>Tin Mill Steel, prized for its ductility and surface quality, is the preferred material for
                                    spray
                                    cans in household chemicals.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-3">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/beverageCans.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Beverage Cans</h4>
                                <p>Tin mill products excel as beverage can steel, serving tea, protein, energy, juice, and
                                    coffee
                                    drink packaging with machinability and durability.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-4">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/dICans.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>DI Can</h4>
                                <p>DI cans, vital for beer and sodas, demand tin plates with strong tensile properties. Baosteel
                                    excels in producing them for brands like Coca-Cola.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-5">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/drdCans.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>DRD Cans</h4>
                                <p>DRD cans demand raw materials with exceptional mechanical properties, particularly
                                    anisotropy,
                                    emphasizing stringent quality standards in production.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-6">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/twistOFfCaps.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Twist-off Caps</h4>
                                <p>Baosteel twist-off cap steel, with 0.16mm minimum thickness, offers stability, shape quality,
                                    and
                                    low earning rate, catering to diverse users.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-7">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/easyOpenEnds.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Easy Open Ends</h4>
                                <p>EOE (Easy Open End) production involves two stages: base end creation and attaching it to the
                                    ring, requiring quality tin mill products.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-7">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/chemicalCans.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Chemical Cans</h4>
                                <p>Tinplates excel in making chemical cans, offering excellent machinability and plate shape,
                                    making
                                    them ideal for packaging chemical products.</p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-8">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/milkPowderCans.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Milk Powder Cans</h4>
                                <p>Tinplates for milk powder cans feature immaculate surfaces, serving the industry with
                                    extensive
                                    experience and versatile specifications to meet various requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-9">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/crownCaps.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Crown Caps</h4>
                                <p>Crown caps, resembling crowns, seal beer bottles. They demand steel with precise dimensions,
                                    hardness, and sometimes bending resistance, catering to varied needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-10">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/batteryCaes.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Battery Case</h4>
                                <p>Tin mill products like T-2.5BA and T-3BA serve corporate users in battery case production
                                    with
                                    diverse, high-performance tinplate options.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider_card element element-10">
                        <div className={classes.slider_card_wrap}>
                            <div className={classes.slider_card_preview}>
                                <img src="../assets/images/AvighnaMetalPack/electricRiceCooker.webp" alt="" />
                            </div>
                            <div className={classes.slider_card_content}>
                                <h4>Electric Rice Cooker</h4>
                                <p>Tin mill products like T-4CA, T-3CA, and T-2.5CA are crucial for electric rice cookers,
                                    emphasizing superb surface quality, primarily serving COFCO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <!-- mobile cards slider section starts here  --> */}

            {/* <div className={classes.pack_scroll_hide}>
                <div className="metal_slider_wrapper container mobile_slider">
                    <h3 className={classes.section_heading}>TIn Mill Packaging Solutions</h3>
                    <div className="slide_wrapper container">
                        <div className="slider_card ">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/foodCans.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Food Can</h4>
                                    <p>Tin mill products serve food packaging needs, from fruits to meat. Choose steel plates
                                        (T-2.5 to
                                        DR-9) based on contents, usage, and coatings.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-2">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/sprayCans.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Spray Cans</h4>
                                    <p>Tin Mill Steel, prized for its ductility and surface quality, is the preferred material
                                        for spray
                                        cans in household chemicals.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-3">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/beverageCans.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Beverage Cans</h4>
                                    <p>Tin mill products excel as beverage can steel, serving tea, protein, energy, juice, and
                                        coffee
                                        drink packaging with machinability and durability.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-4">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/dICans.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>DI Can</h4>
                                    <p>DI cans, vital for beer and sodas, demand tin plates with strong tensile properties.
                                        Baosteel
                                        excels in producing them for brands like Coca-Cola.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-5">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/drdCans.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>DRD Cans</h4>
                                    <p>DRD cans demand raw materials with exceptional mechanical properties, particularly
                                        anisotropy,
                                        emphasizing stringent quality standards in production.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-6">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/twistOFfCaps.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Twist-off Caps</h4>
                                    <p>Baosteel twist-off cap steel, with 0.16mm minimum thickness, offers stability, shape
                                        quality, and
                                        low earning rate, catering to diverse users.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-7">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/easyOpenEnds.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Easy Open Ends</h4>
                                    <p>EOE (Easy Open End) production involves two stages: base end creation and attaching it to
                                        the
                                        ring, requiring quality tin mill products.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-7">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/chemicalCans.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Chemical Cans</h4>
                                    <p>Tinplates excel in making chemical cans, offering excellent machinability and plate
                                        shape, making
                                        them ideal for packaging chemical products.</p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-8">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/milkPowderCans.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Milk Powder Cans</h4>
                                    <p>Tinplates for milk powder cans feature immaculate surfaces, serving the industry with
                                        extensive
                                        experience and versatile specifications to meet various requirements.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-9">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/crownCaps.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Crown Caps</h4>
                                    <p>Crown caps, resembling crowns, seal beer bottles. They demand steel with precise
                                        dimensions,
                                        hardness, and sometimes bending resistance, catering to varied needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-10">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/batteryCaes.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Battery Case</h4>
                                    <p>Tin mill products like T-2.5BA and T-3BA serve corporate users in battery case production
                                        with
                                        diverse, high-performance tinplate options.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider_card element element-10">
                            <div className={classes.slider_card_wrap}>
                                <div className={classes.slider_card_preview}>
                                    <img src="../assets/images/AvighnaMetalPack/electricRiceCooker.webp" alt="" />
                                </div>
                                <div className={classes.slider_card_content}>
                                    <h4>Electric Rice Cooker</h4>
                                    <p>Tin mill products like T-4CA, T-3CA, and T-2.5CA are crucial for electric rice cookers,
                                        emphasizing superb surface quality, primarily serving COFCO.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <section className={`${classes.tin_catalog} ${classes.section_strip}`} >
                <div className={classes.scroll_hide}>
                    <h3>Tin Catalog</h3>
                    <div className={`${classes.tin_catalog_wrapper} ${classes.container}`} >
                        <div className={classes.catalog_preview}>
                            <img src="../assets/images/AvighnaMetalPack/tinRound.webp" className="medium_img" alt="" />
                            <span>Round</span>
                        </div>
                        <div className={classes.catalog_preview}>
                            <img src="../assets/images/AvighnaMetalPack/tinSquare.webp" className="medium_img" alt="" />
                            <span>Square</span>
                        </div>
                        <div className={classes.catalog_preview}>
                            <img src="../assets/images/AvighnaMetalPack/tinRectangular.webp" className="medium_img" alt="" />
                            <span>Rectangle</span>
                        </div>
                        <div className={classes.catalog_preview}>
                            <img src="../assets/images/AvighnaMetalPack/tinOval.webp" className="medium_img" alt="" />
                            <span>Oval</span>
                        </div>
                        <div className={classes.catalog_preview}>
                            <img src="../assets/images/AvighnaMetalPack/tinFancy.webp" className="medium_img" alt="" />
                            <span>Fancy</span>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- tabs section starts here  --> */}

            {/* <section className="section_strip">
        <div className="tabs_wrapper container">
            <h3>Applications of Tin Mill Products in Metal Packaging</h3>
            <div className="tab">
                <button className="tablinks" onclick="openCity(event, 'Packaging')">Battery Components</button>
                <button className="tablinks" onclick="openCity(event, 'Coated Sheets')">metal containers</button>
                <button className="tablinks" onclick="openCity(event, 'Railway Coach')">Paints & Chemicals</button>
                <button className="tablinks" onclick="openCity(event, 'Electrical Panels')">Aerosol cans for spray</button>
                <button className="tablinks" onclick="openCity(event, 'Automobiles')">confectionery</button>
            </div>

            <div id="Packaging" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/AvighnaMetalPack/batteryComponents.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Battery Components</h2>
                        <p className={classes.gal_card_des} >Tin mill products are crucial for battery production due to their
                            corrosion-resistant properties and formability. They are used to create casings that protect
                            battery cells and intricate components, resulting in better performance and reliability.</p>
                    </div>
                </div>
            </div>

            <div id="Coated Sheets" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/AvighnaMetalPack/metalContainers.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Metal containers</h2>
                        <p className={classes.gal_card_des} >Tin mill products make durable and corrosion-resistant metal containers
                            for various products. Tin-coated metal containers offer efficient protection against
                            external factors, preserving contents and ensuring long shelf life.</p>
                    </div>
                </div>
            </div>

            <div id="Railway Coach" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/AvighnaMetalPack/paintContainers.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Paints & Chemicals</h2>
                        <p className={classes.gal_card_des} >Tinplate is essential in packaging paints and chemicals. It prevents
                            interactions between the metal and the contents, ensuring quality and composition.
                            Tin-coated containers provide an airtight seal that guarantees product integrity and
                            longevity.</p>
                    </div>
                </div>
            </div>

            <div id="Electrical Panels" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/AvighnaMetalPack/aerosolCans.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Aerosol cans for spray</h2>
                        <p className={classes.gal_card_des} >Tinplate is used to manufacture aerosol cans for sprays like paints and
                            lubricants. The tin coating prevents leakage and maintains stability, while formability
                            allows efficient and controlled dispensing can designs.</p>
                    </div>
                </div>
            </div>

            <div id="Automobiles" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/AvighnaMetalPack/confectionery.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>confectionery</h2>
                        <p className={classes.gal_card_des} >Tin mill products are vital for packaging confectionery, providing
                            corrosion-resistant metal that keeps products uncontaminated. Tinplate's formability allows
                            for innovative packaging designs that enhance presentation.</p>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

            {/* <!-- cards for mobile view  --> */}
            <section className={`${classes.section_strip} ${classes.mobile_section_cards}`}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Applications of Tin Mill Products in Metal Packaging </h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/AvighnaMetalPack/batteryComponents.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Battery Components</span>
                                    <p>Tin mill products are crucial for battery production due to their corrosion-resistant
                                        properties and formability. They are used to create casings that protect battery cells
                                        and intricate components, resulting in better performance and reliability.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/AvighnaMetalPack/metalContainers.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>metal containers</span>
                                    <p>Tin mill products make durable and corrosion-resistant metal containers for various
                                        products. Tin-coated metal containers offer efficient protection against external
                                        factors, preserving contents and ensuring long shelf life.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/AvighnaMetalPack/paintContainers.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Paints & Chemicals</span>
                                    <p>Tinplate is essential in packaging paints and chemicals. It prevents interactions between
                                        the metal and the contents, ensuring quality and composition. Tin-coated containers
                                        provide an airtight seal that guarantees product integrity and longevity.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/AvighnaMetalPack/aerosolCans.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Aerosol cans for spray</span>
                                    <p>Tinplate is used to manufacture aerosol cans for sprays like paints and lubricants. The
                                        tin coating prevents leakage and maintains stability, while formability allows efficient
                                        and controlled dispensing can designs.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`} >
                                    <img src="../assets/images/AvighnaMetalPack/confectionery.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>confectionery</span>
                                    <p>Tin mill products are vital for packaging confectionery, providing corrosion-resistant
                                        metal that keeps products uncontaminated. Tinplate's formability allows for innovative
                                        packaging designs that enhance presentation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <NewsLetterTemplate />
        </>
    );
};

export default AvighnaMetalPackTemplate;