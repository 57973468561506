import React from 'react';
import { createUseStyles } from 'react-jss';
import { getProfileDisplayData } from '../../../utils/helper';

interface CommunicationPreferenceEditProps {
    communication: string;
    contact: string;
    language: string;
}

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.secondaryDark
    },
    values: {
        color: theme.palette.text.secondaryDark
    },
}));

const CommunicationPreferenceEdit: React.FC<CommunicationPreferenceEditProps> = ({ communication, contact, language }) => {
    const classes = useStyles();
    return (
        <div className="flex gap-x-6 mt-6">
            <div className="w-full">
                <div className={`${classes.title} text-sm font-normal`}>Preferred Communication Channel</div>
                <div className={`${classes.values} text-base font-medium`}>{getProfileDisplayData(communication)}</div>
            </div>
            <div className="w-full">
                <div className={`${classes.title} text-sm font-normal`}>Language Preference</div>
                <div className={`${classes.values} text-base font-medium`}>{getProfileDisplayData(language)}</div>
            </div>
            <div className="w-full">
                <div className={`${classes.title} text-sm font-normal`}>Contact Preference</div>
                <div className={`${classes.values} text-base font-medium`}>{getProfileDisplayData(contact)}</div>
            </div>
        </div>
    );
};

export default CommunicationPreferenceEdit;
