import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import radioActive from '../../../../assets/images/radioActive.svg'
import radioInActive from '../../../../assets/images/radioInactive.svg'
import Button from '../../../atoms/Button/Button';
import { FormikProps } from 'formik';
import { IMaterialRequirement, IMaterialRequirementFormik } from '../../../pages/DashBoard/MyMaterialProduct.page';
import { ICategory } from '../../Home/ProductRequirementForm.template';
import { useAdminMasterService } from '../../../../services/useAdminMasterService';
import { HTTP_STATUS } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    divider: {
        height: '1px',
        background: theme.palette.textV2.tertiary.tertiary100
    },
    tertiary100Border: {
        border: `1px solid ${theme.palette.textV2.tertiary.tertiary100}`
    },
    imageBackground: {
        background: theme.palette.backgroundV2.primary.primary50
    },
    title: {
        color: theme.palette.textV2.tertiary.tertiary700
    }
}));

interface CategorySelectionTemplateProps {
    handleOnBack: () => void;
    handleOnNext: () => void;
    formik: FormikProps<IMaterialRequirementFormik>
}

const CategorySelectionTemplate: React.FC<CategorySelectionTemplateProps> = ({ handleOnBack, handleOnNext, formik }) => {
    const classes = useStyles();
    const [categories, setCategories] = useState<ICategory[]>([]);
    const adminMasterService = useAdminMasterService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const handleCategorySelect = (category: { id: number, name: string }) => () => {
        formik.setFieldValue('categoryId', category.id)
        formik.setFieldValue('categoryName', category.name)
        handleOnNext();
    }

    const loadProductCategories = () => {
        adminMasterService.getAllCategories({ level: 2, page: 0, size: 10 })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setCategories(res?.data?.data?.content)
            }).catch((error) => {
                showSnackbar('error', "Error Fetching Category");
            })
    }

    useEffect(()=>{
        loadProductCategories();
    },[])

    return (
        <div>
            <div className={`flex flex-col ${classes.heading}`}>
                {SnackBarComponent}
                <span className={`text-2xl font-medium`}>Select New Product Category</span>
                <span className='text-xs italic font-medium'>(You can select 1 Product Category at a time)</span>
            </div>
            <div className={`${classes.divider} my-3`}></div>
            <div className=' grid grid-cols-5 gap-4'>
                {categories.map(category => (
                    <div onClick={handleCategorySelect(category)} className={`flex gap-2 items-center p-4 rounded-lg break-keep cursor-pointer ${classes.tertiary100Border}`}>
                        <span><img src={formik.values.categoryId === category.id ? radioActive : radioInActive} alt="" /></span>
                        <span className={`!w-[47px] !h-[47px] rounded-full p-2 border-box overflow-hidden ${classes.imageBackground}`}><img src={category.images?.[0]?.path} alt="" className='w-full h-auto rounded-full'/></span>
                        <span className={`${classes.title}`}>{category.name}</span>
                    </div>
                ))}
            </div>
            <div className={`mt-6 mb-4 ${classes.divider}`}></div>
            <div className='flex justify-end gap-x-3'>
                <Button label='Cancel' variant='secondaryContained' onClick={handleOnBack} />
                <Button label='Back' variant='tertiaryContained' onClick={handleOnBack} />
            </div>
        </div>
    )
}

export default CategorySelectionTemplate