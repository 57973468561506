import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface DisplayImageProps {
    path: string
    imageDialogOpen: boolean
    setImageDialogOpenTo: (value: false)=>void;

}

const DisplayImage: React.FC<DisplayImageProps> = ({ path, imageDialogOpen, setImageDialogOpenTo }) => {
    const handleCloseDialog = () => {
        setImageDialogOpenTo(false);
    };
    return (
        <Dialog
            open={imageDialogOpen}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
        >
            <ClickAwayListener onClickAway={handleCloseDialog}>
                <DialogContent>
                    <img className="max-w-full max-h-full" alt="selectedImage" src={path} />
                </DialogContent>
            </ClickAwayListener>
        </Dialog>
    )
}

export default DisplayImage