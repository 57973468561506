import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from '.';
import { replaceUrlParams } from '../utils/helper';

const SCRAP_PRODUCT_URLS = {
    GET_ALL_VENDOR_INVENTORY: "/vendor/:id/products/inventories",
    GET_VENDOR_INVETORY: "/inventory/vendor/:id/warehouse/:warehouseId/product/:productId/inventories",
    CREATE_INVENTORY: "/inventory/inventories",
    GET_WAREHOUSE:"/warehouse/warehouses",
    GET_ALL_PRODUCT:"/vendor/:id/products",
    GET_PRODUCT_DETAIL:"/vendor/:id/product/:productId",
}

interface IVendorInventories {
   classType: string;
   page:number;
   size:number;
   productIdString?:string;
   search?:string;
   status?:string;
}

interface IWarehouse {
    // key?: string;
    vendorId?: number;
    status?: string;
 }

interface IInventory {
    vendorId: number;
    productId: number;
    warehouseId: number;
    vendorUsnCode: string;
    netWeight: number;
    grossWeight: number;
    packagingType: string;
    isMillTest: boolean;
    status: string;
    uom: string;
    warehousePlacement: string;
 }

export const useVendorInventoryService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getAllVendorInventories = (id: number , params: IVendorInventories) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_ALL_VENDOR_INVENTORY, {id}), authenticatedUser, null, { params });
    }

    const getAllInvendorMappedVenndor = (id: number, warehouseId: number, productId: number, params: IVendorInventories) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_VENDOR_INVETORY, { id, warehouseId, productId }), authenticatedUser, null, {params});
    }

    const createInventories = (data: IInventory[]) => {
        return request(API_METHOD.POST, SCRAP_PRODUCT_URLS.CREATE_INVENTORY, authenticatedUser, data);
    };

    const getAllWarehouseMappedVendor = ( params: IWarehouse) => {
        return request(API_METHOD.GET, SCRAP_PRODUCT_URLS.GET_WAREHOUSE, authenticatedUser, null, { params });
    }

    const getAllProducts = (id: number , params: IVendorInventories) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_ALL_PRODUCT, {id}), authenticatedUser, null, { params });
    }

    const getDetailedProduct = (id: number , productId: number ) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_PRODUCT_DETAIL, {id, productId}), authenticatedUser);
    }

    return {
        getAllVendorInventories,
        getAllInvendorMappedVenndor,
        createInventories,
        getAllWarehouseMappedVendor,
        getAllProducts,
        getDetailedProduct

    };
};
