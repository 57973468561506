import { request } from "."
import { API_METHOD } from "../utils/constant";

export const GST_URL = {
    GST_VERIFY: "/gstin",
    GET_GST: "/details"
}

export const useGstinService = () => {

    const searchGstin = (data: {gstin?: string, userId?:string}) => {
        return request(API_METHOD.POST, GST_URL.GST_VERIFY, null, data);
    }

    const getGstin = (data: {gstin?: string, userId?:string}) => {
        return request(API_METHOD.POST, GST_URL.GET_GST, null, data);
    }

    return {
        searchGstin,
        getGstin
    }
};