import { FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { INDUSTRY_TYPES } from '../../../utils/constant';
import { useCategoryService } from '../../../services/useCategoryService';
import { IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { FormikProps } from 'formik';
import { ICategory } from '../Home/ProductRequirementForm.template';
import { useAdminMasterService } from '../../../services/useAdminMasterService';

interface ProductTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
};


export interface IProduct {
    business: {
        industry: string,
        categories: string
    }
}

const useStyles = createUseStyles((theme: any) => ({
    sectionText: {
        color: theme.palette.text.primaryDark
    },
    black: {
        color: theme.palette.text.primaryLightAccent
    }, dullText: {
        color: theme.palette.text.secondaryLightAccent
    },
    selected: {
        border: `1px solid ${theme.palette.button.primaryDark}`,
        background: theme.palette.background.secondaryLightAccent,
    }
}))

const ProductTemplate: React.FC<ProductTemplateProps> = ({ formik, setFormState }) => {
    const classes = useStyles();
    const catagoryService = useCategoryService();
    const adminMasterService = useAdminMasterService();
    const businessService = useBusinessService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [categories, setCategoriesTo] = useState<ICategory[]>([]);
    const [ errors, setErrors ] = useState({ industry: false, product: false });
    const [loading, setLoading] = useState(false);
    const loadCategories = async () => {
        try {
            adminMasterService.getAllCategories({ level: 2, page: 0, size: 10 })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setCategoriesTo(res?.data?.data?.content)
            }).catch((error) => {
                showSnackbar('error', "Error Fetching Category: ");
            })
        } catch (error) {
            setCategoriesTo([]);
        }
    };

    useEffect(() => {
        loadCategories();
    }, [])

    const handleSelect = (option: any, existingSelection: string[], formik: any, section: string, field: string) => {
        const selection = new Set(existingSelection);
        if (selection.has(option)) {
            selection.delete(option);
        } else {
            if (field !== 'categories') {
                selection.clear();
            }
            selection.add(option);
        }
        formik.setFieldValue(field, Array.from(selection));
        setErrors(prev => ({ ...prev, [section]: false }));
    };
    
    const handleSubmit = () => {
        const selectedCategories = formik.getFieldProps("categories").value;
        const selectedIndustry = formik.getFieldProps("industry").value;
        if (selectedIndustry.length === 0 || selectedCategories.length === 0) {
            setErrors({ industry: !!!selectedIndustry.length, product: !!!selectedCategories.length })
            return;
        }
        setLoading(true)
        user && businessService.updateBusiness({ business: { industry: selectedIndustry.join(","), categories: selectedCategories.join(",") } }, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    formik.setFieldValue('industry', selectedIndustry);
                    formik.setFieldValue('categories', selectedCategories);
                    setFormState(ONBOARDING_STATES.REQUIREMENTS)
                }
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally(() => {setLoading(false)});
    }

    return (
        <div className='select-none'>
            {SnackBarComponent}
            <span className={`text-xl ${classes.black} font-semibold`}>Please Select Your Industry</span>
            <div className='mt-3 mb-8'>
                <div className='flex gap-2 flex-wrap'>
                    {
                        INDUSTRY_TYPES.map((option,index) => (
                            <span className={`py-2 px-4 text-lg border rounded border-accent-gray-950 accent-gray-800 w-fit cursor-pointer ${formik.getFieldProps("industry").value.includes((option.value)) && classes.selected}`}
                                onClick={() => handleSelect(option.value, formik.getFieldProps("industry").value, formik, 'industry', 'industry')} key={index}>{option.label}</span>
                        ))
                    }
                </div>
                {errors.industry &&
                    <FormHelperText error className='top-half '>Industry is required</FormHelperText>
                }
            </div>
            <span className={`text-xl ${classes.black} font-semibold`}>Please Select Your Product Categories</span>
            <div className='mt-3 mb-8'>
                <div className='grid container grid-cols-3 gap-3'>
                    {
                        categories?.map((category,index) => (
                            <div className={`text-lg border rounded-lg border-accent-gray-950 accent-gray-800 w-fit cursor-pointer flex flex-col items-center w-full px-4 pb-4 gap-1 ${formik.getFieldProps("categories").value.includes(category?.id.toString()) && classes.selected}`}
                                onClick={() => handleSelect(category.id.toString(), formik.getFieldProps("categories").value, formik, 'product', 'categories')} key={index}>
                                <div key={category.id} className='flex justify-center w-24'>
                                    <img src={category?.images?.at(0)?.path} alt={""} className='w-3/4 aspect-square' />
                                </div>
                                <div className='text-center'>{category?.name}</div>
                            </div>
                        ))
                    }
                </div>
                {errors.product &&
                    <FormHelperText error className='top-half '>Product Category is required</FormHelperText>
                }
            </div>
            <Button variant="containedLight" label="Next" className="!h-12 mt-8" fullWidth onClick={handleSubmit} isLoading={loading}/>
        </div>
    )
}

export default ProductTemplate