import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { request } from "../services";
import { API_METHOD } from "../utils/constant";

export interface IUpdateWishlistRequest {
    userId: number
    favourites: {
        catalogueIds: number[];
        secondaryCatalogueIds: number[]
    };
}

export const WISHLIST_URLS = {
    GET_WISHLIST: "/wishlist",
    UPDATE_WISHLIST: "/wishlist",
};
export const useWishlistService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getWishlist = () => {
        return request(API_METHOD.GET, WISHLIST_URLS.GET_WISHLIST, authenticatedUser, null);
    };

    const updateWishlist = (data: IUpdateWishlistRequest) => {
        return request(API_METHOD.PUT, WISHLIST_URLS.UPDATE_WISHLIST, authenticatedUser, data);
    };

    return {
        getWishlist,
        updateWishlist,
    };
};