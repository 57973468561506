import * as React from 'react';
import ourServicesShearing from '../../../../src/assets/images/ourServicesShearing.png';
import ourServicesBlanking from '../../../../src/assets/images/ourServicesBlanking.png';
import ourServicesLogistics from '../../../../src/assets/images/ourServicesLogistics.png';
import ourServicesMetalPrinting from '../../../../src/assets/images/ourServicesMetalPrinting.png';
import ourServicesWareHousing from '../../../../src/assets/images/ourServicesWareHousing.png';
import ourServiceslitting from '../../../../src/assets/images/ourServiceslitting.png';

import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        margin: "0px 72px"
    },
    primaryContainer: {
        
    },
    secondaryContainer: {
        border: "1px solid #D6DEF5",
        "& h3": {
            background: " linear-gradient(129deg, #FD6102 26.37%, #7B4847 55.96%, #1E3679 78.2%)",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
        }
    },
    secondaryContent: {
        color: theme.palette.v2.text.primaryDark,
    },
    serviceCard: {
        height: "100%",
        border: `1px solid ${theme.palette.v2.border.primaryLight}`

    },
    cardPreview: {
        height: "132px",
        overflow: "hidden",
        "& img": {
            width: "100%",
        }
    },
    cardContent: {
        lineHeight: "22.4px",
        color: theme.palette.v3.text.secondaryLight,
    },
    cardHeading: {
        color: theme.palette.v2.text.primaryDark,
    },
    "@media (max-width: 480px)": {
        container: {
            padding: "0px 16px !important",
            margin: '0px',
            width: '100vw',
            overflow: 'auto',
        },
        serviceCard: {
            width: '300px',
        },
        primaryContainer: {
            display: 'flex',
            gap: '16px',
            paddingRight: '16px',
        },
        secondaryContainer: {
            border: "1px solid #D6DEF5",
            width: '300px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            "& h3": {
                fontSize: '36px',
            }
        },
        secondaryContent: {
            fontSize: '12px !important',
            lineHeight: '13px !important',
            color: theme.palette.v3.text._secondaryLightAccent,
        },
        cardContent: {
            color: theme.palette.v3.text._secondaryLightAccent,
        },
    },
}));


const OurServicesTemplate: React.FC<{}> = () => {
    const classes = useStyles()

    return (
        <div className={`flex gap-x-6 ${classes.container}`}>
            <div className={`${classes.secondaryContainer} flex flex-col gap-y-6 p-6 rounded-2xl w-1/4`}>
                <h3 className='text-5xl font-semibold'>Our Customised Services</h3>
                <p className={`${classes.secondaryContent} !text-lg font-normal`}>Designed to address your specific needs and deliver optimal results that optimise efficiency and enhance productivity.</p>
                <p className={`${classes.secondaryContent} !text-lg font-semibold`}>Experience the difference where your satisfaction is our top priority.</p>
            </div>
            <div className={`${classes.primaryContainer} grid grid-cols-3 gap-6 w-3/4`}>
                <div className="">
                    <a href="https://avighnasteel.in/pages/shearing.html">
                        <div className={`${classes.serviceCard} card_wrapper  rounded-2xl overflow-hidden`}>
                            <div className={classes.cardPreview}>
                                <img src={ourServicesShearing} alt="" />
                            </div>
                            <div className='px-6 py-5'>
                                <span className={`${classes.cardHeading} text-lg font-medium`}>Shearing</span>
                                <p className={`${classes.cardContent} text-base font-normal`}>Cutting the Coils into desired Sheet Lengths with precise tolerances.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div className=''>
                    <a href="https://avighnasteel.in/pages/slitting.html">
                        <div className={`${classes.serviceCard}  rounded-2xl overflow-hidden`}>
                            <div className={classes.cardPreview}>
                                <img src={ourServiceslitting} alt="" />
                            </div>
                            <div className='px-6 py-5'>
                                <span className={`${classes.cardHeading} text-lg font-medium`}>Slitting</span>
                                <p className={`${classes.cardContent} text-base font-normal`}>Splitting wide coiled sheet metal into narrower widths or for edge trimming of rolled sheet.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div className=''>
                    <a href="">
                        <div className={`${classes.serviceCard}  rounded-2xl overflow-hidden`}>
                            <div className={classes.cardPreview}>
                                <img src={ourServicesBlanking} alt="" />
                            </div>
                            <div className='px-6 py-5'>
                                <span className={`${classes.cardHeading} text-lg font-medium`}>Blanking</span>
                                <p className={`${classes.cardContent} text-base font-normal`}>Cutting or shearing metal sheet  into predetermined shapes and sizes.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div className=''>
                    <a href="">
                        <div className={`${classes.serviceCard}  rounded-2xl overflow-hidden`}>
                            <div className={classes.cardPreview}>
                                <img src={ourServicesMetalPrinting} alt="" />
                            </div>
                            <div className='px-6 py-5'>
                                <span className={`${classes.cardHeading} text-lg font-medium`}>Metal Printing</span>
                                <p className={`${classes.cardContent} text-base font-normal`}>Printing  high-quality images and text onto various types of sheet metal surfaces</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div className=''>
                    <a href="">
                        <div className={`${classes.serviceCard}  rounded-2xl overflow-hidden`}>
                            <div className={classes.cardPreview}>
                                <img src={ourServicesWareHousing} alt="" />
                            </div>
                            <div className='px-6 py-5'>
                                <span className={`${classes.cardHeading} text-lg font-medium`}>Warehousing Services</span>
                                <p className={`${classes.cardContent} text-base font-normal`}>Providing safe material handling and proper storage for Steel Coil and Sheet products.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div className=''>
                    <a href="">
                        <div className={`${classes.serviceCard}  rounded-2xl overflow-hidden`}>
                            <div className={classes.cardPreview}>
                                <img src={ourServicesLogistics} alt="" />
                            </div>
                            <div className='px-6 py-5'>
                                <span className={`${classes.cardHeading} text-lg font-medium`}>Steel Logistics</span>
                                <p className={`${classes.cardContent} text-base font-normal`}>Integrated Transport Services for Delivering Steel Materials safe and Just in Time.</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

    );
};

export default OurServicesTemplate;
