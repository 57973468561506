import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useUserService } from '../../../services/useUserService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { PASSWORD_CHECKLIST, REGEX } from '../../../utils/constant';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as Yup from "yup";
import Visiblity from "../../../assets/icons/outlinedVisiblity.svg"
import VisiblityCrossed from "../../../assets/icons/outlinedVisiblityCrossed.svg"

import {
  TextField,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Button from '../../atoms/Button/Button';
import { useFormik } from 'formik';
import SuccessDialogTemplate from '../../templates/Home/SuccessDialog.template';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import { Visibility } from '@mui/icons-material';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { AuthenticatedUserType } from '../../../contexts/AuthenticatedUserContext';

const useStyles = createUseStyles((theme: any) => ({
  titleText: {
    color: theme.palette.v2.text.primaryDark
  },
  container: {
    border: `1px solid ${theme.palette.border.secondaryLight}`
  },
  sectionText: {
    color: theme.palette.text.primaryDarkAccent
  },
  successGreenColor: {
    color: theme.palette.success.secondaryDark
  },
  content: {
    color: theme.palette.button.primaryLight
  },
  checkContainer: {
    background: theme.palette.v4.background._secondaryLight
  },
  label: {
    color: theme.palette.v4.text._secondaryDark
  },
  defaultLabel: {
    color: theme.palette.textV2.secondary.secondary700
  },
  errorRed: {
    color: theme.palette.textV2.failure.failure500
  }
}));

export interface VerifyTokenParams {
  token: string | null
}

export interface IPasswordPayload {
  oldPassword: string | null,
  newPassword: string,
  confirmPassword: string
}

interface ShowPassword {
  old: boolean,
  new: boolean,
  confirm: boolean
}

interface PASSWORD_CHECKS {
  uppercase: boolean,
  minLength: boolean,
  lowercase: boolean,
  symbol: boolean,
  number: boolean,
}

const showPasswordInitialValue = {
  old: false, new: false, confirm: false
}

const validationSchema1 = Yup.object({
  oldPassword: Yup.string().required('Old Password is required')
    .min(8, 'Old Password must be at least 8 characters'),
  newPassword: Yup.string()
    .required('New Password is required')
    .notOneOf([Yup.ref('oldPassword')], 'New Password must be different from Old Password'),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match').required('Confirm Password is required')
})

const validationSchema2 = Yup.object({
  newPassword: Yup.string()
    .required('New Password is required')
    .notOneOf([Yup.ref('oldPassword')], 'New Password must be different from Old Password'),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match').required('Confirm Password is required')
})


const ChangePasswordPage = () => {
  const classes = useStyles();
  const userService = useUserService();
  const { user, setAuthenticatedUser } = useAuthenticatedUser();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const [showPassword, setShowPassword] = useState<ShowPassword>(showPasswordInitialValue);
  const [isSaveActive, setIsSaveActive] = useState<boolean>(false);
  const [isSuccessDialogActive, setIsDialogActive] = useState(false);
  const [passwordChecks, setPasswordChecks] = useState<PASSWORD_CHECKS>({
    uppercase: false,
    minLength: false,
    lowercase: false,
    symbol: false,
    number: false,
  });

  const toggleShowPassword = (key: string) => () =>
    setShowPassword({ ...showPassword, [key]: !showPassword[key as keyof ShowPassword] })

  const toggleIsSaveDisable = () => {
    setIsSaveActive(
      Object.values(passwordChecks).every(check => check) &&
      !!!Object.keys(formik.errors).find(key => ['newPassword', 'oldPassword', 'confirmPassword'].includes(key))
    )
  }

  const handlePasswordChange = (value: string) => {
    formik.setFieldValue('newPassword', value)
    setPasswordChecks({
      uppercase: REGEX.UPPERCASE.test(value),
      minLength: value.length >= 8,
      lowercase: REGEX.LOWERCASE.test(value),
      symbol: REGEX.SYMBOL.test(value),
      number: REGEX.NUMBER.test(value),
    });
  };

  const handlePasswordSuccessResponse = (isDialogActive: boolean) => {
    setIsDialogActive(false);
  }
   
  const formik = useFormik<IPasswordPayload>({
    initialValues: {
      oldPassword: !!user?.password ? "" : null,
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: !!user?.password ? validationSchema1 : validationSchema2,
    validateOnMount: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      userService.updatePassword({ oldPassword: values.oldPassword, newPassword: values.newPassword })
        .then(res => {
          if (res?.status === HTTP_STATUS.OK) {
            const editedUser = {...user};
            editedUser.password = true;
            setAuthenticatedUser(editedUser as AuthenticatedUserType)
            resetForm();
            setIsDialogActive(true);
          } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
            showSnackbar('error', res?.data?.statusMessage)
          } else {
            showSnackbar('error', "Error while updating password")
          }
        }).catch(error => {
          showSnackbar('error', "Something went wrong")
          console.error("Update Password", error)
        }).finally(() => setSubmitting(false))
    }
  });

  useEffect(() => {
    toggleIsSaveDisable();
    handlePasswordChange(formik.values.newPassword)
  }, [formik.errors])

  return (
    <div>
      <div className={`${classes.titleText} font-semibold text-lg mb-6`}>Change Password</div>
      <SuccessDialogTemplate
        title='Password Changed!'
        content='Your password has been changed successfully. You can now use your new password for future activities.'
        dialogOpen={isSuccessDialogActive}
        setDialogOpen={handlePasswordSuccessResponse}
      />
      <div className={`${classes.container} p-6 rounded-lg`}>
        <div className="auth-right-child-container flex flex-col w-full" >
          {SnackBarComponent}
          <div className="form-container flex flex-col grow w-full">
            <form className="flex flex-col gap-y-6 items-start mb-3 text-lg w-1/2" onSubmit={formik.handleSubmit}>
              <div className='w-full flex flex-col gap-y-6'>
                { !!user?.password && <div>
                  <TextFieldV2
                    label="Please Enter Your Old Password"
                    type={showPassword.old ? "text" : "password"}
                    {...formik.getFieldProps("oldPassword")}
                    variant="outlined"
                    fullWidth
                    className='!mt-0'
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword('old')}
                          edge="end"
                          className='!mr-1'
                        >
                          {showPassword.old ? <img src={VisiblityCrossed} /> : <img src={Visiblity} width='200%'/>}
                        </IconButton>
                      </InputAdornment>
                    }}
                    error={formik.touched?.oldPassword && Boolean(formik.errors?.oldPassword)}
                    // former
                  />
                  {formik.touched?.oldPassword && Boolean(formik.errors?.oldPassword) &&
                    <div className='flex w-full'>
                      <FormHelperText error >{formik.errors?.oldPassword}</FormHelperText>
                    </div>
                  }
                </div>}
                <div>
                  
                  <TextFieldV2
                    label="Please Enter Your New Password"
                    {...formik.getFieldProps("newPassword")}
                    type={showPassword.new ? "text" : "password"}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    className='!mt-0'
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword('new')}
                          edge="end"
                          className='!mr-1'
                        >
                          {showPassword.new ? <img src={VisiblityCrossed} /> : <img src={Visiblity} />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                    error={formik.touched?.newPassword && Boolean(formik.errors?.newPassword)}
                  />
                  {formik.touched?.newPassword && Boolean(formik.errors?.newPassword) &&
                    <div className='flex w-full'>
                      <FormHelperText error className=''>{formik.errors?.newPassword}</FormHelperText>
                    </div>
                  }
                </div>
                <div className={`grid w-full grid-cols-2 py-4 px-5 rounded select-none pointer-none text-sm ${classes.checkContainer}`}>
                    {Object.entries(PASSWORD_CHECKLIST).map(([key, value]) => (
                      <div key={key}>
                        <Checkbox
                          size='small'
                          checked={passwordChecks[key as keyof PASSWORD_CHECKS]}
                          icon={formik.touched.newPassword && !passwordChecks[key as keyof PASSWORD_CHECKS] ? <CancelIcon className={`${classes.errorRed}`}/> :<CheckCircleOutlineIcon className={`${classes.defaultLabel}`}/>}
                          checkedIcon={<CheckCircleIcon className={`${classes.successGreenColor}`} />}
                          disabled
                        />
                        <span className={` ${formik.touched.newPassword && !passwordChecks[key as keyof PASSWORD_CHECKS] ? classes.errorRed : passwordChecks[key as keyof PASSWORD_CHECKS] ? classes.successGreenColor : classes.defaultLabel}`}>
                          {value}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className='w-full'>
                <TextFieldV2
                  label="Please Confirm Your New Password"
                  {...formik.getFieldProps("confirmPassword")}
                  type={showPassword.confirm ? "text" : "password"}
                  variant="outlined"
                  className='!m-0'
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword('confirm')}
                        edge="end"
                        className='!mr-1'
                      >
                        {showPassword.confirm ?  <img src={VisiblityCrossed} /> : <img src={Visiblity} />}
                      </IconButton>
                    </InputAdornment>,
                  }}
                  error={formik.touched?.confirmPassword && Boolean(formik.errors?.confirmPassword)}
                />
                {formik.touched?.confirmPassword && Boolean(formik.errors?.confirmPassword) &&
                  <div className='flex w-full'>
                    <FormHelperText error className=''>{formik.errors?.confirmPassword}</FormHelperText>
                  </div>
                }
              </div>
              <Button variant="contained" label="Save" type="submit" className={`!h-12 !text-lg !mr-0 !px-6 !py-3`} isLoading={formik.isSubmitting} disabled={!isSaveActive || formik.isSubmitting}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordPage