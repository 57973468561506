import * as React from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import noData from '../../../assets/images/noData.png';
import BussinessProfileGSTEditTemplate from './BussinessProfileGSTEdit.template';
import { getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
    },
    secondaryContainer: {
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
        background: theme.palette.v4.background._secondaryDarkAccent,
    },
    container_heading: {
        color: theme.palette.text.secondaryDark,
    },
    card_content: {
        "& span": {
            color: theme.palette.text.secondaryDark,
        },
        "& p": {
            color: theme.palette.text.secondaryDark,
        }
    },
    secondaryText: {
        color: theme.palette.v3.text._secondaryLight,
    },
}));

interface BussinessGSTTemplateProps {
    businessProfile: any,
    handleSection: any,
    updateBusinessProfileSection: (section: string, values: any) => void,
    handleUpdateBusinessProfile: () => void;
}

const BussinessProfileGSTTemplate: React.FC<BussinessGSTTemplateProps> = ({ businessProfile, handleSection, updateBusinessProfileSection, handleUpdateBusinessProfile }) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    
    const handleEditClick = () => {
        setIsEditing(true);        
    };

    const handleSuccess = () => {
        setIsEditing(false);
    };
    
    const handleBack = () => {
        setIsEditing(false);
        handleUpdateBusinessProfile();
    };

    return (
        <>
            {isEditing ? (
                <BussinessProfileGSTEditTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    onSuccess={handleSuccess} onBack={handleBack} gstformik={undefined} />
            ) : (
                <>
                    {businessProfile?.gstin ? (
                        <div className={`${classes.container} p-6 rounded-lg w-full`}>
                            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                                <h2 className={`${classes.container_heading} text-2xl font-medium`}>GST Details</h2>
                                {/* <Button variant={'tertiaryContained'} label={'Edit'} onClick={handleEditClick} /> */}
                            </div>
                            {businessProfile?.address && (
                                <div>
                                    <div className='flex gap-x-9 py-6'>
                                        <div className={`${classes.card_content} flex gap-2`}>
                                            <span >GST Registered Number:</span>
                                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.gstin)}</p>
                                        </div>
                                        <div className={`${classes.card_content} flex gap-2`}>
                                            <span>PAN Number:</span>
                                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.pan)}</p>
                                        </div>
                                    </div>

                                    <div className={`${classes.primaryContainer} p-4 rounded-xl w-1/2`}>
                                        <h5 className={`${classes.container_heading} font-medium text-base mb-8`}>Principal Place of Business</h5>
                                        <div className={`${classes.card_content} max-w-lg mb-3 break-all`}>
                                            <span>Address Line 1</span>
                                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.line1)}</p>
                                        </div>
                                        <div className={`${classes.card_content} max-w-lg mb-3 break-all`}>
                                            <span>Address Line 2</span>
                                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.line2)}</p>
                                        </div>
                                        <div className='grid grid-cols-3 gap-3'>
                                            <div className={classes.card_content}>
                                                <span>Pincode</span>
                                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.pincode)}</p>
                                            </div>
                                            <div className={classes.card_content}>
                                                <span>City</span>
                                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.city)}</p>
                                            </div>
                                            <div className={classes.card_content}>
                                                <span>State</span>
                                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.state)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={`${classes.secondaryContainer} py-14 px-6 flex justify-center w-full rounded-lg`}>
                            <div className='flex flex-col items-center'>
                                <img src={noData} alt="" className='mb-8' />
                                <p className={`${classes.secondaryText} mb-4 text-lg`}>You haven't provided GST details for your business.</p>
                                <Button className=' rounded-lg' variant={'primaryContained'} onClick={handleEditClick} label={"Add GST Details"} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default BussinessProfileGSTTemplate;
