import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const CONFIGURATION_URLS = {
    CONFIGURATION: "/configurations/"
  };
  
export const useConfigurationService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

	const getConfiguration = (contexts: string) => {
		return request(API_METHOD.GET, replaceUrlParams(CONFIGURATION_URLS.CONFIGURATION, { contexts } ), authenticatedUser);
	};

  const getConfiguration1 = (params: any) => {
    return request(API_METHOD.GET, CONFIGURATION_URLS.CONFIGURATION, authenticatedUser, null, { params } );
  };

  return {
    getConfiguration,
    getConfiguration1
  };
};
