import { POLICY_SECTION } from "../../../../utils/constant";

interface ExternalLinksProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
}

const ExternalLinks: React.FC<ExternalLinksProps> = ({ contentStyle, boxStyle, subHeading }) => {
    return (
        <>
            <div className={contentStyle}>
                <div className={boxStyle}>
                    <span className={subHeading}>{POLICY_SECTION.EXTERNAL_LINKS_ON_THE_PLATFORM}</span>
                </div>
                6.1 Avighna may permit other companies or entities to deliver advertisements, research, and news to Users on the Platform. These entities may include third-party advertisement servers, advertisement agencies, advertisement technology vendors, and research and news firms. Avighna may target certain advertisements to Users fitting a specific general profile, but personally identifiable information is not used for this purpose. In serving advertisements or optimizing Services, Avighna may authorize third parties to place or recognize a unique cookie on the User’s browser.
                <br />
                <br />
                6.2 Avighna does not control third-party websites linked from within the Platform. These sites may place their cookies or files on the Users’ computer, collect data, or solicit Personal Information or Financial Information for which Avighna is not responsible. Avighna makes no representations about the privacy practices or policies of such third parties, the terms of use of those websites, or the accuracy, integrity, or quality of the information available on such websites. Inclusion or exclusion of links does not imply endorsement by Avighna of such websites, their providers, or the information on the websites.
                <br />
                <br />
            </div>
        </>
    );
};

export default ExternalLinks;
