import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export const ADMIN_MASTER_URLS = {
    GET_ALL_CATEGORY: "/customer/categories",
    GET_ALL_CATEGORY_BY_IDS: "/customer/categories-by-ids",
    GET_ALL_MANUFACTURER: "/customer/manufacturers",
    GET_ALL_STANDARD: "/customer/standards",
    GET_ALL_QUALITY_GRADE: "/customer/quality-grades",
    GET_ALL_BRANDS_BY_MANUFACTURER_IDS: "/customer/brands-by-manufacturer"
};

export interface IManufacturerFilterRequest {
    search?: string;
    status?: string;
    name?: string;
    manufacturerType?: string;
    page: number;
    size: number;
    sort?: string;
}

export interface ICategoryFilterRequest {
    name?: string;
    level: number;
    parentId?: number | string;
    status?: string;
    page: number;
    size: number;
    sort?: string;
    id?: number | null | string;
}

export interface IStandardFilterRequest {
    search?: string;
    status?: string;
    page: number;
    size: number;
    sort?: string;
}

export interface IQualityGradeFilter {
    standardId?: number;
    search?: string;
    standardName?: string;
    type: string;
    page: number;
    size: number;
    sort?: string;
}

export interface ICategory {
    id: number;
    name: string;
    status: string;
    description: string;
    prefix: string;
    level: number;
    imageId: number;
    createdBy: string;
    updatedBy: string;
    qualityGrades: string;
    standards: string;
    atributes: string;
    parentId: number;
    createdAt: string;
    updatedAt: string;
}

export interface IBrand {
    createdAt: string;
    createdBy: string;
    description: string;
    id: number;
    logoPath: number;
    manufacturerId: number;
    manufacturerName: string;
    name: string;
    prefix: string;
    status: string;
    updatedAt: string;
    updatedBy: string;
    categories?: ICategory[];
}

export interface IManufacturer {
    id: number;
    name: string;
    shortName: string;
    code: string;
    logoPath: number;
    status: string;
    manufacturerType: string;
    createdBy: number;
    updatedBy: number;
    registeredAddressId: number;
    manufacturingLocationId: number;
    categoryIds: string;
    poc: string;
    createdAt: string;
    updatedAt: string;
}

export interface Standard {
    id: number;
    name: string;
    prefix: string;
    yearInUse: number;
    yearLastUpdated: number;
    hsnCodes: string;
    bisHeading: string;
    equivalentTags: string;
    status: string;
    createdBy: string | null;
    updatedBy: string | null;
    application: string;
    features: string;
    description: string;
    createdAt: string;
    updatedAt: string;
}

export const useAdminMasterService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getAllCategories = (params: ICategoryFilterRequest) => {
        return request(API_METHOD.GET, ADMIN_MASTER_URLS.GET_ALL_CATEGORY, authenticatedUser, null, { params });
    };

    const getAllCategoriesByIds = (ids: number[]) => {
        return request(API_METHOD.POST, ADMIN_MASTER_URLS.GET_ALL_CATEGORY_BY_IDS, authenticatedUser, ids);
    };

    const getAllManufacturers = (params: IManufacturerFilterRequest) => {
        return request(API_METHOD.GET, ADMIN_MASTER_URLS.GET_ALL_MANUFACTURER, authenticatedUser, null, { params });
    };

    const getAllStandards = (params: IStandardFilterRequest) => {
        return request(API_METHOD.GET, ADMIN_MASTER_URLS.GET_ALL_STANDARD, authenticatedUser, null, { params });
    };

    const getAllQualityGrades = (params: IQualityGradeFilter) => {
        return request(API_METHOD.GET, ADMIN_MASTER_URLS.GET_ALL_QUALITY_GRADE, authenticatedUser, null, { params });
    };

    const getAllBrandsByManufacturerIds = (data: number[]) => {
        return request(API_METHOD.POST, ADMIN_MASTER_URLS.GET_ALL_BRANDS_BY_MANUFACTURER_IDS, authenticatedUser, data)
    }

    return {
        getAllCategories,
        getAllManufacturers,
        getAllStandards,
        getAllQualityGrades,
        getAllBrandsByManufacturerIds,
        getAllCategoriesByIds
    };
};
