import { createUseStyles } from 'react-jss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { REGEX } from '../../../utils/constant';
import { useEmailSubscriptionService } from '../../../services/useEmailService';
import { EMAIL_SUBSCRIPTION_CONTEXT } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import TextField from '../../atoms/Input/TextField';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: `linear-gradient(115deg, #FEF1EA 8.97%, #EEF2FE 104.01%)`,
        width: '438px',
    },
    title: {
        color: theme.palette.textV2.secondary.secondary800,
        fontSize: '24px',
        lineHeight: "30px",
        letterSpacing: "-0.06px"
    },
    subtitle: {
        color: theme.palette.textV2.secondary.secondary800,
        fontSize: '14px',
        lineHeight: "16.1px",
        letterSpacing: "0.014px"
    },
    quantityInput: {
        width: '100%',
        background: theme.palette.backgroundV2.tertiary.tertiary500,
        borderRadius: '8px',
        "& .MuiOutlinedInput-input": {
            padding: '13px 16px !important  ',
        },
    },
    "@media (max-width: 480px)": {
        container: {
            width: "100%",
            height: '100%',
        },
        quantityInput: {
            width: '100%',
            "& .errorBig": {
                whiteSpace: 'pre',
            },
        },
    },
}));

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please enter your email address').matches(REGEX.EMAIL, 'Invalid email address')
});

const initialValues = {
    email: ''
}

export const NewsLetterTemplate = () => {
    const classes = useStyles();
    const emialSubscriptionService = useEmailSubscriptionService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
            setSubmitting(true)
            emialSubscriptionService.createEmailSubscription({ email: values.email, context: EMAIL_SUBSCRIPTION_CONTEXT.EMAIL_SUBSCRIPTION })
                .then(res => {
                    showSnackbar('success', 'Successfully subscibed to newsletter')
                }).catch(err => {
                    setFieldError('email', 'Failed to subscribe this email')
                    showSnackbar('error', 'Failed to subscibe to newsletter')
                }).finally(() => {
                    setSubmitting(false)
                })
                resetForm();
        }
    });

    return (
        <div className={` ${classes.container} rounded-2xl h-fit`}>
            {SnackBarComponent}
            <div className='grid gap-y-6 p-6'>
                <div className='grid gap-y-2'>
                    <div className={`${classes.title}  font-medium`}>Subscribe to our newsletter</div>
                    <div className={`${classes.subtitle} font-normal gap-8`}> And because your company is unique, you will need an extensible identity solution.</div>
                </div>

                <form className='flex gap-3' onSubmit={formik.handleSubmit}>
                    <div className={classes.quantityInput}>
                        <TextFieldV2
                            {...formik.getFieldProps("email")}
                            placeholder="Your Email"
                            fullWidth
                            error={ formik.touched.email && Boolean(formik.errors.email) }
                            helperText={formik.touched.email && Boolean(formik.errors.email) && formik.errors.email}
                        />
                    </div>
                    <button className='rounded-lg bg-white w-12 h-12' type='submit'>  <ArrowForwardIosIcon /> </button>
                </form>
            </div>
        </div>
    );
};
