import React, { useEffect, useState } from 'react'
import avighnaSteelLogo from "../../../assets/images/avighanaLogo.svg"
import { createUseStyles } from 'react-jss';
import { AUTH_STATES } from '../../../utils/types';

interface CompanyInformationTemplateProps {
    section: AUTH_STATES;
    sentEmailType: AUTH_STATES
}

const useStyles = createUseStyles((theme: any) => ({
    headingColor: {
        color: theme.palette.text.primaryLightAccent
    },
    content: {
        color: theme.palette.button.primaryLight
    }
}));

const CompanyInformationTemplate: React.FC<CompanyInformationTemplateProps> = ({ section, sentEmailType }) => {
    const classes = useStyles();
    const [ content, setContent ] = useState({ heading: "", discription: "" });
    useEffect(() => {
        switch (section) {
            case AUTH_STATES.LOGIN_WITH_EMAIL:
            case AUTH_STATES.LOGIN_WITH_MOBILE:
                setContent({ heading: "Welcome Back!", discription: "You need to be signed in to access the dashboard. " })
                break;
            case AUTH_STATES.USER_SIGNUP:
                setContent({ heading: "Welcome to Avighna Steels", discription: "Sign up is simple, free and fast. " })
                break;
            case AUTH_STATES.OTP_VERIFICATION:
                setContent({ heading: "OTP Verification", discription: "You need to be verify OTP to complete verification. " })
                break;
            case AUTH_STATES.SET_PASSWORD:
                setContent({ heading: "Set New Password", discription: "Please enter your new password. " })
                break;
            case AUTH_STATES.FORGOT_PASSWORD:
                setContent({ heading: "Forgot Password?", discription: "Please enter your registered email. " })
                break;
            case AUTH_STATES.EMAIL_CONFIRMAITON:
                setContent({ heading: `${sentEmailType===AUTH_STATES.FORGOT_PASSWORD ? "Forgot Password?" : "Welcome to Avighna Steels"}`, 
                discription: `${sentEmailType===AUTH_STATES.FORGOT_PASSWORD ? "" : "Sign up is simple, free and fast."}` })
                break;
            default:
            setContent({ heading: "Welcome to Avighna Steels", discription: "Sign up is simple, free and fast. " })
            break;
        }
    }, [section])
    return (
        <div className="flex flex-col justify-center items-center w-full select-none mb-4 mt-6">
            <div className="text-center w-5/12">
                <img src={avighnaSteelLogo} alt="AVIGHNA STEEL" className='w-full' />
            </div>
            <h1 className={`text-3xl font-semibold ${classes.headingColor}`}>{content.heading}</h1>
            <p className={`${classes.content} text-lg text-center`}>{content.discription}</p>
        </div>
    )
}

export default CompanyInformationTemplate