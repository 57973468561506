import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Button from '../../../atoms/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { IProductData } from './SelectProduct.template';
import ProductViewTemplate from './ProductView.template';
import { HTTP_STATUS, STATUS } from '../../../../utils/types';
import Tabs, { ITabsSchema } from '../../../molecules/Tabs/Tabs';
import { IAddNewInventoryDetail } from './AddNewVendorInventory.template';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import InventoryAddView from './InventoryAddView.template';
import { IproductDetails } from '../../VendorManagement/ProductCatalogueView.template';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import { usePostProductService } from '../../../../services/usePostProductService';
import { statusOptions } from '../../../../utils/constant';
import SuccessFailurePopup from '../../../molecules/SuccessFailurePopup/SuccessFailurePopup';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    textHeading3: {
        color: theme.palette.backgroundV2.primary.primary900
    },
    lineColor: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    UsnText: {
        color: theme.palette.backgroundV2.primary.primary500
    },
    viewProduct: {
        color: theme.palette.textV2.primary.primary400,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
}));
export interface IInventoryAddViewProps {
    warehouseId: number;
    productId: number;
    setInventoryStates: Dispatch<SetStateAction<IAddNewInventoryDetail[]>>;

}

const InventoryManagementViewTemplate = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const [productDetails, setproductDetailsTo] = useState<IproductDetails | null>(null);
    const vendorInventoryService = useVendorInventoryService();
    const [activeTab, setActiveTab] = useState('1');
    const [warehouseData, setWarehouseData] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
    const { showSnackbar } = useSnackbar();
    const params = useParams();
    const productId = Number(params.productId);
    const [status, setStatus] = useState<string>("");
    const [isDeletePopupVisible, setDeletePopVisibleTo] = useState(false);
    const { user } = useAuthenticatedUser();
    const [inventoryStates, setInventoryStates] = useState<IAddNewInventoryDetail[]>([]);
    const [availableStock, setAvailabelStockTo] = useState<number | null>(null);

    const handleCancel = () => {
        navigate('/dashboard/inventory-management');
    };

    const handleEdit = () => {
        navigate(`/dashboard/inventory-management/edit/${productId}`);
    }

    const getVedorProductbyId = async () => {
        if (user?.businessId) {
            try {
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId);
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const content: IproductDetails = ProductsResponse.data.data;
                    setproductDetailsTo(content);
                    setProductsData({
                        id: content.id,
                        skuCode: content.standardProductDetails.upcCode,
                        productName: content.productUpcTitle,
                        standardProductDetails: content.standardProductDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
    };
    useEffect(() => {
        getVedorProductbyId();
    }, [user?.businessId]);

    const fetchWarehouse = async () => {
        if (user?.businessId) {
            try {
                const wareHouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (wareHouseResponse.status === HTTP_STATUS.OK) {
                    const warehouses = wareHouseResponse?.data?.data?.content;
                    setWarehouseData(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
    };
    useEffect(() => {
        fetchWarehouse();
    }, [user?.businessId]);

    const schema: ITabsSchema[] = warehouseData?.map((warehouse: { id: number, name: string }) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <InventoryAddView warehouseId={warehouse.id} productId={Number(params.productId)} setInventoryStates={setInventoryStates} />,
    }));

    useEffect(() => {
        calculateTotalSizeOfInventory()
    }, [inventoryStates])

    const calculateTotalSizeOfInventory = () => {
        let totalCalculatedSize: number = 0;
        inventoryStates
            // .filter((vendorInventory: IAddNewInventoryDetail) => vendorInventory.warehouseId === warehouseId)
            .forEach((vendorInventory: IAddNewInventoryDetail) => (
                totalCalculatedSize += Number(vendorInventory.netWeight) || 0
            ));
        setAvailabelStockTo(totalCalculatedSize);
        return totalCalculatedSize;
    }


    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className=' grid gap-6 '>
                <div className='flex  justify-between'>
                    <div>
                        <text className={`${classes.textHeading} font-medium text-lg`}>View Product : </text>
                        <text className={`${classes.viewProduct} font-medium text-lg`}>{productsData?.skuCode}</text>
                    </div>
                    
                </div>
                <ProductViewTemplate
                    product={productsData}
                    showButton={false}
                />
               
                <div className=' grid gap-4 '>
                    <text className={`${classes.textHeading3} text-2xl font-medium`}>Inventory Details </text>
                    <hr className={`${classes.lineColor} border-t `} />
                    <text className=''>Total number of Location: {warehouseData?.length} </text>
                    <div className={`${classes.selectBox} rounded-xl p-4 grid gap-4`}>
                        <text className={`${classes.textHeading3} text-base font-medium `}>USN Details </text>
                        <div className='w-full relative'>
                            <Tabs schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
                        </div>
                        <text className={`${classes.textHeading3}text-base font-medium`}>Total Inventory Available </text>
                        <div className={`${classes.selectBox} rounded-xl grid p-6 w-1/4`}>
                            <text className={` ${classes.radioColor} text-sm font-normal`}>Total Stock Available</text>
                            <text className={` ${classes.radioColor} text-base font-medium`}>{availableStock} MT</text>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' flex justify-end gap-4'>

                <Button
                    variant="tertiaryContained"
                    label="Back"
                    onClick={handleCancel}
                />
                <Button
                    variant="primaryContained"
                    label="Edit"
                    onClick={() => handleEdit()}
                />
            </div>
        </div>
    )
}

export default InventoryManagementViewTemplate