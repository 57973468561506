import React, { useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import OtpInputs from "../../molecules/OtpInputGroup/OtpInputGroup";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useSnackbar } from "../../../hooks/useSnackBar";
import CountDownTimer from "../../atoms/CountDownTimer/CountDownTimer";
import Button from "../../atoms/Button/Button";
import { createUseStyles } from "react-jss";
import AuthDialogFooter from "../../molecules/AuthDialogFooter/AuthDialogFooter";
import { IncognitoUser } from "./Authentication.template";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_ROUTES } from "../../../utils/constant";

interface OtpVerificationTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    userDetails: IncognitoUser;
    otpVerifier: (verificationInfo: any) => Promise<any>
    section: AUTH_STATES
}

const useStyles = createUseStyles((theme: any) => ({
    sectionText: {
        color: theme.palette.text.primaryDarkAccent
    }, content: {
        color: theme.palette.button.primaryLight
    },
    headingColor: {
        color: theme.palette.text._secondaryDark
    }
}));

const OtpVerificationTemplate: React.FC<OtpVerificationTemplateProps> = ({ setAuthState, userDetails, otpVerifier, section }) => {
    const classes = useStyles();
    const [ otpErrorMessage, setOtpErrorMessage ] = useState<string>("");
    const [ otp, setOtp ] = useState<string[]>([ '', '', '', '' ]);
    const [ isTimerActive, setIsTimerActive ] = useState(true);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { syncAuthDialogActive, setAuthenticatedUser } = useAuthenticatedUser();
    const navigate = useNavigate();
    const handleDialogClose = () => {
        syncAuthDialogActive(false);
        setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE)
    };

    const isOtpValid = (otp:any,res?: any) => {
        if (otp.length !== 4) {
            setOtpErrorMessage("Enter valid OTP");
            return false;
        }
        
        if(res !==null && (res?.status !== HTTP_STATUS.OK || (section === AUTH_STATES.USER_SIGNUP && !res?.data?.data?.otpStatus))){
            setOtpErrorMessage("Enter correct OTP");
            return false;
        }

        return true;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const otpCode = otp.join('');
        if (!isOtpValid(otpCode,null)) {
            return;
        }
        setIsLoading(true)
        otpVerifier({ fullName: userDetails.fullName, mobileNumber: userDetails.mobileNumber, otp: otpCode, gstin: userDetails.gstin })
            .then((res) => {
                if (!isOtpValid(otpCode,res)) {
                    return;
                }

                let { user, token, businessId } = res?.data?.data ?? {};
                setAuthenticatedUser({ ...user, token, businessId });

                if (section === AUTH_STATES.USER_SIGNUP)
                    setAuthState(AUTH_STATES.EMAIL_REGISTRATION)
                else {
                    handleDialogClose();
                    !businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
                }

            }).catch(error => {
                console.error("Otp Verification:", error)
            }).finally(() => setIsLoading(false))
    };

    const handleResendOtp = () => {
        setIsTimerActive(true);
        setOtp([ '', '', '', '' ]);
        otpVerifier({ ...userDetails })
            .then(res => {
                if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                    showSnackbar('error', 'OTP not sent');
                } else if (res?.status === HTTP_STATUS.OK) {
                    setIsTimerActive(true);
                    showSnackbar('success', 'OTP sent successfully');
                }
            }).catch(error => {
                console.error("Otp Verification - Resend Otp:", error);
            }).finally(() => setIsLoading(false))
    }

    const onTimerComplete = () => {
        setIsTimerActive(false);
    }

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }

    return (
        <div className="auth-right-child-container flex flex-col w-full" >
            {SnackBarComponent}
            <div className="form-container flex flex-col grow w-full">
                <form className="flex flex-col items-center mb-4 w-full text-lg" onSubmit={handleSubmit}>
                    <div className={`mb-4 ${classes.content} select-none `}>Enter OTP sent to <span className={`${classes.headingColor} font-normal`}>+91 {userDetails?.mobileNumber}</span> </div>
                    <div className="mb-4">
                        <OtpInputs otp={otp} setOtp={setOtp} otpErrorMessage={otpErrorMessage} setOtpErrorMessage={setOtpErrorMessage} />
                        <span className={`flex justify-center cursor-pointer font-normal mt-4 ${isTimerActive ? `pointer-events-none select-none ${classes.content}` : `${classes.sectionText}`}`} onClick={handleResendOtp}>Resend OTP
                            {isTimerActive && <span> &nbsp;in <CountDownTimer timeInSeconds={60} onComplete={onTimerComplete} /></span>}
                        </span>
                    </div>
                    <Button variant="containedLight" label={`${section === AUTH_STATES.USER_SIGNUP ? "Verify" : "Log In"}`} type="submit" className="!h-12 w-full" isLoading={isLoading} />
                </form>
                <AuthDialogFooter setAuthStateTo={setAuthStateTo} section={section === AUTH_STATES.USER_SIGNUP ? AUTH_STATES.LOGIN_WITH_EMAIL : AUTH_STATES.USER_SIGNUP} loginType={AUTH_STATES.LOGIN_WITH_EMAIL} isLogin={section === AUTH_STATES.LOGIN_WITH_MOBILE}/>
            </div>
        </div>
    );
}

export default OtpVerificationTemplate;