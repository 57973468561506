import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export const PREFERENCE_URLS = {
    UPDATE_PREFERENCE: "/business-profile/section/COMMUNICATION_PREFERENCES",
    UPDATE_PAYMENT: "/business-profile/section/PAYMENT_PREFERENCES"
};

export interface ICommunicationRequestBody {
    communicationChannel: string;
    language: string;
    contactMode: string;
}


export interface IPaymentRequestBody {
    creditDays:string;
    paymentMode: string;
    whatsappUpdates: boolean;
    whatsappCommunity: boolean;
    emailUpdates: boolean;
    docsAccess: boolean;
}

export const usePreferenceService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();
 
  const editPreference = ( data: ICommunicationRequestBody) => {
    return request(API_METHOD.PUT,PREFERENCE_URLS.UPDATE_PREFERENCE, authenticatedUser, data );
  };

  const editPaymentPreference = ( data: IPaymentRequestBody) => {
    return request(API_METHOD.PUT,PREFERENCE_URLS.UPDATE_PAYMENT, authenticatedUser, data );
  };


  return {
    editPreference,
    editPaymentPreference
  };
};
