import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export interface INewsParams {
	limit?: number;
	page?: number;
}

export const NEWS_URL = {
    NEWS: "/posts",
};
  
export const useNewsService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

	const getNews = (params: INewsParams) => {
		return request(API_METHOD.GET, NEWS_URL.NEWS, authenticatedUser, null, {params});
	};
    
    return {
      getNews,
    };
};
