import React from "react";
import { createUseStyles } from "react-jss";
import Grid from '@mui/material/Grid';
import { Attribute } from "../../../utils/types";
import { IGridTemplate } from "../../organism/GridView/GridView.organism";
import { formatUOM } from "../../../utils/helper";
import { ATTRIBUTE_LABEL, COLOR_DATA } from "../../../utils/constant";
import { IColorPalette } from "../Catalogue/AttributeSelection.template";

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color:theme.palette.text.primaryLight,
    },
    subText: {
        color:theme.palette.text.secondaryDarkAccent,
    },
    container:{

    },
    "@media (max-width: 476px)": {
      container:{
        display: "grid",
        padding: "12px",
        background: theme.palette.background.primarylightAccent,
        borderRadius: "6px"
      },
      title: {
        color: theme.palette.text.primaryLight,
        textAlign: "center",
        fontSize: "12px"
      },
      subText: {
        color: theme.palette.text.secondaryDarkAccent,
        textAlign: "center"
      },
    },
}));

interface ProductUpcItemTemplateProps {
    data: Attribute[] | null;
    gridTemplate: IGridTemplate;
}
const SecondaryProductUpcItemTemplate: React.FC<ProductUpcItemTemplateProps> = ({ data, gridTemplate}) => {

  const classes = useStyles();
  
  const getAttributeValue = (attributeName?: string, attributeValue?: string) => {
    try {
      if (attributeValue === undefined || attributeValue === '') {
        return '';
      }
      if (attributeName && attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
        return attributeValue;
      }
        const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
        return (
            <div className="flex items-center">
                <div className="w-4 h-3 mr-1 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                <span>{attributeValue}</span>
            </div>
        )
    } catch (error) {
        return attributeValue;
    }
  }
  
  const displayAttributeValue = (item: any) => {
    if (item?.minValue === item?.maxValue || item?.maxValue === "") {
        return (
            <>
                {getAttributeValue(item.name, item.minValue)}
            </>
        );
    } else {
        return (
            <>
                {getAttributeValue(item.name, item.minValue)} - {getAttributeValue(item.name, item.maxValue)}
            </>
        );
    }
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {data?.map((attribute: Attribute, index: number) => (
          <Grid item xs={gridTemplate.xs} sm={gridTemplate.sm} md={gridTemplate.md} lg={gridTemplate.lg} xl={gridTemplate.xl} key={attribute.name}>
            <div className="grid gap-y-1">
              <div className={`${classes.title} text-sm font-normal`}>{attribute?.name}</div>
              <div className={`${classes.subText} text-xs font-medium`}>
                {attribute?.minValue !== attribute?.maxValue ? displayAttributeValue(attribute) : displayAttributeValue(attribute)} {attribute.minValue && attribute.uom && formatUOM(attribute.minValue, attribute?.uom)}
            </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SecondaryProductUpcItemTemplate;