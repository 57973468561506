import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { ICartItem, IContext } from "./CartItemDetail.template";
import CartPriceDetailsTemplate from "./CartPriceDetails.template";
import Button from "../../atoms/Button/Button";
import CartContactUsPop from "../../molecules/CartContactUs/CartContactUsPop";
import phoneCall from "./../../../assets/images/phoneCall.svg";
import downArrow from "./../../../assets/images/downArrow.svg";
import upArrow from "./../../../assets/images/upArrow.svg";
import ListView from "../../organism/ListView/ListView.organism";
import CardItem from "./CartItem.template";
import { PAYMENT_MODES, PAYMENT_TYPES_SELECTION } from "../../../utils/constant";

interface CartDetailsReviewProps {
	cartItemDetails: ICartItem[];
	handleDeleteCartItem: (cartId: number) => void;
	handleCartItemUpdate: (
		cartId: number,
		key: string,
		value: number | string
	) => void;
	configuration: IContext;
	handleRfq: () => void;
	paymentType: string | undefined;
}

const useStyles = createUseStyles((theme: any) => ({
	mainContainer: {
		paddingLeft: "70px",
		paddingRight: "70px",
	},
	leftContainer: {
		width: "67.31%",
	},
	container:{
		rowGap: "16px"
	},
	phoneButton: {
		padding: "14px",
        backgroundColor: theme.palette.backgroundV2.primary.primary100,
	},
	textColor: {
		color: theme.palette.textV2.primary.primary950,
	},
	arrowBorder: {
		border: `1px solid ${theme.palette.borderV2.tertiary.tertiary800}`,
		padding: "20.6px 18.41px",
		borderRadius: "10px"
	},
	paymentSelectionText: {
		color: theme.palette.textV2.primary.primary900,
	},
	paymentBackground: {
		backgroundColor: theme.palette.backgroundV2.primary.primary50,
		border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
	},
	subtitle: {
		color:theme.palette.textV2.primary.primary950,
	},
	rightContainer: {
		width: "30.92%",
		display: "flex",
	},
	rightChildContainer: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        boxShadow: "0px 7px 18px 0px rgba(29, 54, 121, 0.12)",
    },
	rightMobileContainer: {
        display: "none"
    },
	border:{
		border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
		borderRadius: "12px",
		padding: "24px",
		rowGap: "24px"
	},
	"@media (max-width: 480px)": {
        leftContainer: {
            width: "100%",
        },
        rightContainer: {
            display: "none",
        },
        rightMobileContainer: {
            display: "grid",
			border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        },
        mainContainer: {
            paddingLeft: "16px",
            paddingRight: "16px",
        },
        phoneButton: {
            padding: "10px",
        },
		border:{
			border:"none",
			borderBottom: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
			borderRadius: "0px",
			padding: "0px 0px 24px 0px",
			rowGap: "16px"
		},
		arrowBorder: {
			border: `none`,
			padding: "0px",
			borderRadius: "0px"
		},
		container:{
			rowGap: "24px"
		},
    },
}));

type CollapsableStateKeys = 'product' | 'payment' | 'contactUs';

const CartReviewTemplate: React.FC<CartDetailsReviewProps> = ({ cartItemDetails, handleDeleteCartItem, handleCartItemUpdate, configuration, handleRfq, paymentType }) => {
	const [collapsableState, setCollapsableStateTo] = useState({
		product: true,
		payment: false,
		contactUs: false,
	});
	const classes = useStyles();

	const toggleCollapsableState = (section: CollapsableStateKeys) => {
		setCollapsableStateTo((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className={`${classes.mainContainer} flex gap-x-6`}>
				<div className={`${classes.leftContainer} grid gap-y-6 h-fit`}>
					<div className={`${classes.subtitle} text-2xl font-medium`}>{`My Cart (${cartItemDetails.length} Items)`}</div>
					<div className={`${classes.container} grid`}>
						<div className={`${classes.border} grid gap-y-6 w-full h-fit `}>
							<div className="flex justify-between">
								<div className={`${classes.textColor} text-lg font-medium my-auto`}> Product Details</div>
								<button onClick={() => toggleCollapsableState('product')}>
									<div className={`${classes.arrowBorder}`}>
										{!collapsableState.product ? (
											<img src={downArrow} alt="" />
										) : (
											<img src={upArrow} alt="" />
										)}
									</div>
								</button>
							</div>

							{collapsableState.product && (
								<div>
									<ListView
										items={cartItemDetails}
										itemComponent={(item, index) => (
											<CardItem
												index={index}
												data={item}
												handleDeleteCartItem={handleDeleteCartItem}
												handleCartItemUpdate={handleCartItemUpdate}
												configuration={configuration}
												isEditable={false}
											/>
										)}
									/>
								</div>
							)}
						</div>
						<div className={`${classes.border} grid gap-y-6 w-full`}>
							<div className="flex justify-between">
								<div className={`${classes.textColor} text-lg font-medium  my-auto`}> Payment Terms</div>
								<button onClick={() => toggleCollapsableState('payment')}>
									<div className={`${classes.arrowBorder}`}>
										{!collapsableState.payment ? (
											<img src={downArrow} alt="" />
										) : (
											<img src={upArrow} alt="" />
										)}
									</div>
								</button>
							</div>
							{collapsableState.payment && (
								<div className={`p-6 rounded-xl w-full ${classes.paymentBackground}`}>
									<text className={`${classes.paymentSelectionText}  text-lg font-medium`}>
										{paymentType === PAYMENT_MODES.CASH_PAYMENT ? PAYMENT_TYPES_SELECTION.CASH_PAYMENT : PAYMENT_TYPES_SELECTION.CREDIT_PAYMENT}
									</text>
								</div>
							)}
						</div>
					</div>

				</div>



				<div className={classes.rightContainer}>
					<div className={`z-50 rounded-2xl sticky top-0 ${classes.rightChildContainer} h-fit`}>
						{cartItemDetails && cartItemDetails.length > 0 && (
							<CartPriceDetailsTemplate
								data={cartItemDetails}
								configuration={configuration}
								handleRfq={handleRfq}
							/>
						)}
						<div className="flex justify-between gap-x-2 mx-4 mb-4 bg-white">
							<Button
								className='flex-1 !h-12' variant="primaryContained" size="large" label="Generate Quotation" onClick={() => handleRfq()}
							/>
							<div className={`rounded-lg cursor-pointer  ${classes.phoneButton}`} onClick={() => toggleCollapsableState('contactUs')}>
								<img src={phoneCall} alt="call" />
							</div>
						</div>
						{collapsableState.contactUs && <CartContactUsPop />}
					</div>
				</div>
			</div>

			<div className={` fixed bottom-0 z-50 bg-white !pl-0 ${classes.rightMobileContainer} w-full`}>
				{cartItemDetails && cartItemDetails.length > 0 && (
					<CartPriceDetailsTemplate
					data={cartItemDetails}
					configuration={configuration}
					handleRfq={handleRfq}
				/>
				)}
				<div className="flex justify-between gap-x-2 mx-4 mb-4 bg-white">
					<Button className='flex-1' variant="primaryContained" size="small" label="Generate Quotation" onClick={() => handleRfq()} />
					<div className={`rounded-lg cursor-pointer  ${classes.phoneButton}`} onClick={() => toggleCollapsableState('contactUs')}>
						<img className='w-3.5' src={phoneCall} alt="call" />
					</div>
				</div>
				{collapsableState.contactUs && <CartContactUsPop />}
			</div>
		</>
	);
};
export default CartReviewTemplate;
