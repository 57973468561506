import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";

const AvighnaCustomizeTemplate: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <section className={classes.produts_banner}>
                <div className={classes.customize_banner}>
                    <div className={classes.products_banner_content}>
                        <h1 className={classes.main_heading}>Avighna Customize</h1>
                        <h3 className={classes.banner_heading_space}>Precision in Every Dimension: No Waste, No Delay, Optimal Production</h3>
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className={`${classes.products_about_us} ${classes.card_sp}`}>
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>About Avighna Customize</h2>
                            <p className={classes.gal_card_des} >Steel fabrication using non-customized materials can generate waste and
                                scraps, causing additional costs and disruptions in automation. Avighna sources standard quality
                                materials from global manufacturers like Nippon Steel, Posco, and ArcelorMittal and trusted
                                domestic producers like Tata Steel, JSW Steel, AMNS, and SAIL and customizes precise sizes to
                                meet customer requirements. Avighna enables manufacturers to deliver high-quality products
                                efficiently and cost-effectively by reducing waste interruptions and ensuring seamless
                                production.</p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/AvighnaCustomize/aboutAvighnaCustomize.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- cards section starts here  --> */}

            <section className={`${classes.section_margin} ${classes.section_strip} ${classes.seconday_cards_wrap} ${classes.specification_section}`}>
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={`${classes.section_heading_main} ${classes.cards_heading}`} >Benefits From Avighna Customize</h3>
                    <div className={`${classes.p_card_wrapper} ${classes.card_wrapper_mobile}`} >
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/AvighnaCustomize/noBeverages.webp" alt="" />
                                <span>No wastage, no scraps</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`} >
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/AvighnaCustomize/medal.webp" alt="" />
                                <span>Choices from Best Standard Qualities</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/AvighnaCustomize/ruler.webp" alt="" />
                                <span>Exact Specifications</span>
                            </div>
                        </div>

                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/AvighnaCustomize/time.webp" alt="" />
                                <span>Just in Time Deliveries</span>
                            </div>
                        </div>

                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/AvighnaCustomize/moneyBag.webp" alt="" />
                                <span>Huge Cost Advantage</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/AvighnaCustomize/fastForward.webp" alt="" />
                                <span>Faster Production Processes</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- our services section starts here  --> */}

            <section className={classes.section_strip}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Our Services</h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesShearing.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Shearing</span>
                                    <p>Steel Shearing is a precise process that cuts large sheets of steel into smaller,
                                        manageable sizes.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesSlitting.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Slitting</span>
                                    <p>Steel coils are longitudinally cut into narrower strips with uniform width and edge
                                        quality for industrial use.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesBlanking.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Blanking</span>
                                    <p>Steel Blanking is the method of punching out geometric shapes from flat steel sheets to
                                        create components used in manufacturing industries.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesMetalOffsetPrinting.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Metal Offset printing</span>
                                    <p>Metal offset printing transfers ink from a metal plate to a rubber blanket, producing
                                        high-quality prints on steel substrates.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesWarehousing.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>

                                    <span>Warehousing and Handling Services</span>
                                    <p>Avighna Steel Warehousing provides material handling services and supply chain management
                                        solutions to industries through advanced facilities and expertise.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={classes.card_preview}>
                                    <img src="../assets/images/AboutUs/ourServicesSteelLog.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Steel Logistics</span>
                                    <p>Efficient transportation of steel from manufacturers to end-users is crucial.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <NewsLetterTemplate />
        </>
    );
};

export default AvighnaCustomizeTemplate;
