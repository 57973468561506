import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import info from "../../../assets/images/info.png";
import business from "../../../assets/images/business.png";
import ourProducts from "../../../assets/images/ourProducts.png";
import gears from "../../../assets/images/gears.png";
import services from "../../../assets/images/services.png";
import contactUs from "../../../assets/images/contactUs.png";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import steelNews from "../../../assets/images/steelNews.png";
import MenuIcon from "@mui/icons-material/Menu";
import Popover from "@mui/material/Popover";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { MenuList } from "@mui/joy";
import avighnaSteel from "../../../assets/images/avighanaLogo.svg";
import { menuItems } from "./HeaderAboutUs";

const useStyles = createUseStyles((theme: any) => ({
	menu: {
		color: theme.palette.text.primaryDark,
		margin: "12px 16px",
		rowGap: "12px",
		display: "grid",
	},
	menuText: {
		color: theme.palette.textV2.tertiary.tertiary700,
		"& .JoyMuiList-root":{
			border: "1px solid red"
		}
	},
	activeMenuItem: {
		color: theme.palette.textV2.tertiary.tertiary700,
		borderRadius: "8px",
		"& .MuiTouchTripple-root":{
			background: "#FFEFE6",
		}
	},
	"& .MuiList-root": {
		border: "none",
	},
	secondaryDarkText: {
		color: theme.palette.text.secondaryDark
	},
	secondaryLightBorder: {
		color: theme.palette.border.secondaryLight
	},
	subMenu: {
		"& span": {
			color: theme.palette.text._primaryDark,
		},
		"& p": {
			color: "#B3B3B3",
		},
		"&:hover": {
			width: "100%",
			background: theme.palette.background.secondaryDarkAccent,
			"& $menuIcon": {
				width: "60px",
				opacity: 1,
			},
			"& span": {
				color: theme.palette.text._secondaryDark,
			},
		},
		menuIcon: {
			opacity: 0,
			transition: "width 0.3s ease, opacity 1s ease",
			width: "0px",
		},
		// transition: 'background-color 0.3s',
		// '&:hover': {
		//   background: theme.palette.background.primarylightAccent,
		// background: 'red',
		// },
	},
	mainPopover: {
		"& .MuiPaper-root": {
			width: '100vw !important',
			left: '0px !important',
			top: '178px !important',
			height: "100%",
			maxWidth: "calc(100% - 0px)",
			transition: "none",
			borderRadius: "none",
			boxShadow: "none"
		},
	},

}));

const images: { [key: string]: any } = {
	info: info,
	business: business,
	ourProducts: ourProducts,
	gears: gears,
	services: services,
	contactUs: contactUs,
	steelNews: steelNews,
};

const MobileMenus: React.FC = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();
	const [subMenuOpen, setSubMenuOpen] = React.useState<string | null>(null);
	const navigate = useNavigate();

	const navigateToHomePage = () => {
		navigate("/");
	};

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleSubMenuClick = (menuName: string) => {
		setSubMenuOpen(subMenuOpen === menuName ? null : menuName);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setSubMenuOpen(null);
	};

	return (

		<div className={`text-base flex items-center`}>
			{/* <MenuIcon onClick={handleClick} /> */}
			{ anchorEl === null ? <MenuIcon onClick={handleClick} /> : <CloseIcon onClick={handleClose} />

			}
			<Popover
				id="menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				className={classes.mainPopover}
			>
				{/* <CloseIcon onClick={handleClose} /> */}
				<div className={classes.menu}>
					{menuItems.map((menuItem: any) => (
						<span key={menuItem.name} className={`${classes.menuText} `}>
							<MenuItem
								key={menuItem.name}
								onClick={() => handleSubMenuClick(menuItem.name)}
								className={`!gap-4 border ${subMenuOpen === menuItem.name ? classes.activeMenuItem : undefined}`}
							>
								{/* <img
									src={images[menuItem.icon]}
									alt={menuItem.name}
									className="w-4"
								/> */}
								{menuItem.name}
								{menuItem.subMenu.length > 0 && (
									<ArrowDropDownIcon style={{ marginLeft: "auto" }} />
								)}
							</MenuItem>
							{subMenuOpen === menuItem.name && (
								<MenuList className={` !border-none  mt-1`}>
									{menuItem.subMenu.map((subMenuItem: any) => (
										<a key={subMenuItem.label} href={subMenuItem.link} className={`cursor-pointer flex gap-x-4 py-2 px-4 items-center hover:rounded ${classes.subMenu}`}>
											<div>
												<img className='w-7' src={subMenuItem.icon} alt="" />
											</div>
											<div className='grid gap-y-1'>
												<span className='font-medium text-base'>{subMenuItem.label} <ArrowForwardIosOutlinedIcon className='!pb-1' sx={{ fontSize: 20 }} /></span>
												<p className='text-xs'>{subMenuItem.description}</p>
											</div>
										</a>
									))}
								</MenuList>
							)}
						</span>
					))}
				</div>
			</Popover>
			<div className=" my-auto cursor-pointer" onClick={navigateToHomePage}>
				<img src={avighnaSteel} alt="avighnaLogo" className="w-32" />
			</div>
		</div>
	);
};
export default MobileMenus;
