import React from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { createUseStyles } from 'react-jss';
import { Grid } from "@mui/material";
import ImagePreviewer from '../../organisms/_ImagePreviewer';
export interface IImage {
	id: number;
	location: string;
	mimeType: string;
	path: string;
	resourceId: number;
	resourceType: string;
}
interface ICatalogueImageProps {
	catalogueImages?: IImage[];
	height?: any;
	width?: any;
	isSharable?: boolean;
	isRepresentationImage?: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
	iconStyle: {
		borderRadius: "50px",
		boxShadow: `0px 0px 4px 0px ${ theme.palette.boxShadow.primaryDark }`,
		color: theme.palette.text.primaryDark
	},
	wishlistIcon: {
		color: theme.palette.border.primaryDark
	},
	representationText: {
		color: theme.palette.v4.text._primaryDark,
	},
}));

const CatalogueImageTemplate: React.FC<ICatalogueImageProps> = ({ catalogueImages, height, width, isSharable, isRepresentationImage }) => {
	const classes = useStyles();
	return <>
		<ImagePreviewer images={ catalogueImages } height={ height } width={ width } defaultActiveIndex={ 0 } />

		{
			isRepresentationImage && 
		<div className={`${classes.representationText} text-sm font-medium mb-1`}>{"These images are for representation purpose only*"}</div>
		}
		{ isSharable && 
		<Grid container spacing={ 1 } sx={{ marginTop: "18px"}}>
			<Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 } xl={ 6 } >
				<div className='flex gap-x-1'>
					<div className='flex gap-1 p-1'><FavoriteBorderIcon className={ `${ classes.wishlistIcon } cursor-pointer` } /><div>Favorites</div></div>
					<div className='flex gap-1 p-1'><ShareIcon className={ ` cursor-pointer` } /><div>Share</div></div>
				</div>
			</Grid>
			<Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 } xl={ 6 } >
				<div className='flex gap-x-4'>
					<FacebookIcon className={ `${ classes.iconStyle } bg-white p-1 cursor-pointer` } sx={{ fontSize: 30 }} />
					<InstagramIcon className={ `${ classes.iconStyle} bg-white p-1 cursor-pointer` } sx={{ fontSize: 30 }} />
					<TwitterIcon className={ `${ classes.iconStyle } bg-white p-1 cursor-pointer` } sx={{ fontSize: 30 }} />
				</div>
			</Grid>
		</Grid>
		}
	</>;
}

export default CatalogueImageTemplate;