import React from 'react'
import { Link } from 'react-router-dom';
import { CUSTOMER_ROUTES, SUBJECT_TO_TERMS_DISCLAIMER } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    subjectWrapper: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
   
    termsCondition:{
        color: theme.palette.textV2.tertiary.tertiary700, 
       textDecoration: "underline",
    }
}))

const TermsAndConditionCartTemplate: React.FC = () => {
    const classes = useStyles();

    const scrollToDisclaimer = () => {
        const label = document.getElementById('scrollToDisclaimer');
        if (label) {
            label.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className={`flex ${classes.subjectWrapper} font-normal text-xs`}>
            <span>{SUBJECT_TO_TERMS_DISCLAIMER.SUBJECT_TO}</span>
            <Link to={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} className={`${classes.termsCondition} cursor-pointer mx-1 font-medium`}>
                <span>{SUBJECT_TO_TERMS_DISCLAIMER.TERMS_CONDITION}</span>
            </Link>
            <div className={`${classes.subjectWrapper}`} onClick={scrollToDisclaimer}>
                {SUBJECT_TO_TERMS_DISCLAIMER.SALES}
            </div>
        </div>
    )
}

export default TermsAndConditionCartTemplate