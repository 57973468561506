import { FormikProps } from 'formik'
import React from 'react'
import { IMaterialOtherPreference } from './MaterialOtherPreferences.template';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { MODE } from '../../../../utils/types';
import { capitalizeFirstLetter } from '../../../../utils/helper';

interface MaterialOtherPreferencesViewTemplate {
    formik: FormikProps<IMaterialOtherPreference>
    setModeTo: (value: MODE) => void;
    mode: MODE;
    setIsFormModeTo: (value: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.textV2.primary.primary500,
    },
    borderColor: {
        border: `1px solid ${theme.palette.v3.background.primaryLight}`
    },
    heading: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    tertiary100Border: {
        borderColor: theme.palette.textV2.tertiary.tertiary100
    },
    sectionBackground: {
        background: theme.palette.backgroundV2.tertiary.tertiary500
    },
    tertiary700: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    primaryBorder: {
        borderColor: theme.palette.borderV2.primary.primary500
    }
}));

const MaterialOtherPreferencesViewTemplate: React.FC<MaterialOtherPreferencesViewTemplate> = ({ formik, mode, setIsFormModeTo }) => {
    const classes = useStyles();

    const handleEdit = () => {
        setIsFormModeTo(true);
    }

    return (
        <div className={` p-6 grid gap-6 rounded-lg ${classes.sectionBackground}`}>
            <div className={`grid gap-6`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-2xl`}>Other Preference</span>
                    <Button variant='tertiaryContained' label={`${mode !== MODE.ADD ? 'Edit' : 'Add'}`} className='!font-normal' onClick={handleEdit} />
                </div>
                <div className={`${classes.tertiary100Border} border-b`}></div>
                {mode !== MODE.ADD && <div className={`grid grid-cols-2 gap-6`}>
                    <div>
                        <div className={`${classes.tertiary700} font-normal text-sm`}>Delivery time after order</div>
                        <div className={`${classes.tertiary700} font-medium`}>{formik.values.preferredDeliveryTime}</div>
                    </div>
                    <div>
                        <div className={`${classes.tertiary700} font-normal text-sm`}>Preferred delivery location</div>
                        <div className={`${classes.tertiary700} font-medium`}>{(formik.values.preferredDeliveryLocation).map(location => capitalizeFirstLetter(location.label)).join(', ')}</div>
                    </div>
                </div>}
            </div>
            {mode !== MODE.ADD && <div className={`grid gap-6`}>
                <div >
                    <div >
                        <div className={`${classes.tertiary700} font-normal text-sm`}>Customizations</div>
                        <div className={`grid gap-x-6 grid-cols-4`}>
                            {formik.values.customization.map(item => (
                                <div className={`${classes.tertiary700} font-medium p-4 border rounded-lg ${classes.primaryBorder}`}>{item}</div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>}
           {mode !== MODE.ADD && <div className={`grid gap-6`}>
                <div className={`grid gap-6 grid-cols-2`}>
                    <div>
                        <div className={`${classes.tertiary700} font-normal text-sm`}>Import/Export</div>
                        <div className={`${classes.tertiary700} font-medium`}>{capitalizeFirstLetter(formik.values.tradeType)}</div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default MaterialOtherPreferencesViewTemplate