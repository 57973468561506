import React, { ReactNode } from 'react';
import Button from '../../atoms/Button/Button';
import { createUseStyles } from 'react-jss';

interface FormSubmitProps {
  onSubmit: () => void;
  buttonText: string;
  children: ReactNode;
  header: boolean | ReactNode;
  disabled: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
  body: {
    border: `1px solid ${theme.palette.v3.border.primaryLight}`,
  },

}));

const FormSubmit: React.FC<FormSubmitProps> = ({ onSubmit, buttonText, children, header, disabled }) => {
  const classes = useStyles();
  const HeaderBool = () => {
    return (
      <>
        {
          header === false ? (
            <div className='item-center' > {children}</div>
          ) : null
        }
        <div className='text-right my-6'>
          <Button variant='contained' label={buttonText} onClick={onSubmit} disabled={disabled} />
        </div>
        {
          header === true ? (
            <div className='item-center'>{children}</div>
          ) : null
        }
      </>
    );
  }

  const HeaderNode = () => {
    return (
      <>
        <div>{header}</div>
        <div className='flex item-center'>{children}</div>
      </>
    );
  }

  return (
    <div className={`${classes.body} rounded-lg`}>
      <div className='px-6'>
        {typeof header === 'boolean' ? <HeaderBool /> : <HeaderNode />}
      </div>
    </div >
  );
};

export default FormSubmit;
