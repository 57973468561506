import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import Button from "../../atoms/Button/Button";
import CloseIcon from '@mui/icons-material/Close';
import successDialogHeader from '../../../assets/images/successDialogHeader.svg'

interface SuccessDialogProps {
    dialogOpen: boolean;
    setDialogOpen?: (open: boolean) => void;
    title: string,
    content: string,
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            height: "400px !important",
            width: "458px !important",
            borderRadius: "24px"
        },
    },
    mainHeading:{
        color: theme.palette.v3.text.primaryLightAccent
    },
    content:{
        color: theme.palette.v3.text.primaryLight
    },
    button:{
        paddingLeft: "89px !important",
        paddingRight: "89px !important",
        borderColor:  theme.palette.v3.border.searchBarBorder,
        color: theme.palette.v3.text.primaryDark
    }
}));

const SuccessDialogTemplate: React.FC<SuccessDialogProps> = ({ dialogOpen, setDialogOpen = () => {}, title, content }) => {
    const classes = useStyles();
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Dialog className={classes.container}
            open={dialogOpen} onClose={handleDialogClose}>
                 <div className={`absolute top-0 right-0 p-4 cursor-pointer`} onClick={handleDialogClose}>
          <CloseIcon />
        </div>
            <div className={` flex gap-x-6 `}>
                <div className="flex flex-col items-center gap-y-8">
                        <img src={successDialogHeader} alt=""/>
                    <div className="flex flex-col items-center gap-y-6 w-4/5">
                        <div className={`${classes.mainHeading} text-4xl font-semibold mx-auto`}>{title}</div>
                        <div className={`${classes.content} grid gap-y-4 text-sm text-center`}>
                            {content}
                        </div>
                    </div>
                    <div className="mx-auto">
                        <Button className={`${classes.button} !py-3 !text-lg`} variant="outlined" label="Continue" onClick={handleDialogClose} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default SuccessDialogTemplate;