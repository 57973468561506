import React, { useEffect } from 'react'
import { IMaterialRequirement, IMaterialRequirementFormik } from '../../../pages/DashBoard/MyMaterialProduct.page';
import { FormikProps } from 'formik';
import Button from '../../../atoms/Button/Button';
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import plusIcon from "../../../../assets/icons/plus.svg"
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../../utils/constant';
import ErrorMessage from '../../../atoms/ErrorMessage/ErrorMessage';

interface ProductRequirementTemplateProps {
  handleOnBack: () => void;
  handleOnNext: () => void;
  formik: FormikProps<IMaterialRequirementFormik>
}

const useStyles = createUseStyles((theme: any) => ({

  container: {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.v3.border.primaryLight}`,
  },
  heading: {
    color: theme.palette.textV2.primary.primary900
  },
  label: {
    color: theme.palette.buttonV2.secondaryText.text.secondaryDark
  },

  button: {
    padding: '14px !important'
  },
  highlightedText: {
    color: theme.palette.textV2.primary.primary500
  }
}));

const ProductRequirementTemplate: React.FC<ProductRequirementTemplateProps> = ({ formik, handleOnBack, handleOnNext }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleNext = (event: React.FormEvent) => {
    event.preventDefault();
    if (formik.touched.noOfRows && !Boolean(formik.errors.noOfRows))
      handleOnNext();
  }

  const handleOnCancel = () => {
    navigate(CUSTOMER_ROUTES.MY_MATERIAL_LISTING)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={`${classes.container} rounded-2xl p-6 gap-4 mb-4`}>
        <div className={`text-lg font-medium mb-6 ${classes.heading}`}>Product Category selected: <span className={classes.highlightedText}>{formik.values.categoryName}</span></div>
        <div className='relative'>
          <div className='flex w-1/2 gap-x-3 items-end'>
            <TextFieldV2
              {...formik.getFieldProps("noOfRows")}
              id="outlined-basic"
              label="Enter No of Products you want (Max. 10 nos.)"
              variant="outlined"
              placeholder='Enter Quantity'
              type="number"
              error={formik.touched.noOfRows && Boolean(formik.errors.noOfRows)}
              fullWidth
              helperText=""
            />
            <Button
              variant="contained"
              iconButton={<img src={plusIcon} />}
              size='large'
              onClick={handleNext}
              className={classes.button}
            />
          </div>
          <div>
            {formik.touched.noOfRows && Boolean(formik.errors.noOfRows) && <ErrorMessage message={formik.errors.noOfRows as string} />}
          </div>
        </div>
      </div>
      <div className='flex justify-end gap-x-3'>
        <Button label='Cancel' variant='secondaryContained' onClick={handleOnCancel} />
        <Button label='Back' variant='tertiaryContained' onClick={handleOnBack} />
      </div>
    </div>
  )
}

export default ProductRequirementTemplate