import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ICatalogue, ICurrentCartItem } from "../../pages/Catalogue/CatalogueDetails.page";
import { Checkbox } from '@mui/material';
import { UploadedImageData } from "../../pages/DashBoard/FinishedProductCreation.page";
import { useProductUpcBrandMappingService } from "../../../services/useProductUpcBrandMappingService";
import { CUSTOMER_ROUTES, statusOptions } from '../../../utils/constant';
import { CATALOGUE_TYPE_STATES, HTTP_STATUS } from "../../../utils/types";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter, formatPrice, makeRoute } from "../../../utils/helper";
import Button from "../../atoms/Button/Button";
import FilterChip from "../../atoms/FilterChip/FilterChip";
import { IMultiSelectOption } from "../../atoms/MultiSelectInput/MultiSelectInput2";

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.textV2.primary.primary900
    },
    text: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
    link: {
        color: theme.palette.textV2.tertiary.tertiary800
    },
    highLight: {
        color: theme.palette.textV2.primary.primary500
    },
    checkBox: {
        "&.MuiButtonBase-root.MuiCheckbox-root": {
            padding: "0px",
        },
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    price: {
        color: theme.palette.backgroundV2.secondary.secondary950
    },
    brandImage: {
        width: "100%",
        height: "43px",
        objectFit: "contain"
    }
}));

interface SimilarBrandsProps {
    currentCartItem: ICurrentCartItem;
    catalogue: ICatalogue | null;
    catalogueType: CATALOGUE_TYPE_STATES
}

interface InventorySummary {
    warehouseId: number
    warehouseName: string
    netWeight: number
}

interface ISimilarBrands {
    brandImages: UploadedImageData[]
    brandId: number
    brandName: string
    price: number
    updatedAt: string
    catalogueId: number
    inventorySummaries: InventorySummary[]

}

interface IBrandList {
    id: number
    brandName: string
}

const SimilarBrands: React.FC<SimilarBrandsProps> = ({ currentCartItem, catalogue, catalogueType }) => {
    const classes = useStyles();
    const [selectedBrands, setSelectedBrands] = useState<IMultiSelectOption[]>([]);
    const productUpcBrandMappingService = useProductUpcBrandMappingService();
    const [similarBrands, setSimilarBrands] = useState<ISimilarBrands[]>([]);
    const [brands, setBrands] = useState<IBrandList[]>([]);
    const [brandsToDisplay, setBrandsToDisplay] = useState<IBrandList[]>([]);
    const [attributes, setAttributes] = useState<Record<string, any>>({});
    const [displayBrandData, setDisplayBrandData] = useState<number[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const updateAttributes = () => {
        let attributes: Record<string, any> = {};
        if (catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
            Object.keys(currentCartItem.attributes).forEach((key: any) => {
                if (key.toUpperCase() == "HARDNESS") return;
                attributes[key] = {
                    value: currentCartItem.attributes[key].value,
                    uom: catalogue?.catalogueAttributes.SPECIFICATION.find(attribute => attribute.name.toUpperCase().trim() === key.toUpperCase().trim())?.uom
                };
            })
        } else {
            catalogue?.catalogueAttributes.SPECIFICATION.forEach(attribute => {
                if (attribute.name.toUpperCase() == "HARDNESS") return;
                attributes[attribute.name] = { value: JSON.parse(attribute.attributeOptions as string), uom: attribute.uom };
            })
        }
        setAttributes(attributes);
    }

    const handleBrandCheckBox = (option: IMultiSelectOption[] | null) => {
        if (option) {
            setSelectedBrands(option)
        }
    };

    const getSimilarBrands = async () => {
        let res = await productUpcBrandMappingService.fetchSimilarCataloguesByBrandUpcId(currentCartItem.upc?.id as number);
        if (res.status === HTTP_STATUS.OK) {
            setSimilarBrands(res.data.data)
        }
    }

    useEffect(() => {
        getSimilarBrands();
    }, [catalogue])

    useEffect(() => {
        updateAttributes();
    }, [catalogue])

    useEffect(() => {
        setBrands(similarBrands.filter(el => el).filter(similarBrand => similarBrand.brandName.toUpperCase() !== catalogue?.brand.toUpperCase())
        .map(item => ({ id: item.brandId, brandName: item.brandName })));
    }, [similarBrands])

    useEffect(()=>{
        setBrandsToDisplay(brands)
    },[brands])

    const displayWarehouse = (data: any) => {
        const warehouses = data.inventorySummaries.map((el: any) => capitalizeFirstLetter(el.warehouseName));
        return warehouses.length > 2 ? warehouses.slice(0, 2).join(", ") + "..." : warehouses.join(", ")
    }

    const handleCompare = () => {
        setDisplayBrandData(selectedBrands.map(el => el.value as number));
    }

    const handleSearch = (inputValue: string = "") => {
        setSearchTerm(inputValue);
        if(inputValue.length == 0)
            setBrandsToDisplay(brands);
        else {
            const filteredBrands: any = brands.filter(brand => brand.brandName.toUpperCase().includes(inputValue.toUpperCase()))
            setBrandsToDisplay(filteredBrands)
        }
    }

    return (
        <div id="scrollToLabel" className={`${similarBrands.length == 1 && 'hidden'}`}>
            <div className={`${classes.title} text-lg font-semibold mb-3`}>Comparison With Other Manufacturers</div>
            <div className="p-6 pb-4 flex flex-col gap-6 w-full border rounded-2xl">
                <div className={`${classes.title} text-2xl font-semibold`}>{catalogue?.name}</div>
                <div>
                    {Object.keys(attributes as any).length != 0 && <>
                        <div className={`mb-3 text-sm ${classes.text}`}>Selected Specifications</div>
                        <div className="p-6 rounded-2xl border flex flex-wrap w-full">
                            {(Object.keys(attributes as any) ?? []).map((key: any) => (
                                <div className="flex-1 justify-center flex flex-col items-center">
                                    <div className={`${classes.text} text-sm font-normal`}>{key}</div>
                                    <div className={`text-base font-medium ${classes.value}`}>{`${attributes?.[key].value} ${attributes?.[key].uom ?? ""}`}</div>
                                </div>
                            ))}
                        </div>
                    </>}
                </div>
                <div className="box-border">
                    <div className={`mb-3 text-sm ${classes.text}`}>Select Available Brand for Comparison</div>

                    <div className="flex gap-x-4 gap-y-3 items-end">
                        <div className="w-4/5">
                            <FilterChip
                                options={brandsToDisplay.map(brand => ({ label: brand.brandName, value: brand.id }))}
                                label=""
                                value={selectedBrands as any}
                                onchange={handleBrandCheckBox}
                                placeholder="Select brands here"
                                setSearchTerm={handleSearch}
                                textFieldPlaceholder='Search Brands'
                                searchTerm={searchTerm}
                            />
                        </div>
                        <Button variant="tertiaryContained" label="Compare" onClick={handleCompare} />
                    </div>

                    {!!displayBrandData.length && <div>
                        <div className={`${classes.title} text-lg font-semibold my-6`}>
                            {`Comparing With ${displayBrandData.length} Brands`}
                        </div>
                        <div>
                            <div className="border-x-0 border py-4 grid grid-cols-6 justify-items-center">
                                <span className={`${classes.title} text-lg font-semibold`}>Brand</span>
                                <span className={`${classes.title} text-lg font-semibold`}>Delivery Time</span>
                                <span className={`${classes.title} text-lg font-semibold`}>Ex-location</span>
                                <span className={`${classes.title} text-lg font-semibold`}>Price(Rs/MT)<sup>*</sup></span>
                                <span className={`${classes.title} text-lg font-semibold`}>Updated On</span>
                                <span className={`${classes.title} text-lg font-semibold`}>Action</span>
                            </div>
                            {
                                similarBrands.filter(el => displayBrandData.includes(el.brandId)).map((similarBrand) => (
                                    <div className="border-x-0 border py-3 grid grid-cols-6 justify-items-center items-center">
                                        <span className="w-full text-center">
                                            {similarBrand?.brandImages?.[0]?.path ?
                                                <img src={similarBrand?.brandImages?.[0]?.path} alt={similarBrand.brandName?.toUpperCase()} className={classes.brandImage} />
                                                : capitalizeFirstLetter(similarBrand.brandName)}
                                        </span>
                                        <span className={`${classes.text} text-sm font-normal text-ellipsis`}>3-10 days</span>
                                        <span className={`${classes.text} text-sm font-normal text-ellipsis`}>{displayWarehouse(similarBrand)}</span>
                                        <span className={`${classes.price} text-lg font-bold`}>{formatPrice(similarBrand.price)}</span>
                                        <span className={`${classes.text} text-sm font-normal`}>{new Date(similarBrand.updatedAt).toISOString().split('T')[0]}</span>
                                        <Link to={makeRoute(CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION, { query: { "catalogueId": similarBrand.catalogueId } })} target="_blank" rel="noopener noreferrer" className={`underline underline-offset-4 ${classes.link} cursor-pointer text-sm`}>
                                            View Details
                                        </Link>
                                    </div>
                                ))
                            }
                            <div className={`text-xs font-semibold pt-4 ${classes.highLight}`}>*Prices are subject to terms and condition / Market conditions. Please contact us for more details.</div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
};

export default SimilarBrands;