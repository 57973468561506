import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";

const VisionMissionTemplate: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <section>
                <div className={classes.banner_main}>
                    <div className={`${classes.banner_wrapper} ${classes.container}`}>
                        <div className={classes.banner_content}>
                            <span>About Us</span>
                            <h1>Our Mission and Vision</h1>
                            <p>Avighna Steel provides cost-effective flat-rolled steel materials that drive the success of SMEs
                                and MSMEs. We aim to empower industries, foster collaboration, and contribute to overall
                                prosperity through our dedication to excellence, reliability, and customer satisfaction.</p>
                            {/* <!-- <div className="btn">
                        <a href="#">Learn More</a>
                    </div> --> */}
                        </div>
                        <div className={classes.banner_preview}>
                            <div >
                                <img src="../assets/images/VissionMission/bannerImage1.webp" alt="" />
                            </div>
                            <div className={classes.banner_preview_content_secondary}>
                                <img src="../assets/images/VissionMission/bannerImage2.webp" alt="" />
                            </div>
                        </div>
                        <div className={classes.banner_mobile}>
                            <img src="../assets/images/VissionMission/bannerMobile.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className={classes.vision_wrapper}>
                    <div className={`${classes.vision} ${classes.container}`}>
                        <img src="" alt="" />
                        <h3 className={classes.section_heading}>Our Vision</h3>
                        <p>Avighna Steel provides cost-effective, high-quality flat-rolled steel materials to empower SMEs and
                            MSMEs. We drive innovation, foster partnerships, and deliver excellent products and services to
                            become the trusted backbone of industries, contributing to the growth and prosperity of our clients.
                        </p>

                        <div className={classes.vision_card_wrapper}>
                            <div className={classes.vision_card}>
                                <div className={classes.vision_card_wrap}>
                                    <div className={classes.vision_card_preview}><img src="../assets/images/VissionMission/leadership.webp"
                                        alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Leadership</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.vision_card}>
                                <div className={classes.vision_card_wrap}>
                                    <div className={classes.vision_card_preview}><img src="../assets/images/VissionMission/innovation.webp"
                                        alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Innovation</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.vision_card}>
                                <div className={classes.vision_card_wrap}>
                                    <div className={classes.vision_card_preview}><img
                                        src="../assets/images/VissionMission/sustainableGrowth.webp" alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Sustainable Growth</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- our mission statment section starts here  --> */}
            <section className={classes.mission_statment}>
                <div>
                    <div className={`${classes.vision} ${classes.container}`}>
                        <img src="" alt="" />
                        <h3 className={classes.section_heading}>Our Mission Statement</h3>
                        <p>Avighna Steel provides cost-effective, high-quality flat-rolled steel materials to empower SMEs and
                            MSMEs. We drive innovation, foster partnerships, and deliver excellent products and services to
                            become the trusted backbone of industries, contributing to the growth and prosperity of our clients.
                        </p>

                        <div className={classes.mission_card_wrapper_main}>
                            <div className={classes.mission_card}>
                                <div className={classes.mission_card_wrap}>
                                    <div className={classes.vision_card_preview}><img
                                        src="../assets/images/VissionMission/comprehensive.webp" alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Comprehensive range of steel products</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.mission_card}>
                                <div className={classes.mission_card_wrap}>
                                    <div className={classes.vision_card_preview}><img
                                        src="../assets/images/VissionMission/longTernPartnerships.webp" alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Long-term partnerships</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.mission_card}>
                                <div className={classes.mission_card_wrap}>
                                    <div className={classes.vision_card_preview}><img src="../assets/images/VissionMission/exceptional.webp"
                                        alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Exceptional service and timely delivery</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.mission_card}>
                                <div className={classes.mission_card_wrap}>
                                    <div className={classes.vision_card_preview}><img
                                        src="../assets/images/VissionMission/competitivePrices.webp" alt="" /></div>
                                    <div className={classes.card_title}>
                                        <span>Competitive prices</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default VisionMissionTemplate;
