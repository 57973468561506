import { useNavigate, useParams } from "react-router-dom";
import ExistingProductUpdateTemplate from "../../templates/VendorManagement/ExistingProductUpdate.template";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { IVendorProductRequestBodyDTO, usePostProductService } from "../../../services/usePostProductService";
import { HTTP_STATUS } from "../../../utils/types";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { VENDOR_PRODUCT_ROUTES } from "../../../utils/constant";

const PostProductUpdatePage = () => {

    const navigate = useNavigate();
    const params = useParams();
    const postProductService = usePostProductService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorProductId = Number(params.vendorProductId);
    const { user } = useAuthenticatedUser();

    const redirectToVendorProductList = () => {
        navigate(VENDOR_PRODUCT_ROUTES.POST_PRODUCT)
    }

    const updateVendorProductById = async (productId: number, vendorProductRequestBody: IVendorProductRequestBodyDTO) => {
        console.log("Pareame = ", params)
        if (productId) {
            try {
                const vendorProductResponse = await postProductService.upsertVendorProduct(productId, vendorProductRequestBody);
                if (vendorProductResponse.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Product updated successfully.")
                    redirectToVendorProductList();
                }
                else if (vendorProductResponse.status === HTTP_STATUS.BAD_REQUEST) {
                    showSnackbar('error', 'Product update failed.')
                }
            } catch (error) {
                showSnackbar('error', 'Product update failed.')
            }
        }
    }

    return (
        <div>
            <ExistingProductUpdateTemplate onVendorProductSubmit={updateVendorProductById} redirectToVendorProductList={redirectToVendorProductList} />
        </div>
    )
}

export default PostProductUpdatePage;
