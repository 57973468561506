import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Button from "../../atoms/Button/Button";
import PricingInfo from "../../organisms/PricingInfo";
import { IContext, IProductAttribute } from "../../pages/Cart/CartDetails.page-Deprecated";
import { toInr } from "../../../utils/helper";
import { PRICE_DETAILS, TAXES } from "../../../utils/constant";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import TermsAndConditionCartTemplate from "./TermsAndCondtionCart.template";
import { ICartItem, IValueAddedServices } from "../../pages/Cart/CartDetails.page";
import { IShearing } from "../Catalogue/Customization/ShearingCustomizationPopup";

export interface CartPriceDetailsProps {
    data: ICartItem[] | [];
    configuration: IContext;
    handleRfq: () => void;
}
export interface IChargesProps {
    title: string;
    subTitle?: string | null;
    price: number;
    icon?: React.ReactNode;
    iconInfo?: string | null;
    showFullQuantity: boolean;
}
export interface IPriceDetails {
    centralGSTCharges: IChargesProps;
    stateGSTCharges: IChargesProps;
    subTotal: IChargesProps;
    warehouseCharges: IChargesProps;
    handlingCharges: IChargesProps;
    GST: IChargesProps;
    TCS: IChargesProps;
}

export interface CartPriceDetails {
    subTotal: IChargesProps;
    TCS: IChargesProps;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: "grid",
        rowGap: "16px"
    },
    mobileContainer: {
        display: "none",
    },
    priceInformation: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    quantityInformation: {
        color: theme.palette.textV2.tertiary.tertiary800,
        fontSize: "14px"
    },
    addedProductInformation: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    border: {
        borderBottom: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        paddingBottom: "16px"
    },
    grandTotal: {
        color: theme.palette.textV2.tertiary.tertiary700,
        fontSize: "18px",
    },
    chargesInformation: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    arrow: {
        display: "none"
    },
    "@media (max-width: 480px)": {
        container: {
            display: "none",
        },
        mobileContainer: {
            display: "grid",
            rowGap: "12px"
        },
        border: {
            paddingBottom: "12px"
        },
        arrow: {
            display: "flex"
        },
    },
}));

const CartPriceDetailsTemplate: React.FC<CartPriceDetailsProps> = ({ data, configuration, handleRfq }) => {

    const classes = useStyles();
    const [totalQuantity, setTotalQuantityTo] = useState<number>(0);
    const [grandTotal, setGrandTotalTo] = useState<number>(0);
    const [UOM, setUOMTo] = useState<string>("MT");
    const [cartPriceDetails, setCartPriceDetailsTo] = useState<CartPriceDetails>(() => PRICE_DETAILS({}));
    const [isCartItemsQuantityValid, setIsCartItemsQuantityValidTo] = useState<boolean>(true);
    const [showAll, setShowAll] = useState(false);
    const [totalProductAmount, setTotalProductAmount] = useState<number>(0);

    const handleClick = () => {
        setShowAll(!showAll);
    };

    const calculateCartPriceDetails = (cartItems: ICartItem[]) => {
        var cartItemsLessThanMOQ = false;
        var grandTotal = 0;
        var totalQuantity = 0;
        var UOM = "MT";
        var cartItemSubTotal = 0;
        var totalProductAmount = 0;
        var valueAddedServicesCharges = calculateTotalCharges(cartItems);
        const { warehouseCharges, handlingCharges, ...cartPriceDetails } = PRICE_DETAILS({});
        cartItems.forEach((cartItem) => {
            const cartItemQuantity = !isNaN(cartItem.quantity) ? cartItem.quantity : 0;
            cartItemSubTotal = (cartItem.upcPrice) * cartItemQuantity;
            totalProductAmount = totalProductAmount + cartItemSubTotal;
            cartPriceDetails.subTotal.price += cartItemSubTotal;
            cartPriceDetails.centralGSTCharges.price += ((TAXES.GST) * cartItemSubTotal) / 100;
            cartPriceDetails.stateGSTCharges.price += ((TAXES.GST) * cartItemSubTotal) / 100;
            cartPriceDetails.TCS.price += (TAXES.TCS * cartItemSubTotal) / 100;
            totalQuantity += cartItemQuantity;
            UOM = cartItem.uom;
            grandTotal +=
                (cartItemSubTotal +
                    ((TAXES.GST * cartItemSubTotal) / 100) * 2 +
                    ((TAXES.TCS * cartItemSubTotal) / 100));

            if (cartItemQuantity <= 0 || cartItemQuantity < parseFloat(findMOQ(cartItem, cartItem?.attributes?.GENERALIZATION))) {
                cartItemsLessThanMOQ = true;
            }

        });
        cartPriceDetails.subTotal.price += valueAddedServicesCharges;

        setTotalQuantityTo(totalQuantity)
        setGrandTotalTo(grandTotal + valueAddedServicesCharges);
        setUOMTo(UOM);
        setCartPriceDetailsTo(cartPriceDetails);
        setIsCartItemsQuantityValidTo(cartItemsLessThanMOQ);
        setTotalProductAmount(totalProductAmount);
    };

    const calculateTotalChargesForCartItem = (cartItem: ICartItem): number => {
        return cartItem?.valueAddedServices ? (cartItem?.valueAddedServices?.shearing?.reduce((total, item) => total + item.totalCharges, 0) ?? 0) + (cartItem?.valueAddedServices?.slitting?.reduce((total, item) => total + item.totalCharges, 0) ?? 0) : 0;
    }

    const calculateTotalCharges = (cartItems: ICartItem[]): number => {
        return cartItems.reduce((total, item) => total + calculateTotalChargesForCartItem(item), 0);
    }

    const findMOQ = (data: ICartItem, attributes: IProductAttribute[]) => {
        return data.minimumOrderQuantity?.toString() ?? "1";
    };

    useEffect(() => {
        calculateCartPriceDetails(data);
    }, [data]);

    return (
        <>
            <div className={`p-4 ${classes.container} w-full `} >
                <div className={`grid gap-y-3 ${classes.border} w-full`}>
                    <div className="flex justify-between">
                        <div className={`${classes.priceInformation} font-medium text-base`}>Price Details</div>
                        <div className={`${classes.arrow} cursor-pointer`} onClick={handleClick} >
                            {showAll ? <ArrowDropUp /> : <ArrowDropDown />}
                        </div>
                    </div>

                    {showAll &&
                        <div className="flex justify-between text-sm font-normal">
                            <div className={`${classes.quantityInformation}`}>  {`Total Quantity (X${data?.length || 0} ${data?.length === 1 ? "Item" : "Items"})`} </div>
                            <div className={`${classes.quantityInformation}`}>{totalQuantity + " " + UOM} </div>
                        </div>
                    }
                </div>

                <div className={`grid gap-y-4 ${classes.border}`}>
                    <div className="grid gap-y-1">
                        <div className="flex justify-between">
                            <div className={`${classes.grandTotal} text-lg font-medium`}>  {`Total Product Amount`} </div>
                            <div className={`${classes.grandTotal} text-base font-medium mt-0.5`}>{`${toInr(totalProductAmount)}*`} </div>
                        </div>

                        <div className={`${classes.addedProductInformation} text-xs font-normal`}>{`Added for ${data?.length} ${data?.length === 1 ? "Product" : "Products"}`}</div>
                    </div>
                    <div className={`${classes.chargesInformation} text-xs font-normal`}>
                        *Packaging charges, Warehouse charges and Handling charges Extra as applicable
                    </div>
                </div>

                <div className={`grid gap-y-2 ${classes.border}`}>
                    {Object.entries(cartPriceDetails).map(([key, value]) => (
                        <PricingInfo
                            key={key}
                            data={{ ...value, icon: null }}
                            totalQuantity={totalQuantity}
                            uom={UOM}
                            label="CART"
                        />
                    ))}
                </div>

                <div className="grid gap-y-2 w-full">
                    <div className=" flex justify-between">
                        <div className={`${classes.grandTotal} text-lg font-medium`}>
                            Grand Total
                        </div>
                        <div className={`${classes.grandTotal} text-lg font-medium`}>
                            {`${toInr(grandTotal)}*`}
                        </div>
                    </div>

                    <div className="">
                        <TermsAndConditionCartTemplate />
                    </div>
                </div>
            </div>

            <div className={`p-4 ${classes.mobileContainer} w-full `} >
                <div className={`grid gap-y-3 ${classes.border} w-full`}>
                    <div className="flex justify-between">
                        <div className={`${classes.priceInformation} font-medium text-base`}>Price Details</div>
                        <div className={`${classes.arrow} cursor-pointer`} onClick={handleClick} >
                            {showAll ? <ArrowDropUp /> : <ArrowDropDown />}
                        </div>
                    </div>

                    {showAll &&
                        <div className="flex justify-between text-sm font-normal">
                            <div className={`${classes.quantityInformation}`}>  {`Total Quantity (X${data?.length || 0} ${data?.length === 1 ? "Item" : "Items"})`} </div>
                            <div className={`${classes.quantityInformation}`}>{totalQuantity + " " + UOM} </div>
                        </div>
                    }
                </div>
                {showAll &&
                    <>
                        <div className={`grid gap-y-4 ${classes.border}`}>
                            <div className="grid gap-y-1">
                                <div className="flex justify-between">
                                    <div className={`${classes.grandTotal} text-lg font-medium`}>  {`Total Product Amount`} </div>
                                    <div className={`${classes.grandTotal} text-base font-medium mt-0.5`}>{`${toInr(totalProductAmount)}*`} </div>
                                </div>

                                <div className={`${classes.addedProductInformation} text-xs font-normal`}>{`Added for ${data?.length} ${data?.length === 1 ? "Product" : "Products"}`}</div>
                            </div>
                            <div className={`${classes.chargesInformation} text-xs font-normal`}>
                                *Packaging charges, Warehouse charges and Handling charges Extra as applicable
                            </div>
                        </div>

                        <div className={`grid gap-y-2 ${classes.border}`}>
                            {Object.entries(cartPriceDetails).map(([key, value]) => (
                                <PricingInfo
                                    key={key}
                                    data={{ ...value, icon: null }}
                                    totalQuantity={totalQuantity}
                                    uom={UOM}
                                    label="CART"
                                />
                            ))}
                        </div>
                    </>
                }

                <div className="grid gap-y-2 w-full">
                    <div className=" flex justify-between">
                        <div className={`${classes.grandTotal} text-lg font-medium`}>
                            Grand Total
                        </div>
                        <div className={`${classes.grandTotal} text-lg font-medium`}>
                            {`${toInr(grandTotal)}*`}
                        </div>
                    </div>

                    <div className="">
                        <TermsAndConditionCartTemplate />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CartPriceDetailsTemplate;

