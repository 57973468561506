import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

interface IOrderParams {
    page: number;
    size: number;
    sort?: string;
    catalogueName?: string;
    status?: string;
    date?: string | null;
}

export const ORDER_URLS = {
    GET_ALL_ORDERS: "/orders",
    GET_ORDERS_BY_ID: "/order/:orderId",
    GET_ALL_SECONDARY_ORDERS: "/orders-secondary",
    GET_SECONDARY_ORDERS_BY_ID: "/order-secondary/:orderId",
    GET_ALL_SECONDARY_LOT_ORDERS: "/orders-secondary-lots",
    GET_SECONDARY_LOT_ORDERS_BY_ID: "/order-secondary-lot/:orderId",
    
};

export const useOrderService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getAllOrders = (params: IOrderParams) => {
        return request(API_METHOD.GET, ORDER_URLS.GET_ALL_ORDERS, authenticatedUser, null, { params });
    };

    const getOrderById = (orderId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(ORDER_URLS.GET_ORDERS_BY_ID, { orderId }), authenticatedUser);
    };

    const getAllSecondaryOrders = (params: IOrderParams) => {
        return request(API_METHOD.GET, ORDER_URLS.GET_ALL_SECONDARY_ORDERS, authenticatedUser, null, { params });
    };

    const getAllSecondaryLotOrders = (params: IOrderParams) => {
        return request(API_METHOD.GET, ORDER_URLS.GET_ALL_SECONDARY_LOT_ORDERS, authenticatedUser, null, { params });
    };

    const getSecondaryOrderById = (orderId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(ORDER_URLS.GET_SECONDARY_ORDERS_BY_ID, { orderId }), authenticatedUser);
    };

    const getSecondaryLotOrderById = (orderId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(ORDER_URLS.GET_SECONDARY_LOT_ORDERS_BY_ID, { orderId }), authenticatedUser);
    };

    return {
        getAllOrders,
        getOrderById,
        getAllSecondaryOrders,
        getSecondaryOrderById,
        getAllSecondaryLotOrders,
        getSecondaryLotOrderById
    };
};
