


import { TableBody, TableContainer, TableCell, TableHead, TableRow, Table } from '@mui/material';
import { createUseStyles } from 'react-jss';
import editIcon from "../../../../assets/images/editIcon.svg"
import { ISlitting } from './SlittingCustomizationPopup';

interface SlittingCustomizationTableTemplateProps {
    valueAddedService: ISlitting[];
    setDialogOpen: (open: boolean) => void;
    setEditState: (open: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.v4.background._primaryLightAccent,
    },
    tableHeadCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 0px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    tableCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 16px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    tableContaier: {

    },
    "@media (max-width: 480px)": {
        tableContaier: {
            width: "840px"
        },

    },
}))

const SLITTING_HEADING = [
    "Sno.",
    "Width (MM)",
    "Weight of Coil (MT)",
    "CTW Charges/MT",
    "TotalCharges",
]

const SlittingCustomizationTableTemplate: React.FC<SlittingCustomizationTableTemplateProps> = ({ valueAddedService, setDialogOpen, setEditState }) => {

    const classes = useStyles();

    return (
        <>
            <TableContainer className="w-full">
                <div className='grid gap-y-2'>
                    <div className='flex justify-between'>
                        <div className="text-base font-semibold my-auto">Slitting (CTW)</div>
                        <div className='m-2.5'>
                            <img src={editIcon} alt="" onClick={(e) => { setDialogOpen(true); setEditState(true) }} />
                        </div>
                    </div>
                    <div className={`overflow-x-auto overflow-hidden w-full`}>
                        <div className={`${classes.tableContaier} border rounded-lg`}>
                            <Table className="w-full" size="small">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        {SLITTING_HEADING.map((item, index) => (
                                            <TableCell key={index} className={`${classes.tableHeadCell} first:pl-4 first:text-left last:pr-4 last:text-right`}>{item}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {valueAddedService.map((data: any, index: number) => (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell className={`${classes.tableHeadCell} first:pl-8 first:text-left`}>{index + 1}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.width}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.weightOfCoil}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.chargesPerMT}</TableCell>
                                            <TableCell className={`${classes.tableHeadCell} last:pr-8 last:text-right`}>{data.totalCharges}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </TableContainer>
        </>
    )
}

export default SlittingCustomizationTableTemplate