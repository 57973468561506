import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  container: {
    maxWidth: 1077,
    margin: '0 auto',
  },

  heading: {

},
slide: {
  "& img": {
    width: "150px",
  },
},
carouselContainer: {
    "& .react-multiple-carousel__arrow": {
        display: "none",
    },

    "& .react-multi-carousel-list ul": {
        display: "flex",
        alignItems: "center",
        columnGap: "0px"
    },
    "& .react-multi-carousel-list li": {
        width: "fit-content !important",
    },
},
  container_secondary: {
    maxWidth: 1077,
    margin: '0 auto',
    padding: 0,
  },
  slider_main_container: {
    width: '80%',
    margin: '0 auto',
    padding: 0,
  },
  section_strip: {
    padding: '5.625rem 0rem',
  },
  section_strip_secondary: {
    paddingBottom: '5.625rem',
  },
  section_strip_space: {
    paddingBottom: '0rem !important',
  },
  section_margin: {
    marginBottom: '5.625rem',
  },
  section_top_spacing: {
    paddingTop: '0rem !important',
  },
  center_text: {
    textAlign: 'center',
  },
  'page_slider h3': {
    color: '#0A0A47',
    fontSize: '2.375rem',
    fontWeight: 600,
    textTransform: 'capitalize',
    marginBottom: 24,
  },
  banner_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '9.5%',
  },
  banner_main: {
    height: '770px',
    position: 'relative',
    '&:before': {
      content: '""',
      backgroundImage: "url('../assets/images/AboutUs/banner.webp')",
      position: 'absolute',
      top: '-45px',
      left: 0,
      bottom: 0,
      right: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '100%',
      height: '580px',
      zIndex: -1,
    }
  },
  banner_content: {
    width: '50%',
    padding: '0rem 55px 0rem 0rem',
    "& span": {
      color: '#1C3F9F',
      fontSize: '1.125rem',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    "& h1": {
      color: '#0A0A47',
      fontSize: '3rem',
      fontWeight: 600,
      lineHeight: '62.4px',
      marginTop: '0 !important',
    },
    "& p": {
      margin: '24px 0rem',
      color: '#686868',
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '27px',
      textAlign: 'justify',
    },
  },

  banner_content_h1: {
 
  },
  banner_content_p: {
 
  },
  banner_preview: {
    width: '50%',
  },
  banner_preview_content_secondary: {
    "&img": {
      marginTop: '-20px',
      marginLeft: '30px',
    }
 
  },
  vision_banner: {
    height: 791,
  },
  vision_banner_main: {
    backgroundImage: 'url(../assets/images/AboutUs/banner.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  btn: {
    backgroundColor: '#1D3679',
    display: 'inline-block',
    padding: '17px 21px',
    borderRadius: '8px',
    fontSize: '1rem',
    fontWeight: 700,
    '& a': {
      color: '#fff',
    },
  },
  news_btn: {
    marginTop: '24px',
  },

// <-------------suppliers section css starts here----------->

  suppliers_wrapper: {
    textAlign: 'center',
    padding: '0rem 2.8rem 0rem 1.8rem',
  },
  suppliers_wrapper_content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  small_heading: {
    color: '#0A0A47',
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  suppliers: {
    "&img": {
      maxWidth: '100%',
      padding: '1.125rem 12px',
    }
  },
  // <----------business section css starts here --------------> 
  business_wrapper: {
    textAlign: 'center',
    "& h3": {
      color: '#0A0A47',
      textAlign: 'center',
      fontSize: '3rem',
      fontWeight: 600,
      marginBottom: '24px',
    }
    
  },
  business_card: {
    borderRadius: '24px',
    display: 'flex',
    boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
  },
  // business_card_m_even: {
  //   backgroundColor: '#F0F4FD',
  // },
  business_card_m: {
    padding: '5.625rem 0rem',
  },

  card_content: {
    padding: '2.5rem',
    textAlign: 'left',
    width: '70%',
    backgroundColor: '#fff',
    borderRadius: '0px 24px 24px 0px',
    "& h2" : {
      margin: '0',
    },
    "& h4" : {
      color: '#1C3F9F',
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '23.4px',
      margin: '0',
    }

  },
  card_content_secondary: {
    borderRadius: '24px 0px 0px 24px',
  },

  cards_heading: {
    color: '#FFF !important',
    textAlign: 'center',
    fontSize: '2.375rem',
    fontWeight: 600,
  },
  seconday_cards_wrap: {
    background: '#0A0A47',
    marginTop: '5.625rem',
  },
  gal_card_des: {
    color: '#686868',
    fontSize: '1.125rem',
    lineHeight: '27px',
    textAlign: 'justify',
  },
  gal_card_heading: {
    marginBottom: '20px',
    textTransform: 'capitalize',
    color: "#0A0A47",
    fontSize: "38px",
    fontWeight: "600",

  },
  gal_card_btns_wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: '24px',
  },
  gal_card_btn: {
    borderRadius: '8px',
    border: '1px solid #D6E1FF',
    background: '#FFF',
    padding: '16px',
    color: '#1C3F9F',
    textAlign: 'center',
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  galvanised_banner: {
    backgroundImage: 'url(../assets/images/Galvanized/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '991px',
  },
  card_sub_heading: {
    color: '#1C3F9F',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '1.9rem',
    textTransform: 'capitalize',
    margin: '0.5rem 0rem 1.5rem 0rem',
  },
  card_description: {
    color: '#686868',
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: '27px',
    marginBottom: '20px',
    textAlign: 'justify',
  },
  card_review: {
    width: '30%',
    borderRadius: '0px 24px 24px 0px',
    overflow: 'hidden',
    "& img": {
      maxWidth: '100%',
      objectFit: 'cover',
      height: '100%',
    },
  },
  card_review_secondary: {
    borderRadius: '24px 0px 0px 24px',
    overflow: 'hidden',
    width: '30%',
    "& img": {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
    }
  },
  products_wrapper: {
    display: 'flex',
  },
  product_card: {
    "& img": {
      maxWidth: '100%',
    }
  },

  // <----------services section css starts here --------------> 

  card_wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card_main: {
    padding: '0.75rem',
    width: 'calc(100% / 3)',
  },
  card_wrap: {
    boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '24px',
    overflow: 'hidden',
    height: '100%',
  },
  card_preview: {
    "& img": {
      maxWidth: '100%',
    }
  },
  service_card_content: {
    padding: '2rem 2.5rem',
    "&p": {
      color: '#686868',
      fontSize: '1.125rem',
      lineHeight: '130%',
      textAlign: 'justify',
      marginTop: '0.4rem',
    },
    "& span": {
      color: 'var(--primary-dark)',
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: '36.4px',
      marginBottom: '12px',
      textTransform: 'capitalize',
    },
  },

  Header: {
    "& img": {
      maxWidth: '100%',
    }
 
  },
  news_letter_main: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  contact_us_wrapper: {
    backgroundColor: '#0A0A47',
    padding: '5rem 0rem',
    height: '500px',
  },
  contact_us: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    "& img": {
      maxWidth: '100%',
    },
    "& h3": {
      padding: '0rem 7.5rem',
      textTransform: 'capitalize',
      marginBottom: '24px',
    },
    "& span": {
      color: '#A5A5A5',
      fontSize: '1.125rem',
      fontWeight: 500,
    }
  },

  message_wrapper: {
    padding: '2.5rem !important',
    borderRadius: '24px',
    boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
    backgroundColor: '#ffffff',
    position: 'relative',
    top: '9%',
    right: 0,
    left: 0,
    maxWidth: '975px',
    "& h3": {
      color: '#0A0A47',
      fontSize: '2.375rem',
      fontStyle: 'normal',
      fontWeight: 600,
      marginBottom: '0rem',
    }
  },

  inputs_wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  message_content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '50%',
  },
  // message_content_nth_child_1: {
  //   padding: '20px 30px 0rem 0rem',
  // },
  // message_content_nth_child_2: {
  //   padding: '20px 0rem 0rem 30px',
  // },
  newsleter_input: {
    padding: '10px 14px',
    borderRadius: '16px',
    border: '1px solid rgba(104, 104, 104, 0.30)',
    background: '#FFF',
    color: '#686868',
    fontSize: '0.875rem',
    lineHeight: '23px',
    width: '411px',
  },
  btn_main: {
    width: '100px',
  },
  btn_primary: {
    padding: '1.125rem 36px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "& a": {
      color: '#FFF',
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  subscribe: {
    background: 'linear-gradient(3deg, #A4B6E8 -72.75%, rgba(246, 248, 255, 0.00) 96.62%)',
    height: '550px',
  },
  subscribe_wrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    top: '180px',
    right: 0,
    left: 0,
    marginTop: '70px',
  },
  subscribe_content: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2rem',
  },
  subscribe_input: {
    borderRadius: '8px',
    width: '470px',
    padding: '1.125rem 23px',
    border: '1px solid var(--gray, #EDEDED)',
  },
  subscribe_main: {
    textAlign: 'center',
    "p": {
      padding: '0px 10%',
    },
  },

  /* vision and mission page css starts here  */

/* our vision section css starts here  */

  vision: {
    padding: '2.813rem 0rem',
    "& p": {
      color: '#686868',
      fontSize: '1.125rem',
      fontWeight: 400,
      margin: '24px 0rem',
      padding: '0rem 5rem',
      lineHeight: '27px',
    }
  },
  vision_wrapper: {
    backgroundColor: '#F1F5FE',
    textAlign: 'center',
    padding: '2.813rem 0rem',
  },
  section_heading: {
    color: '#0A0A47',
    fontSize: '2.375rem',
    fontWeight: 600,
    textTransform: 'capitalize',
    textAlign: "center",
  },
  vision_card_wrapper: {
    padding: '10px 10px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  vision_card: {
    padding: '12px',
    width: 'calc(100% / 3)',
  },
  mission_statement_wrapper_p: {
    color: '#A5A5A5',
    textAlign: 'center',
    fontSize: '1.125rem',
    lineHeight: '27px',
  },
  mission_card: {
    width: 'calc(100% / 2)',
    padding: '0.75rem',
    borderRadius: '24px',
    height: '100%',
  },
  mission_card_wrap: {
    height: '100%',
  },
  mission_card_wrapper_main: {
    padding: '10px 5.625rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  vision_card_wrap: {
    borderRadius: '24px',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
  },
  vision_card_preview: {
    borderRadius: '24px 24px 0px 0px',
    overflow: 'hidden',
    "& img":{

    }
  },
  vision_card_preview_img: {
    width: '100%',
    verticalAlign: 'text-bottom',
  },
  card_title: {
    padding: '1rem 0rem 24px 0rem',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '0rem 0rem 1.5rem 1.5rem',
    "& span": {
      color: '#1C3F9F',
      fontSize: '1.125rem',
      fontWeight: 600,
    }
  },

/* mission statment section css starts here  */

  mission_statement_wrapper: {
    backgroundColor: 'var(--primary-dark)',
    textAlign: 'center',
  },
  section_heading_secondary: {
    color: '#ffffff',
    fontSize: '2.375rem',
    fontWeight: 600,
    textAlign: 'center',
  },
  /* colledrolled page css starts here  */
  produts_banner: {
    position: "relative",
},
banner_mobile: {

},
card_mob: {

},
service_wrapper : {

},
mobile_section_cards: {
display: "none",
},
produts_card: {

},
mobile_preview: {},
card_wrapper_mobile: {},
// card_wrapper_mobile: {

// },
mobile_cards: {},
cards_secondary: {},
products_bannner_wrapper :{},
bis_section_wrapper: {
  "& h3": {
    color: '#0A0A47',
    textAlign: 'center',
    fontSize: '2.375rem',
    fontWeight: 600,
    marginBottom: '24px'
  },
},
scroll_hide: {},
our_main_business:{},
business_content: {},
card_parent_wrap: {},
mission_statment: {},
grades_table: {},
grades_section: {},
g_card_main: {},

  products_banner_content: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: '100%',
    "& h1": {
      color: '#FFF',
      textAlign: 'center',
      padding: '0rem 15%',
      fontSize: '3rem',
      fontWeight: 600,
      marginBottom: '24px'
    },
    "& h3": {
      color: '#E9E9E9',
      textAlign: 'center',
      fontSize: '2rem',
      fontWeight: 700,
      textTransform: 'capitalize',
      padding: '0rem 23%'
    },
    "& h4": {
      color: '#E9E9E9',
      textAlign: 'center',
      fontSize: '2rem',
      fontWeight: 700,
      textTransform: 'capitalize',
      padding: '0rem 23%',
    }
  },
  banner_heading_space: {
    marginBottom: '34px !important',
  },
  main_heading: {
    marginBottom: '0rem !important',
  },
  section_heading_main: {
    color: '#0A0A47',                             
    fontSize: '2.375rem',
    fontWeight: 600,
    textTransform: 'capitalize',
    marginBottom: '20px !important',
  },
  colled_rolled_banner: {
    backgroundImage: 'url(../assets/images/ColdRolled/banner.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  electro_banner: {
    backgroundImage: 'url(../assets/images/ElectrolyticTinplate/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  banner_background: {
    backgroundImage: 'url(../assets/images/ColdRolled/banner.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '500px',
  },
  tin_free_banner: {
    backgroundImage: 'url(../assets/images/TinFreeSteel/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  tin_mill_banner: {
    backgroundImage: 'url(../assets/images/TinMillBlackPlate/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  color_coated_banner: {
    backgroundImage: 'url(../assets/images/ColorCoated/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  logistics_banner: {
    backgroundImage: 'url(../assets/images/AvighnaLogistics/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  customize_banner: {
    backgroundImage: 'url(../assets/images/AvighnaCustomize/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  metal_pack_banner: {
    backgroundImage: 'url(../assets/images/AvighnaMetalPack/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  avighna_advantage_banner: {
    backgroundImage: 'url(../assets/images/AvighnaAdvantage/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  slitting_banner: {
    backgroundImage: 'url(../assets/images/Slitting/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  shearing_banner: {
    backgroundImage: 'url(../assets/images/Shearing/landingPage.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '990px',
  },
  products_banner: {
    position: 'relative',
  },
  products_about_us: {
    padding: '5.625rem 0rem',
  },
  card_sp: {
    paddingBottom: '0rem !important',
  },
  banner_btn: {
    "& a": {
      padding: '17px 24px',
      alignItems: 'center',
      color: '#fff',
      background: '#3A64D9',
      borderRadius: '8px',
      width: '150px',
    }
  },
  spacing_strip: {
    padding: '0rem',
  },
  about_cards: {
    margin: '0 !important',
  },
  section_strip_main: {
    marginTop: '0 !important',
  },

  /* specification section css starts here  */

  p_card_wrapper: {
    display: 'flex',
    margin: '20px -10px 0rem -10px',
    justifyContent: 'space-between',
  },
  specification_section: {
    paddingBottom: '5.625rem',
  },
  specification_section_wrapper: {
    "& h3": {
      marginBottom: '24px !important',
    }
  },
  card_main_wrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  p_card_preview: {
    borderRadius: '16px',
    background: '#FFF',
    padding: '21px',
    display: 'flex',
    height: '214px',
    boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
    gap: '15px',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    "& img": {
      maxWidth: '100%',
    },
    "& span": {
      fontSize: '1.125rem',
      color: '#1C3F9F',
      textAlign: 'center',
      fontWeight: 600,
    }
  },

  p_card_content: {
    display: 'flex',
    gap: '15px',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '14px',
  },
  p_card_subtext: {
    color: '#0A0A47',
    fontSize: '1.125rem',
    fontWeight: 600,
  },
  p_card_s_text: {
    color: '#1C3F9F',
    fontSize: '0.875rem',
    fontWeight: 400,
  },

  /* steel process section starts here  */

    steel_process_wrapper: {
      padding: '2.5rem',
      borderRadius: '24px',
      background: 'rgba(255, 255, 255, 0.00)',
      boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
      "& h3": {
        color: '#0A0A47',
        fontSize: '2.375rem',
        fontWeight: 600,
        textTransform: 'capitalize',
        textAlign: 'center',
      }
    },
    process_content_wrap: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0rem -8px',
    },
    process_content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px',
      width: 'calc(100% / 3)',
    },
    process_content_main: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      "& span": {
        borderRadius: '46px',
        background: '#D6E1FF',
        padding: '1.125rem',
        textAlign: 'center',
      }
    },
    process_description: {
      borderRadius: '46px',
      border: '1px solid #D6E1FF',
      padding: '16px 62px',
      width: '297px',
      height: '75px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      "& p": {
        color: '#1C3F9F',
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: 600,
      }
    },

    slider_card_wrap: {
      boxShadow: '0px 10px 15px 0px rgba(29, 54, 121, 0.16)',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '24px',
      overflow: 'hidden',
      height: '411px',
    },

    slider_card_preview: {
      "& img": {
          maxWidth: "100%",
      }
    },
    /* service cards css starts here  */

  service_cards_main: {
    "& h3": {
      color: '#0A0A47',
      fontSize: '2.375rem',
      fontWeight: 600,
      textTransform: 'capitalize',
    }
  },
  service_card_wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0rem -12px',
  },
  service_card: {
    "& span": {
      color: '#1C3F9F',
      fontSize: '1.375rem',
      fontWeight: 600,
      lineHeight: '28.6px',
      textTransform: 'capitalize',
      marginTop: '14px',
      display: 'block',
    },
  },
  service_card_img: {
    width: '100%',
    borderRadius: '16px',
  },
  service_card_wrap: {
    width: '50%',
    padding: '12px',
  },

/* primary cards css starts here  */

  primary_card_wrap: {
    padding: '5.625rem 0rem',
    marginTop: '5.625rem',
  },
  primary_cards_wrapper: {
    background: '#F0F4FD',
  },
  primary_card_main: {
    display: 'flex',
    borderRadius: '24px',
    boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
    marginBottom: '24px',
    overflow: 'hidden',
  },
  primary_card_preview: {
    width: '50%',
    "& img": {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }
  },
  primary_card_content: {
    width: '50%',
    padding: '2.5rem',
    backgroundColor: '#fff',
    "& h3": {
      color: '#0A0A47',
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: '36.4px',
      marginBottom: '24px',
    },
    "& p":{
      color: '#686868',
      textAlign: 'justify',
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '27px',
    }
  },

  /* BIS Standards in Cold Rolled Steel section css starts here */

    bis_section: {
      backgroundColor: '#F0F4FD',
      padding: '5.625rem 0rem',
      display: 'block',
    },
    bis_section_mobile: {
      display: 'none',
    },
    bis_content_wrapper: {
      display: 'flex',
      gap: '24px',
      justifyContent: 'center',
      "&":{
        color: '#0A0A47',
        textAlign: 'center',
        fontSize: '2.375rem',
        fontWeight: 600,
        marginBottom: '24px',
      },
    },
    bis_content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '16px',
      padding: '2.5rem',
      borderRadius: '24px',
      background: '#FFF',
      width: '50%',
    },
    bis_content_main: {
      borderBottom: '1px solid #DDD',
    },
    bis_content_heading: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '12px',
    },
    table_heading: {
      color: '#0A0A47',
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    table_subheading: {
      color: '#1C3F9F',
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    bis_content_detail: {
      marginBottom: '1.125rem',
      "& p":{
        color: '#686868',
        fontSize: '1.125rem',
        fontWeight: 400,
      }
    },

    /* tin catalog section starts here  */

  tin_catalog: {
    backgroundColor: '#F0F4FD',
    "& h3": {
      color: '#0A0A47',
      textAlign: 'center',
      fontSize: '2.375rem',
      fontWeight: 600,
      textTransform: 'capitalize',
      marginBottom: '1.125rem',
      marginLeft: '24px',
    }
  },
  tin_catalog_wrapper: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
    padding: '0px 24px',
  },
  catalog_preview: {
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    "& span": {
      color: '#1C3F9F',
      textAlign: 'center',
      fontSize: '1.375rem',
      fontWeight: 600,
    }
  },
  medium_img: {
    height: '194px',
    borderRadius: '16px',
    boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
  },

  /* grades section css starts here   */

  grades_section_wrapper: {
    "& h3": {
      color: '#0A0A47',
      textAlign: 'center',
      fontSize: '2.375rem',
      fontWeight: 600,
      marginBottom: '23px',
    }
  },
  section_space: {
    paddingTop: '5.625rem',
  },
  details_table: {
    borderRadius: '16px',
    "& table" : {
      borderRadius: '16px',
      width: '100%',
      overflow: 'hidden',
      borderCollapse: 'collapse',
      "& thead": {
        "& th": {
          padding: "16px",
        },
        "& tr": {
          background: 'rgba(226, 233, 252, 0.50)',
          borderRadius: '16px',
          borderSpacing: 'collapse',
        }
      },
      "& tbody": {
        "& tr": {
          borderBottom: '1px solid rgba(0, 0, 0, 0.50)',
          textAlign: 'center',
          padding: '20px',
          color: '#686868',
          fontSize: '1.25rem',
          "& td": {
            textAlign: 'center',
            padding: '20px',
            color: '#686868',
            fontSize: '1.25rem',
          },
        },
        
      },
      "& td": {
        color: '#686868',
        fontSize: '0.75rem',
      },
    },
  },


  tbody: {
    "& tr": {
      borderBottom: '1px solid rgba(0, 0, 0, 0.50)',
      textAlign: 'center',
      padding: '20px',
      color: '#686868',
      fontSize: '1.25rem',
      "& td": {
        textAlign: 'center',
        padding: '20px',
        color: '#686868',
        fontSize: '1.25rem',
      },
    },
    
  },

  last_row: {
    borderBottom: 'none !important',
  },
  th: {
    padding: '16px',
  },
  grades_table_wrapper: {
    borderRadius: '24px',
    boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
    overflow: 'hidden',
  },


  /* card section starts here  */

  g_card_wrapper: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'center',
  },
  g_card: {
    display: 'flex',
    width: "200px",
    flexDirection: 'column',
    height: '100%',
    borderRadius: '24px',
    overflow: 'hidden',
    boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
  },
  g_card_preview: {
    "& img": {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
    }
  },
  g_card_content: {
    padding: '2.5rem',
    "& h4": {
      color: '#0A0A47',
      fontSize: '2.375rem',
      fontWeight: 600,
    },
    "& p":{
      color: '#686868',
      fontSize: '1.125rem',
      fontWeight: 400,
      marginTop: '24px',
      lineHeight: '27px',
    }
  },

  /* Style the buttons inside the tab */

  tab: {
    '& button': {
      backgroundColor: 'inherit',
      float: 'left',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      padding: '14px 16px',
      transition: '0.3s',
      fontSize: '1rem',
      textTransform: 'capitalize',
      overflow: 'hidden',
      marginBottom: '24px',
      '&.active': {
        background: 'rgba(214, 225, 255, 0.50)',
        color: '#1C3F9F',
      },
    },
    '& button:not(.active)': {
      borderRadius: '8px',
      border: '1px solid #D6E1FF',
      padding: '8px 16px',
      marginRight: '13px',
    },
  },
  tabcontent: {
    display: 'none',
    borderTop: 'none',
  },
  tabs_wrapper: {
    '& h3': {
      color: '#0A0A47',
      fontSize: '2.375rem',
      fontWeight: 600,
      lineHeight: '49.4px',
      marginBottom: '24px',
    },
  },

  /* <----------eletrolytic pages css starts here--------------->  */

    specTabs: {
      borderRadius: '24px',
      boxShadow: '0px 18px 70px 0px rgba(29, 54, 121, 0.16)',
      padding: '2.5rem',
      marginTop: '5.625rem',
    },
    secondary_tab: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid grey',
      paddingBottom: '2.5rem',
      '& button': {
        padding: '24px',
        color: '#0B0B48',
        fontSize: '1.125rem',
        width: '329px',
      },
    },
    tabs_links: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      gap: '16px',
      borderRadius: '8px',
      border: '1px solid #D6E1FF',
      background: '#FFF',
      boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.05)',
    },
    tabcontent_wrapper: {
      paddingTop: '60px',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      textAlign: 'center',
      borderTop: '1px solid grey',
      marginTop: '40px',
      '& .tab_content_span': {
        color: '#1C3F9F',
        fontSize: '1.25rem',
        fontWeight: 600,
        marginBottom: '12px',
      },
      '& .tab_content_p': {
        color: '#686868',
        fontSize: '1.125rem',
        fontWeight: 400,
        marginTop: '8px',
      },
    },
    tabs: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },

    slider_card_content: {
      '& h4': {
        color: 'var(--primary-dark)',
        fontSize: '1.75rem',
        fontWeight: 600,
        lineHeight: '36.4px',
        marginBottom: '12px',
      },
      '& p': {
        color: '#686868',
        fontSize: '1.125rem',
        lineHeight: '130%',
        textAlign: 'justify',
        marginTop: '10px',
      },
    },

  '@media (max-width: 480px)': {
    container: {
      width: '100%',
      // padding: '0px 26px',
    },
    // banner_wrapper: {
    //   display: 'flex',
    //   flexDirection: 'column',
    // },
    banner_preview_content: {
      display: 'none',
    },
    banner_content: {
      order: 2,
      marginTop: '-50px',
      padding: '80px 24px 24px 24px',
      width: '100%',
      boxShadow: '-7px 6px 30px 0px rgba(29, 54, 121, 0.16)',
    },
    our_main_business: {
      margin: '20px 0px 0px 0px',
    },
    card_review: {
      order: 1,
      width: '100%',
      borderRadius: '24px 24px 0px 0px',
    },
    business_card: {
      display: 'flex',
      flexDirection: 'column',
    },
    card_content_secondary: {
      borderRadius: '24px',
    },
    card_content_h2: {
      marginBottom: '12px !important',
      fontSize: '18px',
    },
    gal_card_des: {
      lineHeight: '21px',
    },
    card_content: {
      width: '100%',
      padding: '24px',
      order: 2,
      borderRadius: '0px 0px 24px 24px',
    },
    metal_slider_wrapper_h3: {
      marginBottom: '10px',
      fontSize: '18px',
    },
    metal_slider_wrapper: {
      padding: '40px 0px 60px 0px',
    },
    card_review_secondary: {
      width: '100%',
      borderRadius: '24px 24px 0px 0px',
    },
    tin_catalog: {
      overflow: 'scroll',
    },
    card_review_img: {
      width: '100%',
      height: '282px',
    },
    products_banner_content_h3: {
      padding: '0px',
    },
    products_banner_content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      width: '100%',
    },
    products_banner_content_h1: {
      padding: '0px',
    },
    metal_pack_banner: {
      height: '490px',
    },
    gal_card_btns_wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
    },
    gal_card_btn: {
      width: '162px',
      textAlign: 'left',
      padding: '8px 16px',
    },
    business_card_m: {
      padding: '2.625rem 1.4rem',
    },
    message_wrapper: {
      padding: '20px !important',
      width: 'calc(100% - 20px) !important',
    },
    primary_cards_wrapper: {
      marginTop: '24px',
    },
    primary_card_main: {
      marginBottom: '24px',
    },
  },
    
});
