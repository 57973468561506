import React, { SyntheticEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ClearIcon } from '@mui/x-date-pickers';
import { createUseStyles } from 'react-jss';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoCompleteTextField from './AutoCompleteTextField';

export interface AutoCompleteOption {
  label: string;
  id: number | string;
}

const useStyles = createUseStyles((theme: any) => ({
  label: {
    color: theme.palette.textV2.tertiary.tertiary600,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px"
  },
   "@media (max-width: 480px)": {
    label: {
      color: theme.palette.textV2.tertiary.tertiary600,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16.1px"
    },
    },
}));

interface AutoCompleteInputProps {
  label: string;
  options: AutoCompleteOption[];
  onSearch: (value: string) => void;
  onChange: (option: AutoCompleteOption | null) => void;
  isDisabled?: boolean;
  value?: AutoCompleteOption | null;
  error?: boolean;
  helperText?: string;
  id?: string;
  placeholder?: string;
  onBlur?: () => void;
}

const AutoCompleteInputV2: React.FC<AutoCompleteInputProps> = ({
  label,
  options,
  onSearch,
  onChange,
  isDisabled,
  value,
  error = false,
  helperText = '',
  placeholder = '',
  id,
  onBlur
}) => {
  const handleInputChange = (e: SyntheticEvent<Element, Event>, value: AutoCompleteOption | null) => {
    onChange(value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onSearch(e.target.value);
  };

  const handleClearValue = () => {
    onSearch("");
    onChange(null);
  };

  const defaultProps = {
    options: options,
    getOptionLabel: (option: AutoCompleteOption) => option.label
  };

  const classes = useStyles();
  
  return (
    <div className={`flex flex-col w-full gap-2 relative ${isDisabled ? 'pointer-events-none select-none': ''}`}>
      {label && (
        <div className={classes.label}>
          {label}
        </div>
      )}
      <Autocomplete
        id={id ?? label}
        {...defaultProps}
        fullWidth
        disabled={isDisabled}
        clearText=''
        onFocus={handleClearValue}
        popupIcon={<ExpandMoreIcon />}
        clearIcon={value?.label === '' ? '' : <ClearIcon onClick={handleClearValue} />}
        renderInput={(params) => (
          <AutoCompleteTextField
            {...params}
            label=""
            onChange={handleSearch}
            error={error}
            placeholder={placeholder}
          />
        )}
        onChange={handleInputChange}
        value={value}
        onBlur={onBlur}   
      />
      {error && !!helperText && <ErrorMessage message={helperText} />}
    </div>
  );
};

export default AutoCompleteInputV2;