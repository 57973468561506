interface ContentPanelItemProps {
    component: React.ReactNode;
}

const ContentPreviewerPanelItem: React.FC<ContentPanelItemProps> = ({ component }) => {
    return (
        <div className="p-0 cursor-pointer" >
            {component}
        </div>
    );
};
export default ContentPreviewerPanelItem;