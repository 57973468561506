import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ProductTabs, tabprops } from './ProductTabs.Template';

const TinMillBlackPlateTemplate: React.FC = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <section className={classes.produts_banner}>
                <div className={`${classes.products_bannner_wrapper} ${classes.tin_mill_banner}`}>
                    <div className={classes.products_banner_content}>
                        <h4>Products</h4>
                        <h3>Tin Mill Black Plate</h3>
                        <h1>Crafting Excellence with Precision</h1>
                        {/* <!-- <div className="banner_btn">
                            <a href="#">Learn More</a>
                        </div> --> */}
                    </div>
                </div>
            </section>

            {/* about us sections starts here  */}

            <section className={`${classes.products_about_us} ${classes.card_sp} ${classes.section_strip}`}>
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>About Tin Mill Black Plate</h2>
                            <p className={classes.gal_card_des}>Tin Mill Black Plate (TMBP) steel is made from Hot Rolled Coils that are
                                pickled, cold-rolled, and processed for formability, surface texture, and flatness. It's widely
                                used in general line cans, automotive, and construction components, as well as in Tinplate and
                                tin-free steel due to its uniform thickness and excellent flatness.</p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/TinMillBlackPlate/aboutTinMil.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>


            {/* <!-- specification range section starts here  --> */}


            {/* <section className="specification_section section_strip">
    <div className="specification_section_wrapper container">
        <h3 className="section_heading_main">Specifications range</h3>
        <div className="card_parent_wrap">
            <div className="p_card_wrapper">
                <div className={classes.card_main}>
                    <div className="card_main_wrap">
                        <div className="p_card_preview">
                            <img src="../assets/images/ColdRolled/cardimg6.webp" alt="" />
                        </div>
                        <div className="p_card_content">
                            <span className="p_card_subtext">Thickness Ranges</span>
                            <span className="p_card_s_text">0.25~2.00 mm (±0.003mm)</span>
                        </div>
                    </div>
                </div>

                <div className={classes.card_main}>
                    <div className="card_main_wrap">
                        <div className="p_card_preview">
                            <img src="../assets/images/ColdRolled/cardimg2.webp" alt="" />
                        </div>
                        <div className="p_card_content">
                            <span className="p_card_subtext">Width Range</span>
                            <span className="p_card_s_text">50~1250mm (±3/-0mm)</span>
                        </div>
                    </div>
                </div>

                <div className={classes.card_main}>
                    <div className="card_main_wrap">
                        <div className="p_card_preview">
                            <img src="../assets/images/ColdRolled/cardimg3.webp" alt="" />
                        </div>
                        <div className="p_card_content">
                            <span className="p_card_subtext">Length Range</span>
                            <span className="p_card_s_text">600-3000 mm (±3/-0mm)</span>
                        </div>
                    </div>
                </div>

                <div className={classes.card_main}>
                    <div className="card_main_wrap">
                        <div className="p_card_preview">
                            <img src="../assets/images/ColdRolled/cardimg4.webp" alt="" />
                        </div>
                        <div className="p_card_content">
                            <span className="p_card_subtext">Outer Diameter</span>
                            <span className="p_card_s_text">1500-2200 mm</span>
                        </div>
                    </div>
                </div>

                <div className={classes.card_main}>
                    <div className="card_main_wrap">
                        <div className="p_card_preview">
                            <img src="../assets/images/ColdRolled/cardimg5.webp" alt="" />
                        </div>
                        <div className="p_card_content">
                            <span className="p_card_subtext">Inner Diameter</span>
                            <span className="p_card_s_text">510, 600, 610 mm</span>
                        </div>
                    </div>
                </div>

                <div className={classes.card_main}>
                    <div className="card_main_wrap">
                        <div className="p_card_preview">
                            <img src="../assets/images/ColdRolled/cardimg1.webp" alt="" />
                        </div>
                        <div className="p_card_content">
                            <span className="p_card_subtext">Coil Weight</span>
                            <span className="p_card_s_text">3-20 tonnes</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='tabs spec-tabs container'>

            <input type="radio" name="tab" id="tab1" role="tab" checked />
            <label for="tab1" id="tab1-label" className="tabs_links"><img src="../assets/images/ElectrolyticTinplate/tinCan.webp" alt="" /> Tin Coating</label>
            <section aria-labelledby="tab1-label">
                <div className="tabcontent_wrapper">
                    <div className="tab_content">
                        <span>Equal coating</span>
                        <p>1.0/1.0, 2.0/2.0, 2.8/2.8, 5.6/5.6 (g/m²)</p>
                    </div>
                    <div className="tab_content">
                        <span>Differential coating</span>
                        <p>1.0/2.8, 2.8/4.0, 2.8/5.6, 2.0/5.6 (g/m²)</p>
                    </div>
                </div>
            </section>

            <input type="radio" name="tab" id="tab2" role="tab" />
            <label for="tab2" id="tab2-label" className="tabs_links"><img src="../assets/images/ElectrolyticTinplate/muscle.webp" alt="" /> Temper</label>
            <section aria-labelledby="tab2-label">
                <div className="tabcontent_wrapper">
                    <div className="tab_content">
                        <span>Single Reduced</span>
                        <p>T1, T2, T3, T4, T5</p>
                    </div>
                    <div className="tab_content">
                        <span>Double Reduced</span>
                        <p>DR7, DR8, DR9</p>
                    </div>
                </div>
            </section>

            <input type="radio" name="tab" id="tab3" role="tab" />
            <label for="tab3" id="tab3-label" className="tabs_links"><img src="../assets/images/ElectrolyticTinplate/table.webp" alt="" /> Surface Finish</label>
            <section aria-labelledby="tab3-label">
                <div className="tabcontent_wrapper">
                    <div className="tab_content">
                        <span>Surface Finish</span>
                        <p>Bright, Stone, Silver, Matt</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section> */}


            {/* <!-- BIS Standards in Cold Rolled Steel section starts here --> */}

            <section className={classes.bis_section}>
                <div className={`${classes.bis_section_wrapper} ${classes.container}`}>
                    <h3>BIS Standards for Tin Mill Black Plate</h3>
                    <div className={classes.bis_content_wrapper}>

                        <div className={classes.bis_content}>
                            <div className={`${classes.bis_content_main} ${classes.last_row}`}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- tabs section starts here  --> */}

            <div className={`${classes.container} ${classes.section_strip}`}>
                <Box>
                    <Box>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label="Cable Armour" {...tabprops(0)} />
                            <Tab label="Deck Plate" {...tabprops(1)} />
                            <Tab label="Metal Lath" {...tabprops(2)} />
                            <Tab label="Construction Material" {...tabprops(3)} />
                            <Tab label="Cans" {...tabprops(4)} />
                        </Tabs>
                    </Box>
                    <ProductTabs value={value} index={0}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/TinMillBlackPlate/cableArmour.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Cable Armour</h2>
                                <p className={classes.gal_card_des}>Tin Mill Black Plate provides robust cable armour, protecting electrical
                                    and communication cables from external elements and mechanical stress.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={1}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/TinMillBlackPlate/deckPlate.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Deck Plate</h2>
                                <p className={classes.gal_card_des}>Tin Mill Black Plate creates corrosion-resistant deck plates for ships
                                    and offshore structures. Its dimensional accuracy and smooth surface finish enhance
                                    structural integrity.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={2}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/TinMillBlackPlate/metalLath.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Metal Lath</h2>
                                <p className={classes.gal_card_des}>Tin Mill Black Plate is essential in producing metal lath, a versatile
                                    construction material for wall and ceiling applications. Its formability and consistent
                                    surface finish make it suitable for diverse construction projects.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={3}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/TinMillBlackPlate/constructionM.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Construction Material</h2>
                                <p className={classes.gal_card_des}>Tin Mill Black Plate is commonly used in construction for roofing,
                                    framing, and cladding due to its strength and dimensional accuracy, ensuring the longevity
                                    and stability of structures.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={4}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/TinMillBlackPlate/cans.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Cans</h2>
                                <p className={classes.gal_card_des}>Tin Mill Black Plate produces secure and dependable packaging for
                                    various consumer goods, including food, beverages, and other commodities.</p>
                            </div>
                        </div>
                    </ProductTabs>
                </Box>
            </div>

            {/* <!-- cards for mobile view  --> */}
            <section className={`${classes.section_strip} ${classes.mobile_section_cards}`}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Applications </h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/TinFreeSteel/bottleCaps.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>bottle crown & metal caps</span>
                                    <p>Tin-free steel is used for bottle caps, providing precise dimensions, strength, and
                                        tamper resistance. The resulting metal caps are durable and malleable, suitable for
                                        various containers. Its consistent surface finish and accuracy make it ideal for diverse
                                        packaging applications.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/TinFreeSteel/chemicalPackaging.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>chemical packaging</span>
                                    <p>Tin-free steel is ideal for chemical packaging. It is corrosion-resistant and ensures
                                        product integrity during storage and transportation.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/TinFreeSteel/printedCircuitBoards.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>printed circuit boards</span>
                                    <p>Tin-free steel is used in electronics to manufacture high-performance PCBs with precise
                                        dimensions and smooth surfaces for optimal device functionality.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/TinFreeSteel/paintContainers.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>paint containers</span>
                                    <p>Tin-free steel is commonly used to make paint containers due to its formability and
                                        smooth surface finish, which ensures safe storage and easy use of paint products.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/TinFreeSteel/photographicFilmCases.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>photographic film cases</span>
                                    <p>Photographic films are stored in tin-free steel cases due to their consistent dimensions
                                        and strength, which protect and preserve sensitive materials and ensure their longevity.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default TinMillBlackPlateTemplate;