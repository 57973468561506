import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { EMAIL_SUBSCRIPTION_CONTEXT } from "../utils/types";

export const CATEGORY_URLS = {
    CREATE_EMAIL_SUBSCRIPTION: "/email-subscription",
};

export interface IEmailSubscriptionBody {
    email: string;
    context: EMAIL_SUBSCRIPTION_CONTEXT
}

export const useEmailSubscriptionService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const createEmailSubscription = (data: IEmailSubscriptionBody) => {
    return request(API_METHOD.POST, CATEGORY_URLS.CREATE_EMAIL_SUBSCRIPTION, authenticatedUser, data);
  };

  return {
    createEmailSubscription,
  };
};
