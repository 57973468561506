import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import Button from '../../atoms/Button/Button';
import GntnDetailModalTemplate from './GstnDetailModal.Template';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import { LocationSectionProps } from './AddBusinessLocation.template';
import GetNumberOfBusinessLocationTemplate from './GetNumberOfBusinessLocation.template';
import SuccessFailurePopup from '../../molecules/SuccessFailurePopup/SuccessFailurePopup';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.v4.border._secondaryDarkAccent}`,
        "&:hover": {
            border: `1px solid ${theme.palette.v4.border.secondaryLightAccent}`,
        },
    },
    mainContainer: {
        border: `1px solid ${theme.palette.v4.border._secondaryDarkAccent}`,
    },
    titleText: {
        color: theme.palette.v2.text.secondaryDark,
        lineHeight: "16.1px"
    },
    titleTextValue: {
        color: theme.palette.v2.text.secondaryDark,
        lineHeight: "22.4px"
    },
    button: {
        backgroundColor: theme.palette.border.primaryDarkAccent,
        color: 'white',
    },
}));

const ViewBusinessLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const [gstinModalDialogOpen, setGstinModalDialogOpen] = useState<boolean>(false);
    const [index, setIndexTo] = useState<number>();
    const [currentGstin, setCurrentGstin] = useState<string>('');
    const bussinessProfileService = useBusinessProfileService();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const [buttonShow, setButtonShow] = useState<boolean>(true);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const locationSectionProps: LocationSectionProps = {
        setCurrentSectionTo: setCurrentSectionTo,
        formik: formik,
    };

    const deleteLocation = async (index: number) => {
        setIsDeleteModalOpen(true);
        setDeleteIndex(index);
    };
    const onClickModal = (index: number) => {
        setGstinModalDialogOpen(true);
        setIndexTo(index)
    };
    const handleDeleteDialogClose = async (event: boolean) => {
        setIsDeleteModalOpen(false);
        if (event && deleteIndex != null) {
        let locations = [...formik.values.locations];
        locations.splice(deleteIndex, 1);
        formik.setFieldValue('locations', locations);
        const business = await bussinessProfileService.getBusinessProfile();
        if(locations?.length == 0){
            locations = [
                ...locations,
                {
                    gstin: business?.data?.data?.gstin,
                    state: business?.data?.data?.address?.city
                }
            ]
        }
        try {
            await bussinessProfileService.updateBusinessProfile(locations, 'LOCATIONS');
        } catch (error) {
            showSnackbar("error", `Business profile update failed ${error}`)
        }
        }
    };
    return (
        <> <div className={`${classes.mainContainer} grid gap-6 p-6 rounded-xl`}>
            {formik.values?.locations?.map((location: any, index: number) => (
                <div key={index} className={`${classes.container} p-6 rounded-lg`}>
                    <div className='flex justify-between'>

                        <div className='flex gap-x-14'>

                            <div className={`boder rounded-lg ${classes.button} w-12 h-12 text-lg flex justify-center text-center items-center font-semibold`}> {index + 1} </div>

                            <div className='flex gap-x-24'>
                                <div className='grid gap-y-1'>
                                    <text className={`${classes.titleText} font-normal text-sm`}>GST Number</text>
                                    <div className={`${classes.titleTextValue} font-medium text-base`}>{location.gstin}</div>
                                </div>
                                <div className='grid gap-y-1'>
                                    <text className={`${classes.titleText} font-normal text-sm`}>Location</text>
                                    <div className={`${classes.titleTextValue} font-medium text-base`}>{location.state}</div>
                                </div>
                            </div>

                        </div>


                        <div className='flex '>
                            <div className='my-auto'>
                                <Button
                                    variant="tertiaryContained"
                                    label="View Details"
                                    onClick={() => { onClickModal(index) }}
                                />
                            </div>
                            <div className='my-auto pl-16'>
                                <img src={deleteIcon} alt="deleteicon" className="blink-on-hover blink-on-click" onClick={() => deleteLocation(index)} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {
                    isDeleteModalOpen &&
                    <SuccessFailurePopup
                    width="458px"
                    height="400px"
                    status={false}
                    heading="Delete Location?"
                    description="Are you sure you want to delete the location?"
                    setShowPopup={setIsDeleteModalOpen}
                    button1={{
                        text: 'Yes',
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: () => handleDeleteDialogClose(true),
                    }}
                    button2={{
                        text: 'No',
                        variant: "secondaryContained",
                        size: "large",
                        onClick: () => handleDeleteDialogClose(false)
                    }}
                />
                }
            {gstinModalDialogOpen && index != null && <GntnDetailModalTemplate locations={formik.values?.locations} index={index} dialogOpen={gstinModalDialogOpen} setDialogOpen={setGstinModalDialogOpen} />}
        </div>
            {!(!buttonShow || formik.values.noOfRows) &&
                <div className='flex justify-self-end'>
                    <Button
                        variant="tertiaryContained"
                        label="Add New Location"
                        onClick={() => { setButtonShow(false) }}
                    />
                </div>}
            {
                (!buttonShow || formik.values.noOfRows )  && <GetNumberOfBusinessLocationTemplate {...locationSectionProps} />

            }

        </>
    );
}
export default ViewBusinessLocationTemplate;