import React from 'react'
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primaryLight}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.primaryDark}`
        }
    },
    background: {
        background: theme.palette.background.primaryLight
    }
}));
const CartPriceDetailsSkeletonTemplate: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={`${classes.container} p-4 h-96 rounded-lg overflow-hidden mb-4 max-w-md w-full`}>
            <div className="p-4">
                <div className={`h-8 w-3/4 mb-2  animate-pulse ${classes.background}`}></div>
                <div className={`w-1/2 mb-4 h-8  animate-pulse ${classes.background}`}></div>
                <div className={`mb-3 w-full h-8  animate-pulse ${classes.background}`}></div>
                <div className={`mb-3 w-2/3 h-6  animate-pulse ${classes.background}`}></div>
                <div className={`mb-3 w-3/2 h-6  animate-pulse ${classes.background}`}></div>
                <div className={`mb-3 w-1/2 h-6  animate-pulse ${classes.background}`}></div>
            </div>
        </div>
    )
}

export default CartPriceDetailsSkeletonTemplate;
