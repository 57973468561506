import { useEffect, useState } from 'react'
import * as yup from "yup";
import { useFormik } from 'formik';
import { HTTP_STATUS, MODE } from '../../../../utils/types';
import { useConfigurationService } from '../../../../services/useConfigurationService';
import { ICustomization } from '../../Catalogue/Customization/SelectCustomization.template';
import MaterialOtherPreferencesFormTemplate from './MaterialOtherPreferencesForm.template';
import MaterialOtherPreferencesViewTemplate from './MaterialOtherPreferencesView.template';
import { useMaterialRequirementOtherPreferenceService, IMaterialRequirementOtherPreference } from '../../../../services/useMaterialRequirementOtherPreferenceService';
import { GENERIC_EXCEPTION_CODE, MATERIAL_REQUIREMENT_CONSTANT, OTHER_MANUFACTURERS } from '../../../../utils/constant';
import { useSnackbar } from '../../../../hooks/useSnackBar';

export interface IMaterialOtherPreference {
    preferredDeliveryLocation: { label: string, value: string }[],
    preferredDeliveryTime: string,
    customization: string[],
    tradeType: string
}

interface MaterialOtherPreference {
    brands: string[] | null,
    customization: string[] | null,
}

const initialValues = {
    preferredDeliveryLocation: [],
    preferredDeliveryTime: "",
    customization: [],
    tradeType: ""
}

const validationSchema = yup.object().shape({
    preferredDeliveryTime: yup.string().required('Preferred Delivery Date is required'),
    preferredDeliveryLocation: yup.array()
        .of(yup.object())
        .min(1, 'You must select at least one option')
        .required('You must select at least one option'),
    tradeType: yup.string().required('tradeType is required'),
    customization: yup.array()
        .of(yup.string())
        .min(1, 'You must select at least one option')
        .required('You must select at least one option'),
})

const MaterialOtherPreferencesTemplate = () => {
    const configurationService = useConfigurationService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [customizations, setCustomizationTo] = useState<ICustomization[]>([]);
    const [mode, setModeTo] = useState<MODE>(MODE.VIEW);
    const [isFormMode, setIsFormModeTo] = useState<boolean>(false);
    const materialRequirementOtherPreferenceService = useMaterialRequirementOtherPreferenceService();
    const formik = useFormik<IMaterialOtherPreference>({
        initialValues,
        validationSchema,
        onSubmit: async (value) => {
            const payload = { ...value, preferredDeliveryLocation: value.preferredDeliveryLocation.map(option => option.value) }
            const res = await materialRequirementOtherPreferenceService.updateMaterialRequirementsOtherPreference(payload, MATERIAL_REQUIREMENT_CONSTANT.OTHER_PREFERENCE);
              
            if (res?.status === HTTP_STATUS.OK) {
                setIsFormModeTo(false);
                setModeTo(MODE.EDIT);
                showSnackbar('success', 'Successfully updated Other Preferences')
            } else {
                showSnackbar('error', 'Failed to update Other Preferences')
            }
        }
    })

    const handleSelectChange = (key: string) => (event: any) => {
        formik.setFieldValue(key, event.target.value);
    };

    const handleMultiSelectChange = (key: string) => (option: { label: string, value: string | number }[]) => {
        formik.setFieldValue(key, option);
    };

    const loadConfiguration = () => {
        configurationService.getConfiguration1({ contexts: "CUSTOMIZATION" })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    setCustomizationTo(res?.data?.data?.[0]?.data);
                }
            })
            .catch((error) => {
                console.error('Error', error);
                setCustomizationTo([]);
            });
    }

    const getMaterialRequirementOtherPreference = () => {
        materialRequirementOtherPreferenceService.getMaterialRequirementOtherPreference()
            .then((res: any) => {
                if (res?.status === HTTP_STATUS.OK) {
                    if (res.data.data.customization == null) {
                        setModeTo(MODE.ADD)
                        return;
                    }
                    formik.setValues({
                        ...formik.values,
                        ...{
                            customization: res?.data?.data?.customization,
                            preferredDeliveryLocation: res?.data?.data?.preferredDeliveryLocation.map((option: any) => ({ label: option, value: option })),
                            preferredDeliveryTime: res?.data?.data?.preferredDeliveryTime,
                            tradeType: res?.data?.data?.tradeType,
                        }
                    })
                    setModeTo(MODE.EDIT)
                } else if (res?.status === HTTP_STATUS.BAD_REQUEST && res.data.exceptionCode === GENERIC_EXCEPTION_CODE.DATA_NOT_FOUND) {
                    setModeTo(MODE.ADD)
                } else {
                    setModeTo(MODE.ADD)
                }
            }).catch((error: any) => {
                console.error('Error fetching material requirement : ' + error)
                setModeTo(MODE.ADD)
            })
    }

    useEffect(() => {
        getMaterialRequirementOtherPreference();
        loadConfiguration();
    }, [])

    const handleCustomization = (name: string) => (e: any) => {
        if (e.target.checked) {
            formik.setFieldValue('customization', [...formik.values.customization, name]);
        } else {
            formik.setFieldValue('customization', formik.values.customization.filter((item: any) => item != name));
        }
    }

    return (
        <>
            {SnackBarComponent}
            {isFormMode ? <MaterialOtherPreferencesFormTemplate
                handleCustomization={handleCustomization}
                customizations={customizations}
                handleSelectChange={handleSelectChange}
                handleMultiSelectChange={handleMultiSelectChange}
                formik={formik}
            />
                : <MaterialOtherPreferencesViewTemplate formik={formik} setModeTo={setModeTo} mode={mode} setIsFormModeTo={setIsFormModeTo} />}
        </>
    )
}

export default MaterialOtherPreferencesTemplate