import React from "react";
import { useLocation } from "react-router-dom";
import { default as MuiBreadCrumbs } from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { createUseStyles } from "react-jss";
import HomeIcon from '../../../assets/images/home_icon.svg';
import RightArrowIcon from '../../../assets/images/right_arrow.svg';
interface BreadCrumbsProps {
    crumbs?: { link: string, label: string }[]
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        marginTop: "26px"
    },
    title: {
        fontSize: "14px",
    },
    name: {
        color: theme.palette.v4.text.secondaryLightAccent,
    },
    lastName: {
        color: theme.palette.v3.text.primaryLight,
    },
    "@media (max-width: 476px)": {
        title: {
            fontSize: "12px",
        },
        container: {
            marginTop: "12px"
        },
    },
}));



const BreadcrumbItem = ({ name, isLast, routeTo }: { name: string; isLast: boolean; routeTo: string }) => {
    const classes = useStyles();
    const linkStyle = {
        fontWeight: "500",
        fontFamily: "IBM Plex Sans",
    };

    const crumbLabelFormater = (label: string) => {
        return label.split('-').map(label => capitalizeFirstLetter(label)).join(" ")
    }

    return (
        <Link className={`${classes.title} ${isLast && "pointer-events-none"} ${isLast ? classes.lastName : classes.name} select-none`} style={linkStyle} underline="none" href={routeTo}>
            {name === 'HOME' ? <img src={HomeIcon} alt=" " />
                : crumbLabelFormater(name)}
        </Link>
    );
};

const Breadcrumbs: React.FC<BreadCrumbsProps> = ({ crumbs }) => {
    const classes = useStyles();
    const location = useLocation();

    const getCrumbsFromLocation = () => {
        const pathnames = location.pathname.split("/").filter((x) => x);
        return pathnames.map((name, index) => ({
            link: `/${pathnames.slice(0, index + 1).join("/")}`,
            label: name
        }));
    }

    const crumbsData = crumbs?.length ? crumbs : getCrumbsFromLocation();

    return (
        <section className={classes.container}>
            <MuiBreadCrumbs separator={<img src={RightArrowIcon} alt=" " />} aria-label="breadcrumb" className="">
                <BreadcrumbItem key={'/'} name={'HOME'} isLast={false} routeTo={'/'} />
                {crumbsData.map((crumb, index) => {
                    const isLast = index === crumbsData.length - 1;
                    return (
                        <BreadcrumbItem key={crumb.link} name={crumb.label} isLast={isLast} routeTo={crumb.link} />
                    );
                })}
            </MuiBreadCrumbs>
        </section>
    );
};

export default Breadcrumbs;
