import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  container: {
    border: `1px solid ${ theme.palette.border.primaryLight }`,
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: `0px 0px 6px 0px ${ theme.palette.boxShadow.primaryDark }`,
    },
  },
  background: {
    background: theme.palette.background.primaryLight,
  }
}));

const CatalogueListItemSkeletonTemplate: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={ `${ classes.container } flex rounded-lg overflow-hidden h-60 mb-4  w-full` }>
      <div className={ `w-1/5  ${ classes.background }` }></div>
      <div className="p-4 w-4/5">
        <div className={ `h-6 w-3/4 mb-2  animate-pulse ${ classes.background }` }></div>
        <div className={ `w-1/2 mb-4 h-4  animate-pulse ${ classes.background }` }></div>
        <div className={ `mb-3 w-full h-3  animate-pulse ${ classes.background }` }></div>
        <div className={ `mb-3 w-2/3 h-3  animate-pulse ${ classes.background }` }></div>
        <div className={ `mb-3 w-1/2 h-3  animate-pulse ${ classes.background }` }></div>
      </div>
    </div>
  );
};

export default CatalogueListItemSkeletonTemplate;
