
import { createUseStyles } from "react-jss";
import { CART_LIFE_CYCLE, FAVOURITE } from "../../../utils/constant";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import favouriteIcon from "../../../assets/images/favourite.svg";
import { useContext, useState, useEffect } from "react";
import { IUpdateWishlistRequest, useWishlistService } from "../../../services/useWishlistService";
import { CLASS_STATES, HTTP_STATUS } from "../../../utils/types";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { WishlistContext } from "../../../contexts/WishlistContext";

interface AddToFavouriteTemplateProps {
    id?: number;
    catalogueType?: string;
}

const useStyles = createUseStyles((theme: any) => ({
    favouriteWrapper: {
        paddingTop: "11px",
        borderRadius: "10px",
        border: `1px solid ${theme.palette.v4.border._primaryLight}`,
        color: theme.palette.v4.text._primaryDarkAccent,
    },
    selectedFavourite: {
        border: `1px solid ${theme.palette.v4.border.secondaryDarkAccent}`,
        background: theme.palette.v4.background.secondaryDarkAccent,
    },
    selectedText: {
        color: theme.palette.v4.text._primaryDark,
    }
}))

const AddToFavouriteTemplate: React.FC<AddToFavouriteTemplateProps> = ({ id, catalogueType }) => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const wishlistContextData = useContext(WishlistContext);
    const wishlistService = useWishlistService();
    const [selectedFavourite, setSelectedFavourite] = useState<boolean>(false);

    useEffect(() => {
        if (id && wishlistContextData.favourites) {
            if (catalogueType === CLASS_STATES.STANDARD || catalogueType === CLASS_STATES.NONSTANDARD) {
                setSelectedFavourite(wishlistContextData?.favourites?.catalogueIds?.includes(id));
            } else if (catalogueType === CLASS_STATES.SECONDARY) {
                setSelectedFavourite(wishlistContextData?.favourites?.secondaryCatalogueIds?.includes(id));
            }
        }
    }, [id, catalogueType, wishlistContextData?.favourites]);

    const handleFavouriteClicked = () => {
        setSelectedFavourite(!selectedFavourite);
        updateWishlist();
    }

    const updateWishlist = async () => {

        try {
            if(id === null) return
            const payload: any = {
                userId: user?.id,
                favourites: {
                    catalogueIds: [...wishlistContextData.favourites.catalogueIds],
                    secondaryCatalogueIds: [...wishlistContextData.favourites.secondaryCatalogueIds]
                }
            };
            if (catalogueType === CLASS_STATES.STANDARD || catalogueType === CLASS_STATES.NONSTANDARD) {
                if (selectedFavourite) {
                    payload.favourites.catalogueIds = payload.favourites.catalogueIds.filter((cId: number) => cId !== id);
                } else {
                    payload.favourites.catalogueIds.unshift(id);
                }
            } else if (catalogueType === CLASS_STATES.SECONDARY) {
                if (selectedFavourite) {
                    payload.favourites.secondaryCatalogueIds = payload.favourites.secondaryCatalogueIds.filter((cId: number) => cId !== id);
                } else {
                    payload.favourites.secondaryCatalogueIds.unshift(id);
                }
            }

            const wishlistResponse = await wishlistService.updateWishlist(payload);
            if (wishlistResponse?.status === HTTP_STATUS.OK) {
                await wishlistContextData.syncWishlist(CART_LIFE_CYCLE.REMOVE_PRODUCT);
                return true;
            } else {
                throw new Error("Wishlist Details fetch failed");
            }
        } catch (error) {
            return false;
        }
    };

    return (
        <button className={`flex justify-center cursor-pointer gap-2 w-full h-12 ${classes.favouriteWrapper} ${selectedFavourite ? classes.selectedFavourite : ""}`} onClick={handleFavouriteClicked}>
            {selectedFavourite ? <img src={favouriteIcon} alt="fav" className="pt-0.5" /> : <FavoriteBorderIcon />}
            <span className={`text-base font-medium h-6 ${selectedFavourite ? classes.selectedText : ""}`}>{FAVOURITE.ADD_TO_FAVOURITE}</span>
        </button>
    )
}

export default AddToFavouriteTemplate;
