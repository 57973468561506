import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export const MAIL_URLS = {
  SEND_MAIL: "/product-share-email",
};

export interface IMailSenderRequestBody {
  to: string[];
  message: string;
  data: any;
}

export const useMailSenderService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const sendMail = (data: IMailSenderRequestBody) => {
    return request(API_METHOD.POST, MAIL_URLS.SEND_MAIL, authenticatedUser, data);
  };

  return {
    sendMail,
  };
};
