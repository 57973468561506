import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { IKeyDetail } from '../../../pages/DashBoard/KeyPersonDetails.page';
import { FormikErrors } from 'formik';
import MultiSelectInput, { Link } from '../../../atoms/MultiSelectInput/MultiSelectInput';
import { IPocSectionProps } from './ViewKeyPersonDetails.template';
import ImageUploadModalTemplate from '../../OnBoarding/ImageUploadModal.template';
import { useFileService } from '../../../../services/useFileService';
import { DOCUMENT_RESOURCE_TYPE, PREFIX, SOCIAL_LINKS } from '../../../../utils/constant';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS, WHATSAPP_LINK } from '../../../../utils/types';
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import SelectV2 from '../../../atoms/Select/SelectV2';
import Checkbox from '@mui/material/Checkbox';
import noProfileImage from '../../../../assets/icons/noProfileImage.svg';
import editPenOutlinedIcon from "./../../../../assets/icons/editPenOutlinedIcon.svg"
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import MultiSelectInput2, { IMultiSelectOption } from '../../../atoms/MultiSelectInput/MultiSelectInput2';

const useStyles = createUseStyles((theme: any) => ({

    helperText: {
        position: 'absolute',
        bottom: '-20px',
    },
    imageContainer: {
        '&:hover $editButton': {
            opacity: 1,
            color: 'blue',
        },
    },
    errorField: {
        color: theme.palette.action.danger
    },
    editButton: {
        background: theme.palette.v4.background.primaryDarkAccent,
        opacity: 0,
        transition: 'opacity 0.3s ease',
        padding: "10px",
        gap: "8px",
        borderRadius: "6px"
    },
    imageHeading: {
        color: theme.palette.text.secondaryDark
    },
    text: {
        color: theme.palette.text.secondaryDarkLight
    },
    checkedInputColor: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    title: {
        color: theme.palette.textV2.primary.primary870,
    },
    social: {
        color: theme.palette.textV2.primary.primary550
    },
    select: {
        width: '384px !important'
    },
}));

const AddKeyPersonDetailsTemplate: React.FC<IPocSectionProps> = ({ formik, poc, index }) => {

    const classes = useStyles();
    const fileService = useFileService();

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user } = useAuthenticatedUser();
    const [data, setData] = useState<any>(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [errors, setErrors] = useState<{ [key: number]: { [key: string]: string } }>({});

    const handleMultiSelectChange = (index: number) => (selectedOptions: IMultiSelectOption[]) => {
        const currentSocialLinks = formik.values.pocs[index].socialLinks || {};
        const updatedSocialLinks: { [key: string]: string } = {};
        selectedOptions.forEach(option => {
            updatedSocialLinks[option.value] = currentSocialLinks[option.value] || '';
        });
        formik.setFieldValue(`pocs.${index}.socialLinks`, updatedSocialLinks);
    };

    const handleTextFieldChange = (key: string, index: number) => (event: any) => {
        const value = event.target.value;
        const currentSocialLinks = { ...formik.values.pocs[index].socialLinks };
        currentSocialLinks[key] = value;
        formik.setFieldValue(`pocs.${index}.socialLinks`, currentSocialLinks);
    };

    const handleWhatsAppLinkChange = (value: any, index: number) => {
        const currentValue = formik.values.pocs[index].whatsAppLink;
        formik.setFieldValue(`pocs.${index}.whatsAppLink`, currentValue === value ? null : value);
    };

    const handleImageModalOpen = (index: number) => {
        setCurrentIndex(index);
        setIsImageModalOpen(true);
    };

    const handleImageModalClose = () => {
        setIsImageModalOpen(false);
    };

    useEffect(() => {
        setData(formik.values?.pocs?.[index])
    }, [formik.values, index])

    const handleSubmit = async (index: number) => {
        try {
            if (selectedFile) {
                const presignedUrlResponse = await fileService.generatePresignedUrl(selectedFile.name, DOCUMENT_RESOURCE_TYPE.BUSINESS_PROFILE_KEY_PERSON_DETAILS, 1);
                if (presignedUrlResponse && presignedUrlResponse.data && presignedUrlResponse.data.data && presignedUrlResponse.data.data.presignedUrl) {
                    const presignedUrl = presignedUrlResponse.data.data.presignedUrl;
                    const imageUploadResponse = await fileService.uploadImageUsingPreSignedUrls(selectedFile, presignedUrl);
                    const pocProfilePicUrl = await fileService.getImagePreSignedUrl(presignedUrlResponse.data.data.objectKey);
                    if (pocProfilePicUrl.status === HTTP_STATUS.OK) {
                        formik.setFieldValue(`pocs[${currentIndex}].profilePic`, pocProfilePicUrl?.data?.data);
                    }
                } else {
                    showSnackbar("error", 'Failed to generate presigned URL')
                }
            }
        } catch (error) {
            showSnackbar("error", 'File upload failed:')

        } finally {
            setIsImageModalOpen(false);
            setSelectedFile(null);
        }
    };

    const validatePoc = (pocs: IKeyDetail[], newPocs: IKeyDetail[], type: string) => (event: any) => async (index: number) => {
        const { name, value } = event.target;
        const nameField = name.split(".")[0];
        formik.setFieldValue(`${nameField}[${index}].${type}`, value);

        const field = name.split('.').pop();
        if (!field) return;

        const currentErrors = { ...errors };
        currentErrors[index] = currentErrors[index] || {};

        let errorMessage = '';
        const fieldsToCheck: (keyof IKeyDetail)[] = ['email', 'alternateEmail', 'mobile', 'alternateMobile'];

        if (!value) {
            currentErrors[index][type] = '';
            setErrors(currentErrors);
            formik.setFieldTouched(`pocs[${index}].${type}`, false, false);
            return;
        }

        if (pocs.some((poc: any) => poc[field] === value) || newPocs.some((poc: any, idx: number) => idx !== index && poc[field] === value)) {
            errorMessage = `${field.charAt(0).toUpperCase() + field.slice(1)} already exists`;
        }

        if (field === 'alternateMobile' && !/^[0-9]*$/.test(value)) {
            errorMessage = 'Alternate Mobile must be a valid number';
        }

        for (let checkField of fieldsToCheck) {
            if (checkField !== field && pocs[index]?.[checkField] === value) {
                errorMessage = `${field.charAt(0).toUpperCase() + field.slice(1)} cannot be the same as ${checkField.charAt(0).toUpperCase() + checkField.slice(1)}`;
            }
        }
        currentErrors[index][type] = errorMessage
        setErrors(currentErrors);

        if (errorMessage) {
            formik.setFieldValue(`pocs[${index}].${type}`, null);
            formik.setFieldTouched(`pocs[${index}].${type}`, true, false);
        }
    };

    return (
        <form>
            {isImageModalOpen &&
                <ImageUploadModalTemplate
                    dialogOpen={isImageModalOpen}
                    setDialogOpen={handleImageModalClose}
                    onSubmit={(e: any) => handleSubmit(e)}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                />}
            <div key={index}>
                <div className='gap-y-1 gap-x-6 flex'>
                    <div>
                        <span className={`text-base font-medium flex pb-3 ${classes.imageHeading}`}>Key Person Images</span>
                        <div className={`${classes.imageContainer} rounded-xl flex w-36 h-40 relative justify-between`}>
                            <div>
                                <img src={poc?.profilePic || noProfileImage} alt="Profile" className="w-full h-full  rounded-xl object-cover" />
                            </div>
                            <img src={editPenOutlinedIcon} alt="Profile" className={`${classes.editButton} cursor-pointer absolute top-2 right-2 z-10`} onClick={() => handleImageModalOpen(index)} />
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className='grid gap-y-6 mb-6'>
                            <div className='grid grid-cols-2 gap-x-6'>
                                <div className='grid gap-y-1'>
                                    <SelectV2
                                        {...formik.getFieldProps(`pocs.${index}.prefix`)}
                                        variant="outlined"
                                        label="Prefix"
                                        value={formik.values.pocs[index]?.prefix}
                                        id="sort"
                                        options={PREFIX}
                                    />
                                </div>
                                <div className='grid gap-y-1'>
                                    <TextFieldV2
                                        label="Name"
                                        placeholder="Enter Name"
                                        variant="outlined"
                                        {...formik.getFieldProps(`pocs.${index}.name`)}
                                        error={
                                            formik.touched.pocs?.[index]?.name &&
                                            Boolean(
                                                formik.errors.pocs &&
                                                Array.isArray(formik.errors.pocs) &&
                                                (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.name
                                            )
                                        }
                                        helperText={
                                            formik.touched.pocs?.[index]?.name &&
                                            formik.errors.pocs &&
                                            Array.isArray(formik.errors.pocs) &&
                                            (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.name
                                        }
                                        FormHelperTextProps={{ className: `${classes.helperText}` }}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-x-6 gap-y-1'>
                            <div className='grid gap-y-1 '>
                                <TextFieldV2
                                    label="Department"
                                    placeholder="Enter Department"
                                    variant="outlined"
                                    {...formik.getFieldProps(`pocs.${index}.department`)}
                                    error={
                                        formik.touched.pocs?.[index]?.department &&
                                        Boolean(
                                            formik.errors.pocs &&
                                            Array.isArray(formik.errors.pocs) &&
                                            (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.department
                                        )
                                    }
                                    helperText={
                                        formik.touched.pocs?.[index]?.department &&
                                        formik.errors.pocs &&
                                        Array.isArray(formik.errors.pocs) &&
                                        (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.department
                                    }
                                    FormHelperTextProps={{ className: `${classes.helperText}` }}
                                />
                            </div>
                            <div className='grid gap-y-1'>
                                <TextFieldV2
                                    label="Designation"
                                    placeholder="Enter Designation"
                                    variant="outlined"
                                    {...formik.getFieldProps(`pocs.${index}.designation`)}
                                    error={
                                        formik.touched.pocs?.[index]?.designation &&
                                        Boolean(
                                            formik.errors.pocs &&
                                            Array.isArray(formik.errors.pocs) &&
                                            (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.designation
                                        )
                                    }
                                    helperText={
                                        formik.touched.pocs?.[index]?.designation &&
                                        formik.errors.pocs &&
                                        Array.isArray(formik.errors.pocs) &&
                                        (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.designation
                                    }
                                    FormHelperTextProps={{ className: `${classes.helperText}` }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-x-6 gap-y-1 mb-6 pt-6'>
                    <div className='grid gap-y-1'>
                        <TextFieldV2
                            label="Email ID"
                            placeholder="Enter email"
                            variant="outlined"
                            {...formik.getFieldProps(`pocs.${index}.email`)}
                            error={
                                formik.touched.pocs?.[index]?.email &&
                                Boolean(
                                    formik.errors.pocs &&
                                    Array.isArray(formik.errors.pocs) &&
                                    (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.email
                                )
                            }
                            helperText={
                                formik.touched.pocs?.[index]?.email &&
                                formik.errors.pocs &&
                                Array.isArray(formik.errors.pocs) &&
                                (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.email
                            }
                            FormHelperTextProps={{ className: `${classes.helperText}` }}
                            disabled={!!user?.email}
                        />
                    </div>
                    <div className='grid'>
                        <TextFieldV2
                            label="Alternate Email ID"
                            placeholder="Enter email"
                            variant="outlined"
                            {...formik.getFieldProps(`pocs.${index}.alternateEmail`)}
                            error={
                                formik.touched.pocs?.[index]?.alternateEmail &&
                                Boolean(
                                    formik.errors.pocs &&
                                    Array.isArray(formik.errors.pocs) &&
                                    (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.alternateEmail
                                )
                            }
                            helperText={
                                errors[index]?.alternateEmail || (
                                    formik.touched.pocs?.[index]?.alternateEmail &&
                                    formik.errors.pocs &&
                                    Array.isArray(formik.errors.pocs) &&
                                    (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.alternateEmail
                                )
                            }
                            onChange={(event) => validatePoc(formik.values.pocs, formik.values.newPocs, 'alternateEmail')(event)(index)}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-x-6 gap-y-1 mb-6 '>
                    <div className='grid gap-y-1 '>
                        <label className={`${classes.text}`}>
                            Phone Number </label>
                        <div className='flex gap-x-2'>
                            <div className='w-1/4'>
                                <TextFieldV2
                                    {...formik.getFieldProps(`pocs.${index}.countryCode`)}
                                    value="IND +91"
                                    variant="outlined"
                                    disabled={true}
                                />
                            </div>
                            <div className='w-3/4'>
                                <TextFieldV2
                                    placeholder="Enter number"
                                    variant="outlined"
                                    {...formik.getFieldProps(`pocs.${index}.mobile`)}
                                    error={
                                        formik.touched.pocs?.[index]?.mobile &&
                                        Boolean(
                                            formik.errors.pocs &&
                                            Array.isArray(formik.errors.pocs) &&
                                            (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.mobile
                                        )
                                    }
                                    helperText={
                                        formik.touched.pocs?.[index]?.mobile &&
                                        formik.errors.pocs &&
                                        Array.isArray(formik.errors.pocs) &&
                                        (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.mobile
                                    }
                                    FormHelperTextProps={{ className: `${classes.helperText}` }}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <label className={`${classes.text}`}>
                            <Checkbox
                                id={`mobile-${index}`}
                                checked={formik.values.pocs[index].whatsAppLink === WHATSAPP_LINK.PRIMARY}
                                onChange={() => handleWhatsAppLinkChange(WHATSAPP_LINK.PRIMARY, index)}
                                className={` ${classes.checkedInputColor}`}
                            />
                            Phone Number </label>
                    </div>
                    <div className='grid gap-y-1'>
                        <label className={`${classes.text}`}>
                            Alternate Phone Number </label>
                        <div className='flex gap-x-2'>
                            <div className='w-1/4'>
                                <TextFieldV2
                                    {...formik.getFieldProps(`pocs.${index}.countryCode`)}
                                    value="IND +91"
                                    variant="outlined"
                                    disabled={true}
                                />
                            </div>
                            <div className='w-3/4'>
                                <TextFieldV2
                                    placeholder="Enter number"
                                    variant="outlined"
                                    {...formik.getFieldProps(`pocs.${index}.alternateMobile`)}
                                    error={
                                        formik.touched.pocs?.[index]?.alternateMobile &&
                                        Boolean(
                                            formik.errors.pocs &&
                                            Array.isArray(formik.errors.pocs) &&
                                            (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.alternateMobile
                                        )
                                    }
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    helperText={
                                        errors[index]?.alternateMobile || (
                                            formik.touched.pocs?.[index]?.alternateMobile &&
                                            formik.errors.pocs &&
                                            Array.isArray(formik.errors.pocs) &&
                                            (formik.errors.pocs as FormikErrors<IKeyDetail>[])[index]?.alternateMobile
                                        )
                                    }
                                    onChange={(event) => validatePoc(formik.values.pocs, formik.values.newPocs, 'alternateMobile')(event)(index)}
                                />
                            </div>
                        </div>
                        <label className={`${classes.text}`}>
                            <Checkbox
                                checked={formik.values.pocs[index].whatsAppLink === WHATSAPP_LINK.SECONDARY}
                                onChange={() => handleWhatsAppLinkChange(WHATSAPP_LINK.SECONDARY, index)}
                                id={`alternateMobile-${index}`}
                                className={`m-2 ${classes.checkedInputColor}`}
                            />
                            Alternate Phone Number </label>
                    </div>
                </div>
                <div className={`grid gap-y-1`}>
                    <div className={classes.select}>
                        <MultiSelectInput2
                            {...formik.getFieldProps(`pocs[${index}].socialLinks`)}
                            options={SOCIAL_LINKS.map((link) => ({
                                label: link.label,
                                value: link.label,
                            }))}
                            label="Social Media Links"
                            value={Object.keys(formik?.values?.pocs[index]?.socialLinks || {}).map(key => ({
                                label: key,
                                value: key
                            }))}
                            onchange={handleMultiSelectChange(index)}
                        />
                    </div>
                    {formik.values?.pocs?.[index]?.socialLinks &&
                        Object.keys(formik.values.pocs[index].socialLinks).length > 0 &&
                        Object.keys(formik.values.pocs[index].socialLinks).map((socialTag) => (
                            <TextFieldV2
                                {...formik.getFieldProps(`pocs[${index}].socialLinks[${socialTag}]`)}
                                variant="outlined"
                                label={
                                    <span className='flex gap-1 text-base font-medium !mt-5 !mb-2'>
                                        <span className={classes.title}>Enter Link </span>
                                        <span className={classes.social}>({socialTag})</span>
                                    </span>
                                }
                                value={formik.values.pocs[index].socialLinks[socialTag] || ''}
                                onChange={handleTextFieldChange(socialTag, index)}
                                error={
                                    formik.touched.pocs?.[index]?.socialLinks?.[socialTag] &&
                                    ((formik.errors.pocs as any)?.[index]?.socialLinks?.[socialTag])
                                }
                                helperText={
                                    Boolean(formik.touched.pocs?.[index]?.socialLinks) &&
                                    ((formik.errors.pocs as any)?.[index]?.socialLinks?.[socialTag])
                                }
                            />
                        )
                        )}
                </div>
            </div>
        </form>
    )
}
export default AddKeyPersonDetailsTemplate