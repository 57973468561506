import React from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import noProduct from "../../../assets/images/noProducts.svg";
import bannerBackground from "../../../assets/images/bannerBackgound.jpg";
import ImageWithContent from "../../atoms/ImageWithContent/ImageWithContent";

const useStyles = createUseStyles((theme: any) => ({
  container:{
    width: "60%"
  },
  iconTitle: {
    color:  theme.palette.text.secondaryDark,
    fontSize: "36px"
  },
  "@media (max-width: 480px)": {
    container:{
      width: "80%"
    },
    iconTitle: {
      fontSize: "24px"
    },
  }
}));

const NoCatalogueTemplate = () => {
  const classes = useStyles();
  return (
    <div className="grid gap-y-16 pb-3">
      <div className={`${classes.container} grid gap-y-4 m-auto`}>
        <img className="m-auto" src={noProduct} alt="pic" />
        <div className={` ${classes.iconTitle} font-black m-auto`}>No Products Found</div>
      </div>
    </div>
  );
};

export default NoCatalogueTemplate;
