import React, { useEffect, useMemo, useState } from "react";
import bgImg from "../../../assets/images/authBackground.svg"
import { createUseStyles } from "react-jss";
import CompanyInformationTemplate from "./CompanyInformation.template";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { AUTH_STATES } from "../../../utils/types";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useAuthService } from "../../../services/useAuthService";
import { Dialog, DialogContent } from '@mui/material';
import LoginWithMobileTemplate from "./LoginWithMobile.template";
import SignUpWithMobileTemplate from "./SignUpWithMobile.template";
import OtpVerificationTemplate from "./OtpVerification.template";
import LoginWithEmailTemplate from "./LoginWithEmail.template";
import PasswordTemplate from "./Password.template";
import { useLocation } from 'react-router-dom';
import RegisterEmailTemplate from "./RegisterEmail.template";
import EmailConfirmationTemplate from "./EmailConfirmation.template";

const useStyles = createUseStyles((theme: any) => ({
    backgroundImg: {
        backgroundImage: `url(${bgImg})`,
    },
    closeButton: {
        cursor: 'pointer',
    },
    sectionText: {
        color: theme.palette.text.primaryDarkAccent
    },
    errorMessage: {
        color: theme.palette.action.danger
    },
    separator: {
        color: theme.palette.general.seperator,
        '& > span:nth-child(odd)': {
            flex: 1,
            border: `1px solid ${theme.palette.general.seperator}`,
            height: '1px',
        },
    }

}))

export interface IncognitoUser {
    email?: string,
    mobileNumber?: string,
    fullName?: string,
    gstin?: string,
    isGstinVerified?:boolean
}

export enum EMAIL_TYPE {
    EMIAL_VERIFICATION = "EMIAL_VERIFICATION",
    FORGET_PASSWORD = "FORGET_PASSWORD"
}

const AuthenticationTemplate: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const { user, isAuthDialogActive, syncAuthDialogActive } = useAuthenticatedUser();
    const [authState, setAuthState] = useState<AUTH_STATES>(() => {
        return new URLSearchParams(location.search).get('token')?.length ?
        AUTH_STATES.SET_PASSWORD : AUTH_STATES.LOGIN_WITH_MOBILE;
    });
    const authService = useAuthService();
    const [userDetails, setUserDetail] = useState<IncognitoUser>({ mobileNumber: user?.mobileNumber ?? "", email: user?.email, fullName: user?.fullName, gstin: user?.gstin, isGstinVerified: false});
    const [sentEmailType, setSentEmailType] = useState<AUTH_STATES>(AUTH_STATES.FORGOT_PASSWORD);
    const [otpVerificationProps, setOtpVerificationProps] = useState<({ otpVerifier: (verificationInfo: any) => Promise<any>, section: AUTH_STATES })>({
        otpVerifier: authService.login, section: AUTH_STATES.LOGIN_WITH_MOBILE
    });

    const updateUserDetails = (name: string, value: string | boolean) => {
        setUserDetail((prev: any) => ({ ...prev, [name]: value, }));
    }

    const authStateView = useMemo(() => {
        switch (authState) {
            case AUTH_STATES.LOGIN_WITH_MOBILE: return <LoginWithMobileTemplate setAuthState={setAuthState} mobileNumber={userDetails?.mobileNumber} updateUserDetails={updateUserDetails} styles={classes} />;
            case AUTH_STATES.USER_SIGNUP: return <SignUpWithMobileTemplate setAuthState={setAuthState} updateUserDetails={updateUserDetails} userDetails={userDetails}/>;
            case AUTH_STATES.OTP_VERIFICATION: return <OtpVerificationTemplate setAuthState={setAuthState} userDetails={userDetails} {...otpVerificationProps} />;
            case AUTH_STATES.EMAIL_CONFIRMAITON: return <EmailConfirmationTemplate setAuthState={setAuthState} sentEmailType={sentEmailType} userDetails={userDetails} authState={authState}/>;
            case AUTH_STATES.EMAIL_REGISTRATION: return <RegisterEmailTemplate setAuthState={setAuthState} updateUserDetails={updateUserDetails} setSentEmailType={setSentEmailType} authState={authState}/>;
            case AUTH_STATES.FORGOT_PASSWORD: return <RegisterEmailTemplate setAuthState={setAuthState} updateUserDetails={updateUserDetails} setSentEmailType={setSentEmailType} authState={authState}/>;
            case AUTH_STATES.SET_PASSWORD: return <PasswordTemplate setAuthState={setAuthState} />;
            default: return <LoginWithEmailTemplate setAuthState={setAuthState} styles={classes} />;
        }
    }, [authState,userDetails]);

    useEffect(() => {
        if (authState === AUTH_STATES.LOGIN_WITH_MOBILE || authState === AUTH_STATES.USER_SIGNUP) {
            setOtpVerificationProps(authState === AUTH_STATES.LOGIN_WITH_MOBILE ? {
                otpVerifier: authService.login,
                section: AUTH_STATES.LOGIN_WITH_MOBILE
            } : {
                otpVerifier: authService.signUp,
                section: AUTH_STATES.USER_SIGNUP
            });
        }
    }, [authState]);

    const handleDialogClose = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, reason: "backdropClick" | "escapeKeyDown" | null) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown")
            return;     
        syncAuthDialogActive(false);
        setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE);
    };

    const handleBackState = () => {
        setAuthState((prev) => {
            switch (prev) {
                case AUTH_STATES.EMAIL_CONFIRMAITON: default: return sentEmailType;
                case AUTH_STATES.FORGOT_PASSWORD: return AUTH_STATES.LOGIN_WITH_EMAIL;
                case AUTH_STATES.OTP_VERIFICATION: return otpVerificationProps.section;
            }
        })
    }

    useEffect(()=>{
        setUserDetail({ mobileNumber: user?.mobileNumber ?? "", email: user?.email, fullName: user?.fullName })
    },[isAuthDialogActive, user])

    return (
        <Dialog fullWidth PaperProps={{ style: { height: 'auto', width: 'sm', borderRadius:"16px" } }}
            open={isAuthDialogActive} onClose={handleDialogClose}>
            <DialogContent className="flex items-center w-full !overflow-y-visible">
                <div className="w-full h-full rounded-3xl relative">
                    <div className="flex justify-between items-center">
                        <div className={`flex items-center justify-between absolute top-3.5 w-full`}>
                            <div>
                            {([AUTH_STATES.FORGOT_PASSWORD, AUTH_STATES.OTP_VERIFICATION, AUTH_STATES.EMAIL_CONFIRMAITON].includes(authState)) 
                            && <span className={`flex items-center cursor-pointer text-lg ${classes.sectionText}`} onClick={handleBackState}><ArrowBackIosIcon />Back</span>}
                            </div>
                            <div>
                            {authState !== AUTH_STATES.SET_PASSWORD && <span className={`${classes.closeButton} `} onClick={(event) => handleDialogClose(event, null)}><CloseIcon /></span>}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center shrink-0 w-full mb-2">
                        <div className='w-full'>
                            <CompanyInformationTemplate section={authState} sentEmailType={sentEmailType} />
                        </div>
                        {authStateView}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default AuthenticationTemplate;