import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ProductTabs, tabprops } from './ProductTabs.Template';

const ElectrolyticTemplate: React.FC = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <section className={classes.produts_banner}>
                <div className={`${classes.products_bannner_wrapper} ${classes.electro_banner}`}>
                    <div className={classes.products_banner_content}>
                        <h4>Products</h4>
                        <h3>Electrolytic Tinplate</h3>
                        <h1>Where Nature Conservation Meets Quality Preservation</h1>
                        {/* <!-- <div className="banner_btn">
                    <a href="#">Learn More</a>
                </div> --> */}
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className={classes.products_about_us}>

                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>About Electrolytic Tinplate (ETP)</h2>
                            <p className={classes.gal_card_des} >Tinplate is a lightweight, low-carbon steel sheet or strip coated with pure
                                tin on both surfaces.
                                It offers excellent corrosion resistance, soldering capability, and an attractive appearance.
                                The steel composition used is carefully controlled to ensure quality. Tinplate is available in
                                different forms and thicknesses, with varying tin coatings and surface finishes. It is versatile
                                and can be used in various industrial and packaging applications.</p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/ElectrolyticTinplate/about.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="specification_section section_strip seconday_cards_wrap section_strip_main m_card_parent">
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={`${classes.section_heading_main} ${classes.cards_heading}`}>Advantages of ETP Steel</h3>
                    <div className={ `${classes.p_card_wrapper} ${classes.card_wrapper_mobile}`}>
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/ElectrolyticTinplate/recycle.webp" alt="" />
                                <span>Recyclable</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/ElectrolyticTinplate/minus.webp" alt="" />
                                <span>Degradable</span>
                            </div>
                        </div>
                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/ElectrolyticTinplate/noAlcohol.webp" alt="" />
                                <span>Non Reactive Tin</span>
                            </div>
                        </div>

                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/ElectrolyticTinplate/printerMaintenance.webp" alt="" />
                                <span>Excellent Printing</span>
                            </div>
                        </div>

                        <div className={`${classes.card_main} ${classes.cards_secondary} ${classes.mobile_cards}`}>
                            <div className={`${classes.p_card_preview} ${classes.mobile_preview}`}>
                                <img src="../assets/images/ElectrolyticTinplate/streetFood.webp" alt="" />
                                <span>Food Packaging </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* <!-- specification range section starts here  --> */}
            {/* 
            <section className={`${classes.specification_section} ${classes.section_strip}`}>
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading_main}>Specifications range</h3>
                    <div className={classes.card_parent_wrap}>
                        <div className={classes.p_card_wrapper}>
                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg6.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Thickness Ranges</span>
                                        <span className={classes.p_card_s_text}>0.25~2.00 mm (±0.003mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg2.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Width Range</span>
                                        <span className={classes.p_card_s_text}>50~1250mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg3.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Length Range</span>
                                        <span className={classes.p_card_s_text}>600-3000 mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg4.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Outer Diameter</span>
                                        <span className={classes.p_card_s_text}>1500-2200 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg5.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Inner Diameter</span>
                                        <span className={classes.p_card_s_text}>510, 600, 610 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg1.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Coil Weight</span>
                                        <span className={classes.p_card_s_text}>3-20 tonnes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tabs spec-tabs container'>
                        <input type="radio" name="tab" id="tab1" role="tab" checked>
                            <label id="tab1-label" className="tabs_links"><img
                                src="../assets/images/ElectrolyticTinplate/tinCan.webp" alt="" /> Tin Coating</label>
                            <section aria-labelledby="tab1-label">
                                <div className="tabcontent_wrapper">
                                    <div className="tab_content">
                                        <span>Equal coating</span>
                                        <p>1.0/1.0, 2.0/2.0, 2.8/2.8, 5.6/5.6 (g/m²)</p>
                                    </div>
                                    <div className="tab_content">
                                        <span>Differential coating</span>
                                        <p>1.0/2.8, 2.8/4.0, 2.8/5.6, 2.0/5.6 (g/m²)</p>
                                    </div>
                                </div>
                            </section>

                            <input type="radio" name="tab" id="tab2" role="tab">
                                <label id="tab2-label" className="tabs_links"><img
                                    src="../assets/images/ElectrolyticTinplate/muscle.webp" alt="" /> Temper</label>
                                <section aria-labelledby="tab2-label">
                                    <div className="tabcontent_wrapper">
                                        <div className="tab_content">
                                            <span>Single Reduced</span>
                                            <p>T1, T2, T3, T4, T5</p>
                                        </div>
                                        <div className="tab_content">
                                            <span>Double Reduced</span>
                                            <p>DR7, DR8, DR9</p>
                                        </div>
                                    </div>
                                </section>

                                <input type="radio" name="tab" id="tab3" role="tab">
                                    <label id="tab3-label" className="tabs_links"><img
                                        src="../assets/images/ElectrolyticTinplate/table.webp" alt="" /> Surface Finish</label>
                                    <section aria-labelledby="tab3-label">
                                        <div className="tabcontent_wrapper">
                                            <div className="tab_content">
                                                <span>Surface Finish</span>
                                                <p>Bright, Stone, Silver, Matt</p>
                                            </div>
                                            <div className="tab_content">
                                                <div className="tab_content">
                                                    <span>Single Reduced</span>
                                                    <p>T1, T2, T3, T4, T5</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </div>
                            </div>
                    </div>
            </section> */}

            <section className={`${classes.specification_section} ${classes.section_strip}`}>
                <div className={`${classes.specification_section_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading_main}>Specifications range</h3>
                    <div className={classes.card_parent_wrap}>
                        <div className={classes.p_card_wrapper}>
                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg6.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Thickness Ranges</span>
                                        <span className={classes.p_card_s_text}>0.25~2.00 mm (±0.003mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg2.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Width Range</span>
                                        <span className={classes.p_card_s_text}>50~1250mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg3.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Length Range</span>
                                        <span className={classes.p_card_s_text}>600-3000 mm (±3/-0mm)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg4.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Outer Diameter</span>
                                        <span className={classes.p_card_s_text}>1500-2200 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg5.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Inner Diameter</span>
                                        <span className={classes.p_card_s_text}>510, 600, 610 mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.card_main}>
                                <div className={classes.card_main_wrap}>
                                    <div className={classes.p_card_preview}>
                                        <img src="../assets/images/ColdRolled/cardimg1.webp" alt="" />
                                    </div>
                                    <div className={classes.p_card_content}>
                                        <span className={classes.p_card_subtext}>Coil Weight</span>
                                        <span className={classes.p_card_s_text}>3-20 tonnes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- BIS Standards in Cold Rolled Steel section starts here --> */}

            <section className={classes.bis_section}>
                <div className={`${classes.bis_section_wrapper} ${classes.container}`}>
                    <h3>BIS Standards for Tinplate</h3>
                    <div className={classes.bis_content_wrapper}>
                        <div className={classes.bis_content}>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 277</span>
                                    <span className={classes.table_subheading}>Year : 2019</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Galvanized Steel Strips and Sheets (Plain & Corrugated)- Specification (Seventh Revision)
                                    </p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 2552</span>
                                    <span className={classes.table_subheading}>Year : 1989</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Steel Drums (galvanized And Ungalvanized)
                                    </p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 2552</span>
                                    <span className={classes.table_subheading}>Year : 1989</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Steel Drums (galvanized And Ungalvanized)
                                    </p>
                                </div>
                            </div>

                            <div className={`${classes.bis_content_main} ${classes.last_row}`}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 2552</span>
                                    <span className={classes.table_subheading}>Year : 1989</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Steel Drums (galvanized And Ungalvanized)
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.bis_content}>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 16732</span>
                                    <span className={classes.table_subheading}>Year : 2019</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Galvanized Structural Steel â€” Specification
                                    </p>
                                </div>
                            </div>

                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 16732</span>
                                    <span className={classes.table_subheading}>Year : 2019</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Galvanized Structural Steel â€” Specification
                                    </p>
                                </div>
                            </div>
                            <div className={classes.bis_content_main}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 16732</span>
                                    <span className={classes.table_subheading}>Year : 2019</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Galvanized Structural Steel â€” Specification
                                    </p>
                                </div>
                            </div>

                            <div className={`${classes.bis_content_main} ${classes.last_row}`}>
                                <div className={classes.bis_content_heading}>
                                    <span className={classes.table_heading}>Standard No: IS 17404</span>
                                    <span className={classes.table_subheading}>Year : 2020</span>
                                </div>
                                <div className={classes.bis_content_detail}>
                                    <p>Electrogalvanized Hot Rolled and Cold Reduced Carbon Steel Sheets and Strips —
                                        Specification</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- tabs section starts here  --> */}
            <div className={`${classes.container} ${classes.section_strip}`}>
                <Box>
                    <Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Furniture profiles cans" {...tabprops(0)} />
                            <Tab label="Chemical packaging" {...tabprops(1)} />
                            <Tab label="Paint Containers" {...tabprops(2)} />
                            <Tab label="Metal containers" {...tabprops(3)} />
                            <Tab label="imitation jewellery" {...tabprops(4)} />
                        </Tabs>
                    </Box>
                    <ProductTabs value={value} index={0}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ElectrolyticTinplate/furnitureProfiles.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Furniture profiles cans</h2>
                                <p className={classes.gal_card_des} >Electrolytic tin plate creates furniture profiles with uniform
                                    dimensions and a sleek finish, improving structural integrity, quality, and design
                                    versatility.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={1}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ElectrolyticTinplate/chemicalPackaging.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Chemical packaging</h2>
                                <p className={classes.gal_card_des} >Electrolytic tin plate is the preferred material for chemical packaging
                                    cans due to its corrosion-resistant properties, which ensure the safe containment of diverse
                                    chemical substances.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={2}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ElectrolyticTinplate/paintContainers.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Paint Containers</h2>
                                <p className={classes.gal_card_des} >Electrolytic tin plates are commonly used for producing durable paint
                                    containers with a smooth surface and optimal formability for easy handling and storage.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={3}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ElectrolyticTinplate/metalContainers.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>metal containers</h2>
                                <p className={classes.gal_card_des} >Electrolytic tinplate is a robust material used to make sturdy metal
                                    containers for various industries. These containers are precise in size and strength, ideal
                                    for safely transporting multiple materials from food to industrial supplies.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={4}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/ElectrolyticTinplate/Jewellery.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>imitation jewellery</h2>
                                <p className={classes.gal_card_des} >Electrolytic tin plates produce cost-effective and malleable imitation
                                    jewellery with intricate designs and a smooth surface finish, meeting fashion industry
                                    demands.</p>
                            </div>
                        </div>
                    </ProductTabs>
                </Box>
            </div>

            {/* <!-- cards for mobile view  --> */}

            <section className={`${classes.section_strip} ${classes.mobile_section_cards}`}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Applications </h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ElectrolyticTinplate/furnitureProfiles.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Furniture profiles cans</span>
                                    <p>Electrolytic tin plate creates furniture profiles with uniform dimensions and a sleek
                                        finish, improving structural integrity, quality, and design versatility.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ElectrolyticTinplate/chemicalPackaging.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>chemical packaging</span>
                                    <p>Electrolytic tin plate is the preferred material for chemical packaging cans due to its
                                        corrosion-resistant properties, which ensure the safe containment of diverse chemical
                                        substances.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ElectrolyticTinplate/paintContainers.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>paint containers</span>
                                    <p>Electrolytic tin plates are commonly used for producing durable paint containers with a
                                        smooth surface and optimal formability for easy handling and storage.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ElectrolyticTinplate/metalContainers.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>metal containers</span>
                                    <p>Electrolytic tinplate is a robust material used to make sturdy metal containers for
                                        various industries. These containers are precise in size and strength, ideal for safely
                                        transporting multiple materials from food to industrial supplies.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/ElectrolyticTinplate/Jewellery.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>imitation jewellery</span>
                                    <p>Electrolytic tin plates produce cost-effective and malleable imitation jewellery with
                                        intricate designs and a smooth surface finish, meeting fashion industry demands.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <NewsLetterTemplate />
        </>
    );
};

export default ElectrolyticTemplate;
