import React, { useEffect, useState } from 'react'
import { MODE } from '../../../../utils/constant';
import Dialog from '@mui/material/Dialog';
import { createUseStyles } from 'react-jss';
import { useAdminMasterService } from '../../../../services/useAdminMasterService';
import { HTTP_STATUS } from '../../../../utils/types';
import { IMaterialAttribute, IMaterialRequirement, IMaterialRequirementFormik } from '../../../pages/DashBoard/MyMaterialProduct.page';
import { IStandards } from '../../Home/ProductRequirementForm.template';
import * as yup from "yup";
import { useFormik } from 'formik';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useMaterialRequirementService } from '../../../../services/useMaterialRequirementService';
import CloseIcon from '@mui/icons-material/Close';
import AddMaterialFormTemplate from './AddMaterialForm.template';
import Button from '../../../atoms/Button/Button';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import MaterialViewTemplate from './MaterialView.template';

interface MaterialListingActionTemplateProps {
    mode: string
    toggleDialogue: (value: boolean) => void;
    isDialogActive: boolean
    id: number | null;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "1057px !important",
            maxWidth: "1057px !important",
            height: "826px",
            borderRadius: "16px"
        }
    },
    heading: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    closeIcon: {
        color: theme.palette.v2.text._primaryDark
    },
    divider: {
        height: '1px',
        background: theme.palette.textV2.tertiary.tertiary100
    },
    tertiary100Border: {
        border: `1px solid ${theme.palette.textV2.tertiary.tertiary100}`
    },
    darkHeading: {
        color: theme.palette.textV2.primary.primary900
    },
    highlightedText: {
        color: theme.palette.textV2.primary.primary500
    },
    containerBorder: {
        border: `1px solid ${theme.palette.textV2.secondary.secondary50}`
    }
}));

const initialValues = {
    materialRequirement: [],
}

const validationSchema = yup.object().shape({
    materialRequirement: yup.array().of(
        yup.object().shape({
            applicationType: yup.string().required('Application type is required'),
            primarySourcingPurpose: yup.string().required('Primary sourcing purpose is required'),
            otherSpecificRequirement: yup.string().required('Other specific requirement is required'),
            frequency: yup.string().required('Frequency is required'),
            quantity: yup.number().typeError('Quantity must be a number').required('Quantity is required').moreThan(0, 'Quantity must be greater than zero').positive('Quantity must be a positive number'),
        })
    )
})

const MaterialListingActionTemplate: React.FC<MaterialListingActionTemplateProps> = ({ isDialogActive, mode, toggleDialogue, id }) => {
    const classes = useStyles();
    const adminService = useAdminMasterService();
    const [standards, setStandards] = useState<IStandards[]>([]);
    const [attributes, setAttributes] = useState<IMaterialAttribute[]>([]);
    const adminMasterService = useAdminMasterService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const materialRequirementService = useMaterialRequirementService();
    const [materialRequirement, setMaterialRequirement] = useState<IMaterialRequirement>();

    const formik = useFormik<IMaterialRequirementFormik>({
        initialValues,
        validationSchema,
        onSubmit: (value) => {
            const payload: IMaterialRequirement = value.materialRequirement[0];
            id && materialRequirementService.updateMaterialRequirements(payload, id)
                .then((res: any) => {
                    if (res?.status === HTTP_STATUS.OK) {
                        handleDialogueClose();
                        showSnackbar('success', "Requirement Updated Successfully ");
                    }
                }).catch((error) => {
                    showSnackbar('error', "Error Fetching Category ");
                })
        }
    })

    const loadProductCategory = (categoryId: number) => {
        adminMasterService.getAllCategories({ id: categoryId as number, level: 2, page: 0, size: 1 })
            .then((res: any) => {
                if (res?.status === HTTP_STATUS.OK) {
                    parseAttribute(res?.data?.data?.content?.[0].attributeDetails?.SPECIFICATION ?? []);
                }
            }).catch((error: any) => {
                showSnackbar('error', "Error Fetching Category ");
            })
    }

    const loadStandard = (inputValue?: string) => {
        adminService.getAllStandards({ search: inputValue ?? '', page: 0, size: 10, sort: 'createdAt,desc' })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setStandards(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Standards: ", error);
            })
    }

    const loadMaterialRequirement = () => {
        id && materialRequirementService.getMaterialRequirementById(id)
            .then((res) => {
                if (res?.status === HTTP_STATUS.OK) {
                    setMaterialRequirement({
                        ...res?.data?.data,
                        standardName: res?.data?.data?.standard,
                        gradeName: res?.data?.data?.grade,
                        category: res?.data?.data?.category
                    })
                }
            }).catch((error) => {
                console.error("Error Fetching material: ", error);
            })
    }

    useEffect(() => {
        if (mode !== MODE.ADD) {
            loadStandard();
        }
        loadMaterialRequirement();
    }, [])

    const parseAttribute = (attributes: IMaterialAttribute[]) => {
        attributes.forEach((attribute: IMaterialAttribute) => {
            if (typeof attribute.attributeOptions === 'string')
                attribute.attributeOptions = JSON.parse(attribute.attributeOptions);
        })
        setAttributes(attributes);
    }

    useEffect(() => {
        if (materialRequirement && mode !== MODE.VIEW) {
            formik.setFieldValue('materialRequirement', [{
                ...materialRequirement,
                standard: { id: materialRequirement.standardId, label: materialRequirement.standardName },
                grade: { id: materialRequirement.gradeId, label: materialRequirement.gradeName }
            }]);
            loadProductCategory(materialRequirement.categoryId as number);
        }
    }, [materialRequirement])

    const handleDialogueClose = () => {
        toggleDialogue(false);
    }

    return (
        <Dialog fullWidth className={classes.container}
            open={isDialogActive} onClose={toggleDialogue}>
            <div className={`flex gap-x-6 px-3 mb-4`}>
                <div className="grid gap-y-6 mt-5 m-auto flex-1 p-6">
                    <div className="flex justify-between">
                        <div className={`${classes.heading} text-lg font-semibold`}>{`${capitalizeFirstLetter(mode.toLowerCase())} specific product category`}</div>
                        <div className={`cursor-pointer`} onClick={handleDialogueClose}>
                            <CloseIcon className={`${classes.closeIcon}`} />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={`p-6 ${classes.containerBorder} rounded-2xl`}>
                            {SnackBarComponent}
                            <div className='flex justify-between'>
                                <div className={`text-2xl font-medium ${classes.highlightedText}`}>{materialRequirement?.category}</div>
                            </div>
                            <div className={`${classes.divider} mt-6 mb-8`}></div>
                            <div >
                                <div className='flex flex-col gap-y-8'>
                                    {(!!formik.values.materialRequirement.length || mode == MODE.VIEW) &&
                                        (mode === MODE.EDIT ?
                                            < AddMaterialFormTemplate formik={formik} index={0} attributes={attributes} standards={standards} loadStandard={(value?: string) => { }} mode={MODE.EDIT} />
                                            : <MaterialViewTemplate materialRequirement={materialRequirement as IMaterialRequirement} />)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end gap-x-3 mt-6'>
                            <Button label='Back' variant='tertiaryContained' onClick={handleDialogueClose} />
                            {mode === MODE.EDIT && <Button label='Save' variant='secondaryContained' type='submit' disabled={!!Object.keys(formik.errors).length} />}
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default MaterialListingActionTemplate

