import { createUseStyles } from "react-jss";
import { POLICY_SECTION } from "../../../../utils/constant";

interface TermsAndConditionsIntroductionProps {
    contentStyle?: string;
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text._secondaryDark,
    },
}));

const TermsAndConditionsIntroduction: React.FC<TermsAndConditionsIntroductionProps> = ({ contentStyle }) => {
    const classes = useStyles();
    return (
        <>
            <div className={`text-3xl font-semibold pb-6 mt-4 ${classes.heading}`}>{POLICY_SECTION.TERMS_INTRODUCTION}</div>
            <div className={contentStyle}>
                <p>
                    These Terms and Conditions of Use (“Terms of Use”) govern the use of the website, content, and community
                    services offered through https://avighnasteel.in/, on any mobile or internet-connected device. These Terms of Use
                    also include any guidelines, additional or supplemental terms, policies and disclaimers issued by us from time to
                    time (“Supplemental Terms”). In case of any conflict between these Terms and Supplemental Terms, the
                    Supplemental Terms shall prevail.
                </p>
                <p>
                    By accessing or using this Site, and/or availing the Services as defined hereunder, You agree to be bound by these
                    Terms along with any other agreement or policy or rule, applicable as on date, or as may be adopted by Us from
                    time to time.
                </p>
                <p>
                    You hereby represent and warrant to the Company that you are at least eighteen (18) years of age or above and
                    are capable of entering, performing, and adhering to these Terms of Use and that you agree to be bound by the
                    instant Terms of Use. While individuals under the age of 18 may utilize the Service of the Platform, they shall do so
                    only with the involvement & guidance of their parents and / or legal guardians, under such Parent / Legal
                    guardian's registered account/Profile.
                </p>
            </div>
        </>
    )
}

export default TermsAndConditionsIntroduction;