import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { createUseStyles } from "react-jss";
import shoppingBags from "../../../../src/assets/images/userPin.svg";
import bullseye from "../../../assets/images/bullseye.svg"
import userPin from "../../../assets/images/userPin.svg"
import coldRolled from "../../../assets/images/coldRolled.svg"
import galvanized from "../../../assets/images/galvanized.svg"
import tinplate from "../../../assets/images/tinplate.svg"
import tinfreeSteel from "../../../assets/images/tinfreeSteel.svg"
import blackPlate from "../../../assets/images/blackPlate.svg"
import colorCoated from "../../../assets/images/colorCoated.svg"
import logistics from "../../../assets/images/logistics.svg"
import customize from "../../../assets/images/customize.svg"
import metalpack from "../../../assets/images/metalpack.svg"
import advantage from "../../../assets/images/advantage.svg"
import energy from "../../../assets/images/energy.svg"
import automobile from "../../../assets/images/automobile.svg"
import construction from "../../../assets/images/construction.svg"
import appliances from "../../../assets/images/appliances.svg"

const icons: { [key: string]: any } = {
    info: userPin,
    vision: bullseye,
    SME: shoppingBags,
    coldRolled: coldRolled,
    galvanized: galvanized,
    tinplate: tinplate,
    tinfreeSteel: tinfreeSteel,
    blackPlate: blackPlate,
    colorCoated: colorCoated,
    logistics: logistics,
    customize: customize,
    metalpack: metalpack,
    advantage: advantage,
   energy: energy ,
   automobile: automobile,
   construction: construction,
   appliances: appliances,
};


const useStyles = createUseStyles((theme: any) => ({
    menu: {
        color: theme.palette.text.primaryDark,
    },

    secondaryDarkText: {
        color: theme.palette.text.secondaryDark
    },
    secondaryLightBorder: {
        color: theme.palette.border.secondaryLight
    },
    subMenu: {
        transition: 'background-color 0.3s',
        '&:hover': {
          background: theme.palette.background.primarylightAccent,
        },
      },
}));

export const menuItems = [
    {
        name: "About Us",
        icon: "info",
        subMenu: [
            { label: "About Us", description: "Avighna Steel, your partner in steel success", icon: userPin, link: '/pages/AboutUs.html' },
            { label: "Vision and Mission", description: "To empower business through exceptional steel, innovation, and customer partnerships", icon: bullseye, link: '/pages/visionMission.html'},
            { label: "SME Advantage", description: "Tailor-made steel solutions for your growing business", icon: shoppingBags, link: '/pages/avighnasemadvantage.html' },
        ],
    },
    {
        name: "Our Products",
        icon: "ourProducts",
        subMenu: [
            { label: "Cold Rolled", description: "Cold-rolled steel is a high-quality material used in automotive, appliance, and construction industries", icon: coldRolled, link: '/pages/coldrolledsteel.html' },
            { label: "Galvanized", description: "Corrosion-resistant steel, ideal for roofing, siding, and culverts", icon: galvanized, link: '/pages/galvanizedsteel.html' },
            { label: "Electrolytic Tinplate", description: "Used for food packaging, available in various grades and thicknesses", icon: tinplate, link: '/pages/Electrolytic.html' },
            { label: "Tinfree steel", description: "Used for can lids and ends, offering excellent formability and corrosion resistance", icon: tinplate, link: '/pages/TinFreeSteel.html' },
            { label: "Tinmill Black plate", description: "Used for general stamping applications, such as toys and appliances", icon: logistics, link: '/pages/TinMillBlackPlate.html' },
            { label: "Color Coated", description: "Available in different colors for aesthetics and corrosion resistance", icon: tinfreeSteel, link: '/pages/ColorCoatedSteel.html' },
        ],
    },
    {
        name: "Business",
        icon: "business",
        subMenu: [

            { label: "Avighna Logistics", description: "Efficient and reliable transportation services for steel products", icon: logistics, link: '/pages/avighnaLogistics.html' },
            { label: "Avighna Customized", description: "Custom steel solutions tailored to specific customer needs", icon: customize, link: '/pages/avighnaCustomize.html' },
            { label: "Avighna Metalpack", description: "Value-added services such as slitting, shearing, and packaging", icon: metalpack, link: '/pages/AvighnaMetalPack.html' },
            { label: "Avighna Advantage", description: "Prime Steel Quality At Secondary Steel Prices", icon: advantage, link: '/pages/avighnaAdvantage.html' },
        ]
    },
    {
        name: "Our Services",
        icon: "services",
        subMenu: [
            { label: "Slitting", description: "Cutting steel coils into narrower widths", icon: coldRolled, link: '/pages/slitting.html' },
            { label: "Shearing", description: "Cutting steel sheets into specific lengths", icon: galvanized, link: '/pages/shearing.html' },
        ],
    },
    {
        name: "Industries",
        icon: "gears",
        subMenu: [
            { label: "Energy", description: "Empower your future with sustainable steel solutions to meet your energy requirements", icon: energy, link: '/pages/energy.html' },
            { label: "Automobile", description: "Driving innovation in the automotive industry with resilient steel components", icon: automobile, link: '/pages/automobiles.html' },
            { label: "Construction", description: "Building success with sturdy steel in construction, from infrastructure to design", icon: construction, link: '/pages/Construction.html' },
            { label: "Appliances", description: "Enhancing modern living through durable steel in home appliances and smart technologies", icon: appliances, link: '/pages/consumerAppliances.html' },
        ],
    },
];

const HeaderAboutUs: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={` flex py-10  gap-x-10 bg-white shadow-md overflow-x-auto overflow-visible`}>
                {menuItems?.map((menuItems) => (
                    <div key={menuItems.name} className='grid gap-y-6  mt-0 m-auto'>
                        <span key={menuItems.name} className={`${classes.secondaryDarkText} text-center font-xs font-medium`}>
                            {menuItems.name}
                        </span>

                       <div className='grid gap-y-2 w-72'>
                       {menuItems.subMenu.map((subMenuItem) => (
                            <a key={subMenuItem.label}  href={subMenuItem.link} className={`cursor-pointer flex gap-x-6 py-2 px-4  hover:rounded ${classes.subMenu}`}>
                                <img src={icons[subMenuItem.icon]} alt={subMenuItem.label} className="w-8" />
                                <div className='grid gap-y-1'>
                                      <span className={`font-semibold text-base ${classes.menu}`}>{subMenuItem.label}  <KeyboardArrowRightIcon /></span>
                                      <span className='text-xs'>{subMenuItem.description} </span>
                                </div>
                            </a>
                        ))}
                       </div>
                    </div>                
                ))}
        </div >
    );
};
export default HeaderAboutUs;