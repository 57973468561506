import React, { useMemo, useState } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { createUseStyles, useTheme } from "react-jss";
import { Close as CloseIcon } from '@mui/icons-material';

type CustomVariant = "tertiaryContained";
type CustomSize = "small" | "medium" | "large";

const useStyles = createUseStyles({ 
    iconButton: {
        color: 'white', 
        width: 28,
        borderRadius: "45px",
        height: "40px",
        alignContent:'center',
        paddingLeft:'5px'
    },
    iconWithText: {
        color: 'white', 
        borderRadius: "45px",
        alignContent:'center',
    },
    root:{
        minWidth: "auto",
        padding: "0px",
        lineHeight: "1 !important",
        "&:hover": {
            backgroundColor: "none",
        },
    },
    buttonContainer: {
        position: "relative",
        display: "inline-flex" 
    },
    closeIcon: {
        position: "absolute",
        top: "-4px", 
        right: "-10px", 
        backgroundColor: ({ theme }: any) => theme.palette.textV2.failure.failure500,
        borderRadius:"17px",
        width: "20px",
        height: "18px",
        color: "#fff", 
        minWidth: "0",
        "&:hover": {
            backgroundColor: ({ theme }: any) => theme.palette.buttonV2.primaryContained.background.primaryLightAccent,
        },
    },
    closeIconLabel: {
        fontSize: "12px",
        padding: "2px 6px",
        fontWeight: "400"
    },
    crossIcon:{
        padding: "2px 6px",
    },
   
    tertiaryContained: {
        color: ({ theme }: any) => theme.palette.buttonV2.tertiaryContained.text.primaryLight,
        borderRadius: "10px",
        backgroundColor: "white",
        border:  ({ theme }: any) => `1px solid ${theme.palette.buttonV2.tertiaryContained.border.primaryLight}`,
        "&:hover": {
            backgroundColor: ({ theme }: any) => theme.palette.buttonV2.tertiaryContained.background.primaryLight,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.tertiaryContained.text.secondaryLight,
            border:  ({ theme }: any) => `1px solid ${theme.palette.buttonV2.tertiaryContained.border.secondaryLight}`,
        },
        '&:focus': {
            border: ({ theme }: any) => `2px solid ${theme.palette.buttonV2.tertiaryContained.border.primaryDark}`
        },
        '&:active': {
            color: "white",
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.tertiaryContained.background.primaryDark,
          },
    },
    label: {
       textTransform: "capitalize",
    },
    small: {
        height: "fit-content",
        fontSize: "14px",
        padding: "10px 16px",
    },
    medium: {   
        height: "fit-content",     
        fontSize: "16px",
        padding: "10px 20px",
    },
    large: {
        height: "fit-content",
        fontSize: "18px",
        padding: "12px 24px",
    },
});

interface ButtonProps extends Omit<MuiButtonProps, "variant"> {
    variant: CustomVariant;
    label?: string | null;
    isLoading?: boolean;
    iconButton?: React.ReactNode;
    size? : CustomSize;
    buttonWithImg? : boolean;
    handleCloseIcon?: () => void;
    hoverButtonLabel?: number | null;
    hoverButton? : boolean;
}

const HoverButton: React.FC<ButtonProps> = ({
    variant,
    label,
    isLoading,
    iconButton,
    size = "large",
    buttonWithImg = false,
    handleCloseIcon,
    hoverButton=false,
    hoverButtonLabel,
    ...props
}) => {
    
    const theme = useTheme<Jss.Theme>();
    const styles = useStyles({ theme });
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const handleOnClick=()=>{
        setIsHovered(false)
        handleCloseIcon && handleCloseIcon();
    }

    const buttonView = useMemo(() => {
        if (buttonWithImg) {
            return (
                <span className="flex items-center">
                    {iconButton && <span className={styles.iconWithText}>{iconButton}</span>}
                    {label && <span className="ml-2">{label}</span>}
                </span>
            );
        }
        return iconButton ? <span className={styles.iconButton}>{iconButton}</span> : label;
    }, [iconButton, label, styles, buttonWithImg]);
    return (
        <div className={styles.buttonContainer}>
            <MuiButton
                {...props}
                className={`${styles[variant]} ${styles[size]} ${props.className ? props.className : ''} ${styles.label} font-medium`}
                disabled={props.disabled || !!isLoading}
                classes={{ root: styles.root }}
            >
                {isLoading ? <CircularProgress size={20}/> : buttonView}
            </MuiButton>
            {hoverButton && (
                <MuiButton
                    className={styles.closeIcon}
                    onClick={handleOnClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {hoverButtonLabel && !isHovered ? (
                       <div className={styles.closeIconLabel}>{hoverButtonLabel}</div>
                    ) : (
                        <CloseIcon className={styles.crossIcon} />
                    )}
                </MuiButton>
            )}
        </div>
    );
};
export default HoverButton;