import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { ADD_VENDOR_INVENTORY_STATES } from '../../../../utils/types';
import { useNavigate } from 'react-router-dom';
import CustomRadioGroup from '../../../molecules/CustomRadioGroup/CustomRadioGroup';
import { AddVendorInventorySectionProp, IAddVendorInventoryForm } from './AddNewVendorInventory.template';
import { FormHelperText } from '@mui/material';

const radioGroupOption = [
    { value: "STANDARD", label: "Standard Prime" },
    { value: "NON_STANDARD", label: "Non Standard Prime" },
    { value: "PROCESSED_PRIME", label: "Commercial Scrap" },
    { value: "MELTING_SCRAP", label: "Melting Scrap" },
]

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading:{
        color: theme.palette.textV2.primary.primary950
    },
    textHeading2:{
        color: theme.palette.textV2.primary.primary900
    },
    selectBox:{
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700,
        width: '23%'
    }
}));
const SelectProductTypeTemplate: React.FC<AddVendorInventorySectionProp> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate('/dashboard/inventory-management');
    };
    const onNext = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["productType"];
        let fieldError = false;
        for (const key of requiredFields) {
			if (key in formik.errors) {
				formik.setFieldTouched(key, true);
				fieldError = true;
			}
            if (formik.values.productType == ''){
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
		}
        formik.setValues((prevValues: IAddVendorInventoryForm) => ({
			...prevValues,
		}));
        if (fieldError === true) return;
        setCurrentSectionTo(ADD_VENDOR_INVENTORY_STATES.PRODUCT);
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value == 'STANDARD'){
            formik.setFieldValue("productType",event.target.value);
        }
    };

    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Add USN Details </div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4'>
                <div className=' grid gap-1/2'>
                 <text className={`${classes.textHeading} text-base font-medium`}> Select Product Type </text>
                 <text className={`${classes.barHeading} text-xs font-normal`}> Please select the product type you want to add</text>
                </div>
                <div className={`${classes.selectBox} p-6 rounded-xl grid gap-4 border-3`}>
                 <text className={`${classes.textHeading2} text-base font-medium`}>Select Product Type</text>
                    <CustomRadioGroup
                    options={radioGroupOption}
                    value={formik.values.productType}
                    onChange={handleChange} 
                    className={`${classes.radioColor} `}/>
                    {formik.touched?.productType && formik.errors?.productType &&
                            <FormHelperText error className='top-half '>{formik.errors?.productType}</FormHelperText>
                        }
                </div>
            </div>

            <div className='flex items-end gap-6 justify-self-end'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                    size='medium'
                />
                <Button
                    variant="primaryContained"
                    label="Next"
                    onClick={onNext}
                    size='medium'
                />
            </div>
        </div>
    )
}

export default SelectProductTypeTemplate