import React, { useState } from 'react';
import Button from '../../atoms/Button/Button';
import { createUseStyles } from 'react-jss';
import banner from "../../../assets/images/rfqBanner.png"
import RequestForQuotationModalTemplate from './RequestForQuotationModal.template';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        margin: "0px 72px",
        border: `1px solid ${theme.palette.borderV2.secondary.secondary100}`,
    },
    secondaryContainer: {

    },
    mainHeading: {
        background: "linear-gradient(122deg, #FD6102 34.29%, #7B4847 64.11%, #1E3679 86.46%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        lineHeight: "56.64px"
    },
    subHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    mobileBtn: {},
    mainContainer: {},
    primaryContainer: {},
    "@media (max-width: 480px)": {
        container : {
            margin: '0px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            "& img": {
                width: '100%',
            },
        },
        secondaryContainer: {
            margin: '0px',
            width: '100%',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
        },
        mainHeading: {
            fontSize: "24px",
            lineHeight: '30px',
        },
        subHeading: {
            fontSize: '16px',
            lineHeight: '22px',
            width:'100%',
        },
        mobileBtn: {
            fontSize: '14px',
        },
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
        },
        primaryContainer: {
            padding: '0px 16px',
        },
    },
}));

export const RequestForQuotationTemplate = () => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleRfqForm = () => {
        setDialogOpen(true);
    }

    return (
        <div className={`${classes.primaryContainer}`}>
        <div className={`rounded-2xl flex ${classes.container}`}>
            <img className='w-1/2' src={banner} alt="rfqBanner" />
            <div className={`${classes.secondaryContainer} w-1/2 mx-24 my-auto grid gap-y-6`}>
                <div className={`${classes.mainContainer} grid gap-y-4`}>
                    <h1 className={`${classes.mainHeading} mx-auto text-5xl font-semibold text-center w-4/5`}>Request for Quotation (RFQ)</h1>
                    <div className={`${classes.subHeading} mx-auto text-lg text-center leading-6 w-3/5`}>Get the best quotations for your customised materials.</div>
                </div>
                <div className='mx-auto'>
                    <Button variant='primaryContained' size="large" label='Post your Requirements' className={classes.mobileBtn} onClick={handleRfqForm}/>
                </div>
            </div>
            <RequestForQuotationModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}/>
        </div>
        </div>
    );
};
