import React, { useMemo, useState } from 'react'
import { FormikProps } from 'formik';
import Button from '../../../atoms/Button/Button';
import { createUseStyles } from 'react-jss';
import { KEY_PERSON_DETAIL_STATES } from '../../../../utils/types';
import GetNumberOfKeyPersonDetails from './GetNumberOfKeyPersonDetails.template';
import { IkeyDetailForm } from '../../../pages/DashBoard/KeyPersonDetails.page';
import AddNewKeyPersonDetilsTemplate from './AddNewKeyPersonDetils.template';
import { HeaderKeyPersonDetailsTemplate } from './HeaderKeyPersonDetails.template';

export interface IKeyDetailProps {
    formik: FormikProps<IkeyDetailForm>;
}

export interface PocSectionProps {
    setCurrentSectionTo: (section: KEY_PERSON_DETAIL_STATES) => void;
    formik: FormikProps<IkeyDetailForm>;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderRadius: "8px",
        border: `1px solid ${theme.palette.v3.border.primaryLight}`,
    },
    text: {
        color: theme.palette.v4.text._secondaryDark
    },
    label: {
        color: theme.palette.buttonV2.secondaryText.text.secondaryDark
    }
}));

const KeyPersonDetailsTemplate: React.FC<IKeyDetailProps> = ({ formik }) => {

    const classes = useStyles();

    const [currentSection, setCurrentSection] = useState<KEY_PERSON_DETAIL_STATES>(KEY_PERSON_DETAIL_STATES.VIEW_KEY_PERSON_DETAILS);

    const keyDetailProps: PocSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik
    };

    const secondaryInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case KEY_PERSON_DETAIL_STATES.ADD_KEY_PERSON_NO:
                return <GetNumberOfKeyPersonDetails {...keyDetailProps} />;
            case KEY_PERSON_DETAIL_STATES.ADD_KEY_PERSON_DETAILS:
                return <AddNewKeyPersonDetilsTemplate {...keyDetailProps} />;
            default:
                return formik.values?.pocs?.length > 0 ? (
                    <HeaderKeyPersonDetailsTemplate {...keyDetailProps} />
                ) : null;
        }
    }, [currentSection, formik]);

    return (
        <div>
            <div className='grid gap-y-6'>
                <div className='flex justify-between items-center'>
                    <span className={`${classes.label} font-semibold text-lg`}>Key Person Details</span>
                    {formik.values.newPocs.length === 0 && (
                        <div className='flex gap-x-4'>
                            {currentSection !== KEY_PERSON_DETAIL_STATES.ADD_KEY_PERSON_NO && (
                                <Button
                                    variant="tertiaryContained"
                                    label="Add New Key Person Details"
                                    size='medium'
                                    onClick={() => { setCurrentSection(KEY_PERSON_DETAIL_STATES.ADD_KEY_PERSON_NO) }}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {secondaryInventorySectionView}
        </div>
    )
}
export default KeyPersonDetailsTemplate;