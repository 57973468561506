import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";

const AvighnaLogisticsTemplate: React.FC = () => {
    const classes = useStyles();
    return (
        <>

            <section className={classes.produts_banner}>
                <div className={`${classes.products_bannner_wrapper} ${classes.logistics_banner}`}>
                    <div className={classes.products_banner_content}>
                        <h3>Avighna Logistics</h3>
                        <h1>Your Partner in India's Thriving Steel Industry</h1>
                        {/* <!-- <div className="banner_btn">
                    <a href="#">Learn More</a>
                </div> --> */}
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className={`${classes.products_about_us} ${classes.card_sp}`}>
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>About Avighna logistics</h2>
                            <p className={classes.gal_card_des} >India's steel industry is thriving, leading to a high demand for specialized
                                material handling systems and warehousing solutions. Navi Mumbai, due to its strategic location,
                                is experiencing exponential growth. Taloja is now Asia's largest hub for steel logistics and
                                marketplace activities. Avighna, a key player in the sector, is constructing a 50,000 sq. ft.
                                Steel Service Centre in Taloja, which will expand to 100,000 sq. ft. This facility will be a hub
                                for material handling, value-added services, warehousing, and efficient distribution. Avighna is
                                committed to providing business opportunities and delivering excellence in steel logistics and
                                support services to meet the evolving needs of the industry.</p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/AvighnaLogistics/aboutAvighnaLogistics.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- cards section starts here  --> */}

            <section className={classes.primary_cards_wrapper}>
                <div className={`${classes.primary_card_wrap} ${classes.container}`}>
                    <div className={classes.primary_card_main}>
                        <div className={classes.primary_card_preview}>
                            <img src="../assets/images/AvighnaLogistics/wareHousing.webp" alt="" />
                        </div>
                        <div className={classes.primary_card_content}>
                            <img src="../assets/images/AvighnaLogistics/warehouse.webp" alt="" />
                            <h3>Warehousing</h3>
                            <p>Avighna offers specialized steel warehousing with waterproofing and robust flooring. The company
                                has a 10,000-square-foot facility for flat-rolled steel variants and is expanding to 50,000
                                square feet. Plans are in place to scale up to 200,000 square feet to meet the increasing demand
                                for steel storage solutions.</p>
                        </div>
                    </div>

                    <div className={classes.primary_card_main}>
                        <div className={classes.primary_card_content}>
                            <img src="../assets/images/AvighnaLogistics/module.webp" alt="" />
                            <h3>Material Handling Systems</h3>
                            <p>Steel materials require specialized material handling systems, including EOT cranes and
                                forklifts, as they weigh several metric tons. A comprehensive approach to steel logistics,
                                including material handling, warehousing, value-added services, and transportation solutions,
                                ensures secure storage and efficient distribution to meet industry needs.</p>
                        </div>
                        <div className={classes.primary_card_preview}>
                            <img src="../assets/images/AvighnaLogistics/materialHandlingSystem.webp" alt="" />
                        </div>
                    </div>

                    <div className={classes.primary_card_main}>
                        <div className={classes.primary_card_preview}>
                            <img src="../assets/images/AvighnaLogistics/transportation.webp" alt="" />
                        </div>
                        <div className={classes.primary_card_content}>
                            <img src="../assets/images/AvighnaLogistics/train.webp" alt="" />
                            <h3>Transportation</h3>
                            <p>Avighna Steel offers customized logistics services for metal products, including steel. Our
                                expedited delivery options and commitment to quality and safety help streamline your supply
                                chain. We provide unique freight solutions, such as custom stacking and carriage by covered or
                                dry van. Trust us to simplify your supply chain and provide top-notch logistics solutions for
                                your business.</p>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default AvighnaLogisticsTemplate;