import React, { useEffect, useState } from 'react';
import { CATEGORY_TYPE, CLASS_STATES, HTTP_STATUS, IPagination } from '../../../utils/types';
import { CATALOGUES_TYPE, PRODUCT_SECTION, initialPaginationValues, statusOptions } from '../../../utils/constant';
import ProductCataloguesListingTableTemplate from './ProductCataloguesListingTable.template';
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import FilterChipV2, { IMultiSelectOption } from '../../molecules/FilterChipV2/FilterChipV2';
import Button from '../../atoms/Button/Button';
import { IProductsParams, usePostProductService } from '../../../services/usePostProductService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useAdminMasterService } from '../../../services/useAdminMasterService';
import { ICategory } from '../Home/ProductRequirementForm.template';
import { InputAdornment } from "@mui/material";
import Search from "@mui/icons-material/Search";
import { IProductSearchParams, useGenericSearchService } from '../../../services/useGenericSearchService';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    textField: {
        padding: "14px 12px !important"
    },
    searchIcon: {
        color: theme.palette.icon.primaryLight
    },
}));

interface ISkuCodes {
id:  number;
skuCode: string;
productCode: string;
productId: number;
}
interface ICataloguesTableViewTemplateProps {
    catalogueType: string;
    stats: number;
}

export interface ICataloguesList {
    id: number;
    skuCode: string;
    productName: string;
    thickness: number;
    width: number;
    temper: number;
    status: string;
    length: number;
}

export interface ICataloguesListingFilter {
    status: string;
    search: string;
    classType: string;
    category: string;
    productIdString: string;
}

const initialCataloguesListingFiltersValues: ICataloguesListingFilter = {
    status: "",
    search: "",
    category: "",
    classType: CLASS_STATES.STANDARD,
    productIdString: ""
}


const ProductCataloguesTableViewTemplate: React.FC<ICataloguesTableViewTemplateProps> = ({ catalogueType, stats }) => {
    const [cataloguesFilters, setcataloguesFiltersTo] = useState<ICataloguesListingFilter>(initialCataloguesListingFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [cataloguesList, setcataloguesListTo] = useState<ICataloguesList[]>([]);
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
    const [skuCodesData, setSkuCodesData] = useState<ISkuCodes[] | null>([]);
    const [status, setStatus] = useState<IMultiSelectOption[]>([]);
    const [category, setCategory] = useState<IMultiSelectOption[]>([]);
    const [skuCode, setSkuCode] = useState<IMultiSelectOption[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [skuSearchTerm, setSkuSearchTerm] = useState<string>('');
    const productService = usePostProductService();
    const adminService = useAdminMasterService();
    const genericSearchService = useGenericSearchService();
    const { user } = useAuthenticatedUser();
    const classes = useStyles();

    const loadProductCategory = () => {
        adminService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: searchTerm ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setProductCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadSkuCodes = () => {
        let params: IProductSearchParams = {
            page: 0,
            size: 10,
            sort: 'createdAt,desc',
            sectionType: PRODUCT_SECTION.BRAND_UPC,
            skuCode: skuSearchTerm?? ""
        }
        genericSearchService.searchProduct(params, {})
            .then((response) => {
                if (response.data.data && response.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = response?.data?.data;
                    setSkuCodesData(response.data.data.content);
                }
                else {
                    setSkuCodesData([]);
                }
            })
            .catch((error) => {
                console.error("Brands fetching error - ", error);
            });
    };
    

    const transformedProductCategoryData: IMultiSelectOption[] = productCategoryData
        ? productCategoryData.map(category => ({
            label: category.name,
            value: category.id
        }))
        : [];

    const transformedSkuCodeData: IMultiSelectOption[] = skuCodesData
        ? skuCodesData.map(skuCode => ({
            label: skuCode.productCode,
            value: skuCode.productId
        }))
        : [];

    const refreshCatalogues = async () => {
        if (user?.businessId) {
            try {
                const params: IProductsParams = {
                    page: pagination.page,
                    size: pagination.size,
                    status: cataloguesFilters.status,
                    category: cataloguesFilters.category,
                    search: cataloguesFilters.search,
                    productIdString: cataloguesFilters.productIdString,
                    classType: catalogueType ?? initialCataloguesListingFiltersValues.classType,
                    sort: 'createdAt,desc',

                };
                const productResponse = await productService.getAllProducts(params, user?.businessId);
                if (productResponse?.data?.data?.content?.length) {
                    const { content, totalElements } = productResponse.data.data;
                    setcataloguesListTo(content);
                    setPaginationTo((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: totalElements,
                    }));
                } else {
                    throw new Error("Products fetch failed");
                }
            } catch (error) {
                setcataloguesListTo([]);
            }
        }
    };
    const handleFiltersChange = (name: string, value: any) => {
        setPaginationTo({ ...pagination });
        setcataloguesFiltersTo({ ...cataloguesFilters, [name]: value ?? "" });
    };

    const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
        const valueString = status.length > 0 ? status[0].value : '';
        handleFiltersChange("status", valueString);
    };

    const handleCategorySaveClick = (category: IMultiSelectOption[]) => {
        const valueString = category.map(item => item.value).join(',');
        handleFiltersChange("category", valueString);
    };

    const handleCodeSaveClick = (skuCodes: IMultiSelectOption[]) => {
        const valueString = skuCodes.map(item => item.value).join(',');
        handleFiltersChange("productIdString", valueString);
    };

    const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setStatus(selected);
        clearWithCrossIcon && handleStatusSaveClick(selected);
    };

    const handleSelectionChangeCategory = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setCategory(selected);
        clearWithCrossIcon && handleCategorySaveClick(selected);
    };

    const handleSelectionChangeCode = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSkuCode(selected);
        clearWithCrossIcon && handleCodeSaveClick(selected);
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPageNumber: number) => {
        setPaginationTo(prevPagination => ({
            ...prevPagination,
            page: newPageNumber
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationTo((prevPagination: IPagination) => ({
            ...prevPagination,
            page: 0,
            size: parseInt(event.target.value, 10)
        }));
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            case 'status':
                setStatus([]);
                handleFiltersChange("status", '');
                break;
            case 'category':
                setCategory([]);
                handleFiltersChange("category", '');
                break;
            case 'productIdString':
                setSkuCode([]);
                handleFiltersChange("productIdString", '');
                break;
        }
    };

    useEffect(() => {
        refreshCatalogues();
    }, [catalogueType, pagination.size, pagination.page, cataloguesFilters]);

    useEffect(() => {
        loadProductCategory();
    }, [searchTerm]);

    useEffect(() => {
        loadSkuCodes();
    }, [skuSearchTerm]);

    return (
        <div className='mt-8'>
            <div className={`${classes.heading} text-lg font-medium mb-4`}>
                {CATALOGUES_TYPE[catalogueType as keyof typeof CATALOGUES_TYPE]} ({stats})
            </div>
            <div className='flex justify-between items-center gap-2 mb-4'>
                <FilterChipV2
                    options={statusOptions}
                    label=" "
                    value={status}
                    onchange={handleSelectionChangeStatus}
                    placeholder="Status"
                    ClearButtonComponent={Button}
                    buttonLabel={"Clear all"}
                    buttonOnClick={() => handleClearClick('status')}
                    SaveButton={Button}
                    saveButtonLabel='Apply'
                    saveButtonOnClick={() => handleStatusSaveClick(status)}
                    isSingleSelect={true}
                    minWidth='180px'
                />
                <FilterChipV2
                    options={transformedSkuCodeData}
                    label=" "
                    value={skuCode}
                    onchange={handleSelectionChangeCode}
                    placeholder="SKU Code"
                    ClearButtonComponent={Button}
                    buttonLabel={"Clear all"}
                    buttonOnClick={() => handleClearClick('productIdString')}
                    SaveButton={Button}
                    saveButtonLabel='Apply'
                    saveButtonOnClick={() => handleCodeSaveClick(skuCode)}
                    textFieldPlaceholder='Search...'
                    searchTerm={skuSearchTerm}
                    setSearchTerm={setSkuSearchTerm}
                    minWidth='200px'
                />
                 <FilterChipV2
                    options={transformedProductCategoryData}
                    label=" "
                    value={category}
                    onchange={handleSelectionChangeCategory}
                    placeholder="Product Category"
                    ClearButtonComponent={Button}
                    buttonLabel={"Clear all"}
                    buttonOnClick={() => handleClearClick('category')}
                    SaveButton={Button}
                    saveButtonLabel='Apply'
                    saveButtonOnClick={() => handleCategorySaveClick(category)}
                    textFieldPlaceholder='Search...'
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    minWidth='200px'
                    isSingleSelect={true}
                />
                <div className='flex justify-end ml-auto'>
                    <TextFieldV2
                        label=''
                        variant="outlined"
                        placeholder="Search by name..."
                        name='search'
                        className={classes.textField}
                        onChange={(event) => {
                            handleFiltersChange("search", event.target.value)
                        }}
                        InputProps={{
                            className: "px-2 h-11 text-base ",
                            startAdornment: (
                                <InputAdornment position="start" style={{ marginLeft: '16px' }}>
                                    <Search className={classes.searchIcon} />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            </div>
            <div>
                <ProductCataloguesListingTableTemplate cataloguesList={cataloguesList} handlePaginationChange={handlePaginationChange} handleRowsPerPageChange={handleRowsPerPageChange} pagination={pagination} vendorId={user?.businessId ?? 0} />
            </div>
        </div>
    );
};

export default ProductCataloguesTableViewTemplate;
