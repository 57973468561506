import React, { useCallback, useEffect, useState } from 'react';
import { timeToLocale } from '../../../utils/helper';


interface CountDownTimerProps {
    timeInSeconds: number;
    onComplete: () => void;
}

const CountDownTimer: React.FC<CountDownTimerProps> = ({ timeInSeconds, onComplete }) => {

    const [ remainingTime, setRemainingTime ] = useState<number>(timeInSeconds);
    const [ intervalId, setIntervalId ] = useState<NodeJS.Timeout | null>(null);

    const completeTimer = useCallback(() => {
        clearInterval(intervalId ?? undefined);
        onComplete();
    },[onComplete, intervalId]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingTime((prevRemainingTime) => {
              const newRemainingTime = prevRemainingTime - 1;
              if (newRemainingTime <= 0) 
                completeTimer()
              return newRemainingTime;
            });
          }, 1000);
          setIntervalId(intervalId)
          return () => clearInterval(intervalId);
    }, [ timeInSeconds ]);

    return (
        <span>{timeToLocale(remainingTime, 'year')}</span>
    );
}

export default CountDownTimer;
