
interface CustomPalette {
    background: Record<string, string>;
    text: Record<string, string>;
    action: Record<string, string>;
    success: Record<string, string>;
    button: Record<string, string>;
    general: Record<string, string>;
    border: Record<string, string>;
    pagination: Record<string, any>;
    boxShadow: Record<string, string>;
    icon: Record<string, string>;
    table: Record<string, string>;
    status: Record<string, string>;
    miscellaneous: Record<string, string>;
    v2: Record<string, any>;
    v3: Record<string, any>;
    v4: Record<string, any>;
    buttonV2: Record<string, any>;
    textV2: Record<string, any>;
    backgroundV2: Record<string, any>;
    borderV2: Record<string, any>
}

interface CustomTheme extends Jss.Theme {
    name: string;
    palette: CustomPalette;
}

export const defaultTheme: CustomTheme = {
    name: "default",
    palette: {
        background: {
            secondaryDarkAccent: "#F6F6F6",
            secondaryLightAccent: "#C8D6FC",
            secondaryDark: "#EB984B",
            primarylightAccent: "#EEF5FF",
            primaryLight: "#EDEDED",
            primaryLightAccent: "#F9FAFE",
            secondaryLight: "#d3d3d3",
            primaryActive: "#EEF5FF",
            primaryDarkAccent: "#1d36790d",
            _secondaryLight: "#1c3f9f0d",
            _secondaryLiightAccent: "#d6e1ff",
            _primaryLight: "#e2e9fc33",
            _primaryLghtAccent: "#D9D9D9",
            secondaryGreyLight:"#333333"

        },
        text: {
            primaryLight: '#5B5B5B',
            primaryLightAccent: "#070D1E",
            primaryDark: "#1D3679",
            active: "#028e18f2",
            primaryDarkAccent: "#1C3F9F",
            secondaryLight: "#929292",
            secondaryLightAccent: "#989898",
            secondaryDark: '#4D4D4D',
            secondaryDarkAccent: '#2D2D2D',
            _primaryDark: "#1D3679",
            _secondaryDark: "#070D1E",
            _primaryDarkAccent: "#121d3db3",
            _secondaryLight:"#FD6202",
            secondaryDarkLight: "#666666",
            secondaryGreyLight:"#333333"
            
        },
        action: {
            active: '#001E3C',
            danger: "#EF0000",
            success: "#4CAF50",
        },
        success: {
            primaryDark: '#4CAF50',
            secondaryDark: '#437B6B',
        },
        button: {
            primaryDark: "#1D3679",
            primaryLight: "#495985",  
            secondaryLight: "#E2EAFF",
            secondaryDark: "#1E377B"
        },
        general: {
            seperator: "#5D5D5D",
        },
        border: {
            primaryLight: "#EDEDED",
            primaryDark: "#1D3679",
            secondaryLight: "#DDD",
            secondaryDark: "#1C3F9F",
            primaryLightAccent: "#E4E4E4",
            _primaryDark: "#1D3679",
            _primaryLight: "#1d367926",
            _secondaryDark: "#4D4D4D",
            primaryDarkAccent: "#FD6202",
            secondaryDarkAccent: "#CFCFCF",
            _secondaryLight: "#CBCBCB",
            secondaryLightAccent: "#1D367985"
        },
        pagination: {
            text: {
                primaryLight: "1C3F9F",
                secondaryLight: "#1E3679"
            },
            border: {
                primaryLight: "#4200FF"
            }
        },
        boxShadow: {
            primaryLight: "#0000000d",
            primaryDark: "#00000040",
            secondaryDark: "#1d36791f",
            secondaryLight: "#00000059"
        },
        icon: {
            primaryLight: "#A1A1A1"
        },
        table:{
            rowOdd:'#1C3F9F08',
            rowEven:'#F7931E08'
        },
        status: {
            primaryLight: "#0AC282",
            secondaryLight: "#FBB040",
            primaryDark: "#F04438"

        }, miscellaneous: {
            onBoardingBackgroundCard: "#F5F8FF",
            emailConfirmationOrange: "#FD6202"
        },
        v2: {
            background: {
                primaryLight: '#F3F3F3',
                secondaryLight: '#F2F7FF',
                primaryLightAccent: '#FFF',
                secondaryLightAccent: "#E8EEFF",
                primaryDark: "#849DE1",
                secondaryDark: "#EBE723",
                _primaryLight: "#FFC700",
            },
            text: {
                primaryLight: "#2951BF",
                secondaryLight: "#8E8E8E",
                primaryLightAccent: "#121D3D7F",
                primaryDarkAccent: "#404040",
                secondaryDarkAccent: "#121d3d80",
                secondaryLightAccent: "#070d1ee6",
                _primaryLight: "#CE6415",
                _secondaryLight: "#121D3DB3",
                _primaryDark: "#1E3679",
                primaryDark: "#142552",
                _primaryLightAccent: "#EF5F09",
                _primaryDarkAccent: "#1E377B",
            },
            boxShadow: {
                primaryLight: "#00000014",
                primaryDark: "#00000026",
                secondaryLight: "#0000004d",
                secondaryDark: "#00000017"
            },
            border: {
                primaryDark: "#3A57A5",
                primaryLight: "#D6DEF5",
                _primaryDark: "#ADBEEB",
            }
        },
        v3: {
            text: {
                primaryDark: "#333",
                _error: '#d32f2f',
                secondaryDark: "#0A1229",
                primaryLight: "#666",
                secondaryLight: "#999999",
                primaryLightAccent: "#24A147",
                primaryDarkAccent: "#263978",
                secondaryLightAccent: "#5B7CD7",
                secondaryDarkAccent: "#B31920",
                _primaryLight: "#FFF",
                _primaryDark: "#190A00",
                _secondaryDark: "#DA1E27",
                _secondaryDarkAccent: "#FD6102",
                _secondaryLight: "#1A1A1A",
                _secondaryLightAccent : "#331400",
                _primaryDarkAccent: "#2849A4",
            },
            background: {
                primaryLight: "#EBEFFA",
                secondaryDark: "#DA1E27",
                _avighnaOrange: "#FD8235",
                primaryDark: "#849DE1",
                secondaryLight: "#D6DEF5",
                primaryLightAccent: "#1E3679",
                secondaryLightAccent: "#FCE9EA",
                secondaryDarkAccent: "#B31920",
                _primaryLight: "#0a12290d"
            },
            gradient: {
                requestQuotationOrange: "#FEF1EA",
                requestQuotationBlue: "#EEF2FE",
            },
            border: {
                orange: "#FEC09A",
                searchBarBorder: "#333",
                primaryLight: "#EBEFFA",
                primaryLightAccent: "#000",
            }
        },
        v4: {
            text: {
                primaryLight: "#1E3679",
                primaryLightAccent: "#FFFFFF",
                primaryDark: "#999999",
                primaryDarkAccent: "#24A147",
                secondaryLight: "#0A1229",
                secondaryLightAccent: "#999",
                secondaryDark: "#1D3679",
                secondaryDarkAccent: "#404040",
                _primaryLight: "#5B7CD7", 
                _primaryLightAccent: "#FD8235",
                _primaryDark: "#FD6202",
                _primaryDarkAccent: "#333",
                _secondaryLight: "#0a122999",
                _secondaryDark: "#6F6F6F",
                _secondaryLightAccent: "1A1A1A",
                _secondaryDarkaccent: "#08325E",
                _secondaryDarkAccent: "#FFF",
            },
            background: {
                primaryLight: "#ADBEEB",
                primaryLightAccent: "#FEA167",
                primaryDark: "#EAFAEF",
                primaryDarkAccent: "#D6DEF5",
                secondaryLight: "#999999",
                secondaryLightAccent: "#FFF",
                secondaryDark: "#FFEFE6",
                secondaryDarkAccent: "#FFE0CC",
                _secondaryLight: "#e6e6e659",
                _primaryLight: "#FD8235",
                _primaryLightAccent: "#F2F2F2",
                _primaryDark: "#FD6202",
                _primaryDarkAccent: "#E64C53",
                _secondaryLightAccent: "#F4F4F4",
                _secondaryDark: "#CCC",
                _secondaryDarkAccent: "#eeeeee4d",
                __primaryLight: "#1E3679",
                __secondaryDark:"#FDCE35",
            },
            border: {
                primaryLight: "#ADBEEB",
                primaryLightAccent: "#D6DEF5",
                primaryDark: "#FEA167",
                primaryDarkAccent: "#3152E6",
                secondaryLight: "#BABEC8",
                secondaryLightAccent: "#FD6202",
                secondaryDark: "#666666",
                secondaryDarkAccent: "#FFE0CC",
                _primaryLight: "#333",
                _primaryLightAccent: "#142552",
                _primaryDark: "#EEE",
                _primaryDarkAccent: "#CCC",
                _secondaryLight: "#D2D7E4",
                _secondaryLightAccent: "#FD8235",
                _secondaryDark: "#DDE4F7",
                _secondaryDarkAccent: "#E6E6E6",
            },
            button: {
                primaryLight: "#FD6202",
                primaryDark: "#CA4F02",
                secondaryDark: "#747474",
                _disabled: "#0a12290d"
            }
        },
        buttonV2:{
            primaryContained:{
                text:{
                    primaryLight: "#FFF",
                    secondaryLight: "#0a122999",
                },
                border:{
                    primaryLight: "#983B01"
                },
                background:{
                    primaryLight: "#FD6202",
                    primaryLightAccent: "#CA4F02",
                    primaryDark: "#983B01",
                    secondaryLight: "#0a12290d",
                }
            },
            secondaryContained:{
                text:{
                    primaryLight: "#983B01",
                    primaryLightAccent: "#652701",
                    primaryDark: "#331400",
                    secondaryLight: "#0a122999",
                },
                border:{
                    primaryLight: "#983B01"
                },
                background:{
                    primaryLight: "#FFE0CC",
                    primaryLightAccent: "#FEC09A",
                    primaryDark: "#FEA167",
                    secondaryLight: "#0a12290d",
                }
            },
            tertiaryContained:{
                text:{
                    primaryLight: "#333",
                    secondaryLight: "#0a122966",                   
                },
                border:{
                    primaryLight: "#333",
                    primaryDark: "#1A1A1A",
                    secondaryLight: "#0a122966",
                },
                background:{
                    primaryLight: "#EEE",
                    primaryDark: "#666"
                }
            },
            primaryText:{
                text:{
                    primaryLight: "#FD8235",
                    primaryDark:"#FD6202",
                    secondaryLight: "#0a122966",
                    secondaryDark: "#333"
                },
                border:{
                    primaryLight: "#333"
                },
            },
            secondaryText:{
                text:{
                    primaryLight: "#0a122933",
                    primaryDark:"#999",
                    secondaryLight: "#1E377B",
                    secondaryDark: "#142552" 
                },
                border:{
                    primaryLight: "#999"
                },
            },
        },
        textV2:{
            primary:{
                primary50: "#FFEFE6",
                primary100: "#FFE0CC",
                primary200: "#FEC09A",
                primary300: "#FEA167",
                primary400: "#FD8235",
                primary500: "#FD6202",
                primary600: "#983B01",
                primary700: "#983B01",
                primary800: "#652701",
                primary900: "#331400",
                primary950: "#190A00",
                primary1000: "#989898",
            },
            secondary:{
                secondary50: "#EBEFFA",
                secondary100: "#D6DEF5",
                secondary200: "#ADBEEB",
                secondary300: "#849DE1",
                secondary400: "#5B7CD7",
                secondary500: "#1E3679",
                secondary600: "#2849A4",
                secondary700: "#1E377B",
                secondary800: "#142552",
                secondary900: "#0A1229",
            },
            tertiary:{
                tertiary50: "#EEEEEE",
                tertiary100: "#E6E6E6",
                tertiary200: "#CCCCCC",
                tertiary300: "#B3B3B3",
                tertiary400: "#999999",
                tertiary500: "#FFFFFF",
                tertiary600: "#666666",
                tertiary700: "#4D4D4D",
                tertiary800: "#333333",
                tertiary900: "#1A1A1A",
                tertiary950: "#0D0D0D",
            },
            success:{
                success50: "#EAFAEF",
                success100: "#D5F6DE",
                success200: "#ACECBE",
                success300: "#82E39D",
                success400: "#58DA7D",
                success500: "#24A147",
                success600: "#25A74A",
                success700: "#1C7D37",
                success800: "#135325",
                success900: "#092A12",
                success950: "#051509",
            },
            failure:{
                failure50: "#FCE9EA",
                failure100: "#F9D2D4",
                failure200: "#F3A5A9",
                failure300: "#EC797E",
                failure400: "#E64C53",
                failure500: "#DA1E27",
                failure600: "#B31920",
                failure700: "#861318",
                failure800: "#5A0C10",
                failure900: "#2D0608",
                failure950: "#160304",
            },
            warning:{
                warning50: "#FFF9E6",
                warning100: "#FEF3CD",
                warning200: "#FEE79A",
                warning300: "#FDDA68",
                warning400: "#FDCE35",
                warning500: "#FDD03A",
                warning600: "#CA9B02",
                warning700: "#977402",
                warning800: "#654E01",
                warning900: "#322701",
                warning950: "#191300",
            },
            alphaDark: {
                alphaDark5: "#0a12290d", //rgba(10, 18, 41, 0.05)
                alphaDark10: "#0a12291a", //rgba(10, 18, 41, 0.1)
                alphaDark20: "#0a122933", //rgba(10, 18, 41, 0.2)
                alphaDark30: "#0a12294d", //rgba(10, 18, 41, 0.3)
                alphaDark40: "#0a122966", //rgba(10, 18, 41, 0.4)
                alphaDark50: "#0a122980", //rgba(10, 18, 41, 0.5)
                alphaDark60: "#0a122999", //rgba(10, 18, 41, 0.6)
                alphaDark70: "#0a1229b3", //rgba(10, 18, 41, 0.7)
                alphaDark80: "#0a1229cc", //rgba(10, 18, 41, 0.8)
                alphaDark90: "#0a1229e6" //rgba(10, 18, 41, 0.9)
            },
            alphaLight: {
                alphaLight60: "#ffffff99"
            }
        },
        backgroundV2: {
            primary:{
                primary50: "#FFEFE6",
                primary100: "#FFE0CC",
                primary200: "#FEC09A",
                primary300: "#FEA167",
                primary400: "#FD8235",
                primary500: "#FD6202",
                primary600: "#983B01",
                primary700: "#983B01",
                primary800: "#652701",
                primary900: "#331400",
                primary950: "#190A00",
            },
            secondary:{
                secondary50: "#EBEFFA",
                secondary100: "#D6DEF5",
                secondary200: "#ADBEEB",
                secondary300: "#849DE1",
                secondary400: "#5B7CD7",
                secondary500: "#1E3679",
                secondary600: "#2849A4",
                secondary700: "#1E377B",
                secondary800: "#142552",
                secondary900: "#0A1229",
                secondary950: "#CA4F02",
            },
            tertiary:{
                tertiary50: "#EEEEEE",
                tertiary100: "#E6E6E6",
                tertiary200: "#CCCCCC",
                tertiary300: "#B3B3B3",
                tertiary400: "#999999",
                tertiary500: "#FFFFFF",
                tertiary600: "#666666",
                tertiary700: "#4D4D4D",
                tertiary800: "#333333",
                tertiary900: "#1A1A1A",
                tertiary950: "#0D0D0D",
            },
            success:{
                success50: "#EAFAEF",
                success100: "#D5F6DE",
                success200: "#ACECBE",
                success300: "#82E39D",
                success400: "#58DA7D",
                success500: "#24A147",
                success600: "#25A74A",
                success700: "#1C7D37",
                success800: "#135325",
                success900: "#092A12",
                success950: "#051509",
            },
            failure:{
                failure50: "#FCE9EA",
                failure100: "#F9D2D4",
                failure200: "#F3A5A9",
                failure300: "#EC797E",
                failure400: "#E64C53",
                failure500: "#DA1E27",
                failure600: "#B31920",
                failure700: "#861318",
                failure800: "#5A0C10",
                failure900: "#2D0608",
                failure950: "#160304",
            },
            warning:{
                warning50: "#FFF9E6",
                warning100: "#FEF3CD",
                warning200: "#FEE79A",
                warning300: "#FDDA68",
                warning400: "#FDCE35",
                warning500: "#FDD03A",
                warning600: "#CA9B02",
                warning700: "#977402",
                warning800: "#654E01",
                warning900: "#322701",
                warning950: "#191300",
            },
            alphaDark: {
                alphaDark5: "#0a12290d", //rgba(10, 18, 41, 0.05)
                alphaDark10: "#0a12291a", //rgba(10, 18, 41, 0.1)
                alphaDark20: "#0a122933", //rgba(10, 18, 41, 0.2)
                alphaDark30: "#0a12294d", //rgba(10, 18, 41, 0.3)
                alphaDark40: "#0a122966", //rgba(10, 18, 41, 0.4)
                alphaDark50: "#0a122980", //rgba(10, 18, 41, 0.5)
                alphaDark60: "#0a122999", //rgba(10, 18, 41, 0.6)
                alphaDark70: "#0a1229b3", //rgba(10, 18, 41, 0.7)
                alphaDark80: "#0a1229cc", //rgba(10, 18, 41, 0.8)
                alphaDark90: "#0a1229e6" //rgba(10, 18, 41, 0.9)
            },
            alphaLight: {
                alphaLight60: "#ffffff99"
            }
        }, 
        borderV2: {
            primary:{
                primary50: "#FFEFE6",
                primary100: "#FFE0CC",
                primary200: "#FEC09A",
                primary300: "#FEA167",
                primary400: "#FD8235",
                primary500: "#FD6202",
                primary600: "#983B01",
                primary700: "#983B01",
                primary800: "#652701",
                primary900: "#331400",
                primary950: "#190A00",
            },
            secondary:{
                secondary50: "#EBEFFA",
                secondary100: "#D6DEF5",
                secondary200: "#ADBEEB",
                secondary300: "#849DE1",
                secondary400: "#5B7CD7",
                secondary500: "#1E3679",
                secondary600: "#2849A4",
                secondary700: "#1E377B",
                secondary800: "#142552",
                secondary900: "#0A1229",
            },
            tertiary:{
                tertiary50: "#EEEEEE",
                tertiary100: "#E6E6E6",
                tertiary200: "#CCCCCC",
                tertiary300: "#B3B3B3",
                tertiary400: "#999999",
                tertiary500: "#FFFFFF",
                tertiary600: "#666666",
                tertiary700: "#4D4D4D",
                tertiary800: "#333333",
                tertiary900: "#1A1A1A",
                tertiary950: "#0D0D0D",
            }, 
        }
    }
};
