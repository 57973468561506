import { createUseStyles } from "react-jss";
import { toInr } from "../../../utils/helper";
import Button from "../../atoms/Button/Button";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { UNIT } from "../../../utils/constant";
import AddToCartIcon from '../../../assets/images/addToCart.svg';
import Whatsapp from "../../atoms/Whatsapp/Whatsapp";
import SubjectToTermsConditionTemplate from "./SubjectToTermsCondition.template";
import QuantityDiscountTemplate from "./QuantityDiscount.template";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useMemo, useState } from "react";
import emailIcon from "../../../assets/images/emailIcon.svg";
import MailerModalTemplate from "./MailerModal.template";
import { ISecondaryCatalogue } from "../../pages/Catalogue/SecondaryCatalogueDetails.page";
import emailIconWhiteOutlined from "../../../assets/images/emailIconWhiteOutlined.svg"
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { PriceDisplayProps } from "./PriceDetails.template";
export interface SecondaryPriceDetailsProps {
	minimumPrice: number;
	maximumPrice: number;
	quantity: string;
	warehousename: string;
	gst: number;
	uom: string;
	moq: number;
	handleAddToCart: () => void;
	handleQuantityChange: (newQuantity: string) => void;
	showAlteredQuantity?: boolean;
	secondaryCatalogue?: ISecondaryCatalogue | null;
	paymentType?: string | null;
	productCombination: boolean | null;
}

const useStyles = createUseStyles((theme: any) => ({
	priceDetailsHeading: {
		background: theme.palette.v4.background.secondaryLightAccent,
		boxShadow: `0px 0px 16px 0px ${theme.palette.v2.boxShadow.secondaryDark}`,
		padding: "20px",
		borderRadius: "16px"
	},
	priceText: {
		color: theme.palette.textV2.secondary.secondary800,
	},
	priceValue: {
		color: theme.palette.textV2.tertiary.tertiary700,
		fontSize: "24px"
	},
	specialPrice: {
		color: theme.palette.v3.text.primaryLight,
	},
	quantityInformation: {
		color: theme.palette.v2.text.primaryDarkAccent
	},
	netWeightText: {
		color: theme.palette.textV2.tertiary.tertiary900,
	},
	lotQuantity: {
		color: theme.palette.v3.text._secondaryLight,
	},
	incrementDecrement: {
		background: theme.palette.v4.background.secondaryLightAccent,
		color: theme.palette.v4.text._primaryDark,
		boxShadow: `0px 4px 8px 3px ${theme.palette.v2.boxShadow.primaryDark}, 0px 1px 3px 0px ${theme.palette.v2.boxShadow.secondaryLight}`,
	},
	basePrice: {
		textDecoration: "line-through",
		textDecorationColor: theme.palette.v4.background._primaryDarkAccent,
		color: "inherit",
	},
	price: {
		color: theme.palette.v4.text.primaryDarkAccent,
	},
	count: {
		background: 'white',
		border: `1px solid ${theme.palette.border.secondaryDarkAccent}`
	},
	cartIcon: {
		position: "absolute",
		zIndex: 1,
		padding: "12px 0px 0px 60px",
	},
	cartButton: {
		background: theme.palette.v4.background._primaryDark,
		borderRadius: "10px",
		"&.MuiButton-root": {
			color: theme.palette.v4.text._secondaryDarkAccent,
		},
		"&:hover": {
			background: theme.palette.v4.background._primaryDark,
		},
		"&:focus": {
			border: "none",
		},
	},
	priceDetails: {
		color: theme.palette.v2.text.primaryLight
	},
	minimumQuantity: {
		color: theme.palette.v4.text.secondaryLightAccent,
	},
	uom: {
		color: theme.palette.v4.text.secondaryLightAccent,
	},
	gst: {
		color: theme.palette.textV2.tertiary.tertiary300
	},
	finalPrice: {
		color: theme.palette.text.secondaryDark
	},
	selectedUpc: {
		color: theme.palette.text.primaryLight,
		background: theme.palette.background.primaryLight,
	},
	gstText: {
		color: theme.palette.v2.text.secondaryDarkAccent,
	},
	finalPriceText: {
		color: theme.palette.v4.text._primaryDark,
	},
	email: {
		borderRadius: "10px",
		border: `1px solid ${theme.palette.v3.border.primaryLightAccent}`,
		width: "54px",
		height: "48px",
	},
	emailIcon: {
		borderRadius: "10px",
		width: "54px",
		height: "48px",
		background: theme.palette.v4.background._primaryDark,
	},
	arrow: {
		display: "none"
	},
	webContainer: {
		display: "grid"
	},
	termAndCondition: {
		display: "flex"
	},
	mobileContainer: {
		display: "none"
	},
	webButton: {
		display: "flex"
	},
	mobileButton: {
		display: "none"
	},
	"@media (max-width: 480px)": {
		arrow: {
			display: "flex"
		},
		termAndCondition: {
			display: "none"
		},
		webContainer: {
			display: "none"
		},
		mobileContainer: {
			display: "grid"
		},
		webButton: {
			display: "none"
		},
		mobileButton: {
			display: "flex"
		},
		email: {
			width: "49px",
			height: "43px",
		},
		emailIcon: {
			width: "49px",
			height: "43px",
		},
		gst: {
			fontSize: "12px",
		},
		priceValue: {
			fontSize: "18px"
		},
		uom: {
			fontSize: "12px",
		},
		priceDetailsHeading: {
			borderRadius: "0px",
			boxShadow: `none`,
			padding: "16px",
			border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
		},
	},
}));

const SecondaryPriceDetailsTemplate: React.FC<SecondaryPriceDetailsProps> = ({ handleAddToCart, paymentType, minimumPrice, maximumPrice, warehousename, quantity, gst, uom, moq, handleQuantityChange, showAlteredQuantity, secondaryCatalogue, productCombination}) => {

	const classes = useStyles();
	const { user } = useAuthenticatedUser();

	const alterQuantity = (alterQuantityUnit: number) => () => {
		handleQuantityChange(String(parseFloat(Math.max(parseFloat(quantity) + alterQuantityUnit, moq).toFixed(2))));
	};

	const increaseQuantity = () => {
		if (warehousename) {
			alterQuantity(1)();
		}
	};

	const decreaseQuantity = () => {
		if (warehousename) {
			alterQuantity(-1)();
		}
	};

	const [dialogOpen, setDialogOpen] = useState<boolean>(false);

	const handleRfqForm = () => {
		setDialogOpen(true);
	}

	const [showAll, setShowAll] = useState(false);
	const handleClick = () => {
		setShowAll(!showAll);
	};

	// TODO: Will modify after getting data from backend
	const PriceDisplay: React.FC<PriceDisplayProps> = ({ price, uom, gstText, classes }) => (
		<div className="flex text-2xl font-semibold tracking-tighter mb-2 whitespace-nowrap">
		  <div className="flex">
			<span className={`${classes.priceValue} font-semibold`}>
			  {price}
			</span>
		  </div>
		  <span className={`${classes.uom} font-bold mt-0 mx-0.5 text-sm`}>*</span>
			<span className={`${classes.uom} font-normal my-auto mr-2 text-sm`}>{`/${uom || "MT"}`}</span>
			<div className={`${classes.gst} font-medium my-auto text-sm`}>
				<span className="mr-1">Excluding </span>
				<span>GST@18%</span>
			</div>
		</div>
	  );
	  
	  const productPriceDisplay = useMemo(() => {
		const gstText = "Excluding ";
		if (productCombination === false) {
		  return (
			<PriceDisplay
			  price="₹ - "
			  uom={uom}
			  gstText={gstText}
			  classes={classes}
			/>
		  );
		}
	  
		const outOfStock = !warehousename || parseFloat(quantity) <= 0;
	  
		if (outOfStock) {
		  return (
			<PriceDisplay
			  price="₹ - "
			  uom={uom}
			  gstText={gstText}
			  classes={classes}
			/>
		  );
		}
	
		const priceRange = minimumPrice === maximumPrice
		  ? toInr(minimumPrice || 0)
		  : `${toInr(minimumPrice || 0)} - ${toInr(maximumPrice || 0)}`;
	  
		return (
		  <PriceDisplay
			price={priceRange}
			uom={uom}
			gstText={gstText}
			classes={classes}
		  />
		);
	  }, [warehousename, minimumPrice, maximumPrice, toInr, uom, classes]);
	  
	  
	  const FinalPriceText = useMemo(() => {
		let message = "For Final Price/Quotation, Click Here.";
		let rfqFlag = false;
		if (productCombination === false) {
			message = "Product not available for this selection";
		} 
		if (!warehousename || parseFloat(quantity) <= 0) {
			message = "Out of Stock";
		} 
		
		return (
			<>
			  <div className={`${classes.finalPriceText} text-base font-semibold mb-1`}>
				{message}
			   </div>
			   <div className={`${classes.finalPriceText} text-base font-semibold mb-1`}>
					{rfqFlag ? `Please refer to 'Request for Quotation'`: null}
			   </div>
			</>
		);
	  }, [warehousename, quantity, moq, productCombination, classes.finalPriceText]);
	  
	
	return (
		<div className={`grid  ${classes.priceDetailsHeading} gap-y-3`}>
			{/* TODO: Will uncomment after getting data from backend */}
			<div className={`${classes.webContainer}  gap-y-4 pb-3 border-b`}>
				<div className="flex flex-col border-b pb-4">
					<span className={`${classes.priceText} text-xl font-medium mb-3`}>Avighna Price</span>
					{productPriceDisplay}
					{/* <div className="flex text-2xl font-semibold tracking-tighter mb-2 whitespace-nowrap">
						<span className={classes.priceValue}>{minimumPrice === maximumPrice ? `${toInr(minimumPrice || 0)}` : `${toInr(minimumPrice || 0)}  -  ${toInr(maximumPrice || 0)}`}</span>
						<span className={`${classes.uom} font-bold mt-0 mx-0.5 text-sm`}>*</span>
						<span className={`${classes.uom} font-normal my-auto mr-2 text-sm`}>{`/${uom || "MT"}`}</span>
						<div className={`${classes.gst} font-medium my-auto text-sm`}>
							<span className="mr-1">Excluding </span>
							<span>GST@18%</span>
						</div>
					</div> */}
					{/* <div className="flex mb-2">
						<span className={`${classes.uom} text-base font-normal`}>Base Price:</span>
						<span className={`${classes.basePrice} text-base font-semibold ml-1`}>Rs75,500</span>
						<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
						<span className={`${classes.price} text-base font-semibold`}>₹3,000</span>
						<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
						<span className={`${classes.price} text-base font-medium`}>Off</span>
					</div>
					<div className="flex">
						<span className={`${classes.uom} text-base font-normal`}>Special Deal Discount:</span>
						<span className={`${classes.price} text-base font-semibold ml-1`}>Rs300</span>
						<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
						<span className={`${classes.price} text-base font-medium`}>Off</span>
					</div> */}
				</div>
				{/* <QuantityDiscountTemplate /> */}
				{showAlteredQuantity && (
					<>
						<div className="text-xl font-medium">Enter Quantity (MT)</div>
						<div className="grid gap-y-2">
							<div className="flex gap-x-4">
								<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: (warehousename && (parseFloat(quantity) > moq)) ? 'pointer' : 'not-allowed' }}><RemoveIcon onClick={decreaseQuantity} /></div>
								<input type="text" className={`w-24 h-9 rounded-lg flex text-center justify-center items-center gap-2 ${classes.count}`} value={quantity} onChange={event => handleQuantityChange(event.target.value)} />
								<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: warehousename ? 'pointer' : 'not-allowed' }}><AddIcon onClick={increaseQuantity} /></div>
							</div>
							<div className={`font-inter text-xs font-semibold mt-1 ${classes.minimumQuantity}`}>Minimum Order Quantity : {moq}{UNIT.UOM} </div>
						</div>
					</>
				)}
				<div>
					{!showAlteredQuantity && (
						<div className="flex flex-col">
							<span className={`text-xl font-medium mb-1 ${classes.netWeightText}`}>Net Weight</span>
							<span className={`flex gap-2 text-2xl font-semibold mb-1 ${classes.lotQuantity}`}>{quantity} {UNIT.UOM}</span>
						</div>
					)}
				</div>
			</div>


			{showAll &&
				<div className={`${classes.mobileContainer}  gap-y-3 pb-3 border-b`}>
					<div className="flex flex-col border-b pb-3">
						<span className={`${classes.priceText} text-xl font-medium mb-3`}>Avighna Price</span>
						{productPriceDisplay}
						{/* <div className="flex text-2xl font-semibold tracking-tighter whitespace-nowrap">
							<span className={classes.priceValue}>{minimumPrice === maximumPrice ? `${toInr(minimumPrice || 0)}` : `${toInr(minimumPrice || 0)}  -  ${toInr(maximumPrice || 0)}`}</span>
							<span className={`${classes.uom} font-bold mt-0 mx-0.5`}>*</span>
							<span className={`${classes.uom} font-normal my-auto mr-2`}>{`/${uom || "MT"}`}</span>
							<div className={`${classes.gst} font-medium my-auto`}>
								<span className="mr-1">Excluding</span>
								<span>GST@18%</span>
							</div>
						</div> */}
						{/* <div className="flex mb-2">
						<span className={`${classes.uom} text-base font-normal`}>Base Price:</span>
						<span className={`${classes.basePrice} text-base font-semibold ml-1`}>Rs75,500</span>
						<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
						<span className={`${classes.price} text-base font-semibold`}>₹3,000</span>
						<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
						<span className={`${classes.price} text-base font-medium`}>Off</span>
					</div>
					<div className="flex">
						<span className={`${classes.uom} text-base font-normal`}>Special Deal Discount:</span>
						<span className={`${classes.price} text-base font-semibold ml-1`}>Rs300</span>
						<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
						<span className={`${classes.price} text-base font-medium`}>Off</span>
					</div> */}
					</div>
					{/* <QuantityDiscountTemplate /> */}
					{showAlteredQuantity && (
						<>
							<div className="text-xl font-medium">Enter Quantity (MT)</div>
							<div className="grid gap-y-2">
								<div className="flex gap-x-4">
									<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: (warehousename && (parseFloat(quantity) > moq)) ? 'pointer' : 'not-allowed' }}><RemoveIcon onClick={decreaseQuantity} /></div>
									<input type="text" className={`w-24 h-9 rounded-lg flex text-center justify-center items-center gap-2 ${classes.count}`} value={quantity} onChange={event => handleQuantityChange(event.target.value)} />
									<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: warehousename ? 'pointer' : 'not-allowed' }}><AddIcon onClick={increaseQuantity} /></div>
								</div>
								<div className={`font-inter text-xs font-semibold mt-1 ${classes.minimumQuantity}`}>Minimum Order Quantity : {moq}{UNIT.UOM} </div>
							</div>
						</>
					)}
					<div>
						{!showAlteredQuantity && (
							<div className="flex flex-col">
								<span className={`text-base font-medium mb-1 ${classes.netWeightText}`}>Net Weight</span>
								<span className={`flex gap-2 text-lg font-semibold ${classes.lotQuantity}`}>{quantity} {UNIT.UOM}</span>
							</div>
						)}
					</div>
				</div>
			}
			<div>
				<div className="flex justify-between">
					<div className={`text-sm font-medium mt-1 ${classes.specialPrice}`}>Your Final Price (After Discounts)</div>
					<div className={`${classes.arrow} gap-x-2 cursor-pointer`} onClick={handleClick} >
						{showAll ? <ArrowDropUp /> : <ArrowDropDown />}
					</div>
				</div>
				{productPriceDisplay}
				{/* <div className="flex text-2xl font-semibold tracking-tighter mb-2 whitespace-nowrap">
					<span className={classes.priceValue}>{minimumPrice === maximumPrice ? `${toInr(minimumPrice || 0)}` : `${toInr(minimumPrice || 0)}  -  ${toInr(maximumPrice || 0)}`}</span>
					<span className={`${classes.uom} font-bold mt-0 mx-0.5 text-sm`}>*</span>
					<span className={`${classes.uom} font-normal my-auto mr-2 text-sm`}>{`/${uom || "MT"}`}</span>
					<div className={`${classes.gst} font-medium my-auto text-sm`}>
						<span className="mr-1">Excluding</span>
						<span>GST@18%</span>
					</div>
				</div> */}
				{FinalPriceText}
				{/* <div className={`${classes.finalPriceText} text-sm font-medium mb-1`}>For Final Price/Quotation, Click Here.</div> */}
				<div className={`${classes.finalPriceText} text-sm font-medium mb-2`}>{(!paymentType) ? "Please select payment method to add to cart." : null}</div>
				<div className="flex justify-between">
					{/* <Button variant="text" disabled={!warehousename || ((parseFloat(quantity) < moq) && showAlteredQuantity)} onClick={handleAddToCart} fullWidth label="Add to Cart" className={`${classes.cartButton} !text-base !font-medium !h-12`} startIcon={<img src={AddToCartIcon} alt="icon" />} /> */}
					<div className={`${classes.webButton} flex-1`}>
				
						<Button variant="primaryContained" disabled={!warehousename || parseFloat(quantity) <= 0 || ((parseFloat(quantity) < moq) && showAlteredQuantity) || !paymentType} onClick={handleAddToCart} fullWidth label="Add to Cart" className={classes.cartButton} startIcon={<img src={AddToCartIcon} alt="icon" />} />

					</div>

					<div className={`${classes.mobileButton} flex-1`}>
						<Button variant="primaryContained" size="small" disabled={!warehousename || parseFloat(quantity) <= 0 || ((parseFloat(quantity) < moq) && showAlteredQuantity) || !paymentType} onClick={handleAddToCart} fullWidth label="Add to Cart" className={classes.cartButton} startIcon={<img src={AddToCartIcon} alt="icon" />} />
					</div>
					<Whatsapp />
					{user?.email && (
						<div className={`${dialogOpen ? classes.emailIcon : classes.email} cursor-pointer grid justify-center content-center`} onClick={handleRfqForm}>
							<img className="" src={dialogOpen ? emailIconWhiteOutlined : emailIcon} alt="" />
						</div>
					)}
					{/* {user?.email && (
						<div className={`${dialogOpen ? classes.emailIcon : classes.email} cursor-pointer`} onClick={handleRfqForm}>
							<img className="" style={{margin:"15px"}} src={dialogOpen ? emailIconWhiteOutlined: emailIcon} alt="" />
						</div>
					)} */}
					<MailerModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} warehouse={warehousename} secondaryCatalogue={secondaryCatalogue} />
				</div>
			</div>
			{/* {(!warehousename) ? (<div className={`p-2.5 mt-2 ${classes.selectedUpc} text-xs font-normal rounded`}>
				Product out of stock, no warehouse found
			</div>) : (!quantity || (parseFloat(quantity) < moq)) && showAlteredQuantity &&
			<div className={`p-2.5 mt-2 ${classes.selectedUpc} text-xs font-normal rounded`}>
				Please order minimum order quantity before adding to the cart
			</div>
			} */}
			<div className={`${classes.termAndCondition}`}>
				<SubjectToTermsConditionTemplate />
			</div>
		</div>
	);
}

export default SecondaryPriceDetailsTemplate;
