import React, { useEffect, useMemo, useState } from 'react'
import OnBoardingInformationTemplate from '../../templates/OnBoarding/OnBoardingInformation.template'
import { ONBOARDING_STATES } from '../../../utils/types';
import EntityTypeTemplate from '../../templates/OnBoarding/EntityType.template';
import ProductTemplate from '../../templates/OnBoarding/Product.template';
import RequirementsTemplate from '../../templates/OnBoarding/ServiceRequirements.template';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useFormik } from 'formik';
import * as Yup from "yup";
import GstInformationTemplate from '../../templates/OnBoarding/GstInformation.template';
import BusinessDetailsTemplate from '../../templates/OnBoarding/BusinessDetails.template';
import { useNavigate } from 'react-router-dom';
import { useGstinService } from '../../../services/useGstService';

export interface IOnBoardingPayload {
  gstin: string,
  name: string,
  legalStatus: string,
  dateOfEstablishment: string,
  websiteUrl: string,
  lastAnnualTurnover: string,
  totalEmployees: string,
  entityType: string,
  industry: string[],
  categories: string[],
  serviceRequirements: string[],
  userId?: string,
  line1: string,
  postalCode: string,
  city: string,
  state: string,
  country: string
}

export interface GstResponse {
  constitutionOfBusiness: string,
  legalNameOfBusiness: string,
  tradeNameOfBusiness: string,
  registrationDate: string,
  gstStatus: string,
  city: string[],
  state: string[],
  country: string[],
  pincode: string,
  addressLine: string,
  gstin: string
}

const OnBoardingPage = () => {
  const { user } = useAuthenticatedUser();
  const [formState, setFormState] = useState(ONBOARDING_STATES.GST_INFORMATION);
  const navigate = useNavigate();
  const gstService = useGstinService();
  const [gstDetails, setGstDetails] = useState<GstResponse | null>(null);
  const initialValues: IOnBoardingPayload = {
    gstin: '',
    name: '',
    legalStatus: "",
    dateOfEstablishment: "",
    websiteUrl: "",
    lastAnnualTurnover: "",
    totalEmployees: "",
    entityType: "",
    industry: [],
    categories: [],
    serviceRequirements: [],
    userId: user?.id,
    line1: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
  }

  const validationSchema = Yup.object({
    gstin: Yup.string().required('GSTIN is required'),
    name: Yup.string().required('Name is required'),
    legalStatus: Yup.string().required('Legal Type is required'),
    dateOfEstablishment: Yup.date().required('Date of Establishment is required'),
    websiteUrl: Yup.string().url('Invalid URL'),
    lastAnnualTurnover: Yup.string().required('Annual Turnover is required'),
    totalEmployees: Yup.number().min(1, 'Enter minimum employee count').required('Employees count is required'),
    entityType: Yup.string().required('Entity Type is required'),
    industry: Yup.array().of(Yup.string().required('Industry is required')),
    categories: Yup.array().of(Yup.string().required('Categories are required')),
    serviceRequirements: Yup.array().of(Yup.string().required('Service Requirements are required')),
    line1: Yup.string().required('Address is required'),
    postalCode: Yup.string().required('Postal Code is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required')
  });

  const formik = useFormik<IOnBoardingPayload>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => { }
  });

  const fetchGstDetails = async () => {
    gstService.searchGstin({ gstin: user?.gstin, userId: user?.id })
      .then(res => {
        setGstDetails(res.data.data)
      }).catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (user === null || user.businessId)
      navigate('/')
    if (gstDetails == null)
      fetchGstDetails();
  }, [])

  useEffect(() => {
    const updatedValues = {
      gstin: gstDetails?.gstin ?? "",
      name: gstDetails?.tradeNameOfBusiness?.length ?  gstDetails?.tradeNameOfBusiness : gstDetails?.legalNameOfBusiness ?? "",
      legalStatus: gstDetails?.constitutionOfBusiness ?? "",
      dateOfEstablishment: gstDetails?.registrationDate?.split("/")?.reverse()?.join("-") ?? "",
      line1: gstDetails?.addressLine ?? "",
      postalCode: gstDetails?.pincode ?? "",
      city: gstDetails?.city[0] ?? "",
      state: gstDetails?.state[0][0] ?? "",
      country: gstDetails?.country[2] ?? ""
    }

    formik.setValues({
    ...formik.values, ...updatedValues
    }, true)
  }, [gstDetails])

  const formStateView = useMemo(() => {
    switch (formState) {
      case 1: return <GstInformationTemplate formik={formik} setFormState={setFormState} gstDetails={gstDetails} />;
      case 2: return <BusinessDetailsTemplate formik={formik} setFormState={setFormState} gstDetails={gstDetails} />;
      case 3: return <EntityTypeTemplate formik={formik} setFormState={setFormState} />;
      case 4: return <ProductTemplate formik={formik} setFormState={setFormState} />;
      case 5: return <RequirementsTemplate formik={formik} setFormState={setFormState} />;
      default: return <GstInformationTemplate formik={formik} setFormState={setFormState} gstDetails={gstDetails} />
    }
  }, [formState, formik])

  return (
    <div className='h-screen w-full'>
      <OnBoardingInformationTemplate setFormState={setFormState} formState={formState}>
        {formStateView}
      </OnBoardingInformationTemplate>
    </div>
  )
}

export default OnBoardingPage