import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Breadcrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import CatalogueGridItemSkeleton from '../../templates/Catalogue/CatalogueGridItemSkeleton.template';
import GridView from '../../organism/GridView/GridView.organism';
import { Grid } from "@mui/material";
import CatalogueGridItem from '../../templates/Catalogue/CatalogueGridItem.template';
import NoCatalogueTemplate from '../../templates/Catalogue/NoCatalogue.template';
import { useCatalogueService } from '../../../services/useCatalogueService';
import { Catalogue } from '../../templates/Catalogue/CatalogueList.template';
import { CLASS_STATES } from "../../../utils/types";
import { WishlistContext } from '../../../contexts/WishlistContext';

interface Payload {
    catalogueIds?: number[];
    secondaryCatalogueIds?: number[];
    classes?: string[];
}

const gridTemplate = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 3,
    xl: 3,
};

const useStyles = createUseStyles((theme: any) => ({
    container: {
        rowGap: "32px",
        padding: "0px 72px"
    },
    heading: {
        color: theme.palette.textV2.secondary.secondary800
    },
    items: {
        color: theme.palette.textV2.tertiary.tertiary800
    },
    catalogueCard: {
        height: "402px",
    },
    "@media (max-width: 476px)": {
        container: {
            rowGap: "24px",
            padding: "0px 16px"
        },
        filterMobileContainer: {
            display: "flex",
            width: "100%",
        },
    },
}));

const Wishlist: React.FC = () => {
    const classes = useStyles()
    const catalogueService = useCatalogueService();
    const [catalogues, setCataloguesTo] = useState<Catalogue[]>([]);
    const [secondaryCatalogues, setSecondaryCataloguesTo] = useState<Catalogue[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const wishlistContextData = useContext(WishlistContext);

    const fetchCatalogues = async () => {
        try {
            if (wishlistContextData?.favourites?.catalogueIds.length === 0) {
                setCataloguesTo([]);
                return;
            }
            setLoading(true);
            const payload: Payload = {
                catalogueIds: wishlistContextData?.favourites?.catalogueIds,
                classes: [CLASS_STATES.STANDARD, CLASS_STATES.NONSTANDARD]
            };
            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 100,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse?.data?.data;
                setCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setCataloguesTo([]);
            setLoading(false);
        }
    };

    const fetchSecondaryCatalogues = async () => {
        try {
            if (wishlistContextData?.favourites?.secondaryCatalogueIds.length === 0) {
                setSecondaryCataloguesTo([]);
                return;
            }
            setLoading(true);
            const payload: Payload = {
                secondaryCatalogueIds: wishlistContextData?.favourites?.secondaryCatalogueIds,
                classes: [CLASS_STATES.SECONDARY]
            };
            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 100,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse?.data?.data;
                setSecondaryCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setSecondaryCataloguesTo([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (wishlistContextData?.favourites?.catalogueIds)
            fetchCatalogues();
    }, [wishlistContextData?.favourites?.catalogueIds]);

    useEffect(() => {
        if (wishlistContextData?.favourites?.secondaryCatalogueIds)
            fetchSecondaryCatalogues();
    }, [wishlistContextData?.favourites?.secondaryCatalogueIds]);

    const [combinedCatalogues, setCombinedCatalogues] = useState<Catalogue[]>([]);

    useEffect(() => {
        if (catalogues.length >= 0 || secondaryCatalogues.length >= 0) {
            setCombinedCatalogues([...catalogues, ...secondaryCatalogues]);
            setLoading(false)
        }
    }, [catalogues, secondaryCatalogues]);

    return (
        <div className={`grid ${classes.container} m-auto max-container`} >
            <div className="">
                <Breadcrumbs />
            </div>
            <div className='grid gap-y-2'>
                <div className={`${classes.heading} text-2xl font-medium`}>Wishlist</div>
                <div className={`${classes.items} text-sm font-medium`}>{combinedCatalogues?.length} Items</div>
            </div>
            <div className="grid gap-y-6">
                {loading ? (
                    <Grid container spacing={2}>
                        <GridView spacing={2} items={[null, null, null]} itemComponent={(item) => <CatalogueGridItemSkeleton />} gridTemplate={gridTemplate} />
                    </Grid>

                ) : combinedCatalogues.length > 0 ? (
                    <>
                        <GridView spacing={2} items={combinedCatalogues} itemComponent={(item) => <CatalogueGridItem data={item} label='wishlist' styles={classes.catalogueCard} />} gridTemplate={gridTemplate} />
                    </>
                ) : (
                    <div className='w-full mx-auto'>
                        <NoCatalogueTemplate />
                    </div>
                )}
            </div>
        </div>
    );
}
export default Wishlist;