import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

interface ISaveFileRequestBody {
    resourceType: string;
    resourceId: number;
    path: string;
    mimeType: string;
}

export const FILE_URLS = {
    GET_ALL_FILE: "/files/:resourceType/:resourceId",
    CREATE_FILES: "/files",
    GET_UPLOAD_URL: "/file/upload/generate_presigned_url",
    GET_URL:"/file/:path",
    
}

export const useFileService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getAllFiles = (resourceType: string, resourceId: number) => {
        const url = replaceUrlParams(FILE_URLS.GET_ALL_FILE, { resourceType, resourceId });
        return request(API_METHOD.GET, url, authenticatedUser);
    };

    const createFile = (data: ISaveFileRequestBody) => {
        return request(API_METHOD.POST, FILE_URLS.CREATE_FILES, authenticatedUser, data);
    };

    const generatePresignedUrl = (fileName: string, resourceType: string, resourceId: number) => {
        const url = FILE_URLS.GET_UPLOAD_URL;
        const params = { fileName, resourceType, resourceId };
        return request(API_METHOD.GET, url, authenticatedUser, null, { params });
    };

    const uploadImageUsingPreSignedUrls = (image: File | ImageData, url : string) => {
        return request(API_METHOD.PUT, url, null ,image);
    }

    const uploadPdfUsingPreSignedUrls = (image: File | ImageData, url: string) => {
        const headers = {
            'Content-Type': 'application/pdf',
            'Content-Disposition': 'inline'
        };
        return request(API_METHOD.PUT, url, null, image, null, undefined, process.env.REACT_APP_API_V1_URL ,headers);
    }

    const getImagePreSignedUrl = (path: string) => {
        const url = replaceUrlParams(FILE_URLS.GET_URL, { path });
        return request(API_METHOD.GET, url, authenticatedUser );
    }

    return {
        getAllFiles,
        createFile,
        generatePresignedUrl,
        uploadImageUsingPreSignedUrls,
        getImagePreSignedUrl,
        uploadPdfUsingPreSignedUrls
    };
};
