import * as React from 'react';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import business from "../../../../src/assets/images/business.png";
import construction from "../../../../src/assets/images/construction.svg";
import energy from "../../../../src/assets/images/energy.svg";
import tinfreeSteel from "../../../../src/assets/images/tinfreeSteel.svg";
import metalpack from "../../../../src/assets/images/metalpack.svg";
import automobile from "../../../../src/assets/images/automobile.svg";
import appliances from "../../../../src/assets/images/appliances.svg";
import logistics from "../../../../src/assets/images/logistics.svg";
import customize from "../../../../src/assets/images/customize.svg";
import galvanized from "../../../../src/assets/images/galvanized.svg";
import blackPlate from "../../../../src/assets/images/blackPlate.svg";
import tinplate from "../../../../src/assets/images/tinplate.svg";
import advantage from "../../../../src/assets/images/advantage.svg";
import coldRolled from "../../../../src/assets/images/coldRolled.svg";
import bullseye from "../../../../src/assets/images/bullseye.svg";
import userPin from "../../../../src/assets/images/userPin.svg";
import shoppingBags from "../../../../src/assets/images/userPin.svg";
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router';
import { HOME_PAGE_ROUTES } from '../../../utils/constant';


const menuSections = [
    {
        "title": "About Us",
        "items": [
            {
                "title": "About Us",
                "icon": userPin,
                "link": HOME_PAGE_ROUTES.ABOUT_US,
                "description": "Avighna Steel, your partner in steel success"
            },
            {
                "title": "Vision/Mission",
                "icon": bullseye,
                "link": HOME_PAGE_ROUTES.VISSION_MISSION,
                "description": "To empower business through exceptional steel, innovation..."
            },
            {
                "title": "SME Advantage",
                "icon": shoppingBags,
                "link": HOME_PAGE_ROUTES.AVIGHNA_SMEADVANTAGE,
                "description": "Tailor-made steel solutions for your growing business"
            }
        ]
    },
    {
        "title": "Our Products",
        "items": [
            {
                "title": "Cold Rolled",
                "icon": coldRolled,
                "link": HOME_PAGE_ROUTES.COLDROLLED_STEEL,
                "description": "Cold-rolled steel is a high-quality material used..."
            },
            {
                "title": "Galva Coated",
                "icon": galvanized,
                "link": HOME_PAGE_ROUTES.GALVANIZED_STEEL,
                "description": "Corrosion-resistant steel, ideal for roofing, siding, and culverts"
            },
            {
                "title": "Tin Coated",
                "icon": tinplate,
                "link": HOME_PAGE_ROUTES.TINFREE_STEEL,
                "description": "Used for can lids and ends, offering excellent formability..."
            },
            {
                "title": "Colour Coated",
                "icon": tinfreeSteel,
                "link": HOME_PAGE_ROUTES.COLOR_COATED,
                "description": "Available in different colors for aesthetics..."
            }
        ]
    },
    {
        "title": "Business",
        "items": [
            {
                "title": "Avighna Logistics",
                "icon": logistics,
                "link": HOME_PAGE_ROUTES.AVIGHNA_LOGISTICS,
                "description": "Efficient and reliable transportation services..."
            },
            {
                "title": "Avighna Metalpack",
                "icon": metalpack,
                "link": HOME_PAGE_ROUTES.AVIGHNA_METALPACK,
                "description": "Value-added services such as slitting, shearing..."
            },
            {
                "title": "Avighna Customize",
                "icon": customize,
                "link": HOME_PAGE_ROUTES.AVIGHNA_CUSTOMIZE,
                "description": "Custom steel solutions tailored to specific..."
            },
            {
                "title": "Avighna Advantage",
                "icon": advantage,
                "link": HOME_PAGE_ROUTES.AVIGHNA_ADVANTAGE,
                "description": "Prime Steel Quality At Secondary Steel Prices"
            }
        ]
    },
    {
        "title": "Industries",
        "items": [
            {
                "title": "Energy",
                "icon": energy,
                "link": HOME_PAGE_ROUTES.ENERGY,
                "description": "Empower your future with sustainable steel..."
            },
            {
                "title": "Automobiles",
                "icon": automobile,
                "link": HOME_PAGE_ROUTES.AUTOMOBILES,
                "description": "Driving innovation in the automotive industry..."
            },
            {
                "title": "Construction",
                "icon": construction,
                "link": HOME_PAGE_ROUTES.CONSTRUCTION,
                "description": "Building success with sturdy steel in construction..."
            },
            {
                "title": "Appliances",
                "icon": appliances,
                "link": HOME_PAGE_ROUTES.CONSUMER_APPLIANCES,
                "description": "Enhancing modern living through durable steel..."
            }
        ]
    },
    {
        "title": "Our Services",
        "items": [
            {
                "title": "Slitting",
                "icon": coldRolled,
                "link": HOME_PAGE_ROUTES.SLITTING,
                "description": "Cutting steel coils into narrower widths"
            },
            {
                "title": "Shearing",
                "icon": galvanized,
                "link": HOME_PAGE_ROUTES.SHEARING,
                "description": "Cutting steel sheets into specific lengths"
            }
        ]
    }
]

const useStyles = createUseStyles((theme: any) => ({
    menuContent: {
        color: theme.palette.text.secondaryLight,
    },
    menuTop: {
        borderTop: `1px solid ${theme.palette.text.secondaryLight}`,
    },
    menuItem: {
        cursor: "pointer",
        padding: "10px 16px 10px 0px",
        width: "90%",
        transition: "width 0.3s ease, background 0.3s ease",
        "& span": {
            color: theme.palette.text._primaryDark,
        },
        "& p": {
            color: "#B3B3B3",
        },
        "&:hover": {
            width: "100%",
            background: theme.palette.background.secondaryDarkAccent,
            "& $menuIcon": {
                width:"60px",
                opacity: 1,
            },
            "& span": {
                color: theme.palette.text._secondaryDark,
            },
        },
    },
    menuIcon: {
        opacity: 0,
        transition: "width 0.3s ease, opacity 1s ease",
        width: "0px",
    }
}));

const HomeMenuTemplate: React.FC<{}> = () => {
    const classes = useStyles()
const navigate = useNavigate()
    return (
        <div className='parent flex gap-20 py-7'>
            {menuSections.map((section, index) => (
                <div className={`child-${index + 1} w-1/5`} key={index}>
                    <span className={`${classes.menuContent} child-1 text-base font-medium`}>{section.title}</span>
                    <div className={`${classes.menuTop} flex flex-col gap-y-3 mt-1 pt-3`}>
                        {section.items.map((item, idx) => (
                            <a href={item.link} className={`${classes.menuItem} rounded-lg flex items-center`} key={idx}>
                                <div className={`${classes.menuIcon}`}>
                                    <img className='w-6 mx-auto' src={item.icon} alt="" />
                                </div>
                                <div className='grid gap-y-1'>
                                    <span className='font-medium text-base'>{item.title} <ArrowForwardIosOutlinedIcon className='!pb-1' sx={{ fontSize: 20 }} /></span>
                                    <p className='text-xs'>{item.description}</p>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HomeMenuTemplate;
