import { FormikProps } from 'formik'
import React from 'react'
import { IMaterialOtherPreference } from './MaterialOtherPreferences.template';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { MODE } from '../../../../utils/types';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import { IMaterialBrandManufacturer } from './MaterialBrandManufacturer.template';

interface MaterialBrandManufacturerViewTemplateProps {
    formik: FormikProps<IMaterialBrandManufacturer>
    setModeTo: (value: MODE) => void;
    mode: MODE;
    setIsFormModeTo: (value: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.textV2.primary.primary500,
    },
    borderColor: {
        border: `1px solid ${theme.palette.v3.background.primaryLight}`
    },
    heading: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    tertiary100Border: {
        borderColor: theme.palette.textV2.tertiary.tertiary100
    },
    sectionBackground: {
        background: theme.palette.backgroundV2.tertiary.tertiary500
    },
    tertiary700: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    primaryBorder: {
        borderColor: theme.palette.borderV2.primary.primary500
    }
}));

const MaterialBrandManufacturerViewTemplate: React.FC<MaterialBrandManufacturerViewTemplateProps> = ({ formik, setModeTo, mode, setIsFormModeTo }) => {
    const classes = useStyles();

    const handleEdit = () => {
        setIsFormModeTo(true);
    }

    return (
        <div className={` p-6 grid gap-6 rounded-lg w-full ${classes.sectionBackground}`}>
            <div className={`grid gap-6 w-full`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-2xl`}>Manufacturer/Brand Preference</span>
                    <Button variant='tertiaryContained' label={`${mode !== MODE.ADD ? 'Edit' : 'Add'}`} className='!font-normal' onClick={handleEdit} />
                </div>
                <div className={`${classes.tertiary100Border} border-b w-full`}></div>
                {mode !== MODE.ADD && <div className={`grid gap-6 w-full`}>
                    <div className='w-full'>
                        <div className={`${classes.tertiary700} font-normal text-base flex gap-4  `}>
                            <span className='font-medium'>Selected Brands: </span>
                            <div className='flex  '>
                                {formik.values.brands?.map((option, index) => (
                                    <div className='flex '>
                                        <span className={`${classes.primary500} font-medium`}>{capitalizeFirstLetter(option?.label?.toLowerCase())}</span>
                                        {!(formik.values?.brands?.length - 1 === index) && <span className='mx-4'> | </span>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default MaterialBrandManufacturerViewTemplate