import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

interface ICatalogueParams {
  page: number;
  size: number;
  sort?: string;
  name?: string;
}
export interface ICatalogueAttributes {
  minValue: string | null;
  maxValue: string | null;
  values: string[] | number[] | null;
}

export interface ICatalogueRequestBody {
  name?: string;
  standardIds?: number[] | null;
  brandIds?: number[] | null;
  gradeIds?: number[] | null;
  manufacturerIds?: number[] | null;
  classes?: string[] | null;
  catalogueTypes?: string[] | null;
  shapes?: string[] | null;
  categoryId?: number | string | null;
  attributes?: { [key: string]: ICatalogueAttributes;}
  catalogueIds?: number[] | null;
  secondaryCatalogueIds?: number[] | null;
}

export const CATALOGUE_URLS = {
  GET_ALL_UPCS_BY_CATALOGUE_ID: "/catalogue/:catalogueId/upcs",
  GET_CATALOGUE_BY_ID: "/catalogue/:catalogueId",
  GET_ALL_CATALOGUE: "/catalogues",
  GET_SECONDARY_CATALOGUE_BY_ID: "/secondary-catalogue/:secondaryCatalogueId",
  GET_SECONDARY_LOTS_CATALOUGE: "/secondary-products/:catalogueId",
  GET_SECONDARY_LOTS_CATALOUGE_BY_ID: "/secondarylots-catalogue/:secondaryCatalogueId"
};

export const useCatalogueService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getAllUpcsByCatalogueId = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_ALL_UPCS_BY_CATALOGUE_ID, { catalogueId } ), authenticatedUser );
  };

  const getCatalogueById = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_CATALOGUE_BY_ID, { catalogueId } ), authenticatedUser );
  };

  const getAllCatalogues = (params: ICatalogueParams, data: ICatalogueRequestBody) => {
    return request(API_METHOD.POST, CATALOGUE_URLS.GET_ALL_CATALOGUE, authenticatedUser, data, { params } );
  };

  const getSecondaryCatalogueById = (secondaryCatalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_CATALOGUE_BY_ID, { secondaryCatalogueId }), authenticatedUser);
  }

  const getSecondaryProductsByCatalogueId = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_LOTS_CATALOUGE, { catalogueId }), authenticatedUser);
  }

  const getSecondaryLotsCatalogueById = (secondaryCatalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_LOTS_CATALOUGE_BY_ID, { secondaryCatalogueId }), authenticatedUser);

  }

  return {
    getAllUpcsByCatalogueId,
    getCatalogueById,
    getAllCatalogues,
    getSecondaryCatalogueById,
    getSecondaryProductsByCatalogueId,
    getSecondaryLotsCatalogueById
  };
};
