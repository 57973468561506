import { FC, createContext, useEffect, useState } from "react";
import { useCartService } from "../services/useCartService";
import { CART_LIFE_CYCLE } from "../utils/constant";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { useSnackbar } from "../hooks/useSnackBar";

interface CartProviderType {
  children: React.ReactNode;
}

export interface CartContextType {
  cartItemsCount: number;
  syncCart: (message:string) => Promise<void>;
  tooltip: any;
  clearCartState: () => void;
}

const getInitialCartState = () => {
  const storedState = localStorage.getItem("cartState");
  return storedState ? JSON.parse(storedState) : { cartItemsCount: 0, tooltip: { visible: false, message: "" } };
};

const CartContext = createContext<CartContextType>({
  cartItemsCount: 0,
  syncCart: async () => {},
  tooltip: { visible: false, message: "" },
  clearCartState: () => {}
});

const CartProvider: FC<CartProviderType> = ({ children }) => {
  
  const cartService = useCartService();
  const [cartItemsCount, setCartItemsCountTo] = useState<number>(getInitialCartState().cartItemsCount);
  const [tooltip, setTooltip] = useState(getInitialCartState().tooltip);
  const { user } = useAuthenticatedUser();
  const { showSnackbar } = useSnackbar();
  const [snackBar, setSnackBar] = useState(1);

  const showTooltip = (message: string) => {
    showSnackbar('success', message);
    // setTooltip({ visible: true, message });
    setTimeout(() => {
      // setTooltip({ visible: false, message: '' });
    }, 2000);
  };
  
  const syncCart = async (message:string) => {
    try {
      if (!user?.id) return;
      const cartResponse = await cartService.getCart();
      const cartItemCount = cartResponse?.data?.data?.length || 0;
      if(message === CART_LIFE_CYCLE.LOGIN){
        setCartItemsCountTo(cartItemCount);
        return;
      }

      if (cartItemsCount < cartItemCount) {
        // showTooltip('Added to Bag');
        showSnackbar('success', 'Added to Bag');
        setSnackBar(prev => prev + 1)
      } else if (cartItemsCount > cartItemCount && message === CART_LIFE_CYCLE.REMOVE_PRODUCT) {
        // showTooltip('Deleted from Bag');
        showSnackbar('success', 'Deleted from Bag');
        setSnackBar(prev => prev + 1)
      } 
      
      setCartItemsCountTo(cartItemCount);
    } catch (error) {
      setCartItemsCountTo(0);
      console.error("Error fetching data:", error);
    }
  };

  const clearCartState = () => {
    localStorage.removeItem("cartState");
    setCartItemsCountTo(0);
  };

  useEffect(() => {
    syncCart(CART_LIFE_CYCLE.LOGIN);
  }, []);

  useEffect(() => {
    localStorage.setItem("cartState", JSON.stringify({ cartItemsCount, tooltip }));
  }, [cartItemsCount, tooltip]);

  return (
    <CartContext.Provider value={{ cartItemsCount, syncCart, tooltip, clearCartState }}>
      { children }
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
