import React from "react";
import { Grid } from "@mui/material";

export interface IGridTemplate {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

interface GridViewProps {
  items: any[];
  itemComponent: (props: any) => React.ReactNode;
  gridTemplate: IGridTemplate;
  spacing?: number;
  width?: string
}

const GridView: React.FC<GridViewProps> = ({ items, itemComponent, gridTemplate, spacing, width }) => {
  return (
    <Grid container spacing={spacing}  style={{width:width}}>
      {items.map((item: any, index) => (
        <Grid item xs={gridTemplate.xs} sm={gridTemplate.sm} md={gridTemplate.md} lg={gridTemplate.lg} xl={gridTemplate.xl} key={item?.id || index}>
          {itemComponent(item)}
        </Grid>
      ))}
    </Grid>
  );
};
export default GridView;
