import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutUsTemplate from "./AboutUs.Template";
import AutoMobilesTemplate from "./AutoMobiles.Template";
import AvighnaAdvantageTemplate from "./AvighnaAdvantage.Template";
import AvighnaCustomizeTemplate from "./AvighnaCustomize.Template";
import AvighnaLogisticsTemplate from "./AvighnaLogistics.Template";
import AvighnaMetalPackTemplate from "./AvighnaMetalPack.Template";
import AvighnaSmeAdvantageTemplate from "./AvighnaSmeAdvantage.Template";
import ColdRolledSteelTemplate from "./ColdRolledSteel.Template";
import { Construction } from "@mui/icons-material";
import ConsumerAppliancesTemplate from "./ConsumerAppliances.Template";
import ElectrolyticTemplate from "./Electrolytic.Template";
import EnergyTemplate from "./Energy.Template";
import GalvanizedSteelTemplate from "./GalvanizedSteel.Template";
import ShearingTemplate from "./Shearing.Template";
import ConstructionTemplate from "./Construction.Template";
import SlittingTemplate from "./Slitting.Template";
import TinFreeSteelTemplate from "./TinFreeSteel.Template";
import VisionMissionTemplate from "./VisionMission.Template";
import { HOME_PAGE_ROUTES } from "../../../utils/constant";
import PrivacyPolicy from "../../pages/Policies/PrivacyPolicy.page";
import ColorCoatedTemplate from "./ColorCoated.Template";
import TinMillBlackPlateTemplate from "./TinMillBlackPlate.Template";
import RequestForQuotationPage from "../../pages/Home/RequestForQuotation.page";

const HomePageRoutes: React.FunctionComponent = () => {
    return (
        <Routes>
             <Route path={HOME_PAGE_ROUTES.AUTOMOBILES} element={<AutoMobilesTemplate />} />
             <Route path={"/rfq"} element={<RequestForQuotationPage />} />
             <Route path={"/aboutus"} element={<AboutUsTemplate />} />
             <Route path={"/avighna-advantage"} element={<AvighnaAdvantageTemplate />} />
             <Route path={"/avighna-customize"} element={<AvighnaCustomizeTemplate />} />
             <Route path={"/avighna-logistics"} element={<AvighnaLogisticsTemplate />} />
             <Route path={"/avighna-metalpack"} element={<AvighnaMetalPackTemplate />} />
             <Route path={"/avighna-sme-advantage"} element={<AvighnaSmeAdvantageTemplate />} />
             <Route path={"/coledrolled-steel"} element={<ColdRolledSteelTemplate />} />
             <Route path={"/construction"} element={<ConstructionTemplate />} />
             <Route path={"/consumer-appliances"} element={<ConsumerAppliancesTemplate />} />
             <Route path={"/electrolytic"} element={<ElectrolyticTemplate />} />
             <Route path={"/energy"} element={<EnergyTemplate />} />
             <Route path={"/galvanized-steel"} element={<GalvanizedSteelTemplate />} />
             <Route path={"/shearing"} element={<ShearingTemplate />} />
             <Route path={"/slitting"} element={<SlittingTemplate />} />
             <Route path={"/tinfree-steel"} element={<TinFreeSteelTemplate />} />
             <Route path={"/tin-mill-black-plate"} element={<TinMillBlackPlateTemplate />} />
             <Route path={"/vision-mission"} element={<VisionMissionTemplate />} />
             <Route path={"/color-coated"} element={<ColorCoatedTemplate />} />
        </Routes>
    );
};

export default HomePageRoutes;