import React, { useState, useEffect } from 'react';
import avighnaWebSiteBanner from '../../../assets/images/avighnaWebSiteBanner.svg';
import avighnaTabletBanner from '../../../assets/images/avighnaTabletBanner.svg'
import avighnaMobileBanner from '../../../assets/images/avighnaMobileBanner.png'
import { getScreenType } from '../../../utils/helper';
import { SCREEN_TYPES } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  banner: {},
  "@media (max-width: 480px)": {
    banner: {
      marginBottom: '32px',
    }
  },
}));

const WebsiteBanner: React.FC = () => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [imageSource, setImageSourse] = useState("");
  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const screenType = getScreenType(screenWidth);
    let imageSource;
    switch (screenType) {
        case SCREEN_TYPES.WEBSITE:
            imageSource = avighnaWebSiteBanner;
            break;
        case SCREEN_TYPES.TABLET:
            imageSource = avighnaTabletBanner;
            break;
        default :
            imageSource = avighnaMobileBanner;
            break;
    }
    return setImageSourse(imageSource);
  }, [screenWidth]);

  return (
    <div className='w-full'>
      <img
        className={`${classes.banner} w-full h-auto`}
        src={imageSource}
        alt="Banner"
      />
    </div>
  );
};
export default WebsiteBanner;



  
 