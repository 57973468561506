import React, { useEffect, useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import { useAuthService } from "../../../services/useAuthService";
import { TextField } from '@mui/material';
import { validatePhoneNumber } from "../../../utils/helper";
import { Classes } from 'jss';
import Button from "../../atoms/Button/Button";
import { createUseStyles } from "react-jss";
import AuthDialogFooter from "../../molecules/AuthDialogFooter/AuthDialogFooter";

interface LoginWithMobileTemplateProps {
    mobileNumber?: string;
    updateUserDetails: (name: string, value: string) => void;
    setAuthState: (state: AUTH_STATES) => void;
    styles: Classes<any>;
}

const useStyles = createUseStyles((theme: any) => ({
    sectionText: {
        color: theme.palette.text.primaryDarkAccent
    },
    background: {
        background: theme.palette.background.secondaryLightAccent
    },
    content: {
        color: theme.palette.button.primaryLight
    }
}));

const LoginWithMobileTemplate: React.FC<LoginWithMobileTemplateProps> = ({ setAuthState, mobileNumber, updateUserDetails, styles }) => {
    const classes = useStyles();

    const [ errorMessage, setErrorMessage ] = useState<string>("");
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const authService = useAuthService();

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value.length > 10) return;
        updateUserDetails('mobileNumber', value);
        if (!validatePhoneNumber(value))
            setErrorMessage('Please enter a valid phone number');
        else setErrorMessage('');
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (errorMessage) return;
        if (mobileNumber?.length !== 10) {
            setErrorMessage('Please enter a valid phone number');
            return;
        }
        setIsLoading(true)
        authService.login({ mobileNumber })
            .then((res => {
                if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                    setErrorMessage('Please enter a registered phone number');
                } else if (res?.status === HTTP_STATUS.OK) {
                    setAuthState(AUTH_STATES.OTP_VERIFICATION)
                }
            })).catch(error => {
                console.error("Some Error Occured:", error)
            }).finally(() => setIsLoading(false))
    };

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }

    return (
        <div className="auth-right-child-container flex flex-col h-full w-full">
            <div className="form-container flex flex-col grow w-full">
                <form className="flex flex-col gap-y-6 w-full" onSubmit={handleSubmit}>
                    <div className="flex w-full h-auto">
                        <span className={`h-full flex item-center justify-center px-4 py-4 mr-2 rounded ${classes.background} ${classes.sectionText} `}>+91</span>
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            type="tel"
                            placeholder="Enter Your Phone Number"
                            value={mobileNumber}
                            onChange={handlePhoneNumberChange}
                            error={errorMessage !== ""}
                            className="w-full"
                            helperText={errorMessage !== "" && errorMessage}
                        />
                    </div>
                    <Button variant="containedLight" label="Get OTP" type="submit" className="!h-12" isLoading={isLoading}/>
                </form>
            </div>
            <AuthDialogFooter setAuthStateTo={setAuthStateTo} section={AUTH_STATES.USER_SIGNUP} loginType={AUTH_STATES.LOGIN_WITH_EMAIL} isLogin={true}/>
        </div>
    );
}

export default LoginWithMobileTemplate;