import React from "react";
import { useStyles } from "./Style";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import NewsLetterTemplate from "./NewsLetter.Template";
import { ProductTabs, tabprops } from './ProductTabs.Template';

const EnergyTemplate: React.FC = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <section>
                <div className={classes.banner_main}>
                    <div className={`${classes.banner_wrapper} ${classes.container}`}>
                        <div className={classes.banner_content}>
                            <span>Flat Rolled Steel</span>
                            <h1>Energy</h1>
                            <p>Flat-rolled steel is vital for energy production and distribution. It's used for wind turbines,
                                solar panels, pipelines, and more. Its strength and versatility make it perfect for harsh power
                                generation, transportation, and infrastructure conditions.</p>
                            {/* <!-- <div className="btn">
                        <a href="#">Learn More</a>
                    </div> --> */}
                        </div>
                        <div className={classes.banner_preview}>
                            <div >
                                <img src="../assets/images/Energy/bannerImage1.webp" alt="" />
                            </div>
                            <div className={classes.banner_preview_content_secondary}>
                                <img src="../assets/images/Energy/bannerImage2.webp" alt="" />
                            </div>
                        </div>
                        {/* <div className={classes.banner_mobile}>
                            <img src="../assets/images/Energy/bannerMobile.png" alt="" />
                        </div> */}
                    </div>
                </div>
            </section>

            {/* <!-- about us sections starts here  --> */}

            <section className="products_about_us section-top-spacing">
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>Flat Rolled Steel</h2>
                            <p className={classes.gal_card_des} >Flat-rolled steel plays a crucial role in energy production, both in the
                                renewable and conventional sectors. In the wind energy industry, it creates strong and durable
                                structures for turbine towers and blades, thanks to its high tensile strength. Similarly, in the
                                solar energy sector, it is used to make the frames of solar panels. In the oil and gas industry,
                                flat-rolled steel is vital for constructing resilient pipelines, while in power distribution, it
                                is used to fabricate transmission poles. Its versatility, durability, and resistance to
                                corrosion make it an indispensable material for advancing various energy initiatives worldwide.
                            </p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/Energy/About.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- slider section starts here  --> */}
            {/* 
            <div className="parent p_secondary">
                <h3 className="slider_heading">Our Product Range For Energy Industries</h3>
                <div className="slick">
                    <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsPrePainted.webp" alt="" />

                    </div>
                    <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsElectrolyticTinplate.webp" alt="" />

                    </div>
                    <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsTinFreeSteel.webp" alt="" />

                    </div>
                    <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsPrePainted.webp" alt="" />

                    </div>
                    <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsColdRolled.webp" alt="" />

                    </div>
                    <div className="slider_child"><img src="../assets/images/AboutUs/ourProductsGalvanized.webp" alt="" />
                    </div>
                </div>
            </div> */}

            {/* <!-- cards for mobile view  --> */}

            <section className={`${classes.section_strip}`}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Applications </h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Energy/offshorePlatforms.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Offshore Platforms</span>
                                    <p>Cold-rolled products aid in offshore platform construction, providing durability to
                                        structural elements, panels, and enclosures. Tin-coating resists saltwater corrosion,
                                        ensuring longevity in harsh marine environments.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Energy/solarEnergy.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Solar Energy Installations</span>
                                    <p>Cold-rolled are used in solar panel components due to their corrosion resistance,
                                        formability, and durability in varying weather conditions.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Energy/piplines.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Pipelines</span>
                                    <p>Cold- rolled is crucial for pipelines, providing corrosion-resistant coatings and
                                        ensuring efficient fluid transport. Tin mill products increase pipeline lifespan and
                                        reliability in diverse industrial applications.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Energy/storageTanks.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Storage Tanks</span>
                                    <p>Cold- rolled materials are often used for storage tanks to resist corrosion and preserve
                                        stored substances. Tinplate improves tank durability for water, chemicals, and other
                                        fluids.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Energy/wind.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Wind Turbine Components</span>
                                    <p>Cold- rolled products are crucial for producing wind turbine components. The
                                        corrosion-resistant properties of tin-coated materials enhance their structural
                                        integrity, ensuring optimal performance in harsh environments.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* <!-- tabs section starts here  --> */}

            <div className={`${classes.container} ${classes.section_strip}`}>
                <Box>
                    <Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Packaging" {...tabprops(0)} />
                            <Tab label="Coated Sheets" {...tabprops(1)} />
                            <Tab label="Railway Coach" {...tabprops(2)} />
                            <Tab label="Electrical Panels" {...tabprops(3)} />
                            <Tab label="Electrical Panels" {...tabprops(4)} />
                        </Tabs>
                    </Box>
                    <ProductTabs value={value} index={0}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Energy/offshorePlatforms.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Offshore Platforms</h2>
                                <p className={classes.gal_card_des} >Cold-rolled products aid in offshore platform construction, providing
                                    durability to structural elements, panels, and enclosures. Tin-coating resists saltwater
                                    corrosion, ensuring longevity in harsh marine environments.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={1}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Energy/solarEnergy.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Solar Energy Installations</h2>
                                <p className={classes.gal_card_des} >Cold-rolled are used in solar panel components due to their corrosion
                                    resistance, formability, and durability in varying weather conditions.</p>
                            </div>
                        </div>
                    </ProductTabs>
                    <ProductTabs value={value} index={2}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Energy/piplines.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Pipelines</h2>
                                <p className={classes.gal_card_des} >Cold- rolled is crucial for pipelines, providing corrosion-resistant
                                    coatings and ensuring efficient fluid transport. Tin mill products increase pipeline
                                    lifespan and reliability in diverse industrial applications.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={3}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Energy/storageTanks.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Storage Tanks</h2>
                                <p className={classes.gal_card_des} >Cold- rolled materials are often used for storage tanks to resist
                                    corrosion and preserve stored substances. Tinplate improves tank durability for water,
                                    chemicals, and other fluids.</p>
                            </div>
                        </div>
                    </ProductTabs>

                    <ProductTabs value={value} index={4}>
                        <div className={classes.business_card}>
                            <div className={classes.card_review_secondary}>
                                <img src="../assets/images/Energy/Wind.webp" alt="" />
                            </div>
                            <div className={classes.card_content}>
                                <h2 className={classes.gal_card_heading}>Wind Turbine Components</h2>
                                <p className={classes.gal_card_des} >Cold- rolled products are crucial for producing wind turbine components.
                                    The corrosion-resistant properties of tin-coated materials enhance their structural
                                    integrity, ensuring optimal performance in harsh environments.</p>
                            </div>
                        </div>
                    </ProductTabs>
                </Box>
            </div>

            <NewsLetterTemplate />
        </>
    );
};

export default EnergyTemplate;
