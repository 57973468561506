import { Link } from 'react-router-dom';
import { CUSTOMER_ROUTES, SUBJECT_TO_TERMS_DISCLAIMER } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    subjectWrapper: {
        color: theme.palette.v4.text._primaryDarkAccent,
        fontSize: "10px",
    },
    termsConditionDisclaimer: {
        color: theme.palette.v4.text.primaryLight,
        textDecoration: "underline",
    },
}))

const SubjectToTermsConditionTemplate: React.FC = () => {

    const classes = useStyles();

    const scrollToDisclaimer = () => {
        const label = document.getElementById('scrollToDisclaimer');
        if (label) {
            label.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className={`flex ${classes.subjectWrapper} font-normal`}>
            <span>{SUBJECT_TO_TERMS_DISCLAIMER.SUBJECT_TO}</span>
            <Link to={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} target="_blank" className={`${classes.termsConditionDisclaimer} cursor-pointer mx-1`}>
                {SUBJECT_TO_TERMS_DISCLAIMER.TERMS_CONDITION}
            </Link>
            <span>{SUBJECT_TO_TERMS_DISCLAIMER.AND}</span>
            <div className={`${classes.termsConditionDisclaimer} cursor-pointer mx-1`} onClick={scrollToDisclaimer}>
                {SUBJECT_TO_TERMS_DISCLAIMER.DISCLAIMER}
            </div>
        </div>
    )
}

export default SubjectToTermsConditionTemplate;