import { POLICY_SECTION } from "../../../../utils/constant";

interface ApplicableLawAndDisputeResolutionProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
}

const ApplicableLawAndDisputeResolution: React.FC<ApplicableLawAndDisputeResolutionProps> = ({ contentStyle, boxStyle, subHeading }) => {
    return (
        <>
            <div className={contentStyle}>
                <div className={boxStyle}>
                    <span className={subHeading}>{POLICY_SECTION.APPLICABLE_LAW_AND_DISPUTE_RESOLUTION}</span>
                </div>
                <p>The Privacy Policy and applicable terms/policies are governed by Indian laws. Disputes related to the Privacy
                    Policy are subject to the dispute resolution provisions in the Terms of Use.
                </p>
            </div>
        </>
    )
}
export default ApplicableLawAndDisputeResolution;