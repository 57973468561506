import React from "react";
import { toInr } from "../../utils/helper";
import { createUseStyles } from "react-jss";
import { IChargesProps } from "../templates/Cart/CartPriceDetails.template";
import Tooltip from "../atoms/Button/Tooltip";
import { TAXES_TYPE } from "../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    price: {
        color: theme.palette.textV2.tertiary.tertiary700,
        lineHeight: "16.1px"
    },
}));

interface IPriceInfoProps {
    data: IChargesProps;
    totalQuantity: number;
    uom: string;
    label?: string;
}

const PricingInfo: React.FC<IPriceInfoProps> = ({ data, totalQuantity, uom, label }) => {
    const classes = useStyles();
    return (
        <>
            {data?.price !== 0 && (
                <div className="flex justify-between">
                    <div className="">
                        <div className={`${classes.price} text-sm font-normal`}>
                            {data?.title} {data?.showFullQuantity ? `(${totalQuantity.toFixed(3)} ${uom})` : null}
                            {data?.icon && (
                                <Tooltip title={data?.iconInfo}>
                                    <span>{data?.icon}</span>
                                </Tooltip>
                            )}
                        </div>
                        {label === "CART" ? (
                            <div className={`${classes.price} text-sm font-normal`}>
                                {data?.title === "Sub Total" ? "" : data?.subTitle}
                            </div>
                        ) : (
                            data?.subTitle && (
                                <div className={`${classes.price} text-sm font-normal`}>{data?.subTitle}</div>
                            )
                        )}
                    </div>
                    <div className={`${classes.price} text-sm font-normal`}>
                        {(data?.title === TAXES_TYPE.CGST || data?.title === TAXES_TYPE.SGST || data?.title === TAXES_TYPE.TCS) ? `+` : ""}{toInr(data?.price || 0)}
                    </div>
                </div>
            )}
        </>
    );
};
export default PricingInfo;
