import React, { useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import { TextField, FormHelperText, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { useAuthService } from "../../../services/useAuthService";
import Button from "../../atoms/Button/Button";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";
import { CUSTOMER_ROUTES, REGEX } from "../../../utils/constant";
import { useFormik } from "formik";
import { Checkbox} from "@mui/joy";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useGstinService } from "../../../services/useGstService";
import { IncognitoUser } from "./Authentication.template";
import { Link } from 'react-router-dom';
interface SignUpWithMobileTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    updateUserDetails: (name: string, value: string | boolean) => void;
    userDetails: IncognitoUser;
}

const useStyles = createUseStyles((theme: any) => ({
    sectionText: {
        color: theme.palette.text.primaryDarkAccent,
        marginLeft: '5px',  
        marginRight: '5px',
      },
    background: {
        background: theme.palette.background.secondaryLightAccent
    },
    dullText: {
        color: theme.palette.text.secondaryLightAccent
    },
    success: {
        color: theme.palette.success.secondaryDark
    }
}));



const SignUpWithMobileTemplate: React.FC<SignUpWithMobileTemplateProps> = ({ setAuthState, updateUserDetails, userDetails }) => {
    const classes = useStyles();
    const [ isGstVerified, setIsGstVerified ] = useState<boolean>(userDetails.isGstinVerified ?? false);
    const [ gstLoading, setGstLoading ] = useState<boolean>(false);
    const authService = useAuthService();
    const gstService = useGstinService();

    const initialValues = {
        fullName: userDetails.fullName ?? '',
        mobileNumber: userDetails.mobileNumber ?? '',
        gstin: userDetails.gstin ?? '',
        agreeToTerms: ''
    }

    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
            .required('Please enter your full name')
            .matches(REGEX.FULL_NAME, 'Please enter a valid name'),
        gstin: Yup.string()
            .required('Please enter your GSTIN')
            .matches(REGEX.GSTIN, 'Please provide a valid GSTIN'),
        mobileNumber: Yup.string()
            .required('Please enter your mobile number')
            .matches(REGEX.PHONE_NUMBER, 'Please enter a valid mobile number'),
        agreeToTerms: Yup.boolean()
            .required('You must agree to User Agreement and Privacy Policy')
            .oneOf([ true ], 'You must agree to User Agreement and Privacy Policy')
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting, setFieldError }) => {
            if(!isGstVerified){
                formik.setFieldError('gstin', 'Please verify your GSTIN by clicking on the \'Verify\' button');
                return;
            }
            setSubmitting(true);
            authService.signUp(values)
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK) {
                        if (res?.data?.data?.otpStatus) {
                            updateUserDetails('mobileNumber', values.mobileNumber);
                            updateUserDetails('fullName', values.fullName);
                            updateUserDetails('gstin', values.gstin);
                            updateUserDetails('isGstinVerified', isGstVerified)
                            setAuthState(AUTH_STATES.OTP_VERIFICATION);
                        } else {
                            if (!res?.data?.data?.isMobileNumberValid)
                                setFieldError('mobileNumber', "This mobile number is already registered");
                        }
                    } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                        console.error("User details form:", res?.data?.statusMessage);
                    }
                }).catch(error => {
                    console.error("User details form:", error)
                }).finally(() => setSubmitting(false));
        },
    });

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }

    const hanldleGstVerification = async () => {
        if(isGstVerified) return;
        setGstLoading(true);
        gstService.searchGstin({gstin: formik.values.gstin})
        .then(res => {
            if(res.data.data.valid){
                if( res.data.data.gstStatus === "ACTIVE" ){
                    formik.setFieldError('gstin', '');
                    setIsGstVerified(true);
                }
                else formik.setFieldError('gstin', 'Please provide a active GSTIN');
            } else {
                setIsGstVerified(false);
                formik.setFieldError('gstin', res.data.data.message);
            }
        }).catch(err => {
            formik.setFieldError('gstin', 'Please provide a valid GSTIN');
        }).finally(()=> setGstLoading(false))
    }

    return (
        <div className="auth-right-child-container flex flex-col w-full">
            <div className="form-container flex flex-col grow w-full">
                <form className="flex flex-col" onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col gap-y-4">
                        <div className="">
                            <TextField
                                label="Full Name"
                                type="text"
                                error={
                                    formik.touched.fullName && Boolean(formik.errors.fullName)
                                }
                                {...formik.getFieldProps("fullName")}
                                fullWidth
                                autoComplete="false"
                            />
                            {formik.touched.fullName && formik.errors.fullName &&
                                <FormHelperText error className='top-half '>{formik.errors.fullName}</FormHelperText>
                            }
                        </div>
                        <div className="">
                            <TextField
                                label="GSTIN"
                                type="text"
                                error={
                                    formik.touched.gstin && Boolean(formik.errors.gstin)
                                }
                                {...formik.getFieldProps("gstin")}
                                value={formik.values.gstin.trim()}
                                fullWidth
                                autoComplete="false"
                                disabled={isGstVerified}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={hanldleGstVerification}
                                            edge="end"
                                        >
                                            {isGstVerified ?
                                                <span className={`flex items-center text-lg ${classes.success}`}><CheckCircleIcon color="success" /> Verified</span>
                                                : gstLoading ? <span className={`flex items-center text-lg gap-x-1 ${classes.sectionText}`}><CircularProgress size={16} className={`${classes.sectionText}`} /> <span>Verifying</span></span> 
                                                : <span className={`text-lg ${classes.sectionText}`}> Verify </span>}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            {formik.touched.gstin && formik.errors.gstin &&
                                <FormHelperText error className=' top-full'>{formik.errors.gstin}</FormHelperText>
                            }
                        </div>
                        <div className="">
                            <div className="flex w-full h-auto">
                                <span className={`h-full flex item-center justify-center px-4 py-4 mr-2 rounded ${classes.background} ${classes.sectionText} `}>+91</span>
                                <TextField
                                    label="Phone Number"
                                    type="text"
                                    error={
                                        formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)
                                    }
                                    {...formik.getFieldProps("mobileNumber")}
                                    fullWidth
                                    autoComplete="false"
                                />
                            </div>
                            {formik.touched.mobileNumber && formik.errors.mobileNumber &&
                                <FormHelperText error className=' top-full'>{formik.errors.mobileNumber}</FormHelperText>
                            }
                        </div>
                        
                        <div className="flex flex-col justify-center pb-4">
                            <div className="flex items-center gap-2">
                                <Checkbox {...formik.getFieldProps("agreeToTerms")} /> 
                                <span className={`${classes.dullText}`}>I agree to 
                                    <Link to={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} target="_blank" className={`${classes.sectionText}`}>
                                         User Agreement 
                                    </Link>
                                    <span> and </span> 
                                    <Link to={CUSTOMER_ROUTES.PRIVACY_POLICY} target="_blank" className={`${classes.sectionText}`}>
                                        Privacy Policy
                                    </Link>
                                   </span>
                            </div>
                            {formik.touched.agreeToTerms && formik.errors.agreeToTerms &&
                                <FormHelperText error className=' top-full'>{formik.errors.agreeToTerms}</FormHelperText>
                            }
                        </div>
                    </div>
                    <Button variant="containedLight" label="Get OTP" type="submit" className="!h-12" isLoading={formik.isSubmitting} />
                </form>
                <div className="text-center mt-2 border-t mt-4 pt-4">
                    Already Have an Account Yet? <span onClick={setAuthStateTo(AUTH_STATES.LOGIN_WITH_EMAIL)} className={`text-secondary cursor-pointer font-normal text-lg ${classes.sectionText}`}>Log In</span>
                </div>
            </div>
        </div>
    );
}

export default SignUpWithMobileTemplate;