import { useEffect, useState } from "react";
import { useOrderService } from "../../../services/useOrderService";
import OrderItemTemplate from "./OrderItem.template";
import { useParams } from "react-router-dom";
import { Order, Product } from "./OrderList.template";
import OrderDetailsTemplate from "./OrderDetails.template";
import { IPriceDetails } from "../Cart/CartPriceDetails.template";
import { PRICE_DETAILS } from "../../../utils/constant";
import PaymentInfoTemplate from "./PaymentInfo.template";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
    container: { 
        padding: "24px",
        rowGap: "24px",
        border: `1px solid ${theme.palette.border.secondaryLight}`,
    },
    priceContainer: {
  
    },
    "@media (max-width: 476px)": {
      container: { 
        padding: "0px",
        rowGap: "16px",
        marginTop: "56px",
        border: "none"
      },
      priceContainer: {
        zIndex: 50,
        bottom: 0,
        left: 0,
        right: 0,
        position: "fixed",
      },
    },
  }));

const OrderViewTemplate: React.FC = () => {
    const classes = useStyles();
    const orderService = useOrderService();
    const params = useParams();
    const [order, setOrder] = useState<Order | null>(null);

    const [totalQuantity, setTotalQuantityTo] = useState<number>(0);
    const [grandTotal, setGrandTotalTo] = useState<number>(0);
    const [UOM, setUOMTo] = useState<string>("MT");
    const [paymentInfoDetails, setPaymentInfoDetailsTo] = useState<IPriceDetails>(() => PRICE_DETAILS({}));

    const calculatePaymentInfoDetails = (data: Order) => {
        let totalQuantity = 0;
        let grandTotal = 0;
        let UOM = "MT";
        let paymentInfoDetails: IPriceDetails = PRICE_DETAILS({});
        const charges = data?.charges?.[0];
        const warehouseCharges = charges?.warehouseCharges ?? 0;
        const handlingCharges = charges?.handlingCharges ?? 0;
        const packagingCharges = charges?.packagingCharges ?? 0;
        const otherCharges = charges?.otherCharges ?? 0;
        paymentInfoDetails.subTotal.price = otherCharges;
        data?.products?.forEach((item: any) => {
            let productSubTotal = item.price + (packagingCharges * item.quantity);
            paymentInfoDetails.subTotal.price += productSubTotal;
            paymentInfoDetails.warehouseCharges.price += (warehouseCharges * (item?.quantity ?? 0));
            paymentInfoDetails.handlingCharges.price += (handlingCharges * (item?.quantity ?? 0));
            let totalProductPrice = (productSubTotal + ((warehouseCharges + handlingCharges) * (item?.quantity ?? 0)));
            paymentInfoDetails.GST.price += ((charges?.gst ?? 0) * totalProductPrice) / 100;
            paymentInfoDetails.TCS.price += ((charges?.tcs ?? 0) * totalProductPrice) / 100;
            UOM = item.uom || "MT";
            totalQuantity += item?.quantity || 0
            grandTotal +=
                (productSubTotal + 
                    (warehouseCharges * item.quantity) +
                    (handlingCharges * item.quantity) +
                    (((charges?.gst ?? 0) * totalProductPrice) / 100) +
                    (((charges?.tcs ?? 0) * totalProductPrice) / 100));
        });
        paymentInfoDetails.GST.price += (otherCharges * (charges?.gst)/100);
        paymentInfoDetails.TCS.price += (otherCharges * (charges?.tcs)/100);
        grandTotal += otherCharges * (1 + (charges?.gst + charges?.tcs)/100);
        setTotalQuantityTo(totalQuantity)
        setGrandTotalTo(grandTotal)
        setUOMTo(UOM)
        setPaymentInfoDetailsTo(paymentInfoDetails);
    };


    const loadOrder = async () => {
        try {
            const orderResponse = await orderService.getOrderById(Number(params.orderId));
            if (orderResponse?.data?.data?.id) {
                setOrder(orderResponse.data.data);
            } else {
                throw new Error("Order fetch failed");
            }
        } catch (error) {
            setOrder(null);
        }
    };

    useEffect(() => {
        loadOrder();
    }, [params.orderId]);

    useEffect(() => {
        if (order) {
            calculatePaymentInfoDetails(order);
        }
    }, [order])

    return (
        <div className={`${classes.container} grid rounded`}>
            <OrderDetailsTemplate order={order} source={"VIEW"} />
            {order?.products.map((product: Product) => (
                <OrderItemTemplate label="Buy Again" key={product.id} product={product} />
            ))}

            <div className={`${classes.priceContainer}`}>
                <PaymentInfoTemplate
                    label={"Payment Information"}
                    status={order?.paymentStatus}
                    showStatus={true}
                    priceDetails={paymentInfoDetails}
                    quantity={totalQuantity}
                    uom={UOM}
                    totalPrice={grandTotal}
                />
            </div>    
        </div>
    );
};

export default OrderViewTemplate;
