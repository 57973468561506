import { useState } from "react";
import { createUseStyles } from "react-jss";
import CallIcon from '@mui/icons-material/Call';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ContactUsPopup from "../../molecules/ContactUsPopup/ContactUsPopup";
import { CONNECT_WITH_US } from "../../../utils/constant";
import callIcon from "../../../assets/images/callIcon.svg";

const useStyles = createUseStyles((theme: any) => ({
    connectUsWrapper: {
        color: theme.palette.v4.text._primaryDarkAccent,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    callIcon: {
        width: "16px",
        height: "16px",
    },
}))

const ContactUs: React.FC = () => {

    const classes = useStyles();

    const [arrowUpDown, setArrowUpDown] = useState<boolean>(false);

    const handleClick = () => {
        setArrowUpDown(!arrowUpDown);
    }

    return (
        <button className={`${classes.connectUsWrapper} font-medium text-base my-auto`} onClick={handleClick} >
            <img src={callIcon} alt="call" className={`${classes.callIcon} mr-1`} />
            {CONNECT_WITH_US.TALK_TO_US}
            {arrowUpDown ?
                <>
                    <ArrowDropUpIcon />
                    <ContactUsPopup />
                </>
                : <ArrowDropDownIcon />}
        </button>
    )
}

export default ContactUs;