import React from 'react';
import joinOurCommunity from "../../../assets/images/joinOurCommunity.svg";
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { NUMBER_URL } from '../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    primaryContainer: {
        padding: '0px',
    },
    container: {
        border: `1px solid ${theme.palette.borderV2.secondary.secondary100}`,
        borderRadius: "40px",
        margin: "0px 72px"
    },
    heading: {
        color: theme.palette.textV2.primary.primary500
    },
    content: {
        color: theme.palette.textV2.secondary.secondary500
    },
    secondaryContainer: {
    },
    btn: {},
    "@media (max-width: 480px)": {
        primaryContainer: {
            padding: '0px 16px',
        },
        container: {
            borderRadius: "24px",
            padding: '16px 8px',
            rowGap: "10px",
            width: '100%',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: '0px',
            "& img": {
                paddingTop: '0px',
                paddingRight: '0px',
                marginLeft: '0px',
            },
        },
        secondaryContainer: {
            margin: '0px',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
        },
        heading: {
            fontSize: '24px',
            textAlign: 'center',
        },
        content: {
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'center',
            padding: '0px 13%',
        },
        btn: {
            fontSize: '14px !important',
            textAlign: 'center',
            marginTop: '4px',
        },

    },
}));

const JoinOurCommunity: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.primaryContainer}`}>
            <div className={`${classes.container} flex justify-center items-center`}>
                <img src={joinOurCommunity} className='pt-10 ml-8 pr-48' alt="joinOurCommunity" />
                <div className={`${classes.secondaryContainer} flex flex-col gap-2  my-11 mr-24`} >
                    <div className={`${classes.heading}  font-bold text-5xl leading-tight `}>Join Our <br /> Community</div>
                    <div className={`${classes.content}  text-lg font-normal leading-6`}>
                        Join our exclusive WhatsApp community to stay up to date with the latest news, updates, instant notifications about new product arrivals, upcoming events, insider insights, and special offers from Avighna Steel.
                    </div>
                    <a href={NUMBER_URL.STEEL_BAZAR_NEWS} target="_blank" rel="noopener noreferrer" className={classes.btn}>
                        <Button variant="primaryContained" size="large" label="Join Now" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default JoinOurCommunity;
