import React, { useEffect, useMemo, useState } from "react";
import { CART_SECTIONS } from "../../../utils/types";
import CartItemDetail, { ICartItem, IContext } from "./CartItemDetail.template";
import CartPaymentDetailTemplate from "./CartPaymentTerms.template";
import CartReviewTemplate from "./CartDetailsReview.template";
import Breadcrumbs from "../../atoms/BreadCrumbs/BreadCrumbs";
import { CUSTOMER_ROUTES, PAYMENT_METHODS } from "../../../utils/constant";
import { makeRoute } from "../../../utils/helper";
import { createUseStyles } from "react-jss";

interface CartDetailsProps {
	cartItemDetails: ICartItem[] | [];
	handleDeleteCartItem: (cartId: number) => void;
	handleCartItemUpdate: (cartId: number, key: string, value: number | string) => void;
	configuration: IContext;
	handleRfq: () => void;
	onSelectPaymentTerm: (term: string) => void;
	currentSection: string;
	setCurrentSectionTo: (section: CART_SECTIONS) => void;
	paymentMethod: string;
}

const useStyles = createUseStyles((theme: any) => ({
	breadContainer: {
		paddingLeft: "70px",
		paddingRight: "70px",
	},
	container: {
		columnGap: "24px"
	},
    "@media (max-width: 480px)": {
		breadContainer: {
			paddingLeft: "16px",
			paddingRight: "16px",
		},
		container: {
			columnGap: "24px"
		}
	}
}));

const CartTemplate: React.FC<CartDetailsProps> = ({ cartItemDetails, handleDeleteCartItem, handleCartItemUpdate, configuration, handleRfq, onSelectPaymentTerm, currentSection, setCurrentSectionTo, paymentMethod }) => {
	const classes = useStyles();
	const cartDetailsSectionProps = {
		setCurrentSectionTo: setCurrentSectionTo,
		cartItemDetails,
		handleDeleteCartItem,
		handleCartItemUpdate,
		configuration,
		handleRfq,
		paymentMethod
	};
	const [paymentType, selectedPaymentTypeTo] = useState<string>(PAYMENT_METHODS.CASH_PAYMENT);

	useEffect(() => {
		selectedPaymentTypeTo(paymentMethod);
	}, [paymentMethod])

	const handleSelectPaymentTerm = (term: string) => {
		selectedPaymentTypeTo(term);
		onSelectPaymentTerm(term);
	};

	const cartDetailsReviewProps = {
		cartItemDetails,
		handleDeleteCartItem,
		handleCartItemUpdate,
		configuration,
		handleRfq,
		paymentType
	};

	const cartSectionView = useMemo(() => {
		switch (currentSection) {
			case CART_SECTIONS.CART_DETAILS:
				return <CartItemDetail {...cartDetailsSectionProps} />;
			case CART_SECTIONS.PAYMENT_SECTION:
				return (
					<CartPaymentDetailTemplate
						onSelectPaymentTerm={handleSelectPaymentTerm}
						setCurrentSectionTo={setCurrentSectionTo}
						cartItemDetails={cartItemDetails}
						configuration={configuration}
						handleRfq={handleRfq}
						paymentMethod={paymentType}
					/>
				);
			case CART_SECTIONS.CART_REVIEW:
				return <CartReviewTemplate {...cartDetailsReviewProps} />;
			default:
				return (
					<CartItemDetail {...cartDetailsSectionProps} />);
		}
	}, [currentSection]);

	return (
		<div className={`grid gap-y-6 m-auto max-container`}>
			<div className={`${classes.breadContainer}`}>
				<Breadcrumbs
					crumbs={[
						{
							link: makeRoute(CUSTOMER_ROUTES.CART, {
								query: { classes: "", catalogueTypes: "" },
							}),
							label: "Cart Page",
						},
					]}
				/>
			</div>
			<div className={`${classes.container} grid`}>
				{cartSectionView}
			</div>
		</div>
	);
};

export default CartTemplate;
