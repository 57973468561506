import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { DELIVERY_INFORMATION } from "../../../utils/constant";

interface ICatalogueDetailsViewTabsTemplateProps {
    deliveryTimeFrame: string;
    minimumOrderQuantity: number;
    key: number;
}
const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    subHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    body: {
        border: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
        borderRadius: '12px'
    },
}));


const ProductCatalogueDetailsViewTabsTemplate: React.FC<ICatalogueDetailsViewTabsTemplateProps> = ({ deliveryTimeFrame, minimumOrderQuantity, key }) => {
    const classes = useStyles();


    return (
        <div className={`${classes.body} p-4`}>
            <div className={`${classes.heading} mb-4 font-medium text-base`}>Delivery and Order Details</div>
            <div className="flex gap-x-6">
                <div className={`${classes.body} p-6`}>
                    <div className={`${classes.subHeading} text-sm`}>Delivery Information</div>
                    <div className={`${classes.subHeading} font-medium text-base `}>
                    {DELIVERY_INFORMATION.find(info => info.value === deliveryTimeFrame)?.label || ""}
                    </div>
                </div> <div className={`${classes.body} p-6`}>
                    <div className={`${classes.subHeading} text-sm`}>Minimum Order Quantity</div>
                    <div className={`${classes.subHeading} font-medium text-base `}>{minimumOrderQuantity}</div>
                </div>
            </div>
        </div>
    );
};

export default ProductCatalogueDetailsViewTabsTemplate;