import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    mainContainer:{
        rowGap: "16px",
    },
    container: {
        border: `1px solid ${theme.palette.border._primaryLight}`
    },
    title: {
        color: theme.palette.text.primaryLightAccent
    },
    content: {
        color: theme.palette.text._primaryDarkAccent
    },
    "@media (max-width: 480px)": {
        mainContainer:{
            rowGap: "12px",
        },

    },
}));

const DisclaimerTemplate: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={`${classes.mainContainer} grid`}>
            <div className={`${classes.title} text-lg font-semibold`}>Disclaimer</div>
            <div className={`${classes.container} flex flex-col rounded-3xl px-6 py-4 items-start gap-2`}>
                <div className={`${classes.content} text-base font-normal`}>The actual product may vary from the image shown on this page. We reserve the right to modify product specifications, prices, or availability without prior notice. Please get in touch with us if you have any questions or require further clarification regarding our steel products. Your satisfaction is our priority, and we are here to assist you with any inquiries or concerns.</div>
            </div>
        </div>
    );
};
export default DisclaimerTemplate;