import React from "react";
import { createUseStyles } from "react-jss";
import { formatUOM } from "../../../utils/helper";
import { IRangeSpecificaiton } from "../../templates/Catalogue/SecondaryLotProductAttributes.template";
import { ATTRIBUTE_LABEL, COLOR_DATA } from "../../../utils/constant";
import { IColorPalette } from "../../templates/Catalogue/AttributeSelection.template";

const useStyles = createUseStyles((theme: any) => ({

    attributeName: {
        fontSize: "14px"
    },
    attributeNames: {
        color: theme.palette.v3.text.primaryLight,
    },
    uom: {
        fontSize: "12px"
    },
    attributes: {
        color: theme.palette.textV2.primary.primary900,
    },
    attributeValue: {
        lineHeight: "36px",
        color: theme.palette.textV2.primary.primary900,
        border: `1px solid ${theme.palette.v4.border._secondaryDarkAccent}`,
    },
    attribute: {
        '&:nth-child(1), &:nth-child(2), &:nth-child(3)': {
            marginTop: '-4px',
        },
        '&:nth-child(n+4)': {
            borderTop: `1px solid ${theme.palette.v4.border._secondaryDarkAccent}`,
            marginBottom: "-12px",
        },
    },
    attributeBorder: {
        border: `1px solid ${theme.palette.border._primaryLight}`,
        padding: "4px 8px",
        borderRadius: "4px",
        marginBottom: "4px",
    },
    "@media (max-width: 476px)": {
        attributes: {
            fontSize: "14px"
        },
        attributeName: {
            fontSize: "12px"
        },
        uom: {
            fontSize: "10px"
        },
    }
}));

interface AttributeViewProps {
    label: string;
    specification: { minValue: string; maxValue: string, uom: string };
}

const AttributeView: React.FC<AttributeViewProps> = ({ specification, label }) => {
    const classes = useStyles();
    const getAttributeValue = (attributeName: string, attributeValue: string) => {
        try {
            if(attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return attributeValue;
            }
              const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-6 h-4 mr-2 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
	}
    return (
        <>
            {specification && label.trim().toLowerCase()!== "hardness" && (
                <div className={`flex flex-col items-center !px-4 !py-4 m-auto flex-1 ${classes.attribute}`} key={label}>
                    <div className="grid gap-y-1">
                        <div className={`${classes.attributeNames} justify-center flex text-sm font-medium gap-x-1`}>
                            <div className={`text-center font-medium ${classes.attributeName}`}>{label.trim()}</div>
                            {specification?.uom && (
                                <div className={`text-center text-xs font-normal ${classes.uom}`}>{`(${formatUOM("", specification.uom).trim()})`}</div>
                            )}
                        </div>
                        <div className={`${classes.attributes} p-2 font-normal m-auto`}>
                            {specification.minValue === specification.maxValue ? (
                                <div className={`${classes.attributeValue} flex justify-center items-center !py-0 !px-3 rounded-lg text-sm`}>{getAttributeValue(label, specification.minValue)}</div>
                            ) : (
                                <div className="flex">
                                    <div className={`${classes.attributeValue} flex justify-center items-center !py-0 !px-3 rounded-lg text-sm`}>{getAttributeValue(label, specification.minValue)}</div>
                                    <div className="flex items-center mx-1">-</div>
                                    <div className={`${classes.attributeValue} flex justify-center items-center !py-0 !px-3 rounded-lg text-sm`}>{getAttributeValue(label, specification.maxValue)}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AttributeView;
