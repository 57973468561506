import React, { useEffect, useState } from 'react'
import Button from '../../atoms/Button/Button'
import { createUseStyles } from 'react-jss';
import { IStatutoryDetailProps } from './StatutoryDetail.template';
import Checkbox from '@mui/material/Checkbox';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import AlertPopupModelTemplate from './AlertPopupModel.template';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import Table, { ColumnType } from '../../organisms/table';
import { IPagination } from '../../../utils/types';
import { initialPaginationValues } from '../../../utils/constant';
import EmptyBankDetailsTemplate from './EmptyBankDetails.template';
import ConfirmPrimaryChangeModal from './ConfirmPrimaryChangeModal.templeate';

interface Column {
    label: string;
    key: string;
    type: ColumnType;
    props: { className: string };
}
interface IBankAccounts {
    ifsc: string;
    branch: string;
    bankName: string;
    isDefault: boolean;
    accountName: string;
    accountNumber: string
}

const useStyles = createUseStyles((theme: any) => ({

    headingText: {
        color: theme.palette.text.secondaryDark
    },
    primaryBankAccoutText: {
        color: theme.palette.v3.text._primaryDark
    },
    paginationTable: {
        paddingLeft: '0px',
        overflowX: "hidden",
        "& .MuiTablePagination-root": {

        },
        "& .MuiTablePagination-selectLabel": {
            color: theme.palette.pagination.text.secondaryLight,
            fontWeight: 500,
            fontSize: "14px",
            paddingLeft: 0,
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #D6DEF5',
            width: '13%',
            paddingRight: '10px'
        },
        "& .MuiTablePagination-displayedRows": {
            color: theme.palette.pagination.text.secondaryLight,
            fontWeight: 500,
            fontSize: "14px",
        },
    },
    paginationComponent: {
        color: theme.palette.pagination.text.secondaryLight,
        fontWeight: 500,
        fontSize: "14px",
        marginLeft: '64%'
    },
    inputLabelText: {
        color: theme.palette.text.secondaryDark
    },
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.v4.background._primaryLightAccent,
        borderRadius: 16,
    },
    tableHeadCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 0px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        border: '1px solid red',
        borderRadius: 5,
        fontFamily: "IBM Plex Sans"

    },
    tableCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 16px",
        color: theme.palette.v4.text._primaryDarkAccent,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    input: {
        width: '60px',
        borderRadius: '8px',
        border: '1px solid #D6DEF5',
        paddingLeft: '20px'
    },
    headings: {
        border: '1px solid #D6DEF5'
    },
    primaryTextBackground: {
        color: theme.palette.v4.text._primaryDark,
        backgroundColor: theme.palette.v4.background.secondaryDark,
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure500,
    },
    checkedInputColor: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    }
}));

const UploadBankDetailsTemplate: React.FC<IStatutoryDetailProps> = ({ formik, refreshBusinessProfile, showEditFormBank }) => {
    const classes = useStyles();
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const [onPrimarychange, setOnPrimarychange] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked) {
            const hasDefaultBankDetails = formik.values.bankDetails.some(detail => detail.isDefault === true);
            if(hasDefaultBankDetails){setIsAlertModalOpen(true)}
            formik.setFieldValue("isDefault", true);
        } else {
            formik.setFieldValue("isDefault", false);
            formik.setSubmitting(true);
            setTimeout(() => formik.setSubmitting(false), 100);
        }
    };

    const handleDelete = async (index: number) => {
        setIsDeleteModalOpen(true);
        setDeleteIndex(index);
    };
    const displayedBankDetails = formik.values.bankDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).reverse();

    const handleDeleteDialogClose = async (event: boolean) => {
        setIsDeleteModalOpen(false);
        if (event && deleteIndex != null) {
            const newBankDetails = [...formik.values.bankDetails].reverse();
            newBankDetails.splice(deleteIndex, 1);
            formik.setFieldValue('bankDetails', newBankDetails);
            const body = [
                ...newBankDetails,
            ];
            try {
                await bussinessProfileService.updateBusinessProfile(body, 'BANK_ACCOUNTS');
                refreshBusinessProfile();
            } catch (error) {
                showSnackbar("error", `Business profile update  failed ${error}`);
            }
        }
    };
    const handleDialogClose = (event: boolean) => {
        if (event === true) {
            setIsAlertModalOpen(false);
            formik.setFieldValue("isDefault", true);
            const updatedBankDetails = formik.values.bankDetails.map(detail => ({
                ...detail,
                isDefault: false
            }));
            formik.setFieldValue("bankDetails", updatedBankDetails);
        } else {
            setIsAlertModalOpen(false);
            formik.setFieldValue("isDefault", false);
        }
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };


    const Action = (id: number) => (
        <div className='flex items-center justify-center gap-x-3'>
            <button onClick={() => handleDelete(id)}><img src={deleteIcon} alt={deleteIcon} /></button>
        </div>
    )

    const getTableColumns = (edit: boolean): Column[] => {
        const columns: Column[] = [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Account holder name", key: "accountName", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Account Number", key: "accountNumber", type: "customm" as ColumnType, props: { className: '' } },
            { label: "IFSC Code", key: "ifsc", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Bank Name", key: "bankName", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Branch Name", key: "branch", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
        ];
        if (edit) {
            return columns.slice(0, -1);
        }
        return columns;
    };

    const getRecords = () => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const paginatedDetails = formik.values.bankDetails
            .slice()
            .reverse()
            .slice(startIndex, endIndex);
        return paginatedDetails.map((displayedBankDetail: IBankAccounts, index) => [
            startIndex + index + 1,
            <div key={index} className="flex gap-x-3 justify-center">
                <div className="my-auto">{displayedBankDetail.accountName}</div>
                {displayedBankDetail.isDefault && (
                    <div className={`px-3 py-1.5 rounded-3xl font-medium text-xs ${classes.primaryTextBackground}`}>
                        Primary
                    </div>
                )}
            </div>,
            displayedBankDetail.accountNumber,
            displayedBankDetail.ifsc,
            displayedBankDetail.bankName,
            displayedBankDetail.branch,
            Action(index),
        ]);
    };

    const getSchema = () => ({
        id: "1",
        pagination: {
            total: formik.values.bankDetails.length,
            currentPage: page,
            isVisible: true,
            limit: rowsPerPage,
            handleChangePage: handleChangePage,
            handleChangeRowsPerPage: handleChangeRowsPerPage
        },
        columns: getTableColumns(!onEdit) ?? []
    })

    useEffect(() => {
        getRecords();
    }, [page, rowsPerPage]);


    return (
        (formik.values.bankDetails.length != 0 || onEdit) ?
            <div className='p-6 grid gap-6 border rounded-lg'>
                <div className=' flex justify-between'>
                    <text className={`${classes.headingText} self-center text-2xl font-medium`}>Bank Details</text>
                    <div className='flex '>
                        <Checkbox
                            checked={formik.values.isDefault}
                            onChange={handleCheckboxChange}
                            name="isDefault"
                            className={`${classes.checkedInputColor}`}
                        />
                        <text className={`${classes.primaryBankAccoutText} self-center text-base font-normal mr-4`}>Primary Bank Account</text>
                        <Button
                            variant={onEdit ? 'primaryContained' : 'tertiaryContained'}
                            label={onEdit ? 'Save' : 'Edit'}
                            size='small'
                            onClick={() => setOnEdit(!onEdit)}
                        />
                    </div>
                    {
                        isAlertModalOpen &&
                        <ConfirmPrimaryChangeModal
                            dialogOpen={isAlertModalOpen}
                            heading={"Change Primary Bank Account"}
                            text={"You have already added the primary bank account are you sure you want to change the "}
                            handleDialogClose={handleDialogClose}
                        />
                    }
                </div>
                <div className="border-t border-gray-300 my-2 "></div>
                {
                    onEdit && <>
                        <div className='flex flex-wrap'>
                            <div className='grid gap-1 w-1/4 pr-3'>
                                <TextFieldV2
                                    className="bg-white rounded-lg"
                                    variant="outlined"
                                    label="Account Holder Name"
                                    placeholder="Enter Name"
                                    fullWidth
                                    {...formik.getFieldProps('accountName')}
                                    error={
                                        formik.touched.accountName &&
                                        Boolean(formik.errors.accountName)
                                    }
                                />
                                {formik.touched && formik.touched.accountName && formik.errors?.accountName && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.accountName}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid gap-1 w-1/4 px-3'>
                                <TextFieldV2
                                    className="bg-white rounded-lg"
                                    variant="outlined"
                                    label="Account Number"
                                    placeholder="Enter Number"
                                    fullWidth
                                    {...formik.getFieldProps('accountNumber')}
                                    error={
                                        formik.touched.accountNumber &&
                                        Boolean(formik.errors.accountNumber)
                                    }
                                />
                                {formik.touched && formik.touched.accountNumber && formik.errors?.accountNumber && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.accountNumber}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid gap-1 w-1/4 px-3'>
                                <TextFieldV2
                                    className="bg-white rounded-lg"
                                    variant="outlined"
                                    label="IFSC Code"
                                    placeholder="Enter IFSC Code"
                                    fullWidth
                                    {...formik.getFieldProps('ifsc')}
                                    onChange={(event: any) => {
                                        formik.handleChange({
                                            target: { name: 'ifsc', value: (event.target.value).toUpperCase() },
                                        });
                                    }}
                                    error={
                                        formik.touched.ifsc &&
                                        Boolean(formik.errors.ifsc)
                                    }
                                />
                                {formik.touched && formik.touched.ifsc && formik.errors?.ifsc && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.ifsc}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid gap-1 w-1/4 pl-3'>
                                <TextFieldV2
                                    className="bg-white rounded-lg"
                                    variant="outlined"
                                    label="Bank Name"
                                    placeholder="Enter bank Name"
                                    fullWidth
                                    {...formik.getFieldProps('bankName')}
                                    error={
                                        formik.touched.bankName &&
                                        Boolean(formik.errors.bankName)
                                    }
                                />
                                {formik.touched && formik.touched.bankName && formik.errors?.bankName && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.bankName}</small>
                                    </div>
                                )}
                            </div>
                            <div className='grid gap-1 mt-6 w-1/4 pr-3'>
                                <TextFieldV2
                                    className="bg-white rounded-lg"
                                    variant="outlined"
                                    label="Branch Name"
                                    placeholder="Enter Branch Name"
                                    fullWidth
                                    {...formik.getFieldProps('branch')}
                                    error={
                                        formik.touched.branch &&
                                        Boolean(formik.errors.branch)
                                    }
                                />
                                {formik.touched && formik.touched.branch && formik.errors?.branch && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.branch}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flex justify-end '>
                            <Button
                                variant={'primaryContained'}
                                label={'Add'}
                                onClick={() => formik.handleSubmit()}
                            />
                        </div>
                    </>
                }
                {formik.values.bankDetails.length != 0 &&
                    <div className='w-full'>
                        <Table schema={getSchema()} records={getRecords()} />
                    </div>
                }
                {
                    isDeleteModalOpen &&
                    <AlertPopupModelTemplate
                        dialogOpen={isDeleteModalOpen}
                        heading={"Delete Bank Account"}
                        text={"Are you sure want to delete this bank account?"}
                        handleDialogClose={handleDeleteDialogClose}
                    />
                }

            </div> :
           showEditFormBank && <EmptyBankDetailsTemplate setEdit={setOnEdit} />
    )
}
export default UploadBankDetailsTemplate