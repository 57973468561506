import React from "react";
import { useStyles } from "./Style";
import NewsLetterTemplate from "./NewsLetter.Template";

const ConstructionTemplate: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <section>
                <div className={classes.banner_main}>
                    <div className={`${classes.banner_wrapper} ${classes.container}`}>
                        <div className={classes.banner_content}>
                            <span>Flat Rolled Steel</span>
                            <h1>Construction</h1>
                            <p>Cold-rolled steel is a crucial material in construction due to its strength, precise dimensions,
                                and versatility. Its uniformity streamlines construction processes, ensuring stability and
                                resilience in buildings and infrastructure.</p>
                            {/* <!-- <div className="btn">
                        <a href="#">Learn More</a>
                    </div> --> */}
                        </div>
                        <div className={classes.banner_preview}>
                            <div >
                                <img src="../assets/images/Construction/bannerImage1.webp" alt="" />
                            </div>
                            <div className={classes.banner_preview_content_secondary}>
                                <img src="../assets/images/Construction/bannerImage2.webp" alt="" />
                            </div>
                        </div>
                        {/* <div className={classes.banner_mobile}>
                    <img src="../assets/images/Construction/bannerMobile.png" alt="" />
                </div> */}
                    </div>
                </div>
            </section>

            <section className="products_about_us section-top-spacing">
                <div className={`${classes.produts_card} ${classes.container}`}>
                    <div className={`${classes.business_card} ${classes.about_cards}`}>
                        <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                            <h4>Introduction</h4>
                            <h2 className={classes.section_heading_main}>Flat Rolled Steel Supplier</h2>
                            <p className={classes.gal_card_des} >Cold-rolled steel is a crucial material in the construction industry, highly
                                valued for its exceptional strength, precise dimensions, and versatility. This type of steel
                                undergoes a process that enhances its surface finish and dimensional accuracy, making it an
                                ideal choice for framing, roofing, and other structural elements. Its uniform thickness and
                                consistency streamline construction processes, ensuring stability and resilience in buildings
                                and infrastructure projects. The reliability and adaptability of cold-rolled steel make it a
                                preferred material for construction, contributing to the efficiency and durability of various
                                architectural endeavors.
                            </p>
                        </div>
                        <div className={classes.card_review}>
                            <img src="../assets/images/Construction/About.webp" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- tabs section starts here  --> */}

            {/* <section className="section_strip">
        <div className="tabs_wrapper container">
            <h3>Applications</h3>
            <div className="tab">
                <button className="tablinks" onclick="openCity(event, 'Packaging')">Building Construction</button>
                <button className="tablinks" onclick="openCity(event, 'Coated Sheets')">Bridges</button>
                <button className="tablinks" onclick="openCity(event, 'Railway Coach')">roofing</button>
                <button className="tablinks" onclick="openCity(event, 'Electrical Panels')">Skyscrapers</button>
                <button className="tablinks" onclick="openCity(event, 'Automobiles')">shopping complexes</button>
            </div>

            <div id="Packaging" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/Construction/buildingConstruction.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Building Construction</h2>
                        <p className={classes.gal_card_des} >Cold-rolled steel is essential for constructing durable refrigerator
                            components that resist corrosion and maintain precise dimensions, ensuring efficient and
                            long-lasting food preservation.</p>
                    </div>
                </div>
            </div>

            <div id="Coated Sheets" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/Construction/Bridges.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Bridges</h2>
                        <p className={classes.gal_card_des} >Cold-rolled steel is crucial for constructing bridges, providing
                            strength, durability and load-bearing capacity. It ensures the resilience of bridge
                            components and supports the spans and functionality of bridges.</p>
                    </div>
                </div>
            </div>

            <div id="Railway Coach" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/Energy/piplines.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>roofing</h2>
                        <p className={classes.gal_card_des} >Cold-rolled steel is commonly used in roofing for its lightweight yet
                            sturdy properties. It provides stability, is long-lasting, is weather-resistant, and can
                            withstand various environmental conditions.</p>
                    </div>
                </div>
            </div>

            <div id="Electrical Panels" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/Construction/Skyscrapers.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>Skyscrapers</h2>
                        <p className={classes.gal_card_des} >Skyscrapers use cold-rolled steel for its strength and precision.
                            Columns and beams made from cold-rolled steel offer vertical strength and stability. This
                            uniform material allows for the precise construction of tall and complex structures.</p>
                    </div>
                </div>
            </div>

            <div id="Automobiles" className="tabcontent">
                <div className={classes.business_card}>
                    <div className={classes.card_review_secondary}>
                        <img src="../assets/images/Construction/shoppingComplexes.webp" alt="" />
                    </div>
                    <div className={classes.card_content}>
                        <h2 className={classes.gal_card_heading}>shopping complexes</h2>
                        <p className={classes.gal_card_des} >Cold-rolled steel is commonly used for framing, support, and facade in
                            shopping mall construction. Its adaptability and durability make it a preferred choice for
                            creating open spaces with structural integrity and visual appeal.</p>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

            {/* <!-- cards for mobile view  --> */}

            <section className={`${classes.section_strip}`}>
                <div className={`${classes.service_wrapper} ${classes.container}`}>
                    <h3 className={classes.section_heading}>Applications </h3>
                    <div className={classes.card_wrapper}>
                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Construction/buildingConstruction.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Building Construction</span>
                                    <p>Cold-rolled steel is crucial for constructing bridges, providing strength, durability and
                                        load-bearing capacity. It ensures the resilience of bridge components and supports the
                                        spans and functionality of bridges.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Construction/bridges.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Bridges</span>
                                    <p>Tin-free steel is ideal for chemical packaging. It is corrosion-resistant and ensures
                                        product integrity during storage and transportation.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Construction/roofing.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>roofing</span>
                                    <p>Cold-rolled steel is commonly used in roofing for its lightweight yet sturdy properties.
                                        It provides stability, is long-lasting, is weather-resistant, and can withstand various
                                        environmental conditions.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Construction/skyscrapers.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>Skyscrapers</span>
                                    <p>Skyscrapers use cold-rolled steel for its strength and precision. Columns and beams made
                                        from cold-rolled steel offer vertical strength and stability. This uniform material
                                        allows for the precise construction of tall and complex structures.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.card_main}>
                            <div className={classes.card_wrap}>
                                <div className={`${classes.card_preview} ${classes.card_mob}`}>
                                    <img src="../assets/images/Construction/shoppingComplexes.webp" alt="" />
                                </div>
                                <div className={classes.service_card_content}>
                                    <span>shopping complexes</span>
                                    <p>Cold-rolled steel is commonly used for framing, support, and facade in shopping mall
                                        construction. Its adaptability and durability make it a preferred choice for creating
                                        open spaces with structural integrity and visual appeal.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate /> 
        </>
    );
};

export default ConstructionTemplate;
