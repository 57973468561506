import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import CustomRadioGroup from "../../molecules/CustomRadioGroup/CustomRadioGroup";
import Button from "../../atoms/Button/Button";
import AddExistingProductTemplate from "./AddExistingProduct.template";
import { CLASS_STATES, HTTP_STATUS, PRODUCT_SELECTION_TYPE, STATUS } from "../../../utils/types";
import { VENDOR_PRODUCT_CLASS_SELECTION_TYPE, VENDOR_PRODUCT_CLASS_TYPE, VENDOR_PRODUCT_ROUTES } from "../../../utils/constant";
import { useVendorInventoryService } from "../../../services/useVendorInventoryService";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    container: {
        border: '1px solid #ccc',
        borderRadius: '12px',
        padding: '24px',
        cursor: 'pointer',
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    inActiveProgressBar: {
        backgroundColor: "#EBEFFA"
    },
    radioColor: {
        padding: "16px",
        width: "23%",
       " & .MuiFormControlLabel-label": {
        color: theme.palette.textV2.tertiary.tertiary700,
        fontSize: "16px",
        fontWeight: "500",
       }
    },
    radioColor2: {
        padding: "16px",
        width: "48%",
       " & .MuiFormControlLabel-label": {
        color: theme.palette.textV2.tertiary.tertiary700,
        fontSize: "16px",
        fontWeight: "500",
       }
    },
    subHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    containerHeading: {
        color: theme.palette.textV2.primary.primary900
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
}));


const SelectProductTypeTemplate: React.FC = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorInventoryService = useVendorInventoryService();
    const { user } = useAuthenticatedUser();


    const [selectedProductClassType, setSelectedProductClassTypeTo] = useState<string>('');
    const [selectedProductSelectionType, setSelectedProductSelectionTypeTo] = useState<string>('');
    const [showRadioButton, setShowRadioButtonTo] = useState<Boolean>(false);
    const [currentStep, setCurrentStep] = useState<string | null>(null);
    const [productClassType, setproductClassTypeTo] = useState<string>(CLASS_STATES.STANDARD);
    const [warehouses, setWarehousesTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedProductClassTypeTo(event.target.value);
        setShowRadioButtonTo(event.target.value === CLASS_STATES.STANDARD || event.target.value === CLASS_STATES.NONSTANDARD);
    };

    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedProductSelectionTypeTo(event.target.value);
    };

    const handleNext = () => {
        if (selectedProductSelectionType === "") {
            showSnackbar("error", "Sub-Product Type is required");
            return;
        }
        if (selectedProductClassType === CLASS_STATES.STANDARD) {
            setproductClassTypeTo(CLASS_STATES.STANDARD);
            if (selectedProductSelectionType === PRODUCT_SELECTION_TYPE.EXISTING_PRODUCT) {
                setCurrentStep(PRODUCT_SELECTION_TYPE.EXISTING_PRODUCT);
            }
            else if (selectedProductSelectionType === PRODUCT_SELECTION_TYPE.ADD_NEW_PRODUCT) {
                setCurrentStep(PRODUCT_SELECTION_TYPE.ADD_NEW_PRODUCT);
            }
        } else {
            setCurrentStep(null);
        }
    };

    const handleCancel = () => {
        navigate(VENDOR_PRODUCT_ROUTES.POST_PRODUCT)
    }

    const fetchWarehouse = async () => {
        try {
            const warehouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
            if (warehouseResponse.status === HTTP_STATUS.OK) {
                const warehouses = warehouseResponse?.data?.data?.content;
                setWarehousesTo(warehouses);
            }
        } catch (error) {
            showSnackbar('error', 'Warehouses fetch failed');
        }
    };

    const ProductSelectionView = useMemo(() => {
        if (currentStep === PRODUCT_SELECTION_TYPE.EXISTING_PRODUCT) {
            return <AddExistingProductTemplate classType={productClassType} warehouses={warehouses} setCurrentStep={setCurrentStep} handleCancel={handleCancel} />;
        } else if (currentStep === PRODUCT_SELECTION_TYPE.ADD_NEW_PRODUCT) {
            return null;
        }
        else
            return (
                <div>
                    <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm`}>
                        <div className="grid gap-y-3 w-1/2">
                            <div className='flex gap-x-2 mx-auto'>
                                <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                                <div className={`${classes.barHeading} my-auto font-semibold`}>Select Product Type </div>
                            </div>
                            <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                        </div>
                        <div className="grid gap-y-3 w-1/2">
                            <div className='flex gap-x-2 mx-auto'>
                                <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                                <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                            </div>
                            <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                        </div>
                        <div className="grid gap-y-3 w-1/2">
                            <div className='flex gap-x-2 mx-auto'>
                                <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                                <div className={`${classes.barHeading} my-auto`}>Add Product Details</div>
                            </div>
                            <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                        </div>
                        <div className="grid gap-y-3 w-1/2">
                            <div className='flex gap-x-2 mx-auto'>
                                <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                                <div className={`${classes.barHeading} my-auto`}>Review</div>
                            </div>
                            <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                        </div>
                    </div>
                    <div className={`${classes.mainHeading} text-base font-medium mt-8`}>Select Product Type</div>
                    <div className={`${classes.subHeading} text-xs mb-4`}>Please select the Product type You want to add</div>
                    <div className={classes.container}>
                        <div className={`${classes.containerHeading} text-base font-medium mb-4`}>Select product Type</div>
                        <div>
                            <CustomRadioGroup
                                options={VENDOR_PRODUCT_CLASS_TYPE}
                                value={selectedProductClassType}
                                onChange={handleChange}
                                className={`${classes.radioColor}`} />
                        </div>
                        {showRadioButton && (
                            <div className="mt-4">
                                <CustomRadioGroup
                                    options={VENDOR_PRODUCT_CLASS_SELECTION_TYPE}
                                    value={selectedProductSelectionType}
                                    onChange={handleChangeType}
                                    className={`${classes.radioColor2}`} />
                            </div>
                        )}
                    </div>
                    <div className="flex gap-4 justify-end mt-8">
                        <Button label={"Cancel"} variant="tertiaryContained" onClick={handleCancel} />
                        {/* <Button label={"Save as Draft"} variant="secondaryContained" /> */}
                        <Button label={"Next"} variant="primaryContained" onClick={handleNext} disabled={selectedProductSelectionType === "ADD_NEW_PRODUCT" || !(selectedProductClassType==="STANDARD")} />
                    </div>
                </div>
            );
    }, [currentStep, selectedProductClassType, showRadioButton, selectedProductSelectionType, classes, handleNext]);

    useEffect(() => {
        fetchWarehouse();
    }, []);

    return <div>{ProductSelectionView}</div>;
};

export default SelectProductTypeTemplate;