import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from '.';
import { IMaterialRequirement } from '../components/pages/DashBoard/MyMaterialProduct.page';
import { replaceUrlParams } from '../utils/helper';

const MATERIAL_REQUIREMENT_URLS = {
    POST_MATERIAL_REQUIREMENT_OTHER_PREFERENCE: "/material-requirement-other-preference",
    PUT_MATERIAL_REQUIREMENT_OTHER_PREFERENCE: "/material-requirement-other-preference/:section",
    GET_MATERIAL_REQUIREMENT_OTHER_PREFERENCE_BY_ID: "/material-requirement-other-preference",
}

export interface IMaterialRequirementOtherPreference {
    customization: string[],
    preferredDeliveryLocation: string[],
    preferredDeliveryTime: string,
    tradeType: string,
}

interface IMaterialRequirementBrandManufacturer {
    brands: string[],
    manufacturerIds: number[]
}

interface IPreferredProductCategory {
    productCategories: number[],
}

export const useMaterialRequirementOtherPreferenceService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getMaterialRequirementOtherPreference = () => {
        return request(API_METHOD.GET, MATERIAL_REQUIREMENT_URLS.GET_MATERIAL_REQUIREMENT_OTHER_PREFERENCE_BY_ID, authenticatedUser, null);
    };

    const updateMaterialRequirementsOtherPreference = (data: IMaterialRequirementOtherPreference | IMaterialRequirementBrandManufacturer | IPreferredProductCategory, section: string) => {
        return request(API_METHOD.PUT, replaceUrlParams(MATERIAL_REQUIREMENT_URLS.PUT_MATERIAL_REQUIREMENT_OTHER_PREFERENCE, {section}), authenticatedUser, data);
    };
    return {
        updateMaterialRequirementsOtherPreference,
        getMaterialRequirementOtherPreference,
    };
};
