import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export const CATEGORY_URLS = {
  GET_ALL_CATEGORY: "/categories",
  GET_CATEGORY_FILTERS: "/categories/filters",
};

interface ICategoryFilterRequest {
  level: number;
  name?: string;
  limit: number;
  page?: number;
  size?: number;
  sort?: string;
  parentId?: string;
  status?: string;
}

export interface AttributeSpecification {
  id: number;
  name: string;
  attributeType: string;
  description: string;
  status: string;
  imageId: number | null;
  createdBy: string;
  updatedBy: string;
  uom: string | null;
  fieldType: string;
  isUpc: boolean;
  isMpc: boolean;
  attributeOptions: string | string[];
  parentId: number | null;
  attributeTypeName: any;
  createdAt: string;
  updatedAt: string;
}

export const useCategoryService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getAllCategories = (params: ICategoryFilterRequest) => {
    return request(API_METHOD.GET, CATEGORY_URLS.GET_ALL_CATEGORY, authenticatedUser, null, { params });
  };
  const getCategoriesFilters = () => {
    return request(API_METHOD.GET, CATEGORY_URLS.GET_CATEGORY_FILTERS, authenticatedUser, null);
  };
  return {
    getAllCategories,
    getCategoriesFilters
  };
};
