import React from "react";
import { useStyles } from "./Style";
import Header from "../../organisms/Home/Header";
import NewsLetterTemplate from "./NewsLetter.Template";
import Footer from "../../atoms/Footer/Footer";

const AvighnaSmeAdvantageTemplate: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <section>
                <div className={classes.banner_main}>
                    <div className={`${classes.banner_wrapper} ${classes.container}`}>
                        <div className={classes.banner_content}>
                            <span>About Us</span>
                            <h1>Avighna SME advantage</h1>
                            <p>Affordable flat-rolled steel supply chain solutions with 25 years of SME/MSME expertise. Benefit
                                from flexible credit terms, just-in-time deliveries, and fully digitized trade services through
                                our next-gen e-commerce platform.</p>
                            {/* <!-- <div className="btn">
                        <a href="#">Learn More</a>
                    </div> --> */}
                        </div>
                        <div className={classes.banner_preview}>
                            <div >
                                <img src="../assets/images/SMEAdv/bannerImage1.webp" alt="" className="banner_img" />
                            </div>
                            <div className={classes.banner_preview_content_secondary}>
                                <img src="../assets/images/SMEAdv/bannerImage2.webp" alt="" className="banner_img" />
                            </div>
                        </div>
                        {/* <div className={classes.banner_mobile}>
                    <img src="../assets/images/SMEAdv/bannerMobile.png" alt="" />
                </div> */}
                    </div>
                </div>
            </section>

            {/* <!------------ our main business section  --------> */}

            <section className={classes.our_main_business}>
                <div className={classes.business_wrapper}>
                    <h3>Our Advantages</h3>
                    <div className={classes.business_content}>
                        <div className={`${classes.section_top_spacing} ${classes.business_card_m}`}>
                            <div className={`${classes.business_card} ${classes.container_secondary}`}>
                                <div className={classes.card_review_secondary}>
                                    <img src="../assets/images/SMEAdv/costEffective.webp" alt="" />
                                </div>
                                <div className={classes.card_content}>
                                    <img src="../assets/images/SMEAdv/Money Bag.webp" alt="" />
                                    <h2 className={`${classes.section_heading_main} ${classes.gal_card_heading}`}>Cost Effective Raw Materials Solutions
                                    </h2>
                                    <p className={classes.gal_card_des} >Avighna offers cost-effective Flat Rolled Steel raw material
                                        solutions
                                        to SMEs and MSMEs
                                        through both Customized Standard Prime and Processed Secondary materials, bringing
                                        substantial raw materials cost savings with exact specifications for your uninterrupted
                                        production flows.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.business_card_m}>
                            <div className={`${classes.business_card} ${classes.container_secondary}`}>
                                <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                                    <img src="../assets/images/SMEAdv/moneyBag1.webp" alt="" />
                                    <h2 className={`${classes.section_heading_main} ${classes.gal_card_heading}`}>Flexible Payment Options</h2>
                                    <p className={classes.gal_card_des} >Avighna supports SMEs and MSMEs with flexible credit options (7-75
                                        days)
                                        for their flat steel
                                        rolled procurement, which is backed by our transparent Credit Rating Assessment
                                        Mechanism.
                                    </p>
                                </div>
                                <div className={classes.card_review}>
                                    <img src="../assets/images/SMEAdv/flexiblePayment.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.business_card_m}>
                            <div className={`${classes.business_card} ${classes.container_secondary}`}>
                                <div className={classes.card_review_secondary}>
                                    <img src="../assets/images/SMEAdv/justInTime.webp" alt="" />
                                </div>
                                <div className={classes.card_content}>
                                    <img src="../assets/images/SMEAdv/moneyBag2.webp" alt="" />
                                    <h2 className={`${classes.section_heading_main} ${classes.gal_card_heading}`}>Just in Time Deliveries</h2>
                                    <p className={classes.gal_card_des} >Avighna maintains inventories in strategic locations like New Delhi
                                        and
                                        Navi Mumbai, enabling
                                        just-in-time deliveries, reducing customer storage costs, and optimizing production
                                        operations.</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.business_card_m}>
                            <div className={`${classes.business_card} ${classes.container_secondary}`}>
                                <div className={`${classes.card_content} ${classes.card_content_secondary}`}>
                                    <img src="../assets/images/SMEAdv/moneyBag3.webp" alt="" />
                                    <h2 className={`${classes.section_heading_main} ${classes.gal_card_heading}`}>Ease of Transactions</h2>
                                    <p className={classes.gal_card_des} >Upcoming Avighna E-Commerce offers online prompt and easy material
                                        selection, real-time order
                                        tracking, and transparent procurement, buying steel was never so user-friendly and
                                        effective.</p>
                                </div>
                                <div className={classes.card_review}>
                                    <img src="../assets/images/SMEAdv/easeofTransactions.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.business_card_m}>
                            <div className={`${classes.business_card} ${classes.container_secondary}`}>
                                <div className={classes.card_review_secondary}>
                                    <img src="../assets/images/SMEAdv/doorStepLogistics.webp" alt="" />
                                </div>
                                <div className={classes.card_content}>
                                    <img src="../assets/images/SMEAdv/moneyBag4.webp" alt="" />
                                    <h2 className={`${classes.section_heading_main} ${classes.gal_card_heading}`}>Door Step Logistics</h2>
                                    <p className={classes.gal_card_des} >Avighna logistics ensures safe, direct, on-time material deliveries,
                                        weatherproof,
                                        eliminating multiple third-party involvement and safeguarding expensive raw materials
                                        through our logistics and transportation services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewsLetterTemplate />
        </>
    );
};

export default AvighnaSmeAdvantageTemplate;
