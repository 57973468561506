import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import CloseIcon from '@mui/icons-material/Close';
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import { FormikErrors, FormikProps } from 'formik';
import { IAddNewInventoryDetail, IAddVendorInventoryForm } from './AddNewVendorInventory.template';
import CustomRadioGroup from '../../../molecules/CustomRadioGroup/CustomRadioGroup';
import uploadIconVendor from '../../../../assets/icons/uploadIconVendor.svg'
import { FormHelperText } from '@mui/material';
import SelectV2 from '../../../atoms/Select/SelectV2';
import { PACKAGING_TYPES, STATUS_OPTION } from '../../../../utils/constant';
import { iModalFormikForm } from './AddInventorysection.template';

export interface IAddNewInventoryModalProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    formik: FormikProps<IAddVendorInventoryForm>;
    modalFormik: FormikProps<iModalFormikForm>;
}

const radioGroupOption = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
]

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.v2.text.primaryDark
    },
    container: {
        "& .MuiDialog-paper": {
            width: "1154px !important",
            maxWidth: "1154px !important",
            borderRadius: "12px"
        },
    },
    mainHeading: {
        color: theme.palette.v3.text.primaryLightAccent
    },
    content: {
        color: theme.palette.v3.text.primaryLight
    },
    button: {
        paddingLeft: "89px !important",
        paddingRight: "89px !important",
        color: theme.palette.button.secondaryDark,
    },
    closeIcon: {
        color: theme.palette.v2.text._primaryDark
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    headingText: {
        color: theme.palette.backgroundV2.primary.primary900,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700,
        width: '23%'
    },
    uploadBox: {
        border: `1px solid ${theme.palette.textV2.tertiary.tertiary800}`,
    },
    uploadText: {
        color: theme.palette.textV2.tertiary.tertiary800,
    },
}));

const AddNewInventoryModalTemplate: React.FC<IAddNewInventoryModalProps> = ({ dialogOpen, setDialogOpen, formik, modalFormik }) => {
    const classes = useStyles();
    const handleDialogClose = () => {
        setDialogOpen(false);
        modalFormik.setFieldValue('wareHouses', []);
    };
    const handleSubmitClose = () => {
      modalFormik.handleSubmit();
    };
    const addAnotherWarehouseFields = () => {
        const newWarehouseDetail: IAddNewInventoryDetail = {
            warehouseId: modalFormik.values.wareHouses[0]?.warehouseId,
            vendorUsnCode: '',
            grossWeight: 0,
            netWeight: 0,
            packagingType: '',
            isMillTest: false,
        };
        modalFormik.setFieldValue('wareHouses',[...modalFormik.values.wareHouses, newWarehouseDetail])
    }
    const handleChange = (index: number, event: any) => {
        const { name, value } = event.target;
        const updatedWarehouses = [...modalFormik.values.wareHouses];
        const parsedValue = name === 'isMillTest' ? value === 'true' : value;
        updatedWarehouses[index] = {
            ...updatedWarehouses[index],
            [name]: parsedValue
        };
        modalFormik.setFieldValue('wareHouses',updatedWarehouses)
    };
    return (
        <Dialog fullWidth className={`${classes.container} w-full`} open={dialogOpen} onClose={handleDialogClose}>
            <div className="p-6 border-3 grid gap-6">
                <div className="flex justify-between">
                    <span className={`${classes.headingText} text-2xl font-medium`}>Add Inventory</span>
                    <div onClick={handleDialogClose}>
                        <CloseIcon className={`${classes.closeIcon}`} />
                    </div>
                </div>

                {modalFormik?.values?.wareHouses?.map((warehouse: IAddNewInventoryDetail, index: number) => (
                    <div key={index} className={`${classes.selectBox} rounded-xl grid gap-6 p-4`}>
                        <div className="flex gap-6">

                            <div className='w-1/4'>
                                <TextFieldV2
                                    variant="outlined"
                                    label="Your USN No. (Optional)"
                                    placeholder="Enter Number"
                                    fullWidth
                                    {...modalFormik.getFieldProps(`wareHouses[${index}].vendorUsnCode`)}
                                    type="text"
                                />
                            </div>

                            <div className='w-1/4'>
                                <TextFieldV2
                                    variant="outlined"
                                    placeholder="Enter Quantity"
                                    label="Net Weight"
                                    fullWidth
                                    {...modalFormik.getFieldProps(`wareHouses[${index}].netWeight`)}
                                    type="number"
                                error={
                                    modalFormik?.touched &&  modalFormik?.touched?.wareHouses && modalFormik?.touched?.wareHouses?.[index]?.netWeight &&
                                    Boolean(( modalFormik?.errors && modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])?.[index]?.netWeight)
                                }
                                />
                                {modalFormik?.touched?.wareHouses?.[index]?.netWeight && modalFormik?.errors?.wareHouses && (modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.netWeight &&
                                    <FormHelperText error className='top-half '>{(modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])?.[index]?.netWeight}</FormHelperText>
                                }
                            </div>

                            <div className='w-1/4'>
                                <TextFieldV2
                                    variant="outlined"
                                    placeholder="Enter Quantity"
                                    label="Gross Weight"
                                    fullWidth
                                    {...modalFormik.getFieldProps(`wareHouses[${index}].grossWeight`)}
                                    type="number"
                                    error={
                                        modalFormik?.touched?.wareHouses?.[index]?.grossWeight &&
                                        Boolean(( modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])?.[index]?.grossWeight)
                                    }
                                />
                                {modalFormik?.touched?.wareHouses?.[index]?.grossWeight && modalFormik?.errors?.wareHouses && (modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.grossWeight &&
                                    <FormHelperText error className='top-half '>{(modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.grossWeight}</FormHelperText>
                                }
                            </div>

                            <div className='w-1/4'>
                                <SelectV2
                                    label="Packing Type"
                                    placeholder="Select packaging"
                                    fullWidth
                                    {...modalFormik.getFieldProps(`wareHouses[${index}].packagingType`)}
                                    error={
                                        modalFormik?.touched?.wareHouses?.[index]?.packagingType &&
                                        Boolean(( modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])?.[index]?.packagingType)
                                    }
                                    options={
                                        PACKAGING_TYPES?.map((packaging) => ({
                                            value: packaging.value,
                                            label: packaging.label,
                                        })) || []
                                    }
                                />
                                {modalFormik?.touched?.wareHouses?.[index]?.packagingType && modalFormik?.errors?.wareHouses && (modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.packagingType &&
                                    <FormHelperText error className='top-half '>{(modalFormik?.errors?.wareHouses as FormikErrors<IAddNewInventoryDetail>[])[index]?.packagingType}</FormHelperText>
                                }
                            </div>

                        </div>

                        <div className="grid gap-4">
                            <span className={`${classes.headingText} font-medium text-base`}>Mill Test Certificate Available?</span>
                            <CustomRadioGroup
                                options={radioGroupOption}
                                value={modalFormik.values.wareHouses[index].isMillTest ? 'true' : 'false'}
                                name="isMillTest" 
                                onChange={(e) => handleChange(index, e)}
                                className={`${classes.radioColor}`}
                            />
                        </div>

                        <div className={`${classes.uploadBox} p-3 w-1/2 flex justify-center rounded-xl gap-2`}>
                            <img src={uploadIconVendor} alt={''} />
                            <text className={`${classes.uploadText} text-lg font-medium`}>Mill Test Certificate (MTC)</text>
                        </div>
                    </div>
                ))}

                <div className="flex justify-between">
                    <Button
                        variant="tertiaryContained"
                        label="+Add Another"
                        onClick={addAnotherWarehouseFields}
                    />
                    <div className='flex gap-4'>
                        <Button
                            variant="tertiaryContained"
                            label="Cancel"
                            onClick={handleDialogClose}
                        />
                        <Button
                            variant="primaryContained"
                            label="+Add"
                            onClick={handleSubmitClose}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default AddNewInventoryModalTemplate;
